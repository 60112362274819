import React, { useState, useEffect, createRef } from "react";
import { AgoraVideoPlayer } from "agora-rtc-react";
import Controls from "./Controls";
import { useParams } from "react-router-dom";
import { useScreenshot } from "use-react-screenshot";
import { axiosPostData, postData } from "../../../api/APIinstance";

function Videos(props) {
  const { users, tracks, setInCall, setStart } = props;
  const [enlargeUser, setEnlargeUser] = useState(0);
  const [floatingTrack, setFloatingTrack] = useState(tracks[1]);
  const [fixedScreenTrack, setFixedScreenTrack] = useState(tracks[1]);
  const [hide, setHide] = useState(false);
  const { id } = useParams();
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();

  useEffect(() => {
    console.log("users", users);
    console.log("tracks", tracks);

  }, [users, tracks]);

  useEffect(() => {
    const url = window.location.href;

    console.log("url.includes", url.includes("call"));

    if (url.includes("call")) {
      setHide(true);
    } else {
      setHide(false);
    }

  }, []);

  function enlargerScreen(id) {
    console.log("id", id);
    setEnlargeUser(id);
  }

  function maximizeSelectedScreen() {
    setFloatingTrack(users[enlargeUser].videoTrack);
  }

  function setScreenShareStreamOnFixedScreen(stream) {
    setFloatingTrack(stream);
  }

  const getImage = async () => {
    console.log("ref.current", ref.current);
    // await takeScreenshot(ref.current);
    if (!!ref) {
      await takeScreenshot(ref?.current);

      if (!!image) {
        console.log("!!ref", !!ref);
        console.log("!!image", !!image);
        console.log(image);

        fetch(image, { headers: { "Content-Type": "image/png" } })
          .then((res) => res.blob())
          .then((bb) => {
            const file = new File([bb], "nothing", { type: "image/png" });
            console.log(
              "file object==============================================================",
              file
            );
            // setFileImage(file);
            const formData = new FormData();
            formData.append("checklist_file", file);
            formData.append("fileType", "Image");
            formData.append("withAnswer", "False");
            formData.append("survey", id);
            console.log("formData", formData);
            let response = axiosPostData("CheckListAttachment/", formData);
            console.log(response, "response");
          });
      }
    } else {
      console.log("video call not started");
    }
  };

  return (
    <React.Fragment>
      <div className="fixed_screen">
        {users.length === 0 ? (
          <>
            {hide === true ? null :
              <i
                className="fa fa-camera text-secondary"
                style={{
                  fontSize: "20px",
                  position: "absolute",
                  top: "4%",
                  left: "90%",
                  zIndex: 50,
                }}
              ></i>
            }
            <AgoraVideoPlayer className="vid" videoTrack={fixedScreenTrack} />
          </>
        ) : (
          <AgoraVideoPlayer
            className={"vid"}
            videoTrack={users[enlargeUser].videoTrack}
            key={users[enlargeUser].uid}
          // onClick={() => enlargerScreen(users[enlargeUser].uid)}
          />
        )}
      </div>

      {users.length > 0 ? (
        <div className="floating_screen">
          <AgoraVideoPlayer
            className="vid"
            videoTrack={floatingTrack}
            onClick={() => {
              maximizeSelectedScreen(floatingTrack);
            }}
          />
        </div>
      ) : null}

      {users.length > 0 ? (
        <div className="row" id="otherusersrow">

          {hide === true ? null :
            <i
              className="fa fa-camera text-light"
              onClick={getImage}
              style={{
                fontSize: "20px",
                position: "absolute",
                top: "4%",
                left: "90%",
                zIndex: 50,
              }}
            ></i>}

          {users.length > 0 &&
            users.map((user, i) => {
              if (i !== enlargeUser) {
                if (user.videoTrack) {
                  return (
                    <div className="col-6 col-md-4 mb-2">
                      <div className="other_users cursor_pointer">
                        <AgoraVideoPlayer
                          className={"vid"}
                          videoTrack={user.videoTrack}
                          key={user.uid}
                          onClick={() => enlargerScreen(i)}
                        />
                        {/* <div class="overlay">
                                                    <div class="text">Hello World</div>
                                                </div> */}
                      </div>
                    </div>
                  );
                } else return null;
              } else return null;
            })}
        </div>
      ) : null}

      {/* <div id={users.length > 0 ? "videos_shrink" : "videos"}  >
                <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} />
            </div> */}

      {/* <div id="videos_2" className={users.length > 0 ? '' : 'd-none'}>
                {users.length > 0 &&
                    users.map((user) => {
                        if (user.videoTrack) {
                            return (
                                <AgoraVideoPlayer
                                    className={'vid'}
                                    videoTrack={user.videoTrack}
                                    key={user.uid}
                                    onClick={() => enlargerScreen(user.uid)}
                                />
                            );
                        } else return null;
                    })}
            </div> */}

      <Controls
        endCall={props.endCall}
        tracks={tracks}
        setStart={setStart}
        setInCall={setInCall}
        setScreenShareStreamOnFixedScreen={setScreenShareStreamOnFixedScreen}
      />
    </React.Fragment>
  );
}

export default Videos;
