// import React, {  Fragment } from 'react';
// import { DollarSign, Tag, ShoppingBag, Sun} from 'react-feather';
// import CountUp from 'react-countup';
// import ChartistGraph from 'react-chartist';
// import { chart1, chartsmall } from '../../../data/default';
// import configDB from '../../../data/customizer/config';
// import {TotalVisits,TotalSale,TotalValue,TotalTax,TotalEarning,ProductionValuation} from '../../../constant'
// const primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;
// const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

// const EventCharts = () => {
//         return (
//             <Fragment>
//                 <div className="col-md-4">
//                     <div className="card">
//                         <div className="card-body">
//                             <div className="chart-widget-dashboard">
//                                 <div className="d-flex">
//                                     <div className="flex-grow-1">
//                                         <h5 className="mt-0 mb-0 f-w-600">
//                                             <DollarSign />
//                                             <span >
//                                                 <CountUp className="counter" end={5789} />
//                                             </span>
//                                         </h5>
//                                         <p>{TotalVisits}</p>
//                                     </div>
//                                     <Tag />
//                                 </div>
//                                 <div className="dashboard-chart-container">
//                                     <div className="small-chart-gradient-1">
//                                         <ChartistGraph key="1" className="ct-chart-line" data={{
//                                             labels: ['01', '02', '03', '04', '05', '06', '07'],
//                                             series: [ [0, 2, 1.2, 4, 2, 3, 1.5, 0] ]
//                                         }} type={'Line'} listener={{
//                                             "created": function (data) {
//                                                 var defs = data.svg.elem('defs');
//                                                     defs.elem('linearGradient', {
//                                                         id: 'gradient1',
//                                                         x1: 0,
//                                                         y1: 0,
//                                                         x2: 1,
//                                                         y2: 1
//                                                     }).elem('stop', {
//                                                         offset: 0,
//                                                         'stop-color': secondary
//                                                     }).parent().elem('stop', {
//                                                         offset: 1,
//                                                         'stop-color': primary
//                                                     });
//                                             }
//                                         }} options={chartsmall} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-md-4">
//                     <div className="card">
//                         <div className="card-body">
//                             <div className="chart-widget-dashboard">
//                                 <div className="d-flex">
//                                     <div className="flex-grow-1">
//                                         <h5 className="mt-0 mb-0 f-w-600">
//                                             <DollarSign />
//                                             <span>
//                                                 <CountUp className="counter" end={4986} />
//                                             </span>
//                                         </h5>
//                                         <p>{TotalSale}</p>
//                                     </div>
//                                     <ShoppingBag />
//                                 </div>
//                                 <div className="dashboard-chart-container">
//                                     <div className="small-chart-gradient-1">
//                                         <ChartistGraph key="1" className="ct-chart-line" data={{
//                                             labels: ['01', '02', '03', '04', '05', '06'],
//                                             series: [ [0, 2, 1.2, 4, 2, 3, 0] ]
//                                         }} type={'Line'} listener={{
//                                             "created": function (data) {
//                                                 var defs = data.svg.elem('defs');
//                                                     defs.elem('linearGradient', {
//                                                         id: 'gradient1',
//                                                         x1: 0,
//                                                         y1: 0,
//                                                         x2: 1,
//                                                         y2: 1
//                                                     }).elem('stop', {
//                                                         offset: 0,
//                                                         'stop-color': secondary
//                                                     }).parent().elem('stop', {
//                                                         offset: 1,
//                                                         'stop-color': primary
//                                                     });
//                                             }
//                                         }} options={chartsmall} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-md-4">
//                     <div className="card">
//                         <div className="card-body">
//                             <div className="chart-widget-dashboard">
//                                 <div className="d-flex">
//                                     <div className="flex-grow-1">
//                                         <h5 className="mt-0 mb-0 f-w-600">
//                                             <DollarSign />
//                                             <span>
//                                                 <CountUp className="counter" end={8568} />
//                                             </span>
//                                         </h5>
//                                         <p>{TotalValue}</p>
//                                     </div>
//                                     <Sun />
//                                 </div>
//                                 <div className="dashboard-chart-container">
//                                     <div className="small-chart-gradient-1">
//                                         <ChartistGraph key="1" className="ct-chart-line" data={{
//                                             labels: ['01', '02', '03', '04', '05', '06', '07'],
//                                             series: [ [0, 2, 1.2, 4, 2, 3, 1.5, 2, 0] ]
//                                         }} type={'Line'} listener={{
//                                             "created": function (data) {
//                                                 var defs = data.svg.elem('defs');
//                                                     defs.elem('linearGradient', {
//                                                         id: 'gradient1',
//                                                         x1: 0,
//                                                         y1: 0,
//                                                         x2: 1,
//                                                         y2: 1
//                                                     }).elem('stop', {
//                                                         offset: 0,
//                                                         'stop-color': secondary
//                                                     }).parent().elem('stop', {
//                                                         offset: 1,
//                                                         'stop-color': primary
//                                                     });
//                                             }
//                                         }} options={chartsmall} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-lg-12">
//                     <div className="card">
//                         <div className="card-header">
//                             <h5>{ProductionValuation}</h5>
//                         </div>
//                         <div className="card-body">
//                             <div className="show-value-top d-flex">
//                                 <div className="value-left d-inline-block">
//                                     <div className="square bg-primary d-inline-block"></div><span>{TotalEarning}</span>
//                                 </div>
//                                 <div className="value-right d-inline-block">
//                                     <div className="square d-inline-block bg-secondary"></div><span>{TotalTax}</span>
//                                 </div>
//                             </div>
//                             <div className="smooth-chart flot-chart-container">
//                                 <ChartistGraph key="1" className="ct-chart-line" data={{
//                                     labels: ['2018', '2019', '2020', '2021'],
//                                     series: [ [0, 6, 2, 6],
//                                     [0, 7, 1, 8]]
//                                 }} type={'Line'} listener={{
//                                     "draw": function (data) {
//                                         if (data.type === 'line' || data.type === 'area') {
//                                             data.element.animate({
//                                                 d: {
//                                                     begin: 2000 * data.index,
//                                                     dur: 2000,
//                                                     from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
//                                                     to: data.path.clone().stringify(),
//                                                 }
//                                             });
//                                         }
//                                     }
//                                 }} options={chart1} />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Fragment>
//         );
//     }

// export default EventCharts;

import React, { Fragment, useEffect, useState } from "react";
import { DollarSign, Tag, ShoppingBag, Sun } from "react-feather";
import CountUp from "react-countup";
import ChartistGraph from "react-chartist";
import { chart1, chartsmall } from "../../../data/default";
import configDB from "../../../data/customizer/config";
import { Card } from "react-bootstrap";
import { getData } from "../../../api/APIinstance";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader";
import { useNavigate } from "react-router-dom";
import { Title } from "chart.js";
// import {
//   TotalVisits,
//   TotalSale,
//   TotalValue,
//   TotalTax,
//   TotalEarning,
//   ProductionValuation,
// } from "../../../constant";
const primary =
  localStorage.getItem("primary_color") || configDB.data.color.primary_color;
const secondary =
  localStorage.getItem("secondary_color") ||
  configDB.data.color.secondary_color;

const EventCharts = ({ survey, data }) => {
  const [tiles, setTiles] = useState([]);
  const navigate = useNavigate();
  const colors = [
    { status: "survey_Active", color: "#708090" },
    { status: "survey_InProgress", color: "#EF5B0C" },
    { status: "survey_Completed", color: "#FFB200" },
    { status: "survey_Signed", color: "#224B0C" },
    { status: "survey_Correction_Requried", color: "#C21010" },
    { status: "survey_Verified", color: "#2264AB" },
  ];

  useEffect(async () => {
    let data = JSON.parse(localStorage.getItem("login_data"));
    let response = await getData(`SurveyCount/?customerId=${data.customer_id}`);
    setTiles(Object.entries(response));
  }, []);

  const handleSurvey1 = (name) => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default/surveyList2/${name}/na`);
  };
  if (tiles) {
    var arrayWithoutD = tiles.filter(function (letter) {
      return letter[0] !== "survey_VerificationPending";
    });
  }

  return (
    <Fragment>
      {!!!survey &&
        arrayWithoutD.map((el) => {
          return (
            <div
              onClick={() => handleSurvey1(el[0].split("_")[1])}
              key={Math.ceil(Math.random() * 100000)}
              className="col-md-2 mb-3 pt-3 border rounded"
              style={{
                height: "140px",
                width: "",
                cursor: "pointer",
                // background: `#${Math.ceil(Math.random() * 1000)}4AB`,
                background: colors.filter((el1) => el[0] == el1.status)[0]
                  ?.color,
                color: "white",
              }}
            >
              <div className="container text-center h-100 w-100">
                <div className="row h-100">
                  <div className="col align-self-start">
                    <span
                      className="mt-0 mb-0 f-w-600"
                      style={{ fontSize: "24px" }}
                    >
                      <CountUp className="counter" end={el[1]} />
                    </span>
                  </div>
                  <div className="col align-self-center"></div>
                  <div className="col align-self-end">
                    <span
                      className="align-self-end"
                      style={{ fontSize: "24px" }}
                    >
                      {" "}
                      {/* {console.log(el)} */}
                      {el[0].split("_")[1] == "VerificationPending"
                        ? "Verification Pending"
                        : el[0].split("_")[1] == "Signed"
                        ? "Submitted"
                        : el[0].split("_")[1] == "Active"
                        ? "Assigned"
                        : el[0].split("_")[1] == "Correction"
                        ? "Correction Required"
                        : el[0].split("_")[1]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {survey ? (
        <Card
          className="col-md-4 border py-2 mx-2 mb-4 rounded"
          style={{ height: "191px" }}
        >
          <div className="container h-100 w-100">
            <div className="p-3">
              <div className="row h-100">
                <div className="col align-self-end">
                  <p
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Vessel
                  </p>
                  <span
                    className="text-uppercase"
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    {data?.vessel.name}
                  </span>
                  <div className="d-flex justify-content-between">
                    <p
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                    >
                      IMO No:
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      {data?.vessel.number}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                    >
                      Type:{" "}
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      {data?.vessel.type.name}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                    >
                      GT:{" "}
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      {data?.vessel.GT}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default EventCharts;
