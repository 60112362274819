import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllCountries,
  getStatesByCountry,
  getCitiesByState,
  getStates,
  getCities,
  updateCustomer,
  getAllCustomerType,
  updateCust,
} from "../../../../api";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(30, "30 characters allowed !")
      .required("Name is required !"),
    email: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    adminEmail: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    website: yup.string().required("Website is required !"),
    // contactNumber: yup.string().required('Contact number is required !'),
    address: yup.string().nullable().required("Address is required !"),
    country: yup
      .array()
      .min(1, "Country is required !")
      .nullable()
      .required("Country is required !"),
    // countryCode: yup.array().nullable().required('Country Code is required !'),
    customerType: yup
      .string()
      .nullable()
      .required("Customer type is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    // isActive: yup.string().nullable().required('Status is required !'),
    logo: yup.mixed().test("fileSize", "The file is too large", (value) => {
      if (!value.length) return true; // attachment is optional
      return value[0].size <= 5000000;
    }),
  })
  .required();

function EditCustForm(props) {
  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });
  const [custTypeList, setCustTypeList] = useState({ data: [], state: false });

  const [cityTypeahead, setCityTypeahead] = useState(false);
  const [stateTypeahead, setStateTypeahead] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const selectedCountry = watch("country");
  const selectedState = watch("state");
  const sameAsEmail = watch("sameAsEmail");

  useEffect(() => {
    setCustTypeList({ ...custTypeList, state: true, data: [] });
    getAllCustomerType().then(
      (res) => {
        console.log("res", res);
        setCustTypeList({ ...custTypeList, state: false, data: res.data });
      },
      (err) => {
        console.log("err", err);
        setCustTypeList({ ...custTypeList, state: false, data: [] });
        toast.error("Something went wrong in fetching customertypes !", {
          autoClose: 3000,
          theme: "light",
        });
      }
    );
  }, []);

  useEffect(() => {
    console.log("props.modal8", props.modal8);

    if (props.modal8 === true) {
      reset();
    }
  }, [props.modal8]);

  useEffect(() => {
    console.log("sameAsEmail", sameAsEmail);
    if (sameAsEmail == true) {
      const email = getValues("email");
      setValue("adminEmail", email);
    }
  }, [sameAsEmail]);

  // useEffect(() => {
  //     console.log("props.data", props.data);

  //     if (props.data !== undefined) {
  // setStateList({ ...stateList, data: [], state: true });

  // getStatesByCountry(props.data.country).then((res) => {
  //     console.log("res.data", res.data);
  //     setStateList({ ...stateList, data: res.data, state: false });
  // }, (err) => {
  //     console.log("err", err);
  //     setStateList({ ...stateList, data: [], state: false });
  //     toast.error("Something went wrong in fetching states !", { autoClose: 3000,theme:'light' })
  // })

  // if (props.data.city !== null || props.data.city !== undefined || props.data.city.length !== 0) {
  //     setCityList({ ...cityList, data: [], state: true });

  //     getCitiesByState(props.data.state).then((res) => {
  //         console.log("res.data", res.data);
  //         setCityList({ ...cityList, data: res.data, state: false });
  //     }, (err) => {
  //         console.log("err", err);
  //         setCityList({ ...cityList, data: [], state: false });
  //         toast.error("Something went wrong in fetching cities !", { autoClose: 3000,theme:'light' })
  //     })
  // }

  // }
  // }, [props.data]);

  useEffect(() => {
    setCountryList({ ...countryList, state: true, data: [] });
    getAllCountries().then(
      (res) => {
        console.log("res", res);
        setCountryList({ ...countryList, state: false, data: res.data });
      },
      (err) => {
        console.log("err", err);
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error("Something went wrong in fetching countries !", {
          autoClose: 3000,
          theme: "light",
        });
      }
    );
  }, []);

  useEffect(() => {
    if (selectedCountry !== undefined && selectedCountry.length !== 0) {
      setStateList({ ...stateList, data: [], state: true });

      getStates(selectedCountry[0].name).then(
        (res) => {
          console.log("res.data", res.data);
          setStateList({ ...stateList, data: res.data, state: false });
        },
        (err) => {
          console.log("err", err);
          setStateList({ ...stateList, data: [], state: false });
          toast.error("Something went wrong in fetching states !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState !== undefined) {
      if (selectedState.length > 0) {
        if (selectedState[0].name.length > 0) {
          setCityList({ ...cityList, data: [], state: true });
          console.log("selectedState", selectedState);
          getCities(selectedState[0].name).then(
            (res) => {
              console.log("res.data", res.data);
              setCityList({ ...cityList, data: res.data, state: false });
            },
            (err) => {
              console.log("err", err);
              setCityList({ ...cityList, data: [], state: false });
              toast.error("Something went wrong in fetching cities !", {
                autoClose: 3000,
                theme: "light",
              });
            }
          );
        }
      }
    }
  }, [selectedState]);

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const reqPayload = { ...data };

      reqPayload.country = data.country[0].name;

      if (
        reqPayload.countryCode !== undefined &&
        reqPayload.countryCode.length > 0
      ) {
        reqPayload.contactNumber =
          reqPayload.countryCode[0].phone_code + reqPayload.contactNumber;
        delete reqPayload.countryCode;
      } else {
        reqPayload.contactNumber = props.data.contactNumber;
        delete reqPayload.countryCode;
      }

      if (reqPayload.state.length === 0) {
        delete reqPayload.state;
      } else {
        reqPayload.state = reqPayload.state[0].name;
      }

      if (reqPayload.city.length === 0) {
        delete reqPayload.city;
      } else {
        reqPayload.city = reqPayload.city[0].name;
      }

      if (Object.keys(reqPayload.logo).length === 0) {
        delete reqPayload.logo;
      } else {
        reqPayload.logo = reqPayload.logo[0];
      }
      reqPayload.country = data.country[0].name;
      reqPayload.Id = props.data.Id;
      delete reqPayload.isActive;
      delete reqPayload.sameAsEmail;

      console.log("reqPayload", reqPayload);

      var formdata = new FormData();
      for (var key in reqPayload) {
        formdata.append(key, reqPayload[key]);
      }
      updateCust(formdata).then(
        (res) => {
          console.log("res", res);

          toast.success("Organisation updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          props.toggle8();

          setTimeout(() => {
            props.CallByFetchCust();
          }, 200);
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal8}
        toggle={props.toggle8}
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={props.toggle8} className="bg-primary-blue">
          {"Update Organisation"}
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Name"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Organisation Name*"
                    name="name"
                    {...register("name")}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Email"}</label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register("email")}
                    defaultValue={props.data?.email}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Admin Email"}</label>
                  <input
                    className={
                      errors.adminEmail
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="email"
                    name="adminEmail"
                    placeholder="Admin Email Address*"
                    {...register("adminEmail")}
                    defaultValue={props.data?.adminEmail}
                  />

                  <span className="text-danger">
                    {errors.adminEmail?.message}
                  </span>

                  <div className="checkbox p-0">
                    <input
                      id="solid3"
                      {...register("sameAsEmail")}
                      name="sameAsEmail"
                      type="checkbox"
                    />
                    <label htmlFor="solid3" className="mb-0">
                      {"Same as email"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Website"}</label>
                  <input
                    className={
                      errors.website
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Customer Website*"
                    name="website"
                    {...register("website")}
                    defaultValue={props.data?.website}
                  />

                  <span className="text-danger">{errors.website?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Organisation Type"}</label>

                  <Controller
                    name="customerType"
                    control={control}
                    defaultValue={props.data?.customerType}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        defaultValue={props.data?.customerType}
                        className={
                          errors.customerType
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      >
                        <option select="">{"--Select*--"}</option>
                        {/* <option value="Owner">{"Owner"}</option> */}
                        {custTypeList.data.map((val, i) => (
                          <option value={val.name} key={i}>
                            {val.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />

                  {/* <Controller
                                        control={control}
                                        name="customerType"
                                        defaultValue={props.data?.customerType}

                                        render={({ onChange, value }) => (
                                            <select
                                                onChange={onChange}
                                                selected={value}
                                                defaultValue={props.data?.customerType}
                                                className={errors.customerType ? "form-control is-invalid" : "form-control"}>

                                                <option select="">{"--Select--"}</option>
                                                
                                                {custTypeList.data.map((val, i) => (
                                                    <option value={val.name} key={i}>{val.name}</option>
                                                ))}

                                            </select>
                                        )}
                                    /> */}

                  <span className="text-danger">
                    {errors.customerType?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Address"}</label>

                  <textarea
                    className={
                      errors.address
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Customer Address*"
                    name="address"
                    {...register("address")}
                    rows="1"
                    defaultValue={props.data?.address}
                  ></textarea>

                  <span className="text-danger">{errors.address?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Country"}</label>

                  <Controller
                    name="country"
                    control={control}
                    defaultValue={[{ name: props.data?.country }]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={countryList.data}
                        isLoading={countryList.state}
                        placeholder="--Select*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.country ? true : false}
                        defaultValue={[{ name: props.data?.country }]}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.country?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"State"}</label>

                  <Controller
                    name="state"
                    control={control}
                    defaultValue={
                      props.data !== undefined
                        ? props.data.state !== null ||
                          props.data.state !== undefined
                          ? [{ name: props.data?.state }]
                          : []
                        : []
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={stateList.data}
                        isLoading={stateList.state}
                        placeholder="--Select--"
                        onChange={onChange}
                        // onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.state ? true : false}
                        onFocus={() => setStateTypeahead(true)}
                        onBlur={() => setStateTypeahead(false)}
                        onKeyDown={() => setStateTypeahead(true)}
                        open={stateTypeahead}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.state?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"City"}</label>

                  <Controller
                    name="city"
                    control={control}
                    defaultValue={
                      props.data !== undefined
                        ? props.data.city !== null ||
                          props.data.city !== undefined
                          ? [{ name: props.data?.city }]
                          : []
                        : []
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={cityList.data}
                        allowNew={true}
                        isLoading={cityList.state}
                        placeholder="--Select--"
                        // onChange={() => { onChange(); setCityTypeahead(false) }}
                        onChange={onChange}
                        selected={value}
                        isInvalid={errors.city ? true : false}
                        onFocus={() => setCityTypeahead(true)}
                        onBlur={() => setCityTypeahead(false)}
                        onKeyDown={() => setCityTypeahead(true)}
                        open={cityTypeahead}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.city?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">Logo</label>

                  <input
                    className={
                      errors.logo ? "form-control is-invalid" : "form-control"
                    }
                    type={"file"}
                    {...register("logo")}
                  />

                  <span className="text-danger">{errors.logo?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">
                    {"Contact Number - "}
                    {`Old: ${props.data?.contactNumber}`}
                  </label>
                  <div className="row ">
                    <div className="col-12 col-md-3 pe-0">
                      <Controller
                        name="countryCode"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            name="countryCode"
                            id="basic-typeahead"
                            labelKey="phone_code"
                            multiple={false}
                            options={countryList.data}
                            isLoading={countryList.state}
                            placeholder="--Select*--"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.countryCode ? true : false}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-9 ps-1">
                      <input
                        className={
                          errors.contactNumber
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        type="text"
                        placeholder="Customer Contact*"
                        name="contactNumber"
                        {...register("contactNumber")}
                      />
                    </div>
                  </div>

                  <span className="text-danger">
                    {errors.contactNumber?.message}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Update Org"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default EditCustForm;
