import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import {
  getCustomers,
  getSubscriptions,
  createCustomerSubscription,
} from "../../../../api/index";
import { convertDateYYMMDD } from "../../Common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    customer: yup.array().nullable().required("Customer is required !"),
    startDate: yup.string().nullable().required("Start date is required !"),
    endDate: yup.string().nullable().required("End date is required !"),
    subscriptionPlan: yup
      .array()
      .nullable()
      .required("Subscription plan is required !"),
    // status: yup.string().nullable().required('Status is required !'),
  })
  .required();

function CreateNewCustSubscription(props) {
  const [modal8, setModal8] = useState();
  const [customerList, setCustomerList] = useState({
    data: [],
    loading: false,
  });
  const [subscriptionList, setSubscriptionList] = useState({
    data: [],
    loading: false,
  });
  const [minEndDate, setMinEndDate] = useState({ date: "" });

  const { register, handleSubmit, formState, control, watch, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        startDate: new Date(),
        endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      },
    }); // initialise the hook

  let { errors } = formState;

  const toggle8 = () => {
    setModal8(!modal8);

    // resetting the form
    reset();

    if (modal8 === undefined || modal8 === true) {
      setCustomerList({ ...customerList, data: [], loading: false });

      setSubscriptionList({ ...customerList, data: [], loading: true });

      getSubscriptions({ pageNo: 1, entriesPerPage: 10000 }).then(
        (res) => {
          console.log("res", res);

          if (res.data.res.length !== 0) {
            const approvedList = res.data.res.filter((val) => {
              return val.status !== "Pending";
            });

            console.log(approvedList);

            setSubscriptionList({
              ...customerList,
              data: approvedList,
              loading: false,
            });
          } else {
            setSubscriptionList({ ...customerList, data: [], loading: false });
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  const sd = watch("startDate");
  useEffect(() => {
    console.log("sd", sd);
    minEndDate.date = sd;
    setMinEndDate({ ...minEndDate, date: minEndDate.date });
    if (sd !== undefined) {
      // console.log(new Date(new Date(sd).getTime() + (24 * 60 * 60 * 1000)));
      setValue(
        "endDate",
        new Date(new Date(sd).setFullYear(new Date().getFullYear() + 1))
      );
    }
  }, [sd]);

  // searching customer i.e organisation
  const searchCustomer = (e) => {
    console.log("e", e);

    if (e.length > 0) {
      setCustomerList({ ...customerList, data: [], loading: true });

      getCustomers({ pageNo: 1, entriesPerPage: 10, searchVal: e }).then(
        (res) => {
          console.log("res", res);
          setCustomerList({
            ...customerList,
            data: res.data.res,
            loading: false,
          });
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      setCustomerList({ ...customerList, data: [], loading: false });
    }
  };

  // submitting form values
  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const reqPayload = {
        customer_id: data.customer[0].Id,
        plan_id: data.subscriptionPlan[0].Id,
        startDate: convertDateYYMMDD(data.startDate, "-"),
        endDate: convertDateYYMMDD(data.endDate, "-"),
      };
      console.log("reqPayload", reqPayload);
      createCustomerSubscription(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("Subscription Created Successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          toggle8();
          // props.fetchCustSubscription("refresh");
          setTimeout(() => {
            props.fetchCustSubscription("refresh");
          }, 200);
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went weong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      {/* required for toaster */}
      <ToastContainer />

      <button
        style={{ fontSize: "16px" }}
        onClick={toggle8}
        className="btn btn-primary-blue float-right"
      >
        New Subscription
      </button>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          <p className="f-22 f-w-400 mb-0">{"Create New Subscription"}</p>
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Organisation"}</label>

                  <Controller
                    name="customer"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        options={customerList.data}
                        isLoading={customerList.loading}
                        multiple={false}
                        placeholder="--Search*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.customer ? true : false}
                        onInputChange={(e) => searchCustomer(e)}
                      />
                    )}
                  />

                  {/* <Controller
                                        as={Typeahead}
                                        control={control}
                                        name="customer"
                                        // {...register("state")}
                                        rules={{ required: true }}
                                        id="basic-typeahead"
                                        labelKey={"name"}
                                        options={customerList.data}
                                        isLoading={customerList.loading}
                                        multiple={false}
                                        placeholder="--Search*--"
                                        isInvalid={errors.customer ? true : false}
                                        defaultValue={''}
                                        onInputChange={(e) => searchCustomer(e)}
                                    /> */}

                  <span className="text-danger">
                    {errors.customer?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Subscription Plan"}</label>

                  <Controller
                    name="subscriptionPlan"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        options={subscriptionList.data}
                        isLoading={subscriptionList.loading}
                        multiple={false}
                        placeholder="--Search*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.subscriptionPlan ? true : false}
                      />
                    )}
                  />

                  {/* <Controller
                                        as={Typeahead}
                                        control={control}
                                        name="subscriptionPlan"
                                        // {...register("state")}
                                        rules={{ required: true }}
                                        id="basic-typeahead"
                                        labelKey="name"
                                        options={subscriptionList.data}
                                        isLoading={subscriptionList.loading}
                                        multiple={false}
                                        placeholder="--Select*--"
                                        isInvalid={errors.subscriptionPlan ? true : false}
                                        defaultValue={''}
                                    /> */}

                  <span className="text-danger">
                    {errors.subscriptionPlan?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Start Date"}</label>

                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        className={
                          errors.startDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                          )
                        }
                        // maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.startDate?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"End Date"}</label>

                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        className={
                          errors.endDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={new Date(minEndDate.date)}
                        // maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  {/* <Controller
                                        control={control}
                                        name="endDate"
                                        defaultValue={null}

                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                className={errors.endDate ? "form-control is-invalid" : "form-control"}
                                                placeholderText="Select date"
                                                onChange={onChange}
                                                selected={value}
                                                minDate={new Date(minEndDate.date)}
                                                // maxDate={new Date()}
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        )}
                                    /> */}

                  <span className="text-danger">{errors.endDate?.message}</span>
                </div>
              </div>

              {/* <div className='col-12 col-md-6'>
                                <div className="form-group mb-2">
                                    <label className='f-w-600'>{"Status"}</label>

                                    <Controller
                                        control={control}
                                        name="status"
                                        defaultValue={null}

                                        render={({ onChange, value }) => (
                                            <select
                                                onChange={onChange}
                                                selected={value}
                                                className={errors.status ? "custom-select form-control is-invalid" : "custom-select form-control"}>
                                                <option select="">{"--Select--"}</option>
                                                <option defaultValue="Active">{"Active"}</option>
                                                <option defaultValue="Inactive">{"Inactive"}</option>
                                            </select>
                                        )}
                                    />

                                    <span className='text-danger'>{errors.status?.message}</span>
                                </div>
                            </div> */}
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Create Subscription"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default CreateNewCustSubscription;
