import React, { useState, useEffect } from "react";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal, } from "react-bootstrap";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  getAllRoles,
  getVessels,
  updateCustomersUser,
} from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { getAllCountries } from "../../../../api";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(70, "70 characters allowed !")
      .required("Name is required !"),
    username: yup
      .string()
      .max(50, "50 characters allowed !")
      .required("Username is required !"),
    email: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    // phone: yup
    // .string()
    // .matches(/^[0-9]+$/, "Phone number should be numeric value")
    // //.required("Phone is required !"),
    //vessel_id: yup.array().min(1, "Please Select Vessel !").required('Please Select Vessel !'),
    //countryCode: yup.array().nullable().required('Country Code is required !'),
    status: yup
      .string()
      .nullable()
      .required("Status is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    role_id: yup
      .array()
      .min(1, "Please Select Role !")
      .required("Please Select Role !"),
  })
  .required();

function EditUserForm(props) {
  const [rolesList, setRolesList] = useState({ data: [], loading: false });
  const [vesselList, setVesselList] = useState({ data: [], loading: false });
  const customer_id = localStorage.getItem("customer_id");
  const [guestSelected, setGuestSelected] = useState(false);
  const [countryList, setCountryList] = useState({ data: [], state: false });

  const { register, handleSubmit, formState, control, reset, watch, setError,setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  const role = watch("role_id");

  let { errors } = formState;

  React.useEffect(() => {
    console.log("role", role);
    if (role !== undefined && role.length > 0) {
      if (role[0].name == "Guest") {
        setGuestSelected(true);
        console.log("true");
      } else {
        console.log("false");
        setGuestSelected(false);
      }
    }
  }, [role]);

  useEffect(() => {
    setRolesList({ ...rolesList, data: [], loading: true });

    getAllRoles().then(
      (res) => {
        console.log("res", res);
        let temp = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].name == "SiteSurveyor") {
            res.data[i].name = "SiteCoordinator";
            temp.push(res.data[i]);
          } else {
            temp.push(res.data[i]);
          }
        }
        setRolesList({ ...rolesList, data: temp, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );

    setVesselList({ ...vesselList, data: [], loading: true });
    getVessels({ customer_id: customer_id }).then(
      (res) => {
        console.log("res", res);
        setVesselList({ ...vesselList, data: res.data.res, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  useEffect(() => {
   // console.log("props.modal8", props.modal8);
    console.log("props",props)
    if (props.modal8 === true) {
      reset();


      setValue("role_id",props.data?.role?[
        {
          name: props.data.role.name,
          Id: props.data.role.Id,
        },
      ]:[])
      setValue("status",props.data?.status);
      setValue("vessel_id",props.data?.vessel?[
        {
          name: props.data.vessel.name,
          Id: props.data.vessel.Id,
        },
      ]:[])

      setCountryList({ ...countryList, state: true, data: [] });
      getAllCountries().then(
        (res) => {
          console.log("res", res);
          let arr=res.data.map((item)=>{
            item.label=item.phone_code+" "+item.name;
            return item;
       });
          setCountryList({ ...countryList, state: false, data: arr });
        },
        (err) => {
          console.log("err", err);
          setCountryList({ ...countryList, state: false, data: [] });
          toast.error("Something went wrong in fetching countries !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    }
  }, [props.modal8]);

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      
      if (data.role_id[0].name !== "Guest") {

        if (data.vessel_id == undefined || data.vessel_id.length < 1) {
          setError("vessel_id", {
            type: "custom",
            message: "Please Select Vessel !",
          });
          return;
        }




        data.Id = props.data.Id;
        data.role_id = data.role_id[0].Id;
        data.vessel_id = data.vessel_id[0].Id;
        data.status = data.status;
        data.customer_id = customer_id;

        if (data.countryCode !== undefined) {
          data.phone = data.countryCode[0].phone_code + data.phone;
          delete data.countryCode;
        } else {
          data.phone = props.data.phone;
          delete data.countryCode;
        }

        console.log("data", data);
        
          updateCustomersUser(data).then(
            (res) => {
              console.log("res", res);

              toast.success("User updated successfully !", {
                autoClose: 3000,
                theme: "light",
              });
              props.toggle8();

              props.fetchUsers();
            },
            (err) => {
              console.log("err", err);
              toast.error("Something went wrong !", {
                autoClose: 3000,
                theme: "light",
              });
            }
          );
        
      } else {
        delete data.vessel_id;
        data.vessel=null;
        data.Id = props.data.Id;
        data.role_id = data.role_id[0].Id;
        data.status = data.status;
        data.customer_id = customer_id;

        if (data.countryCode !== undefined) {
          data.phone = data.countryCode[0].phone_code + data.phone;
          delete data.countryCode;
        } else {
          data.phone = props.data.phone;
          delete data.countryCode;
        }

        console.log("data", data);
        
        updateCustomersUser(data).then(
          (res) => {
            console.log("res", res);

            toast.success("User updated successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            props.toggle8();

            props.fetchUsers();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }

    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
       // isOpen={props.modal8}
        toggle={props.toggle8}
        show={props.modal8}
         onHide={() =>props.toggle8() }  
        size="lg"
        backdrop="static"
      >
        <Modal.Header className="bg-primary-blue" closeButton>
        <Modal.Title ><p className="f-22 f-w-400 mb-0">{"Update User"}</p></Modal.Title>  
        </Modal.Header>

        <Modal.Body>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Name"}</label>
                  <input
                    className={
                      errors.name
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    name="name"
                    placeholder="Enter Name*"
                    {...register("name")}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Username"}</label>
                  <input
                    className={
                      errors.username
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter Username*"
                    name="username"
                    {...register("username")}
                    defaultValue={props.data?.username}
                  />

                  <span className="text-danger">
                    {errors.username?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Email"}</label>
                  <input
                    className={
                      errors.email
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="email"
                    name="email"
                    placeholder="Enter Email*"
                    {...register("email")}
                    defaultValue={props.data?.email}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{`Phone - Old : ${props.data?.phone}`}</label>
                  <div className="row">
                    <div className="col-12 col-md-4 pe-0">
                      <Controller
                        name="countryCode"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            name="countryCode"
                            id="basic-typeahead"
                            labelKey='label'
                            multiple={false}
                            options={countryList.data}
                            isLoading={countryList.state}
                            placeholder="Country Code"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.countryCode ? true : false}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-8 ps-1">
                      <input
                        className={
                          errors.phone
                            ? "form-control is-invalid f-16"
                            : " f-16 form-control"
                        }
                        type="text"
                        name="phone"
                        placeholder="Enter Phone No*"
                        {...register("phone")}
                      // defaultValue={props.data?.phone}
                      />
                    </div>
                  </div>

                  <span className="text-danger">{errors.phone?.message}</span>
                </div>
              </div>

             

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Role"}</label>
                  <Controller
                    name="role_id"
                    control={control}
                    defaultValue={
                      props.data?.role === null ||
                        props.data?.role === undefined
                        ? []
                        : [
                          {
                            name: props.data.role.name,
                            Id: props.data.role.Id,
                          },
                        ]
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={rolesList.data}
                        loading={rolesList.loading}
                        placeholder="Select Role*"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        defaultSelected={
                          props.data?.role === null ||
                            props.data?.role === undefined
                            ? []
                            : [
                              {
                                name: props.data.role.name,
                                Id: props.data.role.Id,
                              },
                            ]
                        }
                        isInvalid={errors.role_id ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.role_id?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Status"}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue={props.data?.status}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={props.data?.status}
                        onChange={onChange}
                        selected={value}
                        className={
                          errors.status
                            ? "custom-select form-control is-invalid f-16"
                            : " f-16 custom-select form-control"
                        }
                      >
                        <option select="">{"Select*"}</option>
                        <option value="Active">{"Active"}</option>
                        <option value="Inactive">{"Inactive"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>
                {guestSelected==false? <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Vessel"}</label>

                  <Controller
                    name="vessel_id"
                    control={control}
                    defaultValue={
                      props.data?.vessel === null ||
                        props.data?.vessel === undefined
                        ? []
                        : [
                          {
                            name: props.data.vessel.name,
                            Id: props.data.vessel.Id,
                          },
                        ]
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={vesselList.data}
                        loading={vesselList.loading}
                        placeholder="Select Vessel*"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        defaultSelected={
                          props.data?.vessel === null ||
                            props.data?.vessel === undefined
                            ? []
                            : [
                              {
                                name: props.data.vessel.name,
                                Id: props.data.vessel.Id,
                              },
                            ]
                        }
                        isInvalid={errors.vessel_id ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.vessel_id?.message}
                  </span>
                </div>
              </div>:<></>}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            style={{ fontSize: "16px " }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Save Changes"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditUserForm;
