import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import AddChecklistItems from "./AddChecklistItems";
import { Trash2, Edit } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { updateCheckList } from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { deleteCheckListItem, getCheckListById } from "../../../../api/index";
import SweetAlert from "sweetalert2";
import { Link, useLocation } from "react-router-dom";
import EditChecklistItem from "./EditChecklistItem";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Name is required !"),
    description: yup.string().required("Description is required !"),
    // checklistLineItems: yup.array().of(
    //     yup.object().shape({
    //         SrNo: yup.string()
    //             .required('Required !'),
    //         section: yup.string()
    //             .required('Required !'),
    //         comments: yup.string().nullable()
    //             .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    //         photos: yup.string().nullable()
    //             .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    //         videos: yup.string().nullable()
    //             .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    //         OG: yup.string().nullable()
    //             .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    //     })
    // )
  })
  .required();

function EditCheckListForm(props) {
  let location = useLocation();

  const oldCheckListData = location.state;
  const customer_id = localStorage.getItem("customer_id");

  const [itemUpdateData, setItemUpdateData] = useState({ data: {} });
  const [checkListdata, setCheckListData] = useState({
    data: [],
    loading: false,
  });
  const [delItemApiFire, setDelItemApiFire] = useState(false);
  const [modal8, setModal8] = useState();
  const toggle8 = () => {
    setModal8(!modal8);
  };

  const { register, handleSubmit, formState, control, reset, getValues } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        // name: checkListData.checklist.name, description: checkListData.checklist.description,
        // checklistLineItems: checkListData.checklistLineItems
      },
    });

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "checklistLineItems",
  });

  useEffect(() => {
    console.log("props", location.state);

    fetchCheckListData();
  }, []);

  const delCheckListItem = (val) => {
    console.log("val", val);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteCheckListItem({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Check List Item Deleted Successfully !", {
              autoClose: 2000,
              theme: "light",
            });
            fetchCheckListData();
            setDelItemApiFire(true);
          },
          (err) => {
            toast.error("Something went wrong, please try again !", {
              autoClose: 3000,
              theme: "light",
            });
            console.log("err", err);
          }
        );
      }
    });
  };

  function fetchCheckListData() {
    setCheckListData({ ...checkListdata, data: [], loading: true });
    getCheckListById({ Id: location.state.checklist.Id }).then(
      (res) => {
        console.log("res", res);

        setCheckListData({ ...checkListdata, data: res.data, loading: false });
        reset({
          name: res.data.checklist.name,
          description: res.data.checklist.description,
          checklistLineItems: res.data.checklistLineItems.map((val) => {
            return {
              Id: val.Id,
              SrNo: val.SrNo,
              section: val.section,
              comments: val.comments,
              photos: val.photos,
              videos: val.videos,
              OG: val.OG,
            };
          }),
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const reqPayload = {
        Id: checkListdata.data.checklist.Id,
        checklist: {
          name: data.name,
          description: data.description,
          customer_id: customer_id,
        },
        checklistLineItems: data.checklistLineItems,
      };

      reqPayload.checklistLineItems.map((val, i) => {
        if (val.Id.length === 0) {
          delete val.Id;
        }
      });

      console.log("reqPayload", reqPayload);

      // Api call

      updateCheckList(reqPayload).then(
        (res) => {
          console.log("res", res);

          toast.success("Checklist Updated Successfully !", {
            autoClose: 3000,
            theme: "light",
          });

          window.history.back();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <EditChecklistItem
        // updateItemApiIsCalled={updateItemApiIsCalled}
        itemUpdateData={itemUpdateData}
        modal8={modal8}
        toggle8={toggle8}
        getValues={getValues}
        checkListdata={checkListdata.data}
        fetchCheckListData={fetchCheckListData}
      />

      <div className="vdi_checklist py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  <p className="mb-0 f-24">Update Checklist</p>
                </div>
                {checkListdata.loading === true ? (
                  <div className="loader-box justify-content-center">
                    <div className="loader">
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    {/* <p className='f-18 f-w-600 mb-0'>Update Checklist</p>
                                            <hr className='mb-3 mt-0' /> */}
                    <form className="">
                      <div className="row">
                        <div className="col-12 col-md-5">
                          <div className="form-group">
                            <label className="f-w-600">{"Name"}</label>
                            <input
                              className={
                                errors.name
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              type="text"
                              placeholder="Enter checklist name*"
                              name="name"
                              {...register("name")}
                            />

                            <span className="text-danger">
                              {errors.name?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-5">
                          <div className="form-group">
                            <label className="f-w-600">{"Description"}</label>
                            <input
                              className={
                                errors.description
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              type="text"
                              placeholder="Enter checklist description*"
                              name="description"
                              {...register("description")}
                            />

                            <span className="text-danger">
                              {errors.description?.message}
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="row">
                      <div className="col-12 mt-4">
                        <hr />
                        <div className="d-flex justify-content-between">
                          <p className="f-18 f-w-600 mb-0">
                            Add Checklist Items
                          </p>

                          <AddChecklistItems
                            // addItemApiIsCalled={addItemApiIsCalled}
                            getValues={getValues}
                            checkListdata={checkListdata.data}
                            fetchCheckListData={fetchCheckListData}
                          />
                        </div>
                        <hr />

                        <div className="table-responsive ">
                          <table className="table table-bordered">
                            <thead className="thead-light">
                              <tr className="f-16">
                                <th scope="col">{"Sr.No"}</th>
                                <th scope="col">{"Section"}</th>
                                <th scope="col">{"Comments"}</th>
                                <th scope="col">{"Photo"}</th>
                                <th scope="col">{"Video"}</th>
                                <th scope="col">{"OG"}</th>
                                <th scope="col">{"Action"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map((field, index) => (
                                <tr key={field.id} className="f-18">
                                  {/* Following code is logic for retrieving id of already present checklist items */}
                                  <td className="d-none">
                                    <input
                                      type="text"
                                      name={`checklistLineItems[${index}].Id`}
                                      {...register(
                                        `checklistLineItems[${index}].Id`
                                      )}
                                      defaultValue={field.Id}
                                    />
                                  </td>

                                  <td scope="row" style={{ width: "100px" }}>
                                    <input
                                      className={"form-control"}
                                      type="text"
                                      name={`checklistLineItems[${index}].SrNo`}
                                      {...register(
                                        `checklistLineItems[${index}].SrNo`
                                      )}
                                      placeholder="SrNo"
                                      defaultValue={field.SrNo}
                                      disabled
                                    />
                                  </td>

                                  <td>
                                    <textarea
                                      className={"form-control"}
                                      type="text"
                                      {...register(
                                        `checklistLineItems[${index}].section`
                                      )}
                                      name={`checklistLineItems[${index}].section`}
                                      placeholder="Enter Section"
                                      defaultValue={field.section}
                                      rows="1"
                                      disabled
                                    ></textarea>
                                  </td>

                                  <td>
                                    <Controller
                                      control={control}
                                      name={`checklistLineItems[${index}].comments`}
                                      defaultValue={field.comments}
                                      render={({ onChange, value }) => (
                                        <select
                                          disabled
                                          defaultValue={field.comments}
                                          {...register(
                                            `checklistLineItems[${index}].comments`
                                          )}
                                          onChange={onChange}
                                          selected={value}
                                          name={`checklistLineItems[${index}].comments`}
                                          className={"form-control"}
                                        >
                                          <option select="">
                                            {"--Select*--"}
                                          </option>
                                          <option defaultValue="Yes">
                                            {"Yes"}
                                          </option>
                                          <option defaultValue="No">
                                            {"No"}
                                          </option>
                                        </select>
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`checklistLineItems[${index}].photos`}
                                      defaultValue={field.photos}
                                      render={({ onChange, value }) => (
                                        <select
                                          disabled
                                          defaultValue={field.photos}
                                          {...register(
                                            `checklistLineItems[${index}].photos`
                                          )}
                                          name={`checklistLineItems[${index}].photos`}
                                          onChange={onChange}
                                          selected={value}
                                          className={"form-control"}
                                        >
                                          <option select="">
                                            {"--Select*--"}
                                          </option>
                                          <option defaultValue="Yes">
                                            {"Yes"}
                                          </option>
                                          <option defaultValue="No">
                                            {"No"}
                                          </option>
                                        </select>
                                      )}
                                    />
                                  </td>

                                  <td>
                                    <Controller
                                      control={control}
                                      name={`checklistLineItems[${index}].videos`}
                                      defaultValue={field.videos}
                                      render={({ onChange, value }) => (
                                        <select
                                          disabled
                                          defaultValue={field.videos}
                                          {...register(
                                            `checklistLineItems[${index}].videos`
                                          )}
                                          name={`checklistLineItems[${index}].videos`}
                                          onChange={onChange}
                                          selected={value}
                                          className={"form-control"}
                                        >
                                          <option select="">
                                            {"--Select*--"}
                                          </option>
                                          <option defaultValue="Yes">
                                            {"Yes"}
                                          </option>
                                          <option defaultValue="No">
                                            {"No"}
                                          </option>
                                        </select>
                                      )}
                                    />
                                  </td>

                                  <td>
                                    <Controller
                                      control={control}
                                      name={`checklistLineItems[${index}].OG`}
                                      defaultValue={field.OG}
                                      render={({ onChange, value }) => (
                                        <select
                                          disabled
                                          defaultValue={field.OG}
                                          {...register(
                                            `checklistLineItems[${index}].OG`
                                          )}
                                          name={`checklistLineItems[${index}].OG`}
                                          onChange={onChange}
                                          selected={value}
                                          className={"form-control"}
                                        >
                                          <option select="">
                                            {"--Select*--"}
                                          </option>
                                          <option defaultValue="Yes">
                                            {"Yes"}
                                          </option>
                                          <option defaultValue="No">
                                            {"No"}
                                          </option>
                                        </select>
                                      )}
                                    />
                                  </td>

                                  <td>
                                    <Edit
                                      style={{ color: "#313131" }}
                                      id={"editToolTip" + index}
                                      className="cursor_pointer"
                                      height={"18px"}
                                      width={"18px"}
                                      onClick={() => {
                                        toggle8();
                                        itemUpdateData.data = field;
                                        setItemUpdateData({
                                          ...itemUpdateData,
                                          data: itemUpdateData.data,
                                        });
                                      }}
                                    />
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={"editToolTip" + index}
                                    >
                                      {"Edit Record"}
                                    </UncontrolledTooltip>

                                    <Trash2
                                      id={"deleteToolTip" + index}
                                      className={
                                        index > 0
                                          ? "cursor_pointer"
                                          : "cursor_banned"
                                      }
                                      onClick={
                                        index > 0
                                          ? index + 1 >
                                            checkListdata.data
                                              .checklistLineItems.length
                                            ? () => remove(index)
                                            : () => delCheckListItem(field)
                                          : null
                                      }
                                      height={"18px"}
                                      width={"18px"}
                                    />
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={"deleteToolTip" + index}
                                    >
                                      {"Delete Record"}
                                    </UncontrolledTooltip>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-end">
                      <button
                        onClick={handleSubmit(onSubmit)}
                        style={{ fontSize: "16px " }}
                        className="btn btn-primary-blue"
                      >
                        {"Update Checklist"}
                      </button>

                      {delItemApiFire ? (
                        <Link
                          to={`${process.env.PUBLIC_URL}/customeradmin/vdi_checklist`}
                        >
                          <button
                            style={{ fontSize: "16px " }}
                            className="btn btn-dark ms-2"
                          >
                            {"Done"}
                          </button>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditCheckListForm;
