import { CLOSE, OPEN, OPEN_CLOSE } from "../actionTypes";

const initial_state = {
  open: false,
};

const OpenClose = (state = initial_state, action) => {
  switch (action.type) {
    case OPEN_CLOSE:
      if (!state.open) {
        document.querySelector(".page-main-header").classList.remove("open");
        document.querySelector(".page-sidebar").classList.remove("open");
      } else {
        document.querySelector(".page-main-header").classList.add("open");
        document.querySelector(".page-sidebar").classList.add("open");
      }
      return { ...state, open: !state.open };

    case OPEN:
      return { ...state, open: true };

    case CLOSE:
      return { ...state, open: false };

    default:
      return { ...state };
  }
};

export default OpenClose;
