import React, { Fragment, useEffect, useState, useRef } from "react";
import Rating from "react-rating";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Accordion,
  Collapse,
  Media,
} from "reactstrap";
import html2canvas from "html2canvas";
import pdf from "../../../../../../assets/images/Vedam/pdf.png";
import word from "../../../../../../assets/images/Vedam/word.png";
import xls from "../../../../../../assets/images/Vedam/xls.png";
import SweetAlert from "sweetalert2";
// import { Col, Row } from "reactstrap";
import {
  axiosPostData,
  axiosPutData,
  deleteApi,
  getData,
  postData,
} from "../../../../../../api/APIinstance";
import { generateUserId } from "../../../../../ProjectComponents/Common/Functions/CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { WATCH_SURVEY_LIST } from "../../../../../../redux/actionTypes";
import { Button } from "react-bootstrap";
import Loader from "../../../../../common/loader";
import AddPhotosVideos from "./addPhotosVideos";
import { toast } from "react-toastify";
import {
  uploadChecklistItemAttachment,
  getChecklistItemAttachment,
} from "../../../../../../api/index";

const CheckListResult = ({
  setIsOpen1,
  Id,
  d,
  surveyDetail,
  setSaved,
  isSelected,
  isOpen1,
}) => {
  // const appData2 = useSelector((content) => content.LoginApp.login);

  const [screenWidth, setScreenWidth] = useState();
  const [photos, setPhotos] = useState([]);
  const [referenceLoader, setReferenceLoader] = useState(false);
  const [Attachedphotos, setAttachedphotos] = useState([]);
  const [AttachedVideos, setAttachedVideos] = useState([]);
  const [rating, setRating] = useState();
  const [comments, setComments] = useState();
  const [loader, setLoader] = useState(false);
  const [videos, setVideos] = useState([]);
  const [accordOpen, setAccordOpen] = useState(false);
  const [doc, setDoc] = useState([]);
  const [modal, setModal] = useState();
  const [linkRecordingModal, setLinkRecordingModal] = useState();
  const [previewModal, setPreviewModal] = useState();
  const [referenceModal, setreferenceModal] = useState();
  const [previewPhotoModal, setPreviewPhotoModal] = useState();
  const [previewVideoModal, setPreviewVideoModal] = useState();
  const [referenceModalPhoto, setreferenceModalPhoto] = useState();
  const [referenceModalVideo, setreferenceModalVideo] = useState();
  const [checklists, setChecklist] = useState();
  const [recordingVideos, setRecordingVideos] = useState([]);
  const [linkRecordingVideos, setLinkRecordingVideos] = useState([]);
  const [selectedRecordedVideo, setSelectedRecordedVideo] = useState();
  const [addedRecordingVideos, setAddedRecordingVideos] = useState();
  const [photosVideos, setPhotosVideos] = useState([]);
  const [preview, setPreview] = useState([]);
  const [photosFulfilled, setphotosFulfilled] = useState(false);
  const [videosFulfilled, setvideosFulfilled] = useState(false);
  const [isCommentMand, setIsCommentMand] = useState(false);

  const [selectedPhotoFile, setSelectedPhotoFile] = useState(null);
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);

  const myRef = React.useRef(null);

  React.useEffect(() => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          console.log("position.coords.latitude", position.coords.latitude);
          console.log("position.coords.longitude", position.coords.longitude);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setStatus("Unable to retrieve your location");
        }
      );
    }
  }, []);

  const executeScroll = () => {
    console.log("m there");
    myRef.current.scrollIntoView();
    myRef.current.focus();
  };

  console.log(
    "selectedRecordedVideo===================================================",
    selectedRecordedVideo
  );

  const linkRecordingtoChecklist = async (event) => {
    let params = {
      checkListItem: d?.Id,
      sid: selectedRecordedVideo?.sid,
      userId: Math.floor((Date.now() * Math.random()) / 10000),
      survey: id,
      stopped_at: selectedRecordedVideo?.stopped_at,
      url: selectedRecordedVideo?.url,
    };
    let response = await postData("SurveyRecordings/", params);
    if (response) {
      console.log(response);
      // dispatch({ type: WATCH_SURVEY_LIST });
      let resFromRecording = await getData(
        `SurveyRecordings/?survey=${id}&checkListItem=${d.Id}`
      );

      const tempRes = resFromRecording.filter((val) => {
        return val.checkListItem === d.Id;
      });
      console.log("tempRes", tempRes);

      setAddedRecordingVideos(tempRes);
      toggle5(event);
    }
  };

  const toggle2 = (event) => {
    setModal(!modal);
    event.stopPropagation();
  };
  const toggle5 = (event) => {
    setLinkRecordingModal(!linkRecordingModal);
    event.stopPropagation();
  };
  const toggle4 = (event) => {
    if (previewModal) {
      setPreviewPhotoModal("");
      setPreviewVideoModal("");
    }
    setPreviewModal(!previewModal);
    event.stopPropagation();
  };
  const toggle3 = (event) => {
    if (referenceModal) {
      setreferenceModalVideo("");
      setreferenceModalPhoto("");
    }
    setreferenceModal(!referenceModal);
    event.stopPropagation();
  };

  const { id } = useParams();

  let rat = JSON.parse(surveyDetail.SOP.methodology.values);

  const dispatch = useDispatch();

  const handleAccordNext = () => {
    setLoader(true);
    let a = Id.indexOf(d.Id);
    if (a != Id.length - 1) {
      setIsOpen1(Id[a + 1]);
    } else {
      setIsOpen1(null);
    }
    setSaved({
      comments: comments,
      rating: rating,
      videos: videos,
      photos: photos,
      doc: doc,
    });

    let userId = JSON.parse(localStorage.getItem("login_data"));

    let params = {
      checkListItem: d.Id,
      values: rating,
      remarks: comments,
      createdBy: localStorage.getItem("vdi_User_Id"),
      survey: id,
    };

    let response = postData("CheckListOutcome/", params);
    if (response) {
      dispatch({ type: WATCH_SURVEY_LIST });
      toast.success("Checklist Answer Submitted Successfully...", {
        autoClose: 3000,
        theme: "light",
        
      });
    }
    setLoader(false);
  };

  const handleAccordPrev = () => {
    let a = Id.indexOf(d.Id);
    if (a != 0) {
      setIsOpen1(Id[a - 1]);
    } else {
      setIsOpen1(null);
    }
  };

  // const selectedRecordingVideo = (item) => {
  //    if (linkRecordingVideos?.includes(item)) {
  //      //  let a = linkRecordingVideos?.filter((el) => el.Id != item.Id);
  //      setSelectedRecordedVideo(item);
  //    }
  // }

  const handleAddingPhoto = async (event) => {
    setLoader(true);

    getChecklistItemAttachment({
      survey: id,
      withSurveyOnly: "False",
      withTable: "False",
    }).then(
      (res) => {
        console.log("res", res);
        setPhotosVideos({ ...photosVideos, loading: false, data: res.data });
      },
      (err) => {
        console.log("err", err);
      }
    );

    let resFromRecording = await getData(`SurveyRecordings/?survey=${id}`);
    setRecordingVideos(resFromRecording);
    event?.stopPropagation();
    setLoader(false);
  };
  const linkRecordings = async (event) => {
    setLoader(true);
    let resFromRecording = await getData(`SurveyRecordings/?survey=${id}`);

    const tempRes = resFromRecording.filter((val) => {
      return val.checkListItem === null;
    });
    console.log("tempRes", tempRes);

    setLinkRecordingVideos(tempRes);
    event?.stopPropagation();
    setLoader(false);
  };
  useEffect(async () => {
    // console.log("Width", window.screen.width);
    setScreenWidth(window.screen.width);
    setLoader(true);
    const checklistData = await getData(
      `CheckListItemData/?checklistItemId=${d.Id}&survey=${id}`
    );
    console.log(checklistData);
    if (checklistData[0].photos == "Yes") {
      let photo = [];
      checklistData[0].check_list_attachment.map((el) => {
        if (el?.withAnswer && el.fileType == "Image") {
          photo.push(true);
        } else {
          photo.push(false);
        }
      });
      if (photo.includes(true)) {
        setphotosFulfilled(true);
      } else {
        setphotosFulfilled(false);
      }
    } else {
      setphotosFulfilled(true);
    }

    if (checklistData[0].comments == "Yes") {
      setIsCommentMand(true);
    } else {
      setIsCommentMand(false);
    }

    if (checklistData[0].videos == "Yes") {
      let video = [];
      checklistData[0].check_list_attachment.map((el) => {
        if (el?.withAnswer && el.fileType == "Video") {
          video.push(true);
        } else {
          video.push(false);
        }
      });
      if (video.includes(true)) {
        setvideosFulfilled(true);
      } else {
        setvideosFulfilled(false);
      }
    } else {
      setvideosFulfilled(true);
    }

    setChecklist(checklistData[0]);
    if (checklistData[0].checklist_item_result.length) {
      var time1 = new Date(
        checklistData[0].checklist_item_result[0].updateTimestamp
      );
      setComments(checklistData[0].checklist_item_result[0].remarks);
      setRating(checklistData[0].checklist_item_result[0].values);
      checklistData[0].checklist_item_result.map((el) => {
        var time2 = new Date(el.updateTimestamp);
        if (time2 > time1) {
          time1 = new Date(el.updateTimestamp);
          setComments(el.remarks);
          setRating(el.values);
        }
      });
    }

    let data = JSON.parse(localStorage.getItem("login_data"));
    setReferenceLoader(true);
    let response = await getData(
      `SurveyApi/?customer_id=${data.customer_id}&user=${data.user}&Id=${id}`
    );
    setReferenceLoader(false);
    let refData = "";
    response.res[0].checklist.checklist_sections.map((item) => {
      item.section_checklist_item.map((element) => {
        if (element.Id == d.Id) {
          refData = element;
        }
      });
    });
    console.log(refData);
    refData?.check_list_attachment.map((el) => {
      if (!el?.withAnswer) {
        if (el?.fileType == "Image") {
          let p = photos;
          p.push(el?.checklist_file);
          setPhotos(p);
        } else if (el?.fileType == "Video") {
          let v = videos;
          v.push(el?.checklist_file);
          setVideos(v);
        } else if (el?.fileType == "Document") {
          let a = doc;
          a.push(el?.checklist_file);
          setDoc(a);
        }
      } else {
        if (el?.fileType == "Image" || el?.fileType == "Document") {
          setAttachedphotos([...Attachedphotos, el?.checklist_file]);
        } else if (el?.fileType == "Video") {
          setAttachedVideos([...AttachedVideos, el?.checklist_file]);
        }
      }
    });
    let resFromRecording = await getData(
      `SurveyRecordings/?survey=${id}&checkListItem=${d.Id}`
    );
    console.log("resFromRecording", resFromRecording);

    const tempRes = resFromRecording.filter((val) => {
      return val.checkListItem === d.Id;
    });
    console.log("tempRes", tempRes);
    setAddedRecordingVideos(tempRes);

    setLoader(false);
  }, []);

  const AddData = async (event) => {
    setLoader(true);
    if (!photosFulfilled) {
      preview.map((el) => {
        if (el.fileType == "Image") {
          setphotosFulfilled(true);
        }
      });
    }
    if (!videosFulfilled) {
      preview.map((el) => {
        if (el.fileType == "Video") {
          setvideosFulfilled(true);
        }
      });
    }
    for (let i = 0; i < preview.length; i++) {
      let item = preview[i];
      const fileForConversion = item.checklist_file.split("?");
      let response = await fetch(fileForConversion[0], {
        headers: {},
      });
      let blob = await response.blob();
      blob = new File([blob], `${item.name}`, {
        type:
          item.fileType == "Image"
            ? "image/jpeg"
            : item.fileType == "Video"
            ? "video/mp4"
            : "",
      });
      const formData = new FormData();
      formData.append("checklist_file", blob);
      formData.append("checkListItem", d.Id);
      formData.append("fileType", item.fileType);
      formData.append("withAnswer", "True");
      formData.append("survey", id);
      formData.append("name", item.name);
      if (item.lat !== null) {
        formData.append("lat", item.lat);
      }
      if (item.long !== null) {
        formData.append("long", item.long);
      }
      if (item.createdBy !== null) {
        formData.append("createdBy", item.createdBy.Id);
      }
      let res = await uploadChecklistItemAttachment(formData);
      console.log(res);
    }
    const checklistData = await getData(
      `CheckListItemData/?checklistItemId=${d.Id}&survey=${id}`
    );
    setChecklist(checklistData[0]);
    toggle2(event);
    setPreview("");
    toast.success("Attachment Added Successfullyy...", {
      autoClose: 3000,
      theme: "light",
      
    });
    event.stopPropagation();
    setLoader(false);
    event.stopPropagation();
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#000000",
          borderRadius: "50",
        }}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#000000",
          borderRadius: "50",
        }}
        onClick={onClick}
      />
    );
  }

  // function captureSS() {

  //   let div =
  //     document.getElementById('screenshot');

  //   html2canvas(div).then((canvas) => {
  //     let a = document.createElement("a");
  //     a.download = "ss.png";
  //     a.href = canvas.toDataURL("image/png");
  //     a.click();
  //   });
  // }
  async function takeSSReturn() {
    const fileName = generateUserId();

    let iconToHide = document.getElementById("ssIconOnVideoCall");
    iconToHide?.classList?.add("d-none");

    let div = document.getElementById("screenshot");
    let canvas = await html2canvas(div);
    let a = document.createElement("a");
    a.download = "ss.png";
    a.href = canvas.toDataURL("image/png");

    let response = await fetch(a.href, {
      headers: { "Content-Type": "image/png" },
    });
    let fileObject = await response.blob();
    let file = new File([fileObject], `${fileName}`, {
      type: "image/png",
    });
    console.log(
      "file object==============================================================",
      file
    );
    return file;
  }

  function checkLocationPermission() {
    navigator.permissions
      .query({
        name: "geolocation",
      })
      .then(function (result) {
        if (result.state == "granted") {
          console.log("result.state", result.state);
          captureSS();
        } else if (result.state == "prompt") {
          console.log("result.state", result.state);
          if (result.state == "granted") {
            captureSS();
          }
          if (result.state == "denied") {
            SweetAlert.fire({
              title:
                "Unable to capture location, please enable browser location, refresh & try again !",
              icon: "error",
            });
          }
        } else if (result.state == "denied") {
          SweetAlert.fire({
            title:
              "Unable to capture location, please enable browser location, refresh & try again !",
            icon: "error",
          });
          console.log("result.state", result.state);
        }
      });
  }

  const captureSS = async () => {
    const fileName = generateUserId();
    const fileName2 = generateUserId();

    let iconToHide = document.getElementById("ssIconOnVideoCall");
    const ssFile = await takeSSReturn();

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(async (loc) => {
    //     console.log("loc", loc);
    //     console.log("ssFile", ssFile);
    console.log("lat", lat);
    console.log("lng", lng);
    if (lat && lng) {
      setStatus(null);
      setLoader(true);
      const formData = new FormData();
      const formDataNoChecklistId = new FormData();
      console.log(d.Id);
      formData.append("checkListItem", d.Id);

      formData.append("checklist_file", ssFile);
      formDataNoChecklistId.append("checklist_file", ssFile);
      formData.append("fileType", "Image");
      formDataNoChecklistId.append("fileType", "Image");
      formData.append("withAnswer", "True");
      formDataNoChecklistId.append("withAnswer", "True");
      formData.append("survey", id);
      formDataNoChecklistId.append("survey", id);
      formData.append("name", fileName);
      formDataNoChecklistId.append("name", fileName2);

      formData.append("lat", lat.toFixed(6));
      formDataNoChecklistId.append("lat", lat.toFixed(6));

      formData.append("lng", lng.toFixed(6));
      formDataNoChecklistId.append("lng", lng.toFixed(6));

      formData.append("createdBy", localStorage.getItem("vdi_User_Id"));
      let response = await axiosPostData("CheckListAttachment/", formData);

      if (response) {
        console.log("response", response);

        const response2 = await axiosPostData(
          "CheckListAttachment/",
          formDataNoChecklistId
        );
        console.log("response2", response2);
        if (response2) {
          const checklistData = await getData(
            `CheckListItemData/?checklistItemId=${d.Id}&survey=${id}`
          );
          setChecklist(checklistData[0]);
        }
        toast.success("Screenshot Captured !", {
          autoClose: 2000,
          theme: "light",
          
        });
      }

      iconToHide.classList.remove("d-none");
      setLoader(false);
    } else {
      toast.error(
        "Unable to capture location, please enable browser location, refresh & try again !",
        {
          autoClose: 4000,
          theme: "light",
          
        }
      );
    }
    //   });

    // } else {
    //   toast.error("Unable to capture location, please enable browser location, refresh & try again !", {
    //     autoClose: 4000,
    //     theme: "light",
    //     
    //   });
    // }

    // if (!navigator.geolocation) {
    //   setStatus("Geolocation is not supported by your browser");
    // } else {
    //   setStatus("Locating...");
    //   navigator.geolocation.getCurrentPosition(
    //     async (position) => {
    //       setStatus(null);
    //       setLoader(true);
    //       const formData = new FormData();
    //       console.log(d.Id);
    //       formData.append("checkListItem", d.Id);
    //       formData.append("checklist_file", file);
    //       formData.append("fileType", "Image");
    //       formData.append("withAnswer", "True");
    //       formData.append("survey", id);
    //       formData.append("name", fileName);
    //       if (position.coords.latitude !== null) {
    //         formData.append("lat", position.coords.latitude.toFixed(6));
    //       }
    //       if (position.coords.longitude !== null) {
    //         formData.append("long", position.coords.longitude.toFixed(6));
    //       }
    //       formData.append("createdBy", localStorage.getItem("vdi_User_Id"));
    //       let response = await axiosPostData("CheckListAttachment/", formData);
    //       toast.success("Screenshot Taken ...", {
    //         autoClose: 2000,
    //         theme: "light",
    //         
    //       });

    //       iconToHide.classList.remove("d-none");

    //       if (response) {
    //         const formDataWithoutId = new FormData();
    //         formDataWithoutId.append("checklist_file", file);
    //         formDataWithoutId.append("fileType", "Image");
    //         formDataWithoutId.append("withAnswer", "True");
    //         formDataWithoutId.append("survey", id);
    //         formDataWithoutId.append("name", fileName);
    //         if (position.coords.latitude !== null) {
    //           formDataWithoutId.append(
    //             "lat",
    //             position.coords.latitude.toFixed(6)
    //           );
    //         }
    //         if (position.coords.longitude !== null) {
    //           formDataWithoutId.append(
    //             "long",
    //             position.coords.longitude.toFixed(6)
    //           );
    //         }
    //         formDataWithoutId.append(
    //           "createdBy",
    //           localStorage.getItem("vdi_User_Id")
    //         );
    //         let responseWithoutId = await axiosPostData(
    //           "CheckListAttachment/",
    //           formDataWithoutId
    //         );
    //         const checklistData = await getData(
    //           `CheckListItemData/?checklistItemId=${d.Id}&survey=${id}`
    //         );
    //         setChecklist(checklistData[0]);
    //       }
    //       setLoader(false);

    //     },
    //     () => {
    //       setStatus("Unable to retrieve your location");
    //     }
    //   );
    // }

    // if (!!a.href) {
    //   let response = await fetch(a.href, {
    //     headers: { "Content-Type": "image/png" },
    //   });
    //   let fileObject = await response.blob();
    //   let file = new File([fileObject], `${fileName}`, {
    //     type: "image/png",
    //   });
    //   console.log("file object==============================================================", file);

    // }
    // a.click();
  };

  const handlePhotoFileInput = async (e) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("checklist_file", e.target.files[0]);
    formData.append("checkListItem", d.Id);
    formData.append("fileType", "Image");
    formData.append("withAnswer", "True");
    formData.append("survey", id);
    formData.append("name", new Date());
    if (lat !== null) {
      formData.append("lat", lat.toFixed(6));
    }
    if (lng !== null) {
      formData.append("long", lng.toFixed(6));
    }
    formData.append("createdBy", localStorage.getItem("vdi_User_Id"));

    let res = await uploadChecklistItemAttachment(formData);
    console.log(res);
    const checklistData = await getData(
      `CheckListItemData/?checklistItemId=${d.Id}&survey=${id}`
    );
    setChecklist(checklistData[0]);
    setLoader(false);
  };
  const handlevideoFileInput = async (e) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("checklist_file", e.target.files[0]);
    formData.append("checkListItem", d.Id);
    formData.append("fileType", "Video");
    formData.append("withAnswer", "True");
    formData.append("survey", id);
    formData.append("name", new Date());
    if (lat !== null) {
      formData.append("lat", lat.toFixed(6));
    }
    if (lng !== null) {
      formData.append("long", lng.toFixed(6));
    }

    formData.append("createdBy", localStorage.getItem("vdi_User_Id"));

    let res = await uploadChecklistItemAttachment(formData);
    console.log(res);
    const checklistData = await getData(
      `CheckListItemData/?checklistItemId=${d.Id}&survey=${id}`
    );
    setChecklist(checklistData[0]);
    setLoader(false);
  };

  function completed() {
    var photo = true;
    var videos = true;
    var comments = true;
    if (checklists?.photos == "Yes") {
      photo = false;
      checklists?.check_list_attachment.map((el) => {
        if (el?.withAnswer && el.fileType == "Image") {
          photo = true;
        }
      });
    }
    if (checklists?.videos == "Yes") {
      videos = false;
      checklists?.check_list_attachment.map((el) => {
        if (el?.withAnswer && el.fileType == "Video") {
          videos = true;
        }
      });
    }
    if (checklists?.comments == "Yes") {
      comments = false;
      var com = "";
      let filtered = checklists?.checklist_item_result.filter(
        (el) => el.survey == id
      );
      var time1 = new Date(filtered[0]?.updateTimestamp);
      if (filtered?.length > 1) {
        filtered.map((el) => {
          var time2 = new Date(el.updateTimestamp);
          if (time2 > time1) {
            time1 = new Date(el.updateTimestamp);
            com = el.remarks;
          }
        });
      } else {
        com = filtered?.length ? filtered[0].remarks : "";
      }
      if (com?.length) {
        comments = true;
      }
    }

    if (
      photo &&
      videos &&
      comments &&
      !!checklists?.checklist_item_result[
        checklists?.checklist_item_result?.length - 1
      ]?.values
    ) {
      return "green";
    } else {
      return "red";
    }
  }

  var basic_settings = {
    dots: false,
    infinite: false,
    speed: 100,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: true,
    centerPadding: "10px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var basic_settings2 = {
    dots: false,
    infinite: false,
    speed: 100,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: true,
    centerPadding: "10px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const deletePhoto = async (data, event) => {
    console.log(data);
    setLoader(true);
    const params = {
      id: data.Id,
    };
    const response = await deleteApi(`CheckListAttachment/`, params);
    if (response.status == 202) {
      const checklistData = await getData(
        `CheckListItemData/?checklistItemId=${d.Id}&survey=${id}`
      );
      if (photosFulfilled && data.fileType == "Image") {
        setphotosFulfilled(false);
      }
      if (videosFulfilled && data.fileType == "Video") {
        setvideosFulfilled(false);
      }
      setChecklist(checklistData[0]);
      handleAddingPhoto();
      // toast.success("Attachment Deleted Successfully...", {
      //   autoClose: 3000,
      //   theme: "light",
      //   
      // });
    }
    event.stopPropagation();
    setLoader(false);
  };

  return (
    <Fragment>
      {!loader ? (
        <div className="my-2 mx-4">
          <div
            className="container-fluid rounded-4 border"
            style={{ color: "#000000" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div className="col mt-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        ref={myRef}
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          width: "calc(100% - 20px)",
                        }}
                      >
                        {d?.SrNo}: {d?.section}
                      </label>
                      <div
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          border: "2px solid black",
                          background: completed(),
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex"
                      style={{ fontSize: "18px", fontWeight: "400" }}
                    >
                      {/* <div className="rating">
                        <div
                          style={{
                            width,
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <span
                            style={{
                              width: "36.5px",
                              textAlign: "center",
                              margin: "0 2.5px",
                            }}
                          >
                            {1}
                          </span>
                          <span
                            style={{
                              width: "36.5px",
                              textAlign: "center",
                              margin: "0 2.5px",
                            }}
                          >
                            {2}
                          </span>
                          <span
                            style={{
                              width: "36.5px",
                              textAlign: "center",
                              margin: "0 2.5px",
                            }}
                          >
                            {3}
                          </span>
                          <span
                            style={{
                              width: "36.5px",
                              textAlign: "center",
                              margin: "0 2.5px",
                            }}
                          >
                            {4}
                          </span>
                          <span
                            style={{
                              width: "36.5px",
                              textAlign: "center",
                              margin: "0 2.5px",
                            }}
                          >
                            {5}
                          </span>
                        </div>
                        <div ref={ratingRef}>
                          <Rating
                            initialRating={rating}
                            emptySymbol={
                              <span
                                className="fa fa-circle-o fa-3x "
                                style={{ margin: "0 2.5px",color:"#4466F2" }}
                              ></span>
                            }
                            fullSymbol={
                              <span
                                className="fa fa-circle fa-3x "
                                style={{ marginRight: "5px",color:"#4466F2" }}
                              ></span>
                            }
                            onChange={(rate) => setRating(rate)}
                          ></Rating>
                        </div>
                      </div> */}
                      <div className="d-flex flex-wrap mb-3">
                        {rat.map((el) => {
                          return (
                            <div
                              key={Math.ceil(Math.random() * 100000)}
                              className="mx-3 my-2"
                            >
                              <label
                                className="d-block text-center"
                                htmlFor={"edo-ani" + el}
                              >
                                {el}
                              </label>
                              <input
                                className="radio_animated"
                                checked={el == rating}
                                id={"edo-ani" + el}
                                type="radio"
                                name="rdo-ani"
                                onClick={() => setRating(el)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <Accordion>
                  <div
                    className="card p-3"
                    style={{
                      border: "1px solid #000000",
                      cursor: "pointer",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {photos.length || videos.length || doc.length ? (
                      <div
                        onClick={(event) => {
                          if (accordOpen) {
                            setAccordOpen(0);
                          } else {
                            setAccordOpen(1);
                          }
                          event.stopPropagation();
                        }}
                        style={{ fontSize: "18px" }}
                      >
                        View Reference photos / videos / pdf / ms word / excel
                        files for surveyor
                      </div>
                    ) : (
                      <span
                        style={{ fontSize: "18px" }}
                        className="text-danger"
                      >
                        No Reference available!!
                      </span>
                    )}
                    <Collapse isOpen={accordOpen == 1}>
                      <div className="card-body border shadow py-4 rounded-4">
                        <Slider {...basic_settings}>
                          {photos.map((el) => {
                            return (
                              <div
                                onClick={(event) => {
                                  toggle3(event);
                                  setreferenceModalPhoto(el);
                                }}
                                key={Math.ceil(Math.random() * 100000)}
                                className="col-md-4 col-sm-6"
                              >
                                <img
                                  src={el}
                                  style={{
                                    width: "80%",
                                    height: "15vh",
                                    // position:"relative"
                                  }}
                                  alt="Photo"
                                />
                              </div>
                            );
                          })}
                          {videos.map((el) => {
                            return (
                              <div
                                onClick={(event) => {
                                  toggle3(event);
                                  setreferenceModalVideo(el);
                                }}
                                key={Math.ceil(Math.random() * 100000)}
                                className="col-md-4 col-sm-6"
                              >
                                <video
                                  src={el}
                                  title="videos"
                                  style={{
                                    width: "80%",
                                    height: "15vh",
                                    // position:"relative"
                                  }}
                                  // controls
                                />
                              </div>
                            );
                          })}
                          {doc.map((el) => {
                            if (el?.includes("pdf")) {
                              return (
                                <a
                                  href={el}
                                  target="_blank"
                                  className="col-md-4 col-sm-6"
                                  key={Math.ceil(Math.random() * 100000)}
                                >
                                  <img
                                    src={pdf}
                                    style={{
                                      width: "80%",
                                      height: "15vh",
                                      // position:"relative"
                                    }}
                                  />
                                </a>
                              );
                            } else if (el?.includes("docx")) {
                              return (
                                <a
                                  href={el}
                                  target="_blank"
                                  className="col-md-4 col-sm-6"
                                  key={Math.ceil(Math.random() * 100000)}
                                >
                                  <img
                                    src={word}
                                    style={{
                                      width: "80%",
                                      height: "15vh",
                                      // position:"relative"
                                    }}
                                  />
                                </a>
                              );
                            } else if (
                              el?.includes("xlsx") ||
                              el?.includes("xls")
                            ) {
                              return (
                                <a
                                  href={el}
                                  target="_blank"
                                  className="col-md-4 col-sm-6"
                                  key={Math.ceil(Math.random() * 100000)}
                                >
                                  <img
                                    src={xls}
                                    style={{
                                      width: "80%",
                                      height: "15vh",
                                      // position:"relative"
                                    }}
                                  />
                                </a>
                              );
                            }
                          })}
                        </Slider>
                      </div>
                    </Collapse>
                  </div>
                </Accordion>

                <div
                  className="flex row mb-4"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div className="col">
                    <label style={{ fontSize: "18px" }}>
                      Surveyor’s uploaded photos
                      {checklists?.photos == "Yes" ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "15px" }}
                        >
                          *
                        </span>
                      ) : (
                        ""
                      )}
                      <img
                        src={require("../../../../../../assets/images/camera.png")}
                        // id="ssIconOnVideoCall"
                        alt="img"
                        className="cursor_pointer"
                        style={{
                          width: "30px",
                          height: "25px",
                          marginLeft: "5px",
                        }}
                        onClick={() => checkLocationPermission()}
                      />
                    </label>
                    <div
                      className="row align-items-center rounded-4"
                      style={{
                        color: "#0B0968",
                        // height: "95px",
                        // marginBottom: Attachedphotos.length ? "170px" : "0px",
                      }}
                    >
                      <div className="col-12">
                        <div className="card-body border shadow py-4 rounded-4">
                          <Slider {...basic_settings2}>
                            {!loader && !!checklists ? (
                              checklists?.check_list_attachment.length > 0 ? (
                                checklists?.check_list_attachment.map((el) => {
                                  if (
                                    el?.withAnswer &&
                                    el?.fileType == "Image"
                                  ) {
                                    return (
                                      <div
                                        className="col-md-4 col-sm-6 d-flex py-3"
                                        key={Math.ceil(Math.random() * 100000)}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                      >
                                        <div
                                          className="onHover"
                                          style={{
                                            position: "relative",
                                            width: "80%",
                                            height: "15vh",
                                          }}
                                        >
                                          <img
                                            // className="p-2"
                                            src={el?.checklist_file}
                                            alt=""
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              // position:"relative"
                                            }}
                                          />

                                          <p
                                            className="mb-0 text-muted text-center"
                                            id={"datePara"}
                                          >
                                            {new Date(
                                              el.created
                                            ).toLocaleString()}
                                          </p>

                                          <div
                                            style={{
                                              position: "absolute",
                                              borderRadius: "50%",
                                              top: "-7px",
                                              background: "grey",
                                              color: "black",
                                              width: "20px",
                                              height: "20px",
                                              right: "-7px",
                                              verticalAlign: "center",
                                              textAlign: "center",
                                              zIndex: 1,
                                            }}
                                            onClick={(event) => {
                                              deletePhoto(el, event);
                                            }}
                                          >
                                            x
                                          </div>
                                          <div className="" id={"imgOverlay"}>
                                            <button
                                              className="bg-transparent border-0 p-0 shadow-none"
                                              onClick={(event) => {
                                                setPreviewPhotoModal(
                                                  el?.checklist_file
                                                );
                                                toggle4(event);
                                              }}
                                            >
                                              <i className="fa fa-eye text-white"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    el?.withAnswer &&
                                    el?.fileType == "Document"
                                  ) {
                                    return (
                                      <div
                                        className="col-md-4 col-sm-6 d-flex py-3"
                                        key={Math.ceil(Math.random() * 100000)}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            width: "100%",
                                          }}
                                        >
                                          <iframe
                                            src={el?.checklist_file}
                                            style={{
                                              width: "80%",
                                              height: "15vh",
                                              // position: "relative",
                                            }}
                                          />

                                          <div
                                            onClick={(event) => {
                                              deletePhoto(el, event);
                                            }}
                                            style={{
                                              position: "absolute",
                                              borderRadius: "50%",
                                              top: "-7px",
                                              background: "grey",
                                              color: "black",
                                              width: "20px",
                                              height: "20px",
                                              right: "20px",
                                              verticalAlign: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            x
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                              ) : (
                                <p className="text-dark">No Photos</p>
                              )
                            ) : (
                              <div className="loader-box justify-content-center">
                                <div className="loader">
                                  <div className="line bg-dark"></div>
                                  <div className="line bg-dark"></div>
                                  <div className="line bg-dark"></div>
                                  <div className="line bg-dark"></div>
                                </div>
                              </div>
                            )}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label style={{ fontSize: "18px" }}>
                      Surveyor’s uploaded videos
                      {checklists?.videos == "Yes" ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "15px" }}
                        >
                          *
                        </span>
                      ) : (
                        ""
                      )}
                      <img
                        src={require("../../../../../../assets/images/video.png")}
                        alt="img"
                        style={{
                          width: "30px",
                          height: "25px",
                          marginLeft: "5px",
                        }}
                      />
                    </label>
                    <div
                      className="row align-items-center rounded-4"
                      style={{
                        color: "#0B0968",
                        // height: "95px",
                        // marginBottom: AttachedVideos.length ? "170px" : "0px",
                      }}
                    >
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body border shadow py-4 rounded-4">
                            <Slider
                              {...basic_settings2}
                              style={{ height: "90px" }}
                            >
                              {!loader && !!checklists ? (
                                checklists?.check_list_attachment.length > 0 ? (
                                  checklists?.check_list_attachment.map(
                                    (el) => {
                                      if (
                                        el?.withAnswer &&
                                        el?.fileType == "Video"
                                      ) {
                                        return (
                                          <div
                                            className="col-md-4 col-sm-6 d-flex py-3"
                                            key={Math.ceil(
                                              Math.random() * 100000
                                            )}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                            }}
                                          >
                                            <div
                                              className="onHover"
                                              style={{
                                                position: "relative",
                                                width: "80%",
                                              }}
                                            >
                                              <video
                                                key={Math.ceil(
                                                  Math.random() * 100000
                                                )}
                                                // className="p-2"
                                                src={el?.checklist_file}
                                                title="videos"
                                                controls
                                                style={{
                                                  width: "100%",
                                                  height: "15vh",
                                                  // position: "relative",
                                                }}
                                              />

                                              <p
                                                className="mb-0 text-muted text-center"
                                                id={"datePara"}
                                              >
                                                {new Date(
                                                  el.created
                                                ).toLocaleString()}
                                              </p>

                                              <div
                                                onClick={(event) => {
                                                  deletePhoto(el, event);
                                                }}
                                                style={{
                                                  position: "absolute",
                                                  borderRadius: "50%",
                                                  top: "-7px",
                                                  background: "grey",
                                                  color: "black",
                                                  width: "20px",
                                                  height: "20px",
                                                  right: "-7px",
                                                  verticalAlign: "center",
                                                  textAlign: "center",
                                                  zIndex: 1,
                                                }}
                                              >
                                                x
                                              </div>
                                              <div
                                                className=""
                                                id={"imgOverlay"}
                                              >
                                                <button
                                                  className="bg-transparent border-0 p-0 shadow-none"
                                                  onClick={(event) => {
                                                    setPreviewVideoModal(
                                                      el?.checklist_file
                                                    );
                                                    toggle4(event);
                                                  }}
                                                >
                                                  <i className="fa fa-eye text-white"></i>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    }
                                  )
                                ) : (
                                  <p className="text-dark">No Videos</p>
                                )
                              ) : (
                                <div className="loader-box justify-content-center">
                                  <div className="loader">
                                    <div className="line bg-dark"></div>
                                    <div className="line bg-dark"></div>
                                    <div className="line bg-dark"></div>
                                    <div className="line bg-dark"></div>
                                  </div>
                                </div>
                              )}
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex row mb-4"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div className="col">
                    <label style={{ fontSize: "18px" }}>
                      Linked Recording videos
                    </label>
                    <div
                      className="row align-items-center rounded-4"
                      style={{
                        color: "#0B0968",
                        // height: "95px",
                        // marginBottom: addedRecordingVideos?.length
                        //   ? "170px"
                        //   : "0px",
                      }}
                    >
                      <div className="col-12">
                        <div className="card-body border shadow py-4 rounded-4">
                          <Slider {...basic_settings2}>
                            {!loader && !!checklists ? (
                              addedRecordingVideos.length > 0 ? (
                                addedRecordingVideos?.map((el) => {
                                  if (el?.url == null) return null;
                                  return (
                                    <div
                                      className="col-md-4 col-sm-6 d-flex py-3"
                                      key={Math.ceil(Math.random() * 100000)}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      {
                                        <div
                                          className="onHover"
                                          style={{
                                            position: "relative",
                                            width: "80%",
                                            height: "15vh",
                                          }}
                                        >
                                          <video
                                            // className="p-2"
                                            src={el?.url}
                                            alt=""
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              // position:"relative"
                                            }}
                                            controls
                                          />

                                          {/* <p
                                        className="mb-0 text-muted text-center"
                                        id={"datePara"}
                                      >
                                        {new Date(el.created).toLocaleString()}
                                      </p> */}

                                          {/* <div
                                        style={{
                                          position: "absolute",
                                          borderRadius: "50%",
                                          top: "-7px",
                                          background: "grey",
                                          color: "black",
                                          width: "20px",
                                          height: "20px",
                                          right: "-7px",
                                          verticalAlign: "center",
                                          textAlign: "center",
                                          zIndex: 1,
                                        }}
                                        onClick={(event) => {
                                          deletePhoto(el, event);
                                        }}
                                      >
                                        x
                                      </div> */}
                                          {/* <div className="" id={"imgOverlay"}>
                                          <button
                                            className="bg-transparent border-0 p-0 shadow-none"
                                            onClick={(event) => {
                                              setPreviewPhotoModal(
                                                el?.checklist_file
                                              );
                                              toggle4(event);
                                            }}
                                          >
                                            <i className="fa fa-eye text-white"></i>
                                          </button>
                                        </div> */}
                                        </div>
                                      }
                                    </div>
                                  );
                                })
                              ) : (
                                <p className="text-dark">
                                  No Recordings Linked
                                </p>
                              )
                            ) : (
                              <div className="loader-box justify-content-center">
                                <div className="loader">
                                  <div className="line bg-dark"></div>
                                  <div className="line bg-dark"></div>
                                  <div className="line bg-dark"></div>
                                  <div className="line bg-dark"></div>
                                </div>
                              </div>
                            )}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row mb-2"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div
                    className="col"
                    onClick={(event) => event.stopPropagation()}
                    style={{ color: "#000000" }}
                  >
                    <label
                      className="col-sm-3 col-form-label"
                      style={{ fontSize: "18px", color: "#000000" }}
                    >
                      Comments / Observations{" "}
                      <span style={{ color: "red" }}>
                        {checklists?.comments == "Yes" ? "*" : ""}
                      </span>
                    </label>
                    <div
                      className="col-sm-12 rounded-4 overflow-hidden"
                      style={{
                        border: "1px solid #000000",
                        color: "#000000",
                      }}
                    >
                      <textarea
                        className="form-control"
                        rows="5"
                        cols="5"
                        placeholder="Comments"
                        value={comments}
                        style={{ fontSize: "18px" }}
                        onChange={(e) => {
                          setComments(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <div className="row d-flex flex-wrap justify-content-end">
                  <div
                    // className="col"
                    className="col"
                  >
                    <div className="row">
                      <button
                        className="btn btn-primary-blue col m-2"
                        // color="primary"
                        style={{
                          // background: "#4466F2",
                          // color: "white",
                          padding: "0.375rem 1.45rem",
                          // width: "200px",
                          // fontSize: "18px",
                        }}
                        onClick={(event) => {
                          handleAddingPhoto(event);
                          toggle2(event);
                          event.stopPropagation();
                        }}
                      >
                        Add Photo/Videos
                      </button>
                      {/* <Button color="primary" onClick={toggle2}>
                    Simple
                  </Button> */}
                      {/* </div> */}
                      {/* <div className="row"></div> */}
                      {/* <div
                    // className="col"
                    className="col-md-4"
                    onClick={(event) => event.stopPropagation()}
                  > */}
                      <button
                        className="btn btn-primary-blue col m-2"
                        // color="primary"
                        style={{
                          // background: "#4466F2",
                          // color: "white",
                          padding: "0.375rem 1.45rem",
                          // fontSize: "18px",
                        }}
                        onClick={(event) => {
                          linkRecordings(event);
                          toggle5(event);
                          event.stopPropagation();
                        }}
                      >
                        Link Recordings
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-md-4"
                    style={{ color: "#4466F2" }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <div className="row">
                      <button
                        className="btn btn-primary-blue col m-2"
                        style={{
                          // background: "#4466F2",
                          // color: "white",
                          padding: "0.375rem 1.45rem",
                        }}
                        type="button"
                        onClick={() => {
                          handleAccordPrev();
                          executeScroll();
                        }}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary-blue col m-2"
                        disabled={
                          isCommentMand === true
                            ? !(
                                !!comments &&
                                !!rating &&
                                photosFulfilled &&
                                videosFulfilled
                              )
                            : !(!!rating && photosFulfilled && videosFulfilled)
                        }
                        style={{
                          // background: "#4466F2",
                          // color: "white",
                          padding: "0.375rem 1.45rem",
                        }}
                        type="button"
                        onClick={() => {
                          handleAccordNext();
                          executeScroll();
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader-box justify-content-center">
          <div className="loader">
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modal}
        toggle={(event) => toggle2(event)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          className="bg-primary-blue"
          toggle={(event) => toggle2(event)}
        >
          {`Attachment for ${d?.section}`}
        </ModalHeader>
        <ModalBody>
          <AddPhotosVideos
            recordingVideos={recordingVideos}
            photosVideos={photosVideos}
            preview={preview}
            setPreview={setPreview}
            // handleClose={handleClose}
            AddData={AddData}
            toggle2={toggle2}
            topic={d}
            deletePhoto={deletePhoto}
            loader={loader}
          />
        </ModalBody>
        {/* <ModalFooter>
          <Button color="secondary" onClick={(event) => toggle2(event)}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
      <Modal
        isOpen={referenceModal}
        toggle={(event) => toggle3(event)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          className="bg-primary-blue"
          toggle={(event) => toggle3(event)}
        >
          {`Reference`}
        </ModalHeader>
        <ModalBody>
          {!!referenceModalPhoto ? (
            <img
              src={referenceModalPhoto}
              style={{
                width: "100%",
                height: "60vh",
                // position:"relative"
              }}
              alt="Photo"
            />
          ) : (
            ""
          )}
          {!!referenceModalVideo ? (
            <video
              src={referenceModalVideo}
              title="videos"
              style={{
                width: "100%",
                height: "60vh",
                // position:"relative"
              }}
              controls
            />
          ) : (
            ""
          )}
        </ModalBody>
        {/* <ModalFooter>
          <Button color="secondary" onClick={(event) => toggle3(event)}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
      <Modal
        isOpen={previewModal}
        toggle={(event) => toggle4(event)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          className="bg-primary-blue"
          toggle={(event) => toggle4(event)}
        >
          {`Preview`}
        </ModalHeader>
        <ModalBody>
          {!!previewPhotoModal ? (
            <div className="d-flex justify-content-center">
              <img
                src={previewPhotoModal}
                style={{
                  width: "60%",
                  height: "60vh",
                  // position:"relative"
                }}
                alt="Photo"
              />
            </div>
          ) : (
            ""
          )}
          {!!previewVideoModal ? (
            <video
              src={previewVideoModal}
              title="videos"
              style={{
                width: "100%",
                height: "60vh",
                // position:"relative"
              }}
              controls
            />
          ) : (
            ""
          )}
        </ModalBody>
        {/* <ModalFooter>
          <Button color="secondary" onClick={(event) => toggle4(event)}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
      <Modal
        isOpen={linkRecordingModal}
        toggle={(event) => toggle5(event)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          toggle={(event) => toggle5(event)}
          className="bg-primary-blue"
        >
          {"Link Video Call Recording"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {linkRecordingVideos.length > 0 ? (
              linkRecordingVideos.map((item) => {
                return (
                  <>
                    {!!item?.url && (
                      <figure className="col-xl-3 col-sm-4">
                        <video
                          style={{
                            border:
                              selectedRecordedVideo == item
                                ? "2px solid #2246AB"
                                : "",
                            // padding: "10px",
                          }}
                          src={item?.url}
                          alt="Gallery"
                          className="img-thumbnail"
                          controls
                          onClick={() => {
                            if (selectedRecordedVideo == item) {
                              setSelectedRecordedVideo("");
                            } else {
                              setSelectedRecordedVideo(item);
                            }
                          }}
                        />
                      </figure>
                    )}
                  </>
                );
              })
            ) : (
              <p className="text-center">No Recordings Present !</p>
            )}
          </div>
          {/* <p>hiii</p> */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={(event) => linkRecordingtoChecklist(event)}
          >
            Link
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default CheckListResult;
