import React, { useState, useEffect, useReducer } from "react";
import { Trash2, Edit, Copy } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import { getCustomerAdminUsers } from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import {
  getChecklist,
  deleteCheckListNew,
  createCheckListNew,
  cloneChecklist,
} from "../../../../api/index";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import clipboard from "../../../../assets/images/Vedam/clipboard.png";

const schema = yup
  .object()
  .shape({
    customer: yup
      .array()
      .min(1, "Customer is required !")
      .required("Customer is required !"),
  })
  .required();

function ChecklistManagementNew() {
  let navigate = useNavigate();
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [apiBySearch, setApiBySearch] = useState(false);
  const [cloneModal, setCloneModal] = useState();
  const [customerList, setCustomerList] = useState({
    data: [],
    loading: false,
  });
  const [selectedChecklistId, setSelectedChecklistId] = useState("");

  const user = localStorage.getItem("user");

  const toggleClonalModal = () => {
    setCustomerList({ ...customerList, data: [], loading: true });
    setCloneModal(!cloneModal);
    console.log("cloneModal", cloneModal);
    if (cloneModal !== true) {
      var reqPayload = {
        pageNo: 1,
        entriesPerPage: 100000,
      };

      getCustomerAdminUsers(reqPayload).then(
        (res) => {
          console.log("res", res);

          if (res.data.length !== 0) {
            const resList = res.data.res.filter((val) => {
              return val.role.name === "customeradmin";
            });
            console.log("resList", resList);
            setCustomerList({ ...customerList, data: resList, loading: false });
          }
        },
        (err) => {
          console.log("err", err);
          if (err.response.status === 500) {
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        }
      );
    }
  };

  const initialState = {
    loading: false,
    error: "",
    checkList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          checkList: action.checkList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      checkList: state.checkList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchCheckList();
  }, [state.page, state.itemsPerPage]);

  useEffect(() => {
    localStorage.removeItem("selectedSection");
    localStorage.removeItem("selectedSectionName");
    localStorage.removeItem("selectedSectionId");
  }, []);

  function fetchCheckList(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      checkList: state.checkList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer: customer_id,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
        customer: customer_id,
      };
    }

    getChecklist(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          checkList: res.data.data,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      console.log("selectedChecklistId", selectedChecklistId);

      const reqPayload = {
        checklist: selectedChecklistId,
        customer: parseInt(data.customer[0].customer.Id),
      };
      console.log("reqPayload", reqPayload);

      cloneChecklist(reqPayload).then(
        (res) => {
          console.log("res", res);

          toast.success("Survey attached to selected customer successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          toggleClonalModal();
          reset();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <div className="vdi_checklist py-4">
        <div className="card">
          <div className="title bg-primary-blue" id="card_title">
            <p className="mb-0 f-24">Global Survey Definition</p>
          </div>
          <div className="card-body">
            {state.loading ? (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            ) : state.checkList.length > 0 ? (
              <div
                className="table-responsive mt-4"
                style={{ maxHeight: "70vh" }}
              >
                <table className="table table-striped table-bordered">
                  <thead className="thead-light">
                    <tr
                      className="f-18"
                      style={{
                        position: "sticky",
                        top: "0",
                        background: "white",
                      }}
                    >
                      <th scope="col">{"Survey Id"}</th>
                      <th scope="col">{"Survey Name"}</th>
                      <th scope="col">{"Description"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.checkList.map((val, i) => (
                      <tr key={i} className="f-16">
                        <td>{val.Id}</td>
                        <td>{val.name}</td>
                        <td>{val.description}</td>
                        <td>
                          <Copy
                            onClick={() => {
                              toggleClonalModal();
                              setSelectedChecklistId(val.Id);
                            }}
                            id={"clone" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"clone" + i}
                          >
                            {"Global Checklist"}
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : apiBySearch ? (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"No Checklist Found !"}
              </p>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"Checklist is empty"}
              </p>
            )}

            {state.loading ? null : (
              <div className="mt-4">
                <Pagination
                  aria-label="Page navigation"
                  className="pagination justify-content-end pagination-primary"
                >
                  <PaginationItem disabled={state.page === 1 ? true : false}>
                    <PaginationLink
                      className="cursor_pointer"
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page - 1,
                          loading: state.loading,
                          error: state.error,
                          checkList: state.checkList,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC - 10);
                      }}
                    >
                      {"Previous"}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem active>
                    <PaginationLink>{state.page}</PaginationLink>
                  </PaginationItem>

                  <PaginationItem
                    disabled={
                      state.page === state.maxPage || state.maxPage === 0
                        ? true
                        : false
                    }
                  >
                    <PaginationLink
                      last
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page + 1,
                          loading: state.loading,
                          error: state.error,
                          checkList: state.checkList,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC + 10);
                      }}
                      className="cursor_pointer"
                    >
                      {"Next"}
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>

                <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={cloneModal}
        toggle={toggleClonalModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader className="bg-primary-blue" toggle={toggleClonalModal}>
          {"Select Customer"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="f-w-600 f-14">{"Customer"}</label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="name"
                      multiple={false}
                      options={customerList.data}
                      isLoading={customerList.loading}
                      placeholder="--Select*--"
                      onChange={onChange}
                      onBlur={onBlur}
                      // selected={value}
                      isInvalid={errors.customer ? true : false}
                    />
                  )}
                />

                <span className="text-danger">{errors.customer?.message}</span>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex justify-content-end">
            <button
              className=" btn btn-primary-blue"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default ChecklistManagementNew;
