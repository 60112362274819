import { useState, useEffect } from "react";
import { useClient } from "./settings";
import { useDispatch } from "react-redux";
import { open } from "../../../redux/sidebarOpenClose/action";
import { PhoneOff, Mic, MicOff, Video, VideoOff, Share, XCircle, RefreshCcw } from "react-feather";
import VideoCallRecording from "../../ProjectComponents/Agora/VideoCallRecording";
import { UncontrolledTooltip } from 'reactstrap';


export default function Controls(props) {
  const [StopRecording, setStopRecording] = useState(false);
  const client = useClient();
  const { tracks, setStart, setInCall, setCall, setOpen, onScreenChoose, videoTrackScreenShare, switchCamera } =
    props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      localStorage.setItem('videoCallMic', !trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };
  const leaveChannel = async () => {
    setStopRecording(true);
    await client.leave();
    props.endCall();

    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
    setCall(false);
    setOpen(false);

  };

  const dispatch = useDispatch();

  return (
    <div
      className="d-flex gap-2 gap-sm-3 "
      // spacing={2}
      style={{}}
    >
      {/* <div className="col-md-3 text-center"> */}
      {trackState.audio ? (
        <>
          <button
            className="btn btn-light justify-content-center btnStyle"
            style={{
              width: "50px",
              height: "40px",
              alignItems: "center",
              display: "flex",
              borderRadius: "12px",
              padding: 0,
            }}
            onClick={() => mute("audio")}
            id="TooltipTopMic"
          >
            <Mic
              style={{
                width: "18px",
                height: "18px",
              }}
            />
          </button>
          <UncontrolledTooltip placement="top" target="TooltipTopMic">
            {"Turn off microphone"}
          </UncontrolledTooltip>
        </>
      ) : (
        <>
          <button
            className="btn btn-primary justify-content-center btnStyle"
            style={{
              width: "50px",
              height: "40px",
              alignItems: "center",
              display: "flex",
              borderRadius: "12px",
              padding: 0,
            }}
            id="TooltipTopMic"
            onClick={() => mute("audio")}
          >
            <MicOff
              style={{
                width: "18px",
                height: "18px",
              }}
            />
          </button>
          <UncontrolledTooltip placement="top" target="TooltipTopMic">
            {"Turn on microphone"}
          </UncontrolledTooltip>
        </>
      )}
      {/* </div> */}
      {/* <div className="col-md-3 text-center"> */}
      {trackState.video ? (
        <>
          <button
            className="btn btn-light justify-content-center btnStyle"
            style={{
              width: "50px",
              height: "40px",
              alignItems: "center",
              display: "flex",
              borderRadius: "12px",
              padding: "0",
            }}
            onClick={() => mute("video")}
            id="TooltipTopCam"
          >
            <Video
              style={{
                width: "18px",
                height: "18px",
              }}
            />
          </button>
          <UncontrolledTooltip placement="top" target="TooltipTopCam">
            {"Turn off camera"}
          </UncontrolledTooltip>
        </>
      ) : (
        <>
          <button
            className="btn btn-primary justify-content-center btnStyle"
            style={{
              width: "50px",
              height: "40px",
              alignItems: "center",
              display: "flex",
              borderRadius: "12px",
              padding: 0,
            }}
            onClick={() => mute("video")}
            id="TooltipTopCam"
          >
            <VideoOff
              style={{
                width: "18px",
                height: "18px",
              }}
            />
          </button>
          <UncontrolledTooltip placement="top" target="TooltipTopCam">
            {"Turn on camera"}
          </UncontrolledTooltip>
        </>
      )}

      {/* <button
        onClick={() => switchCamera()}
        className={"btn btn-primary justify-content-center btnStyle"}
        style={{
          width: "50px",
          height: "40px",
          alignItems: "center",
          display: "flex",
          borderRadius: "12px",
          padding: 0,
        }}
        id="screenShareBtn">
        <RefreshCcw
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      </button> */}

      {/* screen sharing just un comment below */}
      <button
        className={"btn btn-light justify-content-center btnStyle"}
        style={{
          width: "50px",
          height: "40px",
          alignItems: "center",
          display: "flex",
          borderRadius: "12px",
          padding: 0,
        }}
        id="screenShareBtn"
        onClick={() =>
          onScreenChoose(videoTrackScreenShare === null ? "start" : "stop")}
      >
        {videoTrackScreenShare === null ?
          <Share
            style={{
              width: "18px",
              height: "18px",
            }}
          />
          :
          <XCircle
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        }
      </button>
      <UncontrolledTooltip placement="top" target="screenShareBtn">
        {videoTrackScreenShare === null ? "Start screen share" : "Stop screen share"}
      </UncontrolledTooltip>

      {/* </div> */}
      {/* <div className="col-md-3 text-center"> */}
      <button
        className="btn btn-danger justify-content-center btnStyle"
        style={{
          width: "50px",
          height: "40px",
          alignItems: "center",
          display: "flex",
          borderRadius: "12px",
          padding: "0",
        }}
        onClick={() => {
          if (window.innerWidth >= 991) {
            document
              .querySelector(".page-main-header")
              .classList.remove("open");
            document.querySelector(".page-sidebar").classList.remove("open");
            dispatch(open());
          }
          leaveChannel();
        }}
        id="TooltipTopLeave"
      >
        <PhoneOff
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      </button>
      <UncontrolledTooltip placement="top" target="TooltipTopLeave">
        {"Leave Call"}
      </UncontrolledTooltip>
      {/* </div> */}
      <div className="text-center">
        <VideoCallRecording StopRecording={StopRecording} />
      </div>
    </div>
  );
}
