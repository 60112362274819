import React, { useState, useEffect } from "react";
import { Trash2 } from "react-feather";
import {
  createCheckListTable,
  updateCheckListTable,
} from "../../../../api/index";
import { toast } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function CreateTable() {
  let navigate = useNavigate();
  let location = useLocation();

  const { id } = useParams();

  const [columns, setColumns] = useState([
    {colName:"Sequence",axis:""},
    { colName: "Column 1", axis: "" },
    { colName: "Column 2", axis: "" },
    { colName: "Column 3", axis: "" },
  ]);
  const [tableName, setTablename] = useState("");
  const [seq,setSeq]=useState("");
  const [axisType, setAxisType] = useState("row");
  const [rows, setRows] = useState([
    {
      row: [
        {value:"1",axis:""},
        { value: "Row 1", axis: "" },
        { value: "", axis: "" },
        { value: "", axis: "" },
      ],
    },
    {
      row: [
        {value:"2",axis:""},
        { value: "Row 2", axis: "" },
        { value: "", axis: "" },
        { value: "", axis: "" },
      ],
    },
  ]);

  function changeColumnName(e, columnIndex) {
    console.log("e.target.value", e.target.value);
    console.log("columnIndex", columnIndex);
    const updatedColumns = [...columns];
    updatedColumns[columnIndex].colName = e.target.value;
    setColumns(updatedColumns);
  }

  function changeRowValues(e, rowIndex, valueIndex) {
    console.log("e.target.value", e.target.value);
    console.log("rowIndex", rowIndex);
    console.log("valueIndex", valueIndex);
    const updatedRows = [...rows];
    console.log("updatedRows", updatedRows);
    // if(e.target.value.length == 0){

    //   updatedRows[rowIndex].row[valueIndex].value = 0;
    // }else{
    // }

    updatedRows[rowIndex].row[valueIndex].value = e.target.value;

    console.log("updatedRows", updatedRows);
    setRows(updatedRows);
    // console.log("updatedRows", updatedRows);
  }

  function addRowToTable() {
    const updatedRows = [...rows];
    console.log("columns", columns);
    let temp = [];
    for (let i = 0; i < columns.length; i++) {
      temp.push({ value: "", axis: "" });
    }
    updatedRows.push({ row: temp });
    console.log("updatedRows", updatedRows);
    setRows(updatedRows);
  }

  function addColumnToTable() {
    const updatedColumns = [...columns];
    const updatedRows = [...rows];
    updatedColumns.push({ colName: "", axis: "" });
    console.log("updatedRows", updatedRows);
    for (let i = 0; i < updatedRows.length; i++) {
      updatedRows[i].row.push({ value: "", axis: "" });
    }

    setColumns(updatedColumns);
    setRows(updatedRows);
  }

  function delColumn(colIndex) {
    console.log("colIndex", colIndex);
    const updatedColumns = [...columns];
    const updatedRows = [...rows];

    updatedColumns.splice(colIndex, 1);

    for (let i = 0; i < updatedRows.length; i++) {
      updatedRows[i].row.splice(colIndex, 1);
    }
    console.log("updatedColumns", updatedColumns);
    console.log("updatedRows", updatedRows);
    setColumns(updatedColumns);
    setRows(updatedRows);
  }

  function delRow(rowId) {
    console.log("rowId", rowId);
    const updatedRows = [...rows];
    console.log("updatedRows", updatedRows);
    updatedRows.splice(rowId, 1);
    setRows(updatedRows);
  }

  function saveTable() {
   // console.log("columns", columns);
 //   console.log("rows", rows);
  //  console.log("tableName", tableName);
  // console.log("sequence",seq)
    if (tableName.length == 0) {
      toast.error(`Please enter table name`, {
        autoClose: 3000,
        theme: "light",
        
      });
    } else {
      // const payload = {
      //   checklist: id,
      //   file: {
      //     th: columns,
      //     tr: rows,
      //     tableName: tableName,
      //   },
      // };
      let tableList = [];
      console.log("location", location);
     // console.log("unsorted arrya",rows)
      // let arr=rows.sort((a,b)=>{
      //     if(a.row[0].value>b.row[0].value) return 1
      //     return -1
      // })
     // console.log("sorted_arr",arr)
      if (Object.keys(location.state.tableList).length > 0) {
        // if (location.state.tableList.file.length > 0) {
        tableList = [...location.state.tableList.file];
        console.log("update");
        tableList.push({
          answer: null,
          th: columns,
          tr: rows,
          sequence:seq,
          tableName: tableName,
          axisType: axisType,
          TableId: uuidv4(),
        });

        const putPayload = {
          Id: location.state.tableList.Id,
          checklist: id,
          withSurvey: "False",
          file: tableList,
        };
        console.log("putPayload", putPayload);
        putPayload.file.sort((a, b) => {
          const sequenceA = parseInt(a.sequence);
          const sequenceB = parseInt(b.sequence);
          return sequenceA - sequenceB;
        });
        
        // Sort rows within each table based on the "row" value
        putPayload.file.forEach(table => {
          table.tr.sort((a, b) => {
            const rowA = parseInt(a.row[0].value);
            const rowB = parseInt(b.row[0].value);
            return rowA - rowB;
          });
        });
        
        console.log(putPayload,"sorted_data");
        updateCheckListTable(putPayload).then(
          (res) => {
            console.log("res", res);
            toast.success("Table updated successfully !", {
              autoClose: 1000, theme: "light",
            });

            setTimeout(() => {
              navigate(-1);
            }, [1000]);
          },
          (err) => {
            console.log("err", err);
          }
        );
        
      } else {
        console.log("insert");
        console.log("tableList", tableList);
        tableList.push({
          answer: null,
          th: columns,
          tr: rows,
          sequence:seq,
          tableName: tableName,
          axisType: axisType,
          TableId: uuidv4(),
        });

        const postPayload = {
          checklist: id,
          withSurvey: "False",
          file: tableList,
        };

        console.log("postPayload", postPayload);
        postPayload.file.sort((a, b) => {
          const sequenceA = parseInt(a.sequence);
          const sequenceB = parseInt(b.sequence);
          return sequenceA - sequenceB;
        });
        
        // Sort rows within each table based on the "row" value
        postPayload.file.forEach(table => {
          table.tr.sort((a, b) => {
            const rowA = parseInt(a.row[0].value);
            const rowB = parseInt(b.row[0].value);
            return rowA - rowB;
          });
        });
        
        console.log(postPayload,"sorted_data");

        createCheckListTable(postPayload).then(
          (res) => {
            console.log("res", res);
            toast.success("Table created successfully !", {
              autoClose: 1000, theme: "light",
            });

            setTimeout(() => {
              navigate(-1);
            }, [1000]);
          },
          (err) => {
            console.log("err", err);
          }
        );
      }

      // const table = document.getElementById("my-table");
      // const tableHtml = table.outerHTML;
      // console.log("tableHtml", tableHtml);
      // localStorage.setItem("my-table-html", tableHtml);

      // const tableHtml2 = localStorage.getItem("my-table-html");
      // const parser = new DOMParser();
      // const tableElement = parser
      //   .parseFromString(tableHtml2, "text/html")
      //   .querySelector("table");

      // const tableContainer = document.getElementById("table-container");
      // tableContainer.appendChild(tableElement);
      // tableContainer.innerHTML = tableHtml;

      // const cells = document.getElementsByTagName("input");
      // const a = cells[0].textContent;
      // console.log(cells);
      // console.log(a);
    }
  }

  function handleTableName(e) {
    setTablename(e.target.value);
  }

  function handleAxisChange(e, rowId, colId) {
    console.log("e.target.name", e.target.name);
    console.log("e.target.value", e.target.value);
    console.log("rowId", rowId);
    console.log("colId", colId);
    console.log("rows", rows);
    const newRows = [...rows];

    newRows[rowId].row[colId].axis = e.target.value;
    if (e.target.value == "x") {
      for (let i = 0; i < newRows.length; i++) {
        if (rowId !== i) {
          if (newRows[i].row[colId].axis.length > 0) {
            newRows[i].row[colId].axis = "y";

            console.log("radioinliney" + i.toString() + colId.toString());
            const element = document.getElementById(
              "radioinliney" + i.toString() + colId.toString()
            );
            console.log("element", element);
            element.checked = true;
          } else {
            newRows[i].row[colId].axis = "";
          }
        }
      }
    }

    console.log("newRows", newRows);
    setRows(newRows);
  }

  function handleAxisType(e) {
    console.log(e.target.value);
    setAxisType(e.target.value);
    if (e.target.value == "row") {
      let newColumns = [...columns];
      for (let i = 0; i < columns.length; i++) {
        newColumns[i].axis = "";
      }
      setColumns(columns);
    } else if (e.target.value == "column") {
      let newRows = [...rows];
      for (let j = 0; j < newRows.length; j++) {
        for (let k = 0; k < newRows[j].row.length; k++) {
          console.log("newRows[j].row[k].axis", newRows[j].row[k]);
          newRows[j].row[k].axis = "";
        }
      }
      console.log("newRows", newRows);
      setRows(newRows);
    }
  }

  function handleColAxisChange(e, cind) {
    console.log("e.target.checked", e.target.checked);
    console.log("cind", cind);
    const newColumns = [...columns];

    newColumns[cind].axis = e.target.value;

    if (e.target.value == "x") {
      for (let i = 0; i < newColumns.length; i++) {
        if (i !== cind) {
          if (newColumns[i].axis.length > 0) {
            newColumns[i].axis = "y";

            const element = document.getElementById("radioy" + i.toString());
            console.log("element", element);
            element.checked = true;
          } else {
            newColumns[i].axis = "";
          }
        }
      }
    }
    console.log("newColumns", newColumns);
  }

  return (
    <React.Fragment>
      <div className="fontFamily formTable py-4">
        <div className="row">
          <div id="table-container"></div>
          <div className="col-12">
            <div className="card">
              <div
                className="title bg-primary-blue d-flex justify-content-between"
                id="card_title"
              >
                <p className="mb-0 f-24">
                  {" "}
                  <span
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="text-light me-2"
                    style={{ fontSize: "20px", cursor: "pointer" }}
                  >
                    <i className="fa fa-arrow-circle-left"></i>
                  </span>
                  Create Table
                </p>
              </div>
              <div className="card-body">
                <div className="row">
                <div className="col-12 col-md-2">
                    <label className="f-w-600">Enter Sequence</label>
                    <input
                      className="form-control"
                      type="number"
                      value={seq}
                      placeholder="Sequence"
                      onChange={(e) => setSeq(e.target.value)}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <label className="f-w-600">Table Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={tableName}
                      placeholder="Enter table name"
                      onChange={(e) => handleTableName(e)}
                    />
                  </div>

                  <div className="col-12 col-md-2">
                    <label className="f-w-600">Select Axis From</label>
                    <select
                      className="form-control digits"
                      id=""
                      value={axisType}
                      onChange={(e) => {
                        handleAxisType(e);
                      }}
                    >
                      <option>{"row"}</option>
                      <option>{"column"}</option>
                    </select>
                  </div>
                  <div className="col-12 col-md-4">
                    <label className="f-w-600 invisible">Click</label>
                    <div className="d-flex">
                      <button
                        className="btn btn-primary-blue mb-4"
                        onClick={() => {
                          addRowToTable();
                        }}
                      >
                        Add Row
                      </button>
                      <button
                        className="btn btn-primary-blue mb-4 ms-2"
                        onClick={() => {
                          addColumnToTable();
                        }}
                      >
                        Add Column
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  id={"my-table"}
                  className="table-responsive"
                  style={{ overflowX: "scroll" }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {columns.map((col, coli) => (
                          <th
                            scope="col"
                            key={coli}
                            style={{ padding: "8px 8px", minWidth: "150px" }}
                          >
                            <div className="d-flex align-items-center">
                              <textarea
                                //   style={{ border: "none" }}
                                value={col.colName}
                                type="text"
                                className="form-control form-control-table"
                                onChange={(e) => changeColumnName(e, coli)}
                                rows={"1"}
                              ></textarea>

                              <Trash2
                                onClick={() => delColumn(coli)}
                                id={"deleteToolTip"}
                                className="cursor_pointer ms-1 text-danger"
                                height={"16px"}
                                width={"16px"}
                              />
                            </div>

                            {axisType == "column" ? (
                              coli !== 0 ? (
                                <div className="form-group mt-2 text-center m-checkbox-inline mb-0 custom-radio-ml">
                                  <div className="radio radio-primary">
                                    <input
                                      id={"radiox" + coli.toString()}
                                      type="radio"
                                      name={"radio" + coli.toString()}
                                      value="x"
                                      onClick={(e) => {
                                        handleColAxisChange(e, coli);
                                      }}
                                    />
                                    <label
                                      className="mb-0"
                                      htmlFor={"radiox" + coli.toString()}
                                    >
                                      <span className="digits">{"x-axis"}</span>
                                    </label>
                                  </div>
                                  <div className="radio radio-primary">
                                    <input
                                      id={"radioy" + coli.toString()}
                                      type="radio"
                                      name={"radio" + coli.toString()}
                                      value="y"
                                      onClick={(e) => {
                                        handleColAxisChange(e, coli);
                                      }}
                                    />
                                    <label
                                      className="mb-0"
                                      htmlFor={"radioy" + coli.toString()}
                                    >
                                      <span className="digits">{"y-axis"}</span>
                                    </label>
                                  </div>
                                </div>
                              ) : null
                            ) : null}
                          </th>
                        ))}
                        <th
                          scope="col"
                          style={{
                            padding: "8px 8px",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, rowi) => (
                        <tr key={rowi}>
                          {row.row.map((val, vali) => (
                            <td key={vali} style={{ padding: "6px 6px" }}>
                              {vali==0?<input
                               defaultValue={val.value}
                               type="number"
                               className="form-control form-control-table"
                               onChange={(e) => changeRowValues(e, rowi, vali)}
                              />
                                
                             : <> <textarea
                                // style={{ border: "none" }}
                                defaultValue={val.value}
                                type="text"
                                className="form-control form-control-table"
                                onChange={(e) => changeRowValues(e, rowi, vali)}
                                rows={"1"}
                              ></textarea>

                              {axisType == "row" ? (
                                vali == 1 ? (
                                  <div className="form-group mt-2 text-center m-checkbox-inline mb-0 custom-radio-ml">
                                    <div className="radio radio-primary">
                                      <input
                                        id={
                                          "radioinlinex" +
                                          rowi.toString() +
                                          vali.toString()
                                        }
                                        type="radio"
                                        name={
                                          "radio" +
                                          rowi.toString() +
                                          vali.toString()
                                        }
                                        value="x"
                                        onClick={(e) => {
                                          handleAxisChange(e, rowi, vali);
                                        }}
                                      />
                                      <label
                                        className="mb-0"
                                        htmlFor={
                                          "radioinlinex" +
                                          rowi.toString() +
                                          vali.toString()
                                        }
                                      >
                                        <span className="digits">
                                          {" "}
                                          {"x-axis"}
                                        </span>
                                      </label>
                                    </div>
                                    <div className="radio radio-primary">
                                      <input
                                        id={
                                          "radioinliney" +
                                          rowi.toString() +
                                          vali.toString()
                                        }
                                        type="radio"
                                        name={
                                          "radio" +
                                          rowi.toString() +
                                          vali.toString()
                                        }
                                        value="y"
                                        onClick={(e) => {
                                          handleAxisChange(e, rowi, vali);
                                        }}
                                      />
                                      <label
                                        className="mb-0"
                                        htmlFor={
                                          "radioinliney" +
                                          rowi.toString() +
                                          vali.toString()
                                        }
                                      >
                                        <span className="digits">
                                          {" "}
                                          {"y-axis"}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                ) : null
                              ) : null}
                                      </> }</td>
                          ))}

                          <td
                            style={{
                              padding: "6px 6px",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <Trash2
                              onClick={() => delRow(rowi)}
                              id={"deleteToolTip"}
                              className="cursor_pointer ms-1 text-danger"
                              height={"16px"}
                              width={"16px"}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  <button
                    className="btn btn-primary-blue"
                    onClick={() => {
                      saveTable();
                    }}
                  >
                    Save Table
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateTable;
