import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getCheckListTable,
  createCheckListTable,
  updateCheckListTable,
  uploadChecklistItemAttachmentv3,
  getChecklistItemAttachmentv3,
  updateChecklistItemAttachmentv3,
} from "../../../../api/index";
import "../Css/Common.css";
import { toast, ToastContainer } from "react-toastify";
import { Chart } from "react-google-charts";
import html2canvas from "html2canvas";

function ShowGraph() {
  const [table, setTable] = useState({
    data: [],
    tableName: "",
    tableId: "",
    loading: false,
  });
  const [tableOfCheclist, setTableOfCheckList] = useState(false);
  const { cid, cuid, sid, tid, token } = useParams();
  const customerId = atob(cuid);
  const checklistId = atob(cid);
  const tabId = atob(tid);
  const surveyId = atob(sid);
  const tokn = atob(token);
  const divRef = useRef(null);

  useEffect(() => {
    console.log("customerId", customerId);
    console.log("checklistId", checklistId);
    console.log("surveyId", surveyId);
    console.log("tokn", tokn);
    localStorage.setItem("access", tokn);

    fetchTables();
  }, []);

  function fetchTables() {
    setTable({ ...table, data: [], loading: true });

    var reqPayload = {
      pageNo: 1,
      entriesPerPage: 1000,
      customer_id: customerId,
      checklist: checklistId,
      survey: surveyId,
      withSurvey: "True",
    };
    getCheckListTable(reqPayload).then(
      (res) => {
        console.log("res", res);
        if (res.data.payload.length > 0) {
          if (res.data.payload[0].file.length > 0) {
            const resTable = res.data.payload[0].file.filter((val) => {
              return val.TableId == tabId;
            });
            console.log("resTable", resTable);

            generateGraph(resTable[0]);

            setTableOfCheckList(false);
          }
        } else {
          var payload = {
            pageNo: 1,
            entriesPerPage: 1000,
            customer_id: customerId,
            checklist: checklistId,
            withSurvey: "False",
          };
          getCheckListTable(payload).then(
            (res) => {
              console.log("res", res);
              if (res.data.payload.length > 0) {
                setTableOfCheckList(true);
                if (res.data.payload[0].file.length > 0) {
                  const resTable = res.data.payload[0].file.filter((val) => {
                    return val.TableId == tabId;
                  });

                  generateGraph(resTable[0]);
                }
              }
            },
            (err) => {
              console.log("err", err);
              toast.error("Something went wrong !", {
                autoClose: 3000,
                theme: "light",
              });
            }
          );
        }
      },
      (err) => {
        console.log("err", err);
        toast.error("Something went wrong !", {
          autoClose: 3000,
          theme: "light",
        });
      }
    );
  }

  function generateGraph(data) {
    console.log("data", data);

    if (data.axisType == "row") {
      const xArray = data.tr.filter((item) =>
        item.row.some((obj) => obj.axis === "x")
      )[0].row;
      console.log("xArray", xArray);

      const yArrays = data.tr
        .filter((item) => item.row.some((obj) => obj.axis === "y"))
        .map((item) => item.row);
      console.log("yArrays", yArrays);

      // Initialize the output array
      const output = [];

      // Get the row headers from arrayTwo
      const rowHeaders = yArrays.map((row) => row[0].value);

      // Push the row headers as the first row of the output
      output.push(["row 1", ...rowHeaders]);

      // Loop through the objects in arrayOne, starting from index 1
      for (let i = 1; i < xArray.length; i++) {
        // Initialize a new row array with the current object value
        const newRow = [xArray[i].value];

        // Loop through the rows in arrayTwo, starting from index 0
        for (let j = 0; j < yArrays.length; j++) {
          // Push the value from the current row and column to the newRow array
          newRow.push(parseFloat(yArrays[j][i].value));
        }

        // Push the newRow to the output array
        output.push(newRow);
      }

      // Log the output array
      console.log(output);

      table.data = output;
      table.tableName = data.tableName;
      table.tableId = data.TableId;
      setTable({
        ...table,
        tableName: table.tableName,
        tableId: table.tableId,
        data: table.data,
        loading: false,
      });

      setTimeout(() => {
        handleScreenshot();
      }, [500]);
    } else if (data.axisType == "column") {
      let colXIndex = "";
      for (let i = 0; i < data.th.length; i++) {
        if (data.th[i].axis === "x") {
          console.log("Index of element with axis 'x': " + i);
          colXIndex = i;
          break; // break the loop as soon as the first match is found
        }
      }
      console.log("colXIndex", colXIndex);

      const colNames = [];
      const noXYIndexes = [];
      for (let i = 0; i < data.th.length; i++) {
        if (data.th[i].axis === "x" || data.th[i].axis === "y") {
          colNames.push(data.th[i].colName);
        } else if (data.th[i].axis === "") {
          noXYIndexes.push(i);
        }
      }
      console.log("colNames", colNames);
      console.log("noXYIndexes", noXYIndexes);

      const rowData = data.tr.map((item) =>
        item.row.map((cell, i) =>
          i !== colXIndex && noXYIndexes.includes(i) == false
            ? parseFloat(cell.value)
            : noXYIndexes.includes(i) == false
            ? cell.value
            : null
        )
      );
      console.log("rowData", rowData);
      const listWithOutNull = rowData.map((subArray) =>
        subArray.filter((element) => element !== null)
      );

      console.log(listWithOutNull);

      const resData = [colNames, ...listWithOutNull];
      console.log("resData", resData);

      table.data = resData;
      table.tableName = data.tableName;
      table.tableId = data.TableId;
      setTable({
        ...table,
        tableName: table.tableName,
        tableId: table.tableId,
        data: table.data,
        loading: false,
      });

      setTimeout(() => {
        handleScreenshot();
      }, [500]);
    }
  }

  const handleScreenshot = () => {
    const now = new Date().getTime();

    html2canvas(divRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        const file = new File([blob], `${table.tableName}${now}.png`, {
          type: "image/png",
        });
        // do something with the file object, such as uploading it to a server
        console.log("file", file);
        console.log("table", table);
        const params = {
          survey: surveyId,
          fileType: "Image",
          withSurveyOnly: "True",
          withTable: "True",
          TableId: tabId,
        };

        getChecklistItemAttachmentv3(params).then(
          (res) => {
            console.log("res", res);

            if (res.data.length > 0) {
              // update
              const putpayload = {
                Id: res.data[0].Id,
                checklist_file: file,
                fileType: "Image",
                withAnswer: "False",
                withSurveyOnly: "True",
                survey: surveyId,
                TableId: table.tableId,
                withTable: "True",
              };
              console.log("putpayload", putpayload);
              var formdata = new FormData();

              for (var key in putpayload) {
                formdata.append(key, putpayload[key]);
              }

              updateChecklistItemAttachmentv3(formdata).then(
                (res) => {
                  console.log("res", res);
                  toast.success("Chart successfully linked with report !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                },
                (err) => {
                  console.log("err", err);
                  toast.error("Something went wrong !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                }
              );
            } else {
              // insert
              const postpayload = {
                checklist_file: file,
                fileType: "Image",
                withAnswer: "False",
                withSurveyOnly: "True",
                survey: surveyId,
                TableId: table.tableId,
                withTable: "True",
              };
              console.log("postpayload", postpayload);
              var formdata = new FormData();

              for (var key in postpayload) {
                formdata.append(key, postpayload[key]);
              }

              uploadChecklistItemAttachmentv3(formdata).then(
                (res) => {
                  console.log("res", res);
                  toast.success("Chart successfully linked with report !", {
                    autoClose: 1000,
                  });
                },
                (err) => {
                  console.log("err", err);
                  toast.error("Something went wrong !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                }
              );
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
      });
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card my-4 mx-auto" style={{ maxWidth: "900px" }}>
              <div
                className="title bg-primary-blue d-flex justify-content-between"
                id="card_title_graph"
              >
                <p className="mb-0 f-18">{`Graph for ${table.tableName}`}</p>
                {/* <button
                  className="btn btn-light"
                  onClick={() => handleScreenshot()}
                >
                  Link With Report
                </button> */}
              </div>
              <div className="card-body p-0">
                <div ref={divRef}>
                  <Chart
                    width={"100%"}
                    height={"400px"}
                    chartType="LineChart"
                    loader={<div>{"Loading Chart"}</div>}
                    data={table.data}
                    options={{
                      hAxis: {
                        title: "",
                      },
                      vAxis: {
                        title: "",
                      },
                      colors: [
                        "#4466f2",
                        "#fc9803",
                        "#f22f0c",
                        "#ffd129",
                        "#174a9c",
                      ],
                      series: {
                        1: { curveType: "function" },
                      },
                    }}
                    rootProps={{ "data-testid": "2" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShowGraph;
