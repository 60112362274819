import React, { Fragment, useEffect, useState, useRef } from "react";
// import Breadcrumb from "../../../../common/breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../common/loader";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, CardHeader, CardBody, Media } from "reactstrap";
import { version } from "../../../../../constant/index";
import Webcam from "react-webcam";
import {
  axiosPostData,
  getData,
  postData,
  postFormData,
  putData,
} from "../../../../../api/APIinstance";
import {
  WATCH_LOGIN_LIST,
  WATCH_SURVEY_LIST,
} from "../../../../../redux/actionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Accordion,
  Collapse,
} from "reactstrap";
import { Card } from "react-bootstrap";
import { compressFile } from "../../../../ProjectComponents/Common/Functions/CommonFunctions";
import SurveyForm from "./SurveyForm";

const SurveyDashboard = () => {
  const appData = useSelector((content) => content.SurveyReducer.surveyData);
  const [survey_type, setSurvey_type] = useState("");
  const [loader, setLoader] = useState(true);
  const [len, setLen] = useState(0);
  const [lenFilled, setLenFilled] = useState(0);
  const [Sop, setSop] = useState(null);
  const [surveyDetail, setSurveyDetail] = useState();
  const [selfieName, setSelfieName] = useState("");
  const { id, cid } = useParams();
  const [url, setUrl] = React.useState(null);
  const [fileImage, setFileImage] = React.useState();
  const [modal, setModal] = useState();
  const [loc, setLoc] = React.useState({
    lat: "",
    long: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: WATCH_SURVEY_LIST });
    let cam = JSON.parse(localStorage.getItem("camData"));
    setUrl(cam);
  }, []);

  const webcamRef = useRef(null);

  const capturePhoto = React.useCallback(async () => {
    console.log("selfieName", selfieName);
    // if (selfieName != null) {
    const imageSrc = webcamRef.current.getScreenshot();
    localStorage.setItem("camData", JSON.stringify(imageSrc));
    await fetch(imageSrc, { headers: { "Content-Type": "image/png" } })
      .then((res) => res.blob())
      .then(async (bb) => {
        const file = await new File([bb], new Date().getTime() + ".png", {
          type: "image/png",
        });
        setFileImage(file);
        const userId = JSON.parse(localStorage.getItem("login_data"));
        console.log("userId", userId);
        console.log("surveyDetail.Id", surveyDetail.Id);
        console.log("id", id);

        compressFile(file).then(
          async (res) => {
            console.log("res", res);
            const formData = new FormData();
            formData.append("image", res);
            formData.append("survey", surveyDetail.Id);
            formData.append("surveyor", userId.user);
            formData.append("name", selfieName);

            console.log("formData", formData);
            const response = await axiosPostData("SurveyorImage/", formData);
            if (response.status == 201) {
              toast.success("Name & Selfie Submitted SuccessFully...", {
                autoClose: 3000,
                theme: "light",

              });
              let params = {
                Id: id,
                status: "InProgress",
              };
              let response = await putData("SurveyApi/", params);
              dispatch({ type: WATCH_LOGIN_LIST });
              toast.success("Survey Is In In-Progress Status !", {
                autoClose: 3000,
                theme: "light",

              });
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
      });
    setUrl(imageSrc);
    // } else {
    //   toast.success("Please enter your name!!", {
    //     autoClose: 3000,
    //     theme: "red",
    //    
    //   });
    // }
  }, [webcamRef, surveyDetail, selfieName]);

  console.log(selfieName);

  useEffect(async () => {
    var sum = 0;
    var count = 0;
    setSurveyDetail(appData[0]);

    appData[0]?.checklist?.checklist_sections.map((el) => {
      sum = sum + el.section_checklist_item.length;
      el.section_checklist_item.map((el1) => {
        if (Completed(el1) == "green") {
          count = count + 1;
        }
      });
    });

    if (appData[0]?.image_survey.length && appData[0].status == "Signed") {
      setUrl(appData[0]?.image_survey[appData[0]?.image_survey[0]]?.image);
      setSelfieName(
        appData[0]?.image_survey[appData[0]?.image_survey[0]]?.name
      );
      setLoc({
        lat: appData[0].lat,
        long: appData[0].long,
      });
    } else {
      getLocation();
    }

    setLen(sum);
    setLenFilled(count);
    if (appData.length) {
      let response = await getData(`GetSOPById/?Id=${appData[0]?.SOP?.Id}`);
      if (response) {
        setSop(response.res);
      }
    }
    if (appData.length) {
      let s_type = appData[0]?.checklist?.surveyType;
      console.log("stype", s_type)
      setSurvey_type(s_type);

      setLoader(false);
    }
  }, [appData]);

  const toggle3 = (event) => {
    setModal(!modal);
    dispatch({ type: WATCH_SURVEY_LIST });
    // event.stopPropagation();
  };

  const Completed = (data) => {
    var photo = true;
    var videos = true;
    var comments = true;
    if (data.photos == "Yes") {
      photo = false;
      data.check_list_attachment
        .filter((el) => el.survey == id)
        .map((el) => {
          if (el?.withAnswer && el.fileType == "Image") {
            photo = true;
          }
        });
    }
    if (data.videos == "Yes") {
      videos = false;
      data.check_list_attachment
        .filter((el) => el.survey == id)
        .map((el) => {
          if (el?.withAnswer && el.fileType == "Video") {
            videos = true;
          }
        });
    }
    if (data?.comments == "Yes") {
      comments = false;
      var com = "";
      let filtered = data?.checklist_item_result.filter(
        (el) => el.survey == id
      );
      var time1 = new Date(filtered[0]?.updateTimestamp);
      if (filtered?.length > 1) {
        filtered.map((el) => {
          var time2 = new Date(el.updateTimestamp);
          if (time2 > time1) {
            time1 = new Date(el.updateTimestamp);
            com = el.remarks;
          }
        });
      } else {
        com = filtered?.length ? filtered[0].remarks : "";
      }
      if (com?.length) {
        comments = true;
      }
    }

    console.log("********************", data);

    if (data?.checklist_item_result.length > 0) {
      if (
        photo &&
        videos &&
        comments &&
        !!data?.checklist_item_result[data.checklist_item_result.length - 1]
          .values
      ) {
        return "green";
      } else {
        return "red";
      }
    } else {
      return "red";
    }
  };

  const videoConstraints = {
    width: 540,
    facingMode: "environment",
  };

  const navigate = useNavigate();

  const handleSurvey = async () => {
    let data = {
      Id: Number(id),
      lat: loc?.lat,
      long: loc?.long,
    };

    if (loc.lat.length < 1 || loc.long.length < 1) {
      data.lat = localStorage.getItem("surveyLat");
      data.long = localStorage.getItem("surveyLong");
    }

    const jwsToken = localStorage.getItem("access");
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${version}SurveyApi/`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${jwsToken}`,
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.status == 200) {
      // toast.success("Survey Started...", { autoClose: 3000,theme:'light',style:{color:'white'}})
      // navigate(
      //   `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/${id}`
      // );
      navigate(
        `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/conductsurvey/${id}/${cid}`
        , { state: { data: { surveyType: survey_type } } });
    } else {
      // toast.error(`${response.status} Please check browser location`, {
      //   autoClose: 3000,
      //   theme: "light",
      //  
      // })
      window.location.reload();
    }
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition);
    }
  }

  const showPosition = (position) => {
    setLoc({
      lat: Number(Number(position.coords.latitude).toFixed(6)),
      long: Number(Number(position.coords.longitude).toFixed(6)),
    });

    localStorage.setItem(
      "surveyLat",
      Number(Number(position.coords.latitude).toFixed(6))
    );
    localStorage.setItem(
      "surveyLong",
      Number(Number(position.coords.longitude).toFixed(6))
    );
  };
  console.log("app_data", appData);
  return (
    <Fragment>
      <Loader loader={loader} />
      {!!surveyDetail ? (
        <div
          className="card"
          style={{ marginTop: "100px", fontFamily: "roboto" }}
        >
          <div
            className="card-header d-flex align-item-center"
            style={{ background: "#2264AB", padding: "12px 24px" }}
          >
            <span
              onClick={() => {
                // navigate(-1);
                console.log("calingngngnsdnfu isndfui nsdui fnsduin");
                window.history.back();
              }}
              className="text-light"
              style={{ fontSize: "20px", cursor: "pointer" }}
            >
              <i className="fa fa-arrow-circle-left"></i>
            </span>
            <span
              style={{ fontSize: "24px", marginLeft: "15px" }}
              className="text-light"
            >
              {surveyDetail?.vessel.name}
            </span>
          </div>
          <div className="card-body">
            <div className="row">
              {/* <div className="col-12 col-md-4 border">
                <div className="row border-bottom">
                  <div className="row">
                    <div className="col py-2">
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Assigned Survey:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {surveyDetail?.Id}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Survey Name:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {surveyDetail?.checklist?.name}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Verifier:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {surveyDetail?.verifier?.name}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Status:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {surveyDetail?.status == "Active"
                            ? "Assigned"
                            : surveyDetail?.status == "Signed"
                              ? "Completed"
                              : surveyDetail?.status}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Assigned Start Dt:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {new Date(surveyDetail?.startDate).toLocaleString()}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Assigned End Dt:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {new Date(surveyDetail?.endDate).toLocaleString()}
                        </h4>
                      </div>
                      <div className="">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Survey Lat - Long
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {url != null && loc.lat != null
                            ? `${loc.lat} - ${loc.long}`
                            : ""}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          No of Section:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {surveyDetail?.checklist?.checklist_sections?.length}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          No of Check list Items:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {len}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Check list Items Filled:
                        </h4>
                        <h4
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {lenFilled}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {url != null ? (
                      <div className="text-center mt-2">
                        <img
                          src={url}
                          alt="photo"
                          style={{
                            height: "200px",
                            width: "-webkit-fill-available",
                          }}
                        />
                        <input
                          disabled={selfieName ? true : false}
                          type="text"
                          className="form-control form-control-sm mt-3 mx-auto"
                          style={{ maxWidth: '250px' }}
                          value={selfieName}
                          placeholder={"Enter your name"}
                          onChange={(e) => setSelfieName(e.target.value)}
                        />
                      </div>
                    ) : (
                      <div className="text-center mt-2">
                        <Webcam
                          ref={webcamRef}
                          audio={false}
                          screenshotFormat="image/png"
                          videoConstraints={videoConstraints}
                          mirrored={true}
                          screenshotQuality={2}
                          style={{ height: "200px" }}
                        />

                        <input
                          style={{ maxWidth: '250px' }}
                          className="form-control form-control-sm mt-3 mx-auto"
                          value={selfieName}
                          placeholder={"Enter your name"}
                          onChange={(e) => setSelfieName(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div> */}

              <div className="col-12 col-md-4 fontFamily">
                <div className="card light_grey_border">
                  <div
                    className="bg-primary-blue d-flex justify-content-between"
                    id="card_title"
                  >
                    <p className="f-24 mb-0">Survey Details</p>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Survey No:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {surveyDetail?.surveyNo}
                      </h4>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Survey Name:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {surveyDetail?.checklist?.name}
                      </h4>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Vessel Name:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {surveyDetail?.vessel.name}
                      </h4>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Verifier:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {surveyDetail?.verifier?.name}
                      </h4>
                    </div>



                    {/* <div className="">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Assigned Start Dt:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {new Date(surveyDetail?.startDate).toLocaleString()}
                      </h4>
                    </div>

                    <div className="">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Assigned End Dt:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {new Date(surveyDetail?.endDate).toLocaleString()}
                      </h4>
                    </div> */}

                    <div className="d-flex justify-content-between">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Status:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {surveyDetail?.status == "Active"
                          ? "Assigned"
                          : surveyDetail?.status == "Signed"
                            ? "Completed"
                            : surveyDetail?.status}
                      </h4>
                    </div>

                    <div className="">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Survey Lat - Long
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {url != null && loc.lat != null
                          ? `${loc.lat} - ${loc.long}`
                          : ""}
                      </h4>
                    </div>



                    <hr />

                    <div className="d-flex justify-content-between">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        No. of Sections:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {surveyDetail?.checklist?.checklist_sections?.length}
                      </h4>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        No. Of Items Present:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {len}
                      </h4>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        No. Of Items Filled:
                      </h4>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {lenFilled}
                      </h4>
                    </div>

                    <hr className="mt-1" />
                    {url != null ? (
                      <div className="text-center mt-2">
                        <img
                          src={url}
                          alt="photo"
                          style={{
                            height: "200px",
                            width: "-webkit-fill-available",
                          }}
                        />
                        <input
                          disabled={selfieName ? true : false}
                          type="text"
                          className="form-control form-control-sm mt-3 mx-auto"
                          value={selfieName}
                          placeholder={"Enter your name"}
                          onChange={(e) => setSelfieName(e.target.value)}
                        />
                      </div>
                    ) : (
                      <div className="text-center mt-2">
                        <Webcam
                          ref={webcamRef}
                          audio={false}
                          screenshotFormat="image/png"
                          videoConstraints={videoConstraints}
                          mirrored={true}
                          screenshotQuality={2}
                          style={{ height: "200px" }}
                        />

                        <input
                          className="form-control form-control-sm mt-3 mx-auto"
                          value={selfieName}
                          placeholder={"Enter your name"}
                          onChange={(e) => setSelfieName(e.target.value)}
                        />
                      </div>
                    )}

                    <div className="text-center mt-3">
                      {url == null && (
                        <button
                          disabled={!!!selfieName}
                          className="btn btn-primary-blue w-100"
                          onClick={capturePhoto}
                        >
                          {surveyDetail?.status === "InProgress" ||
                            surveyDetail?.status === "Signed" ||
                            surveyDetail?.status === "Correction Required"
                            ? " Add Name & Selfie"
                            : "Save Name & Selfie"}
                        </button>
                      )}
                    </div>

                    <div className="text-center mt-3">
                      <button
                        disabled={
                          surveyDetail?.status == "Active" && url == null
                            ? true
                            : surveyDetail?.status === "InProgress" ||
                              surveyDetail?.status === "Signed" ||
                              surveyDetail?.status === "Correction Required"
                              ? false
                              : false
                        }
                        className="btn btn-primary-blue w-100"
                        // disabled={url == null ? true : false}
                        onClick={handleSurvey}
                      >
                        {surveyDetail?.status === "InProgress" ||
                          surveyDetail?.status === "Signed" ||
                          surveyDetail?.status === "Correction Required"
                          ? "Resume Survey"
                          : "Start Survey"}
                      </button>
                    </div>

                    <div className="text-center mt-3">
                      <button
                        className="btn btn-primary-blue w-100"
                        onClick={() => toggle3((event) => toggle3(event))}
                      >
                        Show All Selfies
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-8">
                <div className="card light_grey_border">
                  <div
                    className="bg-primary-blue d-flex justify-content-between"
                    id="card_title"
                  >
                    <p className="f-24 mb-0">
                      Instructions and guidelines: {Sop?.SOP?.name}
                    </p>
                  </div>
                  <div className="card-body">
                    <div
                      className="mb-3"
                      style={{ color: "#000", fontSize: "18px" }}
                    >
                      Description : {Sop?.SOP?.description}
                    </div>
                    {Sop?.SOPLineItems?.map((el) => {
                      return (
                        <div
                          key={Math.ceil(Math.random() * 100000)}
                          style={{ fontSize: "16px" }}
                        >
                          {el.sequence}. {el.instruction}{" "}
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* <SurveyForm /> */}
              </div>
            </div>
            {/* <div className="row my-4">
              <div className="col-md-4 d-flex justify-content-center">
                {url == null && (
                  <button
                    disabled={!!!selfieName}
                    className="btn btn-primary-blue"
                    onClick={capturePhoto}
                    style={{ width: "70%" }}
                  >
                    {surveyDetail?.status === "InProgress" ||
                      surveyDetail?.status === "Signed" ||
                      surveyDetail?.status === "Correction Required"
                      ? " Add Name & Selfie"
                      : "Save Name & Selfie"}
                  </button>
                )}
              </div>
            </div>
            <div className="row my-4 mt-2">
              <div className="col-md-4 d-flex justify-content-center">
                <button
                  disabled={
                    surveyDetail?.status == "Active" && url == null
                      ? true
                      : surveyDetail?.status === "InProgress" ||
                        surveyDetail?.status === "Signed" ||
                        surveyDetail?.status === "Correction Required"
                        ? false
                        : false
                  }
                  className="btn btn-primary-blue"
                  style={{ width: "70%" }}
                  // disabled={url == null ? true : false}
                  onClick={handleSurvey}
                >
                  {surveyDetail?.status === "InProgress" ||
                    surveyDetail?.status === "Signed" ||
                    surveyDetail?.status === "Correction Required"
                    ? "Resume Survey"
                    : "Start Survey"}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 d-flex justify-content-center">
                <button
                  className="btn btn-primary-blue"
                  style={{ width: "70%" }}
                  onClick={() => toggle3((event) => toggle3(event))}
                >
                  Show All Selfies
                </button>
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <div className="loader-box justify-content-center">
          <div className="loader">
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modal}
        toggle={(event) => toggle3(event)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          className="bg-primary-blue"
          toggle={(event) => toggle3(event)}
        >
          {`Selfies`}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {appData[0]?.image_survey.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <figure className="col-xl-3 col-sm-4">
                    <Media
                      src={item.image}
                      alt="Gallery"
                      className="img-thumbnail"
                    />
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "6px",
                      }}
                    >
                      {item.name}
                    </span>
                  </figure>
                </React.Fragment>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default SurveyDashboard;
