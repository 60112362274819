import { useEffect, useRef, useState } from "react";
import { get_nested_mla, post_mla } from "../../../../api";
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { Trello } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

const schema = yup
    .object()
    .shape({
        name: yup
            .string()
            .max(250, "250 characters allowed !")
            .required("Description is required !"),
        srno: yup
            .string()
            .required("Sr.No is required")
            .max(30, "30 charaters allowed !")
            .test('match', 'Please enter valid Sr.No', function (value) {
                return /^[a-zA-Z0-9.]+$/.test(value);
            }),
        sequence: yup
            .string()
            .required("Sequence no. is required")
            .matches(/^[1-9][0-9]*$/, 'Sequence no. cannot start with 0'),
        soc: yup
            .string()
            .required("SOC required !")
            .matches(/^((?!Select).)*$/, "Please select valid option"),

        socCategory: yup.array().when("soc", {
            is: (value) => value == "true",
            then: yup
                .array()
                .min(1, "Please select SOC category !")
                .required("Please select SOC category !"),
            otherwise: yup.array().notRequired(),
        }),

        photo: yup
            .string()
            .nullable()
            .required("Photo is required !")
            .matches(/^((?!Select).)*$/, "Please select valid option"),
        comments: yup
            .string()
            .nullable()
            .required("Comments is required !")
            .matches(/^((?!Select).)*$/, "Please select valid option"),
    })
    .required();
const Tree_view = (props) => {
    const [jsx, setJsx] = useState({ data: "", loading: false });
    const [content, setContent] = useState({});
    const [modal2, setModal2] = useState(false);
    const [soc_category_list, setSoc_category_list] = useState(props.soc_list);
    const [selected_parent, setSelected_parent] = useState({})
    const [show, setShow] = useState(false);
    const [object, setObject] = useState({})
    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    let { errors } = formState;
    const watchSOC = watch("soc");
    const customer_id = localStorage.getItem("customer_id");


    useEffect(() => {
        console.log("watchSOC", watchSOC);
    }, [watchSOC]);
    const toggle2 = () => {
        setModal2(!modal2);
        if (modal2 == true) {
            props.fetch_mla_list()
        }
    }


    useEffect(() => {
        if (modal2) {
            reset()
            // setSelected_parent({})
        }
        if (show == false) {
            reset()
        }

    }, [modal2, show])


    useEffect(() => {
        console.log(props, "props")

        reset();
        setSoc_category_list(props.soc_list)
        setObject(props.object)
        fetchLevOne(props.object)
    }, [props])

    const createchild = () => {
        setSelected_parent(props.object)
        setShow(true)
    }



    // Fetching MLA struture from MLA Id
    function fetchLevOne(mla) {
        setJsx({ ...jsx, data: "", loading: true })

        // fetching seletect mla here
        get_nested_mla({ mlaIds: `[${mla.Id}]`, customer: customer_id }).then((res) => {
            console.log("res", res)
            const result = res.data.MLA
            console.log("result", result);

            const renderedHTML = renderData(result[0].child);

            setJsx({ ...jsx, data: renderedHTML, loading: false });



        })
            .catch((err) => {
                console.log("err", err)
            })
    }

    function renderData(data) {
        console.log("data", data);
        return (
            data.map((item, i) => (
                <>
                    <li key={new Date().getTime() + item.description}>
                        <span className="caret" onClick={(e) => {
                            setShow(true)
                            console.log("item", item);
                            // selected_parent = item
                            setSelected_parent(item)


                            e.target.parentNode.querySelector(".nested").classList.toggle("active");
                            e.target.classList.toggle("caret-down");

                        }}>{item.description}</span>

                        {
                            item.child.length > 0 ? (
                                <>
                                    <ul className="nested ms-4">
                                        {renderData(item.child)}
                                    </ul>
                                </>
                            ) : (
                                ""
                            )
                        }
                    </li>
                </>

            ))

        );
    }
    const onSubmit = (data) => {
        if (data !== "") {
            console.log("data", data);
            console.log("selected_parent", selected_parent);

            const payload = {
                srno: data.srno,
                soc: data.soc,
                socCategoryId:
                    data.socCategory !== undefined ? data.socCategory[0].Id : null,
                parent: selected_parent.description,
                hasParent: true,
                parentId: selected_parent.Id,
                photo: data.photo,
                comments: data.comments,
                description: data.name,
                sequence: data.sequence,
                customer: customer_id
            };

            console.log("payloadalnsd", payload);
            post_mla(payload).then(
                (res) => {
                    console.log("res", res);
                    toast.success("MLA created successfully !", {
                        autoClose: 3000,
                        theme: "light",
                    });
                    //  toggle2();
                    // props.fetch_mla_list();
                    reset()
                    setShow(false)
                    fetchLevOne(object);
                },
                (err) => {
                    console.log("err.response", err.response);
                    if (err.response.status == 400) {
                        toast.error(err.response.data.message, {
                            autoClose: 3000,
                            theme: "light",
                        });
                        return;
                    }
                    toast.error("Something went wrong !", {
                        autoClose: 3000,
                        theme: "light",
                    });
                }
            );
        } else {
            errors.showMessages();
        }
    };


    return (<>
        <Trello
            onClick={() => {
                toggle2()
            }
            }
            id={"treeToolTip"}
            className="cursor_pointer ms-1"
            height={"18px"}
            width={"18px"}
        />
        <UncontrolledTooltip
            placement="bottom"
            target={"treeToolTip"}
        >
            {"Tree View"}
        </UncontrolledTooltip>
        <Modal
            isOpen={modal2}
            toggle={toggle2}
            backdrop="static"
        >
            <ModalHeader className="bg-primary-blue" toggle={toggle2}>Tree View of {props.object.description}</ModalHeader>
            <ModalBody>
                <div className="row">

                    <div className="col-12 col-md-5">
                        <div>


                            <h6 id="parent" className="cursor_pointer" onClick={createchild}>Parent: {props.object.description}</h6>

                            <UncontrolledTooltip
                                placement="bottom"
                                target={"parent"}
                            >
                                {"Click to create child"}
                            </UncontrolledTooltip>
                        </div>
                        <div style={{
                            overflowX: "scroll", width: '100%', minHeight: '400px',
                            height: 'auto', overflowY: 'auto'
                        }}>
                            {jsx.loading == true ?

                                <div className="loader-box justify-content-center">
                                    <div className="loader">
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                    </div>
                                </div>
                                : Object.keys(jsx.data).length > 0 ?
                                    <div className="mla_list"

                                    >

                                        <ul id="myUL" style={{ whiteSpace: "nowrap", }}>
                                            {jsx.data}
                                        </ul>

                                    </div>
                                    : null}
                        </div></div>
                    {show &&
                        <div className="col-12 col-md-7 shadow-sm p-2" style={{ border: '1px solid #c6c6c6', borderRadius: "8px" }}>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-2">
                                    <label className="f-w-600 f-14">{"Sr. No"}</label>
                                    <input
                                        className={
                                            errors.srno ? "form-control is-invalid" : "form-control"
                                        }
                                        type="text"
                                        name={`srno`}
                                        {...register(`srno`)}
                                        placeholder="Enter Sr.No*"
                                    />

                                    <span className="text-danger">{errors.srno?.message}</span>
                                </div>

                                <div className="col-12 col-md-6 mb-2">
                                    <label className="f-w-600 f-14">{"Sequence"}</label>
                                    <input
                                        className={
                                            errors.sequence ? "form-control is-invalid" : "form-control"
                                        }
                                        type="number"
                                        name={`sequence`}
                                        {...register(`sequence`)}
                                        placeholder="Enter Sequence No."
                                    />

                                    <span className="text-danger">{errors.sequence?.message}</span>
                                </div>

                                <div className="col-12 ">
                                    <label className="f-w-600 f-14">{"Description"}</label>
                                    {/* <input
                                        className={
                                            errors.name ? "form-control is-invalid" : "form-control"
                                        }
                                        type="text"
                                        name={`name`}
                                        {...register(`name`)}
                                        placeholder="Enter Description*"
                                    /> */}

                                    <textarea
                                        className={
                                            errors.name ? "form-control is-invalid" : "form-control"
                                        }
                                        type="text"
                                        name={`name`}
                                        {...register(`name`)}
                                        placeholder="Enter Description*"
                                        rows={"2"}
                                    ></textarea>

                                    <span className="text-danger">{errors.name?.message}</span>
                                </div>
                                <div className="col-12 col-md-6  mt-2">
                                    <div className="form-group mb-2">
                                        <label className="f-w-600">{"Photo"}</label>
                                        <Controller
                                            name="photo"
                                            control={control}
                                            defaultValue="Optional"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <select
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    defaultValue="Optional"
                                                    className={
                                                        errors.photo
                                                            ? " form-control is-invalid"
                                                            : " form-control"
                                                    }
                                                >
                                                    <option select="">{"--Select*--"}</option>
                                                    <option value="NR">{"Not Required"}</option>
                                                    <option value="Optional">{"Optional"}</option>
                                                    <option value="Mandatory">{"Mandatory"}</option>
                                                </select>
                                            )}
                                        />

                                        <span className="text-danger">{errors.photo?.message}</span>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6  mt-2">
                                    <div className="form-group mb-2">
                                        <label className="f-w-600">{"Comments"}</label>
                                        <Controller
                                            name="comments"
                                            control={control}
                                            defaultValue="Optional"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <select
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    defaultValue="Optional"
                                                    className={
                                                        errors.comments
                                                            ? " form-control is-invalid"
                                                            : " form-control"
                                                    }
                                                >
                                                    <option select="">{"--Select*--"}</option>
                                                    <option value="NR">{"Not Required"}</option>
                                                    <option value="Optional">{"Optional"}</option>
                                                    <option value="Mandatory">{"Mandatory"}</option>
                                                </select>
                                            )}
                                        />

                                        <span className="text-danger">{errors.comments?.message}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group mb-2">
                                        <label className="f-w-600">{"SOC"}</label>

                                        <select
                                            {...register("soc")}
                                            // defaultValue="true"
                                            className={
                                                errors.soc ? " form-control is-invalid" : " form-control"
                                            }
                                        >
                                            <option select="">{"Select Option"}</option>
                                            <option value="true">{"Yes"}</option>
                                            <option value="false">{"No"}</option>
                                        </select>

                                        <span className="text-danger">{errors.soc?.message}</span>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 mb-2">
                                    <label className="f-w-600 f-14">{"Select Parent"}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name={`parent`}
                                        value={selected_parent.description}
                                        {...register(`parent`)}
                                        placeholder="Select Parent"
                                    />
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <label className="f-w-600 f-14">{"SOC Category"}</label>
                                    <Controller
                                        name="socCategory"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead-soc-cat"
                                                labelKey="categoryName"
                                                // disabled={selectValue === "No"}
                                                multiple={false}
                                                options={soc_category_list.data}
                                                loading={soc_category_list.loading}
                                                placeholder="Select SOC Category"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                // selected={value}
                                                disabled={
                                                    watchSOC == "false" || watchSOC == undefined
                                                        ? true
                                                        : false
                                                }
                                                isInvalid={errors.socCategory ? true : false}
                                            />
                                        )}
                                    />

                                    <span className="text-danger">{errors.socCategory?.message}</span>
                                </div>

                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        style={{ width: "150px" }}
                                        className="btn btn-primary-blue btn-sm ms-auto mt-2"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Save
                                    </button>
                                </div>

                            </div>
                        </div>
                    }
                </div>

            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary-blue" onClick={toggle2} >Close</button>
            </ModalFooter>
        </Modal>
    </>)
}

export default Tree_view