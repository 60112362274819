import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getChecklistItemAttachment } from '../../../../api/index';
import { Film, FileText, } from 'react-feather';

function Attachments(props) {

    const [attachmentList, setAttachmentList] = useState({ data: [], loading: false });

    useEffect(() => {
        // console.log("props", props);
        if (props.attachmentModal === true) {
            fetchChecklistItemAttachment();
        }
    }, [props.attachmentModal]);



    function fetchChecklistItemAttachment() {
        setAttachmentList({ ...attachmentList, loading: true, data: [] });

        // getChecklistItemAttachment({ checkListItem: props.dataForAttachment.Id, survey: props.surveyId }).then((res) => {
        getChecklistItemAttachment({ checkListItem: props.dataForAttachment.Id, withAnswer: "False", withSurveyOnly: "False",withTable: "False" }).then((res) => {
            console.log("res", res);


            if (res.data.length > 0) {
                const result = res.data.filter((val, i) => {
                    return val.withAnswer === false;
                })
                setAttachmentList({ ...attachmentList, loading: false, data: result });
            } else {
                setAttachmentList({ ...attachmentList, loading: false, data: [] });
            }

        }, (err) => {
            console.log("err", err);
        });
    }



    return (
        <React.Fragment>
            <Modal isOpen={props.attachmentModal} toggle={props.toggleAttachmentModal} size="lg" backdrop="static">
                <ModalHeader toggle={props.toggleAttachmentModal} className='bg-primary-blue'>{"Attachments"}</ModalHeader>
                <ModalBody>
                    {attachmentList.loading ?
                        <div className="loader-box justify-content-center">
                            <div className="loader">
                                <div className="line bg-dark"></div>
                                <div className="line bg-dark"></div>
                                <div className="line bg-dark"></div>
                                <div className="line bg-dark"></div>
                            </div>
                        </div>

                        :
                        attachmentList.data.length !== 0 ?
                            <div className='row'>
                                {
                                    attachmentList.data.map((val, i) => (
                                        val.fileType === "Image" ?
                                            <div
                                                key={i}
                                                className='col-12 col-md-2 mb-3 '
                                            >
                                                <img
                                                    src={val.checklist_file}
                                                    height={'100'}
                                                    width={'100'}
                                                    className='mx-auto p-2 shadow-sm cursor_pointer'
                                                    style={{ border: '1px solid #c6c6c6' }}
                                                    onClick={() => { window.open(val.checklist_file, '_blank').focus() }}

                                                ></img>
                                                {/* <p style={{ wordBreak: 'break-all' }} className='text-center text-muted'>siddharth</p> */}
                                            </div>
                                            :

                                            val.fileType === "Video" ?
                                                <div
                                                    onClick={() => { window.open(val.checklist_file, '_blank').focus() }}
                                                    key={i}
                                                    className='col-12 col-md-2 mb-3 '
                                                >
                                                    <div className='cursor_pointer shadow-sm d-flex justify-content-center align-items-center'
                                                        style={{ height: '100px', width: '100px', border: '1px solid #c6c6c6' }}>
                                                        <Film
                                                            className=''
                                                            style={{ color: '#313131' }}
                                                            height={'50px'}
                                                            width={'50px'}
                                                            id={"doc" + i}
                                                        />
                                                    </div>

                                                </div>
                                                :
                                                val.fileType === "Document" ?
                                                    <div
                                                        onClick={() => { window.open(val.checklist_file, '_blank').focus() }}
                                                        key={i}
                                                        className='col-12 col-md-2 mb-3 '
                                                    >
                                                        <div className='cursor_pointer shadow-sm d-flex justify-content-center align-items-center'
                                                            style={{ height: '100px', width: '100px', border: '1px solid #c6c6c6' }}>
                                                            <FileText
                                                                className=''
                                                                style={{ color: '#313131' }}
                                                                height={'50px'}
                                                                width={'50px'}
                                                                id={"doc" + i}
                                                            />


                                                        </div>
                                                        <p className='text-center text-muted'>{val.name}</p>

                                                    </div>
                                                    : null
                                    ))
                                }
                            </div>
                            :
                            <p>No Attachments Available</p>

                    }

                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default Attachments