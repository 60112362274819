import React, { useState, useEffect, useReducer } from "react";
import { Trash2, Edit, Copy, List, Table } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import {
  getChecklist,
  deleteCheckListNew,
  createCheckListNew,
  cloneChecklist,
  cloneChecklist_new,
} from "../../../../api/index";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import EditChecklist from "./EditChecklist";
import clipboard from "../../../../assets/images/Vedam/clipboard.png";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(50, "50 characters allowed !")
      .required("Name is required !"),
    isForm: yup.boolean().required("Required"),
    surveyType: yup.string().required("Required"),
    description: yup
      .string()
      .max(500, "500 characters allowed !")
     // .required("Description required !"),
  })
  .required();

function ChecklistManagementNew() {
  let navigate = useNavigate();
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const [modal8, setModal8] = useState();
  const [modal, setModal] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdate, setDataForUpdate] = useState({ data: {} });

  const user = localStorage.getItem("user");

  const toggle8 = () => {
    setModal8(!modal8);
    if (modal8 === false || modal8 === undefined) {
      reset();
    }
  };
  const toggle = () => {
    setModal(!modal);
    if (modal === false || modal === undefined) {
      reset();
    }
  };

  const initialState = {
    loading: false,
    error: "",
    checkList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          checkList: action.checkList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      checkList: state.checkList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchCheckList();
  }, [state.page, state.itemsPerPage]);

  useEffect(() => {
    localStorage.removeItem("selectedSection");
    localStorage.removeItem("selectedSectionName");
    localStorage.removeItem("selectedSectionId");
  }, []);

  function fetchCheckList(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      checkList: state.checkList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer: customer_id,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
        customer: customer_id,
      };
    }

    getChecklist(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          checkList: res.data.data,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  // Deleting customer
  const delCheckList = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteCheckListNew({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Survey deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            fetchCheckList();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  function clone(val) {
    console.log("val", val);

    cloneChecklist_new({ checklist: val.Id, customer: parseInt(customer_id) }).then(
      (res) => {
        console.log("res", res);

        SweetAlert.fire({
          title: "Checklist cloned successfully !",
          text: `New Checklist Id : ${res.data.Id}`,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Ok",
          cancelButtonText: "cancel",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            fetchCheckList();
          }
        });
      },
      (err) => {
        console.log("err", err);
        toast.error("Something went wrong !", {
          autoClose: 3000,
          theme: "light",
        });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      data.customer = customer_id;

      createCheckListNew(data).then(
        (res) => {
          console.log("res", res);

          toast.success("Survey created successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          toggle8();
          fetchCheckList();
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <EditChecklist
        modal={modal}
        toggle={toggle}
        data={dataForUpdate.data}
        fetchCheckList={fetchCheckList}
      />

      <div className="vdi_checklist fontFamily py-4">
        <div className="card">
          <div className="title bg-primary-blue" id="card_title">
            <p className="mb-0 f-24">Survey Definition</p>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-end mb-4">
              <button
                onClick={toggle8}
                style={{ fontSize: "16px " }}
                className="btn btn-primary-blue"
              >
                Create New Survey
              </button>
            </div>

            {state.loading ? (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            ) : state.checkList.length > 0 ? (
              <div className="table-responsive " style={{ maxHeight: "70vh" }}>
                <table className="table table-striped table-bordered">
                  <thead className="thead-light">
                    <tr
                      className="f-18"
                      style={{
                        position: "sticky",
                        top: "0",
                        background: "white",
                      }}
                    >
                      <th scope="col">{"Survey Id"}</th>
                      <th scope="col">{"Survey Name"}</th>
                      <th scope="col">{"Survey Type"}</th>
                      <th scope="col">{"Description"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.checkList.map((val, i) => (
                      <tr key={i} className="f-16">
                        <td>{val.Id}</td>
                        <td>{val.name}</td>
                        <td>{val.surveyType}</td>
                        <td>{val.description?val.description:"NA"}</td>
                        <td>
                          <Edit
                            onClick={() => {
                              dataForUpdate.data = val;
                              console.log("val", val);
                              setDataForUpdate({
                                ...dataForUpdate,
                                data: dataForUpdate.data,
                              });
                              toggle();
                            }}
                            style={{ color: "#313131" }}
                            id={"editToolTip" + i}
                            className="cursor_pointer "
                            height={"18px"}
                            width={"18px"}
                          />

                          <UncontrolledTooltip
                            placement="bottom"
                            target={"editToolTip" + i}
                          >
                            {"Edit Survey"}
                          </UncontrolledTooltip>

                          <Trash2
                            onClick={() => delCheckList(val)}
                            id={"deleteToolTip" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"deleteToolTip" + i}
                          >
                            {"Delete Survey"}
                          </UncontrolledTooltip>

                          <img
                            onClick={() => {
                              navigate(
                                `${process.env.PUBLIC_URL}/customeradmin/vdi_checklist_section_items`,
                                { state: val }
                              );
                            }}
                            src={clipboard}
                            style={{ height: "18px", width: "18px" }}
                            className="ms-1 cursor_pointer"
                            id={"sectionItem" + i}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"sectionItem" + i}
                          >
                            {"Create Section And Items"}
                          </UncontrolledTooltip>

                          <Copy
                            onClick={() => clone(val)}
                            id={"clone" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"clone" + i}
                          >
                            {"Clone Checklist"}
                          </UncontrolledTooltip>

                          <Table
                            onClick={() =>
                              navigate(
                                `${process.env.PUBLIC_URL}/customeradmin/checklist/${val.Id}/formtable`,
                                { state: val }
                              )
                            }
                            id={"table" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"table" + i}
                          >
                            {"Table"}
                          </UncontrolledTooltip>

                          {val.isForm ? (
                            <>
                              <List
                                onClick={() =>
                                  navigate(
                                    `${process.env.PUBLIC_URL}/customeradmin/checklist/${val.Id}/formmaster`,
                                    { state: val }
                                  )
                                }
                                id={"form" + i}
                                className="cursor_pointer ms-1"
                                height={"18px"}
                                width={"18px"}
                              />
                              <UncontrolledTooltip
                                placement="bottom"
                                target={"form" + i}
                              >
                                {"Create And Attach Forms"}
                              </UncontrolledTooltip>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : apiBySearch ? (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"No Checklist Found !"}
              </p>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"Checklist is empty"}
              </p>
            )}

            {state.loading ? null : (
              <div className="mt-4">
                <Pagination
                  aria-label="Page navigation"
                  className="pagination justify-content-end pagination-primary"
                >
                  <PaginationItem disabled={state.page === 1 ? true : false}>
                    <PaginationLink
                      className="cursor_pointer"
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page - 1,
                          loading: state.loading,
                          error: state.error,
                          checkList: state.checkList,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC - 10);
                      }}
                    >
                      {"Previous"}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem active>
                    <PaginationLink>{state.page}</PaginationLink>
                  </PaginationItem>

                  <PaginationItem
                    disabled={
                      state.page === state.maxPage || state.maxPage === 0
                        ? true
                        : false
                    }
                  >
                    <PaginationLink
                      last
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page + 1,
                          loading: state.loading,
                          error: state.error,
                          checkList: state.checkList,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC + 10);
                      }}
                      className="cursor_pointer"
                    >
                      {"Next"}
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>

                {/* <p className='f-14 text-muted float-end mt-1'>{`Total Records: ${totalRecords}`}</p> */}
                <div>
                  <div className="d-flex justify-content-end">
                    <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader toggle={toggle8} className="bg-primary-blue">
          {"Create New Survey"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-8 mb-2">
              <label className="f-w-600 f-14">{"Name"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                placeholder="Name*"
              />

              <span className="text-danger">{errors.name?.message}</span>
            </div>

            <div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Create Form"}</label>
              <div className="col ms-2  mt-1">
                <div className="form-group d-flex">
                  <div className="radio radio-primary">
                    <input
                      {...register("isForm")}
                      id="radio1"
                      type="radio"
                      value={true}
                     
                    />
                    <label htmlFor="radio1">{"Yes"}</label>
                  </div>

                  <div className="radio radio-primary ms-4">
                    <input
                      {...register("isForm")}
                      id="radio2"
                      type="radio"
                      value={false}
                      defaultChecked
                    />
                    <label htmlFor="radio2">{"No"}</label>
                  </div>
                </div>
              </div>

              {/* <span className='text-danger'>{errors.name?.message}</span> */}
            </div>

            <div className="col-12 col-md-8 mb-2">
              <label className="f-w-600 f-14">{"Description"}</label>
              <textarea
                className={
                  errors.description
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                name={`description`}
                {...register(`description`)}
                placeholder="Description*"
                rows={"1"}
              ></textarea>

              <span className="text-danger">{errors.description?.message}</span>
            </div>

            <div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Survey Type"}</label>
              <div className="col ms-2  mt-1">
                <div className="form-group d-flex">
                  <div className="radio radio-primary">
                    <input
                      {...register("surveyType")}
                      id="radiostandard"
                      type="radio"
                      value={"Standard"}
                      defaultChecked
                    />
                    <label htmlFor="radiostandard">{"Standard"}</label>
                  </div>

                  <div className="radio radio-primary ms-4">
                    <input
                      {...register("surveyType")}
                      id="radiosire"
                      type="radio"
                      value={"Sire"}
                    />
                    <label htmlFor="radiosire">{"Sire"}</label>
                  </div>
                </div>
              </div>

              {/* <span className='text-danger'>{errors.name?.message}</span> */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default ChecklistManagementNew;
