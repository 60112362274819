import { GET_LOGIN_LIST } from "../actionTypes"

const initial_state = {
    login: [],
    loading: false,
}

const LoginReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_LOGIN_LIST:
            return { ...state, login: action?.login.res, loading: true, };

        default: return { ...state };    
    }
}

export default LoginReducer