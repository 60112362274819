import React, { useEffect, Fragment, useState } from "react";
// import Breadcrumb from "../../../common/breadcrumb";
import { Search, Video, FileText } from "react-feather";
import EventCharts from "../eventCharts";
import { Buffer } from "buffer";
import { useSelector, useDispatch } from "react-redux";
// import configDB from "../../../data/customizer/config";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import { WATCH_LOGIN_LIST } from "../../../../redux/actionTypes";
import { ModalHeader, UncontrolledTooltip } from "reactstrap";
import Loader from "../../../common/loader";
import { getSurveyReport } from "../../../../api/index";
import SurveyReport from '../SurveyReport';
import {
  Accordion,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Slider from "react-slick";
import { getData } from "../../../../api/APIinstance";
import { toast } from "react-toastify";

// var Knob = require("knob"); // browserify require
// var primary =
//   localStorage.getItem("primary_color") || configDB.data.color.primary_color;

const SurveyList = () => {
  const loginData = useSelector((content) => content.LoginApp.login);
  const [searched, setsearched] = useState("");
  const [filtered, setfiltered] = useState([]);
  const [loader, setLoader] = useState(true);
  const [modal, setModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [modalLoader, setmodalLoader] = useState(false);
  const [recordedVideo, setRecordedVideo] = useState([]);
  const [surveyIdForReport, setSurveyIdForReport] = useState("");

  const Status = [
    "Active",
    "InProgress",
    "Completed",
    "Signed",
    "VerificationPending",
    "Verified",
    "Correction",
  ];
  const [surveyData, setSurveyData] = useState([]);
  const [columnsData, setcolumnsData] = useState();
  const [preview, setPreview] = useState();
  const localData = JSON.parse(localStorage.getItem("login_data"));

  const { name } = useParams();
  const navigate = useNavigate();
  const customStyles = {
    rows: {
      style: {
        color: "#000",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "roboto",
      },
    },
    headCells: {
      style: {
        color: "white",
        background: "#2264AB",
        fontSize: "18px",
        fontWeight: "600",
        fontFamily: "roboto",
      },
    },
    cells: {
      style: {
        color: "#000",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "roboto",
      },
    },
  };

  const dispatch = useDispatch();

  function handleRoute(data) {
    console.log("data", data);

    localStorage.setItem("SurveyData", data.Id);
    if (data.checklist === null) {
      toast.error(`Cannot proceed, checklist not available !`, {
        autoClose: 3000,
        theme: "light",
        
      });
    } else {
      if (data.remoteSurveyor !== null) {
        localStorage.setItem("siteSurveyorId", data.remoteSurveyor.Id);
      } else {
        localStorage.removeItem("siteSurveyorId");
      }

      if (data.status === "Active") {
        navigate(
          `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/${data.Id}`
        );
      }
      if (data.status === "InProgress") {
        navigate(
          `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/${data.Id}`
        );
      }
      if (data.status === "Completed") {
        navigate(
          `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/${data.Id}`
        );
      }
      if (data.status === "Verified") {
        console.log("runininingingingigngin");
        navigate(
          `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/previewpage/${data.Id}`
        );
      }

      if (data.status === "Signed") {
        console.log("runininingingingigngin");
        navigate(
          `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/previewpage/${data.Id}`
        );
      }

      if (
        data.status === "Correction Required"
      ) {
        navigate(
          `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/${data.Id}`
        );
      }
      // else {
      //   navigate(
      //     `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/${data.Id}`
      //   );
      // }

      // if (data.status === "Signed") {
      //   navigate(
      //     `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/${data.Id}`
      //   );
      // } else if (data.status === "Active" || data.status === "InProgress") {
      //   navigate(
      //     `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/${data.Id}`
      //   );
      // } else if (data.status === "Completed") {
      //   navigate(
      //     `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/previewpage/${data.Id}`
      //   );
      // } else if (
      //   data.status === "CorrectionRequired" ||
      //   data.status === "Verified"
      // ) {
      //   navigate(
      //     `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/${data.Id}`
      //   );
      // }
    }
  }

  let columns = [
    {
      name: "Vessel",
      selector: (row) => row?.vessel?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Survey No",
      selector: (row) => (row?.surveyNo === null ? "N/A" : row?.surveyNo),
      sortable: true,
      center: true,
    },
    {
      name: "Survey Name",
      selector: (row) =>
        row?.checklist === null ? (
          <span className="shadow-lg" id="redDot"></span>
        ) : (
          row?.checklist?.name
        ),
      sortable: true,
      center: true,
    },
    {
      name: "Verifier",
      selector: (row) => row?.verifier?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className="px-1"
          style={{
            margin: "0px",
            background: row.status === "Active" ?
              "#708090" :
              row.status === "InProgress" ? "#EF5B0C" :
                row.status === "Completed" ? "#FFB200" :
                  row.status === "Signed" ? "#224B0C" :
                    row.status === "Verified" ? "#2264AB" :
                      row.status === "Correction Required" ? "#C21010" : null
            ,
            // "#2246AB",
            color: "#FFF",
            borderRadius: "10px",
          }}
        >
          {row?.status == "Active"
            ? "Assigned"
            : row?.status == "Signed"
              ? "Submitted"
              : row?.status}
        </p>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Assigned Start Date",
      selector: (row) => {
        const dateto = row?.startDate.split("-").join();
        console.log("dateto", dateto);
        var d = new Date(row?.startDate).toLocaleString();
        console.log("d", d);
        return d;
      },
      sortable: true,
      center: true,
    },
    {
      name: "Assigned End Date",
      selector: (row) => {
        const dateto = row?.endDate.split("-").join();
        console.log("dateto", dateto);
        var d = new Date(row?.endDate).toLocaleString();
        console.log("d", d);
        return d;
      },
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: "_id",
      sortable: true,
      cell: (row) => (
        <div>
          {/* <button
            className="btn btn-primary btn-sm"
            onClick={() => toggle(row?.Id)}
            id="recordings"
          > */}
          <Video onClick={() => toggle(row?.Id)} id="recordings" />
          <UncontrolledTooltip placement="bottom" target={"recordings"}>
            {"View Recordings"}
          </UncontrolledTooltip>
          {/* </button> */}

          {/* <button
            className="btn btn-primary btn-sm"
            onClick={() => toggleReportModal(row?.Id)}
            id="viewReport"
          > */}

          {/* {row?.status !== "" ? ( */}
          {row?.status === "Active" ? null :
            <>
              <FileText
                style={{ marginLeft: "5px" }}
                onClick={() => toggleReportModal(row?.Id)}
                id="viewReport"
              />
              <UncontrolledTooltip placement="bottom" target={"viewReport"}>
                {"View Report"}
              </UncontrolledTooltip>
            </>
          }
          {/* ) : null} */}
          {/* </button> */}
        </div>
      ),
    },
  ];

  const getListData = async () => {
    let newData = [];
    if (name == "Correction") {
      if (Status.includes("Correction")) {
        newData = loginData?.filter((el) => {
          return el.status == "Correction Required";
        });
        setLoader(false);
      } else {
        newData = loginData?.filter((el) => {
          return el.vessel.name == name;
        });
        setLoader(false);
      }
      setSurveyData(newData);
    } else {
      if (Status.includes(name)) {
        newData = loginData?.filter((el) => {
          return el.status == name;
        });
        setLoader(false);
      } else {
        newData = loginData?.filter((el) => {
          return el.vessel.name == name;
        });
        setLoader(false);
      }
      setSurveyData(newData);
    }
  };
  useEffect(() => {
    getListData();
  }, [loginData, name]);

  useEffect(() => {
    if (!Status.includes(name)) {
      columns.shift();
      setcolumnsData(columns);
    } else {
      setcolumnsData(columns);
    }
    dispatch({ type: WATCH_LOGIN_LIST });
    localStorage.removeItem("camData");
  }, []);

  function searchHandle(e) {
    let a = e.target.value;
    setsearched(a);
    if (a == "") {
      setfiltered([]);
    } else {
      const f = surveyData.filter((el) => {
        return (
          el.verifier?.name.toLowerCase().includes(a.toLowerCase()) ||
          el?.status.toLowerCase().includes(a.toLowerCase()) ||
          el?.startDate.toLowerCase().includes(a.toLowerCase())
        );
      });
      setfiltered(f);
    }
  }

  const toggle = async (id) => {
    if (!modal) {
      setmodalLoader(true);
      let resFromRecording = await getData(`SurveyRecordings/?survey=${id}`);
      let filteredData = resFromRecording.filter((el) => !!el.url);
      setRecordedVideo(filteredData);
      setmodalLoader(false);
    } else {
      setPreview("");
    }
    setModal(!modal);
  };

  const toggleReportModal = async (id) => {
    if (!reportModal) {
      setReportModal(true);
      setSurveyIdForReport(id);
      // await getSurveyReport({
      //   customer_id: localData.customer_id,
      //   survey: id,
      // }).then(
      //   (res) => {
      //     console.log("res", res);

      //     var base64Str = Buffer.from(res.data).toString("base64");
      //     var binaryString = window.atob(base64Str);
      //     var binaryLen = binaryString.length;
      //     var bytes = new Uint8Array(binaryLen);
      //     for (var i = 0; i < binaryLen; i++) {
      //       var ascii = binaryString.charCodeAt(i);
      //       bytes[i] = ascii;
      //     }
      //     var arrBuffer = bytes;

      //     var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

      //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //       window.navigator.msSaveOrOpenBlob(newBlob);
      //       return;
      //     }

      //     res.data = window.URL.createObjectURL(newBlob);
      //     var link = document.createElement("a");
      //     var frame = document.getElementById("frame");
      //     frame.src = res.data;
      //   },
      //   (err) => {
      //     console.log("err", err);
      //   }
      // );
    }
    setReportModal(!reportModal);
  };

  var basic_settings2 = {
    dots: false,
    infinite: false,
    speed: 100,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: true,
    centerPadding: "10px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#000000",
          borderRadius: 50,
        }}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#000000",
          borderRadius: 50,
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <Fragment>
      {/* <Loader loader={loader} /> */}
      {loginData.length ? (
        <div className="container-fluid" style={{ fontFamily: "roboto" }}>
          <div className="row">
            <div className="col-xl-12 xl-100">
              <div className="card mt-2 overflow-hidden">
                <div
                  className="card-header text-light d-flex"
                  style={{ background: "#2264AB", padding: "12px 24px" }}
                >
                  <span
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="text-light"
                    style={{ fontSize: "20px", cursor: "pointer" }}
                  >
                    <i className="fa fa-arrow-circle-left"></i>
                  </span>
                  <span
                    className="text-light"
                    style={{ fontSize: "24px", marginLeft: "15px" }}
                  >
                    {surveyData?.length && surveyData[0].vessel.name} / Surveys
                  </span>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-xl-9 col-sm-0"></div>
                    <div className="col-md-3 col-sm-12">
                      <div className="faq-form">
                        <input
                          value={searched}
                          onChange={searchHandle}
                          className="form-control"
                          type="text"
                          placeholder="Search.."
                        />
                        <Search className="search-icon" />
                      </div>
                    </div>
                  </div>
                  <DataTable
                    columns={columnsData}
                    data={searched ? filtered : surveyData}
                    striped={true}
                    center={true}
                    persistTableHead
                    highlightOnHover
                    pagination
                    customStyles={customStyles}
                    onRowClicked={(data) => {
                      handleRoute(data);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader-box justify-content-center">
          <div className="loader">
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modal}
        toggle={(event) => toggle(event)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          className="bg-primary-blue"
          toggle={(event) => toggle(event)}
        >
          {`Video Recordings`}
        </ModalHeader>
        <ModalBody>
          <div className="card-body">
            <div className="shadow border mb-2">
              <div className="row ">
                <div
                  className="col p-1"
                  style={{ height: "270px", width: "250%" }}
                >
                  {!!preview ? (
                    <video src={preview} height="100%" width="100%" controls />
                  ) : (
                    <span
                      className="text-center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        justifyContent: "center",
                      }}
                    >
                      No Preview available
                    </span>
                  )}
                </div>
              </div>
            </div>
            {modalLoader ? (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            ) : (
              <div className="px-3">
                <Slider
                  {...basic_settings2}
                  style={{
                    height: "160px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {recordedVideo?.length == 0 ? (
                    <span className="text-danger text-center">
                      No videos available
                    </span>
                  ) : (
                    recordedVideo?.map((element) => {
                      return (
                        <div
                          onClick={() => setPreview(element?.url)}
                          key={Math.ceil(Math.random() * 100000)}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <video
                            src={element?.url}
                            // style={{ height: "100px", width: "100px" }}
                            style={{ width: "80%", height: "15vh" }}
                            controls={true}
                          />
                        </div>
                      );
                    })
                  )}
                </Slider>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={(event) => toggle(event)}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={reportModal} toggle={(event) => toggleReportModal(event)} size="xl" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={(event) => toggleReportModal(event)}>
          {"Survey Report Preview"}
        </ModalHeader>
        <ModalBody>

          <SurveyReport id={surveyIdForReport} />

        </ModalBody>
        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={(event) => toggleReportModal(event)}
            className="btn btn-primary-blue float-right"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      {/* <Modal isOpen={reportModal} toggle={(event) => toggleReportModal(event)}>
        <div
          toggle={(event) => toggleReportModal(event)}
          style={{
            background: "#2264AB",
            padding: "12px 24px",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            className="text-light my-1"
            style={{
              fontSize: "20px",
            }}
          >
            {`View Report`}
          </span>
        </div>
        <ModalBody>
          <div className="pdfBlock d-flex justify-content-center" id="pdfBlock">
            <iframe
              id="frame"
              style={{ height: "80vh", width: "100%" }}
            ></iframe>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={(event) => toggleReportModal(event)}
          >
            Close
          </button>
        </ModalFooter>
      </Modal> */}
    </Fragment>
  );
};

export default SurveyList;
