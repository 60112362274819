import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { updateCheckListNew, update_soc_category } from "../../../../api/index";
import { toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("SOC category name is required !"),
    srno: yup
      .string()
      .max(30, "30 charaters allowed !")
      .matches(/^[a-zA-Z0-9]*$/, 'SR.No must be alphanumeric')
      .required("Sr.No is required"),
    description: yup.string().required("Description is required !"),
  })
  .required();

function Edit_SOC_category(props) {
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;
  const customer_id = localStorage.getItem("customer_id");

  useEffect(() => {
    console.log("props.data", props.data);
    setValue("srno",props.data?.srno)
    setValue("name", props.data?.categoryName);
    setValue("description", props.data?.description);
  }, [props.data]);

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      console.log("props", props);

      const reqPayload = {
         Id:props.data.Id,
          srno:data.srno,
          categoryName: data.name,
          description: data.description,
          customer:customer_id
      }
     // console.log("reqPayload", reqPayload);

          update_soc_category(reqPayload)
              .then((res) => {
                  console.log("res", res);
                  toast.success("SOC category updated successfully !", {
                    autoClose: 3000, theme: "light",
                  });
                  props.toggle();
                  props.fetch_SOC_lists()

              }, (err) => {
                  console.log("err", err);
                  toast.success("Something went wrong !", {
                    autoClose: 3000, theme: "light",
                  });
              })

      } else {
          errors.showMessages();
      }
    }
  

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={props.toggle} className="bg-primary-blue">
          {"Update SOC Category"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Sr. No"}</label>
              <input
                className={
                  errors.srno ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`srno`}
                {...register(`srno`)}
                defaultValue={props.data?.srno}
                placeholder="Sr. No*"
              />

              <span className="text-danger">{errors.srno?.message}</span>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"SOC Category Name"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                defaultValue={props.data?.categoryName}
                placeholder="Enter SOC Category Name"
              />

              <span className="text-danger">{errors.name?.message}</span>
            </div>

            <div className="col-12 col-md-12 mb-2">
              <label className="f-w-600 f-14">{"Description"}</label>
              <textarea
                className={
                  errors.description
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                name={`description`}
                {...register(`description`)}
                defaultValue={props.data?.description}
                placeholder="Enter Description*"
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.description?.message}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
           Save Changes
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
export default Edit_SOC_category;
