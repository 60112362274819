import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getSurvey,
  getSurveyReport,
  postVerifiersComments,
} from "../../../../api/index";
import { Buffer } from "buffer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  convertDate,
  calMaxPage,
} from "../../Common/Functions/CommonFunctions";
import HtmlToPdf from "../../../dashboard/defaultCompo/SurveyReport";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Show_suvey_report } from "../../../dashboard/defaultCompo/surveyList/Show_suvey_report";

const schema = yup
  .object()
  .shape({
    comments: yup.string().required("Comments is required !"),
    // comments: yup.string().notRequired(),
  })
  .required();

function VerifierSurveyList() {
  let location = useLocation();
  const { vid } = useParams();

  const verifier = localStorage.getItem("vdi_User_Id");
  const customer_id = localStorage.getItem("customer_id");
  const [pageNo, setPageNo] = useState(1);
  const [maxPage, setMaxPage] = useState();

  const [filter, setFilter] = useState("Signed");

  const [selectedSurveyDetails, setSelectedSurveyDetails] = useState({
    data: {},
  });

  const [data, setData] = useState({
    list: [],
    loading: false,
    apiFired: false,
  });
  const [modal8, setModal8] = useState();
  const [surveyId, setSurveyId] = useState("");
  const [checklistId, setChecklistId] = useState("");
  const [type, setType] = useState("");
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  const [surveyStatus, setSurveyStatus] = useState("Verified");
  const [totalRecords, setTotalRecords] = useState("");
  let { errors } = formState;

  useEffect(() => {
    console.log("location", location);
    fetchSurveyList();
  }, [filter, pageNo]);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  function fetchSurveyList() {
    setData({ ...data, list: [], loading: true, apiFired: true });

    getSurvey({
      customer_id: customer_id,
      verifier: verifier,
      entriesPerPage: 10,
      pageNo: pageNo,
      vessel: vid,
      status: filter,
    }).then(
      (res) => {
        console.log("res", res);
        setTotalRecords(res.data.filtercount);
        setMaxPage(calMaxPage(res.data.filtercount));
        console.log(calMaxPage(res.data.filtercount));
        if (res.data.length !== 0) {
          setData({
            ...data,
            list: res.data.res,
            loading: false,
            apiFired: true,
          });
        } else {
          setData({ ...data, list: [], loading: false, apiFired: true });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function generatePreview(val) {
    console.log("val", val);
    setType(val.surveyType);
    if (val.verifier_activity.length === 0) {
      console.log("1");
      setValue("comments", "");
    } else {
      console.log("2");

      setValue(
        "comments",
        val.verifier_activity[val.verifier_activity.length - 1].comments
      );
    }
    toggle8();

    if (val.verifier_activity.length > 0) {
      setSurveyStatus(
        val.verifier_activity[val.verifier_activity.length - 1].status
      );
    }

    // storing selected survey
    selectedSurveyDetails.data = val;
    setSelectedSurveyDetails({
      ...selectedSurveyDetails,
      data: selectedSurveyDetails.data,
    });

    getSurveyReport({ customer_id: customer_id, survey: val.Id }).then(
      (res) => {
        console.log("res", res.data);

        // var base64Str = Buffer.from(res.data).toString('base64');

        // var binaryString = window.atob(base64Str);
        //     var binaryLen = binaryString.length;
        //     var bytes = new Uint8Array(binaryLen);
        //     for (var i = 0; i < binaryLen; i++) {
        //         var ascii = binaryString.charCodeAt(i);
        //         bytes[i] = ascii;
        //     }
        //     var arrBuffer = bytes;

        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(newBlob);
        //     return;
        // }

        // res.data = window.URL.createObjectURL(newBlob);

        // console.log("res.data", res.data);

        // var link = document.createElement('a');
        // var frame = document.getElementById('frame');
        // frame.src = res.data;

        // document.getElementById('pdfBlock').appendChild(link);
        // document.body.appendChild(link);
        // link.href = res.data;
        // link.target = "Survey.pdf";
        // link.click();
        // window.URL.revokeObjectURL(res.data);
        // link.remove();
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("selectedSurveyDetails", selectedSurveyDetails);
      data.survey = selectedSurveyDetails.data.Id;
      data.verifier = parseInt(localStorage.getItem("vdi_User_Id"));
      data.status = surveyStatus;
      console.log("data", data);

      // Api for posting comments of verifier
      postVerifiersComments(data).then(
        (res) => {
          console.log("res", res);
          toast.success("Comments saved successfully !", {
            autoClose: 3000,
            theme: "light",
          });

          fetchSurveyList();
          toggle8();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <div className="verifier_surveylist py-4">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="title bg-primary-blue" id="card_title">
                {/* <h4 className='mb-0'>Vessel Management</h4> */}
                <p className="mb-0 f-24">Survey List</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-4 ms-auto">
                    <label className="f-w-600">Status</label>
                    <select
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                      className="form-control digits"
                      defaultValue={filter}
                    >
                      <option>{"Signed"}</option>
                      <option>{"Verified"}</option>
                      <option>{"Correction Required"}</option>
                    </select>
                  </div>
                </div>
                {data.loading ? (
                  <div className="loader-box justify-content-center">
                    <div className="loader">
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                    </div>
                  </div>
                ) : data.list.length > 0 ? (
                  <React.Fragment>
                    <div
                      className="table-responsive mt-4"
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                          <tr
                            className="f-18"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            <th scope="col">{"Survey No"}</th>
                            <th scope="col">{"Vessel Name"}</th>
                            <th scope="col">{"Survey Name"}</th>
                            <th scope="col">{"Surveyor Name"}</th>
                            <th scope="col">{"Site Coordinator Name"}</th>
                            <th scope="col">{"Instruction & Guidelines"}</th>
                            <th scope="col">{"Start Date"}</th>
                            <th scope="col">{"End Date"}</th>

                            {/* <th scope="col">{"Mode"}</th> */}
                          

                            {/* <th scope="col">{"Verifier"}</th> */}


                            <th scope="col">{"Status"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.list.map((val, i) => (
                            <tr className="f-16" key={i}>
                              <td>{val.surveyNo}</td>
                              <td>{val.vessel.name}</td>

                              <td>
                                {val.checklist?.name === null ||
                                  val.checklist?.name === undefined
                                  ? "NA"
                                  : val.checklist?.name}
                              </td>

                              <td>
                                {val.user.name === null ||
                                  val.user.name === undefined
                                  ? "NA"
                                  : val.user.name}
                              </td>

                              <td>
                                {val.remoteSurveyor === null
                                  ? "NA"
                                  : val.remoteSurveyor.name === null ||
                                    val.remoteSurveyor.name === undefined
                                    ? "NA"
                                    : val.remoteSurveyor.name}
                              </td>

                              <td>{val.SOP.name}</td>

                              <td>{convertDate(val.startDate, "-")}</td>
                              <td>{convertDate(val.endDate, "-")}</td>

                              {/* <td>{val.mode}</td> */}
                            

                              {/* <td>
                                {val.verifier === null
                                  ? "NA"
                                  : val.verifier.name === null ||
                                    val.verifier.name === undefined
                                    ? "NA"
                                    : val.verifier.name}
                              </td> */}


                              <td>{val.status}</td>
                              <td>
                                <span
                                  className="badge bg-primary-blue cursor_pointer"
                                  onClick={() => {
                                    generatePreview(val);
                                    setSurveyId(val.Id);
                                    setChecklistId(val.checklist.name);
                                  }}
                                >
                                  {"Verify"}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem disabled={pageNo === 1 ? true : false}>
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() => {
                              setPageNo((pg) => pg - 1);
                            }}
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{pageNo}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            pageNo === maxPage || maxPage === 0 ? true : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() => {
                              setPageNo((pg) => pg + 1);
                            }}
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                      <div>
                        <div className="d-flex justify-content-end">
                          <p className="f-12 text-muted mt-1">{`${pageNo} / ${maxPage} pages`}</p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : data.apiFired ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"There are no surveys !"}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {"Survey Preview"}
        </ModalHeader>
        <ModalBody>
          {/* <div className='pdfBlock d-flex justify-content-center' id='pdfBlock'>
                        <iframe id="frame" style={{ height: '80vh', width: '100%' }}></iframe>
                    </div> */}

          {/* < HtmlToPdf id={surveyId} cid={checklistId} /> */}
          <Show_suvey_report id={surveyId} cid={checklistId} type={type} />
          <div className="row mt-4">
            <div className="col-12">
              <div className="form-group">
                <label className="f-w-600">Comments</label>
                <textarea
                  className={
                    errors.comments
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  rows={"3"}
                  maxLength="200"
                  placeholder="Enter your comments"
                  name="comments"
                  {...register("comments")}
                  disabled={
                    filter === "Verified" || filter === "Correction Required"
                      ? true
                      : false
                  }
                // defaultValue={selectedSurveyDetails.data.verifier_activity?.length > 0 ?
                //     selectedSurveyDetails.data.verifier_activity[selectedSurveyDetails.data.verifier_activity?.length - 1].comments : ""}
                ></textarea>

                <span className="text-danger">{errors.comments?.message}</span>
              </div>
            </div>

            <div className="col-12 mt-2">
              <label className="f-w-600">Status</label>
              <div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
                <div className="radio radio-primary">
                  <input
                    id="radioinline1"
                    type="radio"
                    name="radio3"
                    value="Verified"
                    defaultChecked={surveyStatus === "Verified" ? true : false}
                    onClick={(e) => {
                      setSurveyStatus(e.target.value);
                    }}
                    disabled={
                      filter === "Verified" || filter === "Correction Required"
                        ? true
                        : false
                    }
                  />
                  <label className="mb-0" htmlFor="radioinline1">
                    {"Verified"}
                  </label>
                </div>
                <div className="radio radio-primary">
                  <input
                    id="radioinline2"
                    type="radio"
                    name="radio3"
                    value="Correction Required"
                    defaultChecked={
                      surveyStatus === "Correction Required" ? true : false
                    }
                    onClick={(e) => {
                      setSurveyStatus(e.target.value);
                    }}
                    disabled={
                      filter === "Verified" || filter === "Correction Required"
                        ? true
                        : false
                    }
                  />
                  <label className="mb-0" htmlFor="radioinline2">
                    {"Correction Required"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <div className="col-12 d-flex justify-content-end">
            <button
              onClick={handleSubmit(onSubmit)}
              className="btn btn-primary-blue"
              disabled={
                filter === "Verified" || filter === "Correction Required"
                  ? true
                  : false
              }
            >
              Submit
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default VerifierSurveyList;
