import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ImagePreview from '../../Common/Components/ImagePreview';
import { Paperclip, Video, Upload, Clipboard, MessageCircle } from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import '../../Common/Css/Common.css';
import { generateAgoraToken, } from '../../../../api/index';
import VideoCall from '../../Agora/VideoCall';
import UploadFile from '../ChecklistManagementNew/UploadFile';
import AnswerScreen from '../ChecklistManagementNew/AnswerScreen';
import { getCheckListSection, getSurvey } from '../../../../api/index';
import { getDevices, createMicrophoneAndCameraTracks } from "agora-rtc-sdk-ng";
import Attachments from './Attachments';
import bouncing_plastic_bottle from '../../../../assets/audio/bouncing_plastic_bottle.mp3'
import ChatRoom from '../../../dashboard/defaultCompo/surveyList/surveyDashboard/surveySectionList/chatRoom';

import AgoraRTC from 'agora-rtc-sdk-ng';
import {
    AgoraVideoPlayer, createCustomVideoTrack
} from "agora-rtc-react";


function SurveyDetails() {
    let location = useLocation();
    // const playAlert = require('alert-sound-notify')
    const sopDetails = location.state.SOP;
    const checklistDetails = location.state.checklist;
    const surveyId = location.state.Id;

    const customer_id = localStorage.getItem('customer_id');
    const params = useParams();
    const [chatContainer, setChatContainer] = useState(false);
    const [surveyDetails, setSurveyDetails] = useState({ data: [], loading: false });
    const [sectionList, setSectionList] = useState({ data: [], loading: false });
    const [isSectionSelected, setIsSectionSelected] = useState(false);
    const [checklistItems, setChecklistItems] = useState({ data: [] });
    const [imageModal, setImageModal] = useState();
    const [imageUrl, setImageUrl] = useState('');
    const [token, setToken] = useState({ data: "" });
    const [channelName, setChannelName] = useState("");
    const [inCall, setInCall] = useState(false);
    const [cameraMicTesting, setCameraMicTesting] = useState(false);
    const [dataForAttachmentUpload, setDataForAttachmentUpload] = useState('');
    const [fileUploadModal, setFileUploadModal] = useState();
    const [dataForAnswer, setDataForAnswer] = useState('');
    const [dataForAttachment, setDataForAttachment] = useState('');
    const [answerModal, setAnswerModal] = useState();
    const [attachmentModal, setAttachmentModal] = useState();
    const [devices, setDevices] = useState({ audiolist: [], videolist: [] });
    const [selAudDevice, setSelAudDevice] = useState({ data: {} });
    const [selVidDevice, setSelVidDevice] = useState({ data: {} });
    const [numberOfPidsToColor, setNumberOfPidsToColor] = useState(0);
    const numbers = [
        { num: 1, color: "#28a745" }, { num: 2, color: "#28a745" }, { num: 3, color: "#007bff" }, { num: 4, color: "#007bff" },
        { num: 5, color: "#ffc107" }, { num: 6, color: "#ffc107" }, { num: 7, color: "#ff5707" }, { num: 8, color: "#ff5707" },
        { num: 9, color: "#dc3545" }, { num: 10, color: "#dc3545" }
    ];
    const [localStream, setLocalStream] = useState({ data: '' });
    const [localAudioStream, setlocalAudioStream] = useState({ data: '' });
    const [intervalMicListen, setIntervalMicListen] = useState({ data: '' });

    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [myInterval, setMyInterval] = useState("");


    const appId = `${process.env.REACT_APP_AGORA_APPID}`; //ENTER APP ID HERE    

    useEffect(async () => {
        console.log("location.state", location.state);
        console.log("params", params);


        fetchSurveyDetails();

        setSectionList({ ...sectionList, data: [], loading: true });


        // setSectionList({ ...sectionList, data: checklistDetails.checklist_sections, loading: false });

        fetchChecklistSections();
        localStorage.setItem('urlToBlock', window.location.pathname);


        

    }, []);

   


    function fetchSurveyDetails() {
        setSurveyDetails({ ...surveyDetails, data: [], loading: true });
        getSurvey({ customer_id: parseInt(localStorage.getItem('customer_id')), Id: parseInt(params.id) }).then((res) => {
            console.log("res", res);

            surveyDetails.data = res.data.res;
            setSurveyDetails({ ...surveyDetails, data: surveyDetails.data, loading: false });
        }, (err) => {
            console.log("err", err);
        });
    }

    function fetchChecklistSections() {
        getCheckListSection({ checklist: location.state.checklist.Id }).then((res) => {
            console.log("res", res);

            setSectionList({ ...sectionList, data: res.data, loading: false });


            // If section is selected already this code will run and make that section active
            if (localStorage.getItem('selectedSec')) {
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].id === parseInt(localStorage.getItem('selectedSec'))) {
                            setChecklistItems({ ...checklistItems, data: res.data[i].section_checklist_item });
                            document.getElementById('section_row' + i).style.background = '#B1E1FF'
                        } else {
                            document.getElementById('section_row' + i).style.removeProperty("background");
                        }
                    }
                } else {
                    setChecklistItems({ ...checklistItems, data: [] });
                }
            }
        }, (err) => {
            console.log("err", err);
        });
    }

    const toggleImageModal = (val) => {
        setImageModal(!imageModal);
        if (imageModal === undefined || imageModal === false) {
            setImageUrl(val)
        }
    }

    const toggleFileUploadModal = (val) => {
        setDataForAttachmentUpload(val);
        setFileUploadModal(!fileUploadModal)
    }

    const toggleAnswerModal = (val) => {
        setDataForAnswer(val);
        setAnswerModal(!answerModal)
    }

    const toggleAttachmentModal = (val) => {
        setDataForAttachment(val);
        setAttachmentModal(!attachmentModal)
    }


    function selectSection(val, i) {
        console.log('val', val);
        console.log('i', i);
        console.log("sectionList.data", sectionList.data);

        setIsSectionSelected(true);
        setChecklistItems({ ...checklistItems, data: val.section_checklist_item });

        localStorage.setItem('selectedSec', val.id);
        localStorage.setItem('selectedSecName', val.sectionName);
        localStorage.setItem('selectedSecId', val.sectionId);

        for (let j = 0; j < sectionList.data.length; j++) {
            console.log('j', j);
            if (i === j) {
                document.getElementById('section_row' + i).style.background = '#B1E1FF'
            } else {

                document.getElementById('section_row' + j).style.removeProperty("background");
            }
        }
    }



    async function startVideoCall() {
        console.log("location", location);
        localStorage.setItem('GuestVideoCall', false);

        const user_id = parseInt(localStorage.getItem('user_id'));

        await generateAgoraToken({
            userId: user_id,
            channelName: location.state.Id.toString()
            // channelName: location.state.Id + "_" + location.state.checklist.Id
        }).then((res) => {
            console.log('res', res);
            token.data = res.data;
            setToken({ ...token, data: token.data });

            // setChannelName(location.state.Id + "_" + location.state.checklist.Id);
            setChannelName(location.state.Id.toString());

            setCameraMicTesting(true);

            // Find all audio and video devices
            getDevices().then(devices => {
                console.log("devices", devices);
                var audioDevList = [];
                var videoDevList = [];

                for (let i = 0; i < devices.length; i++) {
                    if (devices[i].kind == "audioinput") {
                        audioDevList.push(devices[i]);
                        console.log("audioDevList", audioDevList);
                    } else if (devices[i].kind == "videoinput") {
                        videoDevList.push(devices[i]);
                        console.log('videoDevList', videoDevList);
                    }
                }

                setDevices({ ...devices, audiolist: audioDevList, videolist: videoDevList });
                // setVideoDevices(videoDevList);
                // audioDevList[0].deviceId;
                // videoDevList[0].deviceId;

                selAudDevice.data = audioDevList[0].deviceId;
                setSelAudDevice({ ...selAudDevice, data: selAudDevice.data })

                selVidDevice.data = videoDevList[0].deviceId;
                setSelVidDevice({ ...selVidDevice, data: selVidDevice.data })
                testCamera('both');
            }).catch((err) => {
                console.log("err", err);
            })


        }, (err) => {
            console.log('err', err);
        });

        // console.log(token);
    }

    function testCamera(callBy) {
        createMicrophoneAndCameraTracks({ microphoneId: selAudDevice.data }, { cameraId: selVidDevice.data })
            .then((tracks) => {
                console.log("tracks", tracks);

                if (callBy === 'both') {
                    localStream.data = tracks[1];
                    setLocalStream({ ...localStream, data: localStream.data });


                    localAudioStream.data = tracks[0];
                    setlocalAudioStream({ ...localAudioStream, data: localAudioStream.data });

                    const video = document.querySelector('#test_stream');
                    // video.srcObject = localStream;
                    localStream.data.play(video);


                    intervalMicListen.data = setInterval(() => {
                        colorPids();
                    }, 500);
                    setIntervalMicListen({ ...intervalMicListen, data: intervalMicListen.data })
                } else if (callBy === 'camera') {
                    localStream.data = tracks[1];
                    setLocalStream({ ...localStream, data: localStream.data });
                    const video = document.querySelector('#test_stream');
                    localStream.data.play(video);


                } else if (callBy === 'mic') {
                    localAudioStream.data = tracks[0];
                    setlocalAudioStream({ ...localAudioStream, data: localAudioStream.data });

                    intervalMicListen.data = setInterval(() => {
                        colorPids();
                    }, 500);
                }


            }, (error) => {
                console.log(error);
            });
    }

    function colorPids() {
        var vol = localAudioStream.data.getVolumeLevel() * 100;
        setNumberOfPidsToColor(Math.round(vol / 10));
        // numberOfPidsToColor = Math.round(vol / 10);
        // console.log("numberOfPidsToColor", numberOfPidsToColor);
        // console.log("Math.round(vol / 10)", Math.round(vol / 10));
    }


    function changeMic(e) {
        console.log("e", e.target.value);
        selAudDevice.data = e.target.value;
        setSelAudDevice({ ...selAudDevice, data: selAudDevice.data })


        console.log("selAudDevice.data", selAudDevice.data);
        // console.log("selVidDevice.data", selVidDevice.data);

        stopTestCamera('mic');

        setTimeout(() => {
            testCamera('mic');
        }, 1000);
    }

    function changeCamera(e) {
        console.log("e", e.target.value);
        selVidDevice.data = e.target.value;
        setSelVidDevice({ ...selVidDevice, data: selVidDevice.data })

        console.log("selVidDevice.data", selVidDevice.data);

        stopTestCamera("camera");

        setTimeout(() => {
            testCamera('camera');
        }, 1000);
    }


    function startTimer(option) {
        if (option === "start") {
            console.log("start calling");

            var count = 0;
            setMyInterval(
                setInterval(() => {
                    // console.log("3 sec");
                    var data = localStorage.getItem('videoCallDetails');
                    data = JSON.parse(data);
                    // console.log('data', JSON.parse(data));
                    data.push({
                        TimeStamp: new Date(),
                        activity: 'InProgress',
                        duration: parseInt(data[count].duration) + 3,
                        customer_id: localStorage.getItem('customer_id'),
                        vdi_userid: localStorage.getItem('vdi_User_Id'),
                        channel_id: surveyId
                    })
                    // console.log('data', data);
                    count++;
                    localStorage.setItem('videoCallDetails', JSON.stringify(data));

                }, 3000)
            );
        } else if (option === "end") {
            console.log("end calling");
            clearInterval(myInterval);
        }
    }

    const startCall = () => {
        setStartTime(new Date().toTimeString().split(" ")[0]);
        startTimer("start");

        const data = [{
            TimeStamp: new Date(),
            activity: 'Call_Start',
            duration: 0,
            customer_id: localStorage.getItem('customer_id'),
            vdi_userid: localStorage.getItem('vdi_User_Id'),
            channel_id: surveyId
        }]

        localStorage.setItem('videoCallDetails', JSON.stringify(data));
        localStorage.setItem('callStart', new Date());
    };

    const endCall = () => {
        console.log("**************************CALL ENDING********************************");
        setEndTime(new Date().toTimeString().split(" ")[0]);
        startTimer("end");


        var data = localStorage.getItem('videoCallDetails');
        data = JSON.parse(data);
        // console.log('data', JSON.parse(data));
        data.push({
            TimeStamp: new Date(),
            activity: 'Call_End',
            duration: parseInt(data[data.length - 1].duration) + 3,
            customer_id: localStorage.getItem('customer_id'),
            vdi_userid: localStorage.getItem('vdi_User_Id'),
            channel_id: surveyId
        })
        console.log('data', data);

        localStorage.setItem('videoCallDetails', JSON.stringify(data));
    };


    function stopTestCamera(val) {
        console.log('localStream.data', localStream.data);

        if (val === 'mic') {
            if (localAudioStream.data) {
                localAudioStream.data.stop();
                localAudioStream.data.close();
            }
            clearInterval(intervalMicListen.data);
            // numberOfPidsToColor = 0;
            setNumberOfPidsToColor(0);
        }

        if (val === 'camera') {
            if (localStream.data) {
                localStream.data.stop();
                localStream.data.close();
            }
        }

        if (val === 'startVideoCall') {
            setCameraMicTesting(false);

            setTimeout(() => {
                setInCall(true);
            }, 500);


            startCall();

        }
    }




    function playSound() {
        console.log('play');
        var x = document.getElementById("myAudio");
        x.play();
    }





    return (
        <React.Fragment>
           

            <audio id="myAudio">
                <source src={bouncing_plastic_bottle} type="audio/mpeg">
                </source>
            </audio>

            <ImagePreview
                toggleImageModal={toggleImageModal}
                imageModal={imageModal}
                imageUrl={imageUrl}
            />

            <UploadFile
                surveyId={surveyId}
                dataForAttachmentUpload={dataForAttachmentUpload}
                toggleFileUploadModal={toggleFileUploadModal}
                fetchChecklistSections={fetchChecklistSections}
                fileUploadModal={fileUploadModal} />

            <AnswerScreen
                surveyId={surveyId}
                answerModal={answerModal}
                toggleAnswerModal={toggleAnswerModal}
                sopDetails={sopDetails}
                dataForAnswer={dataForAnswer}
                fetchChecklistSections={fetchChecklistSections}
            />

            <Attachments
                surveyId={surveyId}
                dataForAttachment={dataForAttachment}
                toggleAttachmentModal={toggleAttachmentModal}
                attachmentModal={attachmentModal}
            />



            <div className='vdi_survey py-4'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='title bg-primary-blue d-flex justify-content-between ' id="card_title">
                                <p className='mb-0 f-24'>Survey Details</p>

                                {/* <div
                                    className='d-flex align-items-center cursor_pointer'
                                    id='video_call_btn'
                                    style={{ padding: '8px 20px 8px 20px', borderRadius: '8px', }}
                                    onClick={() => { if (!inCall && !cameraMicTesting) { startVideoCall() } }}

                                >
                                    <p className='mb-0'>Make A Call</p>
                                    <Video
                                        className='ms-2'
                                        style={{ color: '#fff' }}
                                        height={'18px'}
                                        width={'18px'}
                                        id={"videoCall"}
                                    />
                                </div> */}
                            </div>
                            <div className='card-body'>

                                <div className='row'>

                                    <div className='col-12 mb-4'>
                                        <div className='card shadow-sm' style={{ border: '1px solid #c6c6c6', background: "#f5f5f5" }}>
                                            <div className='card-body'>

                                                <hr className='my-1' />
                                                <p className='f-20 f-w-600 mb-0'>Survey details</p>
                                                <hr className='my-1' />
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <p className='f-16 mb-0 f-w-600 me-4'>{"Comments"}</p>
                                                    <p className='f-16 mb-0 '>{location.state.remarks === null || location.state.remarks === undefined ? "NA" : location.state.remarks}</p>
                                                </div>


                                                <hr className='mb-1 mt-4' />
                                                <p className='f-20 f-w-600 mb-0'>Instructions & Guidelines details</p>
                                                <hr className='my-1' />
                                                <div className='d-flex justify-content-between'>

                                                    <p className='f-16 mb-0 f-w-600 me-4'>{"Name"}</p>
                                                    <p className='f-16 mb-0'>{sopDetails.name}</p>
                                                </div>

                                                <hr className='mb-1 mt-4' />
                                                <p className='f-20 f-w-600 mb-0'>Checklist details</p>
                                                <hr className='my-1' />

                                                <div className='d-flex justify-content-between'>
                                                    <p className='f-16 mb-0 f-w-600 me-4'>{"Name"}</p>
                                                    <p className='f-16 mb-0'>{checklistDetails.name}</p>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <p className='f-16 mb-0 f-w-600 me-4'>{"Description"}</p>
                                                    <p className='f-16 mb-0 '>{checklistDetails.description}</p>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <p className='f-16 mb-0 f-w-600 me-4'>{"Survey Remarks"}</p>
                                                    <p className='f-16 mb-0 '>{location.state.remarks === null || location.state.remarks === undefined ? "NA" : location.state.remarks}</p>
                                                </div>

                                            </div>
                                        </div>


                                    </div>


                                    {cameraMicTesting ?

                                        <div className='col-12 col-md-5'>

                                            <div className='card shadow-sm' style={{ border: '1px solid #c6c6c6' }}>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label className='f-w-600'>Camera</label>
                                                                <select
                                                                    className="form-control"
                                                                    onChange={(e) => changeCamera(e)}
                                                                    id="cameraId"
                                                                    defaultValue="1">
                                                                    {devices.videolist.map((val, i) => (
                                                                        <option key={i} value={val.deviceId}>{val.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div id="test_stream" className='my-2 mx-auto' style={{ width: "80%", height: "40vh", }}></div>

                                                            <div className='form-group'>
                                                                <label className='f-w-600'>Microphone</label>
                                                                <select
                                                                    className="form-control"
                                                                    onChange={(e) => changeMic(e)}
                                                                    id="microphone"
                                                                    defaultValue="1">
                                                                    {devices.audiolist.map((val, i) => (
                                                                        <option key={i} value={val.deviceId}>{val.label}</option>
                                                                    ))}
                                                                </select>

                                                                <div className='mt-4 d-flex justify-content-center'>
                                                                    <i className="fa fa-microphone fa-lg"></i>

                                                                    <div className='d-flex'>
                                                                        {numbers.map((val, i) => (
                                                                            <div key={i} className='pid ms-2'
                                                                                style={{ background: numberOfPidsToColor >= val.num ? val.color : '#e6e7e8', height: '14px', width: '10px' }}></div>
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                                <div className='mt-4 d-flex justify-content-center'>
                                                                    <button className='btn btn-primary-blue mx-auto'
                                                                        onClick={() => playSound()}
                                                                    >Test Speakers</button>
                                                                    <button
                                                                        className='btn btn-dark mx-auto'
                                                                        onClick={() => { stopTestCamera('startVideoCall') }}
                                                                    >Make A Call</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {inCall ?
                                        <div className='col-12 col-md-5'>
                                            {
                                                inCall ?
                                                    <VideoCall
                                                        setInCall={setInCall}
                                                        channelName={channelName}
                                                        appId={appId}
                                                        token={token}
                                                        endCall={endCall} />
                                                    : null
                                            }
                                        </div>
                                        :
                                        null
                                    }


                                    <div className={inCall || cameraMicTesting ? "col-12 col-md-7" : "col-12"} id={inCall || cameraMicTesting ? "cheklistSectionDiv" : ""}>

                                        <hr />

                                        <p className='f-18 f-w-600 mb-0'>Checklist Sections</p>
                                        <hr />



                                        {sectionList.loading ?
                                            <div className="loader-box justify-content-center">
                                                <div className="loader">
                                                    <div className="line bg-dark"></div>
                                                    <div className="line bg-dark"></div>
                                                    <div className="line bg-dark"></div>
                                                    <div className="line bg-dark"></div>
                                                </div>
                                            </div>
                                            :
                                            sectionList.data.length > 0 ?
                                                <div className="table-responsive " id='Checklist_section_table'>
                                                    <table className="table table-bordered ">
                                                        <thead className='thead-light'>
                                                            <tr className=''>
                                                                <th scope="col">{"SectionId"}</th>
                                                                <th scope="col">{"SectionName"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {sectionList.data.map((val, i) => (
                                                                <tr key={i} id={'section_row' + i} className=' cursor_pointer' onClick={() => selectSection(val, i)}>
                                                                    <td disabled style={{ width: '120px' }}>{val.sectionId}</td>
                                                                    <td disabled>{val.sectionName}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                :

                                                <p className='text-danger mb-0 f-20 text-center mt-4'>{"Section list is empty"}</p>
                                        }



                                        <hr />
                                        <p className='f-18 f-w-600 mb-0'>Checklist Items</p>
                                        <hr />


                                        {
                                            checklistItems.data.length > 0 ?
                                                <div className="table-responsive " id='survey_details_table'>
                                                    <table className="table table-bordered ">
                                                        <thead className='thead-light'>
                                                            <tr className=''>
                                                                <th scope="col">{"SrNo"}</th>
                                                                <th scope="col">{"Description"}</th>

                                                                <th scope="col">{"Comments"}</th>
                                                                <th scope="col">{"Photos"}</th>
                                                                <th scope="col">{"Videos"}</th>
                                                                <th scope="col">{"OG"}</th>
                                                                {/* <th scope="col">{"Attachments"}</th> */}
                                                                <th scope="col" style={{ width: '120px' }}>{"Action"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {checklistItems.data.map((val, i) => (
                                                                <tr key={i} className=''>
                                                                    <td scope="row">{val.SrNo === null ? "NA" : val.SrNo}</td>
                                                                    <td>{val.section}</td>

                                                                    <td>{val.comments}</td>
                                                                    <td>{val.photos}</td>
                                                                    <td>{val.videos}</td>
                                                                    <td>{val.OG}</td>

                                                                    {/* <td className=''>
                                                                        {val.check_list_attachment.length > 0 ?
                                                                            val.check_list_attachment.map((value, i) => (
                                                                                value.withAnswer === false ?
                                                                                    value.fileType === "Image" ?
                                                                                        <React.Fragment key={i}>
                                                                                            <Image
                                                                                                onClick={() => { toggleImageModal(value); }}
                                                                                                className='cursor_pointer me-1  '
                                                                                                style={{ color: '#313131' }}
                                                                                                height={'18px'}
                                                                                                width={'18px'}
                                                                                                id={"image" + i}
                                                                                            />
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        value.fileType === "Video" ?
                                                                                            <React.Fragment key={i}>
                                                                                                <Film
                                                                                                    onClick={() => { window.open(value.checklist_file, '_blank').focus() }}
                                                                                                    className='cursor_pointer me-1  '
                                                                                                    style={{ color: '#313131' }}
                                                                                                    height={'18px'}
                                                                                                    width={'18px'}
                                                                                                    id={"video" + i}
                                                                                                />
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            value.fileType === "Document" ?
                                                                                                <React.Fragment key={i}>
                                                                                                    <FileText
                                                                                                        onClick={() => { window.open(value.checklist_file, '_blank').focus() }}
                                                                                                        className='cursor_pointer me-1  '
                                                                                                        style={{ color: '#313131' }}
                                                                                                        height={'18px'}
                                                                                                        width={'18px'}
                                                                                                        id={"file" + i}
                                                                                                    />
                                                                                                </React.Fragment>
                                                                                                : null
                                                                                    :
                                                                                    null
                                                                            ))
                                                                            :
                                                                            "NA"}
                                                                    </td> */}
                                                                    <td>
                                                                        <Upload
                                                                            onClick={() => { toggleFileUploadModal(val); }}
                                                                            style={{ color: '#313131' }}
                                                                            id={"uploadFile" + i}
                                                                            className='cursor_pointer'
                                                                            height={'18px'}
                                                                            width={'18px'} />
                                                                        <UncontrolledTooltip placement="bottom" target={"uploadFile" + i}>
                                                                            {"Upload file"}
                                                                        </UncontrolledTooltip>


                                                                        <Clipboard
                                                                            onClick={() => { toggleAnswerModal(val); }}
                                                                            style={{ color: '#313131' }}
                                                                            id={"viewanswer" + i}
                                                                            className='cursor_pointer ms-1'
                                                                            height={'18px'}
                                                                            width={'18px'} />
                                                                        <UncontrolledTooltip placement="bottom" target={"viewanswer" + i}>
                                                                            {"View Answers"}
                                                                        </UncontrolledTooltip>

                                                                        <Paperclip
                                                                            onClick={() => { toggleAttachmentModal(val); }}
                                                                            style={{ color: '#313131' }}
                                                                            id={"viewattachments" + i}
                                                                            className='cursor_pointer ms-1'
                                                                            height={'18px'}
                                                                            width={'18px'} />
                                                                        <UncontrolledTooltip placement="bottom" target={"viewattachments" + i}>
                                                                            {"View Attachments"}
                                                                        </UncontrolledTooltip>
                                                                        {/* 
                                                                        <i className="icofont icofont-clip ms-1 f-18"
                                                                            id={"viewattachments" + i}
                                                                            style={{ color: '#313131', }}
                                                                            onClick={() => { toggleAttachmentModal(val); }}
                                                                        ></i>
                                                                        <UncontrolledTooltip placement="bottom" target={"viewattachments" + i}>
                                                                            {"View Attachments"}
                                                                        </UncontrolledTooltip> */}

                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                :
                                                isSectionSelected ?
                                                    <p className='text-danger mb-0 f-20 text-center mt-4'>{"No items present in section"}</p>
                                                    :
                                                    <p className='text-danger mb-0 f-20 text-center mt-4'>{"Please select a section"}</p>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-md-7"></div>
                    <div className="col-md-5">
                        {chatContainer ? <ChatRoom /> : ""}
                    </div>
                </div> */}
            </div>

            <button
                className='shadow'
                onClick={() => setChatContainer(!chatContainer)}
                style={{
                    background: "#2264AB",
                    color: "#FFF",
                    position: "fixed",
                    width: "50px",
                    height: "50px",
                    bottom: "20px",
                    right: "40px",
                    borderRadius: "50px",
                    textAlign: "center",
                    border: 'none',
                    zIndex: '999'
                }}
            >
                <MessageCircle
                    className='cursor_pointer '
                    height={'18px'}
                    width={'18px'} />
            </button>
        </React.Fragment >
    )
}

export default SurveyDetails