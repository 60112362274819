import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getChecklistItemAttachment } from "../../../../api/index";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import fileimg from "../../../../assets/images/Vedam/fileimg.png";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { delChecklistItemAttachment } from "../../../../api/index";
import { XCircle } from "react-feather";

function PhotoVideoGallery(props) {
  const [modal8, setModal8] = useState();
  const [onlySurveyFile, setOnlySurveyFiles] = useState({
    data: [],
    loading: false,
  });
  const { id } = useParams();

  const toggle8 = () => {
    setModal8(!modal8);
  };

  useEffect(async () => {
    console.log("modal8", modal8);
    if (modal8 === true) {
      setOnlySurveyFiles({ ...onlySurveyFile, data: [], loading: true });
      fetchDoc();
    }
  }, [modal8]);

  async function fetchDoc() {
    const payloadAttForSurvey = {
      survey: id,
      withSurveyOnly: "False",
      withAnswer: "False",
      withTable: "False"
    };
    console.log("payloadAttForSurvey", payloadAttForSurvey);
    await getChecklistItemAttachment(payloadAttForSurvey).then(
      (res) => {
        console.log("res", res);
        setOnlySurveyFiles({
          ...onlySurveyFile,
          data: res.data,
          loading: false,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const [basic_settings, setBasicSetting] = useState({
    dots: true,
    infinite: true,
    speed: 100,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    adaptiveHeight: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  function deleteAttachment(data) {
    console.log("data", data);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        delChecklistItemAttachment({ Id: data.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Attachment deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            fetchDoc();
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    });
  }

  return (
    <React.Fragment>
      <button onClick={toggle8} className="btn btn-primary-blue">
        {props.data === "image" ? "Image " : "Video "} Gallery
      </button>

      <Modal isOpen={modal8} toggle={toggle8} size="lg">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {props.data === "image" ? "Image Gallery" : "Video Gallery"}
        </ModalHeader>
        <ModalBody>
          {onlySurveyFile.loading ? (
            <div className="loader">
              <div className="line bg-dark"></div>
              <div className="line bg-dark"></div>
              <div className="line bg-dark"></div>
              <div className="line bg-dark"></div>
            </div>
          ) : onlySurveyFile.data.length > 0 ? (
            <div className="pb-4">
              {props.data === "image" ? (
                <Slider {...basic_settings} className="mb-2 mb-md-0">
                  {onlySurveyFile.data.map((img, i) =>
                    img.fileType == "Image" ? (
                      <div id="attachment_parent" key={i}>
                        <div className="image_attachments m-2">
                          <img
                            onClick={() =>
                              window.open(img.checklist_file, "_blank").focus()
                            }
                            style={{ borderRadius: "20px" }}
                            className="cursor_pointer"
                            src={img.checklist_file}
                            alt=""
                            height={"150"}
                            width={"100%"}
                          />

                          <div className="date_overlay">{img.name}</div>
                        </div>

                        <div
                          style={{
                            background: "white",
                            height: "18px",
                            width: "18px",
                            position: "absolute",
                            borderRadius: "50%",
                            top: "2px",
                            right: "2px",
                          }}
                        >
                          <XCircle
                            onClick={() => deleteAttachment(img)}
                            className="cursor_pointer"
                            style={{
                              color: "red",
                            }}
                            height={"18px"}
                            width={"18px"}
                          />
                        </div>
                      </div>
                    ) : null
                  )}
                </Slider>
              ) : props.data == "video" ? (
                <Slider {...basic_settings} className="mb-2 mb-md-0">
                  {onlySurveyFile.data.map((img, i) =>
                    img.fileType == "Video" ? (
                      <div id="attachment_parent" key={i}>
                        <div className="image_attachments m-2">
                          <img
                            onClick={() =>
                              window.open(img.checklist_file, "_blank").focus()
                            }
                            style={{ borderRadius: "20px" }}
                            className="cursor_pointer"
                            src={fileimg}
                            alt=""
                            height={"150"}
                            width={"100%"}
                          />

                          <div className="date_overlay">{img.name}</div>
                        </div>

                        <div
                          style={{
                            background: "white",
                            height: "18px",
                            width: "18px",
                            position: "absolute",
                            borderRadius: "50%",
                            top: "2px",
                            right: "2px",
                          }}
                        >
                          <XCircle
                            onClick={() => deleteAttachment(img)}
                            className="cursor_pointer"
                            style={{
                              color: "red",
                            }}
                            height={"18px"}
                            width={"18px"}
                          />
                        </div>
                      </div>
                    ) : null
                  )}
                </Slider>
              ) : null}
            </div>
          ) : (
            <p className="text-danger text-center">No Document</p>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default PhotoVideoGallery;
