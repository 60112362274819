import React, { useState, useEffect } from 'react';
import { getSurvey, getSurveyV2 } from '../../../../api/index';
import { useNavigate, useParams } from "react-router-dom";
import { ModalHeader, Pagination, PaginationItem, PaginationLink, UncontrolledTooltip } from "reactstrap";
import { Search, Video, FileText, Bell, BellOff, MessageCircle } from "react-feather";
import DataTable from "react-data-table-component";
import { Modal, ModalBody, ModalFooter, } from "reactstrap";
import { getData } from "../../../../api/APIinstance";
import Slider from "react-slick";
import SurveyReport from '../SurveyReport';
import { toast } from "react-toastify";
import { Show_suvey_report } from './Show_suvey_report';
import { getAuth } from 'firebase/auth';
import db from '../../../../data/config';
import { calMaxPage } from '../../../ProjectComponents/Common/Functions/CommonFunctions';

function SurevyList2() {
    const ref = db.collection("notifications");
    const guestUserdata = JSON.parse(localStorage.getItem("guestUserData"));
    const GuestVideoCall = JSON.parse(localStorage.getItem("GuestVideoCall"));
    const emailAddress = JSON.parse(localStorage.getItem("login_data"));

    const { name, vid } = useParams();
    const navigate = useNavigate();
    const localData = JSON.parse(localStorage.getItem("login_data"));
    const [checklistId, setChecklistId] = useState('');
    const [searched, setsearched] = useState("");
    const [filtered, setfiltered] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [surveyData, setSurveyData] = useState({ data: [], loading: false, status: "" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [columnsdata, setColumnsData] = useState();
    const [modal, setModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [modalLoader, setmodalLoader] = useState(false);
    const [recordedVideo, setRecordedVideo] = useState([]);
    const [preview, setPreview] = useState();
    const [surveyIdForReport, setSurveyIdForReport] = useState("");
    const [currentPage, setCurrentPage] = useState({ data: 1 });
    const [survey_type, setSurvey_type] = useState("")
    const [totalRecords, setTotalRecords] = useState("");
    const [entriesPerPage, setentriesPerPage] = useState(10);
    const [pageNo, setpageNo] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [search, setSearch] = useState();


    let columns = [

        {
            name: "Survey No",
            selector: (row) => (row?.surveyNo === null ? "N/A" : row?.surveyNo),
            sortable: true,
            center: true,
        },
        {
            name: "Survey Name",
            selector: (row) =>
                row?.checklist === null ? (
                    <span className="shadow-lg" id="redDot"></span>


                ) : (

                    row?.checklist?.name

                ),


            sortable: true,
            center: true,
        },
        {
            name: "Vessel",
            selector: (row) => row?.vessel?.name,
            sortable: true,
            center: true,
        },
        {
            name: "Verifier",
            selector: (row) => row?.verifier?.name,
            sortable: true,
            center: true,
        },
        {
            name: "Status",
            selector: (row) => (
                <p
                    className="px-1"
                    style={{
                        margin: "0px",
                        background: row.status === "Active" ?
                            "#708090" :
                            row.status === "InProgress" ? "#EF5B0C" :
                                row.status === "Completed" ? "#FFB200" :
                                    row.status === "Signed" ? "#224B0C" :
                                        row.status === "Verified" ? "#2264AB" :
                                            row.status === "Correction Required" ? "#C21010" : null
                        ,
                        // "#2246AB",
                        color: "#FFF",
                        borderRadius: "10px",
                    }}
                >
                    {row?.status == "Active"
                        ? "Assigned"
                        : row?.status == "Signed"
                            ? "Submitted"
                            : row?.status}
                </p>
            ),
            sortable: true,
            center: true,
        },
        {
            name: "Start Date",
            selector: (row) => {
                const dateto = row?.startDate.split("-").join();
                // console.log("dateto", dateto);
                var d = new Date(row?.startDate).toLocaleString();
                // console.log("d", d);
                return d;
            },
            sortable: true,
            center: true,
        },
        {
            name: "End Date",
            selector: (row) => {
                const dateto = row?.endDate.split("-").join();
                // console.log("dateto", dateto);
                var d = new Date(row?.endDate).toLocaleString();
                // console.log("d", d);
                return d;
            },
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: "_id",
            sortable: true,
            cell: (row) => (
                <div className='d-flex justify-content-center'>

                    <Video onClick={() => {
                        toggle(row?.Id)
                    }} id="recordings" />
                    <UncontrolledTooltip placement="bottom" target={"recordings"}>
                        {"View Call Recordings"}
                    </UncontrolledTooltip>

                    {row?.status === "Active" ? null :
                        <>
                            <FileText
                                style={{ marginLeft: "5px" }}
                                onClick={() => {
                                    toggleReportModal(row?.Id);
                                    setChecklistId(row?.checklist.name);
                                    setSurvey_type(row?.surveyType)
                                }}
                                id="viewReport"
                            />
                            <UncontrolledTooltip placement="bottom" target={"viewReport"}>
                                {"View Report"}
                            </UncontrolledTooltip>
                        </>
                    }
                    <div>

                        <span
                            style={{
                                // background: "red",
                                width: "auto",
                                height: "auto",
                                borderRadius: "12px",
                                position: "absolute",
                                top: "0px",
                            }}
                            className='btn-primary-blue'
                        >
                            <p
                                className="m-0"
                                style={


                                    { padding: "2px 4px" }
                                }
                            >
                                {row.notification !== null && row.notification !== undefined ? row.notification
                                    : 0}
                            </p>

                        </span>
                        <Bell />
                    </div>



                </div>
            ),
        },
    ];

    const customStyles = {
        rows: {
            style: {
                color: "#000",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
            },
        },
        headCells: {
            style: {
                color: "white",
                background: "#2264AB",
                fontSize: "18px",
                fontWeight: "600",
                fontFamily: "roboto",
            },
        },
        cells: {
            style: {
                color: "#000",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
            },
        },
    };


    function handleRoute(data) {
        console.log("data", data);

        localStorage.setItem("SurveyData", data.Id);
        if (data.checklist === null) {
            toast.error(`Cannot proceed, checklist not available !`, {
                autoClose: 3000,
                theme: "light",

            });
        } else {
            if (data.remoteSurveyor !== null) {
                localStorage.setItem("siteSurveyorId", data.remoteSurveyor.Id);
            } else {
                localStorage.removeItem("siteSurveyorId");
            }

            if (data.status === "Active") {
                navigate(
                    `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/${data.Id}/${data.checklist.Id}`, { state: { data } }
                );
            }
            if (data.status === "InProgress") {

                navigate(
                    `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/${data.Id}/${data.checklist.Id}`, { state: { data } }
                );
            }
            if (data.status === "Completed") {
                // navigate(
                //     `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/${data.Id}`
                // );
                navigate(
                    `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/conductsurvey/${data.Id}/${data.checklist.Id}`, { state: { data } }
                );
            }
            if (data.status === "Verified") {
                console.log("runininingingingigngin");
                navigate(
                    `${process.env.PUBLIC_URL}/surveyList/surveyDashboard/surveySectionList/previewpage/v2/${data.Id}/${data.checklist.Id}`, { state: { data } }
                );
            }

            if (data.status === "Signed") {
                console.log("runininingingingigngin");
                navigate(
                    `${process.env.PUBLIC_URL}/surveyList/surveyDashboard/surveySectionList/previewpage/v2/${data.Id}/${data.checklist.Id}`, { state: { data } }
                );
            }

            if (
                data.status === "Correction Required"
            ) {
                navigate(
                    `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/conductsurvey/${data.Id}/${data.checklist.Id}`, { state: { data } }
                );
            }
        }
    }

    const toggle = async (id) => {
        if (!modal) {
            setmodalLoader(true);
            let resFromRecording = await getData(`SurveyRecordings/?survey=${id}`);
            let filteredData = resFromRecording.filter((el) => !!el.url);
            setRecordedVideo(filteredData);
            setmodalLoader(false);
        } else {
            setPreview("");
        }
        setModal(!modal);
    };

    const toggleReportModal = async (id) => {
        if (!reportModal) {
            setReportModal(true);
            setSurveyIdForReport(id);
        }
        setReportModal(!reportModal);
    };

    useEffect(() => {
        localStorage.removeItem("camData");
        setColumnsData(columns);
        fetchSurveysAsStatus();

    }, [name, vid]);


    function fetchSurveysAsStatus(para1,value) {

       
        let x=pageNo;
        if(value){
             x=pageNo+value;
        }
        console.log("page",x)

        
        var reqPayload = {
            customer_id: localData.customer_id,
            user: localData.user, status: '',
            pageNo: x,
            entriesPerPage: entriesPerPage
        }



        if (para1 || search) {
            reqPayload = {
                customer_id: localData.customer_id,
                user: localData.user,
                status: '',
                pageNo: x,
                entriesPerPage: entriesPerPage,
                searchVal: para1 == undefined ? search : para1,

            };
        }

        if (name !== 'na') {
            if (name === 'Active') {
                reqPayload.status = 'Active'
            } else if (name === 'InProgress') {
                reqPayload.status = 'InProgress'
            } else if (name === 'Completed') {
                reqPayload.status = 'Completed'
            } else if (name === 'Signed') {
                reqPayload.status = 'Signed'
            } else if (name === 'Verified') {
                reqPayload.status = 'Verified'
            } else if (name === 'Correction') {
                reqPayload.status = 'Correction Required'
            } else {
                delete reqPayload.status
            }
        } else {
            delete reqPayload.status
            reqPayload.vessel = vid;
        }

        setSurveyData({ ...surveyData, loading: true, data: [], status: "" });

        console.log("reqpayload",reqPayload)

        getSurveyV2(reqPayload).then(async (res) => {
            console.log("res", res);

            if (res.data?.res?.length > 0) {
                // setTotalRows(res.data.filtercount);

                setpageNo(x)

                // const myPromise = new Promise(async (resolve, reject) => {
                let apiSurveyRes = await Promise.all(res.data.res.map(async (item, ind) => {

                    // let dataPromises = await calUnreadMsgCount(item.Id, res.data.res)
                    getAuth();
                    let a;
                    //.where(firebase.firestore.FieldPath.documentId(), “in”, listOfReferences)
                    try {
                        console.log("item.Id", item.Id)
                        const resultFD = await ref
                            .where("surveyId", "==", parseInt(item.Id))
                            .where(
                                "from",
                                "!=",
                                GuestVideoCall === true ? guestUserdata.email : emailAddress?.emailId
                            )
                            .get();
                        console.log("resultFD", resultFD);
                        const items = [];
                        resultFD.docs.map(doc => {
                            let docData = doc.data();
                            console.log("docData", docData);

                            if (
                                docData.seenBy.includes(
                                    GuestVideoCall === true
                                        ? guestUserdata.email
                                        : emailAddress?.emailId
                                )
                            ) {
                            } else {
                                items.push(docData);
                            }

                        });
                        console.log("items.length", items.length)
                        item.notification = items.length;


                    } catch (err) {
                        console.log("err", err);
                    }
                }))
                console.log("apiSurveyRes", apiSurveyRes);


                // });



                // console.log("last");
                console.log("res.data.res", res.data.res);
                setSurveyData({ ...surveyData, loading: false, data: res.data.res, status: reqPayload.status });
                setTotalRecords(res.data.count);
                let counts;
                if (para1 !== "" && search !== "") {
                    counts = calMaxPage(res.data.count);
                    setMaxPage(counts)
                }
                else {
                    counts = calMaxPage(res.data.count);
                    setMaxPage(counts)
                }




            } else {
                setTotalRecords(0);
                setSurveyData({ ...surveyData, loading: false, data: [], status: reqPayload.status });
            }
        }, (err) => {
            console.log("err", err);

        });
    }



    function searchHandle(e) {
        let a = e.target.value;
        console.log("a", a);
        setsearched(a);
        if (a == "") {
            setfiltered([]);
        } else {
            const f = surveyData.data.filter((el) => {
                return (
                    el.checklist?.name.toLowerCase().includes(a.toLowerCase()) ||
                    el.surveyNo.toLowerCase().includes(a.toLowerCase())
                );
            });
            setfiltered(f);
        }
    }


    var basic_settings2 = {
        dots: false,
        infinite: false,
        speed: 100,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        adaptiveHeight: true,
        centerPadding: "10px",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    background: "#000000",
                    borderRadius: 50,
                }}
                onClick={onClick}
            />
        );
    }
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    background: "#000000",
                    borderRadius: 50,
                }}
                onClick={onClick}
            />
        );
    }

    const handlePageChange = page => {
        // fetchUsers(page);
        // setCurrentPage(page);
        console.log("page", page);
        currentPage.data = page;
        setCurrentPage({ ...currentPage, data: currentPage.data });
        fetchSurveysAsStatus();
    };


    async function calUnreadMsgCount(id, surData) {
        getAuth();
        let a;
        //.where(firebase.firestore.FieldPath.documentId(), “in”, listOfReferences)
        try {
            console.log("id", id)
            await ref
                .where("surveyId", "==", parseInt(id))
                .where(
                    "from",
                    "!=",
                    GuestVideoCall === true ? guestUserdata.email : emailAddress?.emailId
                )
                // .orderBy("time")
                .onSnapshot((querySnapshot) => {
                    const items = [];
                    querySnapshot.forEach((doc) => {
                        console.log(doc);

                        const msgData = doc.data();
                        // items.push(doc.data());
                        // items.push(doc);

                        if (
                            msgData.seenBy.includes(
                                GuestVideoCall === true
                                    ? guestUserdata.email
                                    : emailAddress?.emailId
                            )
                        ) {
                        } else {
                            items.push(msgData);
                        }
                    });
                    console.log("items 0", items.length);



                    a = items.length;
                    return a
                    // console.log("a", a);
                    // tempS = [...surData]
                    // console.log("tempS", tempS);
                    // for (let i = 0; i < tempS.length; i++) {
                    //     if (tempS[i].Id == id) {
                    //         tempS[i].notification = a
                    //     }
                    // }
                    // setSurveyData({ ...surveyData, data: tempS });
                    // console.log("tempS", tempS);

                });
            // return a
        } catch (error) {
            return 0
        }

    }


    return (
        <React.Fragment>
            <div className="container-fluid" style={{ fontFamily: "roboto" }}>
                <div className="row">
                    <div className="col-xl-12 xl-100">
                        <div className="card mt-2 overflow-hidden">
                            <div
                                className="card-header text-light d-flex"
                                style={{ background: "#2264AB", padding: "12px 24px" }}
                            >
                                <span
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                    className="text-light"
                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                >
                                    <i className="fa fa-arrow-circle-left"></i>
                                </span>
                                <span
                                    className="text-light"
                                    style={{ fontSize: "24px", marginLeft: "15px" }}
                                >
                                    {/* // {surveyData.data?.length && surveyData.data[0].vessel.name} / Surveys */}
                                    {surveyData.status == "Signed" ? "Submitted Surveys" : surveyData.status == "Active" ? "Assigned Surveys" : surveyData.status == undefined ? "Surveys" : `${surveyData.status} Surveys`}
                                </span>
                            </div>
                            <div className="card-body">


                                <div className="row mb-4">
                                    <div className="col-xl-8 col-sm-0"></div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="faq-form">
                                            <input
                                                value={search}
                                                onChange={(e) => {
                                                    setSearch(e.target.value)
                                                    fetchSurveysAsStatus(e.target.value)
                                                }}
                                                className="form-control"
                                                type="text"
                                                placeholder="Search by Survey No./Name"
                                            />
                                            <Search className="search-icon" />
                                        </div>
                                    </div>
                                </div>
                                {/* <DataTable
                                            columns={columnsdata}
                                            data={searched ? filtered : surveyData.data}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage.data}
                                            onChangePage={handlePageChange}
                                            striped={true}
                                            center={true}
                                            persistTableHead
                                            highlightOnHover
                                            customStyles={customStyles}
                                            onRowClicked={(data) => {
                                                handleRoute(data);
                                            }}

                                        /> */}
                                {surveyData.loading ?
                                    <div className="loader-box justify-content-center">
                                        <div className="loader">
                                            <div className="line bg-dark"></div>
                                            <div className="line bg-dark"></div>
                                            <div className="line bg-dark"></div>
                                            <div className="line bg-dark"></div>
                                        </div>
                                    </div> :

                                    <>
                                        <div className="table-responsive " style={{ maxHeight: "70vh" }}>
                                            <table className="table table-striped table-bordered">
                                                <thead className="thead-light">
                                                    <tr
                                                        className="f-18"
                                                        style={{
                                                            position: "sticky",
                                                            top: "0",
                                                            background: "white",
                                                        }}
                                                    >
                                                        <th scope="col">{"Survey No"}</th>
                                                        <th scope="col">{"Survey Name"}</th>
                                                        <th scope="col">{"Vessel"}</th>
                                                        <th scope="col">{"Verifier"}</th>
                                                        <th scope="col">{"Status"}</th>
                                                        <th scope="col">{"Start Time"}</th>
                                                        <th scope="col">{"End Time"}</th>
                                                        <th scope="col">{"Action"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {surveyData.data.map((item) => {
                                                        return <tr key={item.Id} >
                                                            <td className='cursor_pointer' onClick={() => handleRoute(item)}>{item.surveyNo}</td>
                                                            <td className='cursor_pointer' onClick={() => handleRoute(item)}>{item.checklist?.name}</td>
                                                            <td className='cursor_pointer' onClick={() => handleRoute(item)}>{item.vessel.name}</td>
                                                            <td className='cursor_pointer' onClick={() => handleRoute(item)}>{item.verifier.name}</td>
                                                            <td className='cursor_pointer' onClick={() => handleRoute(item)}>{item.status}</td>
                                                            <td >{item.startDate.split("-").join()}</td>
                                                            <td>{item.endDate.split("-").join()}</td>
                                                            <td>



                                                                <Video className='cursor_pointer' onClick={() => {
                                                                    toggle(item?.Id)
                                                                }} id="recordings" />
                                                                <UncontrolledTooltip placement="bottom" target={"recordings"}>
                                                                    {"View Call Recordings"}
                                                                </UncontrolledTooltip>

                                                                {item?.status === "Active" ? null :
                                                                    <>
                                                                        <FileText
                                                                            className='cursor_pointer'
                                                                            style={{ marginLeft: "5px" }}
                                                                            onClick={() => {
                                                                                toggleReportModal(item?.Id);
                                                                                setChecklistId(item?.checklist.name);
                                                                                setSurvey_type(item?.surveyType)
                                                                            }}
                                                                            id="viewReport"
                                                                        />
                                                                        <UncontrolledTooltip placement="bottom" target={"viewReport"}>
                                                                            {"View Report"}
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                                }
                                                                {/* <div>

                                                                    <span
                                                                        style={{
                                                                            // background: "red",
                                                                            width: "auto",
                                                                            height: "auto",
                                                                            borderRadius: "12px",
                                                                            position: "absolute",
                                                                            top: "0px",
                                                                        }}
                                                                        className='btn-primary-blue'
                                                                    >
                                                                        <p
                                                                            className="m-0"
                                                                            style={


                                                                                { padding: "2px 4px" }
                                                                            }
                                                                        >
                                                                            {item.notification !== null && item.notification !== undefined ? item.notification
                                                                                : 0}
                                                                        </p>

                                                                    </span>
                                                                    <Bell />
                                                                </div> */}

                                                                <div style={{
                                                                    "position": "relative",
                                                                    "display": 'inline-block'
                                                                }}>
                                                                    <Bell />
                                                                    {item.notification > 0 && <span style={{
                                                                        "position": "absolute",
                                                                        "top": "-8px",
                                                                        "right": "-8px",
                                                                        "background-color": "rgb(34, 100, 171)",
                                                                        "color": "white",
                                                                        "border-radius": " 50%",
                                                                        "padding": "2px 6px",
                                                                        "font-size": "12px"
                                                                    }}>
                                                                        {item.notification}</span>}
                                                                </div>



                                                            </td>
                                                        </tr>
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                }

                                {surveyData.loading ? null : (
                                    <div className="mt-4">
                                        <Pagination
                                            aria-label="Page navigation"
                                            className="pagination justify-content-end pagination-primary"
                                        >
                                            <PaginationItem disabled={pageNo === 1 ? true : false}>
                                                <PaginationLink
                                                    className="cursor_pointer"
                                                    onClick={() => {
                                                       
                                                        fetchSurveysAsStatus(null,-1)
                                                        //setpageNo(pageNo-1)
                                                    }}
                                                >
                                                    {"Previous"}
                                                </PaginationLink>
                                            </PaginationItem>

                                            <PaginationItem active>
                                                <PaginationLink>{pageNo}</PaginationLink>
                                            </PaginationItem>

                                            <PaginationItem
                                                disabled={
                                                    pageNo === maxPage || maxPage === 0
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <PaginationLink
                                                    last
                                                    onClick={() => {
                                                      
                                                        fetchSurveysAsStatus(null,1);
                                                       // setpageNo(pageNo+1)
                                                    }}
                                                    className="cursor_pointer"
                                                >
                                                    {"Next"}
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>

                                        {/* <p className='f-14 text-muted float-end mt-1'>{`Total Records: ${totalRecords}`}</p> */}
                                        <div>
                                            <div className="d-flex justify-content-end">
                                                <p className="f-12 text-muted mt-1">{`${pageNo} / ${maxPage} pages`}</p>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}


                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <Modal
                isOpen={modal}
                toggle={(event) => toggle(event)}
                size="lg"
                backdrop="static"
            >
                <ModalHeader
                    className="bg-primary-blue"
                    toggle={(event) => toggle(event)}
                >
                    {`Video Recordings`}
                </ModalHeader>
                <ModalBody>
                    <div className="card-body">
                        <div className="shadow border mb-2">
                            <div className="row ">
                                <div
                                    className="col p-1"
                                    style={{ height: "270px", width: "250%" }}
                                >
                                    {!!preview ? (
                                        <video src={preview} height="100%" width="100%" controls />
                                    ) : (
                                        <span
                                            className="text-center"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: "red",
                                                justifyContent: "center",
                                            }}
                                        >
                                            No Preview available
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {modalLoader ? (
                            <div className="loader-box justify-content-center">
                                <div className="loader">
                                    <div className="line bg-dark"></div>
                                    <div className="line bg-dark"></div>
                                    <div className="line bg-dark"></div>
                                    <div className="line bg-dark"></div>
                                </div>
                            </div>
                        ) : (
                            <div className="px-3">
                                <Slider
                                    {...basic_settings2}
                                    style={{
                                        height: "160px",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {recordedVideo?.length == 0 ? (
                                        <span className="text-danger text-center">
                                            No videos available
                                        </span>
                                    ) : (
                                        recordedVideo?.map((element) => {
                                            return (
                                                <div
                                                    onClick={() => setPreview(element?.url)}
                                                    key={Math.ceil(Math.random() * 100000)}
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    <video
                                                        src={element?.url}
                                                        // style={{ height: "100px", width: "100px" }}
                                                        style={{ width: "80%", height: "15vh" }}
                                                        controls={true}
                                                    />
                                                </div>
                                            );
                                        })
                                    )}
                                </Slider>
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary-blue"
                        onClick={(event) => toggle(event)}
                    >
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={reportModal} toggle={(event) => toggleReportModal(event)} size="xl" backdrop="static">
                <ModalHeader className="bg-primary-blue" toggle={(event) => toggleReportModal(event)}>
                    {"Survey Report Preview"}
                </ModalHeader>
                <ModalBody>

                    {/* <SurveyReport id={surveyIdForReport} cid={checklistId} /> */}
                    <Show_suvey_report id={surveyIdForReport} name={checklistId} type={survey_type} />

                </ModalBody>
                <ModalFooter>
                    <button
                        style={{ fontSize: "16px" }}
                        onClick={(event) => toggleReportModal(event)}
                        className="btn btn-primary-blue float-right"
                    >
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>
        </React.Fragment >
    )
}

export default SurevyList2