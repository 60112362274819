import React, { useEffect, useState } from "react";
import {
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import VideoCall from "./VideoCall";
import ChannelForm from "./ChannelForm ";
import { generateAgoraToken } from '../../../api/index';
import './index.css';

var config = ClientConfig;
config = {
    mode: "rtc", codec: "vp8",
};

const appId = `${process.env.REACT_APP_AGORA_APPID}`; //ENTER APP ID HERE
// const token = null;

const App = () => {
    const [inCall, setInCall] = useState(false);
    const [channelName, setChannelName] = useState("");
    const [token, setToken] = useState("");
    const useClient = createClient(config);
    // const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();


    useEffect(() => {

        generateAgoraToken({ userId: 1, channelName: 'channel' }).then((res) => {
            console.log('res', res);
            setToken(res.data);
        }, (err) => {
            console.log('err', err);
        });
    }, []);

    return (
        <div>
            {/* <h1 className="heading">Agora RTC NG SDK React Wrapper</h1>
            {inCall ? (
                <VideoCall setInCall={setInCall} channelName={channelName} appId={appId} token={token} />
            ) : (
                <ChannelForm setInCall={setInCall} setChannelName={setChannelName} appId={appId} />
            )} */}
        </div>
    );
};

export default App