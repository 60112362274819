import { Settings, } from 'react-feather';

export const VERIFIER_MENUITEMS = [
    // {
    //     title: 'Org Management', type: 'sub', icon: Briefcase, active: false, children: [
    //         { path: '/admin/vdi_customer', title: 'Organisation', type: 'link' },
    //         { path: '/admin/vdi_customersubscription', title: 'Organisation Subscription', type: 'link' },
    //     ]
    // },
    {
        title: 'Survey', icon: Settings, path: '/verifier/vessels', type: 'link', active: false
    },
    // {
    //     title: 'User Adminstration', type: 'sub', icon: Briefcase, active: false, children: [
    //         { path: '/admin/vdi_usermaster', title: 'Users', type: 'link' },
    //         { path: '/admin/vdi_customerAdminUsers', title: 'Org Users', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Mail Error Logs', icon: Settings, path: '/admin/mailerrorlogs', type: 'link', active: false
    // },
    // {
    //     title: 'Access Error Logs', icon: Settings, path: '/admin/accesserrorlogs', type: 'link', active: false
    // },

]
