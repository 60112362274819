import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { survey } from '../../../../constant/index';
import { PDFViewer, Document, Text, View, Image, Page, Link, StyleSheet, PDFDownloadLink, Font, } from "@react-pdf/renderer";
import logo from "../../../../assets/images/Vedam/NewDILogoPNG100.png";
import VideoPlay from "../../../../assets/images/Vedam/VideoPlay.jpg";
import moment from "moment";
import RobotoRegular from '../../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoRegular700 from '../../../../assets/fonts/Roboto/Roboto-Bold.ttf';
import { getSurveyRecordings } from '../../../../api/index';
import { version } from '../../../../constant/index';

Font.register({
    family: 'Roboto, sans-serif',
    fonts: [
        {
            src: RobotoRegular,
        },
        {
            src: RobotoRegular700,
            fontWeight: 'bold',
        },
    ],
});

function ReportForApp() {
    const [recording, setRecording] = useState([]);
    const [surveyResponse, setSurveyResponse] = React.useState([]);
    const [Sop, setSop] = React.useState(null);
    const [loader, setLoader] = React.useState(false);

    const { sid, custid, token } = useParams();


    useEffect(() => {
        console.log("token", token);

        const survey_id = atob(sid);
        const customer_id = atob(custid);
        const jwtToken = atob(token);
        console.log("survey_id", survey_id);
        console.log("customer_id", customer_id);

        // let str = 105;
        // let str2 = 7;
        // let enc2 = btoa(str2);
        // let enc = btoa(str);
        // console.log("enc", enc);
        // console.log("enc2", enc2);

        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
            // headers: { Authorization: `Bearer ${token}` }
        };

        const apiUrl = `${process.env.REACT_APP_API_URL + survey}?customer_id=${customer_id}&Id=${survey_id}`;
        // axios.post(url[, data[, config]])

        axios.get(apiUrl, config).then((res) => {
            console.log("res", res);

            setSurveyResponse(res.data.res);
            setLoader(true);

            axios.get(`${process.env.REACT_APP_API_URL}${version}GetSOPById/?Id=${res.data.res[0].SOP.Id}`, config)
                .then((resp) => {
                    console.log("resp", resp);
                    setSop(resp.res);

                }).catch((err) => {
                    console.log("err", err);
                });

            axios.get(`${process.env.REACT_APP_API_URL}${version}SurveyRecordings/?survey=${res?.data?.res[0].Id}`, config)
                .then((response) => {
                    console.log("reponse", response);

                    const temp = response.data.filter((val) => {
                        return val.checkListItem !== null
                    })
                    setRecording(temp);
                }, (err) => {
                    console.log("err", err);
                })

            setLoader(false);




        }).catch((err) => {
            console.log("err", err);
        });


    }, []);


    function downloadReport() {
        const downloadlink = document.getElementsByTagName('a');
        console.log("downloadlink", downloadlink);
        downloadlink.click();
    }



    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#fff",
            color: "black",
            fontFamily: 'Roboto, sans-serif',
            padding: '20px',

            // height: "80%",
        },
        section: {
            margin: 10,
            padding: 10,
        },
        viewer: {
            width: "100%",
            height: window.innerHeight,
        },
        // text: {
        //   padding: 20,
        //   paddingRight: 20,
        // },
        pages: {
            position: "absolute",
            top: "95%",
            left: "50%",
        },
        fristImage: {
            width: 400,
            height: 200,
        },

        secondPage: {
            display: "flex",
            position: "relative",
            padding: 10,
        },
        secondImage: {
            // right:'50%',
            top: "20%",
            width: 200,
            height: 200,
        },
        secondText: {
            left: "45%",
            bottom: "40%",

            width: 200,
            // height: 150,
        },
        title: {
            fontFamily: "",
            fontStyle: "oblique",
        },
        color: {
            color: "red",
        },
        header: {
            height: 100,
            // backgroundColor: "red",
        },
        heade_innerr: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        logoImage: {
            width: "100px",
        },
        btm: {
            // marginBottom: 200,
            flex: 1,
        },
        questionContainer: {
            display: "flex",
            flexDirection: "row",

            border: "1px solid gray",
            height: 100,
            marginHorizontal: 10,
            marginTop: 10,

            content: "",
            clear: "both",
        },

        questionIndex: {
            fontSize: 10,
            textAlign: "center",
        },
        questionIndexContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "10%",
            borderRight: "1px solid gray",
        },
        question: {
            fontSize: 11,
            marginTop: 10,
            wordSpacing: 50,
            lineHeight: 1.5,
        },

        questionsContainer: {
            float: "left",
            padding: " 10px",
            width: "75%",
        },
        questionResult: {
            fontSize: 10,
            textAlign: "center",
        },

        questionResultContainer: {
            borderLeft: "1px solid gray",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "15%",
        },
        comment: {
            border: "1px solid gray",
            marginLeft: 10,
            marginRight: 10,
        },
    });




    return (
        <React.Fragment>
            {/* <div className="reportForApp">
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='title bg-primary-blue' id="card_title">
                                <p className='mb-0 f-24'>{"Survey Report"}</p>
                            </div> */}

            {/* <div className='card-body'> */}
            {loader === true ?

                <div className="loader-box justify-content-center">
                    <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                    </div>
                </div>
                :
                <div className='row'>
                    <div className='col-12'>


                        <div className='card'>
                            <div className='card-body text-center'>


                                <PDFViewer style={styles.viewer}>
                                    <Document>
                                        <Page size="A4" style={styles.page}>
                                            <View style={{
                                                height: "8%",
                                                padding: "0px 15px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                border: "1px solid gray",
                                                paddingBottom: "10px",
                                                paddingTop: "10px",
                                            }}
                                                fixed
                                            >
                                                <View>
                                                    <Image style={{ width: "50px" }} src={logo} />
                                                </View>

                                                <View style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                >
                                                    <Text style={{ fontSize: "14px", fontWeight: '600' }}>
                                                        Vessel Name
                                                    </Text>
                                                    <Text style={{ fontSize: "12px", }}>
                                                        {surveyResponse[0]?.vessel?.name}
                                                    </Text>
                                                </View>

                                                <View
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>

                                                    <Text style={{ fontSize: "14px", fontWeight: '600' }}>
                                                        Report No.
                                                    </Text>
                                                    <Text style={{ fontSize: "12px", }}>
                                                        {surveyResponse[0]?.surveyNo}
                                                    </Text>
                                                </View>
                                            </View>


                                            <View style={[styles.btm, {
                                                borderLeft: '1px solid gray',
                                                borderRight: '1px solid gray',
                                                borderBottom: '1px solid gray',
                                                paddingTop: '10px',
                                            }]} wrap>
                                                <View
                                                    style={{
                                                        padding: "0px 15px",
                                                        justifyContent: "space-between",
                                                        flexDirection: "row",
                                                        marginBottom: "20px",

                                                    }}
                                                >
                                                    <View style={{ width: "50%", }}>
                                                        <Text
                                                            style={{
                                                                fontSize: "18px",
                                                                marginBottom: "10px",
                                                                fontWeight: 700,
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            Vessel Details
                                                        </Text>
                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                Vessel Name:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {surveyResponse[0]?.vessel?.name}
                                                            </Text>
                                                        </View>
                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                Vessel Type:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {surveyResponse[0]?.vessel?.type.name}
                                                            </Text>
                                                        </View>
                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                Unique No:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {surveyResponse[0]?.vessel?.uniqueNumber == "null"
                                                                    ? "Not Available"
                                                                    : surveyResponse[0]?.vessel?.uniqueNumber}
                                                            </Text>
                                                        </View>

                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                IMO No:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {surveyResponse[0]?.vessel?.number}
                                                            </Text>
                                                        </View>

                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                GT:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {surveyResponse[0]?.vessel?.GT}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View
                                                        style={{
                                                            width: "50%",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: "18px",
                                                                marginBottom: "10px",
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            Survey Details
                                                        </Text>
                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            {" "}
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                Survey No:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {surveyResponse[0]?.surveyNo}
                                                            </Text>
                                                        </View>
                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            {" "}
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                Survey Name:
                                                            </Text>

                                                        </View>
                                                        <View >
                                                            {" "}
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                }}
                                                            >
                                                                {surveyResponse[0]?.checklist?.name}
                                                            </Text>

                                                        </View>
                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                Assigned Surveyor Name:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px"
                                                                }}
                                                            >
                                                                {surveyResponse[0]?.user?.name}
                                                            </Text>
                                                        </View>
                                                        <View >
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                ACTUAL Survey Start Date & Time:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",

                                                                }}
                                                            >
                                                                {
                                                                    surveyResponse[0]?.image_survey.length > 0 ?
                                                                        new Date(surveyResponse[0]?.image_survey[0]?.createdDate).toLocaleString()
                                                                        : ""
                                                                    // new Date(surveyResponse[0]?.startDate).toLocaleString()

                                                                }

                                                                {/* {new Date(
                    surveyResponse[0]?.startDate.split("-").join()
                    ).toLocaleDateString("en-au")} */}
                                                            </Text>
                                                        </View>
                                                        <View >
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                ACTUAL Completion Date & Time:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {/* {new Date(
                surveyResponse[0]?.endDate.split("-").join()
              ).toLocaleDateString("en-au")} */}
                                                                {
                                                                    surveyResponse[0]?.survey_signature.length > 0 ?
                                                                        new Date(surveyResponse[0]?.survey_signature[0]?.createdDate).toLocaleString()
                                                                        : ""
                                                                    // new Date(surveyResponse[0]?.endDate).toLocaleString()
                                                                }
                                                            </Text>
                                                        </View>
                                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                Lat - Long:
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontSize: "11px",
                                                                    paddingBottom: "4px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {`${surveyResponse[0]?.lat} - ${surveyResponse[0]?.long}`}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View
                                                    style={{
                                                        padding: "0px 15px",
                                                        marginBottom: "20px",
                                                    }}
                                                >

                                                    <View
                                                        style={{
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: "13px",
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            Surveyor Name & Selfie :{" "}
                                                            {surveyResponse[0]?.image_survey.length > 0 ? surveyResponse[0]?.image_survey[0]?.name : null}
                                                        </Text>
                                                    </View>


                                                    {surveyResponse[0]?.image_survey[0]?.image !== null || surveyResponse[0]?.image_survey[0]?.image !== undefined || surveyResponse[0]?.image_survey.length > 0 ?
                                                        <View>
                                                            {surveyResponse[0]?.image_survey.length > 0 ?
                                                                <Image
                                                                    style={{ width: "110px", marginBottom: "10px" }}
                                                                    src={surveyResponse[0]?.image_survey[0]?.image}
                                                                />
                                                                : null}
                                                        </View>
                                                        : null}
                                                    <View
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: "15px",
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            Instructions & Guidelines :
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontSize: "15px",
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            {Sop?.SOP.name}
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            marginBottom: "4px",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: "12px",
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            Description :
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontSize: "12px",
                                                            }}
                                                        >
                                                            {Sop?.SOP.description}
                                                        </Text>
                                                    </View>
                                                    {Sop?.SOPLineItems?.map((el, i) => {
                                                        return (
                                                            <View key={i}>
                                                                <Text
                                                                    style={{
                                                                        fontSize: "12px",
                                                                    }}
                                                                >
                                                                    {el.sequence}. {el.instruction}{" "}
                                                                </Text>
                                                            </View>
                                                        );
                                                    })}
                                                </View>
                                                {surveyResponse[0]?.checklist?.checklist_sections.map(
                                                    (data, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <View style={{ margin: 10 }}>
                                                                    <Text
                                                                        style={{ fontSize: "12", fontWeight: "bold" }}
                                                                    >
                                                                        {data?.sectionId}. {data?.sectionName}
                                                                    </Text>
                                                                </View>
                                                                {data?.section_checklist_item.map((el, i) => {
                                                                    return (
                                                                        <React.Fragment key={i}>
                                                                            <View style={styles.questionContainer}>
                                                                                <View style={styles.questionIndexContainer}>
                                                                                    <Text style={styles.questionIndex}>
                                                                                        {el?.SrNo}.
                                                                                    </Text>
                                                                                </View>
                                                                                <View style={styles.questionsContainer}>
                                                                                    <Text style={styles.question}>
                                                                                        {el?.section}
                                                                                    </Text>
                                                                                </View>
                                                                                <View style={styles.questionResultContainer}>
                                                                                    <Text style={styles.questionResult}>
                                                                                        {
                                                                                            el?.checklist_item_result[
                                                                                                el?.checklist_item_result.length - 1
                                                                                            ]?.values
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                            </View>
                                                                            <View style={styles.comment}>
                                                                                <Text
                                                                                    style={{
                                                                                        fontSize: "10",
                                                                                        padding: 10,
                                                                                        color: "red",
                                                                                    }}
                                                                                >
                                                                                    <Text
                                                                                        style={{ fontSize: "13", color: "black", fontWeight: '600' }}
                                                                                    >
                                                                                        Comment :
                                                                                    </Text>{" "}
                                                                                    {
                                                                                        el?.checklist_item_result[
                                                                                            el?.checklist_item_result.length - 1
                                                                                        ]?.remarks
                                                                                    }
                                                                                </Text>
                                                                            </View>
                                                                            <View>
                                                                                <View
                                                                                    style={{
                                                                                        // margin: 10,
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                        width: "100%",
                                                                                    }}
                                                                                >
                                                                                    <View
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    >
                                                                                        <Text
                                                                                            style={{
                                                                                                fontSize: "13",
                                                                                                paddingBottom: 10,
                                                                                                margin: "10px",
                                                                                                fontWeight: '600'
                                                                                            }}
                                                                                        >
                                                                                            Image
                                                                                        </Text>
                                                                                        <View
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                // justifyContent: "space-between",
                                                                                                flexWrap: "wrap",
                                                                                                width: "100%",
                                                                                            }}
                                                                                        >
                                                                                            {el?.check_list_attachment.map(
                                                                                                (item, i) => {
                                                                                                    // console.log("insider", item);
                                                                                                    return (
                                                                                                        <React.Fragment key={i}>
                                                                                                            {item.fileType == "Image" &&
                                                                                                                item?.survey == sid &&
                                                                                                                item?.withAnswer ? (
                                                                                                                <View
                                                                                                                    style={{
                                                                                                                        // float: "left",
                                                                                                                        // display: "block",
                                                                                                                        width: "95px",
                                                                                                                        height: "70px",
                                                                                                                        // marginTop: "5px",
                                                                                                                        margin: 10,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <View style={{ width: "100%", height: "100%", objectFit: "contain", }}>
                                                                                                                        <Link src={item.checklist_file} style={{ fontSize: 12, color: "blue", }}>
                                                                                                                            <Image src={item?.checklist_file} style={{
                                                                                                                                width: "100%",
                                                                                                                                // margin: "10px",
                                                                                                                                // height: "70px",
                                                                                                                            }} />
                                                                                                                        </Link>

                                                                                                                        <Text style={{ textAlign: "center", fontSize: "10px", marginBottom: "2px", }}>
                                                                                                                            {moment(
                                                                                                                                item?.created
                                                                                                                            ).format(
                                                                                                                                "DD-MM-YYYY HH:mm"
                                                                                                                            )}
                                                                                                                        </Text>

                                                                                                                        <Text style={{ textAlign: "center", fontSize: "7px", }}>
                                                                                                                            {item?.lat == null &&
                                                                                                                                item?.long == null
                                                                                                                                ? "NA-NA"
                                                                                                                                : `${item?.lat} - ${item?.long}`}
                                                                                                                        </Text>


                                                                                                                    </View>


                                                                                                                </View>
                                                                                                            ) : null}


                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </View>
                                                                                    </View>
                                                                                </View>

                                                                                <View
                                                                                    style={{
                                                                                        margin: 10,
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}
                                                                                >
                                                                                    <View>
                                                                                        <Text
                                                                                            style={{
                                                                                                fontSize: "13",
                                                                                                paddingBottom: 10,
                                                                                                fontWeight: '600'
                                                                                            }}
                                                                                        >
                                                                                            Videos
                                                                                        </Text>
                                                                                        <View
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                flexWrap: "wrap",
                                                                                                width: "100%",
                                                                                            }}
                                                                                        >
                                                                                            {el?.check_list_attachment.map(
                                                                                                (item, i) => {
                                                                                                    return (
                                                                                                        <React.Fragment key={i}>
                                                                                                            {item.fileType == "Video" &&
                                                                                                                item?.survey == sid &&
                                                                                                                item?.withAnswer ? (
                                                                                                                <>
                                                                                                                    <View
                                                                                                                        style={{
                                                                                                                            margin: 10,
                                                                                                                        }}
                                                                                                                        wrap
                                                                                                                    >
                                                                                                                        <View>
                                                                                                                            <Image
                                                                                                                                src={VideoPlay}
                                                                                                                                style={{
                                                                                                                                    width: "95px",
                                                                                                                                    height: "60px",
                                                                                                                                }}
                                                                                                                            />
                                                                                                                            <Text
                                                                                                                                style={{
                                                                                                                                    fontSize: "10px",
                                                                                                                                    marginBottom: "2px",
                                                                                                                                    marginLeft: 10,
                                                                                                                                    marginRight: 10,
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {moment(
                                                                                                                                    item?.created
                                                                                                                                ).format(
                                                                                                                                    "DD-MM-YYYY HH:mm"
                                                                                                                                )}
                                                                                                                            </Text>
                                                                                                                            <Text
                                                                                                                                style={{
                                                                                                                                    fontSize: "7px",
                                                                                                                                    marginLeft: 10,
                                                                                                                                    marginRight:
                                                                                                                                        item?.lat ==
                                                                                                                                            null &&
                                                                                                                                            item?.long == null
                                                                                                                                            ? 15
                                                                                                                                            : 10,
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {item?.lat == null &&
                                                                                                                                    item?.long == null
                                                                                                                                    ? "NA-NA"
                                                                                                                                    : `${item?.lat} - ${item?.long}`}
                                                                                                                            </Text>
                                                                                                                        </View>

                                                                                                                        <View
                                                                                                                            style={{
                                                                                                                                marginLeft: 10,
                                                                                                                                marginRight: 10,
                                                                                                                            }}
                                                                                                                            render={({
                                                                                                                                pageNumber,
                                                                                                                            }) =>
                                                                                                                                pageNumber && (
                                                                                                                                    <>
                                                                                                                                        <Text>
                                                                                                                                            <Link
                                                                                                                                                src={
                                                                                                                                                    item?.checklist_file
                                                                                                                                                }
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 12,
                                                                                                                                                    color:
                                                                                                                                                        "blue",
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                Link
                                                                                                                                            </Link>
                                                                                                                                        </Text>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        ></View>
                                                                                                                    </View>
                                                                                                                </>
                                                                                                            ) : null}
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </View>


                                                                                    </View>
                                                                                </View>






                                                                                {/* <View
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <View>
                                                        <Text
                                                            style={{
                                                                fontSize: "13",
                                                                paddingBottom: 10,
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            Live Stream Recordings
                                                        </Text>
                                                        <View
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                flexWrap: "wrap",
                                                                width: "100%",
                                                            }}
                                                        >

                                                            {
                                                                data?.section_checklist_item.map((item, i) => {
                                                                    recording?.map((val, ind) => {
                                                                        console.log(item.Id + "===" + val.checkListItem);
                                                                        if (3147 === parseInt(val.checkListItem)) {
                                                                            return (
                                                                                <React.Fragment key={i}>
                                                                                    <Text>Matching</Text>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                    }
                                                                    )

                                                                })
                                                            }
                                                        </View>


                                                    </View>
                                                </View> */}



                                                                            </View>
                                                                        </React.Fragment>
                                                                    );
                                                                })}




                                                            </React.Fragment>
                                                        );
                                                    }
                                                )}
                                            </View>







                                            <View
                                                style={{
                                                    padding: 10,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderLeft: '1px solid gray',
                                                    borderRight: '1px solid gray',
                                                }}
                                            >
                                                <View>
                                                    <Text
                                                        style={{
                                                            fontSize: "13",
                                                            paddingBottom: 10,
                                                            fontWeight: '600'
                                                        }}
                                                    >
                                                        Live Stream Recordings
                                                    </Text>
                                                    <View
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap",
                                                            width: "100%",
                                                        }}
                                                    >

                                                        {
                                                            recording?.map((val, ind) => (
                                                                <React.Fragment key={ind}>

                                                                    <View
                                                                        style={{
                                                                            margin: 10,
                                                                        }}
                                                                        wrap
                                                                    >
                                                                        <View>
                                                                            <Image
                                                                                src={VideoPlay}
                                                                                style={{
                                                                                    width: "95px",
                                                                                    height: "60px",
                                                                                }}
                                                                            />

                                                                        </View>

                                                                        <View
                                                                            style={{
                                                                                marginLeft: 10,
                                                                                marginRight: 10,
                                                                            }}
                                                                            render={({
                                                                                pageNumber,
                                                                            }) =>
                                                                                pageNumber && (
                                                                                    <>
                                                                                        <Text>
                                                                                            <Link
                                                                                                src={
                                                                                                    val?.url
                                                                                                }
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color:
                                                                                                        "blue",
                                                                                                }}
                                                                                            >
                                                                                                Link
                                                                                            </Link>
                                                                                        </Text>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        ></View>
                                                                    </View>
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </View>


                                                </View>
                                            </View>










                                            <View style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray' }}>
                                                <View
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "wrap",
                                                        width: "100%",
                                                        marginTop: '10px',
                                                        marginLeft: "10px",
                                                        marginRight: "10px"
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            fontSize: "13",
                                                            paddingBottom: 10,
                                                            marginBottom: "2px",
                                                            fontWeight: '600'
                                                        }}
                                                    >Surveyor's Overall Comments :</Text>

                                                </View>

                                                <View
                                                    style={{
                                                        width: 'auto',
                                                        border: '1px solid gray',
                                                        borderRadius: '8px',
                                                        marginLeft: "10px",
                                                        marginRight: "10px"
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            fontSize: "10px",
                                                            marginBottom: "2px",
                                                            padding: '10px'
                                                        }}
                                                    >{surveyResponse[0]?.remarks}</Text>

                                                </View>

                                                {surveyResponse[0]?.verifier_activity.length > 0 ?
                                                    < View
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap",
                                                            width: "100%",
                                                            marginTop: '10px',
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                            fontWeight: '600'
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: "13",
                                                                paddingBottom: 10,
                                                                marginBottom: "2px",

                                                            }}
                                                        >Verifier's Remarks :</Text>

                                                    </View>
                                                    : null}

                                                {surveyResponse[0]?.verifier_activity.length > 0 ?
                                                    <View
                                                        style={{
                                                            width: 'auto',
                                                            border: '1px solid gray',
                                                            borderRadius: '8px',
                                                            marginLeft: "10px",
                                                            marginRight: "10px"
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: "10px",
                                                                marginBottom: "2px",
                                                                padding: '10px'
                                                            }}
                                                        >{surveyResponse[0]?.verifier_activity[0].comments}</Text>

                                                    </View>
                                                    : null}

                                                {surveyResponse[0]?.survey_signature.length > 0 ?

                                                    <View style={{
                                                        marginLeft: "10px",
                                                        marginRight: "10px",
                                                        marginTop: '10px'
                                                    }}>
                                                        <Text
                                                            style={{
                                                                fontSize: "13",
                                                                paddingBottom: 10,
                                                                marginBottom: "2px",
                                                                fontWeight: '600'
                                                            }}
                                                        >{"Surveyor's Sign :"}</Text>


                                                        <Image
                                                            style={{
                                                                marginTop: "1px",
                                                                marginLeft: "2px",
                                                                height: "50",
                                                                width: "100",
                                                            }}
                                                            src={surveyResponse[0]?.survey_signature[0].sign}
                                                        />
                                                        <Text
                                                            style={{
                                                                fontSize: "10",
                                                                marginTop: "4px",
                                                            }}
                                                        >{new Date(surveyResponse[0]?.survey_signature[0]?.createdDate).toLocaleString()}</Text>
                                                    </View>
                                                    :
                                                    null
                                                }
                                            </View>








                                            <View
                                                style={{
                                                    // padding: "0px 15px",
                                                    display: "flex",
                                                    height: "5%",
                                                    justifyContent: "space-between",
                                                    alignItems: "flex-end",
                                                    flexDirection: "row",
                                                    // position: "absolute",
                                                    // bottom: "10px",
                                                    width: "100%",
                                                    height: 25,
                                                    paddingBottom: '4px',
                                                    paddingHorizontal: 15,
                                                    // paddingTop: '5px',
                                                    border: '1px solid gray',

                                                    // backgroundColor: "red",
                                                }}
                                                fixed
                                            >
                                                <View
                                                    style={{
                                                        // borderTop: 1,
                                                        // borderTopColor: "grey",
                                                        position: "absolute",
                                                        width: "120%",
                                                        bottom: 25,
                                                    }}
                                                ></View>
                                                <View>
                                                    <Text
                                                        style={{
                                                            fontSize: "11px",
                                                        }}
                                                    >
                                                        Survey Name :  {surveyResponse[0]?.checklist?.name}
                                                    </Text>

                                                </View>
                                                {/* <View>
                    <Text
                        style={{
                            fontSize: "11px",
                        }}
                    >
                      
                    </Text>
                </View> */}
                                                <View>
                                                    <Text
                                                        style={{
                                                            fontSize: "11px",
                                                        }}
                                                        render={({ pageNumber, totalPages }) =>
                                                            `${pageNumber} / ${totalPages}`
                                                        }
                                                    ></Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row" }}>
                                                    <Text
                                                        style={{
                                                            fontSize: "11px",
                                                            marginTop: '2px'
                                                        }}
                                                    >
                                                        Powered By
                                                    </Text>
                                                    <Image
                                                        style={{
                                                            marginTop: "1px",
                                                            marginLeft: "2px",
                                                            height: "15px",
                                                            width: "15px",
                                                        }}
                                                        src={logo}
                                                    />
                                                </View>
                                            </View>
                                        </Page>
                                    </Document>
                                </PDFViewer>

                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    )
}

export default ReportForApp