import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { updateCheckListSection } from '../../../../api/index';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
    sectionName: yup.string().max(300, '300 characters allowed !').required('Name is required !'),
    sequence: yup.string()
        .required('Sequence is required !').matches(/^[0-9]*$/, "Only Integer Allowed !"),
    sectionId: yup.string()
        .required('Sr.No is required !'),

}).required();


function UpdateSection(props) {


    const { register, handleSubmit, formState, control, reset } = useForm({
        resolver: yupResolver(schema), mode: "onChange",
    });

    let { errors } = formState;


    useEffect(() => {
        console.log("props", props);
        reset();
    }, [props]);

    const onSubmit = data => {

        if (data !== '') {

            console.log("data", data);


            const reqPayload = {
                id: props.data.id,
                sectionName: data.sectionName,
                sequence: data.sequence,
                sectionId: data.sectionId,
                checklist: props.data.checklist
            }

            console.log("reqPayload", reqPayload);
            updateCheckListSection(reqPayload).then((res) => {
                console.log("res", res);

                props.toggleUpdateSectionModal();
                props.fetchChecklistSections();
                // props.setSectionSeleted(false);

                toast.success("Section updated successfully !", { autoClose: 3000, theme: "light", });

            }, (err) => {
                console.log("err", err);
            })

        } else {
            errors.showMessages();
        }
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.updateSectionModal} toggle={props.toggleUpdateSectionModal} size="lg" backdrop='static'>
                <ModalHeader toggle={props.toggleUpdateSectionModal} className='bg-primary-blue'>{"Update Section"}</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-12 col-md-4 mb-2 mb-md-0'>
                            <label className='f-w-600 f-14'>{"Sr.No"}</label>
                            <input
                                className={errors.sectionId ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                name={`sectionId`}
                                {...register(`sectionId`)}
                                placeholder="Enter Sr.No*"
                                defaultValue={props.data.sectionId}
                            // readOnly
                            />

                            <span className='text-danger'>{errors.sectionId?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2 mb-md-0'>
                            <label className='f-w-600 f-14'>{"Sequence"}</label>
                            <input
                                className={errors.sequence ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                name={`sequence`}
                                {...register(`sequence`)}
                                placeholder="Id*"
                                defaultValue={props.data.sequence}
                            // readOnly
                            />

                            <span className='text-danger'>{errors.sequence?.message}</span>
                        </div>

                        <div className='col-12 col-md-12'>
                            <label className='f-w-600 f-14'>{"Section Name"}</label>
                            <input
                                className={errors.sectionName ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                name={`sectionName`}
                                {...register(`sectionName`)}
                                placeholder="Name*"
                                defaultValue={props.data.sectionName}
                            />

                            <span className='text-danger'>{errors.sectionName?.message}</span>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-primary-blue' onClick={handleSubmit(onSubmit)}>Update Section</button>
                </ModalFooter>


            </Modal>
        </React.Fragment>
    )
}

export default UpdateSection