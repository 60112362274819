import React, { useState, useEffect } from 'react';
import { generateAgoraToken } from '../../../../api/index';
import { getDevices, createMicrophoneAndCameraTracks } from "agora-rtc-sdk-ng";
import GuestVideoCall from '../../Agora/GuestVideoCall';
import axios from 'axios';
import { agoraToken } from '../../../../constant/index';
import bouncing_plastic_bottle from '../../../../assets/audio/bouncing_plastic_bottle.mp3'
import GuestUserAuthForm from './GuestUserAuthForm';
import ChatUI from '../../../dashboard/defaultCompo/surveyList/surveyDashboard/surveySectionList/ChatUI';
import { MessageCircle } from 'react-feather';
import db from '../../../../data/config';
import { secondsToTime, updateCustomerVideoCallMins } from '../../../ProjectComponents/Common/Functions/CommonFunctions';
import firebase from 'firebase/compat/app';
import { useParams } from 'react-router-dom';
import { getAuth } from "firebase/auth";

function GuestUser() {
  // const { id } = useParams();
  const { id, sid, cid, sd, ed, cm } = useParams();

  const [myInterval, setMyInterval] = useState("");
  const [unReadMsgCount, setUnreadMsgCount] = useState(0);
  const [docRef, setDocRef] = useState({ Id: '' });
  const [startTime, setStartTime] = useState("");
  const [totalSeconds, setTotalSeconds] = useState({ data: '' });
  const firebaseAuthFail = JSON.parse(localStorage.getItem('firebaseAuthFail'));
  const [isAuth, setIsAuth] = useState(false);
  const [chatContainer, setChatContainer] = useState(false);

  const [token, setToken] = useState({ data: "" });
  const [channelName, setChannelName] = useState("");
  const [cameraMicTesting, setCameraMicTesting] = useState(false);
  const [devices, setDevices] = useState({ audiolist: [], videolist: [] });
  const [selAudDevice, setSelAudDevice] = useState({ data: {} });
  const [selVidDevice, setSelVidDevice] = useState({ data: {} });
  const [numberOfPidsToColor, setNumberOfPidsToColor] = useState(0);
  const numbers = [
    { num: 1, color: "#28a745" }, { num: 2, color: "#28a745" }, { num: 3, color: "#007bff" }, { num: 4, color: "#007bff" },
    { num: 5, color: "#ffc107" }, { num: 6, color: "#ffc107" }, { num: 7, color: "#ff5707" }, { num: 8, color: "#ff5707" },
    { num: 9, color: "#dc3545" }, { num: 10, color: "#dc3545" }
  ];
  const [localStream, setLocalStream] = useState({ data: '' });
  const [localAudioStream, setlocalAudioStream] = useState({ data: '' });
  const [intervalMicListen, setIntervalMicListen] = useState({ data: '' });
  const [inCall, setInCall] = useState(false);
  const ref = db.collection('notifications');
  // const playAlert = require('alert-sound-notify')


  // const GuestVideoCall = localStorage.getItem("GuestVideoCall");
  const appId = `${process.env.REACT_APP_AGORA_APPID}`; //ENTER APP ID HERE
  const loggedInUser = JSON.parse(localStorage.getItem("guestUserData"));

  useEffect(() => {

    localStorage.setItem('GuestVideoCall', true);
    // console.log("id", id);
    console.log("Guest user useEffect running");

    if (isAuth === true) {
      getToken();
      getData();
    } else {
      localStorage.clear();
    }

  }, [isAuth]);




  async function getData() {
    console.log("atob(sid)", atob(sid));
    console.log("loggedInUser.email", loggedInUser.email);
    getAuth();

    await ref
      .where('surveyId', '==', parseInt(atob(sid)))
      .where('from', '!=', loggedInUser.email)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          console.log(doc);
          console.log(doc.data());

          const msgData = doc.data();
          if (msgData.seenBy.includes(loggedInUser.email)) {

          } else {
            items.push(msgData);
          }

        })
        console.log("items", items);
        console.log("items.length", items.length);

        setUnreadMsgCount(items.length);
      })
  }





  function giveSID() {
    const data = JSON.parse(localStorage.getItem("guestUserData"));

    return data.SIDGUEST.toString();
  }


  async function getToken() {

    await axios.post(`${process.env.REACT_APP_API_URL}${agoraToken}`, { userId: generateUserId(), channelName: atob(sid).toString() })
      // await generateAgoraToken({ userId: generateUserId(), channelName: id })
      .then((res) => {
        console.log('res', res);

        token.data = res.data;
        setToken({ ...token, data: token.data });
        setChannelName(giveSID());
        setCameraMicTesting(true);


        // Find all audio and video devices
        getDevices().then(devices => {
          console.log("devices", devices);
          var audioDevList = [];
          var videoDevList = [];

          for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind == "audioinput") {
              audioDevList.push(devices[i]);
              console.log("audioDevList", audioDevList);
            } else if (devices[i].kind == "videoinput") {
              videoDevList.push(devices[i]);
              console.log('videoDevList', videoDevList);
            }
          }

          setDevices({ ...devices, audiolist: audioDevList, videolist: videoDevList });
          // setVideoDevices(videoDevList);
          // audioDevList[0].deviceId;
          // videoDevList[0].deviceId;

          selAudDevice.data = audioDevList[0].deviceId;
          setSelAudDevice({ ...selAudDevice, data: selAudDevice.data })

          selVidDevice.data = videoDevList[0].deviceId;
          setSelVidDevice({ ...selVidDevice, data: selVidDevice.data })
          testCamera('both');
        }).catch((err) => {
          console.log("err", err);
        })


      }, (err) => {
        console.log("err", err);

      })

  }



  function testCamera(callBy) {
    createMicrophoneAndCameraTracks({ microphoneId: selAudDevice.data }, { cameraId: selVidDevice.data })
      .then((tracks) => {
        console.log("tracks", tracks);

        if (callBy === 'both') {
          localStream.data = tracks[1];
          setLocalStream({ ...localStream, data: localStream.data });


          localAudioStream.data = tracks[0];
          setlocalAudioStream({ ...localAudioStream, data: localAudioStream.data });

          const video = document.querySelector('#test_stream');
          // video.srcObject = localStream;
          localStream.data.play(video);


          intervalMicListen.data = setInterval(() => {
            colorPids();
            // console.log("tesing");
          }, 500);
          setIntervalMicListen({ ...intervalMicListen, data: intervalMicListen.data })
        } else if (callBy === 'camera') {
          localStream.data = tracks[1];
          setLocalStream({ ...localStream, data: localStream.data });
          const video = document.querySelector('#test_stream');
          localStream.data.play(video);


        } else if (callBy === 'mic') {
          localAudioStream.data = tracks[0];
          setlocalAudioStream({ ...localAudioStream, data: localAudioStream.data });

          intervalMicListen.data = setInterval(() => {
            colorPids();
          }, 500);
        }


      }, (error) => {
        console.log(error);
      });
  }

  function colorPids() {
    var vol = localAudioStream.data.getVolumeLevel() * 100;
    setNumberOfPidsToColor(Math.round(vol / 10));
    // numberOfPidsToColor = Math.round(vol / 10);
    // console.log("numberOfPidsToColor", numberOfPidsToColor);
    // console.log("Math.round(vol / 10)", Math.round(vol / 10));
  }

  function changeMic(e) {
    console.log("e", e.target.value);
    selAudDevice.data = e.target.value;
    setSelAudDevice({ ...selAudDevice, data: selAudDevice.data })


    console.log("selAudDevice.data", selAudDevice.data);
    // console.log("selVidDevice.data", selVidDevice.data);

    stopTestCamera('mic');

    setTimeout(() => {
      testCamera('mic');
    }, 1000);
  }

  function changeCamera(e) {
    console.log("e", e.target.value);
    selVidDevice.data = e.target.value;
    setSelVidDevice({ ...selVidDevice, data: selVidDevice.data })

    console.log("selVidDevice.data", selVidDevice.data);

    stopTestCamera("camera");

    setTimeout(() => {
      testCamera('camera');
    }, 1000);
  }

  function stopTestCamera(val) {
    console.log('localStream.data', localStream.data);

    if (val === 'mic') {
      if (localAudioStream.data) {
        localAudioStream.data.stop();
        localAudioStream.data.close();
      }
      clearInterval(intervalMicListen.data);
      // numberOfPidsToColor = 0;
      setNumberOfPidsToColor(0);
    }

    if (val === 'camera') {
      if (localStream.data) {
        localStream.data.stop();
        localStream.data.close();
      }
    }


    if (val === 'startVideoCall') {
      setCameraMicTesting(false);

      setTimeout(() => {
        setInCall(true);
      }, 500);


      // video call stats here
      setStartTime(firebase.firestore.FieldValue.serverTimestamp());
      startTimer("start");
      localStorage.setItem('callStart', new Date());
    }


  }



  function playSound() {
    console.log('play');
    var x = document.getElementById("myAudio");
    x.play();
  }

  function generateUserId() {
    const uid = Math.floor(Math.random() * 1000000000);
    localStorage.setItem('user_id_guest', uid);
    return uid;
  }

  async function startTimer(option) {
    if (option === "start") {
      // console.log("asdasdadasdasd --------- ++++++++++++++++++++++++");
      // if subscription plan is not present no need to save call mins in firebase

      // console.log(atob(sd));

      if (atob(sd) !== "na" && atob(ed) !== "na" && atob(cm) !== "na") {
        // console.log("++++++++++++++++++++++++++++asdasdadasdasd --------- ");

        const Firedata = {
          syncId: null,
          agoraId: Math.floor(100000 + Math.random() * 900000),
          channel: parseInt(atob(sid)),
          userId: null,
          from: loggedInUser.name,
          userType: "Guest",
          source: 'web',
          startTime: firebase.firestore.FieldValue.serverTimestamp(),
          callJoined: true,
          callJoinedTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
          callInProgress: true,
          callInProgressTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
          callLeft: false,
          callLeftTimeStamp: null,
          totalTime: null,
          totalSeconds: null,
          customerId: parseInt(atob(cid))
        }

        // Initial Entry

        const temp_doc_ref = await db.collection("Call").add(Firedata)
        
        docRef.Id = temp_doc_ref.id;
        setDocRef({ ...docRef, Id: docRef.Id });
        console.log("docRef.Id", docRef.Id);
        console.log("start calling");

        var count = 0;
        setMyInterval(
          setInterval(async () => {
            // console.log("docRef.Id", docRef.Id);
            await db.collection("Call").doc(docRef.Id).update({
              callInProgressTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
            // After 3 seconds
          }, 3000)
        );
      }

    } else if (option === "end") {
      console.log("end calling");
      clearInterval(myInterval);
    }
  }


  async function endCall() {
    console.log(docRef.Id);
    startTimer("end");

    if (atob(sd) !== "na" && atob(ed) !== "na" && atob(cm) !== "na") {
      // updating same record
      await db.collection("Call").doc(docRef.Id).update({
        callJoined: false,
        callInProgress: false,
        callLeft: true,
        callInProgressTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        callLeftTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // calculating total seconds of all call and updating
      db.collection("Call").doc(docRef.Id).get().then((snapshot) => {
        console.log(snapshot.data());
        const data = snapshot.data();
        console.log(data.startTime.toDate());


        var seconds = (data.callLeftTimeStamp.toDate().getTime() - data.startTime.toDate().getTime()) / 1000;
        console.log("seconds", Math.round(seconds));
        totalSeconds.data = Math.round(seconds)
        setTotalSeconds({ ...totalSeconds, data: totalSeconds.data });
        // console.log(secondsToTime(Math.round(seconds)));
        const tTime = secondsToTime(Math.round(seconds));

        db.collection("Call").doc(docRef.Id).update({
          totalTime: tTime,
          totalSeconds: Math.round(seconds)
        });


        updateCustomerVideoCallMins(data.startTime.toDate(), Math.round(seconds), parseInt(window.atob(cid)));
      }).catch((e) => console.log(e))
    } else {
      window.location.reload();
    }

  }

  return (
    <React.Fragment>

      {isAuth === true ?

        <>
          <audio id="myAudio">
            <source src={bouncing_plastic_bottle} type="audio/mpeg">
            </source>
          </audio>

          <div className='Guest_User py-4'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='title bg-primary-blue' id="card_title">
                      <p className='mb-0 f-24'>Guest Survey Call</p>
                    </div>
                    <div className='card-body'>
                      <div className='row'>


                        {cameraMicTesting ?

                          <div className='col-12 col-md-5 mx-auto'>

                            <div className='card shadow-sm' style={{ border: '1px solid #c6c6c6', background: '#f0f0f0' }}>
                              <div className='card-body'>
                                <div className='row'>
                                  <div className='col-12'>
                                    <div className='form-group'>
                                      <label className='f-w-600'>Camera</label>
                                      <select
                                        className="form-control"
                                        onChange={(e) => changeCamera(e)}
                                        id="cameraId"
                                        defaultValue="1">
                                        {devices.videolist.map((val, i) => (
                                          <option key={i} value={val.deviceId}>{val.label}</option>
                                        ))}
                                      </select>
                                    </div>

                                    <div id="test_stream"
                                      className='my-2 mx-auto'
                                      style={{ width: "100%", height: "40vh", }}>
                                    </div>

                                    <div className='form-group'>
                                      <label className='f-w-600'>Microphone</label>
                                      <select
                                        className="form-control"
                                        onChange={(e) => changeMic(e)}
                                        id="microphone"
                                        defaultValue="1">
                                        {devices.audiolist.map((val, i) => (
                                          <option key={i} value={val.deviceId}>{val.label}</option>
                                        ))}
                                      </select>

                                      <div className='mt-4 d-flex justify-content-center'>
                                        <i className="fa fa-microphone fa-lg"></i>

                                        <div className='d-flex'>
                                          {numbers.map((val, i) => (
                                            <div key={i} className='pid ms-2'
                                              style={{ background: numberOfPidsToColor >= val.num ? val.color : '#e6e7e8', height: '14px', width: '10px' }}></div>
                                          ))}
                                        </div>
                                      </div>

                                      <div className='mt-4 d-block d-md-flex justify-content-center'>
                                        <button className='btn btn-primary-blue w-100'
                                          onClick={() => playSound()}
                                        >Test Speakers</button>
                                        <button
                                          className='btn btn-dark mt-2 mt-md-0 w-100 ms-0 ms-md-2'
                                          onClick={() => { stopTestCamera('startVideoCall') }}
                                        >Join Call</button>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          null
                        }


                        {inCall ?
                          <div className='col-12'>

                            <GuestVideoCall
                              endCall={endCall}
                              setInCall={setInCall} token={token} />

                          </div>
                          :
                          null
                        }


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {chatContainer ? <ChatUI /> : ""}




          <button
            className='shadow'
            onClick={() => {
              if (firebaseAuthFail) {
                console.log("cannot");
              } else {
                setChatContainer(!chatContainer)
              }
            }}
            style={{
              position: 'relative',
              background: "#2264AB",
              color: "#FFF",
              position: "fixed",
              width: "50px",
              height: "50px",
              bottom: "20px",
              right: "40px",
              borderRadius: "50px",
              textAlign: "center",
              border: 'none',
              zIndex: '999'
            }}
          >
            <MessageCircle
              className='cursor_pointer '
              height={'18px'}
              width={'18px'} />


            {unReadMsgCount > 0 ?
              <span
                style={{
                  background: 'red',
                  width: "auto",
                  height: 'auto',
                  borderRadius: '12px',
                  position: 'absolute',
                  top: '0px'
                }}
              ><p className='m-0' style={unReadMsgCount < 10 ? { padding: '2px 8px 2px 8px' } :
                { padding: '2px 4px 2px 4px' }}>{unReadMsgCount}</p>
              </span>
              : null}
          </button>
        </>
        :

        <GuestUserAuthForm setIsAuth={setIsAuth} />


      }
    </React.Fragment>
  )
}

export default GuestUser