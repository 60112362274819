import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Copy } from "react-feather";
import { toast } from "react-toastify";
import {
  generateRandomString,
  generateRandomNumber,
} from "../../../ProjectComponents/Common/Functions/CommonFunctions";
import { sendCallInvitation, getCallInviteHist } from "../../../../api";
import { useEffect } from "react";

const schema = yup
  .object()
  .shape({
    emaillist: yup
      .string()
      .required("Email list is required !")
      .matches(/^(?=.*[,]).*$/, ", is Required !"),
  })
  .required();

function GuestUserLinkGeneration(props) {
  const [password, setPassword] = useState("");
  const [callInHist, setCallInHist] = useState({
    loading: false,
    data: [],
    visible: false,
  });
  const [generatedLink, setGeneratedLink] = useState("");
  const [showLinkAndPassword, setShowLInkAndPassword] = useState(false);

  const localData = JSON.parse(localStorage.getItem("login_data"));

  const subscriptionPlan = JSON.parse(localStorage.getItem("subscription"));
  const surveyId = window.btoa(props.data);
  const cust = window.btoa(localData.customer_id);
  const startD =
    Object.keys(subscriptionPlan).length > 0
      ? window.btoa(subscriptionPlan.startDate)
      : window.btoa("na");
  const endD =
    Object.keys(subscriptionPlan).length > 0
      ? window.btoa(subscriptionPlan.endDate)
      : window.btoa("na");
  const callMins =
    Object.keys(subscriptionPlan).length > 0
      ? window.btoa(subscriptionPlan?.plan?.callMinutes)
      : window.btoa("na");

  let user = localStorage.getItem("user");

  const { register, handleSubmit, control, formState, watch, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    if (props.linkModal !== true) {
      setValue("emaillist", "");
      setShowLInkAndPassword(false);
      setCallInHist({
        ...callInHist,
        loading: false,
        data: [],
        visible: false,
      });
    }
  }, [props.linkModal]);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      // console.log(
      //   "${process.env.REACT_APP_DOMAIN}call/${props.data}",
      //   `${process.env.REACT_APP_DOMAIN}call/${props.data}`
      // );

      var resultList = data.emaillist.split(",");

      if (resultList.length > 0) {
        resultList = resultList.map((val) => {
          return val.trim();
        });
      }

      console.log(resultList);
      if (resultList.length > 0) {
        for (let i = 0; i < resultList.length; i++) {
          if (resultList[i].length === 0) {
            resultList.splice(i, 1);
          } else {
            if (validateEmail(resultList[i])) {
              console.log("is email");
            } else {
              console.log("not email");
            }
          }
        }
      }
      console.log(resultList);
      //   console.log("generateRandomString", generateRandomString());
      //   setPassword(generateRandomString());
      let randomString = generateRandomString();
      let randomNumber = generateRandomNumber();

      let requestPayload = resultList.map((element, index) => {
        var date = new Date();
        date.setDate(date.getDate() + 1);
        const dates = date.toISOString().replace("T", " ").replace("Z", "");
        console.log(password);
        console.log("cust", cust);
        // expiryDate.setDate(expiryDate.getDate() + 1);
        return {
          uid: (100000 + Math.floor(Math.random() * 900000)).toString(),
          email: element,
          username: randomString,
          channelName: atob(surveyId),
          //   firstName: userData?.FirstName,
          //   lastName: userData?.LastName,
          expiry_time: dates,
          code: parseInt(randomNumber),
          url: `${process.env.REACT_APP_DOMAIN}call/${randomString}/${surveyId}/${cust}/${startD}/${endD}/${callMins}`,
        };
      });
      console.log(requestPayload);
      sendCallInvitation(requestPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            console.log("localData.customer_id", localData.customer_id);
            console.log(
              "subscriptionPlan.startDate",
              subscriptionPlan.startDate
            );
            console.log("subscriptionPlan.endDate", subscriptionPlan.endDate);
            console.log(
              "subscriptionPlan.plan.callMinutes",
              subscriptionPlan?.plan?.callMinutes
            );

            setShowLInkAndPassword(true);
            setGeneratedLink(
              res?.data?.data?.url + "\n" + res?.data?.data?.code
            );

            setPassword(res?.data[0]?.code);

            toast.success(" Successfully !", {
              autoClose: 3000,
            });
          }
        },
        (err) => {
          console.log("err", err);
          if (err.response.status === 400) {
            toast.error("Customer admin email should be unique !", {
              autoClose: 3000,
              theme: "light",
            });
          } else if (err.response.status === 401) {
            toast.error("Unauthorized !", { autoClose: 3000, theme: "light" });
          } else if (err.response.status === 500) {
            toast.error("Something went wrong, please try again !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        }
      );
      //   console.log(requestPayload, "requestPayload");
    } else {
      errors.showMessages();
    }
  };

  function copyLink() {
    try {
      /* Get the text field */
      var copyText = document.getElementById("copy_text");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      toast.success("Link Copied !", { autoClose: 3000, theme: "light" });
    } catch (err) {
      toast.error("Copying Link failed  !", {
        autoClose: 3000,
        theme: "light",
      });
    }
  }

  function copyPassword() {
    try {
      /* Get the text field */
      var copyText = document.getElementById("copy_password");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      toast.success("Password Copied !", { autoClose: 3000, theme: "light" });
    } catch (err) {
      toast.error("Copying Password failed  !", {
        autoClose: 3000,
        theme: "light",
      });
    }
  }

  function fetchInviteHistory() {
    setCallInHist({ ...callInHist, loading: true, data: [], visible: false });
    getCallInviteHist({
      channelName: atob(surveyId),
    }).then(
      (res) => {
        console.log("res", res);

        setCallInHist({
          ...callInHist,
          loading: false,
          data: res.data,
          visible: true,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.linkModal}
        toggle={props.toggleLinkModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader className="bg-primary-blue" toggle={props.toggleLinkModal}>
          {"Send Invites To Participants"}
        </ModalHeader>
        <ModalBody
        // style={{ padding: '50px 20px 50px 20px' }}
        >
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="f-w-600 f-14">Add Participants</label>
                <textarea
                  className={
                    errors.emaillist
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  {...register("emaillist")}
                  rows={"4"}
                  placeholder='Enter emails followed by ","'
                />
                <span className="text-danger">{errors.emaillist?.message}</span>
              </div>
            </div>

            <div className="col-12 mt-2 d-flex justify-content-end">
              <button
                className=" btn btn-primary-blue"
                onClick={handleSubmit(onSubmit)}
              >
                Send Invite
              </button>
            </div>

            {showLinkAndPassword ? (
              <>
                {/* <label className="f-14 f-w-600  mt-2">Generated Link</label>
                <div className="d-flex justify-content-center">
                  <input
                    className="form-control"
                    id={"copy_text"}
                    disabled={true}
                    value={`${generatedLink}`}
                  />

                  <div
                    className="copy_button ms-2 d-flex justify-content-center align-items-center cursor_pointer"
                    onClick={() => copyLink()}
                    style={{
                      height: "50px",
                      borderRadius: "6px",
                      width: "50px",
                      background: "#e0e0e0",
                    }}
                  >
                    <Copy
                      id={"copy"}
                      className=""
                      height={"22px"}
                      width={"22px"}
                    />
                  </div>
                </div> */}

                {/* <>
                  <label className="f-14 f-w-600  mt-2">
                    Generated Password
                  </label>
                  <div className="d-flex justify-content-center">
                    <input
                      className="form-control"
                      id={"copy_password"}
                      disabled={true}
                      value={`${password}`}
                    />

                    <div
                      className="copy_button ms-2 d-flex justify-content-center align-items-center cursor_pointer"
                      onClick={() => copyPassword()}
                      style={{
                        height: "50px",
                        borderRadius: "6px",
                        width: "50px",
                        background: "#e0e0e0",
                      }}
                    >
                      <Copy
                        id={"copy"}
                        className=""
                        height={"22px"}
                        width={"22px"}
                      />
                    </div>
                  </div>
                </> */}

                <>
                  <label className="f-14 f-w-600  mt-2">
                    Generated Link And Password
                  </label>
                  <div className="d-flex justify-content-center">
                    <textarea
                      className="form-control"
                      id={"copy_password"}
                      disabled={true}
                      value={`${generatedLink}`}
                      rows="2"
                    ></textarea>

                    <div
                      className="copy_button ms-2 d-flex justify-content-center align-items-center cursor_pointer"
                      onClick={() => copyPassword()}
                      style={{
                        height: "50px",
                        borderRadius: "6px",
                        width: "50px",
                        background: "#e0e0e0",
                      }}
                    >
                      <Copy
                        id={"copy"}
                        className=""
                        height={"22px"}
                        width={"22px"}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"copy"}
                      >
                        Copy Link
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </>
              </>
            ) : null}

            <div className="d-flex justify-content-end mt-4">
              <a>
                <p
                  className="cursor_pointer"
                  onClick={() => {
                    fetchInviteHistory()
                  }}
                >
                  {"See Invite History"}
                </p>
              </a>
            </div>

            {callInHist.visible ? (
              callInHist.loading ? (
                <div className="loader-box justify-content-center">
                  <div className="loader">
                    <div className="line bg-dark"></div>
                    <div className="line bg-dark"></div>
                    <div className="line bg-dark"></div>
                    <div className="line bg-dark"></div>
                  </div>
                </div>
              ) : callInHist.data?.length > 0 ? (
                <div
                  className="table-responsive"
                  style={{ maxHeight: "400px", overFlowX: "auto" }}
                >
                  <table className="table table-bordered mt-4">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{"Url"}</th>
                        <th scope="col">{"Email"}</th>
                        <th scope="col">{"Passcode"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {callInHist.data?.map((val, i) => (
                        <tr key={i}>
                          <td>{val.url}</td>
                          <td>{val.email}</td>
                          <td>{val.code}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-center mb-0 text-danger">
                  No history found !
                </p>
              )
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default GuestUserLinkGeneration;
