import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadChecklistItemAttachment } from "../../../../api/index";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SurveyLevDocModal from "./SurveyLevDocModal";
import PhotoVideoGallery from "./PhotoVideoGallery";
import VideoApp from "../../../dashboard/videoCall/VideoApp";
import { MessageCircle } from "react-feather";
import {
  validateCallMinsWithSubscriptionPlan,
  compressFile,
} from "../../../ProjectComponents/Common/Functions/CommonFunctions";
import ChatUI from "../../../dashboard/defaultCompo/surveyList/surveyDashboard/surveySectionList/ChatUI";
import db from "../../../../data/config";
import { getAuth } from "firebase/auth";
// surveyList/surveyDashboard/surveySectionList/ChatUI

function UploadPhotoVideo() {
  let location = useLocation();
  let navigate = useNavigate();
  const surveyLevFileRef = useRef();
  const imageUploadRef = useRef();
  const videoUploadRef = useRef();

  const surveyData = location.state;
  const { id } = useParams();
  const surveyType=surveyData.surveyType;
  console.log("surveyType",surveyType)
  const ref = db.collection("notifications");
  const [surveyLevFile, setSurveyLevFile] = useState("");
  const [image, setImage] = useState("");
  const [compress, setCompress] = useState(false);
  const [compress2, setCompress2] = useState(false);
  const [video, setVideo] = useState("");
  const [geolocation, setGeoLocation] = useState({});
  const [chatContainer, setChatContainer] = useState(false);
  const [unReadMsgCount, setUnreadMsgCount] = useState(0);
  const GuestVideoCall = JSON.parse(localStorage.getItem("GuestVideoCall"));
  const localData = JSON.parse(localStorage.getItem("login_data"));
  const firebaseAuthFail = JSON.parse(localStorage.getItem("firebaseAuthFail"));
  const subscriptionPlan = JSON.parse(localStorage.getItem("subscription"));
  const guestUserdata = JSON.parse(localStorage.getItem("guestUserData"));
  const emailAddress = JSON.parse(localStorage.getItem("login_data"));

  const [open1, setOpen] = useState();
  const [call, setCall] = useState(false);
  const [basic_settings, setBasicSetting] = useState({
    dots: true,
    infinite: true,
    speed: 100,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(async () => {
    localStorage.setItem("GuestVideoCall", false);
    getLocation();
    console.log("location", location);
    if (firebaseAuthFail === false) {
      await calUnreadMsgCount();
    }
  }, []);

  function getLocation() {
    if (navigator.geolocation) {
      const res = navigator.geolocation.getCurrentPosition(showPosition);
      console.log("res", res);
    } else {
      console.log("Geolocation is not supported by this browser.");
      toast.error(
        "Unable to capture location, please enable browser location, refresh & try again !",
        {
          autoClose: 4000,
          theme: "light",
          
        }
      );
    }
  }
  function showPosition(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setGeoLocation({ ...geolocation, lat: latitude, long: longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }

  function uploadSurveyLevFile() {
    console.log("surveyLevFile", surveyLevFile);

    const fileSizeInBytes = surveyLevFile.size;
    const maxSizeInBytes = 1024 * 20 * 1048576; // 1MB
    console.log(surveyLevFile.length);
    if (surveyLevFile.length === 0 || surveyLevFile == undefined) {
      toast.error("Please select a file !", {
        autoClose: 3000,
        theme: "light",
        
      });
    } else {
      if (fileSizeInBytes > maxSizeInBytes) {
        setSurveyLevFile("");
        surveyLevFileRef.current.value = "";
        toast.error("File size exceeds limit :" + maxSizeInBytes + "!", {
          autoClose: 3000,
          theme: "light",
          
        });
      } else {
        const reqPayload = {
          survey: id,
          checklist_file: surveyLevFile,
          name: surveyLevFile.name,
          withAnswer: "False",
          "createdBy ": localData.user,
          lat: geolocation.lat.toFixed(5),
          long: geolocation.long.toFixed(5),
          withSurveyOnly: "True",
        };
        console.log(
          "surveyLevFile.type.includes(image)",
          surveyLevFile.type.includes("image")
        );
        if (surveyLevFile.type.includes("image")) {
          reqPayload.fileType = "Image";
        } else {
          reqPayload.fileType = "Document";
        }
        console.log("reqPayload", reqPayload);
        var formdata = new FormData();

        for (var key in reqPayload) {
          formdata.append(key, reqPayload[key]);
        }

        uploadChecklistItemAttachment(formdata).then(
          (res) => {
            console.log("res.data", res.data);
            setSurveyLevFile("");
            surveyLevFileRef.current.value = "";
            toast.success("Document uploaded successfully", {
              autoClose: 3000,
              theme: "light",
              
            });
          },
          (err) => {
            console.log("err", err);
          if(err.response==undefined){

            toast.error("File size is too large !", {
              autoClose: 3000,
              theme: "light",
              
            });
          }
          else{
            toast.error("Something went wrong", {
              autoClose: 3000,
              theme: "light",
              
            });
          }
          
          }
        );
      }
    }
  }

  async function uploadImage() {
    
   

    if (image !== undefined) {
      console.log("image", image);
      const fileSizeInBytes = image.size;
      const maxSizeInBytes = 1024 * 20 * 1048576; // 1MB
      console.log(image.length);
      if (image.length === 0 || image == undefined) {
        toast.error("Please select a file !", {
          autoClose: 3000,
          theme: "light",
          
        });
      } else {
        if (fileSizeInBytes > maxSizeInBytes) {
          setSurveyLevFile("");
          imageUploadRef.current.value = "";
          toast.error("File size exceeds limit :" + maxSizeInBytes + "!", {
            autoClose: 3000,
            theme: "light",
            
          });
        } else {
          const reqPayload = {
            survey: id,
            checklist_file: image,
            name: image.name,
            withAnswer: "False",
            "createdBy ": localData.user,
            lat: geolocation.lat.toFixed(5),
            long: geolocation.long.toFixed(5),
            // "withSurveyOnly": "False",
            fileType: "Image",
          };

          console.log("reqPayload", reqPayload);
          
          var formdata = new FormData();

          for (var key in reqPayload) {
            formdata.append(key, reqPayload[key]);
          }

          uploadChecklistItemAttachment(formdata).then((res) => {
              console.log("res.data", res.data);
              setSurveyLevFile('');
              imageUploadRef.current.value = '';
              toast.success("Document uploaded successfully", {
                  autoClose: 3000,
                  theme: "light",
                  
              });
          }, (err) => {

              console.log("err", err);
              toast.error("Something went wrong", {
                  autoClose: 3000,
                  theme: "light",
                  
              });
          })
        }
      }
    } else {
      toast.error("Please select a file !", {
        autoClose: 3000,
        theme: "light",
        
      });
    }
  }

  function uploadVideo() {
    if (video !== undefined) {
      console.log("video", video);
      const fileSizeInBytes = video.size;
      const maxSizeInBytes = 1024 * 20 * 1048576; // 1MB
      console.log(video.length);
      console.log("fileSizeInBytes", fileSizeInBytes);
      if (video.length === 0 || video == undefined) {
        toast.error("Please select a file !", {
          autoClose: 3000,
          theme: "light",
          
        });
      } else {
        if (fileSizeInBytes > maxSizeInBytes) {
          // setSurveyLevFile('');
          // videoUploadRef.current.value = '';
          toast.error("File size exceeds limit :" + maxSizeInBytes + "!", {
            autoClose: 3000,
            theme: "light",
            
          });
        } else {
          const reqPayload = {
            survey: id,
            checklist_file: video,
            name: video.name,
            withAnswer: "False",
            "createdBy ": localData.user,
            lat: geolocation.lat.toFixed(5),
            long: geolocation.long.toFixed(5),
            // "withSurveyOnly": "False",
            fileType: "Video",
          };

          console.log("reqPayload", reqPayload);
          var formdata = new FormData();

          for (var key in reqPayload) {
            formdata.append(key, reqPayload[key]);
          }

          uploadChecklistItemAttachment(formdata).then(
            (res) => {
              console.log("res.data", res.data);
              setSurveyLevFile("");
              videoUploadRef.current.value = "";
              toast.success("Document uploaded successfully", {
                autoClose: 3000,
                theme: "light",
                
              });
            },
            (err) => {
              console.log("err", err);
              if(err.response==undefined){

                toast.error("File size is too large !", {
                  autoClose: 3000,
                  theme: "light",
                  
                });
              }else{
                toast.error("Something went wrong", {
                  autoClose: 3000,
                  theme: "light",
                  
                });
              }



             
            }
          );
        }
      }
    } else {
      toast.error("Please select a file !", {
        autoClose: 3000,
        theme: "light",
        
      });
    }
  }

  // function for check if the customer plan have subscription mins left, if not, not allowing to make call
  async function checkTotalCustSecs() {
    console.log("firebaseAuthFail", firebaseAuthFail);
    // If subscription is not present no restriction or firebase validation
    if (firebaseAuthFail) {
      toast.error(
        "Unable to calculate video call minutes, please contact admin!",
        {
          autoClose: 3000,
          theme: "light",
          
        }
      );
    }

    if (Object.keys(subscriptionPlan).length > 0) {
      if (firebaseAuthFail === false) {
        validateCallMinsWithSubscriptionPlan(
          localData.customer_id,
          subscriptionPlan.startDate,
          subscriptionPlan.endDate,
          subscriptionPlan.plan.callMinutes
        ).then((res) => {
          console.log("res", res);
          if (res === false) {
            toast.error(
              "Your subscription limit has been exhausted, contact admin !",
              {
                autoClose: 3000,
                theme: "light",
                
              }
            );
          } else {
            setOpen(true);
            setCall(true);
            setBasicSetting({
              ...basic_settings,
              slidesToShow: 2,
              slidesToScroll: 2,
            });

            document.querySelector(".page-main-header").classList.add("open");
            document.querySelector(".page-sidebar").classList.add("open");
          }
        });
      } else {
        setOpen(true);
        setCall(true);
        setBasicSetting({
          ...basic_settings,
          slidesToShow: 2,
          slidesToScroll: 2,
        });

        document.querySelector(".page-main-header").classList.add("open");
        document.querySelector(".page-sidebar").classList.add("open");
      }
    } else {
      console.log("ssssssssssssshould runnnnnnnnnnnnnnnn");
      setOpen(true);
      setCall(true);
      setBasicSetting({
        ...basic_settings,
        slidesToShow: 2,
        slidesToScroll: 2,
      });

      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    }
  }

  async function calUnreadMsgCount() {
    getAuth();

    await ref
      .where("surveyId", "==", parseInt(id))
      .where(
        "from",
        "!=",
        GuestVideoCall === true ? guestUserdata.email : emailAddress?.emailId
      )
      // .orderBy("time")
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          console.log(doc);

          const msgData = doc.data();
          // items.push(doc.data());
          // items.push(doc);

          if (
            msgData.seenBy.includes(
              GuestVideoCall === true
                ? guestUserdata.email
                : emailAddress?.emailId
            )
          ) {
          } else {
            items.push(msgData);
          }
        });
        console.log("items", items);

        console.log("items.length", items.length);

        setUnreadMsgCount(items.length);
      });
  }

  return (
    <React.Fragment>
      <div className="upload_photo_video_survey fontFamily py-4">
        <div className="card">
          <div
            className="title bg-primary-blue d-flex justify-content-between"
            id="card_title"
          >
            <p className="mb-0 f-24">
              <span
                onClick={() => {
                  navigate(-1);
                }}
                className="text-light me-2"
                style={{ fontSize: "20px", cursor: "pointer" }}
              >
                <i className="fa fa-arrow-circle-left"></i>
              </span>
              {`Survey ${surveyData.surveyNo}`}
            </p>
            <p></p>
            <div>
              <button className="btn btn-light" onClick={checkTotalCustSecs}>
                Start A Call
                <i className="fa fa-video-camera ms-2"></i>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div
                className="col-12 col-md-6 order-2"
                style={{
                  border: "1px solid #97A6A8",
                  borderRadius: "12px",
                  padding: "5px 10px 5px 10px",
                }}
              >
                <div className="d-flex">
                  <p className="mb-0 f-18 f-w-600">{`Survey Status : `}</p>
                  <p className="mb-0 f-18">{`${surveyData.status}`}</p>
                </div>

                <div className="form-group">
                  <label className="col-form-label f-w-600">
                    {"Upload File"}
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    ref={surveyLevFileRef}
                    accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,"
                    onChange={async (event) => {
                      let file = event.target.files[0];
                      if (file && file.type.startsWith("image/")) {
                        console.log("Selected file is an image");
                        setCompress2(true);
                        await compressFile(event.target.files[0]).then(
                          (res) => {
                            console.log("res", res);
                            setSurveyLevFile(res);
                            setCompress2(false);
                          },
                          (err) => {
                            console.log("err", err);
                            setCompress2(false);
                            toast.error("Error in compressing image", {
                              autoClose: 1000,
                              theme: "light",
                              
                            });
                          }
                        );
                      } else {
                        setSurveyLevFile(file);
                      }
                    }}
                  />

                  <div className="d-flex justify-content-end my-3">
                    <SurveyLevDocModal />
                    <button
                      disabled={compress2}
                      onClick={() => uploadSurveyLevFile()}
                      className="btn btn-primary-blue ms-2"
                    >
                      Upload File
                    </button>
                  </div>

                  <span className="text-danger f-12 f-w-600">
                    Note: Above upload files will be done at survey level it
                    will not attach inside gallery
                  </span>
                </div>

                <hr className="mt-0" />

                <div className="form-group">
                  <label className="col-form-label f-w-600">
                    {"Upload Image"}
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    ref={imageUploadRef}
                    accept=".jpg,.jpeg,.png,"
                    onChange={async (event) => {

                        if(!event.target.files[0].type.startsWith('image/')){
                          toast.error("Upload only image files !", {
                            autoClose: 1000,
                            theme: "light",
                            
                          });
                          return
                        }
                      
                      setCompress(true);
                      await compressFile(event.target.files[0]).then(
                        (res) => {
                          console.log("res", res);
                          setImage(res);
                          setCompress(false);
                        },
                        (err) => {
                          console.log("err", err);
                          setCompress(false);
                          toast.error("Error in compressing image", {
                            autoClose: 1000,
                            theme: "light",
                            
                          });
                        }
                      );
                    }}
                  />

                  <div className="d-flex justify-content-end my-3">
                    <PhotoVideoGallery data={"image"} />
                    <button
                      disabled={compress}
                      onClick={() => uploadImage()}
                      className="btn btn-primary-blue ms-2"
                    >
                      Upload Image
                    </button>
                  </div>
                </div>

                {surveyType!=="Sire"&&<div className="form-group">
                  <label className="col-form-label f-w-600">
                    {"Upload Video"}
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    ref={videoUploadRef}
                    accept=".mp4,.avi,.mov,.wmv,.flv,.mkv,.3gp,.webm,.m4v,.mpeg"
                    onChange={(event) => setVideo(event.target.files[0])}
                  />

                  <div className="d-flex justify-content-end my-3">
                    <PhotoVideoGallery data={"video"} />
                    <button
                      onClick={() => uploadVideo()}
                      className="btn btn-primary-blue ms-2"
                    >
                      Upload Video
                    </button>
                  </div>
                </div>}
              </div>

              {call ? (
                <div className="col-12 col-md-6 order-1">
                  <VideoApp
                    call={call}
                    setOpen={setOpen}
                    setCall={setCall}
                    chanelName={id}
                    // token={token}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          if (firebaseAuthFail) {
            toast.error("Cannot open chat, please contact admin!", {
              autoClose: 3000,
              theme: "light",
              
            });
          } else {
            setChatContainer(!chatContainer);
          }
        }}
        style={{
         // position: "relative",
          background: "#2264AB",
          color: "#FFF",
          position: "absolute",
          width: "50px",
          height: "50px",
          bottom: "2px",
          right: "10px",
          borderRadius: "50px",
          textAlign: "center",
          border: "none",
          zIndex: 9,
        }}
      >
        <MessageCircle
          className="cursor_pointer "
          height={"18px"}
          width={"18px"}
        />

        {unReadMsgCount > 0 ? (
          <span
            style={{
              background: "red",
              width: "auto",
              height: "auto",
              borderRadius: "12px",
              position: "absolute",
              top: "0px",
            }}
          >
            <p
              className="m-0"
              style={
                unReadMsgCount < 10
                  ? { padding: "2px 8px 2px 8px" }
                  : { padding: "2px 4px 2px 4px" }
              }
            >
              {unReadMsgCount}
            </p>
          </span>
        ) : null}
      </button>
      {chatContainer ? <ChatUI surveyId={id} /> : ""}
    </React.Fragment>
  );
}

export default UploadPhotoVideo;
