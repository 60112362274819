import React, { useState, useEffect } from 'react';
import logo from '../../../assets/images/Vedam/Vedam.png';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fetchOtp, resetPassword } from '../../../api/index';
import SweetAlert from 'sweetalert2';
import TimerComponent from './TimerComponent';

function ForgotPassword() {
    let navigate = useNavigate();
    let location = useLocation();

    const [username, setUsername] = useState({ value: '', error: '' });
    const [otpSent, setOtpSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isTimer, setIsTimer] = useState(false);
    const [firstOtpSent, setFirstOtpSent] = useState(false);
    const [disable, setDisable] = useState(false);
    const [timerVal, setTimerVal] = useState(300);
    const [error, setError] = useState('');
    const [otpexpired,setOtpexpired]=useState(false)
    const schema = yup.object().shape({
        password: yup.string()
            .required('Password is required')
            .matches(
                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$&])(?=.{8,})/,
                "Atleast 1 Uppercase, 1 Number, 1 Special character and must have 8 characters"
              ),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        otp1: yup.string().required('Required !'),
        otp2: yup.string().required('Required !'),
        otp3: yup.string().required('Required !'),
        otp4: yup.string().required('Required !'),
    }).required();

    const { register, handleSubmit, formState, setValue } = useForm({
        resolver: yupResolver(schema), mode: "onChange", defaultValues: {
            username: location.state,
        }
    }); // initialise the hook

    const { errors } = formState;


    function validMail(mail) {
        return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(mail);
    }


    function timer() {
        // timer value has been set in the sign in component
        var sec = timerVal;
        var timer1 = sec, minutes, seconds;

        var timer = setInterval(function () {

            minutes = parseInt(timer1 / 60, 10)
            seconds = parseInt(timer1 % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            setIsTimer(true);
            document.getElementById('safeTimerDisplay').innerHTML = minutes + ":" + seconds;

            if (minutes === 0 && seconds === 0) {
                setDisable(false);
            }

            localStorage.setItem("timer", timer1);
            setTimerVal(timer1);

            if (--timer1 < 0) {
                timer1 = 0;
                clearInterval(timer);
                setDisable(false);
                // navigate(`${process.env.PUBLIC_URL}/signin`);
            }
        }, 1000);

    }

    function handleEmailChange(e) {

        setUsername({ ...username, value: e.target.value, error: '' });
        // if (validMail(e.target.value)) {
        //     console.log('valid');
        // } else {
        //     console.log('invalid');
        //     setUsername({ ...username, value: e.target.value, error: 'Please enter valid email' });
        // }
    }

    // handle method for input fields
    const handleChange = (element, index) => {

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    }

    const handleTimerDone = () => {
        setDisable(false);
        setOtpexpired(true)
    };

    function getOtp() {

        if (username.error.length === 0) {

            console.log("username.value", username.value);
            localStorage.setItem('otpusername', username.value);

            setFirstOtpSent(true);


            // setDisable(true);

            fetchOtp({ username: username.value })
                .then((res) => {
                    console.log("res", res);
                    setOtpSent(true);
                    //   timer();
                })
                .catch((err) => {

                    console.log("err", err.response);

                    if (err.response.status == 400 && err.response.data == "Username not available") {
                        console.log("data", err.response.data);
                        toast.error("This username not available !", {
                            autoClose: 2000
                        })
                    }
                    else {
                        toast.error("Something went wrong !", {
                            autoClose: 2000
                        })
                    }

                    setOtpSent(false)
                    setDisable(false);
                    setFirstOtpSent(false)
                })
        }
    }


    const onSubmit = data => {
        console.log("data", data)


        if (data !== '') {
            console.log("data", data);
            setError('');


            const reqPayload = {
                username: localStorage.getItem('otpusername'),
                otp: data.otp1 + data.otp2 + data.otp3 + data.otp4,
                new_password: data.password
            }

            resetPassword(reqPayload).then((res) => {

                console.log('res', res);
                localStorage.removeItem('access');
                localStorage.removeItem('customer_id');
                localStorage.removeItem('login_data');
                navigate(`${process.env.PUBLIC_URL}/signin`);

            }, (err) => {

                console.log('err', err);
                console.log('errrr', err.response);
                if (err.response.status === 302) {
                    console.log("inside",err.response)
                    localStorage.removeItem('access');
                    localStorage.removeItem('customer_id');
                    localStorage.removeItem('login_data');
                    toast.success("Password reset successful !", { autoClose: 2000, theme: "light", });

                    setTimeout(()=>{
                        navigate(`${process.env.PUBLIC_URL}/signin`);
                    },2200)
                   

                } else if (err.response.status === 403) {
                    SweetAlert.fire({
                        title: "Something went wrong !",
                        text: "User activation request not approved.",
                        icon: "error",
                    });
                } else if (err.response.status === 500) {
                    SweetAlert.fire({
                        title: "Something went wrong !",
                        text: "Server Error",
                        icon: "error",
                    });
                } else if (err.response.status === 401) {
                    console.log("err.response.", err.response);
                    setError(err.response.data.english);
                }
            });

        } else {
            errors.showMessages();
        }
    };


    return (
        <React.Fragment>
            <ToastContainer />
            <div className="Reset_Password page-wrapper">
                <div className="container-fluid">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className="auth-innerright">
                                    <div className="reset-password-box">
                                        <div className="text-center"><img src={logo} alt="" /></div>
                                        <div className="card mt-4 mb-0 p-0">
                                            <div id='card_title' className='title bg-primary-blue'>
                                                <p className='f-24 mb-0'>{"Reset User Password"}</p>
                                            </div>


                                            <div className='card-body'>
                                                <div className='form-group'>
                                                    <label className='f-w-600'>Username</label>
                                                    <input
                                                        className={username.error.length > 0 ? "form-control is-invalid" :
                                                            'form-control'}
                                                        type={'username'}
                                                        placeholder="Enter your username"
                                                        name='username'
                                                        onChange={(e) => { handleEmailChange(e) }}
                                                        value={username.value}
                                                    />

                                                    {username.error.length > 0 ?
                                                        <p className='mb-0 text-danger f-14'>{username.error}</p> : null}
                                                </div>

                                                <div className='mt-2'>
                                                    {isTimer === true ?
                                                        <p className='text-center f-w-600'>
                                                            <i style={{ fontSize: '15px' }} className="fa fa-clock-o"></i> Resend OTP in:
                                                            <span id='safeTimerDisplay'></span></p>
                                                        : null}
                                                </div>
                                                {disable && <TimerComponent onTimerDone={handleTimerDone} />}
                                                {otpexpired &&
                                                    <div className='text-center'>
                                                        <h6>Sorry, the OTP has expired. Please request a new one.</h6>
                                                    </div>
                                                }


                                                <div className='d-flex mt-3 align-items-center'>
                                                    <button
                                                        disabled={disable}
                                                        onClick={() => {
                                                              if(otpexpired==true){
                                                                setOtpexpired(false)
                                                              }
                                                            setDisable(true);
                                                            getOtp()
                                                        }} className={'btn btn-primary w-100 text-center'}>
                                                        {firstOtpSent !== true ? "Send OTP" : "Resend OTP"}
                                                    </button>
                                                </div>

                                                {otpSent ?
                                                    <>
                                                        <label className='f-w-600 mt-2'>Enter OTP</label>

                                                        <div className="d-flex justify-content-center OTP">

                                                            <input
                                                                className={errors.otp1 ? "form-control is-invalid" : "form-control"}
                                                                id={"exampleFormControlInput1"}
                                                                type="text"
                                                                maxLength="1"
                                                                {...register('otp1')}
                                                                // onChange={e => handleChange(e.target)}
                                                                onKeyUp={e => handleChange(e.target)}
                                                            />
                                                            <input
                                                                className={errors.otp2 ? "form-control is-invalid" : "form-control"}
                                                                id={"exampleFormControlInput2"}
                                                                type="text"
                                                                maxLength="1"
                                                                {...register('otp2')}
                                                                // onChange={e => handleChange(e.target)}
                                                                onKeyUp={e => handleChange(e.target)}
                                                            />
                                                            <input
                                                                className={errors.otp3 ? "form-control is-invalid" : "form-control"}
                                                                id={"exampleFormControlInput3"}
                                                                type="text"
                                                                maxLength="1"
                                                                {...register('otp3')}
                                                                // onChange={e => handleChange(e.target)}
                                                                onKeyUp={e => handleChange(e.target)}
                                                            />
                                                            <input
                                                                className={errors.otp4 ? "form-control is-invalid" : "form-control"}
                                                                id={"exampleFormControlInput4"}
                                                                type="text"
                                                                maxLength="1"
                                                                {...register('otp4')}
                                                                // onChange={e => handleChange(e.target)}
                                                                onKeyUp={e => handleChange(e.target)}
                                                            />


                                                        </div>


                                                        <div className="form-group">

                                                            <label className="col-form-label f-w-600">{"New Password"}</label>
                                                            <div className="input-group">
                                                                <input
                                                                    className={errors.password ? "form-control is-invalid" : "form-control"}
                                                                    type={showPassword ? "text" : "password"}
                                                                    placeholder='Enter your password'
                                                                    name='password'
                                                                    {...register('password')}
                                                                />
                                                                {showPassword ?
                                                                    <span className="input-group-text">
                                                                        <i className="fa cursor_pointer fa-eye-slash"
                                                                            onClick={() => setShowPassword(false)}></i>
                                                                    </span>
                                                                    :
                                                                    <span className="input-group-text">
                                                                        <i className="fa cursor_pointer fa-eye"
                                                                            onClick={() => setShowPassword(true)}></i>
                                                                    </span>
                                                                }
                                                            </div>
                                                            <span className='text-danger'>{errors.password?.message}</span>

                                                            {error.length > 0 ? <p className='text-danger text-center'>
                                                                {error}</p> : null}
                                                        </div>
                                                        <div className="form-group">

                                                            <label className="col-form-label f-w-600">{"Confirm New Password"}</label>
                                                            <div className="input-group">
                                                                <input
                                                                    className={errors.confirmPassword ? "form-control is-invalid" : "form-control"}
                                                                    type={showConfirmPassword ? "text" : "password"}
                                                                    placeholder='Enter your password'
                                                                    name='confirmPassword'
                                                                    {...register('confirmPassword')}
                                                                />
                                                                {showConfirmPassword ?
                                                                    <span className="input-group-text">
                                                                        <i className="fa cursor_pointer fa-eye-slash"
                                                                            onClick={() => setShowConfirmPassword(false)}></i>
                                                                    </span>
                                                                    :
                                                                    <span className="input-group-text">
                                                                        <i className="fa cursor_pointer fa-eye"
                                                                            onClick={() => setShowConfirmPassword(true)}></i>
                                                                    </span>
                                                                }
                                                            </div>
                                                            <span className='text-danger'>{errors.confirmPassword?.message}</span>

                                                            {error.length > 0 ? <p className='text-danger text-center'>
                                                                {error}</p> : null}
                                                        </div>



                                                        <button
                                                            onClick={handleSubmit(onSubmit)}
                                                            className="btn btn-primary w-100 mt-4"
                                                            type="submit">{"Submit"}</button>
                                                    </>
                                                    : null}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ForgotPassword