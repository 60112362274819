import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {  update_noc,  } from "../../../../api/index";
import { toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("NOC name is required !"),
  })
  .required();

function Edit_NOC_Master(props) {
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;
  const customer_id = localStorage.getItem("customer_id");

  useEffect(() => {
    console.log("props.data", props.data);
    setValue("name", props.data?.noc);

  }, [props.data]);

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      console.log("props", props);

      const reqPayload = {
         Id:props.data.Id,
          noc: data.name,
          nocCategoryId:props.nocCategoryId,
          customer:customer_id
      }
      console.log("reqPayload", reqPayload);

          update_noc(reqPayload)
              .then((res) => {
                  console.log("res", res);
                  toast.success("NOC updated successfully !", {
                    autoClose: 3000, theme: "light",
                  });
                  props.toggle();
                  props.fetch_NOC_lists();

              }, (err) => {
                toast.success("Something Went Wrong !", {
                    autoClose: 3000, theme: "light",
                  });
                  console.log("err", err);
              })

      } else {
          errors.showMessages();
      }
    }
  

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={props.toggle} className="bg-primary-blue">
          {"Update NOC "}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"NOC Name"}</label>
              {/* <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                defaultValue={props.data?.noc}
                placeholder="Name*"
              /> */}
              <textarea
                 className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                placeholder="Enter NOC Name*"
                defaultValue={props.data?.noc}
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.name?.message}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
           Save Changes
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
export default Edit_NOC_Master;
