import {call,put, takeLatest} from "redux-saga/effects";
import { fetchLoginApi } from "../../api";
import { GET_LOGIN_LIST, WATCH_LOGIN_LIST } from "../../redux/actionTypes";

function* fetchLoginAsyn() {
    let Data = yield call(fetchLoginApi);
    yield put({type : GET_LOGIN_LIST,login:Data});
}

export function* watchLoginData() {
    yield takeLatest(WATCH_LOGIN_LIST,fetchLoginAsyn)
}