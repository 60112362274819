import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { Trash2, Edit } from "react-feather";
import {
  getCheckListFormElement,
  createCheckListForm,
  updateCheckListForm,
  deleteCheckListForm,
} from "../../../../../api/index";
import SweetAlert from "sweetalert2";
import { useParams } from "react-router-dom";
import EditFormForSurvey from "../../../../ProjectComponents/CustomerAdmin/FormCreation/EditFormForSurvey";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Name is required")
      .max(500, "500 characters allowed !"),
    items: yup.array().of(
      yup.object().shape({
        key: yup.string().required("Required"),
        value: yup.string().notRequired(),
      })
    ),
  })
  .required();

function SurveyForm() {
  const [dataForUpdate, setDataForUpdate] = useState({});

  const [elementModal, setElementModal] = useState();
  const [checklistForm, setCheclistForm] = useState({
    data: {},
    loading: false,
  });
  const [isFormOfChecklist, setIsFormOfChecklist] = useState(false);
  const [editModal, setEditModal] = useState();
  const [risOpen, rsetIsOpen] = useState(0);
  const { id, cid } = useParams();
  const [risOpen2, rsetIsOpen2] = useState(0);

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: { items: [{}] },
  }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(async () => {
    setCheclistForm({ ...checklistForm, loading: true, data: [] });
    await fetchChecklistForm();
  }, []);

  const rtoggle = (id, sec_id) => {
    console.log("id", id);
    if (risOpen === id) {
      rsetIsOpen(null);
    } else {
      rsetIsOpen(id);
    }
  };

  const rtoggle2 = async (id) => {
    console.log("id", id);
    if (risOpen2 === id) {
      rsetIsOpen2(null);
    } else {
      rsetIsOpen2(id);
    }
  };

  function fetchChecklistForm() {
    getCheckListFormElement({ survey: id, withSurvey: "True" }).then(
      (res) => {
        console.log("res.data_of_checkList", res.data);
        setCheclistForm({
          ...checklistForm,
          loading: false,
          data: res.data.payload[0],
        });
        setIsFormOfChecklist(false);

        if (res.data.payload.length === 0) {
          getCheckListFormElement({ checklist: cid, withSurvey: "False" }).then(
            (res) => {
              console.log("res.data", res.data);
              setIsFormOfChecklist(true);
              if (res.data.payload.length > 0) {
                setCheclistForm({
                  ...checklistForm,
                  loading: false,
                  data: res.data.payload[0],
                });
              } else {
                setCheclistForm({ ...checklistForm, loading: false, data: [] });
              }
            },
            (err) => {
              console.log("err", err);
              setCheclistForm({ ...checklistForm, loading: false, data: [] });
            }
          );
        }
      },
      (err) => {
        console.log("err", err);
        setCheclistForm({ ...checklistForm, loading: false, data: [] });
      }
    );
  }

  const toggleElementModal = () => {
    setElementModal(!elementModal);

    if (elementModal === false || elementModal === undefined) {
      reset();
    }
  };

  const editToggle = () => {
    setEditModal(!editModal);
  };

  const onSubmit = (data) => {
    console.log("errors", errors);
    if (data !== "") {
      console.log("data", data);

      if (isFormOfChecklist == true) {
        const reqPayload = {
          withSurvey: true,
          checklist: cid,
          survey: id,
        };

        console.log("checklistForm.data", checklistForm.data);

        if (Object.keys(checklistForm.data).length > 0) {
          if (checklistForm.data.file.length > 0) {
            reqPayload.file = [...checklistForm.data.file];
            reqPayload.file.push({
              id:
                checklistForm.data.file[checklistForm.data.file.length - 1].id +
                1,
              title: data.name,
              values: data.items,
            });
          }
        } else {
          reqPayload.file = [{ id: 1, title: data.name, values: data.items }];
        }
        console.log("reqPayload", reqPayload);

        createCheckListForm(reqPayload).then(
          async (res) => {
            console.log("res", res);
            await fetchChecklistForm();
            toggleElementModal();
            toast.success("Form created successfully !", { autoClose: 3000, theme: 'light' });
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 3000, theme: 'light' });
          }
        );
      } else {
        console.log("patch");
        const reqPayload = {
          withSurvey: true,
          checklist: cid,
          Id: checklistForm.data.Id,
          survey: id,
        };

        const index =
          checklistForm.data.file[checklistForm.data.file.length - 1].id + 1;
        reqPayload.file = [...checklistForm.data.file];
        reqPayload.file.push({
          id: index,
          title: data.name,
          values: data.items,
        });
        reqPayload.Id = checklistForm.data.Id;
        console.log("reqPayload", reqPayload);
        updateCheckListForm(reqPayload).then(
          async (res) => {
            console.log("res", res);
            await fetchChecklistForm();
            toggleElementModal();
            toast.success("Form created successfully !", { autoClose: 3000, theme: 'light' });
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 3000, theme: 'light' });
          }
        );
      }
    } else {
      errors.showMessages();
    }
  };

  // Delete Form Function

  const delForm = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        let tempList = [...checklistForm.data.file];
        const reqPayload = {
          withSurvey: true,
          checklist: cid,
          Id: checklistForm.data.Id,
          survey: id,
        };

        for (let i = 0; i < tempList.length; i++) {
          if (tempList[i].id == val.id) {
            tempList.splice(i, 1);
          }
        }
        reqPayload.file = tempList;
        console.log("reqPayload", reqPayload);

        if (checklistForm.data.file.length > 1) {
          console.log("delete by updating");
          updateCheckListForm(reqPayload).then(
            async (res) => {
              console.log("res", res);
              await fetchChecklistForm();
              toast.success("Form deleted successfully !", { autoClose: 3000, theme: 'light' });
            },
            (err) => {
              console.log("err", err);
              toast.error("Something went wrong !", { autoClose: 3000, theme: 'light' });
            }
          );
        } else {
          console.log("hard delete");
          console.log("checklistForm.data.Id ", checklistForm.data.Id);
          // Firing delete api
          deleteCheckListForm({ Id: checklistForm.data.Id }).then(
            async (res) => {
              console.log("res", res);
              toast.success("Form deleted successfully !", { autoClose: 3000, theme: 'light' });
              await fetchChecklistForm();
            },
            (err) => {
              console.log("err", err);
              toast.error("Something went wrong !", { autoClose: 3000, theme: 'light' });
            }
          );
        }
      }
    });
  };

  return (
    <div className="mb-4">
      <div className="default-according style-1 ">
        <Card>
          <CardHeader className="bg-vedam position-relative p-2 d-flex justify-content-between">


            {/* <div
          className="title bg-primary-blue d-flex justify-content-between"
           id="card_title"
        > */}

            <div>
              <h5 className="mb-0">
                <Button
                  style={{ position: "relative" }}
                  as={Card.Header}
                  className="btn btn-link btn-vedam txt-white p-0 "
                  onClick={() => rtoggle2(1)}
                >
                  <p className="mb-0 f-16 me-5">Survey Form List</p>

                </Button>
              </h5>
            </div>

            <div>
              <button className="btn btn-light" onClick={toggleElementModal}>
                Create Form
              </button>
            </div>


            {/* </div> */}
          </CardHeader>
          <Collapse isOpen={risOpen2 === 1}>
            <div className="default-according style-1">
              <div className="row">
                <div className="default-according style-1" id="accordionoc">
                  {checklistForm.loading === true ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : checklistForm.data?.file?.length > 0 ? (
                    checklistForm.data.file.map((data, datai) => (
                      <div className="col-12 mt-2" key={datai}>
                        <Card>
                          <CardHeader className="bg-vedam position-relative">
                            <h5 className="mb-0">
                              <Button
                                as={Card.Header}
                                className="btn btn-link btn-vedam txt-white p-0"
                                color="dark"
                                onClick={() => rtoggle(datai + 1)}
                              >
                                {data.title}
                              </Button>
                            </h5>
                            <div
                              className="position-absolute"
                              style={{ right: "10px", top: "15px" }}
                            >
                              <Edit
                                onClick={() => {
                                  editToggle();
                                  setDataForUpdate(data);
                                }}
                                id={"editToolTip"}
                                className="cursor_pointer"
                                height={"18px"}
                                width={"18px"}
                              />

                              <Trash2
                                onClick={() => delForm(data)}
                                id={"editToolTip" + datai}
                                className="cursor_pointer ms-2"
                                height={"18px"}
                                width={"18px"}
                              />
                            </div>
                          </CardHeader>
                          <Collapse isOpen={risOpen === datai + 1}>
                            <CardBody className="">
                              <div className="row">
                                {data.values.map((ele, elei) => (
                                  <React.Fragment key={elei}>
                                    <div className="col-12 col-md-3 f-w-600">
                                      {ele.key} :
                                    </div>
                                    <div className="col-12 col-md-9">
                                      {ele.value}
                                    </div>
                                    <hr className="my-2 px-2" />
                                  </React.Fragment>
                                ))}
                              </div>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    ))
                  ) : (
                    <p className="text-danger text-center">No form exist !</p>
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </Card>
      </div>

      <EditFormForSurvey
        editModal={editModal}
        editToggle={editToggle}
        dataForUpdate={dataForUpdate}
        formList={checklistForm.data}
        fetchChecklistForm={fetchChecklistForm}
        isFormOfChecklist={isFormOfChecklist}
      />

      <Modal
        isOpen={elementModal}
        toggle={toggleElementModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          toggle={toggleElementModal}
          className="bg-primary-blue position-relative"
        >
          {"Create Form"}

          <button
            style={{
              top: "25%",
              left: "75%",
              tranform: "translate(-50%,-50%)",
            }}
            onClick={() => append({})}
            className="btn btn-light position-absolute"
          >
            Add Element
          </button>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-12 mb-2">
              <label className="f-w-600 f-14">{"Form Name"}</label>
              <textarea
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                placeholder="Enter form name*"
                {...register("name")}
                rows="2"
              ></textarea>

              <span className="text-danger f-14">{errors.name?.message}</span>
            </div>
          </div>

          {fields.map(({ id }, index) => (
            <React.Fragment key={id}>
              <div className="row">
                <div className="col-12 col-md-4 mb-2">
                  <label className="f-w-600 f-14">{"Element Name"}</label>
                  <input
                    className={
                      errors.items?.[index]?.key
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    name={`items[${index}].key`}
                    {...register(`items[${index}].key`)}
                    placeholder="Enter name*"
                  />

                  <span className="text-danger f-14">
                    {errors.items?.[index]?.key?.message}
                  </span>
                </div>

                <div className="col-12 col-md-7 mb-2">
                  <label className="f-w-600 f-14">{"Element Value"}</label>
                  <textarea
                    className={
                      errors.items?.[index]?.value
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    name={`items[${index}].value`}
                    {...register(`items[${index}].value`)}
                    placeholder="Enter value*"
                    rows={1}
                  ></textarea>

                  <span className="text-danger f-14">
                    {errors.items?.[index]?.value?.message}
                  </span>
                </div>

                <div className="col-12 col-md-1">
                  <label className="f-w-600 f-14 invisible">{"Action"}</label>

                  <div
                    id="elememtDel"
                    className={index > 0 ? "" : "cursor_banned "}
                    onClick={index > 0 ? () => remove(index) : null}
                    style={{
                      height: "38px",
                      width: "38px",
                      background: "white",
                      borderRadius: "8px",
                      position: "relative",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <Trash2
                      height={"18px"}
                      width={"18px"}
                      style={{
                        position: "absolute",
                        top: "25%",
                        left: "25%",
                        transform: "transform(-50%,-50%)",
                        color: "red",
                      }}
                    />
                    <UncontrolledTooltip placement="bottom" target="elememtDel">
                      {"Delete Element"}
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Create Form
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SurveyForm;
