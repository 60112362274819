import React, { useState, Fragment, useEffect } from 'react';
import logo from '../../../assets/images/Vedam/Vedam.png';
import { useLocation } from 'react-router-dom';
import { NewPassword, EnterOTP, Resend } from "../../../constant";
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { resetPassword } from '../../../api/index';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { genOtp } from '../../../api/index';
import SweetAlert from 'sweetalert2'
import TimerComponent from './TimerComponent';

const schema = yup.object().shape({
    username: yup.string().required('Username is required !'),
    password: yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$&])(?=.{8,})/,
            "Atleast 1 Uppercase, 1 Number, 1 Special character and must have 8 characters"
        ),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    otp1: yup.string().required('Required !'),
    otp2: yup.string().required('Required !'),
    otp3: yup.string().required('Required !'),
    otp4: yup.string().required('Required !'),
}).required();

const ResetPassword = () => {

    let navigate = useNavigate();
    let location = useLocation();
    const [isTimer, setIsTimer] = useState(false);
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showtimer, setShowtimer] = useState(false)
    const [resentOtpLimit, setResendOtpLimit] = useState({ value: parseInt(localStorage.getItem('otpCount')) });
    const [username, setUsername]=useState("")
    const otpLimit = 3;

    useEffect(() => {
        // localStorage.setItem("timer", "300");
        // timer();
        setShowtimer(true)
        console.log("location.state", location.state);
        setUsername(location.state)
    }, []);



    const { register, handleSubmit, formState, setValue } = useForm({
        resolver: yupResolver(schema), mode: "onChange", defaultValues: {
            username: location.state,
        }
    }); // initialise the hook

  

    const { errors } = formState;

    const resendOtp = () => {
        setShowtimer(true)
        resentOtpLimit.value = parseInt(localStorage.getItem('otpCount')) + 1;
        localStorage.setItem('otpCount', resentOtpLimit.value);
        setResendOtpLimit({ ...resentOtpLimit, value: resentOtpLimit.value });
        setValue('otp1', '');
        setValue('otp2', '');
        setValue('otp3', '');
        setValue('otp4', '');
        let email=location.state;
        if(location.state==undefined){
           email=username
        }

        genOtp({ email: email }).then((res) => {
            console.log("res", res);
            toast.success("Otp sent successfully !", { autoClose: 2000, theme: "light", });

        }, (err) => {
            console.log("err", err.response);
            toast.success("Otp sent successfully !", { autoClose: 2000, theme: "light", });
        })
    }

    function timer() {
        // timer value has been set in the sign in component
        var sec = parseInt(localStorage.getItem('timer'));
        var timer1 = sec, minutes, seconds;

        var timer = setInterval(function () {

            minutes = parseInt(timer1 / 60, 10)
            seconds = parseInt(timer1 % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            setIsTimer(true);
            document.getElementById('safeTimerDisplay').innerHTML = minutes + ":" + seconds;
            localStorage.setItem("timer", timer1);

            if (--timer1 < 0) {
                timer1 = 0;
                clearInterval(timer);
                navigate(`${process.env.PUBLIC_URL}/signin`);
            }
        }, 1000);

    }
    const handleTimerDone = () => {
        setShowtimer(false)
    };


    // handle method for input fields
    const handleChange = (element, index) => {

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    }
    const onSubmit = data => {

        if (data !== '') {
            console.log("data", data);

            const reqPayload = {
                username: location.state|| username,
                otp: data.otp1 + data.otp2 + data.otp3 + data.otp4,
                new_password: data.password
            }

            console.log("reqPayload", reqPayload);
            resetPassword(reqPayload).then((res) => {


                console.log('res', res);


            }, (err) => {

                console.log('err', err);
                if (err.response.status === 302) {

                    toast.success("Password reset successful !", { autoClose: 2000, theme: "light", });

                     setTimeout(() => {
                    navigate(`${process.env.PUBLIC_URL}/signin`);

                     }, 2200)
                } else if (err.response.status === 403) {
                    SweetAlert.fire({
                        title: "Something went wrong !",
                        text: "User activation request not approved.",
                        icon: "error",
                    });
                } else if (err.response.status === 500) {
                    SweetAlert.fire({
                        title: "Something went wrong !",
                        text: "Server Error",
                        icon: "error",
                    });
                }
            });

        } else {
            errors.showMessages();
        }
    };




    return (
        <Fragment>
            <ToastContainer />
            <div className="Reset_Password page-wrapper">
                <div className="container-fluid">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className="auth-innerright">
                                    <div className="reset-password-box">
                                        <div className="text-center"><img src={logo} alt="" /></div>
                                        <div className="card mt-4 mb-0 p-0">
                                            <div id='card_title' className='title bg-primary-blue'>
                                                <p className='f-24 mb-0'>{"Reset User Password"}</p>
                                            </div>


                                            <div className='card-body'>
                                                <form>
                                                    <div className="form-group rounded opt-box">
                                                        <label className="col-form-label pt-0 f-w-600">{"Username"}</label>
                                                        <div className="form-row">
                                                            <div className="col">
                                                                <input
                                                                    className={errors.username ? "form-control is-invalid" : "form-control"}
                                                                    type="text"
                                                                    {...register('username')}
                                                                    disabled={true} />

                                                                <span className='text-danger'>{errors.username?.message}</span>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <label className="col-form-label pt-0 f-w-600 mt-2">{EnterOTP}</label>
                                                    <div className="d-flex justify-content-center OTP">


                                                        <input
                                                            className={errors.otp1 ? "form-control is-invalid" : "form-control"}
                                                            id={"exampleFormControlInput1"}
                                                            type="text"
                                                            maxLength="1"
                                                            {...register('otp1')}
                                                            // onChange={e => handleChange(e.target)}
                                                            onKeyUp={e => handleChange(e.target)}
                                                        />
                                                        <input
                                                            className={errors.otp2 ? "form-control is-invalid" : "form-control"}
                                                            id={"exampleFormControlInput2"}
                                                            type="text"
                                                            maxLength="1"
                                                            {...register('otp2')}
                                                            // onChange={e => handleChange(e.target)}
                                                            onKeyUp={e => handleChange(e.target)}
                                                        />
                                                        <input
                                                            className={errors.otp3 ? "form-control is-invalid" : "form-control"}
                                                            id={"exampleFormControlInput3"}
                                                            type="text"
                                                            maxLength="1"
                                                            {...register('otp3')}
                                                            // onChange={e => handleChange(e.target)}
                                                            onKeyUp={e => handleChange(e.target)}
                                                        />
                                                        <input
                                                            className={errors.otp4 ? "form-control is-invalid" : "form-control"}
                                                            id={"exampleFormControlInput4"}
                                                            type="text"
                                                            maxLength="1"
                                                            {...register('otp4')}
                                                            // onChange={e => handleChange(e.target)}
                                                            onKeyUp={e => handleChange(e.target)}
                                                        />


                                                    </div>
                                                    {errors.otp1 || errors.otp2 || errors.otp3 || errors.otp4 ?
                                                        <p className='text-danger mb-0 text-center mt-1'>{"4 digit otp required !"}</p>
                                                        : null}

                                                    <div className="text-center mt-4">
                                                        <span className="reset-password-link">{"If didn't receive OTP? "}
                                                            <a className="btn-link text-danger d-inline"
                                                                href="#javascript"
                                                                onClick={resentOtpLimit.value < otpLimit ? resendOtp : null}>{Resend}</a>
                                                            <p className='d-inline'>{` (${resentOtpLimit.value} / ${otpLimit})`}</p>
                                                        </span>
                                                        {resentOtpLimit.value==otpLimit&&<p>OTP limit is exceeded, Please contact your amdin !</p>}

                                                        {showtimer === true ?
                                                           <TimerComponent onTimerDone={handleTimerDone} />
                                                            : null}
                                                    </div>

                                                    <h6 className="f-16 mt-4 mb-2">{"Create Your New Password"}</h6>


                                                    <div className="form-group">

                                                        <label className="col-form-label f-w-600">{NewPassword}</label>
                                                        <div className="input-group">
                                                            <input
                                                                className={errors.password ? "form-control is-invalid" : "form-control"}
                                                                type={showPassword ? "text" : "password"}
                                                                placeholder='Enter your password'
                                                                name='password'
                                                                {...register('password')}
                                                            />
                                                            {showPassword ?
                                                                <span className="input-group-text">
                                                                    <i className="fa cursor_pointer fa-eye-slash"
                                                                        onClick={() => setShowPassword(false)}></i>
                                                                </span>
                                                                :
                                                                <span className="input-group-text">
                                                                    <i className="fa cursor_pointer fa-eye"
                                                                        onClick={() => setShowPassword(true)}></i>
                                                                </span>
                                                            }
                                                        </div>

                                                        <span className='text-danger'>{errors.password?.message}</span>
                                                    </div>

                                                    <div className="form-group">

                                                        <label className="col-form-label f-w-600">{"Confirm New Password"}</label>
                                                        <div className="input-group">
                                                            <input
                                                                className={errors.confirmPassword ? "form-control is-invalid" : "form-control"}
                                                                type={showConfirmPassword ? "text" : "password"}
                                                                placeholder='Enter your password'
                                                                name='confirmPassword'
                                                                {...register('confirmPassword')}
                                                            />
                                                            {showConfirmPassword ?
                                                                <span className="input-group-text">
                                                                    <i className="fa cursor_pointer fa-eye-slash"
                                                                        onClick={() => setShowConfirmPassword(false)}></i>
                                                                </span>
                                                                :
                                                                <span className="input-group-text">
                                                                    <i className="fa cursor_pointer fa-eye"
                                                                        onClick={() => setShowConfirmPassword(true)}></i>
                                                                </span>
                                                            }
                                                        </div>
                                                        <span className='text-danger'>{errors.confirmPassword?.message}</span>

                                                        
                                                    </div>


                                                    {/* <div className="form-group">
                                                        <label className="col-form-label">{RetypePassword}</label>
                                                        <input className="form-control" type="password" />
                                                    </div> */}


                                                    <div className="form-group mt-4 mb-2">

                                                        <button
                                                            onClick={handleSubmit(onSubmit)}
                                                            className="btn btn-primary w-100"
                                                            type="submit">{"Submit"}</button>

                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};



export default ResetPassword