import React, { useState, useEffect, useReducer } from "react";
import CreateNewSurveyForm from "./CreateNewSurveyForm";
import { Pagination, PaginationItem, PaginationLink, DropdownMenu, DropdownItem, Button, CardBody } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import {
  Trash2,
  Edit,
  Info,
  Link,
  Copy,
  Video,
  FileText,
  File,
  Upload,
} from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import {
  getSurvey,
  deleteSurvey,
  getSurveyCounts,
  getSurveyRecordings,
  getSurveyReport,
  getSurveyAsPerFilter, get_soc, get_noc, fetchByTagFilter
} from "../../../../api/index";
import {
  convertDate,
  statusFilter,
} from "../../Common/Functions/CommonFunctions";
import EditSurveyForm from "./EditSurveyForm";
import { useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Buffer } from "buffer";
// import firebase from '../../../../data/config';
// import { auth, db } from '../../../../data/config';
// import { collection, query, where, onSnapshot, QuerySnapshot } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import GuestUserLinkGeneration from "./GuestUserLinkGeneration";
import RefModal from "./RefModal";
import HtmlToPdf from "../../../dashboard/defaultCompo/HtmlToPdf";
import { getAuth } from "firebase/auth";
import { Typeahead } from "react-bootstrap-typeahead";
import Dropdown from 'react-bootstrap/Dropdown';

function SurveyManagement() {
  let navigate = useNavigate();
  const [modal8, setModal8] = useState(false);
  const [modal9, setModal9] = useState();
  const [reportModal, setReportModal] = useState();
  const [socList, setSocList] = useState({ data: [], loading: false });
  const [nocList, setNocList] = useState({ data: [], loading: false });

  const [selectedSoc, setSelectedSoc] = useState([]);
  const [selectedNoc, setSelectedNoc] = useState([]);

  const [reportGenerationModal, setReportGenerationModal] = useState(false);
  const [recordingModal, setRecordingModal] = useState();
  const [loadingReport, setLoadingReport] = useState(false);
  const [linkModal, setLinkModal] = useState();
  const [refModal, setRefModal] = useState();
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [surveyRecordings, setSurveyRecordings] = useState({
    data: [],
    loading: false,
  });
  const [totalRecords, setTotalRecords] = useState("");
  const [surveyIdForVideoCall, setSurveyIdForVideoCall] = useState({
    data: "",
  });
  const [surveyNoOfLastRecord, setSurveyNoOfLastRecord] = useState("");
  const [srNo, setSrNo] = useState(0);
  const user = localStorage.getItem("user");
  const customer_id = localStorage.getItem("customer_id");
  const [surveyCounts, setSurveyCounts] = useState({}); // display counts of surveys pending, complete, Inprogress etc
  const [searchVal, setSearchVal] = useState({ value: "" });
  const [filter, setFilter] = useState({ value: [] });

  const [options, setOptions] = useState([
    { name: "Inactive" },
    { name: "Assigned" },
    { name: "Inprogress" },
    { name: "Completed" },
    { name: "Submitted" },
    { name: "Verified" },
    { name: "CorrectionRequired" },
  ]);

  const initialState = {
    loading: false,
    error: "",
    surveyList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          surveyList: action.surveyList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggle8 = () => {
    console.log(modal8, "modal")
    setModal8(!modal8);
  };

  const toggle9 = () => {
    setModal9(!modal9);
  };

  const toggleReportGenerationModal = () => {
    setReportGenerationModal(!reportGenerationModal);
  };




  const toggleReportModal = (id) => {
    setReportModal(!reportModal);
    setLoadingReport(true);
    if (reportModal !== true) {
      const payload = {
        customer_id: customer_id,
        survey: id,
      };
      getSurveyReport(payload).then(
        (res) => {
          console.log("res", res.data);

          var base64Str = Buffer.from(res.data).toString("base64");

          var binaryString = window.atob(base64Str);
          var binaryLen = binaryString.length;
          var bytes = new Uint8Array(binaryLen);
          for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          var arrBuffer = bytes;

          var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          res.data = window.URL.createObjectURL(newBlob);

          console.log("res.data", res.data);

          setLoadingReport(false);
          var link = document.createElement("a");
          var frame = document.getElementById("frame");
          frame.src = res.data;
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  const toggleRecordingModal = (id) => {
    setRecordingModal(!recordingModal);

    console.log("recordingModal", recordingModal);
    console.log("id", id);
    if (recordingModal !== true) {
      setSurveyRecordings({ ...surveyRecordings, data: [], loading: true });

      getSurveyRecordings({ survey: id }).then(
        (res) => {
          console.log("res", res);

          if (res.data.length !== 0) {
            const result = res.data.filter((val) => {
              return val.url !== null;
            });

            console.log("result", result);
            setSurveyRecordings({
              ...surveyRecordings,
              data: result,
              loading: false,
            });
          } else {
            setSurveyRecordings({
              ...surveyRecordings,
              data: [],
              loading: false,
            });
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  const toggleLinkModal = () => {
    setLinkModal(!linkModal);
  };

  const toggleRefModal = () => {
    setRefModal(!refModal);
  };

  useEffect(() => {
    console.log("state", state);

    localStorage.removeItem("selectedSec");
    localStorage.removeItem("selectedSecName");
    localStorage.removeItem("selectedSecId");

    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      surveyList: state.surveyList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchSurveys();
  }, [state.page, state.itemsPerPage]);

  useEffect(() => {
    fetch_soc();

  }, [])


  function fetchSurveys(para1, para2, para3) {

    // dispatch({
    //   type: HANDLE_ACTION,
    //   page: para1 === "refresh" ? 1 : state.page,
    //   loading: true,
    //   error: state.error,
    //   surveyList: state.surveyList,
    //   itemsPerPage: state.itemsPerPage,
    //   maxPage: state.maxPage,
    // });


    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer_id: customer_id,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
        customer_id: customer_id,
      };

      if (filter.value.length > 0) {
        reqPayload.statusList = filter.value;
      } else {
        delete reqPayload.statusList;
      }
    } else {
      if (searchVal.value.length > 0) {
        reqPayload.searchVal = searchVal.value;
      } else {
        delete reqPayload.searchVal;
      }
    }

    if (para1 === "filter") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,

        customer_id: customer_id,
        statusList: para2,
      };
      console.log("searchVal", searchVal.value);
      if (searchVal.value.length > 0) {
        reqPayload.searchVal = searchVal.value;
      } else {
        delete reqPayload.searchVal;
      }
    } else {
      if (filter.value.length > 0) {
        reqPayload.statusList = filter.value;
      } else {
        delete reqPayload.statusList;
      }
    }

    console.log("filter", filter.value);
    console.log("reqPayload", reqPayload);

    getSurveyAsPerFilter(reqPayload).then(
      (res) => {
        // console.log("res", res.data.res.checklist.name);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          surveyList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });

        if (res.data.res.length > 0) {
          console.log("res.data.res[0].surveyNo", res.data.res[0].surveyNo);
          setSurveyNoOfLastRecord(res.data.res[0].surveyNo);
        }
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  const delSurvey = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteSurvey({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Assigned survey deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            fetchSurveys();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  function fetch_soc() {
    setSocList({ ...socList, data: [], loading: true })

    get_soc({ customer: customer_id }).then((res) => {
      console.log("res", res);
      if (res.data.payload.length > 0) {
        setSocList({ ...socList, data: res.data.payload, loading: false })
      } else {
        setSocList({ ...socList, data: res.data.payload, loading: false })
      }
    }, (err) => {
      console.log("err", err);
      setSocList({ ...socList, data: [], loading: false })
    })
  }

  function fetch_noc(data) {
    get_noc({ nocCategoryId: data.nocCategoryId, customer: customer_id }).then((noc_res) => {
      console.log("noc_res", noc_res);

      if (noc_res.data.payload.length > 0) {
        setNocList({ ...nocList, data: noc_res.data.payload, loading: false });
      } else {
        setNocList({ ...nocList, data: [], loading: false });
      }
    }, (noc_err) => {
      console.log("noc_err", noc_err);
      setNocList({ ...nocList, data: [], loading: false });
    })
  }

  function handleSearch() {
    console.log("selectedSoc", selectedSoc);
    console.log("selectedNoc", selectedNoc);
    console.log("searchVal", searchVal);
    console.log("filter", filter);

    const status = filter.value.map(item => item.name);

    const params = {
      customer_id: customer_id,
      filterType: "survey",
      socIds: "[" + selectedSoc[0]?.Id + "]",
      nocIds: "[" + selectedNoc[0]?.Id + "]",
      statusList: JSON.stringify(status),
      searchVal: searchVal.value
    }

    if (selectedSoc.length == 0) {
      delete params.socIds
    }
    if (selectedNoc.length == 0) {
      delete params.nocIds
    }

    console.log("params", params);

    if (params.socIds && params.nocIds) {
      fetchByTagFilter(params).then((res) => {
        console.log("res", res);
        setTotalRecords(res.data.count);
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          surveyList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage: calMaxPage(res.data.count)
        });
      }, (err) => {
        console.log("err", err);

      })
    }
    else {
      params.nocIds = `[]`;
      params.socIds = `[]`;
      console.log("params", params)
      fetchByTagFilter(params).then((res) => {
        console.log("res", res);
        setTotalRecords(res.data.count);

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          surveyList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage: calMaxPage(res.data.count)
        });
      }, (err) => {
        console.log("err", err);

      })
    }
  }


  function fetchReset() {
    getSurveyAsPerFilter({
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer_id: customer_id,
    }).then(
      (res) => {
        // console.log("res", res.data.res.checklist.name);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          surveyList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage: calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });

        if (res.data.res.length > 0) {
          console.log("res.data.res[0].surveyNo", res.data.res[0].surveyNo);
          setSurveyNoOfLastRecord(res.data.res[0].surveyNo);
        }
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  return (
    <React.Fragment>
      <EditSurveyForm
        modal8={modal8}
        toggle8={toggle8}
        data={dataForUpdation}
        fetchSurveys={fetchSurveys}
      />

      <div className="vdi_survey py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  {/* <h4 className='mb-0'>Survey Management</h4> */}
                  <p className="mb-0 f-24">Survey Assignment</p>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    {/* <div className="col-12 col-md-3">
                      <input
                        style={{ maxWidth: "300px" }}
                        className="form-control"
                        type="text"
                        name="search"
                        placeholder="Search"
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            searchVal.value = e.target.value;
                            setSearchVal({
                              ...searchVal,
                              value: searchVal.value,
                            });
                            fetchSurveys("search", e.target.value);
                            setApiBySearch(true);
                          } else {
                            searchVal.value = e.target.value;
                            setSearchVal({
                              ...searchVal,
                              value: searchVal.value,
                            });
                            fetchSurveys();
                            setApiBySearch(false);
                          }
                        }}
                      />
                    </div> */}


                    <div className="col-12 col-md-3 filter">
                      <div className="filter-dropdown dropdown-basic">
                        {/* <Dropdown className="dropdown">
                          <button className="btn btn-primary-blue dropbtn" style={{ borderRadius: '20px !important' }}>
                            <span><i className="icofont icofont-filter me-1"></i></span>
                            {"Filter"}
                            <span><i className="icofont icofont-arrow-down ms-2"></i></span>
                          </button>

                          <DropdownMenu className="dropdown-content p-3">

                            <label className="f-14 f-w-600">Select SOC</label>
                            <Typeahead
                              size="sm"
                              style={{ maxWidth: "300px" }}
                              id="basic-typeahead-soc"
                              labelKey="soc"
                              multiple={false}
                              options={socList.data}
                              loading={socList.loading}
                              placeholder="Select SOC"
                              selected={selectedSoc}
                              onChange={(e) => {
                                console.log("e", e);
                                if (e.length > 0) {
                                  fetch_noc(e[0]);
                                }
                                setSelectedSoc(e.length > 0 ? [e[0]] : [])
                              }}
                            />

                            <label className="f-14 f-w-600">Select NOC</label>
                            <Typeahead
                              size="sm"
                              style={{ maxWidth: "300px" }}
                              id="basic-typeahead-noc"
                              labelKey="noc"
                              multiple={false}
                              selected={selectedNoc}
                              disabled={selectedSoc.length == 0}
                              options={nocList.data}
                              loading={nocList.loading}
                              placeholder="Select NOC"
                              onChange={(e) => {
                                console.log("e", e);
                                setSelectedNoc(e.length > 0 ? [e[0]] : [])
                              }}
                            />

                            <label className="f-14 f-w-600">Select Status</label>
                            <Typeahead
                              size="sm"
                              style={{ maxWidth: "300px" }}
                              id="basic-typeahead"
                              labelKey="name"
                              multiple={true}
                              options={options}
                              placeholder="Select Status"
                              selected={filter.value}
                              onChange={(e) => {
                                console.log("e", e);
                                filter.value = e;
                                setFilter({ ...filter, value: filter.value });
                              }}
                            />

                            <label className="f-w-600 f-14">Search</label>
                            <input
                              style={{ maxWidth: "300px" }}
                              className="form-control form-control-sm"
                              type="text"
                              name="search"
                              placeholder="Search"
                              value={searchVal.value}
                              onChange={(e) => {
                                if (e.target.value.length > 0) {
                                  setSearchVal({
                                    ...searchVal,
                                    value: e.target.value,
                                  });
                                }
                              }}
                            />

                            <div className="row">
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setSelectedSoc([]); setSelectedNoc([]);
                                    setFilter({ value: [] }); setSearchVal({ ...searchVal, value: "" });
                                    fetchSurveys();
                                    fetchReset();
                                  }}
                                  className="btn btn-sm btn-warning mt-3 w-100">Reset</button>
                              </div>

                              <div className="col-6">
                                <button
                                  onClick={() => handleSearch()}
                                  className="btn btn-sm btn-primary-blue mt-3 w-100">Submit</button>
                              </div>
                            </div>
                           
                          </DropdownMenu>
                        </Dropdown> */}
                        <Dropdown>
                          <Dropdown.Toggle className="btn-primary-blue " id="dropdown-basic">
                            Filter
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <label className="f-14 f-w-600">Select SOC</label>
                            <Typeahead
                              size="sm"
                              style={{ maxWidth: "300px" }}
                              id="basic-typeahead-soc"
                              labelKey="soc"
                              multiple={false}
                              options={socList.data}
                              loading={socList.loading}
                              placeholder="Select SOC"
                              selected={selectedSoc}
                              onChange={(e) => {
                                console.log("e", e);
                                if (e.length > 0) {
                                  fetch_noc(e[0]);
                                }
                                setSelectedSoc(e.length > 0 ? [e[0]] : [])
                              }}
                            />

                            <label className="f-14 f-w-600">Select NOC</label>
                            <Typeahead
                              size="sm"
                              style={{ maxWidth: "300px" }}
                              id="basic-typeahead-noc"
                              labelKey="noc"
                              multiple={false}
                              selected={selectedNoc}
                              disabled={selectedSoc.length == 0}
                              options={nocList.data}
                              loading={nocList.loading}
                              placeholder="Select NOC"
                              onChange={(e) => {
                                console.log("e", e);
                                setSelectedNoc(e.length > 0 ? [e[0]] : [])
                              }}
                            />

                            <label className="f-14 f-w-600">Select Status</label>
                            <Typeahead
                              size="sm"
                              style={{ maxWidth: "300px" }}
                              id="basic-typeahead"
                              labelKey="name"
                              multiple={true}
                              options={options}
                              placeholder="Select Status"
                              selected={filter.value}
                              onChange={(e) => {
                                console.log("e", e);
                                filter.value = e;
                                setFilter({ ...filter, value: filter.value });
                              }}
                            />

                            <label className="f-w-600 f-14">Search</label>
                            <input
                              style={{ maxWidth: "300px" }}
                              className="form-control form-control-sm"
                              type="text"
                              name="search"
                              placeholder="Search"
                              value={searchVal.value}
                              onChange={(e) => {
                                if (e.target.value.length > 0) {
                                  setSearchVal({
                                    ...searchVal,
                                    value: e.target.value,
                                  });
                                }
                              }}
                            />

                            <div className="row">
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setSelectedSoc([]); setSelectedNoc([]);
                                    setFilter({ value: [] }); setSearchVal({ ...searchVal, value: "" });
                                    fetchSurveys();
                                    fetchReset();
                                  }}
                                  className="btn btn-sm btn-warning mt-3 w-100">Reset</button>
                              </div>

                              <div className="col-6">
                                <button
                                  onClick={() => handleSearch()}
                                  className="btn btn-sm btn-primary-blue mt-3 w-100">Submit</button>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="col-12 col-md-9 d-flex justify-content-end">
                      <CreateNewSurveyForm
                        totalRecords={totalRecords}
                        fetchSurveys={fetchSurveys}
                        surveyNoOfLastRecord={surveyNoOfLastRecord}
                      />
                    </div>
                  </div>

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.surveyList.length > 0 ? (
                    <div
                      className="table-responsive "
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                          <tr
                            className="f-18"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            <th scope="col">{"Survey No"}</th>
                            <th scope="col">{"Defined Survey"}</th>
                            <th scope="col">{"Vessel"}</th>
                            {/* <th scope="col">{"Id"}</th> */}
                            <th scope="col">{"I&G Name"}</th>

                            {/* <th scope="col">{"Mode"}</th> */}
                            {/* <th scope="col">{"Vessel Id"}</th> */}

                            {/* <th scope="col">{"Surveyor Id"}</th> */}
                            <th scope="col">{"Surveyor"}</th>
                            <th scope="col">{"Verifier"}</th>
                            <th scope="col">{"Site Coordinator"}</th>
                            <th scope="col">{"Start Time"}</th>
                            <th scope="col">{"End Time"}</th>
                            <th scope="col">{"Logs"}</th>
                            <th scope="col">{"Status"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.surveyList.map((val, i) => (
                            <tr key={i} className="f-16">
                              <td>
                                {val.surveyNo !== null ? val.surveyNo : "NA"}
                              </td>
                              {/* <td>{val.Id}</td> */}
                              <td>
                                {val.checklist !== null
                                  ? val.checklist.name
                                  : "NA"}
                              </td>
                              <td>{val.vessel.name}</td>
                              <td>{val.SOP.name}</td>

                              {/* <td>{val.mode}</td> */}
                              {/* <td>{val.vessel.Id}</td> */}

                              {/* <td>{val.user.Id}</td> */}
                              <td>{val.user.name}</td>
                              <td>
                                {val.verifier === null
                                  ? "NA"
                                  : val.verifier.name}
                              </td>
                              <td>
                                {val.remoteSurveyor === null
                                  ? "NA"
                                  : val.remoteSurveyor.name}
                              </td>
                              <td>
                                {new Date(val.startDate).toLocaleString()}
                              </td>
                              <td>{new Date(val.endDate).toLocaleString()}</td>
                              {/* <td>{convertDate(val.startDate, "-")}</td> */}
                              {/* <td>{convertDate(val.endDate, "-")}</td> */}
                              <td>
                                <Video
                                  onClick={() => {
                                    toggleRecordingModal(val.Id);
                                  }}
                                  id={"recordings" + i}
                                  className="cursor_pointer "
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"recordings" + i}
                                >
                                  {"View Recordings"}
                                </UncontrolledTooltip>

                                {val.status !== "Active" ? (
                                  <>
                                    {" "}
                                    <FileText
                                      onClick={() => {
                                        navigate(
                                          `${process.env.PUBLIC_URL}/customeradmin/generatedPDF/${val.Id}/${val.checklist.Id}`,
                                          { state: val }
                                        );
                                      }}
                                      id={"reportGeneration" + i}
                                      className="cursor_pointer  ms-1"
                                      height={"18px"}
                                      width={"18px"}
                                    />
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={"reportGeneration" + i}
                                    >
                                      {"Show Generated Report"}
                                    </UncontrolledTooltip>
                                  </>
                                ) : null}
                              </td>
                              <td>
                                {val.isActive == false
                                  ? "Inactive"
                                  : val.status}
                              </td>

                              <td>
                                <Edit
                                  onClick={() => {
                                    toggle8();
                                    setDataForUpdation(val);
                                  }}
                                  id={"editToolTip" + i}
                                  className="cursor_pointer "
                                  height={"18px"}
                                  width={"18px"}
                                />

                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"editToolTip" + i}
                                >
                                  {"Edit Assigned Survey"}
                                </UncontrolledTooltip>

                                <Trash2
                                  onClick={() => delSurvey(val)}
                                  id={"deleteToolTip" + i}
                                  className="cursor_pointer ms-1"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"deleteToolTip" + i}
                                >
                                  {"Delete Assigned Survey"}
                                </UncontrolledTooltip>

                                <Info
                                  onClick={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/customeradmin/vdi_survey_details/${val.Id}`,
                                      { state: val }
                                    )
                                  }
                                  id={"info" + i}
                                  className="cursor_pointer"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"info" + i}
                                >
                                  {"Survey Details"}
                                </UncontrolledTooltip>

                                <Link
                                  id={"link" + i}
                                  onClick={() => {
                                    toggleLinkModal();
                                    surveyIdForVideoCall.data = val.Id;
                                    setSurveyIdForVideoCall({
                                      ...surveyIdForVideoCall,
                                      data: surveyIdForVideoCall.data,
                                    });
                                  }}
                                  className="cursor_pointer ms-1"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"link" + i}
                                >
                                  {"Generate Link"}
                                </UncontrolledTooltip>

                                <Upload
                                  id={"uploadRef" + i}
                                  onClick={() => {
                                    toggleRefModal();
                                    surveyIdForVideoCall.data = val.Id;
                                    setSurveyIdForVideoCall({
                                      ...surveyIdForVideoCall,
                                      data: surveyIdForVideoCall.data,
                                    });
                                  }}
                                  className="cursor_pointer"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"uploadRef" + i}
                                >
                                  {"Reference Image For Report"}
                                </UncontrolledTooltip>

                                {/* <button
                                                                        onClick={() => { navigate(`${process.env.PUBLIC_URL}/customeradmin/htmltemplate`, { state: val }) }}
                                                                        className='btn btn-primary btn-sm'>html template</button> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Survey Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Survey list is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                surveyList: state.surveyList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC - 10);
                            }}
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                surveyList: state.surveyList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC + 10);
                            }}
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                      {/* <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p> */}
                      <div>
                        <div className="d-flex justify-content-end">
                          <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GuestUserLinkGeneration
        linkModal={linkModal}
        toggleLinkModal={toggleLinkModal}
        data={surveyIdForVideoCall.data}
      />

      <RefModal
        refModal={refModal}
        toggleRefModal={toggleRefModal}
        data={surveyIdForVideoCall.data}
      />

      <Modal
        isOpen={recordingModal}
        toggle={toggleRecordingModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader className="bg-primary-blue" toggle={toggleRecordingModal}>
          {"Survey Recordings"}
        </ModalHeader>
        <ModalBody>
          <div>
            {surveyRecordings.loading ? (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            ) : (
              <div className="row">
                {surveyRecordings.data.length > 0 ? (
                  surveyRecordings.data.map((val, ind) => (
                    <div className="col-12 col-md-4 mb-2" key={ind}>
                      <div className="card shadow-sm p-0 light_grey_border videoRecordingCard">
                        <div className="card-body">
                          <video width={"100%"} height={"60%"} controls>
                            <source src={val.url + "#t=0.5"} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-danger">No Recordings Available !</p>
                )}
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={reportModal}
        toggle={toggleReportModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader className="bg-primary-blue" toggle={toggleReportModal}>
          {"Preview Report"}
        </ModalHeader>
        <ModalBody>
          {loadingReport === true ? (
            <div className="loader-box justify-content-center">
              <div className="loader">
                <div className="line bg-dark"></div>
                <div className="line bg-dark"></div>
                <div className="line bg-dark"></div>
                <div className="line bg-dark"></div>
              </div>
            </div>
          ) : (
            <div
              className="pdfBlock d-flex justify-content-center"
              id="pdfBlock"
            >
              <iframe
                id="frame"
                style={{ height: "80vh", width: "100%" }}
              ></iframe>
            </div>
          )}
        </ModalBody>
      </Modal>
      {reportGenerationModal ? (
        <HtmlToPdf
          toggleReportGenerationModal={toggleReportGenerationModal}
          reportGenerationModal={reportGenerationModal}
        />
      ) : null}
    </React.Fragment>
  );
}

export default SurveyManagement;
