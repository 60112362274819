import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trash2, Edit } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { getMethodology, createSOP } from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { updateSOP, getSOPById, deleteSopItem } from "../../../../api/index";
import SweetAlert from "sweetalert2";
import { Link } from "react-router-dom";
import AddSOPItems from "./AddSOPItems";
import EditSOPItems from "./EditSOPItems";

import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("I&G name is required !"),
    description: yup.string().required("I&G description is required !"),
    selfie: yup
      .string()
      .nullable()
      .required("Selfie is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    opening_Meeting: yup
      .string()
      .nullable()
      .required("Opening meeting required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    // vesselInfo: yup.string().notRequired(),
    methodology_id: yup
      .string()
      .nullable()
      .required("Please select methodology !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    photos: yup
      .string()
      .nullable()
      .required("Photo is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    videos: yup
      .string()
      .nullable()
      .required("Video is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    OG: yup
      .string()
      .nullable()
      .required("OG is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    // items: yup.array().of(
    //     yup.object().shape({
    //         type: yup.string()
    //             .required('Required !'),
    //     })
    // )
  })
  .required();

function EditSOPForm() {
  let location = useLocation();
  const navigate = useNavigate();

  const customer_id = localStorage.getItem("customer_id");
  const oldSopData = location.state.data;

  const [sopData, setSopData] = useState({
    data: { SOP: { name: "", description: "", selfie: "" } },
    loading: false,
  });
  const [delItemApiFire, setDelItemApiFire] = useState(false);
  const [AddItemApiFire, setAddItemApiFire] = useState(false);
  const [EditItemApiFire, setEditItemApiFire] = useState(false);
  const [itemUpdateData, setItemUpdateData] = useState({ data: {} });

  const [modal8, setModal8] = useState();
  const toggle8 = () => {
    setModal8(!modal8);
  };

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {},
  }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    console.log("location", location);
    fetchSOPById();
  }, []);

  const updateItemApiIsCalled = () => {
    setEditItemApiFire(true);
  };
  const addItemApiIsCalled = () => {
    setAddItemApiFire(true);
  };

  const delSOPItem = (val) => {
    console.log("val", val);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteSopItem({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("I&G Item Deleted Successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            fetchSOPById();
            setDelItemApiFire(true);
          },
          (err) => {
            toast.error("Something went wrong, please try again !", {
              autoClose: 3000,
            });
            console.log("err", err);
          }
        );
      }
    });
  };

  function fetchSOPById() {
    setSopData({ ...sopData, data: {}, loading: true });

    getSOPById({ Id: location.state.data.SOP.Id }).then(
      (res) => {
        console.log("res", res);
        sopData.data = res.data.res;
        setSopData({ ...sopData, data: sopData.data, loading: false });

        reset({
          items: res.data.res.SOPLineItems,
          name: res.data.res.SOP.name,
          description: res.data.res.SOP.description,
        });
        setValue("selfie", res.data.res.SOP.selfie);
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const onSubmit = (data) => {
    console.log("data", data);

    if (data !== "") {
      // const tempItems = data.items.map((val, i) => {
      //     return { instruction: val.type, sequence: (i + 1), Id: val.Id }
      // })

      const tempMethodology = location.state.methodology.data.filter(
        (val, i) => {
          if (val.type === data.methodology_id) {
            return val.Id;
          }
        }
      );

      const reqPayload = {
        Id: sopData.data.SOP.Id,
        SOP: {
          customer_id: customer_id,
          name: data.name,
          description: data.description,
          selfie: data.selfie,
          opening_Meeting: data.opening_Meeting,
          methodology_id: tempMethodology[0].Id,
          photos: data.photos,
          videos: data.videos,
          OG: data.OG,
        },
        SOPLineItems: data.items,
      };

      reqPayload.SOPLineItems.map((val, i) => {
        if (val.Id?.length === 0) {
          delete val.Id;
        }
        delete val.id;
        delete val.isActive;
        delete val.sop;
        val.instruction = val.type;
        val.sequence = i + 1;
        delete val.type;
      });
      console.log("reqPayload", reqPayload);

      updateSOP(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("I&G updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });

          setTimeout(() => {
            navigate(`${process.env.PUBLIC_URL}/customeradmin/vdi_sop`);
          }, []);
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <EditSOPItems
        updateItemApiIsCalled={updateItemApiIsCalled}
        itemUpdateData={itemUpdateData}
        modal8={modal8}
        toggle8={toggle8}
        getValues={getValues}
        SOPData={sopData.data}
        fetchSOPById={fetchSOPById}
        methodologyList={location.state.methodology}
      />

      <div className="vdi_sop py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  <p className="mb-0 f-24">
                    Update Instructions & Guidelines 
                  </p>
                </div>

                {sopData.loading === true ? (
                  <div className="loader-box justify-content-center">
                    <div className="loader">
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    {/* <p className='f-18 f-w-600 mb-0'>Update SOP</p>
                                            <hr className='mb-3 mt-0' /> */}
                    <form className="">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group mb-2">
                            <label className="f-w-600">{"Name"}</label>
                            <input
                              className={
                                errors.name
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              type="text"
                              placeholder="Enter Name*"
                              name="name"
                              {...register("name")}
                            />

                            <span className="text-danger">
                              {errors.name?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group mb-2">
                            <label className="f-w-600">{"Description"}</label>
                            <textarea
                              className={
                                errors.description
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              type="text"
                              placeholder="Enter Description*"
                              name="description"
                              {...register("description")}
                              rows="1"
                            ></textarea>

                            <span className="text-danger">
                              {errors.description?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group mb-2">
                            <label className="f-w-600">{"Selfie"}</label>

                            <Controller
                              name="selfie"
                              control={control}
                              defaultValue={sopData.data.SOP?.selfie}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  defaultValue={sopData.data.SOP?.selfie}
                                  className={
                                    errors.selfie
                                      ? " form-control is-invalid"
                                      : " form-control"
                                  }
                                >
                                  y<option select="">{"--Select*--"}</option>
                                  <option value="Yes">{"Compulsory"}</option>
                                  <option value="No">{"Optional"}</option>
                                </select>
                              )}
                            />

                            <span className="text-danger">
                              {errors.selfie?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group mb-2">
                            <label className="f-w-600">
                              {"Opening Meeting"}
                            </label>

                            <Controller
                              name="opening_Meeting"
                              defaultValue={sopData.data.SOP?.opening_Meeting}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  defaultValue={
                                    sopData.data.SOP?.opening_Meeting
                                  }
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  className={
                                    errors.opening_Meeting
                                      ? " form-control is-invalid"
                                      : " form-control"
                                  }
                                >
                                  <option select="">{"--Select*--"}</option>
                                  <option value="Yes">{"Compulsory"}</option>
                                  <option value="No">{"Optional"}</option>
                                </select>
                              )}
                            />

                            <span className="text-danger">
                              {errors.opening_Meeting?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group mb-2">
                            <label className="f-w-600">{"Methodology"}</label>

                            <Controller
                              name="methodology_id"
                              control={control}
                              defaultValue={sopData.data.SOP?.methodology?.type}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  defaultValue={
                                    sopData.data.SOP?.methodology?.type
                                  }
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  className={
                                    errors.methodology_id
                                      ? " form-control is-invalid"
                                      : " form-control"
                                  }
                                >
                                  <option select="">{"Select Methodology"}</option>
                                  {location.state.methodology.data.map(
                                    (val, i) => (
                                      <option key={i} value={val.type}>
                                        {val.type}
                                      </option>
                                    )
                                  )}
                                </select>
                              )}
                            />

                            <span className="text-danger">
                              {errors.methodology_id?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group mb-2">
                            <label className="f-w-600">{"Photo"}</label>

                            <Controller
                              name="photos"
                              defaultValue={sopData.data.SOP?.photos}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  defaultValue={sopData.data.SOP?.photos}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  className={
                                    errors.photos
                                      ? " form-control is-invalid"
                                      : " form-control"
                                  }
                                >
                                  <option select="">{"--Select*--"}</option>
                                  <option value="Required">
                                    {"Compulsory"}
                                  </option>
                                  <option value="Not Required">
                                    {"Optional"}
                                  </option>
                                </select>
                              )}
                            />

                            <span className="text-danger">
                              {errors.photos?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group mb-2">
                            <label className="f-w-600">{"Video"}</label>

                            <Controller
                              name="videos"
                              control={control}
                              defaultValue={sopData.data.SOP?.videos}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  defaultValue={sopData.data.SOP?.videos}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  className={
                                    errors.videos
                                      ? " form-control is-invalid"
                                      : " form-control"
                                  }
                                >
                                  <option select="">{"--Select*--"}</option>
                                  <option value="Required">
                                    {"Compulsory"}
                                  </option>
                                  <option value="Not Required">
                                    {"Optional"}
                                  </option>
                                </select>
                              )}
                            />

                            <span className="text-danger">
                              {errors.videos?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group mb-2">
                            <label className="f-w-600">{"OG"}</label>

                            <Controller
                              name="OG"
                              control={control}
                              defaultValue={sopData.data.SOP?.OG}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  defaultValue={sopData.data.SOP?.OG}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  className={
                                    errors.OG
                                      ? " form-control is-invalid"
                                      : " form-control"
                                  }
                                >
                                  <option select="">{"--Select*--"}</option>
                                  <option value="Required">
                                    {"Compulsory"}
                                  </option>
                                  <option value="Not Required">
                                    {"Optional"}
                                  </option>
                                </select>
                              )}
                            />

                            <span className="text-danger">
                              {errors.OG?.message}
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="row mt-2">
                      {/* <AddSOPItems /> */}
                      <div className="col-12">
                        <hr />
                        <div className="d-flex justify-content-between">
                          <p className="f-18 f-w-600 mb-0">
                            Add Instructions & Guidelines Items
                          </p>

                          <AddSOPItems
                            addItemApiIsCalled={addItemApiIsCalled}
                            getValues={getValues}
                            SOPData={sopData.data}
                            fetchSOPById={fetchSOPById}
                            methodologyList={location.state.methodology}
                          />
                          {/* <button
                                                            style={{ fontSize: "16px" }}
                                                            className='btn btn-sm btn-primary-blue'
                                                            onClick={() => append({})}
                                                        >Add</button> */}
                        </div>
                        <hr />

                        <div className="table-responsive ">
                          <table className="table table-bordered">
                            <thead className="thead-light">
                              <tr className="f-16">
                                <th scope="col">{"Sr.No"}</th>
                                <th scope="col">{"Instructions & Guidelines Item"}</th>
                                <th scope="col">{"Action"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map((field, index) => (
                                <tr key={field.id} className="f-18">
                                  <td style={{ width: "100px" }}>
                                    <input
                                      // className={errors.items?.[index]?.sequence ? 'form-control is-invalid' : 'form-control'}
                                      className="form-control"
                                      type="text"
                                      {...register(`items[${index}].sequence`)}
                                      placeholder="Enter Item"
                                      name={`items[${index}].sequence`}
                                      defaultValue={field.sequence}
                                      disabled
                                    />
                                    {/* <span className='text-danger f-14'>{errors.items?.[index]?.sequence.message}</span> */}
                                  </td>

                                  <td className="d-none">
                                    <input
                                      type="text"
                                      disabled
                                      name={`items[${index}].Id`}
                                      {...register(`items[${index}].Id`)}
                                      defaultValue={field.Id}
                                    />
                                  </td>

                                  <td>
                                    <textarea
                                      // className={errors.items?.[index]?.type ? 'form-control is-invalid' : 'form-control'}
                                      className="form-control"
                                      type="text"
                                      {...register(`items[${index}].type`)}
                                      placeholder="Enter Item"
                                      name={`items[${index}].type`}
                                      defaultValue={field.instruction}
                                      rows="2"
                                      disabled
                                    ></textarea>
                                    {/* <span className='text-danger f-14'>{errors.items?.[index]?.type.message}</span> */}
                                  </td>

                                  <td>
                                    <Edit
                                      style={{ color: "#313131" }}
                                      id={"editToolTip" + index}
                                      className="cursor_pointer"
                                      height={"18px"}
                                      width={"18px"}
                                      onClick={() => {
                                        toggle8();
                                        itemUpdateData.data = field;
                                        setItemUpdateData({
                                          ...itemUpdateData,
                                          data: itemUpdateData.data,
                                        });
                                      }}
                                    />
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={"editToolTip" + index}
                                    >
                                      {"Edit Item"}
                                    </UncontrolledTooltip>

                                    <Trash2
                                      id={"sopdeleteToolTip" + index}
                                      className={
                                        index > 0
                                          ? "cursor_pointer ms-1"
                                          : "cursor_banned ms-1"
                                      }
                                      onClick={
                                        index > 0
                                          ? index + 1 >
                                            sopData.data.SOPLineItems?.length
                                            ? () => remove(index)
                                            : () => delSOPItem(field)
                                          : null
                                      }
                                      height={"18px"}
                                      width={"18px"}
                                    />
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={"sopdeleteToolTip" + index}
                                    >
                                      {"Delete Item"}
                                    </UncontrolledTooltip>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-end">
                      <button
                        onClick={handleSubmit(onSubmit)}
                        style={{ fontSize: "16px " }}
                        className="btn btn-primary-blue"
                      >
                        {"Save Changes"}
                      </button>

                      {delItemApiFire || EditItemApiFire || AddItemApiFire ? (
                        <Link
                          to={`${process.env.PUBLIC_URL}/customeradmin/vdi_sop`}
                        >
                          <button
                            style={{ fontSize: "16px " }}
                            className="btn btn-dark ms-2"
                          >
                            {"Done"}
                          </button>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditSOPForm;
