import React, { useState, useEffect } from "react";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import { convertDateYYMMDD } from "../../Common/Functions/CommonFunctions";
import {
  getVessels,
  getSOP,
  getCustomersUsers,
  getCustomersUsersByRole,
  updateSurvey,
  getChecklistSurveyApi,
} from "../../../../api/index";
import { Search } from "react-feather";

const schema = yup
  .object()
  .shape({
    sop: yup
      .array()
      .min(1, "SOP is required !")
      .nullable()
      .required("SOP is required !"),
    surveyNo: yup.string().required("Survey number is required !"),
    mode: yup
      .string()
      .nullable()
      .required("Mode is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    checkList: yup
      .array()
      .min(1, "Checklist is required !")
      .nullable()
      .required("Checklist is required !"),
    vessel: yup
      .array()
      .min(1, "Vessel is required !")
      .required("Vessel is required !"),
    user: yup
      .array()
      .min(1, "Surveyor is required !")
      .required("Surveyor is required !"),
    // verifier: yup.array().min(1, 'Verifier is required !').required('Verifier is required !'),
    // remoteSurveyor: yup.array().min(1, 'Remote Surveyor is required !').required('Remote Surveyor is required !'),
    startDate: yup.string().required("Start date is required !"),
    endDate: yup.string().required("End date is required !"),
    status: yup
      .string()
      .nullable()
      .required("Status is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
  })
  .required();

function EditSurveyForm(props) {
  const [startDate, setstartDate] = useState(new Date());
  const [vesselList, setVesselList] = useState({ data: [], loading: false });
  const [sopList, setSOPList] = useState({ data: [], loading: false });
  const [userList, setUserList] = useState({ data: [], loading: false });
  const [surveyorList, setSurveyorList] = useState({
    data: [],
    loading: false,
  });
  const [verifierList, setVerifierList] = useState({
    data: [],
    loading: false,
  });
  const [minEndDate, setMinEndDate] = useState({ date: "" });
  const [checkList, setCheckList] = useState({ data: [], loading: false });
  const [siteSurveyorList, setSiteSurveyorList] = useState({
    data: [],
    loading: false,
  });
  const [csearch, setCsearch] = useState("");

  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, watch, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const sd = watch("startDate");
  useEffect(() => {
    console.log("sd", sd);
    minEndDate.date = sd;
    setMinEndDate({ ...minEndDate, date: minEndDate.date });
  }, [sd]);

  // useEffect(() => {
  //     console.log("props", props);
  //     if (props.modal8 === true) {

  //     }
  // }, [props.data])

  useEffect(() => {
    console.log("props.data?.modal8", props.data);
    if(props.data?.surveyNo !== undefined){
      setValue("surveyNo", props.data.surveyNo)
    }
    if(props.data?.startDate!==undefined){
      setValue("startDate",new Date(props.data.startDate))
    }
    if(props.data?.endDate!==undefined){
      setValue("endDate",new Date(props.data.endDate));
    }
    if (props.data?.modal8 !== true) {
     
      fetchVessels();
      fetchSOP();
      fetchUser({ customer_id: customer_id });
      fetchSurveyors({ customer_id: customer_id, searchVal: "Surveyor" });
      fetchVerifiers({ customer_id: customer_id, searchVal: "Verifier" });
      fetchSiteSurveyors({
        customer_id: customer_id,
        searchVal: "SiteSurveyor",
      });
      //   fetchChecklist();
    }
  }, [props.data]);

  function fetchVessels() {
    setVesselList({ ...vesselList, data: [], loading: true });

    getVessels({
      customer_id: customer_id,
      page: 1,
      entriesPerPag: 10000,
    }).then(
      (res) => {
        console.log("res", res);
        const tempList = res.data.res.filter((val) => {
          return (val.status === true);
        });
        setVesselList({ ...vesselList, data: tempList, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function searchChecklist() {
    fetchChecklist();
  }

  function fetchChecklist() {
    setCheckList({ ...checkList, data: [], loading: true });

    getChecklistSurveyApi({
      customer_id: customer_id,
      page: 1,
      entriesPerPage: 10000,
    }).then(
      (res) => {
        console.log("res", res);

        var result = [];
        for (let i = 0; i < res.data.res.length; i++) {
          result.push(res.data.res[i].checklist);
        }

        console.log("result", result);

        setCheckList({ ...checkList, data: result, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchSOP() {
    setSOPList({ ...sopList, data: [], loading: true });

    getSOP({ customer_id: customer_id }).then(
      (res) => {
        console.log("res", res);

        var result = [];
        for (let i = 0; i < res.data.res.length; i++) {
          result.push(res.data.res[i].SOP);
        }

        console.log("result", result);

        setSOPList({ ...sopList, data: result, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchUser(params) {
    setUserList({ ...userList, data: [], loading: true });

    getCustomersUsers(params).then(
      (res) => {
        console.log("res", res);

        setUserList({ ...userList, data: res.data.res, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchVerifiers(params) {
    setVerifierList({ ...verifierList, data: [], loading: true });
    getCustomersUsersByRole(params).then(
      (res) => {
        console.log("res", res);

        setVerifierList({
          ...verifierList,
          data: res.data.res,
          loading: false,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchSurveyors(params) {
    setSurveyorList({ ...surveyorList, data: [], loading: true });
    getCustomersUsersByRole(params).then(
      (res) => {
        console.log("res", res);

        setSurveyorList({
          ...surveyorList,
          data: res.data.res,
          loading: false,
        });
        if (res.data.res.length > 0) {
          const tempList = res.data.res.filter((val) => {
            return (val.role.name === "Surveyor" && val.status === "Active");
          });
          setSurveyorList({ ...surveyorList, data: tempList, loading: false });
        } else {
          setSurveyorList({ ...surveyorList, data: [], loading: false });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchSiteSurveyors(params) {
    setSiteSurveyorList({ ...siteSurveyorList, data: [], loading: true });
    getCustomersUsersByRole(params).then(
      (res) => {
        console.log("res", res);

        setSiteSurveyorList({
          ...siteSurveyorList,
          data: res.data.res,
          loading: false,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const reqPayload = {
        surveyNo: data.surveyNo,
        Id: props.data.Id,
        SOP_id: data.sop[0].Id,
        checklist_id: data.checkList[0].Id,
        mode: data.mode,
        vessel_id: data.vessel[0].Id,
        user_id: data.user[0].Id,
        // verifier_id: data.verifier[0].Id,
        // remoteSurveyor_id: data.remoteSurveyor[0].Id,
        startDate:
          convertDateYYMMDD(data.startDate, "-") +
          " " +
          new Date(new Date()).toLocaleTimeString("it-IT"),
        endDate:
          convertDateYYMMDD(data.endDate, "-") +
          " " +
          new Date(new Date()).toLocaleTimeString("it-IT"),
        status: data.status,
        customer_id: customer_id,
      };
      console.log("reqPayload", reqPayload);

      if (data.verifier.length > 0) {
        if (data.verifier[0].name.length > 0) {
          reqPayload.verifier_id = data.verifier[0]?.Id;
        }
      }
      if (data.remoteSurveyor.length > 0) {
        if (data.remoteSurveyor[0].name.length > 0) {
          reqPayload.remoteSurveyor_id = data.remoteSurveyor[0]?.Id;
        }
      }

      // if (data.remoteSurveyor.length > 0) {
      //     if (data.remoteSurveyor[0].name.length > 0) {

      //         reqPayload.remoteSurveyor_id = data.remoteSurveyor[0]?.Id;
      //     }
      // }

      console.log("reqPayload", reqPayload);
      if (data.status == "Inactive") {
        reqPayload.isActive = "False";
        delete reqPayload.status;
      } else if (data.status == "Active") {
        reqPayload.isActive = "True";
        delete reqPayload.status;
      }

      updateSurvey(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("Assigned survey updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          props.toggle8();
          props.fetchSurveys();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.modal8}
        onHide={() => props.toggle8()}
        size="lg"
        backdrop="static"
      >
        <Modal.Header className="bg-primary-blue" closeButton>

          <Modal.Title >   <p className="f-22 f-w-400 mb-0">{"Update Survey"}</p></Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Survey Number"}</label>

                  <input
                    className={
                      errors.surveyNo
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type={"text"}
                    name="surveyNo"
                    placeholder="Survey Number"
                    {...register("surveyNo")}
                  // defaultValue={
                  //   props.data?.surveyNo 
                  // }
                  />
                  <span className="text-danger">
                    {errors.surveyNo?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">
                    {"Instructions & Guidelines"}
                  </label>

                  <Controller
                    name="sop"
                    control={control}
                    defaultValue={[
                      { name: props.data?.SOP.name, Id: props.data?.SOP.Id },
                    ]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        defaultSelected={[
                          {
                            name: props.data?.SOP.name,
                            Id: props.data?.SOP.Id,
                          },
                        ]}
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={sopList.data}
                        loading={sopList.loading}
                        placeholder="--Select SOP*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.sop ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.sop?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Defined Survey"}</label>

                  <div className="d-flex">
                    <Controller
                      name="checkList"
                      control={control}
                      defaultValue={[
                        {
                          name: props.data?.checklist.name,
                          Id: props.data?.checklist.Id,
                        },
                      ]}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="w-100"
                          id="basic-typeahead"
                          labelKey="name"
                          multiple={false}
                          defaultSelected={[
                            {
                              name: props.data?.checklist.name,
                              Id: props.data?.checklist.Id,
                            },
                          ]}
                          options={checkList.data}
                          loading={checkList.loading}
                          emptyLabel={
                            checkList.data.length > 0
                              ? "No matches found."
                              : "No checklist to select"
                          }
                          placeholder="--Select checklist*--"
                          onChange={onChange}
                          onInputChange={(input) => {
                            // console.log("Typed input:", input);
                            setCsearch(input);
                          }}
                          onBlur={onBlur}
                          // selected={value}
                          isInvalid={errors.checkList ? true : false}
                        />
                      )}
                    />
                    <span
                      className="shadow-sm"
                      onClick={() => searchChecklist()}
                      style={{
                        background: "white",
                        border: "1px solid #c6c6c6",
                        position: "relative",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Search
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                        }}
                        id={"search"}
                        className="cursor_pointer"
                        height={"18px"}
                        width={"18px"}
                      />
                    </span>
                  </div>

                  <span className="text-danger">
                    {errors.checkList?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Mode"}</label>

                  <Controller
                    name="mode"
                    control={control}
                    defaultValue={props.data?.mode}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={props.data?.mode}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.mode
                            ? "custom-select form-control is-invalid f-16"
                            : " f-16 custom-select form-control"
                        }
                      >
                        <option select="">{"Select*"}</option>
                        <option value="Remote">{"Remote"}</option>
                        <option value="Online">{"Onsite"}</option>
                        <option value="Both">{"Both"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.mode?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Vessel"}</label>

                  <Controller
                    name="vessel"
                    control={control}
                    defaultValue={[
                      {
                        name: props.data?.vessel.name,
                        Id: props.data?.vessel.Id,
                      },
                    ]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        defaultSelected={[
                          {
                            name: props.data?.vessel.name,
                            Id: props.data?.vessel.Id,
                          },
                        ]}
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={vesselList.data}
                        isLoading={vesselList.loading}
                        placeholder="--Select vessel*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.vessel ? true : false}
                      />
                    )}
                  />

                  {/* <Controller
                                        control={control}
                                        name="vessel"
                                        defaultValue={[{ name: props.data?.vessel.name, Id: props.data?.vessel.Id }]}
                                        render={({ onChange, value }) => (
                                            <Typeahead
                                                id="basic-typeahead"
                                                labelKey="name"
                                                multiple={false}
                                                options={vesselList.data}
                                                isLoading={vesselList.loading}
                                                placeholder="--Select*--"
                                                onChange={onChange}
                                                selected={value}
                                                isInvalid={errors.vessel ? true : false}

                                            />
                                        )}
                                    /> */}

                  <span className="text-danger">{errors.vessel?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  {/* <label className='f-w-600 f-14'>{"User"}</label> */}
                  <label className="f-w-600 f-14">{"Surveyor"}</label>

                  <Controller
                    name="user"
                    control={control}
                    defaultValue={[
                      { name: props.data?.user.name, Id: props.data?.user.Id },
                    ]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        defaultSelected={[
                          {
                            name: props.data?.user.name,
                            Id: props.data?.user.Id,
                          },
                        ]}
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        // options={userList.data}
                        // isLoading={userList.loading}
                        options={surveyorList.data}
                        isLoading={surveyorList.loading}
                        placeholder="--Select surveyor*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.user ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.user?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Verifier"}</label>

                  <Controller
                    name="verifier"
                    control={control}
                    defaultValue={
                      props.data?.verifier !== null
                        ? [
                          {
                            name: props.data?.verifier.name,
                            Id: props.data?.verifier.Id,
                          },
                        ]
                        : [{ name: "" }]
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        defaultSelected={
                          props.data?.verifier !== null
                            ? [
                              {
                                name: props.data?.verifier.name,
                                Id: props.data?.verifier.Id,
                              },
                            ]
                            : [{ name: "" }]
                        }
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={verifierList.data}
                        isLoading={verifierList.loading}
                        placeholder="--Select verifier--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        disabled={props.data?.status === "Verified"}
                        isInvalid={errors.verifier ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.verifier?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Site Co-ordinator"}</label>

                  <Controller
                    name="remoteSurveyor"
                    control={control}
                    defaultValue={
                      props.data?.remoteSurveyor !== null
                        ? [
                          {
                            name: props.data?.remoteSurveyor.name,
                            Id: props.data?.remoteSurveyor.Id,
                          },
                        ]
                        : [{ name: "" }]
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        defaultSelected={
                          props.data?.remoteSurveyor !== null
                            ? [
                              {
                                name: props.data?.remoteSurveyor.name,
                                Id: props.data?.remoteSurveyor.Id,
                              },
                            ]
                            : [{ name: "" }]
                        }
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={siteSurveyorList.data}
                        isLoading={siteSurveyorList.loading}
                        emptyLabel={
                          siteSurveyorList.data.length > 0
                            ? "No matches found."
                            : "No site surveyor to select"
                        }
                        placeholder="--Select site coordinator--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.remoteSurveyor ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.remoteSurveyor?.message}
                  </span>
                </div>
              </div>

              {/* <div className='col-12 col-md-6'>
                                <div className="form-group mb-2">
                                    <label className='f-w-600 f-14'>{"Remote Surveyor"}</label>


                                    <Controller
                                        name="remoteSurveyor"
                                        control={control}
                                        defaultValue={props.data?.remoteSurveyor !== null ? [{ name: props.data?.remoteSurveyor.name, Id: props.data?.remoteSurveyor.Id }] : [{ name: '' }]}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (

                                            <Typeahead
                                                defaultSelected={props.data?.remoteSurveyor !== null ? [{ name: props.data?.remoteSurveyor.name, Id: props.data?.remoteSurveyor.Id }] : [{ name: '' }]}

                                                id="basic-typeahead"
                                                labelKey="name"
                                                multiple={false}
                                                options={surveyorList.data}
                                                isLoading={surveyorList.loading}
                                                placeholder="--Select--"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                // selected={value}                                                
                                                isInvalid={errors.remoteSurveyor ? true : false}
                                            />

                                        )}
                                    />



                                    <span className='text-danger'>{errors.remoteSurveyor?.message}</span>
                                </div>
                            </div> */}

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">Start Date</label>
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={
                      props.data !== undefined
                        ? new Date(props.data.startDate)
                        : ""
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        defaultValue={
                          props.data !== undefined
                            ? new Date(props.data.startDate)
                            : ""
                        }
                        className={
                          errors.startDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                          )
                        }
                        // maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.startDate?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">End Date</label>
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={
                      props.data !== undefined
                        ? new Date(props.data.endDate)
                        : ""
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        defaultValue={
                          props.data !== undefined
                            ? new Date(props.data.endDate)
                            : ""
                        }
                        className={
                          errors.endDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={new Date(minEndDate.date)}
                        // maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                  {/* <Controller
                                        control={control}
                                        name="endDate"
                                        defaultValue={props.data !== undefined ? new Date(props.data.endDate) : ''}

                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                defaultValue={props.data !== undefined ? new Date(props.data.endDate) : ''}
                                                className={errors.endDate ? "form-control is-invalid" : "form-control"}
                                                placeholderText="Select date"
                                                onChange={onChange}
                                                selected={value}
                                                minDate={new Date(minEndDate.date)}
                                                // maxDate={new Date()}
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        )}
                                    /> */}

                  <span className="text-danger">{errors.endDate?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Status"}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue={props.data?.status}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={props.data?.status}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.status
                            ? "custom-select form-control is-invalid f-16"
                            : " f-16 custom-select form-control"
                        }
                      >
                        <option select="">{"Select*"}</option>
                        <option value="Active">{"Active"}</option>
                        <option value="Inactive">{"Inactive"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            style={{ fontSize: "16px " }}
            className="btn btn-primary-blue float-right"
          >
            {"Update Survey"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditSurveyForm;
