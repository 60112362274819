import React, { useState, useEffect } from 'react';
import Draggable from "react-draggable"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { getChecklistItemAnswer, postChecklistItemAnswer, uploadChecklistItemAttachment, getChecklistItemAttachment } from '../../../../api/index';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { Film, FileText, } from 'react-feather';

const schema = yup.object().shape({
    answer: yup.string().nullable().required('Answer is required !'),
    remarks: yup.string().nullable().notRequired(),

}).required();

function AnswerScreen(props) {

    const [isOpen, setIsOpen] = useState(null);
    const [data, setData] = useState({});
    // const idForGettingAnswer = localStorage.getItem("vdi_User_Id");
    const [answers, setAnswers] = useState({ data: [], loading: false });
    const [attachmentList, setAttachmentList] = useState({ data: [], loading: false });

    const { register, handleSubmit, formState, control, reset, setValue } = useForm({
        resolver: yupResolver(schema), mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;
    const [filesWithAnswerTrue, setFilesWithAnswerTrue] = useState(null);

    const toggle = (id) => {
        if (isOpen === id) {
            setIsOpen(null)
        } else {
            setIsOpen(id);
            console.log("firing");
            if (id === 1) {
                fetchAnswer();
            } else if (id === 2) {
                fetchChecklistItemAttachment();
            }
        }
    }


    useEffect(() => {
        console.log("props", props);
        console.log("props.sopDetails", props.sopDetails);

        if (props.answerModal === false || props.answerModal === undefined) {
            setValue('answer', '');
            setValue('remarks', '');
            toggle(null)
        }

        // console.log("values", JSON.parse(props.sopDetails.methodology.values));


    }, [props]);




    function fetchAnswer() {
        setAnswers({ ...answers, loading: true, data: [] });
        // console.log("props.dataForAnswer", props.dataForAnswer);
        getChecklistItemAnswer({ checkListItem: props.dataForAnswer.Id, survey: props.surveyId }).then((res) => {
            console.log("res", res);
            setAnswers({ ...answers, loading: false, data: res.data });


        }, (err) => {
            console.log("err", err);
        });
    }

    function fetchChecklistItemAttachment() {
        setAttachmentList({ ...attachmentList, loading: true, data: [] });

        getChecklistItemAttachment({ checkListItem: props.dataForAnswer.Id, survey: props.surveyId, withSurveyOnly: "False",withTable: "False" }).then((res) => {
            console.log("res", res);
            setAttachmentList({ ...attachmentList, loading: false, data: res.data });

            if (res.data.length > 0) {
                const result = res.data.filter((val, i) => {
                    return val.withAnswer === true;
                })
                setFilesWithAnswerTrue(result);
            }

        }, (err) => {
            console.log("err", err);
        });
    }

    const onSubmit = data => {

        if (data !== '') {

            console.log("data", data);

            const reqPayload = {
                checkListItem: props.dataForAnswer.Id,  //checklistItem Id
                values: data.answer,
                remarks: data.remarks,
                createdBy: localStorage.getItem('vdi_User_Id'),
                survey: props.surveyId
            }
            console.log("reqPayload", reqPayload);
            console.log("props.surveyId", props.surveyId);

            postChecklistItemAnswer(reqPayload).then((res) => {
                console.log("res", res);
                toast.success("Answer submitted successfully !", { autoClose: 3000, theme: "light", });
                fetchAnswer();
                setValue('answer', '');
                setValue('remarks', '');
            }, (err) => {
                console.log("err", err);
            });

        } else {
            errors.showMessages();
        }
    };


    function handleFileChange(e) {
        console.log(e.target.files);
        setData(e.target.files[0]);
    }


    async function handleFileSubmit() {
        console.log("data", data);
        console.log("props", props);

        const reqPayload = {
            checklist_file: data,
            checkListItem: props.dataForAnswer.Id,
            survey: props.surveyId,
            name: data.name,
            createdBy: parseInt(localStorage.getItem("vdi_User_Id"))
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(await showPosition);

        }
        function showPosition(position) {
            console.log("position.coords.latitude ", Number(Number(position.coords.latitude).toFixed(6)));
            console.log("position.coords.longitude ", Number(Number(position.coords.longitude).toFixed(6)));

            reqPayload.lat = Number(Number(position.coords.latitude).toFixed(6));
            reqPayload.long = Number(Number(position.coords.longitude).toFixed(6));


            if (reqPayload.checklist_file.name.endsWith(".jpeg") || reqPayload.checklist_file.name.endsWith(".JPEG")) {
                reqPayload.fileType = "Image";
            } else if (reqPayload.checklist_file.name.endsWith(".png" || reqPayload.checklist_file.name.endsWith(".PNG"))) {
                reqPayload.fileType = "Image";
            } else if (reqPayload.checklist_file.name.endsWith(".jpg") || reqPayload.checklist_file.name.endsWith(".JPG")) {
                reqPayload.fileType = "Image";
            } else if (reqPayload.checklist_file.name.endsWith(".mp4")) {
                reqPayload.fileType = "Video";
            } else if (reqPayload.checklist_file.name.endsWith(".WMV")) {
                reqPayload.fileType = "Video";
            } else if (reqPayload.checklist_file.name.endsWith(".MOV")) {
                reqPayload.fileType = "Video";
            } else if (reqPayload.checklist_file.name.endsWith(".pdf")) {
                reqPayload.fileType = "Document";
            } else if (reqPayload.checklist_file.name.endsWith(".xlsx") || reqPayload.checklist_file.name.endsWith(".xls")) {
                reqPayload.fileType = "Document";
            } else if (reqPayload.checklist_file.name.endsWith(".docx") || reqPayload.checklist_file.name.endsWith(".doc")) {
                reqPayload.fileType = "Document";
            }

            reqPayload.withAnswer = "True";
            console.log("reqPayload", reqPayload);

            var formdata = new FormData();

            for (var key in reqPayload) {
                formdata.append(key, reqPayload[key]);
            }



            uploadChecklistItemAttachment(formdata).then((res) => {
                console.log("res", res);

                toast.success("Attachment upload successfully !", { autoClose: 3000, theme: "light", });
                fetchChecklistItemAttachment();

            }, (err) => {
                console.log("err", err);
            });
        }


    }


    return (
        <React.Fragment>
            <Draggable >
                <Modal isOpen={props.answerModal} toggle={props.toggleAnswerModal} size="lg" >
                    <ModalHeader toggle={props.toggleAnswerModal} className='bg-primary-blue'>{"Checklist Item Details"}</ModalHeader>
                    <ModalBody>
                        <div className='row' id='answerModalRow'>
                            <Card className='mb-2'>
                                <CardHeader className="bg-dark p-0 shadow-sm">
                                    <h5 className="mb-0">
                                        <Button as={Card.Header} className='btn btn-link txt-white w-100 text-start' color='dark' onClick={() => { toggle(1); }}  >
                                            Checklist Item Answer
                                        </Button>
                                    </h5>
                                </CardHeader>
                                <Collapse isOpen={isOpen === 1}>
                                    <CardBody>
                                        <div className='mb-2'>
                                            <p className='mb-0 text-muted f-16'>
                                                <span className='me-2 f-w-600'>Q.</span>
                                                {props.dataForAnswer?.section}
                                            </p>

                                        </div>
                                        {
                                            answers.loading ?
                                                <div className="loader-box justify-content-center">
                                                    <div className="loader">
                                                        <div className="line bg-dark"></div>
                                                        <div className="line bg-dark"></div>
                                                        <div className="line bg-dark"></div>
                                                        <div className="line bg-dark"></div>
                                                    </div>
                                                </div>
                                                : answers.data.length > 0 ?
                                                    <div className="table-responsive" id='Checklist_answer_table'>
                                                        <table className="table table-bordered ">
                                                            <thead className='thead-light'>
                                                                <tr className=''>
                                                                    <th scope="col">{"Answer"}</th>
                                                                    <th scope="col">{"Comments"}</th>
                                                                    <th scope="col">{"Name"}</th>
                                                                    <th scope="col">{"Username"}</th>
                                                                    <th scope="col">{"Role"}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* {answers.data.map((val, i) => (
                                                                    <tr key={i} id={'section_row' + i} className=' cursor_pointer'>
                                                                        <td>{val.values}</td>
                                                                        <td>{val.name}</td>
                                                                        <td>{val.username}</td>
                                                                        <td>{val.role}</td>
                                                                    </tr>
                                                                ))} */}
                                                                <tr id={'section_row'}>
                                                                    <td>{answers.data[0].values}</td>
                                                                    <td>{answers.data[0].remarks === null || answers.data[0].remarks.length === 0 ? "NA" : answers.data[0].remarks}</td>
                                                                    <td>{answers.data[0].name}</td>
                                                                    <td>{answers.data[0].username}</td>
                                                                    <td>{answers.data[0].role}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    : <p>No Answers Available</p>
                                        }

                                        <hr />


                                        <div className='row'>
                                            <div className='col-12 col-md-6'>
                                                <div className="form-group mb-2">
                                                    <label className='f-w-600'>Your Answer</label>
                                                    {props.sopDetails?.methodology.values === null || props.sopDetails?.methodology.values.length === 0 ?

                                                        <input
                                                            className={errors.answer ? " form-control is-invalid" : " form-control"}
                                                            name='answer'
                                                            placeholder='Enter your answer here'
                                                            {...register('answer')}></input>
                                                        :


                                                        <select
                                                            className={errors.answer ? " form-control is-invalid" : " form-control"}
                                                            {...register('answer')}
                                                        >

                                                            <option value="">{"--Select*--"}</option>

                                                            {console.log("props.sopDetails?.methodology?.values", props.sopDetails?.methodology?.values)};

                                                            {console.log(JSON.parse(props.sopDetails?.methodology.values))}

                                                            {JSON.parse(props.sopDetails?.methodology.values).map((val, i) => (
                                                                <option value={val} key={i}>{val}</option>
                                                            ))}

                                                            {/* {props.sopDetails?.methodology?.type === "Satisfactory/Not Satisfactory" ?
                                                            <>
                                                                <option value="Satisfactory">{"Satisfactory"}</option>
                                                                <option value="Not Satisfactory">{"Not Satisfactory"}</option>
                                                                <option value="NA">{"NA"}</option>
                                                            </>
                                                            :
                                                            props.sopDetails?.methodology?.type === "yes/no" ?
                                                                <>
                                                                    <option value="Yes">{"Yes"}</option>
                                                                    <option value="No">{"No"}</option>
                                                                    <option value="NA">{"NA"}</option>
                                                                </> :

                                                                props.sopDetails?.methodology?.type === "Ratings" ?
                                                                    <>
                                                                        <option value="1">{"1"}</option>
                                                                        <option value="2">{"2"}</option>
                                                                        <option value="3">{"3"}</option>
                                                                        <option value="4">{"4"}</option>
                                                                        <option value="5">{"5"}</option>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        <option value="Yes">{"Yes"}</option>
                                                                        <option value="No">{"No"}</option>
                                                                        <option value="NA">{"NA"}</option>
                                                                    </>
                                                        } */}
                                                        </select>
                                                    }
                                                    <span className='text-danger'>{errors.answer?.message}</span>
                                                </div>
                                            </div>

                                            <div className='col-12'>
                                                <div className="form-group mb-2">
                                                    <label className='f-w-600'>Comments</label>

                                                    <textarea
                                                        className={errors.remarks ? " form-control is-invalid" : " form-control"}
                                                        rows={'2'}
                                                        name='remarks'
                                                        disabled={props.dataForAnswer?.comments === "No" ? true : false}
                                                        {...register('remarks')}
                                                        placeholder="Enter your comments here"
                                                    >
                                                    </textarea>

                                                    <span className='text-danger'>{errors.remarks?.message}</span>
                                                </div>
                                            </div>

                                            <div className='col-12'>
                                                <button
                                                    onClick={handleSubmit(onSubmit)} style={{ fontSize: "16px " }}
                                                    className='btn btn-primary-blue float-right'>{"Submit"}</button>
                                            </div>
                                        </div>

                                    </CardBody>
                                </Collapse>
                            </Card>



                            <Card className='mb-2'>
                                <CardHeader className="bg-dark p-0 shadow-sm" >
                                    <h5 className="mb-0">
                                        <Button as={Card.Header} className='btn btn-link txt-white w-100 text-start' color='dark' onClick={() => { toggle(2); }}  >
                                            Attachments
                                        </Button>
                                    </h5>
                                </CardHeader>
                                <Collapse isOpen={isOpen === 2}>
                                    <CardBody>
                                        {
                                            attachmentList.loading ?
                                                <div className="loader-box justify-content-center">
                                                    <div className="loader">
                                                        <div className="line bg-dark"></div>
                                                        <div className="line bg-dark"></div>
                                                        <div className="line bg-dark"></div>
                                                        <div className="line bg-dark"></div>
                                                    </div>
                                                </div>
                                                :
                                                attachmentList.data.length > 0 ?
                                                    <>
                                                        <div className='row '>
                                                            {attachmentList.data.map((val, i) => (
                                                                val.withAnswer === true ?
                                                                    val.fileType === "Image" ?
                                                                        <div
                                                                            key={i}
                                                                            className='col-12 col-md-2 mb-3 '
                                                                            style={{ lineHeigth: '0.6' }}
                                                                        >
                                                                            <img
                                                                                src={val.checklist_file}
                                                                                height={'150'}
                                                                                width={'120'}
                                                                                className='mx-auto p-2 shadow-sm cursor_pointer'
                                                                                style={{ border: '1px solid #c6c6c6' }}
                                                                                onClick={() => { window.open(val.checklist_file, '_blank').focus() }}

                                                                            ></img>
                                                                            <p className='f-12 text-center'>{val.name}</p>
                                                                        </div>
                                                                        :

                                                                        val.fileType === "Video" ?
                                                                            <div
                                                                                onClick={() => { window.open(val.checklist_file, '_blank').focus() }}
                                                                                key={i}
                                                                                className='col-12 col-md-2 mb-3 '
                                                                            >
                                                                                <div className='cursor_pointer shadow-sm d-flex justify-content-center align-items-center'
                                                                                    style={{ height: '100px', width: '100px', border: '1px solid #c6c6c6' }}>
                                                                                    <Film
                                                                                        className=''
                                                                                        style={{ color: '#313131' }}
                                                                                        height={'50px'}
                                                                                        width={'50px'}
                                                                                        id={"doc" + i}
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                            :
                                                                            val.fileType === "Document" ?
                                                                                <div
                                                                                    onClick={() => { window.open(val.checklist_file, '_blank').focus() }}
                                                                                    key={i}
                                                                                    className='col-12 col-md-2 mb-3 '
                                                                                >
                                                                                    <div className='cursor_pointer shadow-sm d-flex justify-content-center align-items-center'
                                                                                        style={{ height: '100px', width: '100px', border: '1px solid #c6c6c6' }}>
                                                                                        <FileText
                                                                                            className=''
                                                                                            style={{ color: '#313131' }}
                                                                                            height={'50px'}
                                                                                            width={'50px'}
                                                                                            id={"doc" + i}
                                                                                        />
                                                                                    </div>

                                                                                </div>
                                                                                : null
                                                                    : null
                                                            ))}

                                                            {filesWithAnswerTrue !== null ?
                                                                filesWithAnswerTrue.length > 0 ? null :
                                                                    <p className='mb-2'>No Attachments Available</p>
                                                                : null}

                                                        </div>


                                                    </>

                                                    :
                                                    <p>No Attachments Available</p>
                                        }

                                        {props.dataForAnswer?.photos === "No" && props.dataForAnswer?.videos === 'No' ? null
                                            :
                                            <div className='row'>
                                                <div className='col-12 col-md-6 mb-2'>
                                                    <div className='form-group'>
                                                        <label className='f-w-600'>Upload File</label>
                                                        <input
                                                            className='form-control'
                                                            type={'file'}
                                                            name='file'
                                                            accept={props.dataForAnswer?.photos === 'Yes' && props.dataForAnswer?.videos === 'No' ?
                                                                'image/*' : props.dataForAnswer?.photos === 'No' && props.dataForAnswer?.videos === 'Yes' ? 'video/*'
                                                                    : props.dataForAnswer?.photos === 'Yes' && props.dataForAnswer?.videos === 'Yes' ? "image/*,video/*" : null}
                                                            onChange={(e) => handleFileChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-12 mt-2'>
                                                    <button className='btn btn-primary-blue' onClick={handleFileSubmit}>Upload</button>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                </Collapse>
                            </Card>

                        </div>
                    </ModalBody>

                </Modal>
            </Draggable>
        </React.Fragment >
    )
}

export default AnswerScreen