import React, { useState, useEffect, useRef } from "react";
import {
  getCheckListTable,
  createCheckListTable,
  updateCheckListTable,
  updateChecklistItemAttachmentv3,
  getChecklistItemAttachmentv3,
  uploadChecklistItemAttachmentv3,
} from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
} from "reactstrap";
import { Chart } from "react-google-charts";
import html2canvas from "html2canvas";

function FormsOfSurvey2() {
  const [formList, setFormList] = useState({ data: [], loading: false });
  const [modal8, setModal8] = useState();
  const [tableOfCheclist, setTableOfCheckList] = useState(false);
  const [risOpen, rsetIsOpen] = useState(0);
  const [risOpen2, rsetIsOpen2] = useState(0);
  const [lcData, setlcData] = useState({ tableName: "", tabId: "", data: [] });
  const [seq, setSeq] = useState("");

  const localData = JSON.parse(localStorage.getItem("login_data"));
  const { id, cid } = useParams();
  const divRef = useRef(null);
  const parser = new DOMParser();

  const rtoggle = async (tid) => {
    console.log("tid", tid);
    if (risOpen === tid) {
      rsetIsOpen(null);
    } else {
      rsetIsOpen(tid);
    }
  };

  const rtoggle2 = async (id) => {
    console.log("id", id);
    if (risOpen2 === id) {
      rsetIsOpen2(null);
    } else {
      rsetIsOpen2(id);
    }
  };
  useEffect(() => {
    fetchTables();
  }, []);

  function fetchTables() {
    setFormList({ ...formList, data: [], loading: true });

    var reqPayload = {
      pageNo: 1,
      entriesPerPage: 1000,
      customer_id: localData.customer_id,
      checklist: cid,
      survey: id,
      withSurvey: "True",
    };
    getCheckListTable(reqPayload).then(
      (res) => {
        console.log("res", res);
        if (res.data.payload.length > 0) {
          if (res.data.payload[0].file.length > 0) {
            setFormList({
              ...formList,
              data: res.data.payload[0],
              loading: false,
            });
            setTableOfCheckList(false);
          }
        } else {
          var payload = {
            pageNo: 1,
            entriesPerPage: 1000,
            customer_id: localData.customer_id,
            checklist: cid,
            withSurvey: "False",
          };
          getCheckListTable(payload).then(
            (res) => {
              console.log("res", res);
              if (res.data.payload.length > 0) {
                setTableOfCheckList(true);
                if (res.data.payload[0].file.length > 0) {
                  setFormList({
                    ...formList,
                    data: res.data.payload[0],
                    loading: false,
                  });
                }
              }
            },
            (err) => {
              console.log("err", err);
              toast.error("Something went wrong !", {
                autoClose: 3000,
                theme: "light",
              });
            }
          );
        }
      },
      (err) => {
        console.log("err", err);
        toast.error("Something went wrong !", {
          autoClose: 3000,
          theme: "light",
        });
      }
    );
  }

  function onInputChange(e, who) {
    console.log("e.target.id", e.target.id);
    console.log("e.target.value", e.target.value);
    const element = document.getElementById(e.target.id);
   
    if (who == "col") {
      console.log("element", element);
      element.value = e.target.value;
    } else if (who == "row") {
      if (e.target.value.length > 0) {
        console.log("element", element);
        element.value = e.target.value;
      } 
      else {
        element.value = "";
      }
    }
  }


  function saveTable(i, val) {
    console.log("i", i);
    console.log("val", val);
    console.log("formList.data.file", formList.data.file);
    let payload = { ...val };
    payload.th = [];
    payload.tr = [];
    payload.sequence=seq;
    // const newFormList = [];
    // newFormList.push(formList.data.file[i]);

    for (let b = 0; b < val.th.length; b++) {
      console.log(document.getElementById("col" + i + b).value);
      payload.th.push({
        colName: document.getElementById("col" + i.toString() + b.toString())
          .value,
        axis: val.th[b].axis,
      });
    }
    for (let j = 0; j < val.tr.length; j++) {
      payload.tr.push({ row: [] });
      for (let z = 0; z < val.tr[j].row.length; z++) {
        console.log(formList.data.file[i].tr[j]);
        payload.tr[j].row.push({
          value: document.getElementById(
            "row" + i.toString() + j.toString() + z.toString()
          ).value,
          axis: formList.data.file[i].tr[j].row[z].axis,
        });
        // console.log(
        //   document.getElementById(
        //     "row" + i.toString() + j.toString() + z.toString()
        //   ).value
        // );
      }
    }
   

    console.log("payload", payload);

    if (tableOfCheclist) {
      console.log("create table for surveys");
      const postPayload = { ...formList.data };
      postPayload.file.splice(i, 1);
      postPayload.file.splice(i, 0, payload);
      delete postPayload.Id;
      delete postPayload.id;
      delete postPayload.createdDate;
      delete postPayload.updatedDate;
      delete postPayload.isActive;

      postPayload.checklist = parseInt(cid);
      postPayload.survey = parseInt(id);
      postPayload.withSurvey = "True";


      if (
        postPayload.file[i].answer !== null &&
        postPayload.file[i].answer.length > 0
      ) {
        // sorting table based on sequence 
        postPayload.file.sort((a, b) => {
          const sequenceA = parseInt(a.sequence);
          const sequenceB = parseInt(b.sequence);
          return sequenceA - sequenceB;
        });

        // Sort rows within each table based on the sequence
        postPayload.file.forEach(table => {
          table.tr.sort((a, b) => {
            const rowA = parseInt(a.row[0].value);
            const rowB = parseInt(b.row[0].value);
            return rowA - rowB;
          });
        });
        console.log("postPayload", postPayload);
        createCheckListTable(postPayload).then(
          (res) => {
            console.log("res", res);
            toast.success("Table updated successfully !", {
              autoClose: 1000,
              theme: "light",
            });
            generateGraph(postPayload.file[i], "save");
            fetchTables();
          },
          (err) => {
            console.log("err", err);
          }
        );
      } else {
        toast.error("Answer required!", { autoClose: 1000, theme: "light" });
      }
    } else {
      console.log("formList.data", formList.data);
      const putPayload = { ...formList.data };
      putPayload.file.splice(i, 1);
      putPayload.file.splice(i, 0, payload);
      delete putPayload.createdDate;
      delete putPayload.updatedDate;
      delete putPayload.isActive;

      console.log("update table of survey");

      if (
        putPayload.file[i].answer !== null &&
        putPayload.file[i].answer.length > 0
      ) {
        payload.sequence=seq;

        // sorting table based on sequence 
        putPayload.file.sort((a, b) => {
          const sequenceA = parseInt(a.sequence);
          const sequenceB = parseInt(b.sequence);
          return sequenceA - sequenceB;
        });

        // Sort rows within each table based on the "row" sequence
        putPayload.file.forEach(table => {
          table.tr.sort((a, b) => {
            const rowA = parseInt(a.row[0].value);
            const rowB = parseInt(b.row[0].value);
            return rowA - rowB;
          });
        });
      
        console.log("putPayload", putPayload);
        updateCheckListTable(putPayload).then(
          (res) => {
            console.log("res", res);
            toast.success("Table updated successfully !", {
              autoClose: 1000,
              theme: "light",
            });
            generateGraph(putPayload.file[i], "save");
            fetchTables();
          },
          (err) => {
            console.log("err", err);
          }
        );
      } else {
        toast.error("Answer required!", { autoClose: 1000, theme: "light" });
      }
    }
  }

  function handleAnswer(e, i) {
    console.log("e.target.id", e.target.id);
    console.log("e.target.checked", e.target.checked);
    console.log("i", i);

    const ele = document.getElementById(e.target.id);
    ele.checked = e.target.checked;

    if (e.target.id.includes("answery")) {
      const ele = document.getElementById("answern" + i);
      ele.checked = !e.target.checked;
      const newFormList = { ...formList };
      newFormList.data.file[i].answer = "Yes";
      setFormList(newFormList);
    } else if (e.target.id.includes("answern")) {
      const ele = document.getElementById("answery" + i);
      ele.checked = !e.target.checked;
      const newFormList = { ...formList };
      newFormList.data.file[i].answer = "No";
      setFormList(newFormList);
    }
  }

  const toggle8 = (who) => {
    setModal8(!modal8);

    if (who == "save") {
      setTimeout(() => {
        handleScreenshot();
      }, [500]);
    }
  };

  function generateGraph(data, who) {
    console.log("data", data);

    if (data.axisType == "row") {
      const xArray = data.tr.filter((item) =>
        item.row.some((obj) => obj.axis === "x")
      )[0].row;
      console.log("xArray", xArray);

      const yArrays = data.tr
        .filter((item) => item.row.some((obj) => obj.axis === "y"))
        .map((item) => item.row);
      console.log("yArrays", yArrays);

      const output = [];

      const rowHeaders = yArrays.map((row) => row[0].value);

      output.push(["row 1", ...rowHeaders]);

      for (let i = 1; i < xArray.length; i++) {
        const newRow = [xArray[i].value];

        for (let j = 0; j < yArrays.length; j++) {
          newRow.push(parseFloat(yArrays[j][i].value));
        }

        output.push(newRow);
      }

      console.log(output);

      lcData.data = output;
      lcData.tableName = data.tableName;
      lcData.tabId = data.TableId;
      setlcData({
        ...lcData,
        tableName: lcData.tableName,
        tabId: lcData.tabId,
        data: lcData.data,
      });
    } else if (data.axisType == "column") {
      let colXIndex = "";
      for (let i = 0; i < data.th.length; i++) {
        if (data.th[i].axis === "x") {
          console.log("Index of element with axis 'x': " + i);
          colXIndex = i;
          break; // break the loop as soon as the first match is found
        }
      }
      console.log("colXIndex", colXIndex);

      const colNames = [];
      const noXYIndexes = [];
      for (let i = 0; i < data.th.length; i++) {
        if (data.th[i].axis === "x" || data.th[i].axis === "y") {
          colNames.push(data.th[i].colName);
        } else if (data.th[i].axis === "") {
          noXYIndexes.push(i);
        }
      }
      console.log("colNames", colNames);
      console.log("noXYIndexes", noXYIndexes);

      const rowData = data.tr.map((item) =>
        item.row.map((cell, i) =>
          i !== colXIndex && noXYIndexes.includes(i) == false
            ? parseFloat(cell.value)
            : noXYIndexes.includes(i) == false
              ? cell.value
              : null
        )
      );
      console.log("rowData", rowData);
      const listWithOutNull = rowData.map((subArray) =>
        subArray.filter((element) => element !== null)
      );

      console.log(listWithOutNull);

      const resData = [colNames, ...listWithOutNull];
      console.log("resData", resData);
      lcData.data = resData;
      lcData.tableName = data.tableName;
      lcData.tabId = data.TableId;
      setlcData({
        ...lcData,
        tableName: lcData.tableName,
        tabId: lcData.tabId,
        data: lcData.data,
      });
    }

    toggle8(who);
    console.log("lcData", lcData);
  }

  const handleScreenshot = () => {
    const now = new Date().getTime();

    console.log("lcData", lcData);
    html2canvas(divRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        const file = new File([blob], `${lcData.tableName}${now}.png`, {
          type: "image/png",
        });
        // do something with the file object, such as uploading it to a server
        // console.log("file", file);

        const params = {
          survey: id,
          fileType: "Image",
          withSurveyOnly: "True",
          withTable: "True",
          TableId: lcData.tabId,
        };
        getChecklistItemAttachmentv3(params).then(
          (res) => {
            console.log("res", res);
            if (res.data.length > 0) {
              const putpayload = {
                Id: res.data[0].Id,
                checklist_file: file,
                fileType: "Image",
                withAnswer: "False",
                withSurveyOnly: "True",
                survey: id,
                TableId: lcData.tabId,
                withTable: "True",
              };
              console.log("putpayload", putpayload);
              var formdata = new FormData();
              for (var key in putpayload) {
                formdata.append(key, putpayload[key]);
              }
              updateChecklistItemAttachmentv3(formdata).then(
                (res) => {
                  console.log("res", res);
                  toast.success("Chart successfully linked with report !", {
                    autoClose: 1000,
                    theme: "light",
                  });
                },
                (err) => {
                  console.log("err", err);
                  toast.error("Something went wrong !", {
                    autoClose: 1000,
                    theme: "light",
                  });
                }
              );
            } else {
              const postpayload = {
                checklist_file: file,
                fileType: "Image",
                withAnswer: "False",
                withSurveyOnly: "True",
                survey: id,
                TableId: lcData.tabId,
                withTable: "True",
              };
              console.log("postpayload", postpayload);
              var formdata = new FormData();
              for (var key in postpayload) {
                formdata.append(key, postpayload[key]);
              }
              uploadChecklistItemAttachmentv3(formdata).then(
                (res) => {
                  console.log("res", res);
                  toast.success("Chart successfully linked with report !", {
                    autoClose: 1000,
                    theme: "light",
                  });
                },
                (err) => {
                  console.log("err", err);
                  toast.error("Something went wrong !", {
                    autoClose: 1000,
                    theme: "light",
                  });
                }
              );
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
      });
    });
  };

  return (
    <React.Fragment>
      {" "}
      <div className="fontFamily mb-4">
        <div className="default-according style-1">
          <Card>
            <CardHeader className="bg-vedam position-relative p-2">
              <h5 className="mb-0">
                <Button
                  style={{ position: "relative" }}
                  as={Card.Header}
                  className="btn btn-link btn-vedam txt-white p-0 "
                  onClick={() => rtoggle(1)}
                >
                  <p className="mb-0 f-16 me-5"> {`Tables for survey`}</p>
                </Button>
              </h5>
            </CardHeader>

            <Collapse isOpen={risOpen === 1}>
              <div className="default-according style-1">
                <CardBody style={{ padding: "15px" }}>
                  {formList.data.file?.map((val, i) => (
                    <Card key={i}>
                      <CardHeader className="bg-dark position-relative px-2 py-2">
                        <h5 className="mb-0">
                          <Button
                            style={{ position: "relative" }}
                            as={Card.Header}
                            className="btn btn-link btn-dark txt-white p-0 "
                            onClick={() => rtoggle2(i + 1)}
                          >
                            <div >

                              <div> <p className="mb-0 f-16 me-5">
                                {`${val.tableName}`}
                              </p></div>


                            </div>


                          </Button>
                        </h5>
                      </CardHeader>

                      <Collapse
                        isOpen={risOpen2 === i + 1}
                        style={{ padding: "15px" }}
                      >
                        {/* {renderTables()} */}

                        <div
                          className="table-responsive"
                          id={"my-table" + i}
                          style={{ overflowX: "scroll" }}
                        >
                           <div className="d-flex justify-content-start mb-3"> 
                          <div className="me-3"><label className="f-w-600">Sequence :</label>  </div>
                          <div> <input
                            className="form-control"
                            type="number" 

                            defaultValue={val.sequence}
                            placeholder="Sequence"
                            style={{ width: "35%", height:"100%" }}
                            onChange={(e) => setSeq(e.target.value)}
                          /></div>
                          </div>


                          <table className="table table-bordered">
                            <thead>
                              <tr style={{ background: "#2264ab" }}>
                                {val.th.map((col, coli) => (
                                  coli == 0 ? <th
                                    scope="col"
                                    key={coli}
                                    style={{
                                      padding: "8px 8px",
                                      minWidth: "150px",
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <input
                                        style={{
                                          background: "#2264ab",
                                          color: "white",
                                        }}
                                        id={
                                          "col" + i.toString() + coli.toString()
                                        }
                                        defaultValue={col.colName}
                                        type="text"
                                        className="form-control form-control-table"

                                        disabled={coli == 0}
                                        rows={"1"}
                                      />
                                    </div>
                                  </th> :
                                    <th
                                      scope="col"
                                      key={coli}
                                      style={{
                                        padding: "8px 8px",
                                        minWidth: "150px",
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <textarea
                                          style={{
                                            background: "#2264ab",
                                            color: "white",
                                          }}
                                          id={
                                            "col" + i.toString() + coli.toString()
                                          }
                                          defaultValue={col.colName}
                                          type="text"
                                          className="form-control form-control-table"
                                          onChange={(e) => {
                                            onInputChange(e, "col");
                                          }}
                                          disabled={coli == 0}
                                          rows={"1"}
                                        ></textarea>
                                      </div>
                                    </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {val.tr.map((row, rowi) => (
                                <tr key={rowi}>
                                  {row.row?.map((val, vali) => (
                                    vali == 0 ? <td
                                      key={vali}
                                      style={{ padding: "6px 6px" }}
                                    >
                                      <input
                                        id={
                                          "row" +
                                          i.toString() +
                                          rowi.toString() +
                                          vali.toString()
                                        }
                                        // style={{ border: "none" }}
                                        defaultValue={val.value}
                                        type="text"
                                        className="form-control form-control-table"
                                        onChange={(e) => {
                                          onInputChange(e, "row");
                                        }}
                                        rows={"1"}
                                      />
                                    </td>
                                      : <td
                                        key={vali}
                                        style={{ padding: "6px 6px" }}
                                      >
                                        <textarea
                                          id={
                                            "row" +
                                            i.toString() +
                                            rowi.toString() +
                                            vali.toString()
                                          }
                                          // style={{ border: "none" }}
                                          defaultValue={val.value}
                                          type="text"
                                          className="form-control form-control-table"
                                          onChange={(e) => {
                                            onInputChange(e, "row");
                                          }}

                                          rows={"1"}
                                        ></textarea>
                                      </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="row mt-2 justify-content-end">
                          <div className="col-12 col-md-5 col-lg-5">
                            <div className="text-end">
                              <div className="form-group m-checkbox-inline mb-0">
                                <label className="f-w-600 f-14">
                                  Accepted:{" "}
                                </label>
                                <div className="checkbox checkbox-primary">
                                  <input
                                    id={"answery" + i.toString()}
                                    onChange={(e) => handleAnswer(e, i)}
                                    type="checkbox"
                                    defaultChecked={
                                      val.answer == "Yes" ? true : false
                                    }
                                  />
                                  <label htmlFor={"answery" + i.toString()}>
                                    <span className="digits"> {"Yes"}</span>
                                  </label>
                                </div>
                                <div className="checkbox checkbox-primary">
                                  <input
                                    id={"answern" + i.toString()}
                                    type="checkbox"
                                    onChange={(e) => handleAnswer(e, i)}
                                    defaultChecked={
                                      val.answer == "No" ? true : false
                                    }
                                  />
                                  <label htmlFor={"answern" + i.toString()}>
                                    <span className="digits"> {"No"}</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="text-end">
                              {tableOfCheclist == false ? (
                                <button
                                  onClick={() => generateGraph(val, "view")}
                                  style={{ maxWidth: "150px" }}
                                  className="btn btn-primary-blue  btn-sm mt-2  f-12 text-center"
                                >
                                  view graph
                                </button>
                              ) : null}
                              <button
                                style={{ maxWidth: "150px" }}
                                onClick={() => saveTable(i, val)}
                                className="btn btn-primary-blue btn-sm ms-2 mt-2 f-12 text-center"
                              >
                                save
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4"></div> */}
                      </Collapse>
                    </Card>
                  ))}
                </CardBody>
              </div>
            </Collapse>
          </Card>
        </div>
      </div>
      <Modal
        className="fontFamily"
        isOpen={modal8}
        toggle={toggle8}
        size="lg"
        backdrop="static"
      >
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {`Graph for ${lcData.tableName}`}
        </ModalHeader>

        <ModalBody>
          <div ref={divRef}>
            <Chart
              width={"100%"}
              height={"400px"}
              chartType="LineChart"
              loader={<div>{"Loading Chart"}</div>}
              data={lcData.data}
              options={{
                hAxis: {
                  title: "",
                },
                vAxis: {
                  title: "",
                },
                colors: ["#4466f2", "#fc9803", "#f22f0c", "#ffd129", "#174a9c"],
                series: {
                  1: { curveType: "function" },
                },
              }}
              rootProps={{ "data-testid": "2" }}
            />
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <button
            className="btn btn-primary-blue float-right"
            onClick={() => handleScreenshot()}
          >
            Link With Report
          </button>
        </ModalFooter> */}
      </Modal>
    </React.Fragment>
  );
}

export default FormsOfSurvey2;
