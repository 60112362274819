import React, { useState, useEffect } from 'react';

function TimerComponent({ onTimerDone }) {
  const [time, setTime] = useState(5 * 60); // 5 minutes in seconds

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      onTimerDone(); 
    }
  }, [time, onTimerDone]);

  return (
    <div className='text-center'>
      <h6>Time Remaining for OTP: {Math.floor(time / 60)}:{time % 60 < 10 ? '0' : ''}{time % 60}</h6>
    </div>
  );
}

export default TimerComponent;
