import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import AddSOPItems from "./AddSOPItems";
import { Typeahead } from "react-bootstrap-typeahead";
import { Trash2 } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { getMethodology, createSOP } from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("I&G name is required !"),
    description: yup.string().required("I&G description is required !"),
    selfie: yup
      .string()
      .nullable()
      .required("Selfie is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    opening_Meeting: yup
      .string()
      .nullable()
      .required("Opening meeting required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    // vesselInfo: yup.string().notRequired(),
    methodology_id: yup
      .string()
      .nullable()
      .required("Please select methodology !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    photos: yup
      .string()
      .nullable()
      .required("Photo is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    videos: yup
      .string()
      .nullable()
      .required("Video is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    OG: yup
      .string()
      .nullable()
      .required("OG is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    items: yup.array().of(
      yup.object().shape({
        sequence: yup
          .number()
          .required("Please enter sr.no")
          .typeError("Please enter valid number")
          .test('is-number', 'Please enter a valid number for sr.no', value => {
            if (value === undefined || value === null) {
              return false;
            }
            return !isNaN(value);
          }),
        type: yup.string().required("I&G item name is required !"),
      })
    ),
  })
  .required();

function CreateNewSOPForm(props) {
  const [modal8, setModal8] = useState();
  const [methodology, setMethodology] = useState({ data: [], loading: false });
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: { items: [{}] },
  }); // initialise the hook

  let { errors } = formState;

  const toggle8 = () => {
    setModal8(!modal8);
    setMethodology({ ...methodology, data: [], loading: true });
    if (modal8 === false || modal8 === undefined) {
      // resetting the form
      reset();

      getMethodology().then(
        (res) => {
          console.log("res", res);
          setMethodology({ ...methodology, data: res.data, loading: false });
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = (data) => {
    console.log("data", data);

    if (data !== "") {
      const tempItems = data.items.map((val, i) => {
        return { instruction: val.type, sequence: val.sequence };
      });

      const reqPayload = {
        SOP: {
          customer_id: customer_id,
          name: data.name,
          description: data.description,
          selfie: data.selfie,
          opening_Meeting: data.opening_Meeting,
          methodology_id: data.methodology_id,
          photos: data.photos,
          videos: data.videos,
          OG: data.OG,
        },
        SOPLineItems: tempItems,
      };

      console.log("reqPayload", reqPayload);

      createSOP(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("I&G created successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          toggle8();

          props.fetchSOPS("refresh");
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <button
        onClick={toggle8}
        style={{ fontSize: "16px " }}
        className="btn btn-primary-blue"
      >
        Create New I&G{" "}
      </button>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {/* {'Create New SOP'} */}
          <p className="f-22 f-w-400 mb-0">
            {"Create New Instructions & Guidelines"}
          </p>
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Name"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Enter Name*"
                    name="name"
                    {...register("name")}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Description"}</label>
                  <textarea
                    className={
                      errors.description
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Description*"
                    name="description"
                    {...register("description")}
                    rows="1"
                  ></textarea>

                  <span className="text-danger">
                    {errors.description?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Selfie"}</label>
                  <Controller
                    name="selfie"
                    control={control}
                    defaultValue="Yes"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue="Yes"
                        className={
                          errors.selfie
                            ? " form-control is-invalid"
                            : " form-control"
                        }
                      >
                        y<option select="">{"--Select*--"}</option>
                        <option value="Yes">{"Compulsory"}</option>
                        <option value="No">{"Optional"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.selfie?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Opening Meeting"}</label>
                  <Controller
                    name="opening_Meeting"
                    control={control}
                    defaultValue="Yes"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue="Yes"
                        className={
                          errors.opening_Meeting
                            ? " form-control is-invalid"
                            : " form-control"
                        }
                      >
                        y<option select="">{"--Select*--"}</option>
                        <option value="Yes">{"Compulsory"}</option>
                        <option value="No">{"Optional"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">
                    {errors.opening_Meeting?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Methodology"}</label>

                  <Controller
                    name="methodology_id"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.methodology_id
                            ? " form-control is-invalid"
                            : " form-control"
                        }
                      >
                        <option select="">{"Select Methodology*"}</option>
                        {methodology.data.map((val, i) => (
                          <option key={i} value={val.Id}>
                            {val.type}
                          </option>
                        ))}
                      </select>
                    )}
                  />

                  <span className="text-danger">
                    {errors.methodology_id?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Photo"}</label>

                  <Controller
                    name="photos"
                    control={control}
                    defaultValue="Required"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue="Required"
                        className={
                          errors.photos
                            ? " form-control is-invalid"
                            : " form-control"
                        }
                      >
                        <option select="">{"--Select*--"}</option>
                        <option value="Required">{"Compulsory"}</option>
                        <option value="Not Required">
                          {"Optional"}
                        </option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.photos?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Video"}</label>

                  <Controller
                    name="videos"
                    control={control}
                    defaultValue="Required"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue="Required"
                        className={
                          errors.videos
                            ? " form-control is-invalid"
                            : " form-control"
                        }
                      >
                        <option select="">{"--Select*--"}</option>
                        <option value="Required">{"Compulsory"}</option>
                        <option value="Not Required">
                          {"Optional"}
                        </option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.videos?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"OG"}</label>

                  <Controller
                    name="OG"
                    control={control}
                    defaultValue="Required"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue="Required"
                        className={
                          errors.OG
                            ? " form-control is-invalid"
                            : " form-control"
                        }
                      >
                        <option select="">{"--Select*--"}</option>
                        <option value="Required">{"Compulsory"}</option>
                        <option value="Not Required">
                          {"Optional"}
                        </option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.OG?.message}</span>
                </div>
              </div>
            </div>
          </form>

          <div className="row">
            {/* <AddSOPItems /> */}
            <div className="col-12">
              <p className="f-18 f-w-600 mb-0">
                {"Instructions & Guidelines Items"}
              </p>
              <hr className="mb-3 mt-0" />

              <div className="table-responsive ">
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr className="f-16">
                      <th scope="col">{"Sr.No"}</th>
                      <th scope="col">{"Instructions & Guidelines Item"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td style={{ width: "170px" }}>
                          <input
                            className={
                              errors.items?.[index]?.sequence
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            type="text"
                            name={`items[${index}].sequence`}
                            {...register(`items[${index}].sequence`)}
                            placeholder="Enter Sr.No"
                          />
                          <span className="text-danger f-14">
                            {errors.items?.[index]?.sequence?.message}
                          </span>
                        </td>

                        <td>
                          <textarea
                            className={
                              errors.items?.[index]?.type
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            type="text"
                            // ref={register()}
                            {...register(`items[${index}].type`)}
                            placeholder="Enter I&G item name"
                            name={`items[${index}].type`}
                            rows="1"
                          ></textarea>
                          <span className="text-danger f-14">
                            {errors.items?.[index]?.type?.message}
                          </span>
                        </td>

                        <td style={{ width: "70px" }}>
                          <Trash2
                            id="sopdeleteToolTip"
                            className={index > 0 ? "" : "cursor_banned"}
                            onClick={index > 0 ? () => remove(index) : null}
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="sopdeleteToolTip"
                          >
                            {"Delete Item"}
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="d-flex justify-content-end  mt-4">
                  <button
                    style={{ fontSize: "16px" }}
                    className="btn btn-sm btn-primary-blue"
                    onClick={() => append({})}
                  >
                    Add
                  </button>

                  {/* <button
                                        style={{ fontSize: "16px" }}
                                        className='btn btn-sm btn-primary-blue ml-2'
                                        onClick={handleSubmit(onSubmit)}>{"Submit"}</button> */}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            onClick={handleSubmit(onSubmit)}
            style={{ fontSize: "16px " }}
            className="btn btn-primary-blue float-right"
          >
            {"Save "}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default CreateNewSOPForm;
