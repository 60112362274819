import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { toast } from 'react-toastify';
import { Trash2, } from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import { getCheckListFormElement, createCheckListForm, deleteCheckListForm } from '../../../../api/index';
import SweetAlert from 'sweetalert2'

const schema = yup.object().shape({
    name: yup.string().required('Name is required').max(500, '500 characters allowed !'),
    items: yup.array().of(
        yup.object().shape({
            key: yup.string().required('Required'),
            value: yup.string().notRequired()
        })
    )

}).required();


function FormElements(props) {


    const [elementModal, setElementModal] = useState();
    const [checklistForm, setCheclistForm] = useState({ data: {}, loading: false });

    const { register, handleSubmit, formState, control, reset } = useForm({
        resolver: yupResolver(schema), mode: "onChange", defaultValues: { items: [{}] }
    }); // initialise the hook

    let { errors } = formState;

    const { fields, append, remove } = useFieldArray({
        control, name: 'items'
    });


    useEffect(async () => {
        setCheclistForm({ ...checklistForm, loading: true, data: [] });
        await fetchChecklistForm();
    }, []);

    function fetchChecklistForm() {
        getCheckListFormElement({ checklist: props.checklistId, withSurvey: "False" }).then((res) => {
            console.log("res.data", res.data);
            setCheclistForm({ ...checklistForm, loading: false, data: res.data.payload });
        }, (err) => {
            console.log("err", err);
            setCheclistForm({ ...checklistForm, loading: false, data: [] });
        })
    }




    const toggleElementModal = () => {
        setElementModal(!elementModal)

        if (elementModal === false || elementModal === undefined) {
            reset();
        }
    }


    const onSubmit = data => {
        console.log("errors", errors);
        if (data !== '') {

            console.log("data", data);
            data.items.push({ key: 'title', value: data.name })
            const reqPayload = {
                file: data.items,
                withSurvey: false,
                checklist: props.checklistId
            }
            console.log("reqPayload", reqPayload);
            createCheckListForm(reqPayload).then(async (res) => {
                console.log("res", res);
                await fetchChecklistForm();
                toggleElementModal();
                toast.success("Form created successfully !", { autoClose: 3000,theme:'light' });
            }, (err) => {
                console.log("err", err);
                toast.error("Something went wrong !", { autoClose: 3000,theme:'light' });
            });

        } else {
            errors.showMessages();
        }
    };


    // Delete Form Function

    function deleteForm() {
        console.log("checklistForm", checklistForm);

        // If user selects yes only then deleting the form
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this record !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {

                    // Firing delete api
                    deleteCheckListForm({ Id: checklistForm.data[0].Id }).then(async (res) => {
                        console.log("res", res);
                        toast.success("Form deleted successfully !", { autoClose: 3000 });
                        await fetchChecklistForm();
                    }, (err) => {
                        console.log("err", err);
                        toast.error("Something went wrong !", { autoClose: 3000 });
                    });

                }
            })
    }



    return (
        <React.Fragment>
            <hr />
            <div className='d-flex justify-content-between'>
                <p className='f-18 f-w-600 mb-0'>Form: {checklistForm?.data[0]?.file[checklistForm?.data[0]?.file.length - 1].value}</p>

                {checklistForm?.data[0]?.file.length > 0 ?
                    <div>
                        {/* <UpdateForm checklistForm={checklistForm} fetchChecklistForm={fetchChecklistForm} /> */}
                        <button className='btn btn-danger ms-2' onClick={() => deleteForm()}>Delete</button>
                    </div>
                    :
                    <button onClick={toggleElementModal} className='btn btn-primary-blue '>Create Form</button>
                }

            </div>
            <hr />

            {checklistForm.loading === true ?
                <div className="loader-box justify-content-center">
                    <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                    </div>
                </div>
                :
                checklistForm?.data[0]?.file.length > 0 ?
                    <div className="table-responsive " id='Checklist_section_table'>
                        <table className="table table-bordered">
                            <thead className='thead-light'>
                                <tr className='f-18'>
                                    <th scope="col">{"Sr No"}</th>
                                    <th scope="col">{"Element Name"}</th>
                                    <th scope="col">{"Value"}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {checklistForm?.data[0]?.file.map((val, i) => (
                                    i !== checklistForm?.data[0]?.file.length - 1 ?
                                        <tr className='f-16 cursor_pointer' key={i}>
                                            <td>{i + 1}</td>
                                            <td>{val.key}</td>
                                            <td>{val.value}</td>
                                        </tr> : null
                                ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    <p className='mb- text-danger text-center'>No Form Elements Present !</p>
            }


            <Modal isOpen={elementModal} toggle={toggleElementModal} size="lg" backdrop='static'>
                <ModalHeader
                    toggle={toggleElementModal}
                    className='bg-primary-blue position-relative'>
                    {"Create Form"}

                    <button
                        style={{ top: '25%', left: '75%', tranform: 'translate(-50%,-50%)', }}
                        onClick={() => append({})}
                        className='btn btn-light position-absolute'>Add Element</button>
                </ModalHeader>

                <ModalBody>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <label className='f-w-600 f-14'>{"Form Name"}</label>
                            <textarea
                                className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                name={`name`}
                                placeholder="Enter form name*"
                                {...register('name')}
                                rows="2"
                            ></textarea>

                            <span className='text-danger f-14'>{errors.name?.message}</span>
                        </div>
                    </div>
                    {fields.map(({ id }, index) => (
                        <React.Fragment key={id}>
                            <div className='row'>
                                <div className='col-12 col-md-4 mb-2'>
                                    <label className='f-w-600 f-14'>{"Element Name"}</label>
                                    <input
                                        className={errors.items?.[index]?.key ? 'form-control is-invalid' : 'form-control'}
                                        type='text'
                                        name={`items[${index}].key`}
                                        {...register(`items[${index}].key`)}
                                        placeholder="Enter name*"
                                    />

                                    <span className='text-danger f-14'>{errors.items?.[index]?.key?.message}</span>
                                </div>

                                <div className='col-12 col-md-7 mb-2'>
                                    <label className='f-w-600 f-14'>{"Element Value"}</label>
                                    <textarea
                                        className={errors.items?.[index]?.value ? 'form-control is-invalid' : 'form-control'}
                                        type='text'
                                        name={`items[${index}].value`}
                                        {...register(`items[${index}].value`)}
                                        placeholder="Enter value*"
                                        rows={1}
                                    ></textarea>

                                    <span className='text-danger f-14'>{errors.items?.[index]?.value?.message}</span>
                                </div>

                                <div className='col-12 col-md-1'>
                                    <label className='f-w-600 f-14 invisible'>{"Action"}</label>

                                    <div
                                        id="elememtDel"
                                        className={index > 0 ? '' : 'cursor_banned '}
                                        onClick={index > 0 ? () => remove(index) : null}
                                        style={{
                                            height: '38px',
                                            width: '38px',
                                            background: 'white',
                                            borderRadius: '8px',
                                            position: 'relative',
                                            border: "1px solid #ced4da"
                                        }}>
                                        <Trash2
                                            height={'18px'} width={'18px'} style={{
                                                position: 'absolute',
                                                top: '25%', left: '25%', transform: 'transform(-50%,-50%)', color: 'red'
                                            }} />
                                        <UncontrolledTooltip placement="bottom" target="elememtDel">
                                            {"Delete Element"}
                                        </UncontrolledTooltip>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-primary-blue' onClick={handleSubmit(onSubmit)}>Create Form</button>
                </ModalFooter>


            </Modal>
        </React.Fragment >
    )
}

export default FormElements