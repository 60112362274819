import { AgoraVideoPlayer, getDevices } from "agora-rtc-react";
import html2canvas from "html2canvas";
import { createRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useScreenshot } from "use-react-screenshot";
import { axiosPostData, getData, postData } from "../../../api/APIinstance";
import {
  generateUserId,
  compressFile,
} from "../../ProjectComponents/Common/Functions/CommonFunctions";
import SweetAlert from "sweetalert2";

export default function Video(props) {
  const {
    users,
    tracks,
    setInCall,
    setStart,
    videoTrackScreenShare,
    client,
    switchedTrack,
  } = props;
  const [enlargeUser, setEnlargeUser] = useState(0);
  const [image, takeScreenshot] = useScreenshot();
  const [status, setStatus] = useState(null);
  const [fileImage, setFileImage] = useState("");
  const { id } = useParams();
  const ref = createRef(null);
  const [floatingTrack, setFloatingTrack] = useState(tracks[1]);
  const [fixedScreenTrack, setFixedScreenTrack] = useState(tracks[1]);
  const [swith, setSwitch] = useState(false);
  const [geolocation, setGeoLocation] = useState();

  const user = localStorage.getItem("user");

  async function takeSSReturn() {
    let iconToHide = document.getElementById("ssIconOnVideoCall");

    let div = document.getElementById("screenshot");
    let canvas = await html2canvas(div);
    let a = document.createElement("a");
    // a.download = "ss.png";
    a.href = canvas.toDataURL("image/png");
    const FileName = generateUserId();
    if (!!a.href) {
      let response = await fetch(a.href, {
        headers: { "Content-Type": "image/png" },
      });
      let fileObject = await response.blob();
      let file = new File([fileObject], `${FileName}`, {
        type: "image/png",
      });
      console.log(
        "file object==============================================================",
        file
      );
      return file;
    }
  }

  function checkLocationPermission() {
    navigator.permissions
      .query({
        name: "geolocation",
      })
      .then(function (result) {
        if (result.state == "granted") {
          console.log("result.state", result.state);
          getImage();
        } else if (result.state == "prompt") {
          console.log("result.state", result.state);
          if (result.state == "granted") {
            getImage();
          }
          if (result.state == "denied") {
            SweetAlert.fire({
              title:
                "Unable to capture location, please enable browser location, refresh & try again !",
              icon: "error",
            });
          }
        } else if (result.state == "denied") {
          SweetAlert.fire({
            title:
              "Unable to capture location, please enable browser location, refresh & try again !",
            icon: "error",
          });
          console.log("result.state", result.state);
        }
      });
  }

  const getImage = async () => {
    let iconToHide = document.getElementById("ssIconOnVideoCall");
    const fileName = generateUserId();
    const ssFile = await takeSSReturn();

    console.log("geolocation", geolocation);
    if (geolocation !== undefined) {
      iconToHide.classList.add("d-none");
      setStatus(null);

      compressFile(ssFile).then(
        async (imgres) => {
          console.log("imgres", imgres);
          const formData = new FormData();
          formData.append("checklist_file", imgres);
          formData.append("fileType", "Image");
          formData.append("withAnswer", "True");
          formData.append("survey", id);
          formData.append("name", fileName);

          formData.append("lat", geolocation.latitude.toFixed(6));

          formData.append("long", geolocation.longitude.toFixed(6));

          formData.append("createdBy", localStorage.getItem("vdi_User_Id"));
          let response = await axiosPostData("CheckListAttachment/", formData);
          toast.success("Screenshot Taken ...", {
            autoClose: 2000,
            theme: "light",

          });

          iconToHide.classList.remove("d-none");
        },
        (imgerr) => {
          console.log("imgerr", imgerr);
        }
      );
    } else {
      toast.error(
        "Unable to capture location, please enable browser location, refresh & try again !",
        {
          autoClose: 4000,
          theme: "light",

        }
      );
    }
  };

  useEffect(() => {
    console.log("users", users);
    console.log("tracks", tracks);
  }, [users, tracks]);

  useEffect(() => {
    console.log("navigator.geolocation", navigator.geolocation);
    navigator.geolocation.getCurrentPosition(async (loc) => {
      console.log("loc", loc);
      setGeoLocation(loc.coords);
    });
  }, []);

  useEffect(async () => {
    console.log("videoTrackScreenShare", videoTrackScreenShare);

    if (videoTrackScreenShare !== null) {
      // console.log("tracks", tracks);
      // tracks[1].close();
      client
        .unpublish(tracks[1])
        .then(() => {
          console.log("Video track unpublished successfully");
        })
        .catch((error) => {
          console.error("Failed to unpublish video track", error);
        });

      if (localStorage.getItem("videoCallMic") == "false") {
        await tracks[0].setEnabled(true);
        await client.publish([tracks[0], videoTrackScreenShare]);
        await tracks[0].setEnabled(false);
      } else {
        await client.publish([tracks[0], videoTrackScreenShare]);
      }
      setFloatingTrack(videoTrackScreenShare);
    } else {
      client
        .unpublish(videoTrackScreenShare)
        .then(() => {
          console.log("Video track unpublished successfully");
        })
        .catch((error) => {
          console.error("Failed to unpublish video track", error);
        });

      if (localStorage.getItem("videoCallMic") == "false") {
        await tracks[0].setEnabled(true);
        await client.publish([tracks[0], tracks[1]]);
        await tracks[0].setEnabled(false);
      } else {
        await client.publish([tracks[0], tracks[1]]);
      }

      setFloatingTrack(tracks[1]);
    }
  }, [videoTrackScreenShare]);

  // useEffect(() => {
  //   if (switchedTrack !== null) {
  //     setFloatingTrack(switchedTrack);
  //     client.unpublish(tracks[1]).then(() => {
  //       console.log('Video track unpublished successfully');
  //     }).catch((error) => {
  //       console.error('Failed to unpublish video track', error);
  //     });
  //     client.publish([tracks[0], switchedTrack]);

  //   } else {
  //     setFloatingTrack(tracks[1]);
  //     client.unpublish(switchedTrack).then(() => {
  //       console.log('Video track unpublished successfully');
  //     }).catch((error) => {
  //       console.error('Failed to unpublish video track', error);
  //     });
  //     client.publish([tracks[0], tracks[1]]);
  //   }
  // }, [switchedTrack]);

  function enlargerScreen(id) {
    console.log("id", id);
    setEnlargeUser(id);
  }

  function maximizeSelectedScreen(val) {
    if (val === 1) {
      setFloatingTrack(users[enlargeUser].videoTrack);
      setSwitch(true);
    } else {
      setFloatingTrack(tracks[1]);
      setSwitch(false);
    }
  }

  function setScreenShareStreamOnFixedScreen(stream) {
    setFloatingTrack(stream);
  }
  const [gridSpacing, setGridSpacing] = useState(12);
  useEffect(() => {
    setGridSpacing(Math.max(Math.floor(12 / (users.length + 1)), 4));
  }, [users, tracks]);
  return (
    <>
      <div
        ref={ref}
        style={{
          background: "grey",
          height: "100%",
          width: "100%",
        }}
        id="screenshot"
      >
        {users.length === 0 ? (
          <AgoraVideoPlayer
            className="vid"
            videoTrack={
              videoTrackScreenShare !== null
                ? videoTrackScreenShare
                : fixedScreenTrack
            }
          />
        ) : swith === false ? (
          <AgoraVideoPlayer
            className={"vid remote_user"}
            videoTrack={users[enlargeUser].videoTrack}
            key={users[enlargeUser].uid}
          // onClick={() => enlargerScreen(users[enlargeUser].uid)}
          />
        ) : (
          <AgoraVideoPlayer className={"vid"} videoTrack={tracks[1]} />
        )}
        {user === "SiteSurveyor" ? null : users.length ? (
          <i
            className="fa fa-camera text-light"
            onClick={() => checkLocationPermission()}
            style={{
              position: "relative",
              top: "-95%",
              left: "90%",
              fontSize: "20px",
              cursor: "pointer",
            }}
            id="ssIconOnVideoCall"
          ></i>
        ) : (
          <i
            className="fa fa-camera text-secondary"
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              fontSize: "20px",
            }}
            id="ssIconOnVideoCall"
          ></i>
        )}
      </div>

      {users.length > 0 ? (
        <div
          // ref={ref}
          // className="row"
          style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            height: "200px",
            width: "200px",
          }}
        >
          {/* <div></div> */}
          <AgoraVideoPlayer
            className="vid"
            videoTrack={floatingTrack}
            onClick={() => {
              maximizeSelectedScreen(swith === false ? 1 : 2);
            }}
          />
        </div>
      ) : null}

      {users.length > 0 ? (
        <div
          className="row"
          id="otherusersrow"
          style={{
            marginTop: "0",
          }}
        >
          {users.length > 0 &&
            users.map((user, i) => {
              if (i !== enlargeUser) {
                if (user.videoTrack) {
                  return (
                    <div className="col-md-4 mb-2 " ref={ref}>
                      <div className="other_users cursor_pointer d-block">
                        <AgoraVideoPlayer
                          className={"vid"}
                          videoTrack={user.videoTrack}
                          key={user.uid}
                          onClick={() => enlargerScreen(i)}
                        />
                      </div>
                    </div>
                  );
                } else return null;
              } else return null;
            })}
        </div>
      ) : null}
    </>
  );
}
