import {
  Home,
  Box,
  DollarSign,
  UserPlus,
  Users,
  Chrome,
  Settings,
  Airplay,
  FolderPlus,
  File,
  ChevronRight,
  Command,
  Cloud,
  Book,
  FileText,
  Server,
  Image,
  Sliders,
  Map,
  GitPullRequest,
  Calendar,
  Edit,
  Mail,
  MessageSquare,
  UserCheck,
  Layers,
  HelpCircle,
  Database,
  Headphones,
  Mic,
  ShoppingBag,
  Search,
  AlertOctagon,
  Lock,
  Briefcase,
  BarChart,
  Target,
  List,
  Package,
  Triangle,
  User,
  ChevronsRight,
} from "react-feather";
import dashboard from "./../../../assets/images/sidebar_images/dashboard.png";
import profile from "./../../../assets/images/sidebar_images/profile.png";
import steering from "./../../../assets/images/sidebar_images/steering.png";
import info from "./../../../assets/images/sidebar_images/info.png";
import soc from "./../../../assets/images/sidebar_images/soc.png";
import settings from "./../../../assets/images/sidebar_images/settings.png";
import globe from "./../../../assets/images/sidebar_images/globe.png";
import survey from "./../../../assets/images/sidebar_images/survey.png";
import com_check from "./../../../assets/images/sidebar_images/com_check.png";
import checklist from "./../../../assets/images/sidebar_images/checklist.png";

export const CUSTOMER_ADMIN_MENUITEMS = [
  {
    title: "Dashboard",
    icon: dashboard,
    path: "/customeradmin/dashboard",
    type: "link",
    active: false,
  },
  {
    title: "User Management",
    icon: profile,
    path: "/customeradmin/vdi_usermaster",
    type: "link",
    active: false,
  },
  {
    title: "Vessel Management",
    icon: steering,
    path: "/customeradmin/vdi_vessel",
    type: "link",
    active: false,
  },
  // {
  //     title: 'Role Management', icon: Settings, path: '/customeradmin/vdi_role', type: 'link', active: false
  // },
  {
    title: "Instructions & Guidelines Management",
    icon: info,
    path: "/customeradmin/vdi_sop",
    type: "link",
    active: false,
  },
  // {
  //     title: 'CheckList Management', icon: Settings, path: '/customeradmin/vdi_checklist', type: 'link', active: false
  // },
  
  
  // {
  //     title: 'Survey Logs', icon: Settings, path: '/customeradmin/vdi_surveylog', type: 'link', active: false
  // },
  {
    title: "SOC Category",
    icon: soc,
    path: "/customeradmin/soc_category",
    type: "link",
    active: false,
  },
  {
    title: "NOC Category",
    icon: settings,
    path: "/customeradmin/noc_category",
    type: "link",
    active: false,
  },
  {
    title: "Multi Level Answers",
    icon: globe,
    path: "/customeradmin/mla",
    type: "link",
    active: false,
  },
  {
    title: "Survey Definition",
    icon: survey,
    path: "/customeradmin/vdi_checklist_new",
    type: "link",
    active: false,
  },
  {
    title: "Survey Assignment",
    icon: com_check,
    path: "/customeradmin/vdi_survey",
    type: "link",
    active: false,
  },
  {
    title: "Non Conformance",
    icon: checklist,
    path: "/customeradmin/nonconformance",
    type: "link",
    active: false,
  },
];
