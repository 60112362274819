import React, { useEffect, useState } from 'react';
import { downloadReportAttachment } from '../../../../api/index';
import { useParams } from 'react-router-dom';

function ImageDownload() {

    const { id } = useParams();
    const [err, setErr] = useState('');

    useEffect(() => {
        console.log("id", id);
        downloadReportAttachment({ Id: id }).then((res) => {
            console.log("res.data.checklist_file", res.data.checklist_file);
            // window.open(res.data.checklist_file, '_blank').focus();
            window.location.href = res.data.checklist_file;
        }, (err) => {

            console.log("err", err);
            setErr("File Not Found");
        });

    }, []);

    return (
        <React.Fragment>
            {err.length > 0 ?
                <p className='text-danger f-22' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>{err}</p>
                : null}
        </React.Fragment>
    )
}

export default ImageDownload