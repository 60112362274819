import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/images/Vedam/NewDILogoPNG.png';
import vedam from '../../../../assets/images/Vedam/vedamL.png'
import { RememberMe, VedamSignIn } from '../../../../constant';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { Button } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Accordion,
    Collapse,
} from "reactstrap";
import '../Css/Common.css';
import { useNavigate } from 'react-router-dom';
import { genOtp } from '../../../../api/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'sweetalert2'
import { postData } from '../../../../api/APIinstance';
import { getAuth, createUserWithEmailAndPassword, setPersistence, signInWithEmailAndPassword } from "firebase/auth";

const schema = yup.object().shape({
    username: yup.string().required('Username is required !'),
    password: yup.string().required('Password is required !'),
}).required();



function SigninUI() {
    const [modal, setModal] = useState();
    const [disableLoginBtn, setDisableLoginBtn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const TandC = async (event) => {
        let param = {
            user: localStorage.getItem("vdi_User_Id"),
            customer: localStorage.getItem("customerId"),
            termsAgreed: true
        }
        let response = await postData("TermsAndConditions/", param)
        if (response.status == 201) {
            window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`;
        }
    }





    const toggle3 = (event) => {
        setModal(!modal);
        event.stopPropagation();
    };

    let navigate = useNavigate();

    const [authErr, setAuthErr] = useState('');


    // username : user for customeradmin
    // username : admin for superadmin

    const { register, handleSubmit, formState, getValues } = useForm({
        resolver: yupResolver(schema), mode: "onChange", defaultValues: {
            username: '',
            password: ''
        }
    }); // initialise the hook

    const { errors } = formState;



    useEffect(() => {
        // using this in reset password component
        localStorage.setItem("timer", "300");
        localStorage.setItem("otpCount", "1");
    }, []);

    const onSubmit = async data => {
        setDisableLoginBtn(true);

        if (data !== '') {
            console.log("data", data);

            const apiUrl = `${process.env.REACT_APP_API_URL + VedamSignIn}`
            console.log("apiUrl", apiUrl);

            await axios.post(apiUrl, data)
                .then(async (res) => {
                    console.log("res", res);


                    // setting token to localstorage
                    localStorage.setItem('access', res.data.token);
                    localStorage.setItem('user', res.data.userGroups[0].groupName);
                    localStorage.setItem('user_id', res.data.user_id);
                    localStorage.setItem('vdi_User_Id', res.data.vdi_User_Id);
                    localStorage.setItem('customerId', res.data.customerId);
                    localStorage.setItem('userlogo', res.data.customerData?.logo);

                    const auth = getAuth();

                    let letLogin = true;
                    console.log("res.data.vdi_User_Id", res.data.vdi_User_Id);
                    const passwordForFirebaseAuth = "vedam_" + res.data.vdi_User_Id;

                    var firebaseEmail = res.data.emailId.split("@");
                    firebaseEmail = firebaseEmail[0] + "_" + res.data.vdi_User_Id + "@" + firebaseEmail[1]
                    var firebasePassword = passwordForFirebaseAuth;
                    console.log("firebaseEmail", firebaseEmail);
                    console.log("firebasePassword", firebasePassword);

                    await createUserWithEmailAndPassword(auth, firebaseEmail, firebasePassword)
                        .then((userCredential) => {
                            const user = userCredential.user;

                            
                            console.log("user", user);
                            localStorage.setItem("firebase_user_UID", user.uid);
                            letLogin = true;
                            localStorage.setItem("firebaseAuthFail", false);

                        })
                        .catch(async (error) => {
                            setDisableLoginBtn(false);
                            const errorCode = error.code;
                            const errorMessage = error.message;

                            console.log("errorCode", errorCode);
                            console.log("errorMessage", errorMessage);



                            if (errorCode == "auth/weak-password") {
                                setAuthErr('Password too weak, change your password !');
                                letLogin = true;
                                console.log("weak password error firebase");
                                return
                            } else if (errorCode == "auth/email-already-in-use") {
                                setAuthErr('');

                                await signInWithEmailAndPassword(auth, firebaseEmail, firebasePassword)
                                    .then((userCredential) => {

                                        setDisableLoginBtn(false);
                                        const user = userCredential.user;

                                        console.log("user", user);
                                        letLogin = true;
                                        localStorage.setItem("firebaseAuthFail", false);

                                    })
                                    .catch((error) => {
                                        setDisableLoginBtn(false);
                                        const errorCode = error.code;
                                        const errorMessage = error.message;
                                        letLogin = true;

                                        localStorage.setItem("firebaseAuthFail", true);
                                        console.log("errorCode", errorCode);
                                        console.log("errorMessage", errorMessage);
                                    });

                            }

                        });

                    if (letLogin) {
                        console.log("aoidasiodj ioasj ");
                        if (res.status === 200 || res.status === 201) {


                            if (res.data.userGroups[0].groupName === "customeradmin") {

                                if (res.data.customerData.status === "Pending") {

                                    SweetAlert.fire({
                                        title: "Account not approved !",
                                        text: "Please try logging in later!",
                                        icon: "info",
                                    });

                                } else {
                                    localStorage.setItem(
                                        "login_data",
                                        JSON.stringify({
                                            customer_id: res.data.customerId,
                                            user: res.data.vdi_User_Id,
                                            userId: res.data.user_id,
                                            FirstName: res.data.FirstName,
                                            LastName: res.data.LastName,
                                            emailId: res.data.emailId
                                        }));
                                    localStorage.setItem("customer_id", res.data.customerId);
                                    localStorage.setItem("name", res.data.userGroups[0].groupName);
                                    localStorage.setItem("usersname", res.data.FirstName);

                                    localStorage.setItem('subscription', JSON.stringify(res.data.subscriptionPlan))

                                    window.location.href = `${process.env.PUBLIC_URL}/customeradmin/dashboard`;
                                    setDisableLoginBtn(false);

                                }


                            } else if (res.data.userGroups[0].groupName === "superadmin_approver"
                                || res.data.userGroups[0].groupName === "superadmin_executive") {
                                console.log("superadmin");
                                window.location.href = `${process.env.PUBLIC_URL}/admin/vdi_customer`;
                                setDisableLoginBtn(false);
                                localStorage.setItem("name", res.data.userGroups[0].groupName);
                                localStorage.setItem("usersname", res.data.FirstName);

                            } else if (res.data.userGroups[0].groupName === "Verifier") {
                                if (res.data.isActive == true) {
                                    localStorage.setItem(
                                        "login_data",
                                        JSON.stringify({
                                            customer_id: res.data.customerId,
                                            user: res.data.vdi_User_Id,
                                            userId: res.data.user_id,
                                            FirstName: res.data.FirstName,
                                            emailId: res.data.emailId
                                        }));

                                    localStorage.setItem("customer_id", res.data.customerId);

                                    localStorage.setItem("name", res.data.userGroups[0].groupName);
                                    localStorage.setItem("usersname", res.data.FirstName);
                                    if (res?.data?.userTerms?.length !== 0) {
                                        window.location.href = `${process.env.PUBLIC_URL}/verifier/vessels`;
                                        setDisableLoginBtn(false);
                                    } else {
                                        toggle3((event) => toggle3(event));
                                    }

                                } else {
                                    setAuthErr('User is inactive');
                                }

                            } else if (res.data.userGroups[0].groupName === "Surveyor") {
                                if (res.data.isActive == true) {
                                    localStorage.setItem(
                                        "login_data",
                                        JSON.stringify({
                                            customer_id: res.data.customerId,
                                            user: res.data.vdi_User_Id,
                                            userId: res.data.user_id,
                                            FirstName: res.data.FirstName,
                                            emailId: res.data.emailId
                                        }));
                                    localStorage.setItem("name", res.data.userGroups[0].groupName);
                                    localStorage.setItem("usersname", res.data.FirstName);
                                    localStorage.setItem('subscription', JSON.stringify(res.data.subscriptionPlan))

                                    console.log("-------should run-----");
                                    // navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
                                    if (res?.data?.userTerms?.length !== 0) {
                                        window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`;
                                        setDisableLoginBtn(false);
                                    } else {
                                        toggle3((event) => toggle3(event));
                                    }
                                } else {
                                    setAuthErr('User is inactive');
                                }
                                // dispatch({type: WATCH_LOGIN_LIST})
                            } else if (res.data.userGroups[0].groupName === "SiteSurveyor") {
                                localStorage.setItem("GuestVideoCall", false);
                                if (res.data.isActive == true) {
                                    localStorage.setItem(
                                        "login_data",
                                        JSON.stringify({
                                            customer_id: res.data.customerId,
                                            user: res.data.vdi_User_Id,
                                            userId: res.data.user_id,
                                            FirstName: res.data.FirstName,
                                            emailId: res.data.emailId
                                        }));
                                    localStorage.setItem("name", res.data.userGroups[0].groupName);
                                    localStorage.setItem("usersname", res.data.FirstName);
                                    localStorage.setItem('subscription', JSON.stringify(res.data.subscriptionPlan))

                                    if (res?.data?.userTerms?.length !== 0) {
                                        window.location.href = `${process.env.PUBLIC_URL}/sitecoordinator/vessels`;
                                        setDisableLoginBtn(false);
                                    } else {
                                        toggle3((event) => toggle3(event));
                                    }
                                } else {
                                    setAuthErr('User is inactive');
                                }
                            }




                        }
                    } else {
                        console.log("inside else----------------");
                    }
                   

                })
                .catch(err => {
                    setDisableLoginBtn(false);
                    console.log("err", err);
                    console.log("err.response", err.response);
                    if (err.response.status === 401) {
                        setAuthErr('Invalid username or password !');


                    } else if (err.response.status === 403) {



                        genOtp({ email: data.username }).then((res) => {
                            console.log("res", res);

                            navigate(`${process.env.PUBLIC_URL}/resetpassword`, { state: data.username });

                        }, (err) => {
                            console.log("err", err.response);

                            if (err.response.data.error_details.includes("not verified")) {

                                navigate(`${process.env.PUBLIC_URL}/resetpassword`, { state: data.username });

                            }
                        })

                    } else {
                        SweetAlert.fire({
                            title: "Something went wrong !",
                            text: "Please try after some time.",
                            icon: "error",
                        });
                    }


                })

        } else {
            errors.showMessages();
        }
    };


    function forgetPassword() {
        const data = getValues();

        genOtp({ email: data.username }).then((res) => {
            console.log("res", res);

            navigate(`${process.env.PUBLIC_URL}/resetpassword`, { state: data.username });

        }, (err) => {
            console.log("err", err.response);

            if (err.response.data.error_details.includes("not verified")) {

                navigate(`${process.env.PUBLIC_URL}/resetpassword`, { state: data.username });

            }
        })
    }


    return (
        <React.Fragment>


            <div className="page-wrapper" id='vedam_signin'>
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center"><img src={logo} alt="" style={{ height: '140px', width: '140px' }} /></div>

                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <p className='f-28 mb-0'>{'Sign In'}</p>
                                                    <p className='f-14 text-muted'>
                                                        {"Enter your Username and Password"}
                                                    </p>
                                                </div>
                                                <form className=" mt-4">
                                                    <div className="form-group">

                                                        <label className="col-form-label f-w-600 pt-0">{"Username"}</label>

                                                        <input
                                                            className={errors.username ? "form-control is-invalid" : "form-control"}
                                                            type="text"
                                                            placeholder='Enter your username*'
                                                            name='username'
                                                            {...register('username')}
                                                        // onChange={() => setAuthErr('')}
                                                        />

                                                        <span className='text-danger'>{errors.username?.message}</span>
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="col-form-label f-w-600">{"Password"}</label>
                                                        <div className="input-group">
                                                            <input
                                                                className={errors.password ? "form-control is-invalid" : "form-control"}
                                                                type={showPassword ? "text" : "password"}
                                                                placeholder='Enter your password*'
                                                                name='password'
                                                                {...register('password')}
                                                            // onChange={() => setAuthErr('')}
                                                            />
                                                            {showPassword ?
                                                                <span className="input-group-text">
                                                                    <i className="fa cursor_pointer fa-eye-slash"
                                                                        onClick={() => setShowPassword(false)}></i>
                                                                </span>
                                                                :
                                                                <span className="input-group-text">
                                                                    <i className="fa cursor_pointer fa-eye"
                                                                        onClick={() => setShowPassword(true)}></i>
                                                                </span>
                                                            }

                                                        </div>
                                                        <p
                                                            className="mb-0 forgot_password cursor_pointer"
                                                            style={{ fontSize: '12px', color: '#2264ab' }}
                                                            onClick={() => {
                                                                navigate(`${process.env.PUBLIC_URL}/forgotpassword`);
                                                            }}>Forgot Password ?</p>

                                                        <span className='text-danger'>{errors.password?.message}</span>
                                                    </div>

                                                    {authErr.length > 0 ?
                                                        <div className='d-flex'>
                                                            <p className='text-danger mb-0 f-w-600'>{authErr}</p>
                                                            {/* <p onClick={forgetPassword} id='forgetPassword' className='cursor_pointer ms-1'>{"Forgotten Password?"}</p> */}
                                                        </div>
                                                        : null}


                                                    {/* <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">{RememberMe}</label>
                                                    </div> */}


                                                    <div className="form-group form-row mt-4 mb-0 ">
                                                        <button
                                                            disabled={disableLoginBtn}
                                                            onClick={handleSubmit(onSubmit)}
                                                            style={{ fontSize: '16px', position: "relative" }}
                                                            className="btn btn-primary-blue btn-block w-100">{disableLoginBtn ?
                                                                 <Spinner animation="border" variant="light" role="status" size='sm' />
                                                               
                                                                : `Sign In`}


                                                            {disableLoginBtn ? <span className="rotate dashed"
                                                                style={{
                                                                    maxHeight: '20px',
                                                                    maxWidth: '20px', position: 'absolute',
                                                                    top: '-8px', left: '85%',
                                                                    transform: 'translate(-50%,-50%)'
                                                                }}></span> : null}
                                                        </button>


                                                    </div>

                                                </form>

                                                <p className='mb-0 text-center text-muted f-12 mt-2'>{`Version ${process.env.REACT_APP_BUILD_VERSION}`}</p>
                                            </div>

                                            {/* <span className="rotate dashed bg-danger" style={{ maxHeight: '20px', maxWidth: '20px' }}></span> */}

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}

                </div>

                <div className='d-flex justify-content-end' style={{ position: 'absolute', bottom: '0', right: '10px' }}>
                    <p className='f-16 mb-0'>Powered By </p>
                    <img src={vedam} className='ms-1' style={{ height: '20px', width: '85px' }} />
                </div>


            </div>
            <Modal isOpen={modal} toggle={(event) => toggle3(event)}>
                <div
                    toggle={(event) => toggle3(event)}
                    style={{
                        background: "#2264AB",
                        padding: "12px 24px",
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <span
                        className="text-light my-1"
                        style={{
                            fontSize: "20px",
                        }}
                    >
                        {`Reference`}
                    </span>
                </div>
                <ModalBody>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Natus tempore ut excepturi veniam. Hic eveniet quaerat voluptatem omnis doloremque reprehenderit quisquam deserunt facilis sint. Natus quidem fugiat perferendis molestiae laudantium?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={(event) => TandC(event)}>
                        Accept
                    </Button>
                    <Button color="primary" onClick={(event) => toggle3(event)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default SigninUI