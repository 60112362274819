import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  survey,
  checklistTable,
  checklistItemAttachmentv3,
} from "../../../../constant/index";
import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import logo from "../../../../assets/images/Vedam/NewDILogoPNG100.png";
import VideoPlay from "../../../../assets/images/Vedam/VideoPlay.jpg";
import moment from "moment";
import RobotoRegular from "../../../../assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoRegular700 from "../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import { convertUrl } from "../../Common/Functions/CommonFunctions";
import { version } from "../../../../constant/index";

Font.register({
  family: "Roboto, sans-serif",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoRegular700,
      fontWeight: "bold",
    },
  ],
});

function ReportForApp() {
  const [recording, setRecording] = useState([]);
  const [surveyResponse, setSurveyResponse] = React.useState([]);
  const [Sop, setSop] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [perSID, setPerSID] = useState({ data: "" });
  const [tableList, setTableList] = useState({ data: [], loading: false });
  const [checklistForm, setChecklistForm] = useState({
    data: [],
    loading: false,
  });

  const [refImages, setRefImages] = React.useState({
    data: [],
    loading: false,
  });

  const { sid, custid, token, cid } = useParams();

  useEffect(async () => {
    console.log("token", token);
    console.log("cid", atob(cid));
    const survey_id = atob(sid);
    perSID.data = atob(sid);
    setPerSID({ ...perSID, data: perSID.data });

    const customer_id = atob(custid);
    const jwtToken = atob(token);
    console.log("survey_id", survey_id);
    console.log("customer_id", customer_id);
    setLoader(true);

    const config = {
      headers: { Authorization: `Bearer ${jwtToken}` },
    };

    const apiUrl = `${
      process.env.REACT_APP_API_URL + survey
    }?customer_id=${customer_id}&Id=${survey_id}`;

    await axios
      .get(apiUrl, config)
      .then(async (res) => {
        console.log("res", res);

        setSurveyResponse(res.data.res);

        await axios
          .get(
            `${process.env.REACT_APP_API_URL}${version}GetSOPById/?Id=${res.data.res[0].SOP.Id}`,
            config
          )
          .then((resp) => {
            console.log("resp", resp);
            setSop(resp.data.res);
          })
          .catch((err) => {
            console.log("err", err);
          });
      })
      .catch((err) => {
        console.log("err", err);
      });

    await axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }${version}SurveyRecordings/?survey=${atob(sid)}`,
        config
      )
      .then(
        (response) => {
          console.log("reponse", response);

          const temp = response.data.filter((val) => {
            return val.checkListItem !== null;
          });
          setRecording(temp);
        },
        (err) => {
          console.log("err", err);
        }
      );

    setChecklistForm({ ...checklistForm, loading: true, data: [] });
    await fetchChecklistForm();
    await fetchTables();
    await fetchRefImages();
    setLoader(false);
  }, []);

  //vedambackend.technicious.in/api/v3/CheckListAttachment/?fileType=Image&withAnswer=False&withSurveyOnly=True&survey=358&withTable=False&isRef=True

  async function fetchRefImages() {
    const config = {
      headers: { Authorization: `Bearer ${atob(token)}` },
    };
    setRefImages({ loading: true, data: [] });
    await axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }${"api/v3/"}CheckListAttachment/?fileType=Image&withAnswer=False&withSurveyOnly=True&survey=${atob(
          sid
        )}&withTable=False&isRef=True`,
        config
      )
      .then(
        (res) => {
          console.log("res", res);
          if (res.data.length > 0) {
            setRefImages({ ...refImages, loading: false, data: res.data });
          } else {
            setRefImages({ ...refImages, loading: false, data: [] });
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
  }

  function fetchTables() {
    setTableList({ ...tableList, data: [], loading: true });

    const config = {
      headers: { Authorization: `Bearer ${atob(token)}` },
    };

    const tabUrl = `${
      process.env.REACT_APP_API_URL + checklistTable
    }?pageNo=1&entriesPerPage=1000&customer_id=${atob(custid)}&checklist=${atob(
      cid
    )}&survey=${atob(sid)}&withSurvey=True`;

    axios.get(tabUrl, config).then(
      (res) => {
        console.log("res", res);
        if (res.data.payload.length > 0) {
          if (res.data.payload[0].file.length > 0) {
            const tabImageUrl = `${
              process.env.REACT_APP_API_URL + checklistItemAttachmentv3
            }?pageNo=1&entriesPerPage=1000&customer_id=${atob(
              custid
            )}&checklist=${atob(cid)}&survey=${atob(sid)}&withSurvey=True`;

            axios.get(tabImageUrl, config).then(
              (attRes) => {
                console.log("attRes", attRes);
                if (attRes.data.length > 0 && res.data.payload.length > 0) {
                  const tempTableList = res.data.payload[0];

                  for (let i = 0; i < tempTableList.file.length; i++) {
                    for (let j = 0; j < attRes.data.length; j++) {
                      console.log(
                        `${tempTableList.file[i].TableId} ---- ${attRes.data[j].TableId}`
                      );
                      if (
                        tempTableList.file[i].TableId == attRes.data[j].TableId
                      ) {
                        tempTableList.file[i].tableImage =
                          attRes.data[j].checklist_file;
                      }
                      // else {
                      //   tempTableList.file[i].tableImage = "";
                      // }
                    }
                  }
                  console.log("tempTableList", tempTableList);
                  setTableList({
                    ...tableList,
                    data: tempTableList,
                    loading: false,
                  });
                } else {
                  setTableList({
                    ...tableList,
                    data: res.data.payload[0],
                    loading: false,
                  });
                }
              },
              (attErr) => {
                console.log("attErr", attErr);
              }
            );
          }
        } else {
          const tab2Url = `${
            process.env.REACT_APP_API_URL + checklistTable
          }?pageNo=1&entriesPerPage=1000&customer_id=${atob(
            custid
          )}&checklist=${atob(cid)}`;
          axios.get(tab2Url, config).then(
            (res) => {
              console.log("res", res);
              if (res.data.payload.length > 0) {
                if (res.data.payload[0].file.length > 0) {
                  setTableList({
                    ...tableList,
                    data: res.data.payload[0],
                    loading: false,
                  });
                }
              }
            },
            (err) => {
              console.log("err", err);
            }
          );
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchChecklistForm() {
    const jwtToken = atob(token);
    const config = {
      headers: { Authorization: `Bearer ${jwtToken}` },
      // headers: { Authorization: `Bearer ${token}` }
    };
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v2/FormItem/?survey=${atob(
          sid
        )}&withSurvey=True`,
        config
      )
      .then(
        (res) => {
          console.log("res.data", res.data);
          if (res.data.payload.length > 0) {
            setChecklistForm({
              ...checklistForm,
              loading: false,
              data: res.data.payload[0],
            });
          }

          if (res.data.payload.length === 0) {
            axios
              .get(
                `${
                  process.env.REACT_APP_API_URL
                }api/v2/FormItem/?checklist=${atob(cid)}&withSurvey=False`,
                config
              )
              .then(
                (res) => {
                  console.log("res.data", res.data);
                  if (res.data.payload.length > 0) {
                    setChecklistForm({
                      ...checklistForm,
                      loading: false,
                      data: res.data.payload[0],
                    });
                  }
                },
                (err) => {
                  console.log("err", err);
                  setChecklistForm({
                    ...checklistForm,
                    loading: false,
                    data: [],
                  });
                }
              );
          }
        },
        (err) => {
          console.log("err", err);
          setChecklistForm({ ...checklistForm, loading: false, data: [] });
        }
      );
  }

  function downloadReport() {
    const downloadlink = document.getElementsByTagName("a");
    console.log("downloadlink", downloadlink);
    downloadlink.click();
  }

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      color: "black",
      fontFamily: "Roboto, sans-serif",
      padding: "20px",

      // height: "80%",
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    // text: {
    //   padding: 20,
    //   paddingRight: 20,
    // },
    pages: {
      position: "absolute",
      top: "95%",
      left: "50%",
    },
    fristImage: {
      width: 400,
      height: 200,
    },

    secondPage: {
      display: "flex",
      position: "relative",
      padding: 10,
    },
    secondImage: {
      // right:'50%',
      top: "20%",
      width: 200,
      height: 200,
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      marginBottom: "10px",
    },
    tableRow: {
      flexDirection: "row",
      borderBottom: "1px solid grey",
    },

    tableHeader: {
      color: "black",
      fontSize: 12,
      // fontWeight: "bold",
    },
    secondText: {
      left: "45%",
      bottom: "40%",

      width: 200,
      // height: 150,
    },
    container: {
      flexDirection: "row",
      flexWrap: "wrap",
      //   justifyContent: "space-between",
      //   margin: 10,
    },
    image: {
      width: "23%",
      marginBottom: 10,
    },
    title: {
      fontSize: 10,
      textAlign: "center",
    },
    color: {
      color: "red",
    },
    header: {
      height: 100,
      // backgroundColor: "red",
    },
    heade_innerr: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    logoImage: {
      width: "100px",
    },
    btm: {
      // marginBottom: 200,
      flex: 1,
    },
    questionContainer: {
      display: "flex",
      flexDirection: "row",

      border: "1px solid gray",
      height: "auto",
      marginHorizontal: 10,
      marginTop: 10,

      content: "",
      clear: "both",
    },

    questionIndex: {
      fontSize: 10,
      textAlign: "center",
    },
    questionIndexContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      width: "10%",
      borderRight: "1px solid gray",
    },
    question: {
      fontSize: 11,
      wordSpacing: 50,
      lineHeight: 1.5,
    },

    questionsContainer: {
      float: "left",
      padding: " 10px",
      width: "75%",
    },
    questionResult: {
      fontSize: 10,
      textAlign: "center",
    },

    questionResultContainer: {
      borderLeft: "1px solid gray",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      width: "15%",
    },
    comment: {
      border: "1px solid gray",
      marginLeft: 10,
      marginRight: 10,
    },
  });

  const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            height: "8%",
            padding: "0px 15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            border: "1px solid gray",
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
          fixed
        >
          <View>
            <Image
              style={{ width: "50px" }}
              src={
                surveyResponse[0]?.user?.customer?.logo === null
                  ? logo
                  : surveyResponse[0]?.user?.customer?.logo
              }
            />
          </View>

          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: "14px", fontWeight: "600" }}>
              Vessel Name
            </Text>
            <Text style={{ fontSize: "12px" }}>
              {surveyResponse[0]?.vessel?.name}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: "14px", fontWeight: "600" }}>
              Report No.
            </Text>
            <Text style={{ fontSize: "12px" }}>
              {surveyResponse[0]?.surveyNo}
            </Text>
          </View>
        </View>

        <View
          style={[
            styles.btm,
            {
              borderLeft: "1px solid gray",
              borderRight: "1px solid gray",
              borderBottom: "1px solid gray",
              paddingTop: "10px",
            },
          ]}
          wrap
        >
          <View
            style={{
              padding: "0px 15px",
              justifyContent: "space-between",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <View style={{ width: "50%" }}>
              <Text
                style={{
                  fontSize: "18px",
                  marginBottom: "10px",
                  fontWeight: 700,
                  fontWeight: "600",
                }}
              >
                Vessel Details
              </Text>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Vessel Name:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {surveyResponse[0]?.vessel?.name}
                </Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Vessel Type:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {surveyResponse[0]?.vessel?.type.name}
                </Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Unique No:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {surveyResponse[0]?.vessel?.uniqueNumber == "null"
                    ? "Not Available"
                    : surveyResponse[0]?.vessel?.uniqueNumber}
                </Text>
              </View>

              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  IMO No:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {surveyResponse[0]?.vessel?.number}
                </Text>
              </View>

              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  GT:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {surveyResponse[0]?.vessel?.GT}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: "18px",
                  marginBottom: "10px",
                  fontWeight: "600",
                }}
              >
                Survey Details
              </Text>
              <View style={{ display: "flex", flexDirection: "row" }}>
                {" "}
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Survey No:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {surveyResponse[0]?.surveyNo}
                </Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                {" "}
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Survey Name:
                </Text>
              </View>
              <View>
                {" "}
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                  }}
                >
                  {surveyResponse[0]?.checklist?.name}
                </Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Assigned Surveyor Name:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {surveyResponse[0]?.user?.name}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  ACTUAL Survey Start Date & Time:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                  }}
                >
                  {
                    surveyResponse[0]?.image_survey.length > 0
                      ? new Date(
                          surveyResponse[0]?.image_survey[0]?.createdDate
                        ).toLocaleString()
                      : ""
                    // new Date(surveyResponse[0]?.startDate).toLocaleString()
                  }

                  {/* {new Date(
                        surveyResponse[0]?.startDate.split("-").join()
                        ).toLocaleDateString("en-au")} */}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  ACTUAL Completion Date & Time:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {/* {new Date(
                    surveyResponse[0]?.endDate.split("-").join()
                  ).toLocaleDateString("en-au")} */}
                  {
                    surveyResponse[0]?.survey_signature.length > 0
                      ? new Date(
                          surveyResponse[0]?.survey_signature[0]?.createdDate
                        ).toLocaleString()
                      : ""
                    // new Date(surveyResponse[0]?.endDate).toLocaleString()
                  }
                </Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    fontWeight: "600",
                  }}
                >
                  Lat - Long:
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    paddingBottom: "4px",
                    marginLeft: "5px",
                  }}
                >
                  {`${surveyResponse[0]?.lat} - ${surveyResponse[0]?.long}`}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              padding: "10px 15px",
              marginBottom: "20px",
            }}
          >
            <View
              style={{
                marginBottom: "10px",
              }}
            >
              <Text
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                Surveyor Name & Selfie :{" "}
                {surveyResponse[0]?.image_survey.length > 0
                  ? surveyResponse[0]?.image_survey[0]?.name
                  : null}
              </Text>
            </View>

            {surveyResponse[0]?.image_survey[0]?.image !== null ||
            surveyResponse[0]?.image_survey[0]?.image !== undefined ||
            surveyResponse[0]?.image_survey.length > 0 ? (
              <View style={[styles.container]}>
                {surveyResponse[0]?.image_survey.length > 0
                  ? surveyResponse[0]?.image_survey.map((val, i) => (
                      <View key={i} style={styles.image}>
                        <Image
                          style={{ height: "100px", marginRight: "5px" }}
                          src={convertUrl(
                            surveyResponse[0]?.image_survey[i]?.image
                          )}
                        />
                        <Text style={[styles.title, { marginTop: "5px" }]}>
                          {val.name}
                        </Text>
                      </View>
                    ))
                  : null}
              </View>
            ) : null}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
                paddingTop: "20px",
              }}
            >
              <Text
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Instructions & Guidelines :
              </Text>
              <Text
                style={{
                  fontSize: "15px",
                  marginLeft: "5px",
                }}
              >
                {Sop?.SOP.name}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "4px",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Description :
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                }}
              >
                {Sop?.SOP.description}
              </Text>
            </View>
            {Sop?.SOPLineItems?.map((el, i) => {
              return (
                <View key={i}>
                  <Text
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {el.sequence}. {el.instruction}{" "}
                  </Text>
                </View>
              );
            })}
          </View>

          <View
            style={{
              padding: "0px 10px 10px 10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            {refImages.data.length > 0 ? (
              <React.Fragment>
                <Text>Reference Images :</Text>
                {refImages.data.map((refimg, refi) => (
                  <Image
                    src={convertUrl(refimg.checklist_file)}
                    style={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                    key={refi}
                  ></Image>
                ))}
              </React.Fragment>
            ) : null}
          </View>

          {checklistForm.data.file?.length > 0 ? (
            <React.Fragment>
              <View style={{ borderTop: "1px solid gray" }}></View>
              <View
                style={{
                  // borderLeft: '1px solid gray',
                  // borderRight: '1px solid gray',
                  padding: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "13",
                    paddingBottom: 10,
                    fontWeight: "600",
                  }}
                >
                  Form Section
                </Text>

                {checklistForm.data.file.map((val, vali) => (
                  <React.Fragment key={vali}>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                    >
                      {val.title}
                    </Text>
                    <View style={styles.table}>
                      <View style={[styles.tableRow, styles.tableHeader]}>
                        <View
                          style={{
                            flexBasis: "40%",
                            flexGrow: "1",
                            padding: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <Text>{"Name"}</Text>
                        </View>
                        <View
                          style={{
                            flexBasis: "60%",
                            flexGrow: "1",
                            padding: "10px",
                            borderLeft: "1px solid black",
                            fontWeight: "bold",
                          }}
                        >
                          <Text>{"Value"}</Text>
                        </View>
                      </View>
                      {val.values?.map((val, i) => (
                        <View
                          key={i}
                          style={[styles.tableRow, styles.tableHeader]}
                        >
                          <View
                            style={{
                              flexBasis: "40%",
                              flexGrow: "1",
                              padding: "10px",
                            }}
                          >
                            <Text>{val.key}</Text>
                          </View>
                          <View
                            style={{
                              flexBasis: "60%",
                              flexGrow: "1",
                              padding: "10px",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text>{val.value}</Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  </React.Fragment>
                ))}
              </View>
              <View style={{ borderTop: "1px solid gray" }}></View>
            </React.Fragment>
          ) : null}

          {tableList.data.file?.length > 0 ? (
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "600",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              Table Section
            </Text>
          ) : null}

          {tableList.data.file?.length > 0
            ? tableList.data.file.map((tab, tabi) => (
                <View key={tabi} style={{ padding: "10px" }}>
                  <Text
                    style={{
                      paddingBottom: "6px",
                      fontSize: "13px",
                      fontWeight: "600",
                    }}
                  >
                    {tab.tableName}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {tab.th.map((thval, thi) => (
                      <View
                        key={thi}
                        style={{
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            padding: "5px",
                          }}
                        >
                          {thval.colName}
                        </Text>
                      </View>
                    ))}
                  </View>

                  {tab.tr.map((trval, tri) => (
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "1px solid black",
                      }}
                      key={tri}
                    >
                      {trval.row.map((v, vi) => (
                        <View
                          key={vi}
                          style={{
                            width: "50%",
                            borderLeft: "1px solid black",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "10px",
                              padding: "5px",
                            }}
                          >
                            {" "}
                            {v.value}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ))}

                  <View
                    style={{
                      marginTop: "5px",
                      paddingBottom: "6px",
                      fontSize: "12px",
                    }}
                  >
                    <Text style={{ fontWeight: "600" }}>
                      {" "}
                      Accepted :
                      <Text style={{ fontWeight: "400" }}>{tab.answer}</Text>
                    </Text>
                  </View>

                  {tab.tableImage !== undefined ? (
                    // tab.tableImage.length > 0 ? (
                    <Image
                      src={convertUrl(tab.tableImage)}
                      style={{
                        width: "100%",
                      }}
                    ></Image>
                  ) : // ) : null
                  null}
                </View>
              ))
            : null}

          {surveyResponse[0]?.checklist?.checklist_sections.map((data, i) => {
            return (
              <React.Fragment key={i}>
                <View style={{ margin: 10 }}>
                  <Text style={{ fontSize: "12", fontWeight: "bold" }}>
                    {data?.sectionId}. {data?.sectionName}
                  </Text>
                </View>
                {data?.section_checklist_item.map((el, i) => {
                  return (
                    <React.Fragment key={i}>
                      <View style={styles.questionContainer}>
                        <View style={styles.questionIndexContainer}>
                          <Text style={styles.questionIndex}>{el?.SrNo}.</Text>
                        </View>
                        <View style={styles.questionsContainer}>
                          <Text style={styles.question}>{el?.section}</Text>
                        </View>
                        <View style={styles.questionResultContainer}>
                          <Text style={styles.questionResult}>
                            {
                              el?.checklist_item_result
                                ?.filter((ans, ind) => {
                                  return ans.survey == atob(sid);
                                })
                                .pop()?.values
                            }
                          </Text>
                        </View>
                        {/* <View style={styles.questionResultContainer}>
                                                        <Text style={styles.questionResult}>
                                                            {
                                                                el?.checklist_item_result[
                                                                    el?.checklist_item_result.length - 1
                                                                ]?.values
                                                            }
                                                        </Text>
                                                    </View> */}
                      </View>
                      <View style={styles.comment}>
                        <View style={{ margin: "10px" }}>
                          <Text
                            style={{
                              fontSize: "10",

                              color: "red",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: "13",
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              Comment :
                            </Text>{" "}
                            {/* {
                                                                el?.checklist_item_result[
                                                                    el?.checklist_item_result.length - 1
                                                                ]?.remarks
                                                            } */}
                            {
                              el?.checklist_item_result
                                ?.filter((ans, ind) => {
                                  return ans.survey == atob(sid);
                                })
                                .pop()?.remarks
                            }
                          </Text>
                        </View>
                      </View>
                      <View>
                        <View
                          style={{
                            // margin: 10,
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              width: "100%",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: "13",
                                paddingBottom: 10,
                                margin: "10px",
                                fontWeight: "600",
                              }}
                            >
                              Image
                            </Text>
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "space-between",
                                flexWrap: "wrap",
                                width: "100%",
                              }}
                            >
                              {el?.check_list_attachment.map((item, i) => {
                                // console.log("insider", item);
                                return (
                                  <React.Fragment key={i}>
                                    {item.fileType == "Image" &&
                                    parseInt(item?.survey) ===
                                      parseInt(perSID.data) &&
                                    item?.withAnswer ? (
                                      <View
                                        style={{
                                          // float: "left",
                                          // display: "block",
                                          width: "95px",
                                          height: "70px",
                                          // marginTop: "5px",
                                          margin: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "contain",
                                          }}
                                        >
                                          <Link
                                            // src={item.checklist_file}
                                            src={`${process.env.REACT_APP_DOMAIN}download / ${item?.Id}`}
                                            style={{
                                              fontSize: 12,
                                              color: "blue",
                                            }}
                                          >
                                            <Image
                                              src={convertUrl(
                                                item?.checklist_file
                                              )}
                                              style={{
                                                width: "100%",
                                                // margin: "10px",
                                                // height: "70px",
                                              }}
                                            />
                                          </Link>

                                          <Text
                                            style={{
                                              textAlign: "center",
                                              fontSize: "10px",
                                              marginBottom: "2px",
                                            }}
                                          >
                                            {moment(item?.created).format(
                                              "DD-MM-YYYY HH:mm"
                                            )}
                                          </Text>

                                          <Text
                                            style={{
                                              textAlign: "center",
                                              fontSize: "7px",
                                            }}
                                          >
                                            {item?.lat == null &&
                                            item?.long == null
                                              ? "NA-NA"
                                              : `${item?.lat} - ${item?.long}`}
                                          </Text>
                                        </View>
                                      </View>
                                    ) : null}
                                  </React.Fragment>
                                );
                              })}
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            // margin: 10,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontSize: "13",
                                paddingBottom: 10,
                                fontWeight: "600",
                                marginLeft: "10",
                              }}
                            >
                              Videos
                            </Text>
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                width: "100%",
                              }}
                            >
                              {el?.check_list_attachment.map((item, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    {item.fileType == "Video" &&
                                    parseInt(item?.survey) ===
                                      parseInt(perSID.data) &&
                                    item?.withAnswer ? (
                                      <>
                                        <View
                                          style={{
                                            margin: 10,
                                          }}
                                          wrap
                                        >
                                          <View>
                                            <Image
                                              src={VideoPlay}
                                              style={{
                                                width: "95px",
                                                height: "60px",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: "10px",
                                                marginBottom: "2px",
                                                marginLeft: 10,
                                                marginRight: 10,
                                              }}
                                            >
                                              {moment(item?.created).format(
                                                "DD-MM-YYYY HH:mm"
                                              )}
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: "7px",
                                                marginLeft: 10,
                                                marginRight:
                                                  item?.lat == null &&
                                                  item?.long == null
                                                    ? 15
                                                    : 10,
                                              }}
                                            >
                                              {item?.lat == null &&
                                              item?.long == null
                                                ? "NA-NA"
                                                : `${item?.lat} - ${item?.long}`}
                                            </Text>
                                          </View>

                                          <View
                                            style={{
                                              marginLeft: 10,
                                              marginRight: 10,
                                            }}
                                            render={({ pageNumber }) =>
                                              pageNumber && (
                                                <>
                                                  <Text>
                                                    <Link
                                                      // src={
                                                      //     item?.checklist_file
                                                      // }
                                                      src={`${process.env.REACT_APP_DOMAIN}download / ${item?.Id}`}
                                                      style={{
                                                        fontSize: 12,
                                                        color: "blue",
                                                      }}
                                                    >
                                                      Link
                                                    </Link>
                                                  </Text>
                                                </>
                                              )
                                            }
                                          ></View>
                                        </View>
                                      </>
                                    ) : null}
                                  </React.Fragment>
                                );
                              })}
                            </View>
                          </View>
                        </View>

                        {recording?.filter((val, ind) => {
                          return val.checkListItem == el?.Id;
                        }).length > 0 ? (
                          <View
                            style={{
                              padding: 10,
                              display: "flex",
                              flexDirection: "row",
                              borderLeft: "1px solid gray",
                              borderRight: "1px solid gray",
                            }}
                          >
                            <View>
                              <Text
                                style={{
                                  fontSize: "13",
                                  paddingBottom: 10,
                                  fontWeight: "600",
                                }}
                              >
                                Live Stream Recordings
                              </Text>
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  width: "100%",
                                }}
                              >
                                {recording?.map((val, ind) =>
                                  val.checkListItem == el?.Id ? (
                                    <React.Fragment key={ind}>
                                      <View
                                        style={
                                          {
                                            // margin: 10,
                                          }
                                        }
                                        wrap
                                      >
                                        <View>
                                          <Image
                                            src={VideoPlay}
                                            style={{
                                              width: "95px",
                                              height: "60px",
                                            }}
                                          />
                                        </View>

                                        <View
                                          style={{
                                            marginLeft: 10,
                                            marginRight: 10,
                                          }}
                                          render={({ pageNumber }) =>
                                            pageNumber && (
                                              <>
                                                <Text>
                                                  <Link
                                                    src={val?.url}
                                                    style={{
                                                      fontSize: 12,
                                                      color: "blue",
                                                    }}
                                                  >
                                                    Link
                                                  </Link>
                                                </Text>
                                              </>
                                            )
                                          }
                                        ></View>
                                      </View>
                                    </React.Fragment>
                                  ) : null
                                )}
                              </View>
                            </View>
                          </View>
                        ) : null}

                        {/* <View
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <View>
                                                            <Text
                                                                style={{
                                                                    fontSize: "13",
                                                                    paddingBottom: 10,
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                Live Stream Recordings
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    flexWrap: "wrap",
                                                                    width: "100%",
                                                                }}
                                                            >

                                                                {
                                                                    data?.section_checklist_item.map((item, i) => {
                                                                        recording?.map((val, ind) => {
                                                                            console.log(item.Id + "===" + val.checkListItem);
                                                                            if (3147 === parseInt(val.checkListItem)) {
                                                                                return (
                                                                                    <React.Fragment key={i}>
                                                                                        <Text>Matching</Text>
                                                                                    </React.Fragment>
                                                                                );
                                                                            }
                                                                        }
                                                                        )

                                                                    })
                                                                }
                                                            </View>


                                                        </View>
                                                    </View> */}
                      </View>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </View>

        {/* {checklistForm.data.file?.length > 0 ?
                    <>
                        <View style={{ borderTop: '1px solid gray' }}></View>
                        <View style={{
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            padding: "10px"
                        }}>
                            <Text
                                style={{
                                    fontSize: "13",
                                    paddingBottom: 10,
                                    fontWeight: '600'
                                }}
                            >
                                Form
                            </Text>
                            <View style={{ border: '1px solid gray', }}>
                                <Text style={{ marginBottom: '5px', textAlign: 'center', fontSize: '16px', padding: '10px' }}>
                                    {formName}
                                </Text>

                                <View style={{ borderTop: '1px solid gray', padding: '10px' }}>

                                    {checklistForm.data.file?.map((val, ind) => (
                                        ind !== checklistForm.data.file.length - 1 ?
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: '5px 0px 5px 0px'
                                            }}>
                                                <View style={{ width: '20%' }}>
                                                    <Text style={{ textTransform: 'capitalize', fontWeight: '600', fontSize: '14px', color: '#616A6B' }}>{val.key} :</Text>
                                                </View>
                                                <View style={{ width: '80%', }}>
                                                    <Text style={{ borderBottom: '1px solid #424949', color: '#616A6B', fontSize: '14px', lineHeight: '1' }}>{val.value}</Text>
                                                </View>
                                            </View>
                                            : null
                                    ))}
                                </View>
                            </View>
                        </View>
                        <View style={{ borderTop: '1px solid gray' }}></View>
                    </>
                    : null} */}

        <View
          style={{
            borderLeft: "1px solid gray",
            borderRight: "1px solid gray",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              marginTop: "10px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "13",
                paddingBottom: 10,
                marginBottom: "2px",
                fontWeight: "600",
              }}
            >
              Surveyor's Overall Comments :
            </Text>
          </View>

          <View
            style={{
              width: "auto",
              border: "1px solid gray",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                marginBottom: "2px",
                padding: "10px",
              }}
            >
              {surveyResponse[0]?.remarks}
            </Text>
          </View>

          {surveyResponse[0]?.verifier_activity.length > 0 ? (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                marginTop: "10px",
                marginLeft: "10px",
                marginRight: "10px",
                fontWeight: "600",
              }}
            >
              <Text
                style={{
                  fontSize: "13",
                  paddingBottom: 10,
                  marginBottom: "2px",
                }}
              >
                Verifier's Remarks :
              </Text>
            </View>
          ) : null}

          {surveyResponse[0]?.verifier_activity.length > 0 ? (
            <View
              style={{
                width: "auto",
                border: "1px solid gray",
                borderRadius: "8px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  marginBottom: "2px",
                  padding: "10px",
                }}
              >
                {
                  surveyResponse[0]?.verifier_activity[
                    surveyResponse[0]?.verifier_activity.length - 1
                  ].comments
                }
              </Text>
            </View>
          ) : null}

          {surveyResponse[0]?.survey_signature.length > 0 ? (
            <View
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              <Text
                style={{
                  fontSize: "13",
                  paddingBottom: 10,
                  marginBottom: "2px",
                  fontWeight: "600",
                }}
              >
                {"Surveyor's Sign :"}
              </Text>

              <Image
                style={{
                  marginTop: "1px",
                  marginLeft: "2px",
                  height: "50",
                  width: "100",
                }}
                src={convertUrl(
                  surveyResponse[0]?.survey_signature[
                    surveyResponse[0]?.survey_signature.length - 1
                  ].sign
                )}
              />
              <Text
                style={{
                  fontSize: "10",
                  marginTop: "4px",
                }}
              >
                {new Date(
                  surveyResponse[0]?.survey_signature[
                    surveyResponse[0]?.survey_signature.length - 1
                  ]?.createdDate
                ).toLocaleString()}
              </Text>
            </View>
          ) : null}
        </View>

        <View
          style={{
            height: "2%",
            padding: "0px 15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            borderLeft: "1px solid gray",
            borderRight: "1px solid gray",
          }}
          fixed
        ></View>

        <View
          style={{
            // padding: "0px 15px",
            display: "flex",
            height: "5%",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flexDirection: "row",
            // position: "absolute",
            // bottom: "10px",
            width: "100%",
            height: 25,
            paddingBottom: "4px",
            paddingHorizontal: 15,
            // paddingTop: '5px',
            border: "1px solid gray",

            // backgroundColor: "red",
          }}
          fixed
        >
          <View
            style={{
              // borderTop: 1,
              // borderTopColor: "grey",
              position: "absolute",
              width: "120%",
              bottom: 25,
            }}
          ></View>
          <View>
            <Text
              style={{
                fontSize: "11px",
              }}
            >
              Survey Name : {surveyResponse[0]?.checklist?.name}
            </Text>
          </View>
          {/* <View>
                        <Text
                            style={{
                                fontSize: "11px",
                            }}
                        >
                          
                        </Text>
                    </View> */}
          <View>
            <Text
              style={{
                fontSize: "11px",
              }}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            ></Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text
              style={{
                fontSize: "11px",
                marginTop: "2px",
              }}
            >
              Powered By
            </Text>
            <Image
              style={{
                marginTop: "1px",
                marginLeft: "2px",
                height: "15px",
                width: "15px",
              }}
              src={logo}
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <React.Fragment>
      {/* <div className="reportForApp">
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='title bg-primary-blue' id="card_title">
                                <p className='mb-0 f-24'>{"Survey Report"}</p>
                            </div> */}

      {/* <div className='card-body'> */}
      {loader === true ? (
        <div className="loader-box justify-content-center">
          <div className="loader">
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body text-center">
                {/* <PDFViewer style={styles.viewer}>

                                </PDFViewer> */}
                <PDFDownloadLink
                  document={<MyDoc />}
                  fileName={
                    surveyResponse[0]?.checklist?.name +
                    "_" +
                    surveyResponse[0]?.surveyNo
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Generating Survey Report..."
                    ) : (
                      <button className="btn btn-primary">
                        {"Download Report"}
                      </button>
                    )
                  }
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </React.Fragment>
  );
}

export default ReportForApp;
