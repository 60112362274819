import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { updateCheckListNew } from "../../../../api/index";
import { toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Name is required !"),
    formCreate: yup.boolean().required("Required"),
    surveyType: yup.string().required("Required"),
    description: yup.string()
    .max(500, "500 characters allowed !")
    //.required("Description required !"),
  })
  .required();

function EditChecklist(props) {
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;
  const customer_id = localStorage.getItem("customer_id");

  useEffect(() => {
    console.log("props.data", props.data);
    setValue("name", props.data?.name);
    setValue("formCreate", props.data?.isForm);
    setValue("description", props.data?.description);
  }, [props.data]);

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      console.log("props", props);

      const reqPayload = {
        Id: props.data.Id,
        id: props.data.id,
        isForm: data.formCreate,
        surveyType: data.surveyType,
        name: data.name,
        description: data.description,
        customer: parseInt(customer_id),
      };
      console.log("reqPayload", reqPayload);

      updateCheckListNew(reqPayload).then(
        (res) => {
          console.log("res", res);

          toast.success("Survey updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          props.toggle();
          props.fetchCheckList();
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={props.toggle} className="bg-primary-blue">
          {"Update Survey"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-8 mb-2">
              <label className="f-w-600 f-14">{"Name"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                defaultValue={props.data?.name}
                placeholder="Name*"
              />

              <span className="text-danger">{errors.name?.message}</span>
            </div>

            <div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Create Form"}</label>
              <div className="col ms-2  mt-1">
                <div className="form-group d-flex">
                  <div className="radio radio-primary">
                    <input
                      {...register("formCreate")}
                      id="radio1"
                      type="radio"
                      value={true}
                      defaultChecked={props.data?.isForm == true ? true : false}
                    />
                    <label htmlFor="radio1">{"Yes"}</label>
                  </div>

                  <div className="radio radio-primary ms-4">
                    <input
                      {...register("formCreate")}
                      id="radio2"
                      type="radio"
                      value={false}
                      defaultChecked={
                        props.data?.isForm == false ? true : false
                      }
                    />
                    <label htmlFor="radio2">{"No"}</label>
                  </div>
                </div>
              </div>

              <span className="text-danger">{errors.formCreate?.message}</span>
            </div>

            <div className="col-12 col-md-8 mb-2">
              <label className="f-w-600 f-14">{"Description"}</label>
              <textarea
                className={
                  errors.description
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                name={`description`}
                {...register(`description`)}
                defaultValue={props.data?.description}
                placeholder="Description*"
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.description?.message}</span>
            </div>

            <div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Survey Type"}</label>
              <div className="col ms-2  mt-1">
                <div className="form-group d-flex">
                  <div className="radio radio-primary">
                    <input
                      {...register("surveyType")}
                      id="radiostandard"
                      type="radio"
                      value={"Standard"}
                      defaultChecked={
                        props.data?.surveyType == "Standard" ? "Standard" : ""
                      }
                    />
                    <label htmlFor="radiostandard">{"Standard"}</label>
                  </div>

                  <div className="radio radio-primary ms-4">
                    <input
                      {...register("surveyType")}
                      id="radiosire"
                      type="radio"
                      value={"Sire"}
                      defaultChecked={
                        props.data?.surveyType == "Sire" ? "Sire" : ""
                      }
                    />
                    <label htmlFor="radiosire">{"Sire"}</label>
                  </div>
                </div>
              </div>

              {/* <span className='text-danger'>{errors.name?.message}</span> */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
           Save Changes
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default EditChecklist;
