import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { axiosPutData } from "../../../../../../api/APIinstance";
import { WATCH_LOGIN_LIST } from "../../../../../../redux/actionTypes";
import { useParams } from "react-router-dom";
import Loader from "../../../../../common/loader";
import { toast } from "react-toastify";
import { renameChecklistItemAttachment } from "../../../../../../api/index";

const AddPhotosVideos = ({
  photosVideos,
  recordingVideos,
  handleAddingPhoto,
  // handleClose,
  toggle2,
  deletePhoto,
  topic,
  setPreview,
  preview,
  AddData,
  loader,
}) => {
  // const [preview, setPreview] = useState(photosVideos[0]);
  const { id } = useParams();
  // const dispatch = useDispatch();
  // const [loader, setLoader] = useState(false);
  // const [SelectedPhotos, setSelectedPhotos] = useState([]);
  // const [uploadedVideo, setUploadedVideo] = useState("");

  const [filename, setFilename] = useState("");
  const [filenameErr, setFilenameErr] = useState("");
  var FormData = require("form-data");

  var basic_settings2 = {
    dots: false,
    infinite: false,
    speed: 100,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    adaptiveHeight: true,
    centerPadding: "10px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#000000",
          borderRadius: 50,
        }}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#000000",
          borderRadius: 50,
        }}
        onClick={onClick}
      />
    );
  }

  // const AddData = async () => {
  //   const formData = new FormData();
  //   formData.append("checkListItem", topic.Id);
  //   formData.append("Id ", preview.Id);
  //   formData.append("withAnswer ", "True");
  //   const response = await axiosPutData("CheckListAttachment/", formData);
  //   if (response.status == 200) {
  //     dispatch({ type: WATCH_LOGIN_LIST });
  //     toggle2()F
  //     setPreview('')
  //     toast.success(response.status);
  //   }
  //   // setLoader(false);
  // };

  function updateFilename() {
    console.log("filename", filename);
    console.log("preview", preview);
    const reqPayload = {
      Id: preview[0].Id,
      name: filename,
    };

    console.log("reqPayload", reqPayload);
    var formdata = new FormData();

    for (var key in reqPayload) {
      formdata.append(key, reqPayload[key]);
    }

    if (filename.length > 0) {
      setFilenameErr("");

      renameChecklistItemAttachment(formdata).then(
        (res) => {
          console.log("res", res);
          handleAddingPhoto();
          toast.success("Attachment renamed successfully", {
            autoClose: 3000,
            theme: "light",

          });
        },
        (err) => {
          console.log("err", err);
          toast.error("Failed to rename attachment", {
            autoClose: 3000,
            theme: "light",

          });
        }
      );
    } else {
      setFilenameErr("Required");
    }
  }

  const previwAdd = (data) => {
    console.log("data=========>", data);

    if (preview.includes(data)) {
      let a = preview.filter((el) => el.Id != data.Id);
      console.log("a", a);

      setPreview(a);
    } else if (!preview.includes(data) && preview.length != 3) {
      setPreview((prev) => [...prev, data]);
      console.log("data", data);
      setFilename(data.name);
    }
  };

  return (
    <>
      <div className="card" style={{ marginBottom: "0px" }}>
        <span
          className="d-flex justify-content-center"
          style={{
            fontSize: "23px",
            fontWeight: "600",
            color: "#2264AB",
          }}
        >
          Preview
        </span>
        <div className="card-body">
          <div className="shadow border mb-2">
            <div className="row ">
              <div
                className="col p-1"
                style={{ height: "270px", width: "250%" }}
              >
                {preview.length ? (
                  preview[preview.length - 1]?.fileType == "Image" ? (
                    <img
                      className="p-1 mb-4"
                      src={preview[preview.length - 1]?.checklist_file}
                      style={{
                        height: "-webkit-fill-available",
                        width: "-webkit-fill-available",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    !!preview && (
                      <video
                        src={
                          Object.keys(preview[preview.length - 1]).includes(
                            "url"
                          )
                            ? preview[preview.length - 1]?.url
                            : preview[preview.length - 1]?.checklist_file
                        }
                        height="100%"
                        width="100%"
                        controls
                      />
                    )
                  )
                ) : (
                  <span
                    className="text-center"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "red",
                      justifyContent: "center",
                    }}
                  >
                    No Preview available
                  </span>
                )}
              </div>
            </div>

            <div className="my-1 mx-2 ">
              {!loader ? (
                <>
                  {preview.length > 0 ? (
                    <div className="d-flex justify-content-between">
                      <input
                        style={{ height: "fit-content" }}
                        type="text"
                        className={
                          filenameErr.length > 0
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        onChange={(e) => {
                          var temp = e.target.value;
                          if (temp.length === 0) {
                            setFilenameErr("Required !");
                          } else {
                            setFilenameErr("");
                          }
                          setFilename(e.target.value);
                        }}
                        value={filename}
                        placeholder="Enter filename"
                      />

                      <button
                        style={{ height: "fit-content" }}
                        onClick={updateFilename}
                        className="btn btn-primary-blue ms-0 ms-md-2"
                      >
                        Rename
                      </button>

                      <button
                        disabled={preview ? false : true}
                        className="btn btn-primary-blue ms-0 ms-md-2"
                        style={{ height: "fit-content" }}
                        onClick={(event) => AddData(event)}
                      >
                        Add
                      </button>
                    </div>
                  ) : null}

                  {filenameErr.length > 0 ? (
                    <p className="text-danger mt-1 mb-0">{filenameErr}</p>
                  ) : null}
                </>
              ) : (
                <div className="loader-box justify-content-center">
                  <div className="loader">
                    <div className="line bg-dark"></div>
                    <div className="line bg-dark"></div>
                    <div className="line bg-dark"></div>
                    <div className="line bg-dark"></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row ">
            <div className="col">
              <h5 className="mb-1 text-center">Photos</h5>
              <div className="row my-1">
                <div className="col">
                  {loader ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : (
                    <Slider
                      {...basic_settings2}
                      style={{
                        height: "160px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {photosVideos.data.filter((el) => {
                        return el.fileType == "Image";
                      }).length == 0 ? (
                        <span className="text-danger text-center">
                          No photos available
                        </span>
                      ) : (
                        photosVideos.data
                          .filter((el) => {
                            return (
                              el.fileType == "Image" && el.checkListItem == null
                            );
                          })
                          .map((el1) => {
                            return (
                              <div
                                key={Math.ceil(Math.random() * 100000)}
                                className="col-md-4 col-sm-6 position-relative px-2 mt-3"
                              // onMouseEnter={}
                              >
                                <div className="position-relative">
                                  <img
                                    // onClick={() => {
                                    //   setPreview(el1);
                                    // }}
                                    onClick={() => previwAdd(el1)}
                                    src={el1.checklist_file}
                                    style={{
                                      width: "100%",
                                      height: "25vh",
                                      border: preview?.includes(el1)
                                        ? "2px solid #2246AB"
                                        : "",
                                      // padding: "10px",
                                    }}
                                  />

                                  <p
                                    className="mb-0 text-muted text-center"
                                    id={"datePara"}
                                  >
                                    {new Date(el1.created).toLocaleString()}
                                    <br />
                                    {el1.name}
                                  </p>
                                  <button
                                    onClick={(event) => deletePhoto(el1, event)}
                                    type="button"
                                    className="btn position-absolute top-0 end-0 p-0  rounded-pill align-items-center d-flex justify-content-center"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      background: "gray",
                                    }}
                                  >
                                    x
                                  </button>
                                </div>
                              </div>
                            );
                          })
                      )}
                    </Slider>
                  )}
                </div>
              </div>
              <h5 className="mb-1 text-center">Videos</h5>
              <div className="row ">
                <div className="col">
                  <Slider
                    {...basic_settings2}
                    style={{
                      height: "160px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {photosVideos.data.filter((el) => {
                      return el.fileType == "Video";
                    }).length == 0 ? (
                      <span className="text-danger text-center">
                        No videos Available
                      </span>
                    ) : (
                      photosVideos.data
                        .filter((el) => {
                          return (
                            el.fileType == "Video" && el.checkListItem == null
                          );
                        })
                        .map((el1) => {
                          return (
                            <div
                              key={Math.ceil(Math.random() * 100000)}
                              className="col-md-4 col-sm-6 position-relative px-2 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => previwAdd(el1)}
                            >
                              <div className="position-relative">
                                <video
                                  src={el1.checklist_file}
                                  // style={{ height: "100px", width: "100px" }}
                                  style={{
                                    width: "100%",
                                    height: "25vh",
                                    border: preview.includes(el1)
                                      ? "2px solid blue"
                                      : "",
                                    // padding: "10px",
                                  }}
                                  controls={true}
                                />

                                <p
                                  className="mb-0 text-muted text-center"
                                  id={"datePara"}
                                >
                                  {new Date(el1.created).toLocaleString()}<br />
                                  {el1.name}
                                </p>
                                <button
                                  onClick={(event) => {
                                    deletePhoto(el1, event);
                                  }}
                                  type="button"
                                  className="btn position-absolute top-0 end-0 p-0  rounded-pill align-items-center d-flex justify-content-center"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    background: "gray",
                                  }}
                                >
                                  x
                                </button>
                              </div>
                            </div>
                          );
                        })
                    )}
                  </Slider>
                </div>
              </div>
              {/* <h5 className="mb-4 text-center">Recorded Videos</h5>
              <div className="row ">
                <div className="col">
                  <Slider
                    {...basic_settings2}
                    style={{
                      height: "160px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {recordingVideos.length ? (
                      recordingVideos?.map((element) => {
                        return (
                          <div
                            key={Math.ceil(Math.random() * 100000)}
                            className="col-md-4 col-sm-6 position-relative px-2"
                            style={{ display: "flex", alignItems: "center" }}
                            // onClick={() => setPreview(element)}
                          >
                            <video
                              src={element.url}
                              // style={{ height: "100px", width: "100px" }}
                              style={{ width: "100%", height: "20vh" }}
                              controls={true}
                            />
                            <button
                              type="button"
                              className="btn position-absolute top-0 end-0 p-0  rounded-pill align-items-center d-flex justify-content-center"
                              style={{
                                width: "20px",
                                height: "20px",
                                background: "gray",
                              }}
                            >
                              x
                            </button>
                          </div>
                        );
                      })
                    ) : (
                      <span className="text-danger text-center">
                        No Video recording Available
                      </span>
                    )}
                  </Slider>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddPhotosVideos;
