import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function HtmlTemplate() {
    const location = useLocation();

    useEffect(() => {
        console.log("location", location);
    }, []);
    return (
        <React.Fragment>
            <div className='htmltemplate'>
                {location.state.checklist.checklist_sections.map((val, i) => (
                    <React.Fragment key={i}>
                        <h2>{val.sectionName}</h2>
                        {val.section_checklist_item.map((item, ind) => (
                            <React.Fragment key={ind}>
                                <table >
                                    <thead>
                                        <tr>
                                            <th> S No </th>
                                            <th> Checklist Item </th>
                                            <th> Checklist Answer </th>
                                            <th> Remarks </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "10%" }}> {item.SrNo} </td>
                                            <td style={{ width: "40%", textAlign: "left" }}> {item.section} </td>
                                            <td style={{ width: "30%" }}> {item.checklist_item_result[0].values} </td>
                                            <td style={{ width: "20%" }}> {item.checklist_item_result[0].remarks} </td>
                                        </tr>
                                    </tbody>
                                </table >






                                <div class="row thumbnail-row">
                                    <div> {/* this div I have lifted up from the below loop as
                                     div is block level element it takes entire space, if only img is rendered inside loop, images ll display side by side*/}
                                        {item.check_list_attachment.map((img, index) => (
                                            img.fileType === "Image" ?
                                                <img class="thumbnail-image" key={index} src={img.checklist_file} />
                                                : null
                                        ))}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}

                    </React.Fragment>

                ))
                }
            </div >
        </React.Fragment >
    )
}

export default HtmlTemplate