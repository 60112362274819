import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { updateSubscription, getAllCountries } from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Name is required !"),
    inspectionsCount: yup
      .string()
      .required("Inspection Nos is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    callMinutes: yup
      .string()
      .required("Call minutes is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    userCount: yup
      .string()
      .required("User is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    vesselCount: yup
      .string()
      .required("Vessels is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    monthlySubscription: yup
      .string()
      .required("Monthly subscription is required !"),
    annualSubscription: yup
      .string()
      .required("Annual subscription is required !"),
    currency_id: yup
      .array()
      .min(1, "Currency is required")
      .nullable()
      .required("Currency is required !"),
    perSurvey: yup
      .string()
      .required("Per survey is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    perMinute: yup
      .string()
      .required("Per minute is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
  })
  .required();

function EditSubscription(props) {
  const [currencyList, setCurrencyList] = useState({
    data: [],
    loading: false,
  });

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      var reqPayload = { ...data };
      reqPayload.currency_id = data.currency_id[0].id;
      reqPayload.Id = props.data?.Id;
      reqPayload.inspectionsCount = parseInt(data.inspectionsCount);
      reqPayload.callMinutes = parseInt(data.callMinutes);
      reqPayload.userCount = parseInt(data.userCount);
      reqPayload.vesselCount = parseInt(data.vesselCount);

      console.log("reqPayload", reqPayload);

      updateSubscription(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("Subscription updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          props.toggle8();

          setTimeout(() => {
            props.FetchSubscription();
          }, 200);
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  useEffect(() => {
    setCurrencyList({ ...currencyList, data: [], loading: true });

    getAllCountries().then(
      (res) => {
        console.log("res", res);
        setCurrencyList({ ...currencyList, data: res.data, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />

      <Modal
        isOpen={props.modal8}
        toggle={props.toggle8}
        size="lg"
        backdrop="static"
      >
        <ModalHeader className="bg-primary-blue" toggle={props.toggle8}>
          {"Update Subscription"}
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Name"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Plan Name*"
                    name="name"
                    {...register("name")}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Inspection Nos"}</label>
                  <input
                    className={
                      errors.inspectionsCount
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter No. Of Inspections*"
                    name="inspectionsCount"
                    {...register("inspectionsCount")}
                    defaultValue={props.data?.inspectionsCount}
                  />

                  <span className="text-danger">
                    {errors.inspectionsCount?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Call Minutes"}</label>
                  <input
                    className={
                      errors.callMinutes
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Call Mintues*"
                    name="callMinutes"
                    {...register("callMinutes")}
                    defaultValue={props.data?.callMinutes}
                  />

                  <span className="text-danger">
                    {errors.callMinutes?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Users"}</label>
                  <input
                    className={
                      errors.userCount
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter No. Of Users*"
                    name="userCount"
                    {...register("userCount")}
                    defaultValue={props.data?.userCount}
                  />

                  <span className="text-danger">
                    {errors.userCount?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Vessels"}</label>
                  <input
                    className={
                      errors.vesselCount
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter No. Of Vessels*"
                    name="vesselCount"
                    {...register("vesselCount")}
                    defaultValue={props.data?.vesselCount}
                  />

                  <span className="text-danger">
                    {errors.vesselCount?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Monthly Subscription"}</label>
                  <input
                    className={
                      errors.monthlySubscription
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Amount*"
                    name="monthlySubscription"
                    {...register("monthlySubscription")}
                    defaultValue={props.data?.monthlySubscription}
                  />

                  <span className="text-danger">
                    {errors.monthlySubscription?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Annual Subscription"}</label>
                  <input
                    className={
                      errors.annualSubscription
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Amount*"
                    name="annualSubscription"
                    {...register("annualSubscription")}
                    defaultValue={props.data?.annualSubscription}
                  />

                  <span className="text-danger">
                    {errors.annualSubscription?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Currency"}</label>

                  <Controller
                    name="currency_id"
                    control={control}
                    defaultValue={
                      props.data !== undefined
                        ? props.data.currency === null
                          ? []
                          : [
                              {
                                currency: props.data.currency.currency,
                                id: props.data.currency.id,
                              },
                            ]
                        : []
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="currency"
                        multiple={false}
                        options={currencyList.data}
                        isLoading={currencyList.loading}
                        placeholder="--Select*--"
                        onChange={onChange}
                        selected={value}
                        isInvalid={errors.currency_id ? true : false}
                      />
                    )}
                  />

                  {/* <Controller
                                        control={control}
                                        name="currency_id"
                                        defaultValue={props.data !== undefined ?
                                            props.data.currency === null ? [] :
                                                [{ currency: props.data.currency.currency, id: props.data.currency.id }]
                                            : []}
                                        // defaultValue={[{ currency: props.data?.currency.currency, id: props.data?.currency.id}]}
                                        render={({ onChange, value }) => (
                                            <Typeahead
                                                id="basic-typeahead"
                                                labelKey="currency"
                                                multiple={false}
                                                options={currencyList.data}
                                                isLoading={currencyList.loading}
                                                placeholder="--Select*--"
                                                onChange={onChange}
                                                selected={value}
                                                isInvalid={errors.currency_id ? true : false}
                                            />
                                        )}
                                    /> */}

                  <span className="text-danger">
                    {errors.currency_id?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Per Survey"}</label>
                  <input
                    className={
                      errors.perSurvey
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Value*"
                    name="perSurvey"
                    {...register("perSurvey")}
                    defaultValue={props.data?.perSurvey}
                  />

                  <span className="text-danger">
                    {errors.perSurvey?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Per Minute"}</label>
                  <input
                    className={
                      errors.perMinute
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Value*"
                    name="perMinute"
                    {...register("perMinute")}
                    defaultValue={props.data?.perMinute}
                  />

                  <span className="text-danger">
                    {errors.perMinute?.message}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Update Plan"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default EditSubscription;
