import React, { useState, useEffect, useRef } from "react";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Modal, } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  getAllRoles,
  getVessels,
  createCustomersUser,
  createCustomersUserForGuest,
} from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { convertDate } from "../../../ProjectComponents/Common/Functions/CommonFunctions";
import { getAllCountries } from "../../../../api";
import { Eye, EyeOff } from "react-feather";
const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(70, "70 characters allowed !")
      .required("Name is required !"),
    username: yup
      .string()
      .max(50, "50 characters allowed !")
      .required("Username is required !"),
    email: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    phone: yup
      .string()
      .required("Phone no. is required !")
      .matches(/^[0-9]+$/, "Phone number should be numeric value"),
    countryCode: yup.array().nullable().required("Country Code is required !"),
    password: yup
    .string()
    .required("Please enter password !")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$&])(?=.{8,})/,
      "Atleast 1 Uppercase, 1 Number, 1 Special character and must have 8 characters"
    ),
    // status: yup
    //   .string()
    //   .nullable()
    //   .required("Status is required !")
    //   .matches(/^((?!Select).)*$/, "Please select valid option"),
    role_id: yup
      .array()
      .min(1, "Please Select Role !")
      .required("Please Select Role !"),
     
  })
  .required();

function NewCustomerForm(props) {
  const [modal8, setModal8] = useState();
  const [visible, setVisible] = useState(false);
  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [rolesList, setRolesList] = useState({ data: [], loading: false });
  const [vesselList, setVesselList] = useState({ data: [], loading: false });
  const customer_id = localStorage.getItem("customer_id");
  const subscriptionPlan = JSON.parse(localStorage.getItem("subscription"));
  const [guestSelected, setGuestSelected] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch, setError, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;
  const role = watch("role_id");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  useEffect(() => {
    if (visible == false || visible == undefined) {
      setCountryList({ ...countryList, state: true, data: [] });
      toggle8();
      getAllCountries().then(
        (res) => {
          console.log("res", res);
          let arr = res.data.map((item) => {
            item.label = item.phone_code + " " + item.name;
            return item;
          });
          setCountryList({ ...countryList, state: false, data: arr });


        },
        (err) => {
          console.log("err", err);
          setCountryList({ ...countryList, state: false, data: [] });
          toast.error("Something went wrong in fetching countries !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    }
  }, [visible]);



  React.useEffect(() => {
    console.log("role", role);

    if (role !== undefined && role.length > 0) {
      if (role[0].name == "Guest") {
        setGuestSelected(true);
        console.log("true");
      } else {
        console.log("false");
        setGuestSelected(false);

      }
    }
  }, [role]);

  const toggle8 = () => {
    //setModal8(!modal8);
    // setVisible(!visible)

    if (visible === false || visible === undefined) {
      // resetting the form
      setGuestSelected(false)
      reset();
      setRolesList({ ...rolesList, data: [], loading: true });

      getAllRoles().then(
        (res) => {
          console.log("res", res);
          let temp = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].name == "SiteSurveyor") {
              res.data[i].name = "SiteCoordinator";
              temp.push(res.data[i]);
            } else {
              temp.push(res.data[i]);
            }
          }
          setRolesList({ ...rolesList, data: temp, loading: false });
        },
        (err) => {
          console.log("err", err);
        }
      );

      setVesselList({ ...vesselList, data: [], loading: true });
      getVessels({ customer_id: customer_id }).then(
        (res) => {
          console.log("res", res);
          setVesselList({ ...vesselList, data: res.data.res, loading: false });
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      reset();
    }
  };


  const onSubmit = (data) => {
    const currDate = new Date(new Date().setHours(0, 0, 0, 0));
    const planStartDate = new Date(
      new Date(new Date(subscriptionPlan.startDate)).setHours(0, 0, 0, 0)
    );
    const planEndDate = new Date(
      new Date(new Date(subscriptionPlan.endDate)).setHours(0, 0, 0, 0)
    );


    if (data !== "") {
      console.log("data", data);
      if (data.role_id[0].name !== "Guest") {
        if (data.vessel_id == undefined || data.vessel_id.length < 1) {
          setError("vessel_id", {
            type: "custom",
            message: "Please Select Vessel !",
          });
          return;
        } else {
          data.role_id = data.role_id[0].Id;
          data.vessel_id = data.vessel_id[0].Id;
          data.status = "Active";
          data.customer_id = customer_id;

          data.phone = data.countryCode[0].phone_code + data.phone;
          delete data.countryCode;

          console.log("data", data);
          console.log("subscriptionPlan", subscriptionPlan);

          console.log("currDate", currDate);
          console.log("planStartDate", planStartDate);
          console.log("planEndDate", planEndDate);
          console.log("props.totalRecords", props.totalRecords);

          console.log(
            "currDate >= planStartDate === true && currDate <= planEndDate === true",
            currDate >= planStartDate === true &&
            currDate <= planEndDate === true
          );

          if (
            currDate >= planStartDate === true &&
            currDate <= planEndDate === true
          ) {
            console.log("valid");

            if (props.totalRecords < subscriptionPlan.plan.userCount) {
              console.log("valid");
              createCustomersUser(data).then(
                (res) => {
                  console.log("res", res);
                  if (res.status === 200 || res.status === 201) {
                    toast.success("User Created Successfully !", {
                      autoClose: 3000,
                      theme: "light",
                    });
                    // toggle8();
                    setVisible(false)
                    props.fetchUsers("refresh");
                  }
                },
                (err) => {
                  console.log("err", err);

                  console.log("err.response", err.response);

                  if (err.response.status === 400) {
                    // toast.error("Customer user email should be unique !", { autoClose: 3000,theme:'light' })
                    toast.error("Username not available !", {
                      autoClose: 3000,
                      theme: "light",
                    });
                  } else if (err.response.status === 401) {
                    toast.error("Unauthorized !", {
                      autoClose: 3000,
                      theme: "light",
                    });
                  } else if (err.response.status === 403) {
                    toast.error("Invalid Subscription, contact admin !", {
                      autoClose: 3000,
                      theme: "light",
                    });
                  } else if (err.response.status === 500) {
                    toast.error("Something went wrong, please try again !", {
                      autoClose: 3000,
                      theme: "light",
                    });
                  }
                }
              );
            } else {
              toast.error("Invalid Subscription, contact admin !", {
                autoClose: 3000,
                theme: "light",
              });
            }
          } else {
            console.log("invalid");
            toast.error("Invalid Subscription, contact admin !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        }
      } else {
        console.log("data", data)
        delete data.vessel_id;
        data.role_id = data.role_id[0].Id;
        data.status = "Active";
        data.customer_id = customer_id;

        data.phone = data.countryCode[0].phone_code + data.phone;
        delete data.countryCode;

        if (
          currDate >= planStartDate === true &&
          currDate <= planEndDate === true
        ) {
          console.log("valid");

          if (props.totalRecords < subscriptionPlan.plan.userCount) {
            console.log("valid");
            console.log("data", data);
            createCustomersUserForGuest(data).then(
              (res) => {
                console.log("res", res);
                if (res.status === 200 || res.status === 201) {
                  toast.success("User Created Successfully !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                  //   toggle8();
                  setVisible(false)
                  props.fetchUsers("refresh");
                }
              },
              (err) => {
                console.log("err", err);

                console.log("err.response", err.response);

                if (err.response.status === 400) {
                  // toast.error("Customer user email should be unique !", { autoClose: 3000,theme:'light' })
                  toast.error("Username not available !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                } else if (err.response.status === 401) {
                  toast.error("Unauthorized !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                } else if (err.response.status === 403) {
                  toast.error("Invalid Subscription, contact admin !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                } else if (err.response.status === 500) {
                  toast.error("Something went wrong, please try again !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                }
              }
            );
          } else {
            toast.error("Invalid Subscription, contact admin !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        } else {
          console.log("invalid");
          toast.error("Invalid Subscription, contact admin !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    } else {
      errors.showMessages();
      toast.error("Something went wrong !", {
        autoClose: 3000,
        theme: "light",
      });
    }
  };




  return (
    <React.Fragment>
      <div className="d-flex justify-content-end">
        <button
          style={{ fontSize: "16px " }}
          onClick={() => setVisible(true)}
          className="btn btn-primary-blue "
        >
          Create New User
        </button>
      </div>


      <Modal show={visible} onHide={() => setVisible(false)} size="lg" backdrop="static">
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title >  <p className="f-22 f-w-400 mb-0">{"Create New User"}</p></Modal.Title>

        </Modal.Header>

        <Modal.Body>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Name"}</label>
                  <input
                    className={
                      errors.name
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    name="name"
                    placeholder=" Enter Name*"
                    {...register("name")}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Username"}</label>
                  <input
                    className={
                      errors.username
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter Username*"
                    name="username"
                    {...register("username")}
                  />

                  <span className="text-danger">
                    {errors.username?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Email"}</label>
                  <input
                    className={
                      errors.email
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="email"
                    name="email"
                    placeholder="Enter Email*"
                    {...register("email")}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Phone No"}</label>
                  <div className="row">
                    <div className="col-12 col-md-4 pe-0">

                      <Controller
                        name="countryCode"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (

                          <Typeahead
                            name="countryCode"

                            id="basic-typeahead"

                            labelKey='label'

                            multiple={false}
                            options={countryList.data}
                            isLoading={countryList.state}
                            placeholder="Country Code*"
                            onChange={onChange}
                            onBlur={onBlur}

                            selected={value}
                            isInvalid={errors.countryCode ? true : false}

                          />


                        )}
                      />


                    </div>
                    <div className="col-12 col-md-8 ps-1">
                      <input
                        className={
                          errors.phone
                            ? "form-control is-invalid f-16"
                            : " f-16 form-control"
                        }
                        type="text"
                        name="phone"
                        placeholder="Enter Phone No*"
                        {...register("phone")}
                      />
                    </div>
                  </div>

                  <span className="text-danger">{errors.phone?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Role"}</label>

                  <Controller
                    name="role_id"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead

                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={rolesList.data}
                        loading={rolesList.loading}
                        placeholder="Select Role*"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.role_id ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.role_id?.message}</span>
                </div>
              </div>

              <div className={guestSelected ? "d-none" : "col-12 col-md-6"}>
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Vessel"}</label>

                  <Controller
                    name="vessel_id"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead

                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={vesselList.data}
                        loading={vesselList.loading}
                        emptyLabel={
                          vesselList.data.length > 0
                            ? "No matches found."
                            : "No vessels to select"
                        }
                        placeholder="Select Vessel*"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.vessel_id ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.vessel_id?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Password"}</label>
                  <div className="input-group">
                    <input
                      className={
                        errors.password
                          ? "form-control is-invalid f-16"
                          : " f-16 form-control"
                      }
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter Password*"
                      name="password"
                      {...register("password")}
                    />

                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        onClick={handleTogglePassword}
                        style={{ cursor: 'pointer' }}
                      >
                        {showPassword ? <Eye /> : <EyeOff />}
                      </span>
                    </div>
                  </div>
                  <span className="text-danger">
                    {errors.password?.message}
                  </span>
                </div>
              </div>

              {/* <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Status"}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue="Active"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue="Active"
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.status
                            ? "custom-select form-control is-invalid f-16"
                            : " f-16 custom-select form-control"
                        }
                      >
                        <option select="">{"Select*"}</option>
                        <option defaultValue="Active">{"Active"}</option>
                        <option defaultValue="Inactive">{"Inactive"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div> */}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            style={{ fontSize: "16px " }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default NewCustomerForm;
