import { useEffect, useState } from "react";
import VideoCall from "./videoCall";
import {
  createMicrophoneAndCameraTracks,
  // createStream,
} from "agora-rtc-sdk-ng";
import AgoraRTC from "agora-rtc-sdk-ng";
import { getDevices } from "agora-rtc-sdk-ng";
import Webcam from "react-webcam";
import { open } from "../../../redux/sidebarOpenClose/action";
import { useDispatch } from "react-redux";
import bouncing_plastic_bottle from "../../../assets/audio/bouncing_plastic_bottle.mp3";
import { useParams } from "react-router-dom";
import { getData, postData, putData } from "../../../api/APIinstance";
import "./index.css";
import {
  createCustomVideoTrack,
  createScreenVideoTrack,
} from "agora-rtc-react";

import firebase from 'firebase/compat/app';
import db from '../../../data/config';
import { getStorage, uploadBytes, ref as abcd, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, getDoc, } from "firebase/firestore";
import { secondsToTime } from '../../ProjectComponents/Common/Functions/CommonFunctions';
import { collection, query, where, getDocs } from "firebase/firestore";
import { updateCustomerVideoCallMins } from '../../ProjectComponents/Common/Functions/CommonFunctions';


function VideoApp(props) {
  const { id } = useParams();
  const [inCall, setInCall] = useState(false);
  // const playAlert = require('alert-sound-notify')


  const localData = JSON.parse(localStorage.getItem("login_data"));
  const userType = localStorage.getItem("user");
  const subscriptionPlan = JSON.parse(localStorage.getItem('subscription'));

  const [myInterval, setMyInterval] = useState("");
  const [docRef, setDocRef] = useState({ Id: '' });
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [cameraMicTesting, setCameraMicTesting] = useState(false);
  const [isSharingEnabled, setIsSharingEnabled] = useState(false);
  const [devices, setDevices] = useState({ audiolist: [], videolist: [] });
  const [selAudDevice, setSelAudDevice] = useState({ data: {} });
  const [selVidDevice, setSelVidDevice] = useState({ data: {} });
  const [numberOfPidsToColor, setNumberOfPidsToColor] = useState(0);
  const [token, setToken] = useState();

  const [totalSeconds, setTotalSeconds] = useState({ data: '' });
  const [currentDateOfRecord, setCurrentDateOfRecord] = useState('');


  const ref = db.collection('Call');

  const numbers = [
    { num: 1, color: "#28a745" },
    { num: 2, color: "#28a745" },
    { num: 3, color: "#007bff" },
    { num: 4, color: "#007bff" },
    { num: 5, color: "#ffc107" },
    { num: 6, color: "#ffc107" },
    { num: 7, color: "#ff5707" },
    { num: 8, color: "#ff5707" },
    { num: 9, color: "#dc3545" },
    { num: 10, color: "#dc3545" },
  ];
  const [localStream, setLocalStream] = useState({ data: "" });
  const [localAudioStream, setlocalAudioStream] = useState({ data: "" });
  const [intervalMicListen, setIntervalMicListen] = useState({ data: "" });

  useEffect(async () => {
    startVideoCall();
    const userD = JSON.parse(localStorage.getItem("login_data"));
    const params = {
      userId: `${userD?.userId}`,
      channelName: `${id}`,
    };

    const response = await postData("generateAgoraToken/", params, "Bearer ");
    setToken(response);
    console.log("props.chanelName", props.chanelName);
    console.log("id", id);
  }, []);

  const dispatch = useDispatch();

  async function startVideoCall() {
    localStorage.setItem("GuestVideoCall", false);

    if (props.call) {
      setCameraMicTesting(true);

      // Find all audio and video devices
      getDevices()
        .then((devices) => {
          var audioDevList = [];
          var videoDevList = [];

          for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind == "audioinput") {
              audioDevList.push(devices[i]);
            } else if (devices[i].kind == "videoinput") {
              videoDevList.push(devices[i]);
            }
          }

          setDevices({
            ...devices,
            audiolist: audioDevList,
            videolist: videoDevList,
          });
          // setVideoDevices(videoDevList);
          // audioDevList[0].deviceId;
          // videoDevList[0].deviceId;

          selAudDevice.data = audioDevList[0].deviceId;
          setSelAudDevice({ ...selAudDevice, data: selAudDevice.data });

          selVidDevice.data = videoDevList[0].deviceId;
          setSelVidDevice({ ...selVidDevice, data: selVidDevice.data });

          testCamera("both");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }

  function testCamera(callBy) {
    createMicrophoneAndCameraTracks(
      { microphoneId: selAudDevice.data },
      { cameraId: selVidDevice.data }
    ).then(
      (tracks) => {
        console.log("tracks", tracks);

        if (callBy === "both") {
          localStream.data = tracks[1];
          setLocalStream({ ...localStream, data: localStream.data });

          localAudioStream.data = tracks[0];
          setlocalAudioStream({
            ...localAudioStream,
            data: localAudioStream.data,
          });

          const video = document.querySelector("#test_stream");
          // video.srcObject = localStream;
          localStream.data.play(video);

          intervalMicListen.data = setInterval(() => {
            colorPids();
          }, 500);
          setIntervalMicListen({
            ...intervalMicListen,
            data: intervalMicListen.data,
          });
        } else if (callBy === "camera") {
          localStream.data = tracks[1];
          setLocalStream({ ...localStream, data: localStream.data });
          const video = document.querySelector("#test_stream");
          localStream.data.play(video);
        } else if (callBy === "mic") {
          localAudioStream.data = tracks[0];
          setlocalAudioStream({
            ...localAudioStream,
            data: localAudioStream.data,
          });

          intervalMicListen.data = setInterval(() => {
            colorPids();
          }, 500);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function colorPids() {
    var vol = localAudioStream.data.getVolumeLevel() * 100;
    setNumberOfPidsToColor(Math.round(vol / 10));
    // numberOfPidsToColor = Math.round(vol / 10);
    // console.log("numberOfPidsToColor", numberOfPidsToColor);
    // console.log("Math.round(vol / 10)", Math.round(vol / 10));
  }

  function changeMic(e) {
    console.log("e", e.target.value);
    selAudDevice.data = e.target.value;
    setSelAudDevice({ ...selAudDevice, data: selAudDevice.data });

    console.log("selAudDevice.data", selAudDevice.data);
    // console.log("selVidDevice.data", selVidDevice.data);

    stopTestCamera("mic");

    setTimeout(() => {
      testCamera("mic");
    }, 1000);
  }

  function changeCamera(e) {
    console.log("e", e.target.value);
    selVidDevice.data = e.target.value;
    setSelVidDevice({ ...selVidDevice, data: selVidDevice.data });

    console.log("selVidDevice.data", selVidDevice.data);

    stopTestCamera("camera");

    setTimeout(() => {
      testCamera("camera");
    }, 1000);
  }




  async function startTimer(option) {
    if (option === "start") {

      // if subscription plan is not present no need to save call mins in firebase
      if (Object.keys(subscriptionPlan).length > 0) {


        const Firedata = {
          syncId: null,
          agoraId: Math.floor(100000 + Math.random() * 900000),
          channel: parseInt(id),
          userId: parseInt(localData.user),
          from: localData.FirstName,
          userType: userType,
          source: 'web',
          startTime: firebase.firestore.FieldValue.serverTimestamp(),
          callJoined: true,
          callJoinedTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
          callInProgress: true,
          callInProgressTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
          callLeft: false,
          callLeftTimeStamp: null,
          totalTime: null,
          totalSeconds: null,
          customerId: localData.customer_id
        }

        // Initial Entry

        const temp_doc_ref = await db.collection("Call").add(Firedata)
        docRef.Id = temp_doc_ref.id;
        setDocRef({ ...docRef, Id: docRef.Id });
        console.log("docRef.Id", docRef.Id);
        console.log("start calling");

        var count = 0;
        setMyInterval(
          setInterval(async () => {
            console.log("docRef.Id", docRef.Id);
            await db.collection("Call").doc(docRef.Id).update({
              callInProgressTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
            // After 3 seconds
          }, 3000)
        );
      }

    } else if (option === "end") {
      console.log("end calling");
      clearInterval(myInterval);
    }
  }

  const startCall = () => {
    setStartTime(firebase.firestore.FieldValue.serverTimestamp());
    startTimer("start");
    localStorage.setItem('callStart', new Date());
  };

  const endCall = async () => {
    console.log(docRef.Id);
    startTimer("end");

    if (Object.keys(subscriptionPlan).length > 0) {
      // updating same record
      await db.collection("Call").doc(docRef.Id).update({
        callJoined: false,
        callInProgress: false,
        callLeft: true,
        callInProgressTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        callLeftTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // calculating total seconds of all call and updating
      db.collection("Call").doc(docRef.Id).get().then((snapshot) => {
        console.log(snapshot.data());
        const data = snapshot.data();
        console.log(data.startTime.toDate());


        var seconds = (data.callLeftTimeStamp.toDate().getTime() - data.startTime.toDate().getTime()) / 1000;
        console.log("seconds", Math.round(seconds));
        totalSeconds.data = Math.round(seconds)
        setTotalSeconds({ ...totalSeconds, data: totalSeconds.data });
        // console.log(secondsToTime(Math.round(seconds)));
        const tTime = secondsToTime(Math.round(seconds));

        db.collection("Call").doc(docRef.Id).update({
          totalTime: tTime,
          totalSeconds: Math.round(seconds)
        });


        updateCustomerVideoCallMins(data.startTime.toDate(), Math.round(seconds));
      }).catch((e) => console.log(e))
    }

  };


  // const handleSwitchCamera = () => {
  //   console.log("localStream.data", localStream.data);
  //   // Find all audio and video devices
  //   getDevices()
  //     .then((devices) => {
  //       var videoDevList = [];

  //       for (let i = 0; i < devices.length; i++) {
  //         if (devices[i].kind == "videoinput") {
  //           videoDevList.push(devices[i]);
  //         }
  //       }

  //       if (videoDevList.length > 0) {
  //         for (let j = 0; j < videoDevList.length; j++) {
  //           if (videoDevList[j].deviceId !== selVidDevice.data) {
  //             selVidDevice.data = videoDevList[j].deviceId;
  //             setSelVidDevice({ ...selVidDevice, data: selVidDevice.data });
  //             testCamera("both");
  //           }
  //         }
  //       }
  //       console.log("videoDevList", videoDevList);
  //       console.log("selVidDevice.data", selVidDevice.data);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };





  function stopTestCamera(val) {
    console.log("localStream.data", localStream.data);

    if (val === "mic") {
      if (localAudioStream.data) {
        localAudioStream.data.stop();
        localAudioStream.data.close();
      }
      clearInterval(intervalMicListen.data);
      // numberOfPidsToColor = 0;
      setNumberOfPidsToColor(0);
    }

    if (val === "camera") {
      if (localStream.data) {
        localStream.data.stop();
        localStream.data.close();
      }
    }

    if (val === "startVideoCall") {
      localStorage.setItem('videoCallMic', true);
      setCameraMicTesting(false);

      setTimeout(() => {
        setInCall(true);
      }, 500);

      // video call stats here
      startCall();
    }
  }

  function playSound() {
    console.log("play");
    var x = document.getElementById("myAudio");
    x.play();
  }

  return (
    <div
      className="App"
      style={{ height: "100vh", width: "100%", overflow: "hidden auto" }}
    >
      <audio id="myAudio">
        <source src={bouncing_plastic_bottle} type="audio/mpeg"></source>
      </audio>

      {inCall ? (
        <VideoCall
          setOpen={props.setOpen}
          setCall={props.setCall}
          setInCall={setInCall}
          token={token}
          channelName={props.chanelName}
          endCall={endCall}
        // handleSwitchCamera={handleSwitchCamera}
        // onScreenChoose={onScreenChoose}
        // width={1200}
        // height={720}
        />
      ) : (
        <div className="col-12 col-md-12">
          <div
            className="card shadow-sm"
            style={{ border: "1px solid #c6c6c6", height: "60%" }}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="f-w-600">Camera</label>
                    <select
                      className="form-control"
                      // onChange={() => changeMicOrCamera()}
                      onChange={(e) => changeCamera(e)}
                      id="cameraId"
                      defaultValue="1"
                    >
                      {devices.videolist.map((val, i) => (
                        <option key={i} value={val.deviceId}>
                          {val.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    id="test_stream"
                    className="my-2 mx-auto"
                    style={{ width: "80%", height: "40vh" }}
                  ></div>
                  {/* <div
                    id="test_stream"
                    className="d-flex justify-content-center py-3"
                    style={{ height: "30vh" }}
                  >
                    <Webcam style={{ width: "-webkit-fill-available" }} />
                  </div> */}

                  <div className="form-group">
                    <label className="f-w-600">Microphone</label>
                    <select
                      className="form-control"
                      // onChange={(e) => {
                      //   setSelAudDevice({
                      //     ...selAudDevice,
                      //     data: e.target.value,
                      //   });
                      //   changeMicOrCamera();
                      // }}
                      onChange={(e) => changeMic(e)}
                      id="microphone"
                      defaultValue="1"
                    >
                      {devices.audiolist.map((val, i) => (
                        <option key={i} value={val.deviceId}>
                          {val.label}
                        </option>
                      ))}
                    </select>

                    <div className="mt-4 d-flex justify-content-center">
                      <i className="fa fa-microphone fa-lg"></i>

                      <div className="d-flex">
                        {numbers.map((val, i) => (
                          <div
                            key={i}
                            className="pid ms-2"
                            style={{
                              background:
                                numberOfPidsToColor >= val.num
                                  ? val.color
                                  : "#e6e7e8",
                              height: "14px",
                              width: "10px",
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>

                    <div className="mt-4 d-flex justify-content-center">
                      <button
                        className="btn btn-primary-blue mx-auto"
                        onClick={() => playSound()}
                      >
                        Test Speakers
                      </button>

                      <button
                        className="btn btn-dark mx-auto"
                        onClick={() => {
                          props.setCall(false);
                          props.setOpen(false);
                          dispatch(open());
                          document
                            .querySelector(".page-main-header")
                            .classList.remove("open");
                          document
                            .querySelector(".page-sidebar")
                            .classList.remove("open");
                        }}
                      >
                        Close
                      </button>
                      <button
                        className="btn btn-dark mx-auto"
                        onClick={() => {
                          stopTestCamera("startVideoCall");
                        }}
                      >
                        Initiate Meeting
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default VideoApp;
