import React, { useState, useEffect, useReducer } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getCheckListTable,
  deleteCheckListTable,
  updateCheckListTable,
  createCheckListTable,
} from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Trash2, Edit, BarChart } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ChartistGraph from "react-chartist";

function FormTable() {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location.state", location.state);
  const { id } = useParams();

  const [totalRecords, setTotalRecords] = useState("");

  const [srNo, setSrNo] = useState(0);
  const [modal8, setModal8] = useState();
  const [chartData, setChartData] = useState({
    labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    series: [],
  });
  const customer_id = localStorage.getItem("customer_id");

  const initialState = {
    loading: false,
    error: "",
    tableList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          tableList: action.tableList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      tableList: state.tableList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchTables();
  }, [state.page, state.itemsPerPage]);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  function fetchTables(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      tableList: state.tableList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer_id: customer_id,
      checklist: id,
      withSurvey: "False",
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
        customer_id: customer_id,
        
      };
    }

    getCheckListTable(reqPayload).then(
      (res) => {
        console.log("res", res);
        // console.log('calMaxPage(res.data.count)', calMaxPage(res.data.count));
        setTotalRecords(
          res.data.payload.length > 0
            ? res.data.payload[0].file.length
            : res.data.payload.length
        );

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          tableList:
            res.data.payload.length > 0
              ? res.data.payload[0]
              : res.data.payload,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.payload.length)
              : calMaxPage(res.data.total),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 3000,theme:'light' });
        }
      }
    );
  }

  function deleteTable(val, i) {
    console.log("val", val);
    console.log("i", i);
    console.log("state.tableList", state.tableList);

    const payload = { ...state.tableList };
    payload.file.splice(i, 1);
    delete payload.createdDate;
    delete payload.updatedDate;
    delete payload.isActive;
    delete payload.survey;
    console.log("payload", payload);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // createCheckListTable
        // Firing delete api
        updateCheckListTable(payload).then(
          (res) => {
            console.log("res", res);
            toast.success("Table deleted successfully !", {
              autoClose: 1000, theme: "light",
            });
            fetchTables();
          },
          (err) => {
            console.log("err", err);
          }
        );
        // deleteCheckListTable({ Id: val.Id }).then(
        //   (res) => {
        //     console.log("res", res);
        //     toast.success("Table deleted successfully !", {
        //       autoClose: 3000,
        //     });
        //     fetchTables();
        //   },
        //   (err) => {
        //     console.log("err", err);
        //   }
        // );
      }
    });
  }

  function generateChart(val) {
    console.log("val", val);
    // setChartData
    toggle8();
  }

  return (
    <React.Fragment>
      <div className="fontFamily formTable py-4">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div
                className="title bg-primary-blue d-flex justify-content-between"
                id="card_title"
              >
                <p className="mb-0 f-24">
                  <span
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="text-light me-2"
                    style={{ fontSize: "20px", cursor: "pointer" }}
                  >
                    <i className="fa fa-arrow-circle-left"></i>
                  </span>
                  Define New Table For Checklist : {location.state.name}
                </p>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary-blue"
                    onClick={() =>
                      navigate(
                        `${process.env.PUBLIC_URL}/customeradmin/checklist/${id}/createtable`,
                        { state: { tableList: state.tableList } }
                      )
                    }
                  >
                    Create Table
                  </button>
                </div>

                {state.loading ? (
                  <div className="loader-box justify-content-center">
                    <div className="loader">
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                    </div>
                  </div>
                ) : state.tableList.file?.length > 0 ? (
                  <div
                    className="table-responsive "
                    style={{ maxHeight: "70vh" }}
                  >
                    <table className="table table-striped table-bordered mt-4">
                      <thead className="thead-light">
                        <tr className="f-18">
                          <th scope="col">{"Sequence"}</th>
                          <th scope="col">{"Table Name"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.tableList.file?.map((val, i) => (
                          <tr key={i} className="f-16">
                            <td>{val.sequence}</td>
                            <td>{val.tableName}</td>
                            <td>
                              <Edit
                                onClick={() =>
                                  navigate(
                                    `${process.env.PUBLIC_URL}/customeradmin/checklist/editform/${state.tableList.Id}`,
                                    {
                                      state: {
                                        data: state.tableList.file[i],
                                        allData: state.tableList,
                                        index: i,
                                      },
                                    }
                                  )
                                }
                                style={{ color: "#313131" }}
                                // onClick={() => { toggle8(); setDataForUpdation(val) }}
                                id={"editToolTip" + i}
                                className="cursor_pointer "
                                height={"18px"}
                                width={"18px"}
                              />
                              {/* </Link> */}
                              <UncontrolledTooltip
                                placement="bottom"
                                target={"editToolTip" + i}
                              >
                                {"Edit Record"}
                              </UncontrolledTooltip>

                              <Trash2
                                onClick={() => deleteTable(val, i)}
                                id={"deleteToolTip" + i}
                                className="cursor_pointer ms-1"
                                height={"18px"}
                                width={"18px"}
                              />
                              <UncontrolledTooltip
                                placement="bottom"
                                target={"deleteToolTip" + i}
                              >
                                {"Delete Record"}
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Table list is empty"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal8} toggle={toggle8} size="lg">
        <ModalHeader toggle={toggle8} className="bg-primary-blue">
          {"Select Columns To Generate Graph"}
        </ModalHeader>
        <ModalBody>
          <div style={{ position: "relative" }}>
            <ChartistGraph
              data={chartData}
              options={{
                height: "100%",
                fullWidth: true,
                chartPadding: {
                  right: 40,
                },
              }}
              type={"Line"}
              className="flot-chart-container"
            />
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default FormTable;
