import axios from "axios";
import client from "../constant/AxiosInstance";
import {
  AllCountries,
  StateByCountry,
  StatesByCountry,
  Cities,
  CitiesByState,
  Customer,
  updCustomer,
  Subscription,
  CustSubscription,
  sopById,
  CustomerType,
  UserAdministration,
  Role,
  AllCurrency,
  customerAdminUser,
  vesselList,
  vesselByUser,
  vesselType,
  sopMethodology,
  sop,
  delVesselFile,
  checkList,
  CustomerAdminUsers,
  survey,
  checkListItem,
  checkListById,
  SOPItem,
  AllRole,
  customerAdminUserByRole,
  generatePassword,
  generateOtp,
  resetPass,
  mailErrLogs,
  accessErrLogs,
  checkListNew,
  checklistSection,
  checklistItemNew,
  agoraToken,
  checklistItemAttachment,
  checklistItemOutcome,
  surveyReport,
  verifierComments,
  clonechecklist,
  survey_2,
  surveycounts,
  videoCall,
  vidRecordToken,
  checklistTable,
  surveyRecording,
  reportAttachment,
  checklistFormEle,
  renameAttachment,
  surveyFilter,
  customerGuestMaster,
  callInvitation,
  InviteAuth,
  CallInvite,
  sotp,
  actualCustSubsUsage,
  survey2,
  surveySectionV2,
  surveySectionItemsV2,
  checkListItemStatus,
  checklistItemAttachmentv3,
  nonConfirm,
  surveyv3,
  soc_category,
  noc_category,
  soc_master,
  noc_master,
  mla_master,
  nested_mla, nonConformanceSections, nonConformanceSectionsItems, tagFilter, checkListNewSurveyApi, cloneChecklistNew
} from "../constant/index";
import { getData } from "./APIinstance";
import { statusFilter } from "../components/ProjectComponents/Common/Functions/CommonFunctions";

// Vedam Apis

// Masters
export const getAllCountries = () => {
  return client.get(AllCountries);
};

export const getAllCurrency = () => {
  return client.get(AllCurrency);
};

export const getAllCustomerType = () => {
  return client.get(CustomerType);
};

export const getStatesByCountry = (id) => {
  return client.post(StateByCountry, { id: id });
};
export const getStates = (id) => {
  console.log("id", id);
  return client.get(StatesByCountry, { params: { country_name: id } });
};

export const getCities = (id) => {
  console.log("id", id);
  return client.get(Cities, { params: { state_name: id } });
};

export const getCitiesByState = (id) => {
  return client.post(CitiesByState, { id: id });
};

export const getAutoPassword = () => {
  return client.get(generatePassword);
};

export const genOtp = (payload) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${generateOtp}`, payload);
};

export const resetPassword = (payload) => {
  return axios.put(`${process.env.REACT_APP_API_URL}${resetPass}`, payload);
};

// Organisation / Customer
export const createCustomer = (payload) => {
  return client.post(Customer, payload);
};

export const sendCallInvitation = (payload) => {
  return client.post(callInvitation, payload);
};

export const updateCustomer = (payload) => {
  return client.put(Customer, payload);
};

export const updateCust = (payload) => {
  return client.put(updCustomer, payload);
};

export const deleteCustomer = (params) => {
  console.log("params", params);
  return client.delete(Customer, { params: params });
};

export const getCustomers = (params) => {
  return client.get(Customer, { params: params });
};

// Subscription
export const getSubscriptions = (params) => {
  return client.get(Subscription, { params: params });
};
export const createSubscription = (payload) => {
  return client.post(Subscription, payload);
};
export const updateSubscription = (payload) => {
  return client.put(Subscription, payload);
};

export const deleteSubscription = (params) => {
  return client.delete(Subscription, { params: params });
};

// Customer's Subscription

export const createCustomerSubscription = (payload) => {
  return client.post(CustSubscription, payload);
};

export const getCustomerSubscriptions = (params) => {
  return client.get(CustSubscription, { params: params });
};

export const deleteCustomerSubscriptions = (params) => {
  return client.delete(CustSubscription, { params: params });
};

export const updateCustomerSubscription = (payload) => {
  return client.put(CustSubscription, payload);
};

// User Adminstration

export const createUser = (payload) => {
  return client.post(UserAdministration, payload);
};

export const getUsers = (params) => {
  return client.get(UserAdministration, { params: params });
};

export const getCustomerAdminUsers = (params) => {
  return client.get(CustomerAdminUsers, { params: params });
};

export const deleteUser = (params) => {
  return client.delete(UserAdministration, { params: params });
};

export const updateUser = (payload) => {
  return client.put(UserAdministration, payload);
};

// Role Master

export const getRoles = (params) => {
  return client.get(Role, { params: params });
};

export const getAllRoles = () => {
  return client.get(Role);
};
// export const getAllRoles = () => {
//     return client.get(AllRole);
// }

export const getPaginatedRoles = (params) => {
  return client.get(Role, { params: params });
};

export const createRole = (payload) => {
  return client.post(Role, payload);
};

export const updateRole = (payload) => {
  return client.put(Role, payload);
};

export const deleteRole = (params) => {
  return client.delete(Role, { params: params });
};

// Mail Errro logs
export const getMailErrorLogs = (params) => {
  return client.get(mailErrLogs, { params: params });
};

// Access Errro logs
export const getAccessErrorLogs = (params) => {
  return client.get(accessErrLogs, { params: params });
};

// Customer Admin Start

// user
export const getCustomersUsers = (params) => {
  return client.get(customerAdminUser, { params: params });
};
export const getCustomersUsersByRole = (params) => {
  return client.get(customerAdminUserByRole, { params: params });
};

export const createCustomersUser = (payload) => {
  return client.post(customerAdminUser, payload);
};

export const createCustomersUserForGuest = (payload) => {
  return client.post(customerGuestMaster, payload);
};

export const updateCustomersUser = (payload) => {
  return client.put(customerAdminUser, payload);
};

export const deleteCustomersUser = (params) => {
  return client.delete(customerAdminUser, { params: params });
};

// vessel
export const getVesselTypes = () => {
  return client.get(vesselType);
};

export const getVesselByUser = (params) => {
  return client.get(vesselByUser, { params: params });
};

export const getVessels = (params) => {
  return client.get(vesselList, { params: params });
};

export const createVessel = (payload) => {
  return client.post(vesselList, payload);
};

export const updateVessel = (payload) => {
  return client.put(vesselList, payload);
};

export const deleteVessel = (params) => {
  return client.delete(vesselList, { params: params });
};

export const deleteVesselFile = (params) => {
  return client.delete(delVesselFile, { params: params });
};

// SOP Management

export const getMethodology = () => {
  return client.get(sopMethodology);
};

export const getSOP = (params) => {
  return client.get(sop, { params: params });
};
export const getSOPById = (params) => {
  return client.get(sopById, { params: params });
};

export const createSOP = (payload) => {
  return client.post(sop, payload);
};

export const updateSOP = (payload) => {
  return client.put(sop, payload);
};

export const deleteSOP = (params) => {
  return client.delete(sop, { params: params });
};

export const deleteSopItem = (params) => {
  return client.delete(SOPItem, { params: params });
};

// Check list management

export const getCheckList = (params) => {
  return client.get(checkList, { params: params });
};

export const getCheckListFormElement = (params) => {
  return client.get(checklistFormEle, { params: params });
};

export const createCheckListForm = (payload) => {
  return client.post(checklistFormEle, payload);
};
export const getCheckListTable = (params) => {
  return client.get(checklistTable, { params: params });
};
export const createCheckListTable = (payload) => {
  return client.post(checklistTable, payload);
};

export const updateCheckListTable = (payload) => {
  return client.patch(checklistTable, payload);
};

export const deleteCheckListTable = (params) => {
  return client.delete(checklistTable, { params: params });
};

export const updateCheckListForm = (payload) => {
  return client.patch(checklistFormEle, payload);
};

export const deleteCheckListForm = (params) => {
  return client.delete(checklistFormEle, { params: params });
};

export const getCheckListItemStatus = (params) => {
  return client.get(checkListItemStatus, { params: params });
};

export const getCheckListSection = (params) => {
  return client.get(checklistSection, { params: params });
};

export const getCheckListById = (params) => {
  return client.get(checkListById, { params: params });
};

export const getChecklist = (params) => {
  return client.get(checkListNew, { params: params });
};
export const getChecklistSurveyApi = (params) => {
  return client.get(checkListNewSurveyApi, { params: params });
};

export const createCheckList = (payload) => {
  return client.post(checkList, payload);
};
export const createCheckListNew = (payload) => {
  return client.post(checkListNew, payload);
};

export const createCheckListSection = (payload) => {
  return client.post(checklistSection, payload);
};

export const updateCheckListSection = (payload) => {
  return client.put(checklistSection, payload);
};

export const deleteChecklistSection = (params) => {
  return client.delete(checklistSection, { params: params });
};

export const updateCheckList = (payload) => {
  return client.put(checkList, payload);
};

export const updateCheckListNew = (payload) => {
  return client.put(checkListNew, payload);
};

export const deleteCheckList = (params) => {
  return client.delete(checkList, { params: params });
};

export const deleteCheckListNew = (params) => {
  return client.delete(checkListNew, { params: params });
};

export const deleteCheckListItem = (params) => {
  return client.delete(checkListItem, { params: params });
};

export const createChecklistItem = (payload) => {
  return client.post(checklistItemNew, payload);
};

export const getChecklistItem = (params) => {
  return client.get(checklistItemNew, { params: params });
};

export const updateChecklistItem = (payload) => {
  return client.put(checklistItemNew, payload);
};
export const deleteChecklistSecItem = (params) => {
  return client.delete(checklistItemNew, { params: params });
};

export const uploadChecklistItemAttachment = (payload) => {
  return client.post(checklistItemAttachment, payload);
};
export const updateChecklistItemAttachment = (payload) => {
  return client.put(checklistItemAttachment, payload);
};
export const getChecklistItemAttachment = (params) => {
  return client.get(checklistItemAttachment, { params: params });
};
export const delChecklistItemAttachment = (params) => {
  return client.delete(checklistItemAttachment, { params: params });
};
export const uploadChecklistItemAttachmentv3 = (payload) => {
     console.log("payload",payload)
  return client.post(checklistItemAttachmentv3, payload);
};
export const updateChecklistItemAttachmentv3 = (payload) => {
  return client.patch(checklistItemAttachmentv3, payload);
};
export const getChecklistItemAttachmentv3 = (params) => {
  return client.get(checklistItemAttachmentv3, { params: params });
};
export const getNonConfirmsList = (params) => {
  return client.get(nonConfirm, { params: params });
};
export const updateNonConfoList = (payload) => {
  return client.patch(nonConfirm, payload);
};
export const createNonConfoList = (payload) => {
  return client.post(nonConfirm, payload);
};

export const delChecklistItemAttachmentv3 = (params) => {
  return client.delete(checklistItemAttachmentv3, { params: params });
};

export const renameChecklistItemAttachment = (payload) => {
  return client.patch(renameAttachment, payload);
};

// Checklist cloning
export const cloneChecklist = (payload) => {
  return client.post(clonechecklist, payload);
};

//New checklist cloning 

export const cloneChecklist_new=(payload)=>{
  return client.post(cloneChecklistNew,payload)
}

// Verifiers
export const postVerifiersComments = (payload) => {
  return client.post(verifierComments, payload);
};

// Checklist outcome
export const getChecklistItemAnswer = (params) => {
  return client.get(checklistItemOutcome, { params: params });
};
export const postChecklistItemAnswer = (payload) => {
  return client.post(checklistItemOutcome, payload);
};
export const updateChecklistItemAnswer = (payload) => {
  return client.patch(checklistItemOutcome, payload);
};



// Survey management

export const getSurvey = (params) => {
  return client.get(survey, { params: params });
};

export const getSurveyAsPerFilter = (params) => {
  console.log("params", params);
  let url = surveyFilter;
  let res;
  if (params.statusList) {
    if (params.statusList.length === 0) {
      delete params.statusList;
      url = surveyFilter;
    } else {
      res = statusFilter(params.statusList);
      url = surveyFilter + "?statusList=[" + res + "]";
      delete params.statusList;
    }
  }
  console.log("url", url);
  return client.get(url, { params: params });
};

export const updateSurveyV1 = (params) => {
  return client.put(survey, params);
};

export const getSurveyV2 = (params) => {
  return client.get(survey2, { params: params });
};

export const getSurveySections = (params) => {
  return client.get(surveySectionV2, { params: params });
};

export const getSurveySectionsItems = (params) => {
  return client.get(surveySectionItemsV2, { params: params });
};

export const getNCSurveySectionsItems = (params) => {
  return client.get(nonConformanceSectionsItems, { params: params });
};

export const createSurvey = (payload) => {
  return client.post(survey, payload);
};
export const createSurveyv3 = (payload) => {
  return client.post(surveyv3, payload);
};

export const updateSurvey = (payload) => {
  return client.put(survey_2, payload);
};

export const deleteSurvey = (params) => {
  return client.delete(survey, { params: params });
};

export const getSurveyCounts = (params) => {
  return client.get(surveycounts, { params: params });
};

// survey report
export const getSurveyReport = (params) => {
  return client.get(surveyReport, { params: params });
};

export const fetchSurvyeApi = () => {
  let id = localStorage.getItem("SurveyData");
  let data = JSON.parse(localStorage.getItem("login_data"));
  let response = getData(
    `SurveyApi/?customer_id=${data.customer_id}&user=${data.user}&Id=${id}`
  );
  // let response = getData(`SurveyApi/?customer_id=7&pageNo=2&entriesPerPage=5000&user=102`);
  return response;
};

// Customer Admin End

// Agora

export const generateAgoraToken = (payload) => {
  return client.post(agoraToken, payload);
};

// Video Recording Apis

export const videoRecordingToken = (body) => {
  let response = client.post(vidRecordToken, body);
  return response;
};

export const acquire = (body, basicAuth) => {
  console.log("basicAuth", basicAuth);
  let response = axios.post(
    `${videoCall}${process.env.REACT_APP_AGORA_APPID}/cloud_recording/acquire`,
    body,
    {
      auth: basicAuth,
    }
  );

  return response;
};

export const startVCR = (data, basicAuth) => {
  console.log("basicAuth", basicAuth);
  let response = axios.post(
    `${videoCall}${process.env.REACT_APP_AGORA_APPID}/cloud_recording/resourceid/${data.url}/mode/mix/start`,
    data.body,
    {
      auth: basicAuth,
    }
  );

  return response;
};

export const stopVCR = (data, basicAuth) => {
  console.log("basicAuth", basicAuth);
  let response = axios.post(
    `${videoCall}${process.env.REACT_APP_AGORA_APPID}/cloud_recording/resourceid/${data.url}/sid/${data.sid}/mode/mix/stop`,
    data.body,
    {
      auth: basicAuth,
    }
  );

  return response;
};

export const postSurveyRecording = async (body) => {
  let response = await client.post(surveyRecording, body);

  return response;
};
export const putSurveyRecording = async (body) => {
  let response = await client.put(surveyRecording, body);

  return response;
};

export const getSurveyRecordings = async (params) => {
  let response = await client.get(surveyRecording, { params: params });
  return response;
};

export const getCallInviteHist = async (params) => {
  let response = await client.get(CallInvite, { params: params });
  return response;
};

export const loginGuestUser = async (data) => {
  let res = await axios.post(
    `${process.env.REACT_APP_API_URL}${InviteAuth}`,
    data
  );
  return res;
};

export const fetchOtp = async (data) => {
  let res = axios.post(`${process.env.REACT_APP_API_URL}${sotp}`, data);
  return res;
};
export const fetchCustSubsUsage = async (data) => {
  let res = client.get(
    `${process.env.REACT_APP_API_URL}${actualCustSubsUsage}`,
    { params: data }
  );
  return res;
};

// Already Present Theme Apis

export const fetchProductApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/product.json`);
};

export const fetchChatApi1 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chatMember.json`);
};

export const fetchChatApi2 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chat.chats.json`);
};

export const fetchEmailApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/email.json`);
};

export const fetchBookmaekApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/bookmark.json`);
};

export const fetchTodoApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/todo.json`);
};

export const fetchTaskApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/task.json`);
};

export const fetchLoginApi = () => {
  let data = JSON.parse(localStorage.getItem("login_data"));
  let response = getData(
    `SurveyApi/?customer_id=${data.customer_id}&user=${data.user}`
  );
  // let response = getData(`SurveyApi/?customer_id=7&pageNo=2&entriesPerPage=5000&user=102`);

  return response;
};

// Report
export const downloadReportAttachment = (params) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${reportAttachment}`, {
    params: params,
  });
};


//soc category 

export const get_soc_category = (params) => {
  return client.get(soc_category, { params: params })
}

export const post_soc_category = (payload) => {
  return client.post(soc_category, payload)
}

export const update_soc_category = (payload) => {
  return client.patch(soc_category, payload)
}

export const delete_soc_category = (params) => {
  return client.delete(soc_category, { params: params })
}
//noc category 

export const get_noc_category = (params) => {
  return client.get(noc_category, { params: params })
}

export const post_noc_category = (payload) => {
  return client.post(noc_category, payload)
}

export const update_noc_category = (payload) => {
  return client.patch(noc_category, payload)
}

export const delete_noc_category = (params) => {
  return client.delete(noc_category, { params: params })
}

// SOC master api's 

export const get_soc = (params) => {
  return client.get(soc_master, { params: params })
}

export const post_soc = (payload) => {
  return client.post(soc_master, payload)
}

export const update_soc = (payload) => {
  return client.patch(soc_master, payload)
}

export const delete_soc = (params) => {
  return client.delete(soc_master, { params: params })
}

// NOC master api's 

export const get_noc = (params) => {
  return client.get(noc_master, { params: params })
}

export const post_noc = (payload) => {
  return client.post(noc_master, payload)
}

export const update_noc = (payload) => {
  return client.patch(noc_master, payload)
}

export const delete_noc = (params) => {
  return client.delete(noc_master, { params: params })
}

// Multi level answer  api's
export const get_mla = (params) => {
  return client.get(mla_master, { params: params })
}

export const post_mla = (payload) => {
  return client.post(mla_master, payload)
}

export const update_mla = (payload) => {
  return client.patch(mla_master, payload)
}

export const delete_mla = (params) => {
  return client.delete(mla_master, { params: params })
}

//nested api 

export const get_nested_mla = (params) => {
  return client.get(nested_mla, { params: params })
};


// Non conformance sections
export const get_nonconformance_sections = (params) => {
  return client.get(nonConformanceSections, { params: params })
};

// Non conformance sections
export const fetchByTagFilter = (params) => {
  return client.get(tagFilter, { params: params })
};