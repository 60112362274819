import React, { useState, useEffect, useReducer } from "react";
import "./CustomerManagement.css";
import "../../Common/Css/Common.css";
import CreateCustForm from "./CreateCustForm";
import {
  getCustomers,
  deleteCustomer,
  updateCustomer,
  updateCust,
} from "../../../../api/index";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Trash2, Edit, Eye } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import EditCustForm from "./EditCustForm";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";

function CustomerManagement() {
  const [modal8, setModal8] = useState();
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const user = localStorage.getItem("user");
  const [totalRecords, setTotalRecords] = useState("");

  const initialState = {
    loading: false,
    error: "",
    customerList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          customerList: action.customerList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  // Deleting customer
  const delCustomer = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteCustomer({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Organisation deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            setTimeout(() => {
              CallByFetchCust();
            }, 200);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      customerList: state.customerList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    CallByFetchCust();
  }, [state.page, state.itemsPerPage]);

  function approveCustomer(val) {
    var formdata = new FormData();
    formdata.append("Id", val.Id);
    formdata.append("status", "Approved");

    updateCust(formdata).then(
      (res) => {
        console.log("res", res);

        toast.success("Organisation approved successfully !", {
          autoClose: 3000,
          theme: "light",
        });
        CallByFetchCust();
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function CallByFetchCust(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      customerList: state.customerList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
      };
    }

    getCustomers(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          customerList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  function viewUser(val) {
    // alert(val);
    window.location.href = `${process.env.PUBLIC_URL}/admin/customeruserprofile/${val}`;
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {/* Edit Customer modal */}
      <EditCustForm
        modal8={modal8}
        toggle8={toggle8}
        data={dataForUpdation}
        CallByFetchCust={CallByFetchCust}
      />

      <div className="vdi_customer py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  <p className="mb-0 f-24">Organisation Management</p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4">
                    <input
                      style={{ maxWidth: "300px" }}
                      className="form-control"
                      type="text"
                      name="search"
                      placeholder="Search"
                      // checking if search value is not empty then only calling the function
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          CallByFetchCust("search", e.target.value);
                          setApiBySearch(true);
                        } else {
                          CallByFetchCust();
                        }
                      }}
                    />

                    <CreateCustForm CallByFetchCust={CallByFetchCust} />
                  </div>

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.customerList.length > 0 ? (
                    <div
                      className="table-responsive "
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                          <tr
                            className="f-18"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            {/* <th scope="col">{"SrNo"}</th> */}
                            <th scope="col">{"Id"}</th>
                            <th scope="col">{"Name"}</th>
                            <th scope="col">{"Type"}</th>
                            <th scope="col">{"Email"}</th>
                            <th scope="col">{"Contact"}</th>
                            <th scope="col">{"Address"}</th>
                            <th scope="col">{"City"}</th>
                            <th scope="col">{"State"}</th>
                            <th scope="col">{"Country"}</th>
                            <th scope="col">{"Website"}</th>
                            <th scope="col">{"DateOfCreation"}</th>
                            <th scope="col">{"DateOfUpdate"}</th>
                            <th scope="col">{"LastAccessed"}</th>
                            <th scope="col">{"Logo"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.customerList.map((val, i) => (
                            <tr key={i} className="f-16">
                              {/* <th scope="row">{i + srNo + 1}</th> */}
                              <th scope="row">{val.Id}</th>
                              <td>{val.name}</td>
                              <td>
                                {val.customerType === null
                                  ? "NA"
                                  : val.customerType}
                              </td>
                              <td>{val.email}</td>
                              <td>{val.contactNumber}</td>
                              <td>{val.address}</td>
                              <td>
                                {val.city === null || val.city.length === 0
                                  ? "NA"
                                  : val.city}
                              </td>
                              <td>
                                {val.state === null || val.state.length === 0
                                  ? "NA"
                                  : val.state}
                              </td>
                              <td>
                                {val.country === null ||
                                val.country.length === 0
                                  ? "NA"
                                  : val.country}
                              </td>
                              <td>{val.website}</td>
                              <td>{val.createdDate}</td>
                              <td>{val.updatedDate}</td>
                              <td>
                                {val.user?.last_login === null
                                  ? "NA"
                                  : val.user?.last_login}
                              </td>
                              <td>
                                {val.logo === null ? (
                                  "NA"
                                ) : (
                                  <img
                                    src={val.logo}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                )}
                              </td>

                              <td>
                                <Edit
                                  onClick={() => {
                                    toggle8();
                                    setDataForUpdation(val);
                                  }}
                                  id={"editToolTip" + i}
                                  className="cursor_pointer "
                                  height={"18px"}
                                  width={"18px"}
                                />

                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"editToolTip" + i}
                                >
                                  {"Edit Record"}
                                </UncontrolledTooltip>

                                {/* <Eye
                                                                        onClick={() => { viewUser(val.Id) }}
                                                                        id={"viewToolTip" + i}
                                                                        className='cursor_pointer ms-1'
                                                                        height={'18px'}
                                                                        width={'18px'} />

                                                                    <UncontrolledTooltip placement="bottom" target={"viewToolTip" + i}>
                                                                        {"View Record"}
                                                                    </UncontrolledTooltip> */}

                                {/* <Trash2
                                                                        onClick={() => delCustomer(val)}
                                                                        id={"deleteToolTip" + i}
                                                                        className='cursor_pointer ml-1'
                                                                        height={'18px'} width={'18px'} />
                                                                    <UncontrolledTooltip placement="bottom" target={"deleteToolTip" + i}>
                                                                        {"Delete Record"}
                                                                    </UncontrolledTooltip> */}

                                {user === "superadmin_approver" ? (
                                  val.status === "Pending" ? (
                                    <button
                                      onClick={() => approveCustomer(val)}
                                      className="btn btn-pill btn-xs btn-primary-blue"
                                    >
                                      Approve
                                    </button>
                                  ) : null
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Organisation Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Organisation list is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                customerList: state.customerList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC - 10);
                            }}
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                customerList: state.customerList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC + 10);
                            }}
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CustomerManagement;
