import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User, Mail, Lock, Settings, LogOut } from 'react-feather';
// import { firebase_app } from "../../../data/config";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react'
import { EditProfile, Inbox, LockScreen } from '../../../constant'
import Ellipse from '../../../assets/images/Vedam/Ellipse.png';
import { getAuth, signOut } from "firebase/auth";

const UserMenu = () => {
    const [profile, setProfile] = useState('');
    // auth0 profile
    const { logout } = useAuth0()
    const authenticated = JSON.parse(localStorage.getItem("authenticated"))
    const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))
    const navigate = useNavigate();
    const user = localStorage.getItem('name');
    const usersname = localStorage.getItem('usersname');

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || Ellipse);
        console.log("user", user);
    }, []);

    // const Logout_From_Firebase = () => {
    //     console.log("Logout_From_Firebase call");
    //     localStorage.removeItem('profileURL')
    //     localStorage.removeItem('token');
    //     firebase_app.auth().signOut()
    //     navigate(`${process.env.PUBLIC_URL}/login`)
    // }

    // const  Logout_From_Auth0 = () =>  {
    //     localStorage.removeItem("auth0_profile")
    //     localStorage.setItem("authenticated",false)
    //     navigate(`${process.env.PUBLIC_URL}/login`)
    //     logout()
    // }

    const logoutUser = () => {

        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });

        // localStorage.removeItem('access');
        // localStorage.removeItem('customer_id');
        // localStorage.removeItem('login_data');
        // localStorage.removeItem('firebaseAuthFail');        
        // localStorage.removeItem('subscription');
        // localStorage.removeItem('videoCallMic');
        // localStorage.removeItem('videoCallAudio');
        localStorage.clear();
        navigate(`${process.env.PUBLIC_URL}/signin`);
    }

    const redirectForgotPass = () => {
        // localStorage.removeItem('access');
        // localStorage.removeItem('customer_id');
        // localStorage.removeItem('login_data');
        navigate(`${process.env.PUBLIC_URL}/forgotpassword`);
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="d-flex align-items-center" style={{ position: "relative" }}>
                    <p
                        id='imageUserInfo'
                        className='f-w-600 f-20 text-capitalize'
                        style={{
                            position: 'absolute',
                            top: '50%', left: '50%', transform: 'translate(-50%, -50%)', opacity: 0.5
                        }}>{user === "Surveyor" || user === "customeradmin" || user === "Verifier"|| user === "SiteSurveyor" ? usersname.charAt(0) : user.charAt(0)}</p>

                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={authenticated ? auth0_profile.picture : profile} alt="header-user" />
                    {/* <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div> */}
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    {/* <li><Link to={`${process.env.PUBLIC_URL}/users/userEdit`}><User />{EditProfile}</Link></li> */}
                    {/* <li><Link to={`${process.env.PUBLIC_URL}/email-app/emailDefault`}><Mail />{Inbox}</Link></li> */}
                    {/* <li><a href="#javascript"><Lock />{LockScreen}</a></li> */}
                    {/* <li><a href="#javascript"><Settings />{"Settings"}</a></li> */}
                    <li style={{ wordWrap: 'break-word' }} className='text-muted f-14'>
                        {user === "Surveyor" || user === "customeradmin" || user === "Verifier" || user === "SiteSurveyor" ? usersname : user}
                    </li>
                    <hr className='my-0' />

                    {user === "Surveyor" || user === "customeradmin" || user === "Verifier" ?
                        <li><a onClick={() => redirectForgotPass()}  ><Settings /> {"Reset Password"}</a></li>
                        : null}


                    <li><a onClick={authenticated ? logoutUser : logoutUser}  ><LogOut /> {"Log Out"}</a></li>

                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;