import firebase from "firebase/compat/app";
import db from "../../../../data/config";
import Compressor from "compressorjs";
import {
  getStorage,
  uploadBytes,
  ref as abcd,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const localData = JSON.parse(localStorage.getItem("login_data"));

export const compressFile = (file) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        resolve(result);
      },
      error(error) {
        reject(error);
      },
    });
  });
  // const image = file;

  // new Compressor(image, {
  //   quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
  //   success: (compressedResult) => {
  //     // compressedResult has the compressed file.
  //     // Use the compressed file to upload the images to your server.
  //     console.log("file", file);
  //     console.log("compressedResult", compressedResult);
  //   },
  // });
};

export const convertDateYYMMDD = (d, divider) => {
  var weekDate = new Date(d);
  const date = weekDate.getDate();
  const month = weekDate.getMonth() + 1;
  const year = weekDate.getUTCFullYear();

  return year + divider + month + divider + date;
};

export const convertDate = (d, divider) => {
  var weekDate = new Date(d);
  const date = weekDate.getDate();
  const month = weekDate.getMonth() + 1;
  const year = weekDate.getUTCFullYear();

  return date + divider + month + divider + year;
};

export const calMaxPage = (counts) => {
  const maxPage = Math.floor(counts / 10);

  if (counts % 10 === 0) {
    return maxPage;
  } else {
    return maxPage + 1;
  }
};

export function generateUserId() {
  var length = 4,
    // charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    charset = "0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  return retVal;
}

export function generateRandomString() {
  var length = 6,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  return retVal;
}

export function generateRandomNumber() {
  var length = 6,
    charset = "0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  return retVal;
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function secondsToTime(seconds) {
  const arr = new Date(seconds * 1000).toISOString().substr(11, 8).split(":");

  const days = Math.floor(seconds / 86400);
  arr[0] = parseInt(arr[0], 10) + days * 24;
  if (arr[0] < 9) {
    arr[0] = "0" + arr[0];
  }
  console.log(arr[0]);

  console.log(arr.join(":"));
  return arr.join(":");
}

export function convertUrl(url) {
  // console.log("url", url);
  var result;

  if (url !== null || url !== undefined) {
    result = url.split("?");
    // console.log("result",result);
  }
  // console.log("result[0]",result[0]);
  return result[0];
}

export async function updateCustomerVideoCallMins(
  startTime,
  seconds,
  customerId
) {
  console.log("startTime", startTime);
  console.log("seconds", seconds);
  const CustomerVideoCallMins = await query(
    collection(db, "CustomerVideoCallMins"),
    where(
      "customerId",
      "==",
      localData == null ? customerId : localData.customer_id
    ),
    where("year", "==", startTime.getFullYear()),
    where("month", "==", startTime.getMonth() + 1)
  );

  const CustVidCallStat = await getDocs(CustomerVideoCallMins);

  if (CustVidCallStat.empty === true) {
    await db.collection("CustomerVideoCallMins").add({
      customerId: localData == null ? customerId : localData.customer_id,
      month: startTime.getMonth() + 1,
      year: startTime.getFullYear(),
      totalSeconds: seconds,
    });
    window.location.reload();
  } else {
    CustVidCallStat.forEach((val) => {
      console.log(val.id, " => ", val.data());
      const data = val.data();

      db.collection("CustomerVideoCallMins")
        .doc(val.id)
        .update({
          totalSeconds: data.totalSeconds + seconds,
        });

      window.location.reload();
    });
  }
}

// Checking customer video min consumption with actual subscription plan

export function validateCallMinsWithSubscriptionPlan(
  customer_id,
  startYear,
  EndYear,
  subscriptionPlanCallMinutes
) {
  const planStartYear = new Date(startYear).getFullYear();
  const planEndYear = new Date(EndYear).getFullYear();

  const currDate = new Date(new Date().setHours(0, 0, 0, 0));
  const planStartDate = new Date(
    new Date(new Date(startYear)).setHours(0, 0, 0, 0)
  );
  const planEndDate = new Date(
    new Date(new Date(EndYear)).setHours(0, 0, 0, 0)
  );

  console.log("currDate.getTime()", new Date(currDate).getTime());
  console.log("currDate.getTime()", new Date(currDate));
  console.log("planStartDate", planStartDate.getTime());
  console.log("planStartDate", planStartDate);
  console.log("planEndDate", planEndDate.getTime());
  console.log("planEndDate", planEndDate);
  console.log(currDate >= planStartDate);
  console.log(currDate <= planEndDate);

  console.log(currDate >= planStartDate && currDate <= planEndDate);

  if (currDate >= planStartDate === true && currDate <= planEndDate === true) {
    getAuth();
    const myDocRef = db
      .collection("CustomerVideoCallMins")
      .where("customerId", "==", customer_id);
    // .where("year", "==", planStartYear)
    // .where("year", "==", planEndYear);
    let isTrue = false;

    return new Promise((resolve, reject) => {
      myDocRef.onSnapshot((querySnapshot) => {
        const items = [];
        var totalsecs = 0;
        querySnapshot.forEach((doc) => {
          console.log("doc.data()", doc.data());
          if (
            doc.data().year == planStartYear ||
            doc.data().year == planEndYear
          ) {
            items.push(doc.data());
            const data = doc.data();
            totalsecs = totalsecs + data.totalSeconds;
          }
        });

        console.log("items", items);
        console.log("totalsecs", totalsecs);
        console.log("totalmins", Math.floor(totalsecs / 60));
        console.log(subscriptionPlanCallMinutes);

        if (Math.floor(totalsecs / 60) < subscriptionPlanCallMinutes) {
          console.log("if true");
          isTrue = true;
          resolve(isTrue);
        } else {
          console.log("else false");
          isTrue = false;
          resolve(isTrue);
        }
      });
    });
  } else {
    console.log("outer else false");
    return Promise.resolve(false);
  }
}

export function statusFilter(val) {
  let statusArr = [];

  for (let i = 0; i < val.length; i++) {
    let data = val[i].name;
    if (data.toLowerCase() === "inprogress") {
      statusArr.push(JSON.stringify("InProgress"));
    } else if (data.toLowerCase() === "submitted") {
      statusArr.push(JSON.stringify("Signed"));
    } else if (data.toLowerCase() === "assigned") {
      statusArr.push(JSON.stringify("Active"));
    } else if (data.toLowerCase() === "CorrectionRequired") {
      statusArr.push('"Correction Required"');
    } else {
      statusArr.push(JSON.stringify(data));
    }
  }

  let result = statusArr.join(",");
  console.log("result", result);
  return result;
}


export function extractExtension(url) {
  // Split the URL by dot (.) to separate the file name and extension
  const parts = url.split('.');

  // Get the last part of the array, which should be the extension
  const extension = parts[parts.length - 1];

  return extension;
}