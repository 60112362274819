import React from 'react'
import axios from "axios";

import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
export const Show_suvey_report = (props) => {
    const [pdfUrl, setPdfData] = useState(null)
    const [loader, setLoader] = useState(true);
    let location=useLocation();
  ///  console.log("location.state.data.surveyType", location.state.data.surveyType)
    const localData = JSON.parse(localStorage.getItem("login_data"));
    const token = localStorage.getItem("access");
    const base64 = btoa(token);
    const env = `${process.env.REACT_APP_ENV}`;
    // console.log(token);
    //console.log(localData, env);
    const x =`${process.env.REACT_APP_REPORT_URL}`
    const y=`${process.env.REACT_APP_SIRE_REPORT_URL}`
    console.log(props,"props");
   
    const Get_report = async () => {
      let url; 
        const data = {
          env: env,
          customer_id: localData.customer_id,
          survey_id: props.id.toString(),
          authToken: base64,
        };
        let z=false;
         
        if(location.state?.data?.surveyType ){
          z=false;
        
        }
        else{
          if(location.state?.data?.surveyType=="Sire"){
            z=true;
          }
          
        }


      
        if(props.type==="Sire"  || z ){
          url=y;
        }
        else{
          url=x; 
        }
        
         //console.log(data)
    
        axios
          .post(url, data, { responseType: 'arraybuffer' })
          .then(
            (res) => {
              console.log('res', res)
              if (res.status === 200 || res.status === 201) {
                const pdfData = new Blob([res.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfData);
                setPdfData(pdfUrl);
                setLoader(false);
              }
            },
            (err) => {
              console.log('err.response.status', err.response.status)
              if (err.response.status === 400) {
                console.log('err.response', err.response)
    
              }
            },
          )
      };

      const handleclick = () => {
        const anchor = document.createElement("a");
        anchor.href = pdfUrl;
        anchor.download = `${props.name}_${props.id}.pdf`;
        anchor.click();
      };
      useEffect(async () => {
        await Get_report();
      }, []);
  return (
    <div className='card-body'>
          {loader && (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            )}

        <iframe src={pdfUrl} width="100%" height="700px"></iframe>

        <div className="d-flex justify-content-end">

            {
                loader?( <button
                    className="btn btn-primary-blue mt-4 "
                  
                  >
                    {"Generating Report..."}
                  </button>):( <button
                className="btn btn-primary-blue mt-4 "
                onClick={handleclick}
              >
                {"Download Report"}
              </button>)
            }
             
    </div>
    </div>
  )
}
