import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Buffer } from "buffer";
import { Accordion } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WATCH_LOGIN_LIST } from "../../../../../../../redux/actionTypes";
import Loader from "../../../../../../common/loader";
import SignatureCanvas from "react-signature-canvas";
import {
  axiosPostData,
  getData,
  getStringData,
  putData,
} from "../../../../../../../api/APIinstance";
import axios from "axios";
import { toast } from "react-toastify";
import SurveyReport from '../../../../SurveyReport';
import { generateRandomNumber } from '../../../../../../ProjectComponents/Common/Functions/CommonFunctions';

const PreviewPage = () => {
  const appData = useSelector((content) => content.LoginApp.login);
  const [isOpen, setIsOpen] = useState(0);
  const [comments, setComments] = useState();
  const [error, setError] = useState({
    comError: "",
    signError: "",
  });
  const [modal8, setModal8] = useState();
  const [signed, setSigned] = useState(true);
  const [loader, setLoader] = useState(true);
  const [surveyData, setSurveyData] = useState();
  const [pdfFile, setPDFfile] = useState();
  const [Loc, setLoc] = useState();
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const { id } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  useEffect(() => {
    const data = appData.filter((el) => {
      return el.Id == id;
    });
    console.log("data", data);
    setSurveyData(data[0]);
    if (appData.length) {
      setLoader(false);
    }
  }, [appData]);

  useEffect(async () => {
    dispatch({ type: WATCH_LOGIN_LIST });
    getLocation();
  }, []);

  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });

  const toggle8 = () => {
    setModal8(!modal8);
  };

  // const [width, setWidth] = useState(0);
  // const canvRef = React.useRef(null);
  // React.useLayoutEffect(() => {
  //   setWidth(canvRef.current.);
  // }, []);

  let sigPad = {};
  const clear = () => {
    sigPad.clear();
  };
  const trim = async () => {
    if (!!comments) {
      handleStatus();
      let photo = await {
        trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL("image/png"),
      };
      await fetch(photo.trimmedDataURL, {
        headers: { "Content-Type": "image/png" },
      })
        .then((res) => res.blob())
        .then(async (bb) => {
          const file = await new File([bb], new Date().getTime() + ".png", { type: "image/png" });
          const userId = JSON.parse(localStorage.getItem("login_data"));
          if (!!Loc) {
            const formData = new FormData();
            formData.append("sign", file);
            formData.append("survey", id);
            formData.append("surveyor", userId.user);
            formData.append("lat", Loc.lat);
            formData.append("long", Loc.long);
            if (!!file) {
              const response = await axiosPostData(
                "SurveyorSignature/",
                formData
              );
              if (response.status == 201) {
                toast.success("Signature Submitted Successfully...", {
                  autoClose: 3000,
                  theme: "light",
                  
                });
                setSigned(false);
                console.log("--------------dispatchh-------------");
                dispatch({ type: WATCH_LOGIN_LIST });
              }
            } else {
              setError({ ...error, signError: "Please give Signature!!" });
            }
          } else {
            window.location.reload();
          }
        });
    } else {
      setError({ ...error, comError: "Please Comment!!" });
    }
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition);
    }
  }

  const showPosition = (position) => {
    setLoc({
      lat: Number(Number(position.coords.latitude).toFixed(6)),
      long: Number(Number(position.coords.longitude).toFixed(6)),
    });
  };

  const handleStatus = async () => {
    setLoader(true);
    let params = {
      Id: id,
      status: "Signed",
      remarks: comments,
    };
    if (!!comments) {
      let response = await putData("SurveyApi/", params);
      if (response.status == "Signed") {
        if (!!!pdfFile) {
          // handlePdf();
        }
        toast.success("Survey Signed Successfully...", {
          autoClose: 3000,
          theme: "light",
          
        });
      }
    } else {
      setError({ ...error, comError: "Please Comment!!" });
    }
  };

  const handlePdf = async () => {
    setLoader(true);
    if (pdfFile == null) {
      let data = JSON.parse(localStorage.getItem("login_data"));
      let pdf = await authAxios(
        `${process.env.REACT_APP_API_URL}/SurveyReport/?customer_id=${data.customer_id}&survey=${id}`
      );
      if (pdf.data) {
        toast.success("PDF file Generated Successfully...", {
          autoClose: 3000,
          theme: "light",
          
        });
        var base64Str = Buffer.from(pdf.data).toString("base64");

        var binaryString = window.atob(base64Str);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        var arrBuffer = bytes;

        var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        pdf.data = window.URL.createObjectURL(newBlob);
        setPDFfile(pdf.data);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (surveyData?.status == "Signed") {
      // handlePdf();
      setSigned(false);
    }
  }, [surveyData]);
  // console.log(width);
  const navigate = useNavigate();

  return (
    <Fragment>
      {loader ? (
        <div className="loader-box justify-content-center">
          <div className="loader">
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
          </div>
        </div>
      ) : (
        <Container fluid={true}>
          <div className="card  overflow-hidden mt-4">
            <div
              className="card-header d-flex"
              style={{ background: "#2264AB", padding: "12px 24px" }}
            >
              <span
                onClick={() => {
                  navigate(-1);
                }}
                className="text-light"
                style={{ fontSize: "20px", cursor: "pointer" }}
              >
                <i className="fa fa-arrow-circle-left"></i>
              </span>
              <span
                className="text-light"
                style={{ fontSize: "24px", marginLeft: "15px" }}
              >
                PREVIEW
              </span>
            </div>
            <div className="card-body">
              <div className="row mt-2">
                <div className="col">
                  {!!surveyData &&
                    surveyData.checklist.checklist_sections.map((el) => {
                      return (
                        <Accordion defaultActiveKey="0">
                          <Card
                            style={{
                              border: "1px solid #000000",
                              cursor: "pointer",
                              width: "100%",
                              marginBottom: "20px",
                              overflow: "hidden",
                            }}
                          >
                            <CardHeader
                              // style={{  }}
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "16px",
                              }}
                              onClick={() => toggle(el.id)}
                            >
                              <h5 className="mb-0">{el.sectionName}</h5>
                              {isOpen == el.id ? (
                                <span style={{ fontSize: "20px" }}>
                                  <i className="fa fa-caret-up"></i>
                                </span>
                              ) : (
                                <span style={{ fontSize: "20px" }}>
                                  <i className="fa fa-sort-desc"></i>
                                </span>
                              )}
                            </CardHeader>
                            <Collapse isOpen={isOpen === el.id}>
                              <CardBody>
                                {el.section_checklist_item.map((el1) => {
                                  return (
                                    <Accordion defaultActiveKey="0">
                                      <Card
                                        style={{
                                          border: "1px solid #000000",
                                          cursor: "pointer",
                                          width: "100%",
                                          marginBottom: "20px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <CardHeader
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            cursor: "pointer",
                                            padding: "16px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <h5
                                            className="mb-0"
                                            style={{
                                              width: "calc(100% - 20px)",
                                            }}
                                          >
                                            {el1.section}
                                          </h5>
                                          <div
                                            className="bg-success"
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                              borderRadius: "50px",
                                              border: "1px solid black",
                                            }}
                                          ></div>
                                        </CardHeader>
                                      </Card>
                                    </Accordion>
                                  );
                                })}
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Accordion>
                      );
                    })}
                </div>

                {surveyData.status === "Verified" ?
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary-blue text-light"
                      onClick={() => {
                        toggle8();
                      }}
                    >
                      View Report
                    </button>
                  </div>
                  : null}

                {
                  surveyData.status !== "Verified" ?
                    ((state) && !signed) || surveyData.status === "Signed" ? (
                      <Row>
                        {/* <iframe
                      src={`${pdfFile}`}
                      title="Preview"
                      style={{ height: "90vh", width: "100%" }}
                    ></iframe> */}
                        <SurveyReport id={id} />
                      </Row>
                    ) : (
                      <>
                        <Row className="justify-content-center mt-3">
                          <Col sm="12" xl="6">
                            <div className="row">
                              <div className="col">
                                <div className="form-group mb-0">
                                  <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea4"
                                    rows="3"
                                    placeholder="Overall Comment"
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    style={{
                                      border: "1px solid #000000",
                                      width: "100%",
                                      marginBottom: "20px",
                                      overflow: "hidden",
                                      borderTopLeftRadius: "8px",
                                      borderTopRightRadius: "8px",
                                    }}
                                  ></textarea>
                                </div>
                                <div className="text-danger">{error.comError}</div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col sm="12" xl="6">
                            <div
                              // ref={canvRef}
                              className="my-4 mx-auto"
                              style={{
                                border: "1px solid #000000",
                                overflow: "hidden",
                                borderRadius: "7px",
                                maxWidth: "700px",
                              }}
                            >
                              <SignatureCanvas
                                ref={(ref) => {
                                  sigPad = ref;
                                }}
                                penColor="black"
                                canvasProps={{
                                  className: "sigCanvas ",
                                  width: 700,
                                  height: 200,
                                }}
                              />
                              <div className="text-danger">{error.signError}</div>
                            </div>
                            <div
                              style={{
                                gap: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                className="btn btn-primary text-light"
                                // style={{ background: "#0B0968" }}
                                onClick={clear}
                              >
                                ReSign
                              </button>
                              <button
                                className="btn btn-primary text-light"
                                // style={{ background: "#0B0968" }}
                                onClick={trim}
                              >
                                Submit
                              </button>
                              <button
                                className="btn btn-primary text-light"
                                // style={{ background: "#0B0968" }}
                                onClick={() => {
                                  // handlePdf();
                                  toggle8();
                                }}
                              >
                                Preview Report
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )
                    : null
                }
              </div>
            </div>
          </div>
        </Container>
      )}


      <Modal isOpen={modal8} toggle={toggle8} size="xl" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {"Survey Report Preview"}
        </ModalHeader>
        <ModalBody>
          {/* <iframe
            src={`${pdfFile}`}
            title="Preview"
            style={{ height: "90vh", width: "100%" }}
          ></iframe> */}
          <SurveyReport id={id} />

        </ModalBody>
        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={toggle8}
            className="btn btn-primary-blue float-right"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      {/* <Loader loader={loader} /> */}
    </Fragment>
  );
};
export default PreviewPage;
