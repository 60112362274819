import React, { useState, useEffect } from 'react'
import {
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import { PhoneOff, Mic, MicOff, Video, VideoOff, RefreshCcw, Share } from 'react-feather';
import '../Common/Css/Common.css';
import VideoCallRecording from './VideoCallRecording';
import ScreenShare from './ScreenShare';
import { useParams } from "react-router-dom";

var config = ClientConfig;
config = {
    mode: "rtc", codec: "vp8",
};

function Controls(props) {
    const useClient = createClient(config);
    const client = useClient();
    const { tracks, setInCall, setStart, videoTrackScreenShare, onScreenChoose } = props;
    const [hide, setHide] = useState(false);
    const [trackState, setTrackState] = useState({ video: true, audio: true });

    const GuestVideoCall = localStorage.getItem('GuestVideoCall');
    const { id } = useParams();

    useEffect(() => {
        const url = window.location.href;

        console.log("url.includes", url.includes("call"));

        if (url.includes("call")) {
            setHide(true);
        } else {
            setHide(false);
        }

    }, []);



    const mute = async (type) => {
        if (type === "audio") {
            await tracks[0].setEnabled(!trackState.audio);
            setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
            });
        } else if (type === "video") {
            await tracks[1].setEnabled(!trackState.video);
            setTrackState((ps) => {
                return { ...ps, video: !ps.video };
            });
        }
    }

    const leaveChannel = async () => {
        await client.leave();
        console.log("client.getListeners", client.getListeners);
        client.removeAllListeners();
        tracks[0].close();
        tracks[1].close();
        setStart(false);
        setInCall(false);

        await props.endCall()


        // if (GuestVideoCall === true) {

        // } else {

        // }

    };





    return (
        <React.Fragment>
            <div className="controls">
                <div className='d-flex justify-content-center'>

                    {trackState.audio ?
                        <div className='video_call_btn cursor_pointer shadow' id='micOnBtn' onClick={() => mute("audio")}>
                            <Mic
                                className=' '
                                style={{ color: '#313131', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                height={'18px'}
                                width={'18px'}
                                id={"leave"}
                            />
                        </div>
                        :
                        <div className='video_call_btn cursor_pointer shadow' id='micOffBtn' onClick={() => mute("audio")}>
                            <MicOff
                                className='cursor_pointer '
                                style={{ color: '#313131', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                height={'18px'}
                                width={'18px'}
                                id={"leave"}
                            />
                        </div>
                    }

                    {/* <div className='video_call_btn shadow' style={{ background: "#e3e3e3" }}>
                        <p className={trackState.audio ? "on" : ""}
                            onClick={() => mute("audio")}>
                            {trackState.audio ? "MuteAudio" : "UnmuteAudio"}
                        </p>


                    </div> */}

                    {trackState.video ?
                        <div className='video_call_btn cursor_pointer shadow ms-3 ' id='videoOnBtn' onClick={() => mute("video")}>
                            <Video
                                className=' '
                                style={{ color: '#313131', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                height={'18px'}
                                width={'18px'}
                                id={"leave"}
                            />
                        </div>
                        :
                        <div className='video_call_btn cursor_pointer shadow ms-3 ' id='videoOffBtn' onClick={() => mute("video")}>
                            <VideoOff
                                className='cursor_pointer '
                                style={{ color: '#313131', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                height={'18px'}
                                width={'18px'}
                                id={"leave"}
                            />
                        </div>
                    }

                    {/* <div className='video_call_btn cursor_pointer shadow ms-3 '
                        id='screenSjaring'
                        onClick={() => onScreenChoose(videoTrackScreenShare === null ? "start" : "stop")}>
                        <Share
                            className='cursor_pointer'
                            style={{ color: '#313131', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                            height={'18px'}
                            width={'18px'}
                            id={"sSharing"}
                        />
                    </div> */}

                    {/* <div className='video_call_btn ms-3 shadow' style={{ background: "#e3e3e3" }}>
                        <p className={trackState.video ? "on" : ""}
                            onClick={() => mute("video")}>
                            {trackState.video ? "MuteVideo" : "UnmuteVideo"}
                        </p>
                    </div> */}


                    <div className='video_call_btn ms-3 shadow cursor_pointer ' id='CallOffBtn' onClick={() => leaveChannel()}>
                        <PhoneOff

                            className=''
                            style={{ color: '#fff', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                            height={'18px'}
                            width={'18px'}
                            id={"leave"}
                        />
                        {/* {<p onClick={() => leaveChannel()}>Leave</p>} */}
                    </div>

                    {/* <div className='video_call_btn ms-3 shadow cursor_pointer '
                        style={{ background: '#ffffff' }}
                        id='switchCamera' onClick={() => props.handleSwitchCamera()}>
                        <RefreshCcw

                            className=''
                            style={{
                                color: '#313131',
                                position: 'absolute',
                                top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                            }}
                            height={'18px'}
                            width={'18px'}
                            id={"switchCamera"}
                        />
                    </div> */}

                    {hide === true ? null : <VideoCallRecording />}


                </div>
            </div>
        </React.Fragment>
    )
}

export default Controls