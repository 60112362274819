import React, { useState, useEffect, } from 'react';
import db from '../../../../data/config';
import { collection, query, where, getDocs } from "firebase/firestore";
import { secondsToTime, updateCustomerVideoCallMins } from "../../Common/Functions/CommonFunctions";
import { fetchCustSubsUsage } from '../../../../api/index';
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";

var Knob = require('knob')

function CustomerAdminDashboard() {
    const localData = JSON.parse(localStorage.getItem("login_data"));
    const subscriptionPlan = JSON.parse(localStorage.getItem('subscription'));
    const firebaseAuthFail = JSON.parse(localStorage.getItem('firebaseAuthFail'));

    const planStartYear = new Date(subscriptionPlan.startDate).getFullYear();
    const planEndYear = new Date(subscriptionPlan.endDate).getFullYear();



    const [actualMin, setActualMin] = useState({ data: '', loading: false });
    const [usageData, setUsageData] = useState({ data: '', loading: false });
    getAuth();

    async function getData() {
        getAuth();
        console.log("planStartYear", planStartYear);
        console.log("planEndYear", planEndYear);
        setActualMin({ ...actualMin, data: '', loading: true });
        // const ref = db.collection('CustomerVideoCallMins');
        console.log("localData.customer_id", localData.customer_id);

        const CustomerVideoCallMins = await query(collection(db, "CustomerVideoCallMins"),
            where("customerId", "==", localData.customer_id),
        );

        const CustVidCallStat = await getDocs(CustomerVideoCallMins);
        console.log("CustVidCallStat", CustVidCallStat);
        if (CustVidCallStat.empty === true) {
            setActualMin({ ...actualMin, data: 0, loading: false });
            perCircle(0, 'firebase');
        } else {
            const items = [];
            var totalsecs = 0;
            CustVidCallStat.forEach((doc) => {
                if (doc.data().year == planStartYear || doc.data().year == planEndYear) {
                    items.push(doc.data());
                    const data = doc.data();
                    totalsecs = totalsecs + data.totalSeconds;
                }
            })

            console.log("items", items);
            console.log("totalsecs", totalsecs);
            setActualMin({ ...actualMin, data: totalsecs, loading: false });
            perCircle(Math.floor(totalsecs / 60), 'firebase')
        }
        // await db.collection('CustomerVideoCallMins').where('customerId', '==', localData.customer_id)
        //     .onSnapshot((querySnapshot) => {
        //         const items = [];
        //         var totalsecs = 0;
        //         querySnapshot.forEach((doc) => {
        //             items.push(doc.data());
        //             const data = doc.data();
        //             totalsecs = totalsecs + data.totalSeconds;
        //         })

        //         console.log("items", items);
        //         console.log("totalsecs", totalsecs);
        //         setActualMin({ ...actualMin, data: totalsecs, loading: false });
        //         perCircle(Math.floor(totalsecs / 60), 'firebase')
        //     })


    }

    async function getUsageData() {
        setUsageData({ ...usageData, data: {}, loading: true });
        fetchCustSubsUsage({ customer_id: localData.customer_id }).then((res) => {
            console.log("res", res);
            setUsageData({ ...usageData, data: res.data, loading: false });
            perCircle(res.data);
        }, (err) => {
            console.log("err", err);
        })
    }

    useEffect(async () => {
        if (firebaseAuthFail) {
            console.log("firebaseAuthFail", firebaseAuthFail);
            toast.error("Unable to calculate call minutes ! please contact admin.", {
                autoClose: 3000,
                theme: "light",

            });
        }


        if (Object.keys(subscriptionPlan).length > 0) {
            await getUsageData();

            var currDate = new Date();
            currDate.setHours(0, 0, 0, 0);
            console.log("currDate", currDate);
            // console.log("currDate.getTime()", currDate.getTime());

            const q = query(collection(db, "Call"),
                where("source", "==", "web"),
                where("totalTime", "==", null),
                where("customerId", "==", localData.customer_id),
                where("callLeft", "==", false),
                // where('startTime', '<', currDate.getTime())
            );

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                const data = doc.data();

                var seconds = (data.callInProgressTimeStamp.toDate().getTime() - data.startTime.toDate().getTime()) / 1000;
                const tTime = secondsToTime(Math.round(seconds));
                console.log("tTime", tTime);


                if (data.startTime.toDate().getTime() < currDate.getTime()) {

                    db.collection("Call").doc(doc.id).update({
                        totalSeconds: Math.round(seconds),
                        totalTime: tTime,
                        callInProgress: false,
                        callJoined: false,
                        callLeft: true,
                    });

                    updateCustomerVideoCallMins(data.startTime.toDate(), Math.round(seconds));
                }
            });


            await getData();
        }

        console.log("Object.keys(subscriptionPlan).length", Object.keys(subscriptionPlan).length);

    }, []);

    function perCircle(data, para2) {


        if (para2 === 'firebase') {
            console.log(data);
            var callMin = Knob({
                value: ((data / subscriptionPlan.plan.callMinutes * 100).toFixed(1)),
                left: 1,
                angleOffset: 180,
                className: "review",
                thickness: 0.2,
                width: 100,
                height: 100,
                fgColor: "#2264ab",
                readOnly: false,
                dynamicDraw: false,
                tickColorizeValues: true,
                bgColor: '#f6f7fb',
                lineCap: 'round',
                displayPrevious: true,
                readOnly: true
            })
            document.getElementById('callMin').appendChild(callMin);

        } else {
            var inspection = Knob({
                value: (data.Survey_count / subscriptionPlan.plan.inspectionsCount * 100).toFixed(1),
                left: 1,
                angleOffset: 180,
                className: "review",
                thickness: 0.2,
                width: 100,
                height: 100,
                fgColor: "#2264ab",
                readOnly: false,
                dynamicDraw: false,
                tickColorizeValues: true,
                bgColor: '#f6f7fb',
                lineCap: 'round',
                displayPrevious: true,
                readOnly: true
            })
            document.getElementById('inspection').appendChild(inspection);

            var user = Knob({
                value: (data.User_count / subscriptionPlan.plan.userCount * 100).toFixed(1),
                left: 1,
                angleOffset: 180,
                className: "review",
                thickness: 0.2,
                width: 100,
                height: 100,
                fgColor: "#2264ab",
                readOnly: false,
                dynamicDraw: false,
                tickColorizeValues: true,
                bgColor: '#f6f7fb',
                lineCap: 'round',
                displayPrevious: true,
                readOnly: true
            })
            document.getElementById('user').appendChild(user);


            var vessel = Knob({
                value: (data.Vessel_count / subscriptionPlan.plan.vesselCount * 100).toFixed(1),
                left: 1,
                angleOffset: 180,
                className: "review",
                thickness: 0.2,
                width: 100,
                height: 100,
                fgColor: "#2264ab",
                readOnly: false,
                dynamicDraw: false,
                tickColorizeValues: true,
                bgColor: '#f6f7fb',
                lineCap: 'round',
                displayPrevious: true,
                readOnly: true

            })
            document.getElementById('vessel').appendChild(vessel);
        }

    }


    return (
        <React.Fragment>
            <div className='CustomerAdminDashboard py-4'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='title bg-primary-blue' id="card_title">
                                <p className='mb-0 f-24'>{"Dashboard"}</p>
                            </div>
                            <div className='card-body'>
                                {Object.keys(subscriptionPlan).length > 0 ?
                                    <div className='row'>
                                        <div className='col-12 col-md-3'>
                                            <div className='card shadow-sm light_grey_border'>
                                                <div className='card-body'>
                                                    <h4 className="text-center mb-2">Survey</h4>

                                                    {usageData.loading ?
                                                        <div className="loader-box justify-content-center">
                                                            <div className="loader">
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="knob-block text-center">
                                                                <div className="knob" id="inspection">
                                                                </div>
                                                            </div>

                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <p className='mb-0 f-24'>{usageData.data.Survey_count}</p>
                                                                <p className='mb-0 f-34 f-w-600'>{" / " + subscriptionPlan?.plan?.inspectionsCount}</p>
                                                            </div>
                                                        </>
                                                    }


                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-12 col-md-3'>
                                            <div className='card shadow-sm light_grey_border'>
                                                <div className='card-body'>
                                                    <h4 className="text-center mb-2">Call Minutes</h4>


                                                    {actualMin.loading ?
                                                        <div className="loader-box justify-content-center">
                                                            <div className="loader">
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="knob-block text-center">
                                                                <div className="knob" id="callMin">
                                                                </div>
                                                            </div>

                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <p className='mb-0 f-24'>{Math.floor(actualMin.data / 60)}</p>
                                                                <p className='mb-0 f-34 f-w-600'>{" / " + subscriptionPlan?.plan?.callMinutes}</p>
                                                            </div>
                                                        </>
                                                    }


                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-12 col-md-3'>
                                            <div className='card shadow-sm light_grey_border'>
                                                <div className='card-body'>
                                                    <h4 className="text-center mb-2">Users</h4>

                                                    {usageData.loading ?
                                                        <div className="loader-box justify-content-center">
                                                            <div className="loader">
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="knob-block text-center">
                                                                <div className="knob" id="user">
                                                                </div>
                                                            </div>

                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <p className='mb-0 f-24'>{usageData.data.User_count}</p>
                                                                <p className='mb-0 f-34 f-w-600'>{" / " + subscriptionPlan?.plan?.userCount}</p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-12 col-md-3'>
                                            <div className='card shadow-sm light_grey_border'>
                                                <div className='card-body'>
                                                    <h4 className="text-center mb-2">Vessels</h4>

                                                    {usageData.loading ?
                                                        <div className="loader-box justify-content-center">
                                                            <div className="loader">
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="knob-block text-center">
                                                                <div className="knob" id="vessel">
                                                                </div>
                                                            </div>

                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <p className='mb-0 f-24'>{usageData.data.Vessel_count}</p>
                                                                <p className='mb-0 f-34 f-w-600'>{" / " + subscriptionPlan?.plan?.vesselCount}</p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    :
                                    <p className='text-danger mb-0 text-center f-16'>
                                        You have not subscribed a plan yet !
                                    </p>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CustomerAdminDashboard