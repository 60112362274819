import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Collapse,
  Accordion,
} from "reactstrap";
import CheckListResult from "./checkListResult";
import { useSelector } from "react-redux";

const AcordianCompo = ({ handleAddingPhoto }) => {
  const appData1 = useSelector((content) => content.SurveyReducer.surveyData);

  const [isOpen, setIsOpen] = useState();
  const [Id, setIsId] = useState([]);
  const [isOpen1, setIsOpen1] = useState();
  const [surveyDetail, setsurveyDetail] = useState([]);
  const [NumberCompleted, setNumberCompleted] = useState(0);
  const [saved, setSaved] = useState({
    comments: "",
    rating: "",
    videos: "",
    photos: "",
    doc: "",
  });
  const { id } = useParams();
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  useEffect(async () => {
    // const d = appData1?.filter((el) => {
    //   return id == el.Id;
    // });
    let a = [];
    appData1[0]?.checklist?.checklist_sections.map((el) => {
      el?.section_checklist_item?.map((el) => {
        a.push(el.Id);
      });
    });
    console.log(a, "=========================================================");
    setIsId(a);
    setsurveyDetail(appData1[0]);
  }, [appData1]);

  const selecting = (el) => {
    console.log("el", el);
    var count = 0;
    el?.section_checklist_item.map((el2) => {
      if (completed(el2) == "green") {
        count = count + 1;
      }
    });
    return count;
  };

  function completed(data) {
    console.log("----------data--------------", data);
    var photo = true;
    var videos = true;
    var comments = true;
    var filtered_attachment = data.check_list_attachment.filter(
      (el) => el.survey == id
    );

    // console.log("data", data);

    if (data.photos == "Yes") {
      photo = false;
      filtered_attachment.map((el) => {
        if (el?.withAnswer && el.fileType == "Image") {
          photo = true;
        }
      });
    }

    if (data.videos == "Yes") {
      videos = false;
      filtered_attachment.map((el) => {
        if (el?.withAnswer && el.fileType == "Video") {
          videos = true;
        }
      });
    }

    if (data.comments == "Yes") {
      comments = false;
      var com = "";
      let filtered = data.checklist_item_result.filter((el) => el.survey == id);
      console.log("filtered", filtered);
      if (filtered?.length > 1) {
        var time1 = new Date(filtered[0]?.updateTimestamp);
        filtered.map((el) => {
          var time2 = new Date(el.updateTimestamp);
          if (time2 > time1) {
            time1 = new Date(el.updateTimestamp);
            com = el.remarks;
          }
        });
      } else {
        com = filtered?.length ? filtered[0].remarks : "";
        console.log("com", com);
      }
      if (com?.length) {
        console.log("com?.length");
        comments = true;
      } else {
        comments = false;
      }
    } else {
      // comments = false;
    }

    console.log("photo", photo);
    console.log("videos", videos);
    console.log("comments", comments);
    console.log("id",id);
    console.log("data.checklist_item_result",data.checklist_item_result);

    const temp = data.checklist_item_result.filter((val) => { return val.survey === parseInt(id) });

    console.log("temp", temp);

    console.log(photo &&
      videos &&
      comments &&
      !!data.checklist_item_result[data.checklist_item_result.length - 1]?.values);
    console.log("!!data.checklist_item_result[data.checklist_item_result.length - 1]?.values", !!data.checklist_item_result[data.checklist_item_result.length - 1]?.values);

    if (temp.length > 0) {
      if (
        photo &&
        videos &&
        comments &&
        !!temp[temp.length - 1]?.values
      ) {
        console.log("green");
        return "green";
      } else {
        console.log("red");
        return "red";
      }
    } else {
      return "red";
    }

    // if (
    //   photo &&
    //   videos &&
    //   comments &&
    //   !!data.checklist_item_result[data.checklist_item_result.length - 1]?.values
    // ) {
    //   console.log("green");
    //   return "green";
    // } else {
    //   console.log("red");
    //   return "red";
    // }
  }
  return (
    <Fragment>
      <div className="container-fluid"></div>
      {surveyDetail?.checklist?.checklist_sections.map((data, i) => {
        return (
          <Card
            key={i}
            style={{
              border: "1px solid #000000",
              cursor: "pointer",
              width: "100%",
              marginBottom: "20px",
            }}
            onClick={(event) => {
              toggle(data?.sectionId);
              event.stopPropagation();
            }}
          >
            <div className="d-flex justify-content-between px-3">
              <div style={{ padding: "15px" }}>
                <div
                  className="float-left"
                  style={{ fontSize: "20px", fontWeight: "600" }}
                >
                  {data?.sectionId}. {data?.sectionName}
                </div>
              </div>
              <div className="d-flex col-1 justify-content-between align-items-center">
                <div style={{ fontSize: "18px", fontWeight: "600" }}>
                  {selecting(data) + "/" + data.section_checklist_item.length}
                </div>
                {!!isOpen ? (
                  <span style={{ fontSize: "20px" }}>
                    <i className="fa fa-caret-up"></i>
                  </span>
                ) : (
                  <span style={{ fontSize: "20px" }}>
                    <i className="fa fa-sort-desc"></i>
                  </span>
                )}
              </div>
            </div>
            <Collapse isOpen={isOpen == data?.sectionId}>
              <CardBody style={{ padding: "0px" }}>
                {data?.section_checklist_item.map((el) => {
                  return (
                    <Fragment key={Math.ceil(Math.random() * 100000)}>
                      {el.Id != isOpen1 ? (
                        <Card
                          onClick={(event) => {
                            if (isOpen1 != el.Id) {
                              setIsOpen1(el.Id);
                            } else {
                              setIsOpen1("");
                            }
                            event.stopPropagation();
                          }}
                        >
                          <CardHeader
                            style={{
                              border: "2px solid #bebebe",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="py-2 px-4"
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                width: "calc(100% - 20px)",
                              }}
                            >
                              {el.SrNo + `: ` + el.section}
                            </div>
                            <div
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "5px",
                                borderRadius: "50%",
                                background: completed(el),
                                border: "2px solid black",
                                alignSelf: "center",
                              }}
                            ></div>
                          </CardHeader>
                        </Card>
                      ) : (
                        <CheckListResult
                          isOpen1={isOpen1}
                          handleAddingPhoto={handleAddingPhoto}
                          setIsOpen1={setIsOpen1}
                          isOpen={isOpen}
                          Id={Id}
                          d={el}
                          surveyDetail={surveyDetail}
                          setSaved={setSaved}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </CardBody>
            </Collapse>
          </Card>
        );
      })}
    </Fragment>
  );
};

export default AcordianCompo;
