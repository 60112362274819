import React, { useState, useEffect } from "react";
import { generateUserId, generateRandomNumber } from "../Common/Functions/CommonFunctions";
import {
  acquire,
  videoRecordingToken,
  startVCR,
  stopVCR,
  postSurveyRecording,
  putSurveyRecording,
} from "../../../api/index";
import "../Common/Css/Common.css";
import videorecording from "../../../assets/video/videorecording.gif";
import { useParams } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { convertDateYYMMDD } from "../../ProjectComponents/Common/Functions/CommonFunctions";
import { UncontrolledTooltip } from 'reactstrap';
var FormData = require("form-data");

function VideoCallRecording({ StopRecording }) {
  const { id } = useParams();
  const [videoRecordingStarted, setVideoRecordingStarted] = useState(false);
  const [resourceId, setResourceId] = useState("");
  const [stopped, setStopped] = useState(false);
  const [sid, setSid] = useState("");
  const [videoRecordingLimit, setVideoRecordingLimit] = useState(0);
  const [basicAuth, setBasicAuth] = useState({});
  const [idForPutAPi, setIdForPutApi] = useState("");
  const [saving, setSaving] = useState("");
  var agoraToken;
  const [uid, setUid] = useState({ data: '' });

  async function startVideoRecording() {
    // uid.data = new Date().getTime().toString();
    // uid.data = generateUserId();
    uid.data = generateRandomNumber();
    setUid({ ...uid, data: uid.data });
    setStopped(true);
    setVideoRecordingLimit((prevLimit) => prevLimit + 1);
    console.log("videoRecordingLimit", videoRecordingLimit);

    const reqPayload = { userId: uid.data, channelName: id };
    var formdata = new FormData();

    for (var key in reqPayload) {
      formdata.append(key, reqPayload[key]);
    }

    await videoRecordingToken(formdata).then(
      (res) => {
        console.log("res", res);
        console.log("res.data.token", res.data.token);
        agoraToken = res.data.token;

        var decodedString = atob(res.data.authKey);

        const result = decodedString.split(":");
        console.log("result", result);

        basicAuth.username = result[0];
        basicAuth.password = result[1];
        setBasicAuth({
          ...basicAuth,
          username: basicAuth.username,
          password: basicAuth.password,
        });

        const acquirePayload = {
          cname: id,
          uid: uid.data,
          clientRequest: {
            resourceExpiredHour: 24,
            scene: 0,
          },
        };

        acquire(acquirePayload, basicAuth).then(
          (acqRes) => {
            console.log("acqRes", acqRes);

            const startVCRPayload = {
              cname: id,
              uid: uid.data,
              clientRequest: {
                token: agoraToken,
                recordingConfig: {
                  maxIdleTime: 30,
                  streamTypes: 2,
                  audioProfile: 1,
                  channelType: 0,
                  videoStreamType: 0,
                  transcodingConfig: {
                    width: 540,
                    height: 640,
                    fps: 15,
                    bitrate: 500,
                    mixedVideoLayout: 1,
                    backgroundColor: "#FF0000",
                  },
                },
                recordingFileConfig: {
                  avFileType: ["hls", "mp4"],
                },
                storageConfig: {
                  vendor: 1,
                  region: 14,
                  bucket: `${process.env.REACT_APP_BUCKET}`,
                  accessKey: `${process.env.REACT_APP_ACCESS_KEY}`,
                  secretKey: `${process.env.REACT_APP_SECRET_KEY}`,
                  fileNamePrefix: ["videorecording", id],
                },
              },
            };

            setResourceId(acqRes.data.resourceId);

            startVCR(
              { url: acqRes.data.resourceId, body: startVCRPayload },
              basicAuth
            ).then(
              (vcrResponse) => {
                console.log("vcrResponse", vcrResponse);
                setResourceId(vcrResponse.data.resourceId);
                setSid(vcrResponse.data.sid);
                if (vcrResponse.status === 200) {
                  setVideoRecordingStarted(true);

                  const postSurveyPayload = {
                    survey: id,
                    userId: uid.data,
                    sid: vcrResponse.data.sid,
                  };

                  postSurveyRecording(postSurveyPayload).then(
                    (postSurResponse) => {
                      console.log("postSurResponse", postSurResponse);

                      setIdForPutApi(postSurResponse.data.id);
                    },
                    (err) => {
                      console.log("err", err);
                    }
                  );
                }
              },
              (err) => {
                console.log("err", err);
                SweetAlert.fire({
                  title: "Video recording failed !",
                  text: "Please try again !",
                  icon: "error",
                });
              }
            );
          },
          (err) => {
            console.log("err", err);
            SweetAlert.fire({
              title: "Video recording failed !",
              text: "Please try again !",
              icon: "error",
            });
          }
        );
      },
      (err) => {
        console.log("err", err);

        SweetAlert.fire({
          title: "Video recording failed !",
          text: "Please try again !",
          icon: "error",
        }).then((result) => {
          if (result.value) {
            SweetAlert.fire(`The returned value is: ${result.value}`);
          }
        });
      }
    );
  }

  useEffect(() => {
    if (StopRecording && stopped) {
      stopVideoRecording();
    }
  }, [StopRecording]);

  function stopVideoRecording() {
    console.log("resourceId========================", resourceId);
    setSaving("Saving...");
    setStopped(false);

    const payload = {
      url: resourceId,
      body: {
        cname: id,
        uid: uid.data,
        clientRequest: {
          async_stop: false,
        },
      },
      sid: sid,
    };
    console.log("payload", payload);

    stopVCR(payload, basicAuth).then(
      (res) => {
        console.log("res", res);
        setVideoRecordingStarted(false);

        var videoUrl;

        if (res.data.serverResponse.fileList.length > 0) {
          videoUrl = res.data.serverResponse.fileList[0].fileName;
        } else {
          const temp = res.data.serverResponse.fileList[res.data.serverResponse.fileList.length - 1].fileName.split('.');
          videoUrl = temp[0] + ".mp4"
          console.log("temp", temp);
          console.log("videoUrl", videoUrl);
        }

        const date = new Date();

        const d = convertDateYYMMDD(date, "-");
        console.log("d", d);

        var curr_hour = date.getHours();
        var curr_min = date.getMinutes();

        var curr_sec = date.getSeconds();
        const time = curr_hour + ":" + curr_min + ":" + curr_sec;

        const payloadForSurveyPutApi = {
          id: idForPutAPi,
          url: `${process.env.REACT_APP_AMAZON_URL}${videoUrl}`,
          stopped_at: d + " " + time,
        };
        console.log("payloadForSurveyPutApi", payloadForSurveyPutApi);

        putSurveyRecording(payloadForSurveyPutApi).then(
          (putres) => {
            console.log("putres", putres);
            toast.success("Video Recorded Successfully !", {
              autoClose: 3000,
              theme: "light",
              
            });
            setSaving("");
          },
          (err) => {
            console.log("err", err);

            toast.error("Failed to save video recording !", {
              autoClose: 3000,
              theme: "light",
              
            });
            setSaving("");
          }
        );
      },
      (err) => {
        console.log("err", err);
        SweetAlert.fire({
          title: "Video recording failed to stop!",
          text: "Please try again !",
          icon: "error",
        });
      }
    );
  }

  // useEffect(()=>{
  //     // console.log("make a call startde")
  //     startVideoRecording()
  // },[])

  return (
    <React.Fragment>
      {videoRecordingStarted === true ? (
        <div
          onClick={() => {
            if (!!!saving) {
              stopVideoRecording();
            }
          }}
          className="video_call_btn_recording ms-2   d-flex justify-content-center align-items-center cursor_pointer"
          id="videoRecordBtn"
          style={{ background: "#fff", color: "#313131", fontWeight: "600" }}
        >
          {!!saving ? (
            saving
          ) : (
            <>
              Stop{" "}
              <img
                src={videorecording}
                className="ms-1"
                alt={"recording"}
                height={"25"}
                width={"25"}
              />
            </>
          )}
        </div>
      ) : (
        <div
          onClick={() => {
            console.log("videoRecordingLimit", videoRecordingLimit);
            startVideoRecording();
          }}
          className="video_call_btn_recording ms-2 d-flex justify-content-center align-items-center cursor_pointer"
          id="videoRecordBtn"
          style={{ background: "#fff", color: "#313131", fontWeight: "600" }}
        >
          Record meeting
          {/* <img src={videorecording} alt={'recording'} height={'25'} width={'25'} /> */}
        </div>
      )}

      <UncontrolledTooltip placement="top" target="videoRecordBtn">
        {videoRecordingStarted === true ?
          "Stop" : "Record Meeting"
        }
      </UncontrolledTooltip>
    </React.Fragment>
  );
}

export default VideoCallRecording;
