import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


function ImagePreview(props) {

    useEffect(() => {
        console.log("imageUrl", props.imageUrl);
        console.log("imageUrl", props.imageUrl.checklist_file);
    }, [props.imageUrl]);

    return (
        <React.Fragment>
            <Modal isOpen={props.imageModal} toggle={props.toggleImageModal} size="lg" backdrop='static'>
                <ModalHeader toggle={props.toggleImageModal} className='bg-primary-blue'>{"Image Preview"}</ModalHeader>
                <ModalBody>
                    <div className='d-flex justify-content-center'>
                        <img className='img-fluid' src={props.imageUrl.checklist_file} height="auto" width="260px" alt='image' />
                    </div>
                    <div className='text-center'>
                        <p className='text-muted f-14'>{props.imageUrl.name}</p>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ImagePreview