import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion } from "reactstrap";
import { MessageCircle } from "react-feather";
import { AllCloseAccordian } from "../../../../../base/Accordian/AccordianComponent";
import AcordianCompo from "./acordianCompo";
import { WATCH_SURVEY_LIST } from "../../../../../../redux/actionTypes";
import Loader from "../../../../../common/loader";
import VideoApp from "../../../../videoCall/VideoApp";
import { getData, postData, putData } from "../../../../../../api/APIinstance";
import {
  close,
  open,
  openClose,
} from "../../../../../../redux/sidebarOpenClose/action";
import AddPhotosVideos from "./addPhotosVideos";
import { toast } from "react-toastify";
import ChatRoom from "./chatRoom";
import ChatUI from "./ChatUI";
import GuestUserLinkGeneration from "../../../../../ProjectComponents/CustomerAdmin/SuveyManagement/GuestUserLinkGeneration";
import db from '../../../../../../data/config';
import { validateCallMinsWithSubscriptionPlan } from '../../../../../ProjectComponents/Common/Functions/CommonFunctions';
import { getStorage, uploadBytes, ref as abcd, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import firebase from 'firebase/compat/app';
import { getAuth } from "firebase/auth";

const SurveySectionList = () => {
  const appData = useSelector((content) => content.SurveyReducer.surveyData);

  const localData = JSON.parse(localStorage.getItem("login_data"));
  const firebaseAuthFail = JSON.parse(localStorage.getItem('firebaseAuthFail'));
  const subscriptionPlan = JSON.parse(localStorage.getItem('subscription'));

  const ref = db.collection('notifications');
  const guestUserdata = JSON.parse(localStorage.getItem("guestUserData"));
  const GuestVideoCall = JSON.parse(localStorage.getItem("GuestVideoCall"));
  const emailAddress = JSON.parse(localStorage.getItem("login_data"));

  const [token, setToken] = useState();
  const [open1, setOpen] = useState();
  const [unReadMsgCount, setUnreadMsgCount] = useState(0);

  const [check, setCheck] = useState(true);
  const [linkModal, setLinkModal] = useState(false);
  const [surveyDetail, setSurveyDetail] = useState();
  const [loader, setLoader] = useState(true);
  const [call, setCall] = useState(false);
  const [chatContainer, setChatContainer] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();



  useEffect(async () => {

    dispatch({ type: WATCH_SURVEY_LIST });
    localStorage.setItem("GuestVideoCall", false);

    if (firebaseAuthFail === false) {
      await getData();
    }
  }, []);

  async function getData() {
    getAuth();

    await ref
      .where('surveyId', '==', parseInt(id))
      .where('from', '!=', GuestVideoCall === true ? guestUserdata.email : emailAddress?.emailId)
      // .orderBy("time")
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          console.log(doc);

          const msgData = doc.data();
          // items.push(doc.data());
          // items.push(doc);

          if (msgData.seenBy.includes(GuestVideoCall === true ? guestUserdata.email : emailAddress?.emailId)) {

          } else {
            items.push(msgData);
          }

        })
        console.log("items", items);

        // for (let i = 0; i < items.length; i++) {
        //   if (items[i].seenBy.includes(GuestVideoCall === true ? guestUserdata.email : emailAddress?.emailId)) {

        //   } else {
        //     finalVal.push(items[i]);
        //   }
        // }

        console.log("items.length", items.length);

        setUnreadMsgCount(items.length);
      })
  }

  const Completed = (data) => {
    var photo = true;
    var videos = true;
    var comments = true;
    if (data.photos == "Yes") {
      photo = false;
      data.check_list_attachment
        .filter((el) => el.survey == id)
        .map((el) => {
          if (el?.withAnswer && el.fileType == "Image") {
            photo = true;
          }
        });
    }
    if (data.videos == "Yes") {
      videos = false;
      data.check_list_attachment
        .filter((el) => el.survey == id)
        .map((el) => {
          if (el?.withAnswer && el.fileType == "Video") {
            videos = true;
          }
        });
    }
    if (data?.comments == "Yes") {
      comments = false;
      var com = "";
      let filtered = data?.checklist_item_result.filter(
        (el) => el.survey == id
      );
      var time1 = new Date(filtered[0]?.updateTimestamp);
      if (filtered?.length > 1) {
        filtered.map((el) => {
          var time2 = new Date(el.updateTimestamp);
          if (time2 > time1) {
            time1 = new Date(el.updateTimestamp);
            com = el.remarks;
          }
        });
      } else {
        com = filtered?.length ? filtered[0].remarks : "";
      }
      if (com?.length) {
        comments = true;
      }
    }
    if (
      photo &&
      videos &&
      comments &&
      !!data?.checklist_item_result[data?.checklist_item_result.length - 1]
        ?.values
    ) {
      return "green";
    } else {
      return "red";
    }
  };

  useEffect(() => {
    var count = 0;
    var count1 = 0;
    if (appData.length) {
      appData[0]?.checklist?.checklist_sections?.map((el1) => {
        el1.section_checklist_item.map((el) => {
          if (Completed(el) === "green") {
            count = count + 1;
          }
        });
        count1 = count1 + el1.section_checklist_item.length;
      });
    }

    if (count1 != 0 && count == count1) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [appData]);

  const handleRoute = async () => {
    var response;
    if (appData[0]?.status != "Signed") {
      let params = {
        Id: id,
        status: "Completed",
      };
      response = await putData("SurveyApi/", params);
      if (response) {
        toast.success("Survey Completed...", {
          autoClose: 3000,
          theme: "light",
          
        });
      }
    }
    navigate(
      `${process.env.PUBLIC_URL}/dashboard/default/surveyList/surveyDashboard/surveySectionList/previewpage/${id}`,
      { state: appData[0]?.status == "Signed" }
    );
  };
  console.log(appData, "appData============================>");
  const toggleLinkModal = () => {
    setLinkModal(!linkModal);
  };


  // function for check if the customer plan have subscription mins left, if not, not allowing to make call
  async function checkTotalCustSecs() {
    console.log("firebaseAuthFail", firebaseAuthFail);
    // If subscription is not present no restriction or firebase validation
    if (firebaseAuthFail) {
      toast.error("Unable to calculate video call minutes, please contact admin!", {
        autoClose: 3000,
        theme: "light",
        
      });
    }


    if (Object.keys(subscriptionPlan).length > 0) {

      if (firebaseAuthFail === false) {


        validateCallMinsWithSubscriptionPlan(localData.customer_id,
          subscriptionPlan.startDate, subscriptionPlan.endDate, subscriptionPlan.plan.callMinutes)
          .then((res) => {
            console.log("res", res)
            if (res === false) {
              toast.error("Your subscription limit has been exhausted, contact admin !", {
                autoClose: 3000,
                theme: "light",
                
              });
            }
            else {
              setOpen(true);
              setCall(true);
              // setBasicSetting({ ...basic_settings, slidesToShow: 2, slidesToScroll: 2 });

              document
                .querySelector(".page-main-header")
                .classList.add("open");
              document
                .querySelector(".page-sidebar")
                .classList.add("open");

            }

          })
      } else {
        setOpen(true);
        setCall(true);
        dispatch(close());
        document
          .querySelector(".page-main-header")
          .classList.add("open");
        document
          .querySelector(".page-sidebar")
          .classList.add("open");
      }
    } else {
      console.log("ssssssssssssshould runnnnnnnnnnnnnnnn");
      setOpen(true);
      setCall(true);
      dispatch(close());
      document
        .querySelector(".page-main-header")
        .classList.add("open");
      document
        .querySelector(".page-sidebar")
        .classList.add("open");

    }

  }

  return (
    <Fragment>
      <Loader loader={loader} />
      {!appData.length ? (
        <div className="loader-box justify-content-center">
          <div className="loader">
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
            <div className="line bg-dark"></div>
          </div>
        </div>
      ) : (
        <>
          <div
            className="card mb-0"
            style={{ marginTop: "100px", fontFamily: "roboto" }}
          >
            <div
              className="card-heading row"
              style={{
                fontSize: "24px",
                background: "#2264AB",
                padding: "12px 24px",
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
              }}
            >
              <span className="col text-light">
                <span
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-light"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                >
                  <i className="fa fa-arrow-circle-left"></i>
                </span>
                <span style={{ marginLeft: "15px" }}>
                  {appData[0]?.vessel?.name}
                </span>
              </span>

              <span className="col text-center text-light">
                Survey Name:{" "}
                {appData !== null ? appData[0]?.checklist?.name : "NA"}
              </span>
              <span className="col text-center text-light">
                Status:{" "}
                {appData[0]?.status == "Active"
                  ? "Assigned"
                  : appData[0]?.status == "Signed"
                    ? "Completed"
                    : appData[0]?.status}
              </span>
            </div>
            <div className="card-body border-top">
              <div className="container-fluid" style={{ fontFamily: "roboto" }}>
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="row">
                      <div className="col">
                        {appData[0]?.verifier_activity != 0 ? (
                          <>
                            <div
                              className="headLine mb-1"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                // color: "#2264AB",
                              }}
                            >
                              Verifier Comment
                            </div>
                            <div className="border border-dark rounded-3 p-3">
                              <p
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#2264AB",
                                }}
                              >
                                {appData[0]?.verifier_activity[0].comments}
                              </p>
                            </div>{" "}
                          </>
                        ) : null}
                      </div>

                      {!open1 || !call ? (
                        <div
                          className="col-auto"
                          style={{
                            marginTop:
                              appData[0]?.verifier_activity != 0 ? "28px" : 0,
                          }}
                        >
                          <button
                            disabled={appData[0]?.status == "Signed"}
                            className="btn btn-primary-blue py-2"
                            style={{
                              color: "white",
                            }}
                            onClick={() => {
                              checkTotalCustSecs()
                            }}
                          >
                            start a call
                            <i className="fa fa-video-camera mx-2"></i>
                          </button>
                        </div>
                        // <button
                        //   style={{
                        //     marginTop:
                        //       appData[0]?.verifier_activity != 0 ? "28px" : 0,
                        //   }}
                        //   disabled={appData[0]?.status == "Signed"}
                        //   className="btn btn-primary-blue py-2"
                        //   onClick={() => {
                        //     checkTotalCustSecs()
                        //   }}
                        // >
                        //   start a call
                        //   <i className="fa fa-video-camera mx-2"></i>
                        // </button>
                      ) : null}
                      <div
                        className="col-auto"
                        style={{
                          marginTop:
                            appData[0]?.verifier_activity != 0 ? "28px" : 0,
                        }}
                      >
                        <button
                          className="btn btn-primary-blue py-2"
                          style={{
                            // background: "#0B0968",
                            // color: "white",
                            width: "auto",
                          }}
                          onClick={() => {
                            toggleLinkModal();
                          }}
                        >
                          Invite Participants
                          <i className="fa fa-link mx-2"></i>
                        </button>
                      </div>
                    </div>
                    {/* <div></div> */}
                  </div>
                </div>
                <div className="row">
                  {open1 ? (
                    <div className="col-md-6 position-relative overflow-hidden">
                      {call ? (
                        <VideoApp
                          call={call}
                          setOpen={setOpen}
                          setCall={setCall}
                          chanelName={`${appData[0]?.Id}`}
                        // token={token}
                        />
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    className="col mt-3 mt-md-0"
                    style={{ height: "80vh", overflow: "auto" }}
                  >
                    {/* <p className="f-20 f-w-600">{appData[0]?.checklist.name}</p> */}
                    <Accordion>
                      <div className="default-according" id="accordionclose">
                        <AcordianCompo />
                      </div>
                    </Accordion>
                    <div className="row justify-content-end">
                      <div className="col-md-8"></div>
                      <div className="col-md-4">
                        <button
                          disabled={check}
                          onClick={handleRoute}
                          className="btn btn-primary-blue text-light"
                        // style={{
                        //   background: "#0B0968",
                        // }}
                        >
                          {appData[0]?.status == "Signed"
                            ? "View report PDF"
                            : "Proceed"}
                        </button>
                        <button
                          onClick={() => {
                            if (firebaseAuthFail) {
                              toast.error("Cannot open chat, please contact admin!", {
                                autoClose: 3000,
                                theme: "light",
                                
                              });
                            } else {
                              setChatContainer(!chatContainer);
                            }

                          }}
                          style={{
                            position: 'relative',
                            background: "#2264AB",
                            color: "#FFF",
                            position: "fixed",
                            width: "50px",
                            height: "50px",
                            bottom: "20px",
                            right: "40px",
                            borderRadius: "50px",
                            textAlign: "center",
                            border: "none",
                            zIndex: 9,
                          }}
                        >
                          <MessageCircle
                            className="cursor_pointer "
                            height={"18px"}
                            width={"18px"}
                          />

                          {unReadMsgCount > 0 ?
                            <span
                              style={{
                                background: 'red',
                                width: "auto",
                                height: 'auto',
                                borderRadius: '12px',
                                position: 'absolute',
                                top: '0px'
                              }}
                            ><p className="m-0" style={unReadMsgCount < 10 ? { padding: '2px 8px 2px 8px' } :
                              { padding: '2px 4px 2px 4px' }}>{unReadMsgCount}</p>
                            </span>
                            : null}
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7"></div>
                      <div className="col-md-5">
                        {/* {chatContainer ? <ChatRoom surveyId={id} /> : ""} */}
                        {chatContainer ? <ChatUI surveyId={id} /> : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
      }
      {
        linkModal ? (
          <GuestUserLinkGeneration
            toggleLinkModal={toggleLinkModal}
            linkModal={linkModal}
            data={id}
          />
        ) : null
      }
    </Fragment >
  );
};

export default React.memo(SurveySectionList);
