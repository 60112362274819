import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { getCheckListSection, get_mla, updateChecklistItem, } from '../../../../api/index';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
// import Draggable from "react-draggable";


const schema = yup.object().shape({
    SrNo: yup.string()
        .required('Required !'),
    sequence: yup.string()
        .required('Required !').matches(/^[0-9]*$/, "Only Integer Allowed !"),
    section: yup.string()
        .max(300, "300 characters allowed !")
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    sectionName: yup.string()
        .required('Required !'),
    comments: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    photos: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    videos: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    OG: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),

}).required();


function UpdateItems(props) {

    const [sectionList, setSectionList] = useState({ loading: false, data: [] });
    const [mla, setMla] = useState(props.data.MLA);
    const [mlaList, setMlaList] = useState({
        data: [],
        loading: false,
    });
    const { register, handleSubmit, formState, control, reset, setValue } = useForm({
        resolver: yupResolver(schema), mode: "onChange",
    });
    const customer_id = localStorage.getItem("customer_id");

    const handlechange = (e) => {

        setMla(e);

        /// console.log(mla,"mlainhandle")
    }
    var sectionName;

    console.log("mla", mla);
    let { errors } = formState;
    console.log("customer_id",customer_id)
    const fetch_mla = () => {
        get_mla({ hasParent: "False", customer: customer_id }).then(
            (res) => {
                console.log("res", res);
                setMlaList({ loading: false, data: res.data.payload });
            },
            (err) => {
                /// console.log("err", err);
                setMlaList({ loading: false, data: [] });
            }
        );
    }

    useEffect(() => {
        console.log('props_update_items', props);
        

        setMla(props.data.MLA);
        if (props.updateItemModal === false || props.updateItemModal === undefined) {
            fetchChecklistSections();
            fetch_mla();
            reset();

        }else{
            console.log(localStorage.getItem('selectedSectionName'))
            setValue("sectionName", localStorage.getItem('selectedSectionName'));
            reset({"section":props.data.section, 
                   "videos":props.data.videos,
                    "SrNo":props.data.SrNo,
                    "sequence":props.data.sequence,
                    "comments":props.data.comments,
                    "photos":props.data.photos,
                    "OG":props.data.OG

        
        })
        }
    }, [props]);





    function fetchChecklistSections() {
        setSectionList({ ...sectionList.data, loading: true, data: [] })

        getCheckListSection({ checklist: props.checkListId })
            .then((res) => {
                console.log("res", res);
                setSectionList({ ...sectionList.data, loading: false, data: res.data })

            }, (err) => {
                console.log("err", err);
            })
    }


    const onSubmit = data => {

        if (data !== '') {

            console.log('props', props);

            console.log('sectionList.data', sectionList.data);

            var sectionDetails;
            sectionDetails = sectionList.data.filter((val) => {
                return val.sectionName === data.sectionName
            })
            data.sectionName = sectionDetails[0].id;
            data.checkList = props.checkListId;

            console.log("sectionDetails", sectionDetails);
            data.Id = props.data.Id;
            data.id = props.data.id;

            data.MLA = mla;
            console.log('data', data,);

            updateChecklistItem(data).then((res) => {
                console.log('res', res);
                toast.success("Item updated successfully !", { autoClose: 3000, theme: "light", });
                props.toggleUpdateModal();
                // props.setSectionSeleted(false);
                props.fetchChecklistSections();


            }, (err) => {
                console.log('err', err);
            });
        } else {
            errors.showMessages();
        }
    };



    return (
        <React.Fragment>
            {/* <Draggable > */}
            <Modal isOpen={props.updateItemModal} toggle={props.toggleUpdateModal} size="lg" backdrop="static">
                <ModalHeader toggle={props.toggleUpdateModal} className='bg-primary-blue '>{"Update Item"}</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-12 col-md-3 mb-2'>
                            <label className='f-w-600 f-14'>{"Sr.No"}</label>
                            <input
                                className={errors.SrNo ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                name={`SrNo`}
                                {...register(`SrNo`)}
                                placeholder="SrNo*"
                               // defaultValue={props.data.SrNo === null ? '' : props.data.SrNo}
                            />

                            <span className='text-danger'>{errors.SrNo?.message}</span>
                        </div>

                        <div className='col-12 col-md-3 mb-2'>
                            <label className='f-w-600 f-14'>{"Sequence"}</label>
                            <input
                                className={errors.sequence ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                name={`sequence`}
                                {...register(`sequence`)}
                                placeholder="sequence*"
                               // defaultValue={props.data.sequence === null ? '' : props.data.sequence}
                            />

                            <span className='text-danger'>{errors.sequence?.message}</span>
                        </div>

                        <div className='col-12 col-md-12 mb-2'>
                            <label className='f-w-600 f-14'>{"Description"}</label>
                            <textarea
                                className={errors.section ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                {...register(`section`)}
                                name={`section`}
                                placeholder="Enter description*"
                                rows='2'
                               // defaultValue={props.data.section}
                            ></textarea>

                            <span className='text-danger'>{errors.section?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Section"}</label>

                            <select
                                id="sectionName"
                                {...register(`sectionName`)}
                                name={`sectionName`}
                                className={errors.sectionName ? 'form-control is-invalid' : 'form-control'}
                                defaultValue={localStorage.getItem('selectedSectionName')}
                            >
                                <option value="">{"--Select*--"}</option>
                                {sectionList.data.map((val, i) => (
                                    <option value={val.sectionName} key={i}>{val.sectionName}</option>
                                ))}

                            </select>

                            <span className='text-danger'>{errors.sectionName?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Comments"}</label>

                            <select
                                {...register(`comments`)}
                                name={`comments`}
                                className={errors.comments ? 'form-control is-invalid' : 'form-control'}
                              //</div>  defaultValue={props.data.comments}
                              >
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Compulsory"}</option>
                                <option value="No">{"Optional"}</option>
                            </select>

                            <span className='text-danger'>{errors.comments?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Photos"}</label>

                            <select
                                {...register(`photos`)}
                                name={`photos`}
                                className={errors.photos ? 'form-control is-invalid' : 'form-control'}
                               // defaultValue={props.data.photos}
                                >
                                <option value="">{"--Select*--"}</option>
                                <option value="Yes">{"Compulsory"}</option>
                                <option value="No">{"Optional"}</option>
                            </select>

                            <span className='text-danger'>{errors.photos?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Videos"}</label>

                            <select
                                {...register(`videos`)}
                                name={`videos`}
                                className={errors.videos ? 'form-control is-invalid' : 'form-control'}
                                //defaultValue={props.data.videos}
                                >
                                <option value="">{"--Select*--"}</option>
                                <option value="Yes">{"Compulsory"}</option>
                                <option value="No">{"Optional"}</option>
                            </select>

                            <span className='text-danger'>{errors.videos?.message}</span>

                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"OG"}</label>

                            <select
                                {...register(`OG`)}
                                name={`OG`}
                                className={errors.OG ? 'form-control is-invalid' : 'form-control'}
                                //defaultValue={props.data.OG}
                                >

                                <option value="">{"--Select*--"}</option>
                                <option value="Yes">{"Compulsory"}</option>
                                <option value="No">{"Optional"}</option>
                            </select>

                            <span className='text-danger'>{errors.OG?.message}</span>
                        </div>
                        {props.survey_type && <div className="col-12 col-md-8">
                            <label className="f-w-600 f-14">
                                {"Configure Multi Level Answer"}
                            </label>

                            <Typeahead
                                id="basic-typeahead"
                                labelKey="description"

                                options={mlaList.data}
                                isLoading={mlaList.loading}
                                placeholder="--Select*--"

                                //  selected={mla}
                                selected={mla}
                                multiple={true}
                                onChange={handlechange}
                            />
                        </div>}


                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-primary-blue' onClick={handleSubmit(onSubmit)}>Update Item</button>
                </ModalFooter>
            </Modal>
            {/* </Draggable> */}
        </React.Fragment>
    )
}

export default UpdateItems