import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import logo from "../../../../assets/images/avtar/1.jpg";
import user from '../../../../assets/images/Vedam/user.png'
import { getCustomers } from "../../../../api";
import { ToastContainer, toast } from "react-toastify";
import { Edit, EyeOff } from "react-feather";
import EditCustForm from "./EditCustForm";

function CustomerUserDash() {
  const params = useParams();
  const [userRecord, setUserRecord] = useState([]);
  const [modal8, setModal8] = useState();
  const [dataForUpdation, setDataForUpdation] = useState();

  const [show, setShow] = useState(true);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  function fetchCustomer() {
    getCustomers({ Id: params.Id }).then(
      (res) => {
        console.log(res);
        // store in state
        setUserRecord(res.data.res[0]);
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <EditCustForm
        modal8={modal8}
        toggle8={toggle8}
        data={dataForUpdation}
        fetchCustomer={fetchCustomer}
      />

      
      <div className="py-4 org_detail_page">
        <div className="container-fluid">
          <div className="row pb-3">
            <div className="title bg-primary-blue " id="card_title">
              <p className="mb-0 f-24">Organisation Details</p>
            </div>
          </div>



          <div className="row pb-3">
            <div>
              <button
                className="btn btn-primary-blue"
                style={{ float: "right" }}
              >
                Generate Report
              </button>
            </div>
          </div>



          <div className="row">
            {show ? (
              <>
                <div className="col-12 col-md-4">
                  <div className="card">
                    <div className="card-body">

                      <div>
                        <img
                          src={user}
                          alt="user prof logo"
                          className="image-circle"
                          style={{ height: "100px" }}
                        />

                        {/* <button
                          className="btn btn-primary-blue"
                          style={{ float: "right" }}
                          onClick={() => setShow(!show)}
                        >
                          Hide Details
                        </button> */}
                        <div className="pull-right">
                          <EyeOff className="cursor_pointer" onClick={() => setShow(!show)} />
                          <Edit className=" ms-2 cursor_pointer" onClick={() => { toggle8(); setDataForUpdation(userRecord); }} />
                        </div>
                      </div>



                      <div className=" my-2">
                        <p className="f-16">{userRecord.name}</p>

                      </div>

                      <div className="row">
                        <ul className="list-group p-0">

                          <li className="list-group-item text-left">
                            <span className="pull-right">
                              {userRecord.customerType}
                            </span>{" "}
                            <strong>Type</strong>
                          </li>
                          <li className="list-group-item text-left">
                            <span className="pull-right">
                              {userRecord.email}
                            </span>{" "}
                            <strong>Email</strong>
                          </li>
                          <li className="list-group-item text-left">
                            <span className="pull-right">
                              {userRecord.contactNumber}
                            </span>{" "}
                            <strong>Contact</strong>
                          </li>
                          <li className="list-group-item text-left">
                            <span className="pull-right">
                              {userRecord.address}
                            </span>{" "}
                            <strong>Address</strong>
                          </li>
                          <li className="list-group-item text-left">
                            <span className="pull-right">
                              {userRecord.city}
                            </span>{" "}
                            <strong>City</strong>
                          </li>
                          <li className="list-group-item text-left">
                            <span className="pull-right">
                              {userRecord.state}
                            </span>{" "}
                            <strong>State</strong>
                          </li>
                          <li className="list-group-item text-left">
                            <span className="pull-right">
                              {userRecord.country}
                            </span>{" "}
                            <strong>Country</strong>
                          </li>
                          <li className="list-group-item text-left">
                            <span className="pull-right">
                              <a href="#">{userRecord.website}</a>
                            </span>{" "}
                            <strong>Website</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex">
                            <h5 className="mb-0">No. of Inspections</h5>
                          </div>
                          <div className="project-widgets text-center">
                            <h1 className="counter" style={{ color: "#2264ab" }}>{120}</h1>
                            <h6 className="mb-0">per/month</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex">
                            <h5 className="mb-0">Call Minutes</h5>
                          </div>
                          <div className="project-widgets text-center">
                            <h1 className="counter" style={{ color: "#2264ab" }}>
                              {65000}
                            </h1>
                            <h6 className="mb-0">per/month</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex">
                            <h5 className="mb-0">No. of Users</h5>
                          </div>
                          <div className="project-widgets text-center">
                            <h1 className="counter" style={{ color: "#2264ab" }}>{250}</h1>
                            <h6 className="mb-0">per/annum</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex">
                            <h5 className="mb-0">No. of Vessels</h5>
                          </div>
                          <div className="project-widgets text-center">
                            <h1 className="counter" style={{ color: "#2264ab" }}>{100}</h1>
                            <h6 className="mb-0">per/annum</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-12 col-md-2">
                  <button
                    className="btn btn-primary-blue"                    
                    onClick={() => setShow(!show)}
                  >
                    Show Details
                  </button>
                </div>

                <div className="col-12 col-md-10">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex">
                            <h5 className="mb-0">No. of Inspections</h5>
                          </div>
                          <div className="project-widgets text-center">
                            <h1 className="counter" style={{ color: "#2264ab" }}>{120}</h1>
                            <h6 className="mb-0">per/month</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex">
                            <h5 className="mb-0">Call Minutes</h5>
                          </div>
                          <div className="project-widgets text-center">
                            <h1 className="counter" style={{ color: "#2264ab" }}>
                              {65000}
                            </h1>
                            <h6 className="mb-0">per/month</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex">
                            <h5 className="mb-0">No. of Users</h5>
                          </div>
                          <div className="project-widgets text-center">
                            <h1 className="counter" style={{ color: "#2264ab" }}>{250}</h1>
                            <h6 className="mb-0">per/annum</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex">
                            <h5 className="mb-0">No. of Vessels</h5>
                          </div>
                          <div className="project-widgets text-center">
                            <h1 className="font-primary counter">{100}</h1>
                            <h6 className="mb-0">per/annum</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>



        </div>
      </div>
    </React.Fragment>
  );
}

export default CustomerUserDash;
