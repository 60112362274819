import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { createSubscription, getAllCountries } from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(30, "30 characters allowed !")
      .required("Name is required !"),
    inspectionsCount: yup
      .string()
      .required("Inspection Nos is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    callMinutes: yup
      .string()
      .required("Call minutes is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    userCount: yup
      .string()
      .required("User is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    vesselCount: yup
      .string()
      .required("Vessels is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    monthlySubscription: yup
      .string()
      .required("Monthly subscription is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    annualSubscription: yup
      .string()
      .required("Annual subscription is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    currency_id: yup
      .array()
      .min(1, "Currency is required")
      .nullable()
      .required("Currency is required !"),
    perSurvey: yup
      .string()
      .required("Per survey is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
    perMinute: yup
      .string()
      .required("Per minute is required !")
      .matches(/^[0-9]+$/, "Only numbers allowed !"),
  })
  .required();

function NewSubscriptionForm(props) {
  const [modal8, setModal8] = useState();
  const [currencyList, setCurrencyList] = useState({
    data: [],
    loading: false,
  });

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const toggle8 = () => {
    setModal8(!modal8);

    if (modal8 === false || modal8 === undefined) {
      // resetting the form
      reset();
      setCurrencyList({ ...currencyList, data: [], loading: true });

      getAllCountries().then(
        (res) => {
          console.log("res", res);
          setCurrencyList({ ...currencyList, data: res.data, loading: false });
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      var reqPayload = data;
      reqPayload.currency_id = data.currency_id[0].id;
      reqPayload.inspectionsCount = parseInt(data.inspectionsCount);
      reqPayload.callMinutes = parseInt(data.callMinutes);
      reqPayload.userCount = parseInt(data.userCount);
      reqPayload.vesselCount = parseInt(data.vesselCount);

      console.log("reqPayload", reqPayload);

      createSubscription(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Subscription Plan Created Successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            toggle8();

            setTimeout(() => {
              props.FetchSubscription("refresh");
            }, 200);
          }
        },
        (err) => {
          console.log("err", err);
          console.log("err.response", err.response);

          if (err.response.status === 400) {
            toast.error("Plan name already exists!", {
              autoClose: 3000,
              theme: "light",
            });
          } else {
            toast.error("Something went wrong, please try again !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };
  return (
    <React.Fragment>
      <ToastContainer />

      <button
        style={{ fontSize: "16px" }}
        onClick={toggle8}
        className="btn btn-primary-blue"
      >
        New Plan
      </button>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {"Create New Subscription Plan"}
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Name"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Plan Name*"
                    name="name"
                    {...register("name")}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Inspection Nos"}</label>
                  <input
                    className={
                      errors.inspectionsCount
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter No. Of Inspections*"
                    name="inspectionsCount"
                    {...register("inspectionsCount")}
                  />

                  <span className="text-danger">
                    {errors.inspectionsCount?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Call Minutes"}</label>
                  <input
                    className={
                      errors.callMinutes
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Call Mintues*"
                    name="callMinutes"
                    {...register("callMinutes")}
                  />

                  <span className="text-danger">
                    {errors.callMinutes?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Users"}</label>
                  <input
                    className={
                      errors.userCount
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter No. Of Users*"
                    name="userCount"
                    {...register("userCount")}
                  />

                  <span className="text-danger">
                    {errors.userCount?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Vessels"}</label>
                  <input
                    className={
                      errors.vesselCount
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter No. Of Vessels*"
                    name="vesselCount"
                    {...register("vesselCount")}
                  />

                  <span className="text-danger">
                    {errors.vesselCount?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Monthly Subscription"}</label>
                  <input
                    className={
                      errors.monthlySubscription
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Montly Amount*"
                    name="monthlySubscription"
                    {...register("monthlySubscription")}
                  />

                  <span className="text-danger">
                    {errors.monthlySubscription?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Annual Subscription"}</label>
                  <input
                    className={
                      errors.annualSubscription
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Yearly Amount*"
                    name="annualSubscription"
                    {...register("annualSubscription")}
                  />

                  <span className="text-danger">
                    {errors.annualSubscription?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Currency"}</label>

                  <Controller
                    name="currency_id"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="currency"
                        multiple={false}
                        options={currencyList.data}
                        isLoading={currencyList.loading}
                        placeholder="--Select*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.currency_id ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.currency_id?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Per Survey"}</label>
                  <input
                    className={
                      errors.perSurvey
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter PerSurvey Value*"
                    name="perSurvey"
                    {...register("perSurvey")}
                  />

                  <span className="text-danger">
                    {errors.perSurvey?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Per Minute"}</label>
                  <input
                    className={
                      errors.perMinute
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter PerMinute Value*"
                    name="perMinute"
                    {...register("perMinute")}
                  />

                  <span className="text-danger">
                    {errors.perMinute?.message}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Create Plan"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default NewSubscriptionForm;
