import React, { useState, useEffect, useReducer } from "react";
// import NewUserForm from './NewUserForm';
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { getCustomerAdminUsers, deleteUser } from "../../../../api/index";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Trash2, Edit } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
// import EditUserForm from './EditUserForm';
import NewUserFormForCustomer from "./NewUserFormForCustomer";

function CustomerAdminUsers() {
  const [modal8, setModal8] = useState();
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [totalRecords, setTotalRecords] = useState("");

  const initialState = {
    loading: false,
    error: "",
    userList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          userList: action.userList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  // Deleting customer
  const delUser = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteUser({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("User deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            setTimeout(() => {
              fetchUsers();
            }, 200);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      userList: state.userList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchUsers();
  }, [state.page, state.itemsPerPage]);

  function fetchUsers(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      userList: state.userList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
      };
    }

    getCustomerAdminUsers(reqPayload).then(
      (res) => {
        console.log("res", res);
        setTotalRecords(res.data.count);

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          userList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {/* <EditUserForm modal8={modal8} toggle8={toggle8} data={dataForUpdation} fetchUsers={fetchUsers} /> */}

      <div className="vdi_usermaster py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  <p className="mb-0 f-24">Organisation Users</p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-end">
                    <NewUserFormForCustomer fetchUsers={fetchUsers} />
                  </div>
                  {/* <NewUserForm fetchUsers={fetchUsers} /> */}

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.userList.length > 0 ? (
                    <div
                      className="table-responsive mt-4"
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                          <tr
                            className="f-18"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            {/* <th scope="col">{"Id"}</th> */}
                            <th scope="col">{"UserId"}</th>
                            <th scope="col">{"Name"}</th>
                            <th scope="col">{"Username"}</th>
                            <th scope="col">{"Email"}</th>
                            <th scope="col">{"Role"}</th>
                            {/* <th scope="col">{"StartDate"}</th> */}
                            {/* <th scope="col">{"EndDate"}</th> */}
                            <th scope="col">{"Status"}</th>
                            {/* <th scope="col">{"Action"}</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {state.userList.map((val, i) => (
                            <tr key={i} className="f-16">
                              {/* <th scope="row">{"1"}</th> */}
                              <td>{val.Id}</td>
                              <td>{val.name}</td>
                              <td>{val.username}</td>
                              <td>
                                {val.email === null ||
                                val.email === undefined ||
                                val.email.length === 0
                                  ? "NA"
                                  : val.email}
                              </td>
                              <td>
                                {val.role === null ? "NA" : val.role.name}
                              </td>
                              {/* <td>{"NA"}</td> */}
                              {/* <td>{"NA"}</td> */}
                              <td>
                                {val.status === "true"
                                  ? "Active"
                                  : val.status === "false"
                                  ? "Inactive"
                                  : val.status}
                              </td>

                              {/* <td>
                                                                    <Edit
                                                                        onClick={() => { toggle8(); setDataForUpdation(val) }}
                                                                        id={"editToolTip" + i}
                                                                        className='cursor_pointer '
                                                                        height={'18px'}
                                                                        width={'18px'} />

                                                                    <UncontrolledTooltip placement="bottom" target={"editToolTip" + i}>
                                                                        {"Edit Record"}
                                                                    </UncontrolledTooltip>

                                                                    <Trash2
                                                                        onClick={() => delUser(val)}
                                                                        id={"deleteToolTip" + i}
                                                                        className='cursor_pointer ml-1'
                                                                        height={'18px'} width={'18px'} />
                                                                    <UncontrolledTooltip placement="bottom" target={"deleteToolTip" + i}>
                                                                        {"Delete Record"}
                                                                    </UncontrolledTooltip>
                                                                </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No User Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Organisation's user list is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() =>
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                userList: state.userList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              })
                            }
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() =>
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                userList: state.userList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              })
                            }
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CustomerAdminUsers;
