import React, { useState, useEffect, useReducer } from "react";
import CreateNewSOPForm from "./CreateNewSOPForm";
import { Trash2, Edit, PlusSquare } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { getSOP, deleteSOP, getMethodology } from "../../../../api/index";
// import EditSOPForm from './EditSOPForm';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Sop() {
  const navigate = useNavigate();
  // const [modal8, setModal8] = useState();
  const [apiBySearch, setApiBySearch] = useState(false);
  // const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const user = localStorage.getItem("user");
  const customer_id = localStorage.getItem("customer_id");
  const [methodology, setMethodology] = useState({ data: [], loading: false });
  const [totalRecords, setTotalRecords] = useState("");

  const initialState = {
    loading: false,
    error: "",
    sopList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          sopList: action.sopList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // const toggle8 = () => {
  //     setModal8(!modal8)
  // }

  // Deleting customer
  const delSOP = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteSOP({ Id: val.SOP.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("I&G deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            fetchSOPS();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  useEffect(() => {
    setMethodology({ ...methodology, data: [], loading: true });
    getMethodology().then(
      (res) => {
        console.log("res", res);
        setMethodology({ ...methodology, data: res.data, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      sopList: state.sopList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchSOPS();
  }, [state.page, state.itemsPerPage]);

  function fetchSOPS(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      sopList: state.sopList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer_id: customer_id,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
        customer_id: customer_id,
      };
    }

    getSOP(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          sopList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  return (
    <React.Fragment>
      {/* <EditSOPForm modal8={modal8} toggle8={toggle8} data={dataForUpdation} fetchSOPS={fetchSOPS} /> */}

      <div className="vdi_sop py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  {/* <h4 className='mb-0'>SOP</h4> */}
                  <p className="mb-0 f-24">
                    {"Instructions & Guidelines Management"}
                  </p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-end mb-4">
                    <CreateNewSOPForm fetchSOPS={fetchSOPS} />
                  </div>

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.sopList.length > 0 ? (
                    <div
                      className="table-responsive "
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                          <tr
                            className="f-18"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            {/* <th scope="col">{"Id"}</th> */}
                            <th scope="col">{"I&G_Name"}</th>
                            <th scope="col">{"Description"}</th>
                            <th scope="col">{"Selfie"}</th>
                            <th scope="col">{"Opening Meeting"}</th>
                            <th scope="col">{"Methodology"}</th>
                            <th scope="col">{"Photos"}</th>
                            <th scope="col">{"Videos"}</th>
                            <th scope="col">{"OG"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.sopList.map((val, i) => (
                            <tr key={i} className="f-16">
                              {/* <td>{val.SOP.Id}</td> */}
                              <td>{val.SOP.name}</td>
                              <td>{val.SOP.description}</td>
                              <td>
                                {val.SOP.selfie === "Yes"
                                  ? "Compulsory"
                                  : "Optional"}
                              </td>
                              <td>
                                {val.SOP.opening_Meeting === "Yes"
                                  ? "Compulsory"
                                  : "Optional"}
                              </td>
                              <td>{val.SOP.methodology?.type}</td>
                              <td>
                                {val.SOP.photos === "Required"
                                  ? "Compulsory"
                                  : "Optional"}
                              </td>
                              <td>
                                {val.SOP.videos === "Required"
                                  ? "Compulsory"
                                  : "Optional"}
                              </td>
                              <td>
                                {val.SOP.OG === "Required"
                                  ? "Compulsory"
                                  : "Optional"}
                              </td>
                              <td>
                                {/* <Link to={{ pathname: `${process.env.PUBLIC_URL}/customeradmin/update/vdi_sop`, state: { data: val, methodology: methodology }, }}> */}
                                <Edit
                                  onClick={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/customeradmin/update/vdi_sop`,
                                      {
                                        state: {
                                          data: val,
                                          methodology: methodology,
                                        },
                                      }
                                    )
                                  }
                                  style={{ color: "#313131" }}
                                  // onClick={() => { toggle8(); setDataForUpdation(val) }}
                                  id={"editToolTip" + i}
                                  className="cursor_pointer "
                                  height={"18px"}
                                  width={"18px"}
                                />
                                {/* </Link> */}
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"editToolTip" + i}
                                >
                                  {"Edit I&G"}
                                </UncontrolledTooltip>

                                <Trash2
                                  onClick={() => delSOP(val)}
                                  id={"deleteToolTip" + i}
                                  className="cursor_pointer ml-1"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"deleteToolTip" + i}
                                >
                                  {"Delete I&G"}
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Sop Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Sop list is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                sopList: state.sopList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC - 10);
                            }}
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                sopList: state.sopList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC + 10);
                            }}
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                      {/* <p className='f-14 text-muted float-end mt-1'>{`Total Records: ${totalRecords}`}</p> */}
                      <div>
                        <div className="d-flex justify-content-end">
                          <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sop;
