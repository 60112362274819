import React from 'react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  authHeader,
  handleResponse,
  configureFakeBackend
} from '../services/fack.backend';
import configDB from '../data/customizer/config'
import PrivateRoute from './private-route';
import Signin from '../auth/signin';
import AppLayout from '../components/app';
import Login from '../pages/login';
import LoginWithBgImg from '../pages/loginWithBgImg';
import LoginWithVideo from '../pages/loginWithVideo';
import Signup from '../pages/signup';
import SignupWithImg from '../pages/signupWithImg';
import SignupWithVideo from '../pages/signupWithVideo';
import UnlockUser from '../pages/unlockUser';
import ForgetPwd from '../pages/forgetPwd';
import ResetPwd from '../pages/resetPwd';
import ComingSoon from '../pages/comingsoon';
import ComingSoonImg from '../pages/comingsoonImg';
import ComingSoonVideo from '../pages/comingsoonVideo';
import Maintenance from '../pages/maintenance';
import Error400 from '../pages/errors/error400';
import Error401 from '../pages/errors/error401';
import Error403 from '../pages/errors/error403';
import Error404 from '../pages/errors/error404';
import Error500 from '../pages/errors/error500';
import Error503 from '../pages/errors/error503';
import { routes, SuperAdminroutes, Verifierroutes, Surveyorsroutes, SiteCoordinatorRoutes } from './layouts-routes';
import Callback from '../auth/callback';
import SigninUI from '../components/ProjectComponents/Common/Components/SigninUI';
import ResetPassword from '../components/ProjectComponents/ResetPassword/ResetPassword';
import Toastr from '../components/advance/toastr';
import GuestUser from '../components/ProjectComponents/Common/Components/GuestUser';
import Testing from '../components/ProjectComponents/Common/Components/Testing';
// import { routes } from './layouts-routes';
// import Callback from '../auth/callback';
import AdminLogin from "../auth/adminLogin"
import { createBrowserHistory as createHistory } from 'history';
import ReportForApp from '../components/ProjectComponents/Common/Components/ReportForApp';
import ReportForAppOpen from '../components/ProjectComponents/Common/Components/ReportForAppOpen';
import ForgotPassword from '../components/ProjectComponents/ResetPassword/ForgotPassword';
import ImageDownload from '../components/ProjectComponents/Common/Components/ImageDownload';
import ShowGraph from '../components/ProjectComponents/Common/Components/ShowGraph';


configureFakeBackend();

const history = createHistory();
const MainRoutes = () => {
  const jwt_token = localStorage.getItem('access');
  const user = localStorage.getItem('user');
  // const jwt_token = localStorage.getItem('access');

  useEffect(() => {
    const abortController = new AbortController();
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch('/users', requestOptions).then(handleResponse)
    const color = localStorage.getItem('color')
    const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
    document.body.classList.add(layout);
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

    console.log("user", user);

    return function cleanup() {
      abortController.abort();
    }

  }, []);


  // useEffect(() => {
  //   console.log("history", history);
  //   history.listen((location, action) => {
  //     console.log(action, location.pathname, location.state);
  //   });

  // }, [window.location.pathname]);


  return (
    <>
      <Routes>
        <Route exact path='/' element={<PrivateRoute />} >



          {jwt_token ?
            user === 'customeradmin' ?
              routes.map(({ path, Component }, i) => (
                <Route element={<AppLayout />} key={i}>
                  {jwt_token &&
                    <Route exact
                      path={`/`}
                      element={<Navigate to={`${process.env.PUBLIC_URL}/customeradmin/dashboard`} />}
                    />}
                  <Route exact path={path} element={Component} />
                </Route>
              ))
              :
              user === "superadmin_approver" || user === "superadmin_executive" ?

                SuperAdminroutes.map(({ path, Component }, i) => (
                  <Route element={<AppLayout />} key={i}>
                    {jwt_token &&
                      <Route exact
                        path={`/`}
                        element={<Navigate to={`${process.env.PUBLIC_URL}/admin/vdi_customer`} />}
                      />}
                    <Route exact path={path} element={Component} />
                  </Route>
                ))

                :

                user === "Verifier" ?

                  Verifierroutes.map(({ path, Component }, i) => (
                    <Route element={<AppLayout />} key={i}>
                      {jwt_token &&
                        <Route exact
                          path={`/`}
                          element={<Navigate to={`${process.env.PUBLIC_URL}/verifier/vessels`} />}
                        />}
                      <Route exact path={path} element={Component} />
                    </Route>
                  ))
                  :

                  user === "Surveyor" ?
                    Surveyorsroutes.map(({ path, Component }, i) => (
                      <Route element={<AppLayout />} key={i}>
                        {jwt_token &&
                          <Route exact
                            path={`/`}
                            element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />}
                          />}
                        <Route exact path={path} element={Component} />
                      </Route>
                    ))


                    :
                    user === "SiteSurveyor" ?
                      SiteCoordinatorRoutes.map(({ path, Component }, i) => (
                        <Route element={<AppLayout />} key={i}>
                          {jwt_token &&
                            <Route exact
                              path={`/`}
                              element={<Navigate to={`${process.env.PUBLIC_URL}/sitecoordinator/vessels`} />}
                            />}
                          <Route exact path={path} element={Component} />
                        </Route>
                      ))

                      : null
            :
            <Route
              path="*"
              element={<Navigate to="/signin" replace />}
            />

          }
        </Route>


        {/* <Route exact path={`${process.env.PUBLIC_URL}/advance/bootstrap-notify`} element={<Toastr />} /> */}
        <Route exact path={`${process.env.PUBLIC_URL}/signin`} element={<SigninUI />} />
        <Route exact path={`${process.env.PUBLIC_URL}/graph/:cid/:cuid/:sid/:tid/:token`} element={<ShowGraph />} />
        <Route exact path={`${process.env.PUBLIC_URL}/resetpassword`} element={<ResetPassword />} />
        <Route exact path={`${process.env.PUBLIC_URL}/forgotpassword`} element={<ForgotPassword />} />
        <Route exact path={`${process.env.PUBLIC_URL}/download/:id`} element={<ImageDownload />} />


        <Route exact path={`${process.env.PUBLIC_URL}/call/:id/:sid/:cid/:sd/:ed/:cm`} element={<GuestUser />} />

        <Route exact path={`${process.env.PUBLIC_URL}/testing/:id`} element={<Testing />} />
        <Route exact path={`${process.env.PUBLIC_URL}/appreport/:sid/:custid/:token/:cid`} element={<ReportForApp />} />
        <Route exact path={`${process.env.PUBLIC_URL}/appreportopen/:sid/:custid/:token`} element={<ReportForAppOpen />} />


        <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/login`} element={<Login />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/loginWithBgImg`} element={<LoginWithBgImg />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/loginWithVideo`} element={<LoginWithVideo />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/signup`} element={<Signup />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/signupWithImg`} element={<SignupWithImg />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/signupWithVideo`} element={<SignupWithVideo />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} element={<UnlockUser />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} element={<ForgetPwd />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} element={<ResetPwd />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} element={<ComingSoon />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonImg`} element={<ComingSoonImg />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonVideo`} element={<ComingSoonVideo />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} element={<Maintenance />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} element={<Error400 />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} element={<Error401 />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} element={<Error403 />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} element={<Error404 />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} element={<Error500 />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} element={<Error503 />} />
        <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
      </Routes>
    </>
  );


};

export default MainRoutes;