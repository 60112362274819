import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { updateUser, getRoles } from "../../../../api";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Name is required !")
      .max(30, "30 characters allowed !")
      .matches(/^[A-Za-z ]+$/, "Only characters allowed !"),
    email: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    username: yup
      .string()
      .required("Username is required !")
      .max(30, "30 characters allowed !"),
    role: yup
      .array()
      .min(1, "Role is required !")
      .nullable()
      .required("Role is required !"),
    status: yup
      .string()
      .nullable()
      .required("Status is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
  })
  .required();

function EditUserForm(props) {
  const [roleList, setRoleList] = useState({ data: [], loading: false });

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  const sameAsEmail = watch("sameAsEmail");

  useEffect(() => {
    console.log("props.modal8", props.modal8);

    if (props.modal8 === true) {
      reset();
    }
  }, [props.modal8]);

  useEffect(() => {
    console.log("sameAsEmail", sameAsEmail);
    if (sameAsEmail == true) {
      const email = getValues("email");
      setValue("username", email);
    }
  }, [sameAsEmail]);

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const reqPayload = { ...data };

      reqPayload.Id = props.data.Id;
      reqPayload.role = data.role[0].Id;
      delete reqPayload.sameAsEmail;

      console.log("reqPayload", reqPayload);
      updateUser(reqPayload).then(
        (res) => {
          console.log("res", res);

          toast.success("User updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          props.toggle8();

          setTimeout(() => {
            props.fetchUsers();
          }, 200);
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  useEffect(() => {
    setRoleList({ ...roleList, data: [], loading: true });

    getRoles().then(
      (res) => {
        console.log("res", res);
        let tempList = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].name === "SiteSurveyor") {
            res.data[i].name = "SiteCoordinator";
            tempList.push(res.data[i]);
          } else {
            tempList.push(res.data[i]);
          }
        }
        setRoleList({ ...roleList, data: tempList, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  useEffect(() => {
    console.log("props.data", props.data);
  }, [props.data]);

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal8}
        toggle={props.toggle8}
        size="lg"
        backdrop="static"
      >
        <ModalHeader className="bg-primary-blue" toggle={props.toggle8}>
          {"Update User"}
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Name"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Your Name*"
                    name="name"
                    {...register("name")}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Email"}</label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register("email")}
                    defaultValue={props.data?.email}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Username"}</label>
                  <input
                    className={
                      errors.username
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Username*"
                    name="username"
                    {...register("username")}
                    defaultValue={props.data?.username}
                  />

                  <span className="text-danger">
                    {errors.username?.message}
                  </span>
                  <div className="checkbox p-0">
                    <input
                      id="solid3"
                      {...register("sameAsEmail")}
                      name="sameAsEmail"
                      type="checkbox"
                    />
                    <label htmlFor="solid3" className="mb-0">
                      {"Same as email"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Role"}</label>
                  <Controller
                    name="role"
                    control={control}
                    defaultValue={
                      props.data === undefined
                        ? []
                        : props.data.role === null
                        ? []
                        : [
                            {
                              name: props.data.role.name,
                              Id: props.data.role.Id,
                            },
                          ]
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        defaultSelected={
                          props.data === undefined
                            ? []
                            : props.data.role === null
                            ? []
                            : [
                                {
                                  name: props.data.role.name,
                                  Id: props.data.role.Id,
                                },
                              ]
                        }
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={roleList.data}
                        isLoading={roleList.loading}
                        placeholder="--Select*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.role ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.role?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Status"}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue={props.data?.status}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={props.data?.status}
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        className={
                          errors.status
                            ? "custom-select form-control is-invalid"
                            : "custom-select form-control"
                        }
                      >
                        <option select="">{"--Select*--"}</option>
                        <option value="Active">{"Active"}</option>
                        <option value="Inactive">{"Inactive"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Update User"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default EditUserForm;
