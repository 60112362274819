import { Settings } from "react-feather";

export const SURVEYOR_MENUITEMS = [
  {
    title: "Dashboard",
    icon: Settings,
    path: "/dashboard/default",
    type: "link",
    active: false,
  },
  // children: [
  {
    path: "/dashboard/default/surveyList2/Active/na",
    title: "Assigned Surveys",
    icon: Settings,
    type: "link",
    active: false,
  },
  {
    path: "/dashboard/default/surveyList2/InProgress/na",
    title: "InProgress Surveys",
    icon: Settings,
    type: "link",
    active: false,
  },
  {
    path: "/dashboard/default/surveyList2/Completed/na",
    title: "Completed Surveys",
    icon: Settings,
    type: "link",
    active: false,
  },
  {
    path: "/dashboard/default/surveyList2/Signed/na",
    title: "Submitted Surveys",
    icon: Settings,
    type: "link",
    active: false,
  },
  {
    path: "/dashboard/default/surveyList2/Correction/na",
    title: "Correction Required Surveys",
    icon: Settings,
    type: "link",
    active: false,
  },
  {
    path: "/dashboard/default/surveyList2/Verified/na",
    title: "Verified Surveys",
    icon: Settings,
    type: "link",
    active: false,
  },
];
