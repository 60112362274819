import React, { useState, useEffect, useReducer } from "react";
import { Trash2, Edit } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import CreateNewCheckListForm from "./CreateNewCheckListForm";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import { getCheckList, deleteCheckList } from "../../../../api/index";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";

function CheckListManagement() {
  let navigate = useNavigate();

  // const [modal8, setModal8] = useState();
  const [apiBySearch, setApiBySearch] = useState(false);
  // const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const user = localStorage.getItem("user");
  const customer_id = localStorage.getItem("customer_id");

  const initialState = {
    loading: false,
    error: "",
    checkList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          checkList: action.checkList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // const toggle8 = () => {
  //     setModal8(!modal8)
  // }

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      checkList: state.checkList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchCheckList();
  }, [state.page, state.itemsPerPage]);

  function fetchCheckList(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      checkList: state.checkList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer_id: customer_id,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
        customer_id: customer_id,
      };
    }

    getCheckList(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          checkList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  // Deleting customer
  const delCheckList = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteCheckList({ Id: val.checklist.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Checklist deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            fetchCheckList();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  return (
    <React.Fragment>
      <div className="vdi_checklist py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  <p className="mb-0 f-24">CheckList Management</p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-end">
                    <Link
                      to={`${process.env.PUBLIC_URL}/customeradmin/create/vdi_checklist`}
                    >
                      <button
                        style={{ fontSize: "16px " }}
                        className="btn btn-primary-blue"
                      >
                        Create New Checklist
                      </button>
                    </Link>
                  </div>

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.checkList.length > 0 ? (
                    <div
                      className="table-responsive "
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-bordered mt-4">
                        <thead className="thead-light">
                          <tr className="f-18">
                            <th scope="col">{"Name"}</th>
                            <th scope="col">{"Description"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.checkList.map((val, i) => (
                            <tr key={i} className="f-16">
                              <td scope="row">{val.checklist.name}</td>
                              <td>{val.checklist.description}</td>
                              <td>
                                {/* <Link to={{ pathname: `${process.env.PUBLIC_URL}/customeradmin/update/vdi_checklist`, state: val }}> */}
                                <Edit
                                  onClick={() => {
                                    navigate(
                                      `${process.env.PUBLIC_URL}/customeradmin/update/vdi_checklist`,
                                      { state: val }
                                    );
                                  }}
                                  style={{ color: "#313131" }}
                                  id={"editToolTip" + i}
                                  className="cursor_pointer "
                                  height={"18px"}
                                  width={"18px"}
                                />
                                {/* </Link> */}

                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"editToolTip" + i}
                                >
                                  {"Edit Record"}
                                </UncontrolledTooltip>

                                <Trash2
                                  onClick={() => delCheckList(val)}
                                  id={"deleteToolTip" + i}
                                  className="cursor_pointer ml-1"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"deleteToolTip" + i}
                                >
                                  {"Delete Record"}
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Checklist Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Checklist is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                checkList: state.checkList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC - 10);
                            }}
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                checkList: state.checkList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC + 10);
                            }}
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CheckListManagement;
