import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createUser, getRoles } from "../../../../api";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { getAutoPassword } from "../../../../api/index";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Name is required !")
      .max(30, "30 characters allowed !")
      .matches(/^[A-Za-z ]+$/, "Only characters allowed !"),
    email: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    username: yup
      .string()
      .required("Username is required !")
      .max(30, "30 characters allowed !"),
    password: yup
      .string()
      .required("Password is required !")
      .min(6, "Minimum 6 characters required !")
      .max(8, "Maximum 8 characters allowed !")
      .matches(/^((?!\s).)*$/, "Space not allowed"),
    role_id: yup
      .array()
      .min(1, "Role is required !")
      .nullable()
      .required("Role is required !"),
    status: yup
      .string()
      .nullable()
      .required("Status is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
  })
  .required();

function NewUserForm(props) {
  const [modal8, setModal8] = useState();
  const [roleList, setRoleList] = useState({ data: [], loading: false });
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const toggle8 = () => {
    setModal8(!modal8);

    if (modal8 === false || modal8 === undefined) {
      // resetting form
      reset();

      setRoleList({ ...roleList, data: [], loading: true });

      getRoles().then(
        (res) => {
          console.log("res", res);
          let tempList = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].name === "SiteSurveyor") {
              res.data[i].name = "SiteCoordinator";
              tempList.push(res.data[i]);
            } else {
              tempList.push(res.data[i]);
            }
          }

          let x = tempList.filter(
            (item) =>
              item.name == "superadmin_executive" ||
              item.name == "superadmin_approver"
          );
          console.log("templist", x);
          setRoleList({ ...roleList, data: x, loading: false });
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  const sameAsEmail = watch("sameAsEmail");
  const allocateAutoPassword = watch("allocateAutoPassword");

  useEffect(() => {
    console.log("sameAsEmail", sameAsEmail);
    if (sameAsEmail == true) {
      const email = getValues("email");
      setValue("username", email);
    }
  }, [sameAsEmail]);

  useEffect(() => {
    console.log("allocateAutoPassword", allocateAutoPassword);
    if (allocateAutoPassword == true) {
      getAutoPassword().then(
        (res) => {
          console.log("res");
          setValue("password", res.data);
          toast.success("Password Generated !", {
            autoClose: 3000,
            theme: "light",
          });
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    }
  }, [allocateAutoPassword]);

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const reqPayload = { ...data };
      reqPayload.role_id = data.role_id[0].Id;
      reqPayload.adminEmail = data.username;

      delete reqPayload.sameAsEmail;
      delete reqPayload.allocateAutoPassword;

      console.log("reqPayload", reqPayload);

      createUser(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("User Created Successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          toggle8();

          setTimeout(() => {
            props.fetchUsers("refresh");
          }, 200);
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <button
        style={{ fontSize: "16px" }}
        onClick={toggle8}
        className="btn btn-primary-blue float-right"
      >
        New User
      </button>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {"Create New User"}
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Name"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Your Name*"
                    name="name"
                    {...register("name")}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Email"}</label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register("email")}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Username"}</label>
                  <input
                    className={
                      errors.username
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Username*"
                    name="username"
                    {...register("username")}
                  />

                  <span className="text-danger">
                    {errors.username?.message}
                  </span>

                  <div className="checkbox p-0">
                    <input
                      id="solid3"
                      {...register("sameAsEmail")}
                      name="sameAsEmail"
                      type="checkbox"
                    />
                    <label htmlFor="solid3" className="mb-0">
                      {"Same as email"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Password"}</label>

                  <div className="input-group">
                    <input
                      className={
                        errors.password
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type={showPassword ? "text" : "password"}
                      placeholder="Password*"
                      name="password"
                      {...register("password")}
                    />
                    <div className="input-group-append">
                      {showPassword ? (
                        <span className="input-group-text">
                          <i
                            className="fa cursor_pointer fa-eye-slash"
                            onClick={() => setShowPassword(false)}
                          ></i>
                        </span>
                      ) : (
                        <span className="input-group-text">
                          <i
                            className="fa cursor_pointer fa-eye"
                            onClick={() => setShowPassword(true)}
                          ></i>
                        </span>
                      )}
                    </div>
                  </div>

                  <span className="text-danger">
                    {errors.password?.message}
                  </span>

                  <div className="checkbox p-0">
                    <input
                      id="dafault-checkbox2"
                      {...register("allocateAutoPassword")}
                      name="allocateAutoPassword"
                      type="checkbox"
                    />
                    <label htmlFor="dafault-checkbox2" className="mb-0">
                      {"Allocate auto password"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Role"}</label>

                  <Controller
                    name="role_id"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={roleList.data}
                        isLoading={roleList.loading}
                        placeholder="--Select*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.role_id ? true : false}
                      />
                    )}
                  />

                  {/* <Controller
                                        as={Typeahead}
                                        control={control}
                                        name="role_id"
                                        // {...register("state")}
                                        rules={{ required: true }}
                                        id="basic-typeahead"
                                        labelKey="name"
                                        options={roleList.data}
                                        isLoading={roleList.loading}
                                        multiple={false}
                                        placeholder="--Select*--"
                                        isInvalid={errors.role_id ? true : false}
                                    /> */}

                  <span className="text-danger">{errors.role_id?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Status"}</label>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={"Active"}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={"Active"}
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        className={
                          errors.status
                            ? "custom-select form-control is-invalid"
                            : "custom-select form-control"
                        }
                      >
                        <option select="">{"--Select*--"}</option>
                        <option defaultValue="Active">{"Active"}</option>
                        <option defaultValue="Inactive">{"Inactive"}</option>
                      </select>
                    )}
                  />
                  {/* <Controller
                                        control={control}
                                        name="status"
                                        defaultValue={null}

                                        render={({ onChange, value }) => (
                                            <select
                                                onChange={onChange}
                                                selected={value}
                                                className={errors.status ? "custom-select form-control is-invalid" : "custom-select form-control"}>
                                                <option select="">{"--Select*--"}</option>
                                                <option defaultValue="Active">{"Active"}</option>
                                                <option defaultValue="Inactive">{"Inactive"}</option>
                                            </select>
                                        )}
                                    /> */}

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Create User"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default NewUserForm;
