import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { delChecklistItemAttachmentv3, getChecklistItemAttachmentv3, uploadChecklistItemAttachmentv3 } from "../../../../api/index";
import { toast } from "react-toastify";
import { compressFile } from "../../Common/Functions/CommonFunctions";
import { Eye, XCircle } from "react-feather";

const FILE_SIZE = "5000000";
const schema = yup
  .object()
  .shape({
    file: yup
      .mixed()
      .test("fileSize", "File can be upload to 5 MB", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= FILE_SIZE;
      }),
  })
  .required();

var FormData = require("form-data");

function RefModal(props) {
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const [compress2, setCompress2] = useState(false);
  const [photolist, setPhotolist] = useState({ loading: false, data: [] });
  useEffect(() => {
    console.log("props", props);

    if (props.refModal === false || props.refModal === undefined) {
      reset();

    }

    if (props.refModal == true) {
      get_checklist_api()
    }
  }, [props]);

  // function handleChange(e) {
  //   console.log(e.target.files);
  //   setData(e.target.files[0]);
  // }

  const onSubmit = async (data) => {
    if (data !== "") {
      console.log("data", data);
      console.log("props.data", props.data);

      let file = data.file[0];

      if (!file.type.startsWith("image/")) {
        toast.error("Please upload only images", {
          autoClose: 2000
        })
        return
      }

      if (file && file.type.startsWith("image/")) {
        console.log("Selected file is an image");
        setCompress2(true);
        await compressFile(data.file[0]).then(
          (res) => {
            console.log("res", res);
            fireApi(res);
            setCompress2(false);
          },
          (err) => {
            console.log("err", err);
            setCompress2(false);
            toast.error("Error in compressing image", {
              autoClose: 1000,
              theme: "light",

            });
          }
        );
      } else {
        fireApi(file);
      }
    } else {
      errors.showMessages();
    }
  };

  function fireApi(file) {
    console.log("file",file)
    const postpayload = {
      checklist_file: file,
      fileType: "Image",
      withAnswer: "False",
      withSurveyOnly: "True",
      survey: props.data,
      withTable: "False",
      isRef: "True",
    };
    console.log("postpayload", postpayload);
    var formdata = new FormData();
    for (var key in postpayload) {
      formdata.append(key, postpayload[key]);
    }

    uploadChecklistItemAttachmentv3(formdata).then(
      (res) => {
        console.log("res", res);
        if (res.status == 200 || res.status == 201) {
          props.toggleRefModal();
          toast.success("Reference image uploaded successfully !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function get_checklist_api() {


    const params = {
      withAnswer: "False",
      withSurveyOnly: "True",
      survey: props.data,
      withTable: "False",
      isRef: "True",
    };
    console.log("params", params);



    getChecklistItemAttachmentv3(params).then(
      (res) => {
        console.log("res", res);
        if (res.status == 200 || res.status == 201) {
          if (res.data.length > 0) {
            setPhotolist({ loading: false, data: res.data })
          }
          else {
            setPhotolist({ loading: false, data: [] })
          }

        }
      },
      (err) => {
        console.log("err", err);
      }
    );


  }

  const handle_photo_delete = (id) => {


    const params = {
      Id: id,
    };
    delChecklistItemAttachmentv3(params).then(
      (res) => {
        console.log("res", res);
        toast.success("Attachment Deleted Successfully...", {
          autoClose: 3000,
          theme: "light",

        });
       
        get_checklist_api()
      },
      (err) => {

        toast.error("Something went wrong", {
          autoClose: 3000,
          theme: "light",

        });
        console.log("err", err);
      }
    );


  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.refModal}
        toggle={props.toggleRefModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={props.toggleRefModal} className="bg-primary-blue">
          {"Upload Reference Images For Report"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <div className="form-group">
                <label className="f-w-600">File</label>
                <input
                  className={
                    errors.file ? "form-control is-invalid" : "form-control"
                  }
                  type={"file"}
                  // onChange={(e) => handleChange(e)}
                  accept="image/*"
                  {...register("file")}
                />
              </div>
              <span className="text-danger">{errors.file?.message}</span>
            </div>
          </div>
          <div className="row mt-3 d-flex ">
            {photolist.loading ?
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
              : photolist.data?.map((val, ind, arr) => (

                <div className="col-12 col-md-3" key={Math.ceil(
                  Math.random() *
                  100000
                )}>

                  <div
                    id="attachment_parent"

                  >
                    <div className="image_attachments m-2">
                      <img

                        style={{
                          borderRadius:
                            "20px",
                        }}
                        className="cursor_pointer"
                        src={
                          val.checklist_file
                        }
                        alt=""
                        height={
                          "150"
                        }
                        width={
                          "100%"
                        }
                      />


                      <div className="date_overlay">
                        <p
                          className="mb-0"
                          style={{
                            lineHeight:
                              "1",
                          }}

                        >

                          {val.name}<br />
                          {new Date(val.created).toLocaleString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                          })}
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        background:
                          "white",
                        height:
                          "18px",
                        width:
                          "18px",
                        position:
                          "absolute",
                        borderRadius:
                          "50%",
                        top: "2px",
                        right:
                          "2px",
                      }}
                    >
                      <XCircle

                        onClick={() => {
                          handle_photo_delete(val.Id);
                        }}
                        className="cursor_pointer"
                        style={{
                          color:
                            "red",
                        }}
                        height={
                          "18px"
                        }
                        width={
                          "18px"
                        }
                      />
                    </div>
                  </div>
                </div>
              ))
            }  </div>

        </ModalBody>
        <ModalFooter>
          <button
            disabled={compress2}
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Upload
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default RefModal;
