import React, { useState, useEffect, useReducer } from "react";
import { getSurveyV2, getSurveyAsPerFilter } from "../../../../api/index";
import { useParams, Link } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Eye } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { toast } from "react-toastify";

function SiteCoordinatorSurvey() {
  let navigate = useNavigate();

  const [filter, setFilter] = useState({ value: "" });
  const [searchVal, setSearchVal] = useState({ value: "" });
  const [surveyNoOfLastRecord, setSurveyNoOfLastRecord] = useState("");
  const [apiBySearch, setApiBySearch] = useState(false);
  const [totalRecords, setTotalRecords] = useState("");
  const [srNo, setSrNo] = useState(0);
  const localData = JSON.parse(localStorage.getItem("login_data"));
  const { id } = useParams();

  const [options, setOptions] = useState([
    { name: "Assigned" },
    { name: "Inprogress" },
    { name: "Completed" },
    { name: "Submitted" },
    { name: "Verified" },
    { name: "CorrectionRequired" },
  ]);

  const initialState = {
    loading: false,
    error: "",
    surveyList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          surveyList: action.surveyList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      surveyList: state.surveyList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchSurveys();
  }, [state.page, state.itemsPerPage]);

  function fetchSurveys(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      surveyList: state.surveyList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      customer_id: localData.customer_id,
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      vessel: id,
      remoteSurveyor: localData.user,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
        customer_id: localData.customer_id,
      };

      if (filter.value.length > 0) {
        reqPayload.statusList = filter.value;
      } else {
        delete reqPayload.statusList;
      }
    } else {
      if (searchVal.value.length > 0) {
        reqPayload.searchVal = searchVal.value;
      } else {
        delete reqPayload.searchVal;
      }
    }

    if (para1 === "filter") {
      reqPayload = {
        customer_id: localData.customer_id,
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        vessel: id,
        remoteSurveyor: localData.user,
        statusList: para2,
      };
      console.log("searchVal", searchVal.value);
      if (searchVal.value.length > 0) {
        reqPayload.searchVal = searchVal.value;
      } else {
        delete reqPayload.searchVal;
      }
    } else {
      if (filter.value.length > 0) {
        reqPayload.statusList = filter.value;
      } else {
        delete reqPayload.statusList;
      }
    }

    console.log("filter", filter.value);
    console.log("reqPayload", reqPayload);

    getSurveyAsPerFilter(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          surveyList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });

        if (res.data.res.length > 0) {
          console.log("res.data.res[0].surveyNo", res.data.res[0].surveyNo);
          setSurveyNoOfLastRecord(res.data.res[0].surveyNo);
        }
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  return (
    <React.Fragment>
      <div className="site_coordinator_survey fontFamily py-4">
        <div className="card">
          <div className="title bg-primary-blue" id="card_title">
            <p className="mb-0 f-24">
              <span
                onClick={() => {
                  navigate(-1);
                }}
                className="text-light me-2"
                style={{ fontSize: "20px", cursor: "pointer" }}
              >
                <i className="fa fa-arrow-circle-left"></i>
              </span>
              Survey List
            </p>
          </div>
          <div className="card-body">
            <div className="row">
              {/* <div className="col-12 col-md-3">
                                <input
                                    style={{ maxWidth: "300px" }}
                                    className="form-control"
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            searchVal.value = e.target.value;
                                            setSearchVal({ ...searchVal, value: searchVal.value });
                                            fetchSurveys("search", e.target.value);
                                            setApiBySearch(true);
                                        } else {
                                            searchVal.value = e.target.value;
                                            setSearchVal({ ...searchVal, value: searchVal.value });
                                            fetchSurveys();
                                            setApiBySearch(false);
                                        }
                                    }}
                                />
                            </div> */}

              <div className="col-12 col-md-3">
                <Typeahead
                  style={{ maxWidth: "300px" }}
                  id="basic-typeahead"
                  labelKey="name"
                  multiple={true}
                  options={options}
                  placeholder="Select Status"
                  onChange={(e) => {
                    filter.value = e;
                    setFilter({ ...filter, value: filter.value });
                    fetchSurveys("filter", e);
                  }}
                />
              </div>

              <div className="col-12 ">
                {state.loading ? (
                  <div className="loader-box justify-content-center">
                    <div className="loader">
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                    </div>
                  </div>
                ) : state.surveyList.length > 0 ? (
                  <div
                    className="table-responsive mt-4"
                    style={{ maxHeight: "70vh" }}
                  >
                    <table className="table table-striped table-bordered ">
                      <thead className="thead-light">
                        <tr
                          className="f-18"
                          style={{
                            position: "sticky",
                            top: "0",
                            background: "white",
                          }}
                        >
                          <th scope="col">{"Vessel"}</th>
                          <th scope="col">{"Survey No"}</th>
                          <th scope="col">{"Survey Name"}</th>
                          <th scope="col">{"Verifier"}</th>
                          <th scope="col">{"Status"}</th>
                          <th scope="col">{"Assigned StartDate"}</th>
                          <th scope="col">{"Assigned EndDate"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.surveyList.map((val, i) => (
                          <tr key={i} className="f-16">
                            <td>{val.vessel.name}</td>
                            <td>{val.surveyNo}</td>
                            <td>{val.checklist.name}</td>
                            <td>{val.verifier.name}</td>
                            <td>{val.status}</td>
                            <td>{new Date(val.startDate).toLocaleString()}</td>
                            <td>{new Date(val.endDate).toLocaleString()}</td>
                            <td>
                              <Eye
                                style={{ color: "#313131" }}
                                // onClick={() => { toggle8(); setDataForUpdation(val) }}
                                id={"eye" + i}
                                className="cursor_pointer "
                                height={"18px"}
                                width={"18px"}
                                onClick={() => {
                                  navigate(
                                    `${process.env.PUBLIC_URL}/sitecoordinator/vessels/survey/${val.Id}`,
                                    { state: val }
                                  );
                                }}
                              />

                              <UncontrolledTooltip
                                placement="bottom"
                                target={"eye" + i}
                              >
                                {"view"}
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : apiBySearch ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"No Survey Found !"}
                  </p>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Survey list is empty"}
                  </p>
                )}

                {state.loading ? null : (
                  <div className="mt-4">
                    <Pagination
                      aria-label="Page navigation"
                      className="pagination justify-content-end pagination-primary"
                    >
                      <PaginationItem
                        disabled={state.page === 1 ? true : false}
                      >
                        <PaginationLink
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: HANDLE_ACTION,
                              page: state.page - 1,
                              loading: state.loading,
                              error: state.error,
                              surveyList: state.surveyList,
                              itemsPerPage: state.itemsPerPage,
                              maxPage: state.maxPage,
                            });
                            setSrNo((prevC) => prevC - 10);
                          }}
                        >
                          {"Previous"}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem active>
                        <PaginationLink>{state.page}</PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        disabled={
                          state.page === state.maxPage || state.maxPage === 0
                            ? true
                            : false
                        }
                      >
                        <PaginationLink
                          last
                          onClick={() => {
                            dispatch({
                              type: HANDLE_ACTION,
                              page: state.page + 1,
                              loading: state.loading,
                              error: state.error,
                              surveyList: state.surveyList,
                              itemsPerPage: state.itemsPerPage,
                              maxPage: state.maxPage,
                            });
                            setSrNo((prevC) => prevC + 10);
                          }}
                          className="cursor_pointer"
                        >
                          {"Next"}
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>

                    {/* <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p> */}
                    <div>
                      <div className="d-flex justify-content-end">
                        <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                      </div>
                      <div className="d-flex justify-content-end">
                        <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SiteCoordinatorSurvey;
