import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const config = require("./app_config.json")

const firebaseConfig = {
    apiKey: "AIzaSyBHo3pTlUQ6sxoeQClgLt77i2bjH_AlXac",
    authDomain: "vedam-34eb1.firebaseapp.com",
    projectId: "vedam-34eb1",
    storageBucket: "vedam-34eb1.appspot.com",
    messagingSenderId: "676579856863",
    appId: "1:676579856863:web:25adfe3d1ea3228057746a",
    measurementId: "G-N8R00CE3ZL",
    databaseURL: "https://vedam-34eb1.firebaseio.com"
}




// const auth = getAuth(firebaseApp);
// const db = initializeFirestore(firebaseApp, { experimentalForceLongPolling: true });


// export const googleProvider = new firebase.auth.GoogleAuthProvider();
// export const facebookProvider = new firebase.auth.FacebookAuthProvider();
// export const twitterProvider = new firebase.auth.TwitterAuthProvider();
// export const githubProvider = new firebase.auth.GithubAuthProvider();


const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ experimentalForceLongPolling: true });
const db = firebase.firestore();


// firebase.firestore.setLogLevel('debug');

// Auth0
export default db ;
export const auth0 = ({
    domain: config.auth0.domain,
    clientId: config.auth0.clientID,
    redirectUri: config.auth0.redirectUri
})

// Jwt
export const Jwt_token = config.jwt_token



