import React, { useState, useEffect, createRef } from "react";
import { AgoraVideoPlayer } from "agora-rtc-react";
import Controls from "./Controls";
import { useParams } from "react-router-dom";
import { useScreenshot } from "use-react-screenshot";
import { axiosPostData, postData } from "../../../api/APIinstance";
import AgoraRTC from "agora-rtc-sdk-ng";
AgoraRTC.setLogLevel(4);

function GuestVideos(props) {
    const { users, tracks, setInCall, setStart, endCall, client } = props;
    const [enlargeUser, setEnlargeUser] = useState(0);
    const [floatingTrack, setFloatingTrack] = useState(tracks[1]);
    const [fixedScreenTrack, setFixedScreenTrack] = useState(tracks[1]);
    const [swith, setSwitch] = useState(false);
    const [videoTrackScreenShare, setVideoTrackScreenShare] = useState(null);

    const [hide, setHide] = useState(false);
    const { id } = useParams();
    const ref = createRef(null);
    const [image, takeScreenshot] = useScreenshot();

    useEffect(() => {
        console.log("users", users);
        console.log("tracks", tracks);

    }, [users, tracks]);

    useEffect(() => {
        const url = window.location.href;

        // console.log("url.includes", url.includes("call"));

        if (url.includes("call")) {
            setHide(true);
        } else {
            setHide(false);
        }

    }, []);

    const onScreenChoose = async (para) => {
        console.log("para", para);
        if (para == "start") {

            let sources = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    cursor: "always",
                    height: 1000,
                    width: 1200,
                },
                audio: false,
            });

            const videoTrack = sources.getVideoTracks()[0];
            // console.log(videoTrack);
            if (videoTrack) {
                const screenTrack = AgoraRTC.createCustomVideoTrack({
                    mediaStreamTrack: videoTrack,
                });
                console.log("screenTrack", screenTrack);
                setVideoTrackScreenShare(screenTrack);

                setFloatingTrack(screenTrack);
                client.unpublish(tracks[1]).then(() => {
                    console.log('Video track unpublished successfully');
                }).catch((error) => {
                    console.error('Failed to unpublish video track', error);
                });
                client.publish([tracks[0], screenTrack]);
            }
        } else {
            setVideoTrackScreenShare(null);
            setFloatingTrack(tracks[1]);
            client.unpublish(videoTrackScreenShare).then(() => {
                console.log('Video track unpublished successfully');
            }).catch((error) => {
                console.error('Failed to unpublish video track', error);
            });
            client.publish([tracks[0], tracks[1]]);
        }

    };



    function enlargerScreen(id) {
        console.log("id", id);
        setEnlargeUser(id);
    }

    function maximizeSelectedScreen(val) {
        if (val === 1) {
            setFloatingTrack(users[enlargeUser].videoTrack);
            setSwitch(true);
        } else {
            setFloatingTrack(tracks[1]);
            setSwitch(false);
        }
    }

    function setScreenShareStreamOnFixedScreen(stream) {
        setFloatingTrack(stream);
    }

    const getImage = async () => {
        console.log("ref.current", ref.current);
        // await takeScreenshot(ref.current);
        if (!!ref) {
            await takeScreenshot(ref?.current);

            if (!!image) {
                console.log("!!ref", !!ref);
                console.log("!!image", !!image);
                console.log(image);

                fetch(image, { headers: { "Content-Type": "image/png" } })
                    .then((res) => res.blob())
                    .then((bb) => {
                        const file = new File([bb], "nothing", { type: "image/png" });
                        console.log(
                            "file object==============================================================",
                            file
                        );
                        // setFileImage(file);
                        const formData = new FormData();
                        formData.append("checklist_file", file);
                        formData.append("fileType", "Image");
                        formData.append("withAnswer", "False");
                        formData.append("survey", id);
                        console.log("formData", formData);
                        let response = axiosPostData("CheckListAttachment/", formData);
                        console.log(response, "response");
                    });
            }
        } else {
            console.log("video call not started");
        }
    };

    return (
        <React.Fragment>

            <div className="row">

                <div className="col-12 col-md-9 mx-auto" style={{ position: 'relative' }}>


                    <div className="Guest_fixed_screen">
                        {users.length === 0 ? (
                            <>
                                <AgoraVideoPlayer className="vid" videoTrack={fixedScreenTrack} />
                            </>
                        ) : (

                            swith === false ?
                                <AgoraVideoPlayer
                                    className={"vid"}
                                    videoTrack={users[enlargeUser].videoTrack}
                                    key={users[enlargeUser].uid}
                                // onClick={() => enlargerScreen(users[enlargeUser].uid)}
                                />
                                :
                                <AgoraVideoPlayer
                                    className={"vid"}
                                    videoTrack={tracks[1]}

                                />
                        )}

                        <Controls
                            videoTrackScreenShare={videoTrackScreenShare}
                            onScreenChoose={onScreenChoose}
                            endCall={endCall}
                            tracks={tracks}
                            setStart={setStart}
                            setInCall={setInCall}
                        />

                        {users.length > 0 ? (
                            <div className="Guest_floating_screen">
                                <AgoraVideoPlayer
                                    className="vid"
                                    videoTrack={floatingTrack}
                                    onClick={() => {
                                        maximizeSelectedScreen(swith === false ? 1 : 2);
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>





                </div>

                {users.length > 0 ? (
                    <div className="col-12 col-md-3">
                        <div className="row" id="Guest_otherusersrow_parent mt-2 mt-md-0">

                            {users.length > 0 &&
                                users.map((user, i) => {
                                    if (i !== enlargeUser) {
                                        if (user.videoTrack) {
                                            return (
                                                <div className="col-12 mb-2">
                                                    <div className="Guest_otherusersrow cursor_pointer">
                                                        <AgoraVideoPlayer
                                                            className={"vid"}
                                                            videoTrack={user.videoTrack}
                                                            key={user.uid}
                                                            onClick={() => enlargerScreen(i)}
                                                        />

                                                    </div>
                                                </div>
                                            );
                                        } else return null;
                                    } else return null;
                                })}
                        </div>
                    </div>
                ) : null}



            </div>
        </React.Fragment>
    );
}

export default GuestVideos;