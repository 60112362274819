import React, { useState, useEffect, useReducer } from "react";
import { Trash2, Edit, Copy, List, Table, PlusCircle } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import clipboard from "../../../../assets/images/Vedam/clipboard.png";

import { delete_noc, get_noc, post_noc } from "../../../../api";
import Edit_NOC_Master from "./Edit_NOC_Master";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("NOC name is required !"),
  })
  .required();

function NOC_Master() {
  let navigate = useNavigate();
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;
  const category_data = useLocation();

  const [modal8, setModal8] = useState();
  const [modal, setModal] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdate, setDataForUpdate] = useState({ data: {} });

  const user = localStorage.getItem("user");

  const toggle8 = () => {
    setModal8(!modal8);
    if (modal8 === false || modal8 === undefined) {
      reset();
    }
  };
  const toggle = () => {
    setModal(!modal);
    if (modal === false || modal === undefined) {
      reset();
    }
  };

  const initialState = {
    loading: false,
    error: "",
    nocList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          nocList: action.nocList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  
  const [search,setSearch]=useState();


  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      nocList: state.nocList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetch_NOC_lists();
  }, [state.page, state.itemsPerPage]);

  useEffect(() => { }, []);

  function fetch_NOC_lists(para1) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      nocList: state.nocList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      page: state.page,
      entriesPerPage: state.itemsPerPage,
      customer: customer_id,
      nocCategoryId: category_data.state.Id,
    };


    if (para1 || search) {
      reqPayload = {
        page: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal:para1==undefined?search:para1,
        customer: customer_id,
        nocCategoryId: category_data.state.Id
      };
    }


    get_noc(reqPayload).then(
      (res) => {
        console.log("res_soc    ", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.total));
        setTotalRecords(res.data.total);
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          nocList: res.data.payload,
          itemsPerPage: state.itemsPerPage,
          maxPage:
          (para1 !== "" && search!=="")
              ? calMaxPage(res.data.total)
              : calMaxPage(res.data.total),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  // Deleting customer
  const del_noc_category = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        delete_noc({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("NOC  deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            if (state.nocList.length == 1 && state.page !== 1) {
              dispatch({
                type: HANDLE_ACTION,
                page: state.page - 1,
                loading: state.loading,
                error: state.error,
                nocList: state.nocList,
                itemsPerPage: state.itemsPerPage,
                maxPage: state.maxPage,
              });
            } else {
              fetch_NOC_lists();
            }
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const payload = {
        noc: data.name,
        nocCategoryId: category_data.state.Id,
        customer:customer_id
      };

      post_noc(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("NOC  created successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          toggle8();
          fetch_NOC_lists();
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message, {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Edit_NOC_Master
        modal={modal}
        toggle={toggle}
        data={dataForUpdate.data}
        fetch_NOC_lists={fetch_NOC_lists}
        nocCategoryId={category_data.state.Id}
      />

      <div className="vdi_nocList fontFamily py-4">
        <div className="card">
          <div className="title bg-primary-blue" id="card_title">
            <p className="mb-0 f-24">
              <span
                onClick={() => {
                  navigate(-1);
                }}
                className="text-light me-2"
                style={{ fontSize: "20px", cursor: "pointer" }}
              >
                <i className="fa fa-arrow-circle-left"></i>
              </span>
              NOC Master</p>
          </div>
          <div className="card-body">
          <div className="d-flex justify-content-between">
          <div >
                  <input placeholder="Search" className="form-control"
                    onChange={(e) => {
                      setSearch(e.target.value)
                      fetch_NOC_lists(e.target.value);
                    }}
                  />
                </div>

          <div className="d-flex justify-content-end mb-4">
              <button
                onClick={toggle8}
                style={{ fontSize: "16px " }}
                className="btn btn-primary-blue"
              >
                Create New NOC
              </button>
            </div>

          </div>
           

            {state.loading ? (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            ) : state.nocList.length > 0 ? (
              <div className="table-responsive " style={{ maxHeight: "70vh" }}>
                <table className="table table-striped table-bordered">
                  <thead className="thead-light">
                    <tr
                      className="f-18"
                      style={{
                        position: "sticky",
                        top: "0",
                        background: "white",
                      }}
                    >
                      <th scope="col">{"Sr.No"}</th>
                      <th scope="col">{"NOC Name"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.nocList.map((val, i) => (
                      <tr key={i} className="f-16">
                        <td>{i + 1}</td>
                        <td>{val.noc}</td>
                        <td>
                          <Edit
                            onClick={() => {
                              dataForUpdate.data = val;
                              console.log("val", val);
                              setDataForUpdate({
                                ...dataForUpdate,
                                data: dataForUpdate.data,
                              });
                              toggle();
                            }}
                            style={{ color: "#313131" }}
                            id={"editToolTip" + i}
                            className="cursor_pointer "
                            height={"18px"}
                            width={"18px"}
                          />

                          <UncontrolledTooltip
                            placement="bottom"
                            target={"editToolTip" + i}
                          >
                            {"Edit NOC"}
                          </UncontrolledTooltip>

                          <Trash2
                            onClick={() => del_noc_category(val)}
                            id={"deleteToolTip" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"deleteToolTip" + i}
                          >
                            {"Delete NOC"}
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : apiBySearch ? (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"No NOC List Found !"}
              </p>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"NOC List is empty"}
              </p>
            )}

            {state.loading ? null : (
              <div className="mt-4">
                <Pagination
                  aria-label="Page navigation"
                  className="pagination justify-content-end pagination-primary"
                >
                  <PaginationItem disabled={state.page === 1 ? true : false}>
                    <PaginationLink
                      className="cursor_pointer"
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page - 1,
                          loading: state.loading,
                          error: state.error,
                          nocList: state.nocList,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC - 10);
                      }}
                    >
                      {"Previous"}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem active>
                    <PaginationLink>{state.page}</PaginationLink>
                  </PaginationItem>

                  <PaginationItem
                    disabled={
                      state.page === state.maxPage || state.maxPage === 0
                        ? true
                        : false
                    }
                  >
                    <PaginationLink
                      last
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page + 1,
                          loading: state.loading,
                          error: state.error,
                          nocList: state.nocList,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC + 10);
                      }}
                      className="cursor_pointer"
                    >
                      {"Next"}
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>

                {/* <p className='f-14 text-muted float-end mt-1'>{`Total Records: ${totalRecords}`}</p> */}
                <div>
                  <div className="d-flex justify-content-end">
                    <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader toggle={toggle8} className="bg-primary-blue">
          {"Create New NOC"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"NOC Name"}</label>
              {/* <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                placeholder="Name*"
              /> */}
               <textarea
                 className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                placeholder="Enter NOC Name*"
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.name?.message}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
           Save
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default NOC_Master;
