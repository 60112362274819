import React, { useState, useEffect, useReducer } from "react";
import { Trash2, Edit, FileText } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import CreateVesselForm from "./CreateVesselForm";
import { Typeahead } from "react-bootstrap-typeahead";
import { getVessels, deleteVessel } from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import EditVesselForm from "./EditVesselForm";

function VesselManagement() {
  const [modal8, setModal8] = useState(false);
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [search,setSearch]=useState("");

  const customer_id = localStorage.getItem("customer_id");

  const initialState = {
    loading: false,
    error: "",
    customerList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          customerList: action.customerList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  // Deleting customer
  const delCustomer = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteVessel({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            setTimeout(() => {
              fetchVessels();
            }, 200);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      customerList: state.customerList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchVessels();

  }, [state.page, state.itemsPerPage,]);

  function fetchVessels(para1) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      customerList: state.customerList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer_id,
    };
    console.log("para11",para1)
    if(para1=="refresh"){
      para1=undefined
    }

    if (para1) {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal:para1||search,
        customer_id,
      };
    }

    console.log("reqpayload",reqPayload)

    getVessels(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
       console.log("serach",search)
        if(para1){
          setTotalRecords(res.data.filtercount)
        }
        else{
          setTotalRecords(res.data.count);
        }
        

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          customerList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
          (para1)
          ? calMaxPage(res.data.res.length)
          :calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  const delay = 1000;
  let timerId;

  const handleSearchChange = (val) => {
    clearTimeout(timerId);
    if (val == "") {
      setSearch("")
      fetchVessels()
    }
    else {
      console.log("val", val)

     

      timerId = setTimeout(() => {
        setSearch(val)
        fetchVessels(val)

      }, delay);
    }


  }

  useEffect(() => {

    return () => {
      clearTimeout(timerId);
    };
  }, []);


  return (
    <React.Fragment>
      <EditVesselForm
        modal8={modal8}
        toggle8={toggle8}
        data={dataForUpdation}
        fetchVessels={fetchVessels}
      />

      <div className="vdi_vessel py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  {/* <h4 className='mb-0'>Vessel Management</h4> */}
                  <p className="mb-0 f-24">Vessel Management</p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4">
                    <input
                      style={{ maxWidth: "300px" }}
                      className="form-control"
                      type="text"
                      name="search"
                      placeholder="Search by IMO No./Vessel Name"
                      // checking if search value is not empty then only calling the function
                      onChange={(e) => {
                        handleSearchChange(e.target.value)
                      }}
                    />

                    <CreateVesselForm
                      fetchVessels={fetchVessels}
                      totalRecords={totalRecords}
                    />
                  </div>

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.customerList.length > 0 ? (
                    <div
                      className="table-responsive "
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-striped table-bordered ">
                        <thead className="thead-light">
                          <tr
                            className="f-18"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            {/* <th scope="col">{"VesselId"}</th> */}
                            <th scope="col">{"IMO No"}</th>
                            <th scope="col">{"Unique No"}</th>
                            <th scope="col">{"Vessel Name"}</th>
                            <th scope="col">{"Vessel Type"}</th>
                            <th scope="col">{"G.T"}</th>
                            <th scope="col">{"Vessel Info"}</th>
                            <th scope="col">{"Status"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.customerList.map((val, i) => (
                            <tr className="f-16" key={i}>
                              {/* <th scope="row">{val.Id}</th> */}
                              <td>{val.number}</td>
                              <td>
                                {val.uniqueNumber ? val.uniqueNumber : "NA"}
                              </td>
                              <td>{val.name}</td>
                              <td>{val.type.name}</td>
                              <td>{val.GT ? val.GT : "NA"}</td>
                              <td>
                                {val.files.length === 0
                                  ? "NA"
                                  : val.files.map((val, i) => (
                                    <React.Fragment key={i}>
                                      {/* <a href={val.file}> */}
                                      <FileText
                                        onClick={() => {
                                          window
                                            .open(val.file, "_blank")
                                            .focus();
                                        }}
                                        className="mr-1 cursor_pointer"
                                        id={"fileTextToolTip" + i}
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                      {/* </a> */}
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target={"fileTextToolTip" + i}
                                      >
                                        {"File Record " + (i + 1)}
                                      </UncontrolledTooltip>
                                    </React.Fragment>
                                  ))}
                              </td>
                              <td>
                                {val.status === true ? "Active" : "Inactive"}
                              </td>
                              <td>
                                <Edit
                                  onClick={() => {
                                    toggle8();
                                    setDataForUpdation(val);
                                  }}
                                  id={"editToolTip" + i}
                                  className="cursor_pointer "
                                  height={"18px"}
                                  width={"18px"}
                                />

                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"editToolTip" + i}
                                >
                                  {"Edit Vessel"}
                                </UncontrolledTooltip>

                                <Trash2
                                  onClick={() => delCustomer(val)}
                                  id={"deleteToolTip" + i}
                                  className="cursor_pointer ml-1"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"deleteToolTip" + i}
                                >
                                  {"Delete Vessel"}
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Vessel Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Vessel list is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                customerList: state.customerList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC - 10);
                            }}
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                customerList: state.customerList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC + 10);
                            }}
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                      {/* <p className='f-14 text-muted float-end mt-1'>{`Total Records: ${totalRecords}`}</p> */}
                      <div>
                        <div className="d-flex justify-content-end">
                          <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VesselManagement;
