import React, { useState, useEffect } from 'react';
import { getVesselByUser } from '../../../../api/index';
import ship2 from '../../../../assets/images/Vedam/ship2.png';
import { useNavigate } from 'react-router-dom';

function SiteCoordinatorVessels() {
    let navigate = useNavigate();

    const [vesselList, setVesselList] = useState({ loading: false, data: [] });
    const localData = JSON.parse(localStorage.getItem('login_data'));

    useEffect(() => {
        setVesselList({ vesselList, loading: true, data: [] });

        const reqPayload = {
            customer_id: localData.customer_id,
            user_id: localData.user
        }
        getVesselByUser(reqPayload).then((res) => {
            console.log("res", res);
            setVesselList({ vesselList, loading: false, data: res.data });
        }, (err) => {

            console.log("err", err);
        });
    }, []);

    return (
        <React.Fragment>
            <div className='site_coordinator fontFamily py-4'>
                <div className='card'>
                    <div className='title bg-primary-blue' id="card_title">
                        <p className='mb-0 f-24'>Select Vessel</p>
                    </div>
                    <div className='card-body'>
                        <div className='row'>

                            {vesselList.loading ?

                                <div className="loader-box justify-content-center">
                                    <div className="loader">
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                    </div>
                                </div>

                                :
                                vesselList.data.length > 0 ?
                                    vesselList.data.map((val, i) => (

                                        <div className='col-12 col-md-4' key={i}>
                                            <div
                                                onClick={() => { navigate(`${process.env.PUBLIC_URL}/sitecoordinator/vessels/${val.Id}/survey`, { state: val }) }}
                                                className='card shadow-sm cursor_pointer'
                                                style={{ border: '1px solid #c7c9c8' }}>
                                                <div className='card-body'>
                                                    <p className='f-w-600 mb-0 f-22 text-center'>{val.name}</p>

                                                    <div className='text-center mt-4'>
                                                        <img className='' src={ship2} height='100' width='100' />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <p className='text-danger mb-0 f-22 text-center mt-4'>{"There are no vessels !"}</p>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SiteCoordinatorVessels