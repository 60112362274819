import React, { useState, useEffect } from "react";
import {
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  getRemoteNetworkQuality,
  AgoraRTCStats,
} from "agora-rtc-sdk-ng";
import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";

import Controls from "./Controls";
import Videos from "./Videos";

var config = ClientConfig;
config = {
  mode: "rtc",
  codec: "vp8",
};

function VideoCall(props) {
  const { setInCall, channelName, appId, token } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [temp, setTemp] = useState({ data: false });
  const useClient = createClient(config);
  const client = useClient();
  const user_id = parseInt(localStorage.getItem("user_id"));
  // const user_id_guest = parseInt(localStorage.getItem('user_id_guest'));

  const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  useEffect(() => {
    console.log("props", props);
    console.log("user_id_guest", localStorage.getItem("user_id_guest"));
    console.log("tracks", tracks);
  }, []);

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      // console.log("token", token.data);
      // console.log("channelName", channelName);
      // console.log("appId", appId);
      // console.log("name", name);
      // console.log("localStorage.getItem('user_id')", localStorage.getItem('user_id'));
      console.log("channelName", channelName);
      console.log("client", client);
      console.log("ready", ready);
      console.log("tracks", tracks);

      await client.join(appId, name, token.data, user_id);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
      temp.data = true;
      setTemp({ ...temp, data: temp.data });
      console.log("temp.data", temp.data);
    };

    if (ready && tracks) {
      console.log("init ready");
      init(channelName);
    }
  }, [channelName, client, ready, tracks]);

  // useEffect(() => {
  //     console.log("start", start);
  //     console.log("tracks", tracks);
  // }, [start, tracks]);



  return (
    <React.Fragment>
      <div className="Agora_App">
        {start && tracks && (
          <Videos
            endCall={props.endCall}
            users={users}
            tracks={tracks}
            setStart={setStart}
            setInCall={setInCall}
          />
        )}

        {/* {ready && tracks && (
                    <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
                )} */}
      </div>

    </React.Fragment>
  );
}

export default VideoCall;
