import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCheckListItemStatus } from "../../../../api/index";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import SurveyReport from "../SurveyReport";
import SignatureCanvas from "react-signature-canvas";
import { Container, Row, Col } from "reactstrap";
import {
  axiosPostData,
  getData,
  getStringData,
  putData,
} from "../../../../api/APIinstance";
import { compressFile } from "../../../../components/ProjectComponents/Common/Functions/CommonFunctions";
import { updateSurveyV1 } from "../../../../api/index";
import { Show_suvey_report } from "../surveyList/Show_suvey_report";

function PreviewV2() {
  const navigate = useNavigate();
  let location=useLocation();
  const [surveyItemStatus, setSurveyItemStatus] = useState({
    data: [],
    loading: false,
  });
  const localData = JSON.parse(localStorage.getItem("login_data"));
  const [isOpen, setIsOpen] = useState(0);
  const [modal8, setModal8] = useState();
  const [comments, setComments] = useState("");
  const [Loc, setLoc] = useState();
  const [signed, setSigned] = useState(true);
  const [pdfFile, setPDFfile] = useState();

  const [error, setError] = useState({
    comError: "",
    signError: "",
  });

  const { id, cid } = useParams();

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition);
    }
  }

  const showPosition = (position) => {
    setLoc({
      lat: Number(Number(position.coords.latitude).toFixed(6)),
      long: Number(Number(position.coords.longitude).toFixed(6)),
    });
  };

  const toggle = async (i, sec_id) => {
    console.log("id", i);

    if (isOpen === i) {
      setIsOpen(null);
    } else {
      setIsOpen(i);
    }
  };

  const toggle8 = () => {
    setModal8(!modal8);
  };

  useEffect(async () => {
    getLocation();
   
    console.log("locatin",location)
    setSurveyItemStatus({ ...surveyItemStatus, loading: true, data: [] });
    const reqPayload = {
      customer_id: localData.customer_id,
      survey_id: id,
      user: localData.user,
    };
    await getCheckListItemStatus(reqPayload).then(
      (res) => {
        console.log("res", res);

        setSurveyItemStatus({
          ...surveyItemStatus,
          loading: false,
          data: res.data,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  let sigPad = {};
  const clear = () => {
    sigPad.clear();
  };
  const trim = async () => {
    console.log("comments", comments);
    console.log("!!comments", !!comments);

    console.log("sigPad.isEmpty", sigPad.isEmpty());

    const isSignEmpty = sigPad.isEmpty();

    if (comments.length === 0) {
      // setError({ ...error, comError: "Please Comment!!" });
      toast.error("Comment is required !", {
        autoClose: 3000,
        theme: "light",
      
      });
      return;
    }
    if (isSignEmpty === true) {
      toast.error("Signature is required !", {
        autoClose: 3000,
        theme: "light",
      
      });
      // setError({ ...error, signError: "Please give Signature!!" });
      return;
    }

    let photo = await {
      trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL("image/png"),
    };
    await fetch(photo.trimmedDataURL, {
      headers: { "Content-Type": "image/png" },
    })
      .then((res) => res.blob())
      .then(async (bb) => {
        const file = await new File([bb], new Date().getTime() + ".png", {
          type: "image/png",
        });
        const userId = JSON.parse(localStorage.getItem("login_data"));
        if (!!Loc) {
          console.log("Loc", Loc);
          console.log("!!Loc", !!Loc);

          compressFile(file).then(
            async (imgres) => {
              console.log("imgres", imgres);
              const formData = new FormData();
              formData.append("sign", imgres);
              formData.append("survey", id);
              formData.append("surveyor", userId.user);
              formData.append("lat", Loc.lat);
              formData.append("long", Loc.long);
              if (!!file) {
                const response = await axiosPostData(
                  "SurveyorSignature/",
                  formData
                );
                if (response.status == 201) {
                  toast.success("Signature Submitted Successfully...", {
                    autoClose: 3000,
                    theme: "light",
                  
                  });
                  await handleStatus();
                  setSigned(false);
                  console.log("--------------dispatchh-------------");
                }
              } else {
                setError({ ...error, signError: "Please give Signature!!" });
              }
            },
            (imgerr) => {
              console.log("imgerr", imgerr);
              toast.error("Something went wrong !", {
                autoClose: 3000,
                theme: "light",
              
              });
            }
          );
        } else {
          console.log("Loc", Loc);
          toast.error("Refreshing your window to capture geo location !", {
            autoClose: 2000,
            theme: "light",
          
          });

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
  };

  const handleStatus = async () => {
    let params = {
      Id: id,
      status: "Signed",
      remarks: comments,
    };

    // let response = await putData("SurveyApi/", params);
    // if (response.status == "Signed") {
    //     if (!!!pdfFile) {
    //         // handlePdf();
    //     }
    //     toast.success("Survey Signed Successfully...", {
    //         autoClose: 3000,
    //         theme: "light",
    //       
    //     });
    // }
    updateSurveyV1(params).then(
      (res) => {
        console.log("res", res);
        toast.success("Survey Signed Successfully...", {
          autoClose: 3000,
          theme: "light",
        
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  return (
    <React.Fragment>
      <div className="v2_preview py-4">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div
                className="bg-primary-blue d-flex justify-content-between"
                id="card_title"
              >
                <p className="mb-0 f-24">
                  <span
                    onClick={() => {
                      if (surveyItemStatus.data.status === "Signed") {
                        navigate(
                          `${process.env.PUBLIC_URL}/dashboard/default/surveyList2/Signed/na`
                        );
                      } else {
                        navigate(-1);
                      }
                    }}
                    className="text-light me-2"
                    style={{ fontSize: "20px", cursor: "pointer" }}
                  >
                    <i className="fa fa-arrow-circle-left"></i>
                  </span>
                  {`Preview`}
                </p>
              </div>
              <div className="card-body">
                <div className="default-according style-1" id="accordionoc">
                  {surveyItemStatus.loading ? (
                    <div className="loader">
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                    </div>
                  ) : (
                    surveyItemStatus.data?.SurveyData?.map((val, i) => (
                      <Card key={i}>
                        <CardHeader className="bg-vedam">
                          <Button
                            as={Card.Header}
                            className="btn btn-link btn-vedam txt-white p-0 d-flex justify-content-between"
                            onClick={() => {
                              toggle(i + 1);
                            }}
                          >
                            <p className="mb-0 f-18"> {`${val.sectionName}`}</p>
                          </Button>
                        </CardHeader>
                        <Collapse isOpen={isOpen === i + 1}>
                          <CardBody className="p-3">
                            {val.checklist.map((item, ind) => (
                              <Card key={ind}>
                                <CardHeader className="bg-light">
                                  <Button
                                    style={{ position: "relative" }}
                                    as={Card.Header}
                                    className="btn btn-link txt-white p-0 "
                                    color="light"
                                  >
                                    <p className="mb-0 f-18 me-5">
                                      {" "}
                                      {`${item.section}`}
                                    </p>
                                    <span
                                      className="ms-5"
                                      style={{
                                        height: "15px",
                                        width: "15px",
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translate(0, -50%)",
                                        borderRadius: "50%",
                                        background: item.test_pass
                                          ? "#51EE27"
                                          : "red",
                                      }}
                                    ></span>
                                  </Button>
                                </CardHeader>
                                <Collapse isOpen={false}>
                                  <CardBody className="p-3"></CardBody>
                                </Collapse>
                              </Card>
                            ))}
                          </CardBody>
                        </Collapse>
                      </Card>
                    ))
                  )}
                </div>

                {surveyItemStatus.data.status === "Verified" ? (
                  <div className="d-flex justify-content-end mt-4">
                    <button
                      className="btn btn-primary-blue text-light"
                      onClick={() => {
                        toggle8();
                      }}
                    >
                      View Report
                    </button>
                  </div>
                ) : null}

                {surveyItemStatus.loading ? (
                  <div className="loader-box justify-content-center">
                    <div className="loader">
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                      <div className="line bg-dark"></div>
                    </div>
                  </div>
                ) : surveyItemStatus.data.status !== "Verified" ? (
                  !signed || surveyItemStatus.data.status === "Signed" ? (
                    <div className="mt-4">
                      {/* <SurveyReport id={id} cid={cid} /> */}
                      <Show_suvey_report id={id} cid={cid} type={location.state.data.surveyType}/>
                    </div>
                  ) : (
                    <>
                      <Row className="justify-content-center mt-4">
                        <Col sm="12" xl="6">
                          <div className="row">
                            <div className="col">
                              <div className="form-group mb-0">
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea4"
                                  rows="3"
                                  placeholder="Overall Comment"
                                  value={comments}
                                  onChange={(e) => setComments(e.target.value)}
                                  style={{
                                    border: "1px solid #000000",
                                    width: "100%",
                                    marginBottom: "20px",
                                    overflow: "hidden",
                                    borderTopLeftRadius: "8px",
                                    borderTopRightRadius: "8px",
                                  }}
                                ></textarea>
                              </div>
                              {/* <div className="text-danger">{error.comError}</div> */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col sm="12" xl="6">
                          <div
                            // ref={canvRef}
                            className="my-4 mx-auto"
                            style={{
                              border: "1px solid #000000",
                              overflow: "hidden",
                              borderRadius: "7px",
                              maxWidth: "700px",
                            }}
                          >
                            <SignatureCanvas
                              ref={(ref) => {
                                sigPad = ref;
                              }}
                              penColor="black"
                              canvasProps={{
                                className: "sigCanvas ",
                                width: 700,
                                height: 200,
                              }}
                            />
                            {/* <div className="text-danger">{error.signError}</div> */}
                          </div>
                          <div
                            style={{
                              gap: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              className="btn btn-primary text-light"
                              // style={{ background: "#0B0968" }}
                              onClick={clear}
                            >
                              ReSign
                            </button>
                            <button
                              className="btn btn-primary text-light"
                              // style={{ background: "#0B0968" }}
                              onClick={trim}
                            >
                              Submit
                            </button>
                            <button
                              className="btn btn-primary text-light"
                              // style={{ background: "#0B0968" }}
                              onClick={() => {
                                // handlePdf();
                                toggle8();
                              }}
                            >
                              Preview Report
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal8} toggle={toggle8} size="xl" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {"Survey Report Preview"}
        </ModalHeader>
        <ModalBody>
          {/* <SurveyReport id={id} cid={cid} /> */}
          <Show_suvey_report id={id} cid={cid} type={location.state.data.surveyType}/>
        </ModalBody>
        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={toggle8}
            className="btn btn-primary-blue float-right"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default PreviewV2;

