import React, { useState, useEffect, useReducer } from "react";
import NewCustomerForm from "./NewCustomerForm";
import { Trash2, Edit } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import { UncontrolledTooltip } from "reactstrap";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import {
  getCustomersUsers,
  getAllRoles,
  deleteCustomersUser,
} from "../../../../api/index";
import EditUserForm from "./EditUserForm";

function UserManagement() {
  const [modal8, setModal8] = useState(false);
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const [roles, setRoles] = useState({ data: [], loading: false });
  const [totalRecords, setTotalRecords] = useState("");
  const [searchVal, setSearchVal] = useState({ data: "" });
  const [selRole, setSelRole] = useState({ data: "" });

  const initialState = {
    loading: false,
    error: "",
    customerList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          customerList: action.customerList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  // Deleting customer
  const delCustomer = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteCustomersUser({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("User deleted successfully !", { autoClose: 3000, theme: "light", });

            setTimeout(() => {
              fetchUsers();
            }, 200);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 3000, theme: "light", });
          }
        );
      }
    });
  };

  useEffect(() => {
    let mounted = true;
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      customerList: state.customerList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    if (mounted) {
      fetchUsers();
    }

    return () => (mounted = false);
  }, [state.page, state.itemsPerPage]);

  useEffect(() => {
    fetchRoles();
  }, []);

  function fetchRoles() {
    setRoles({ ...roles, loading: true, data: [] });
    getAllRoles().then(
      (res) => {
        console.log("res", res);
        if (res.data.length > 0) {
          const newRoles = res.data.map((val, i) => {
            if (val.name == "SiteSurveyor") {
              val.name = "SiteCoordinator";
              return val;
            } else {
              return val;
            }
          });
          console.log("newRoles", newRoles);
          setRoles({ ...roles, loading: false, data: newRoles });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchUsers(para1) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      customerList: state.customerList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    const customer_id = localStorage.getItem("customer_id");

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      customer_id: customer_id,
    };
    if(para1=="refresh"){
      para1=undefined
    }


    if (para1) {
      reqPayload = {
        customer_id: customer_id,
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para1,
        role: selRole.data,
      };
    }
    console.log("selRole.data", selRole.data);
    if (selRole.data.length == 0) {
      delete reqPayload.role;
    }

    if (para1&&para1.length == 0) {
      delete reqPayload.searchVal;
    }

    getCustomersUsers(reqPayload).then(
      (res) => {
        console.log("res", res);
       
      
       
       if(para1){
        setTotalRecords(res.data.filtercount)
      }
      else{
        setTotalRecords(res.data.count);
      }
       

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          customerList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            (para1 )
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
       console.log("state",state.maxPage)
      },
      (err) => {
        console.log("err", err.response);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        } else if (err.response.status === 403) {
          toast.error("Invalid Subscription, contact admin !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }
  const delay = 1000;
  let timerId;

  const handleSearchChange = (e) => {


    
  
    clearTimeout(timerId);

    timerId = setTimeout(() => {
     
      
      fetchUsers(e.target.value);
      setApiBySearch(true);


    }, delay);

  }

  useEffect(() => {

    return () => {
      clearTimeout(timerId);
    };
  }, []);


  const searchbyrole=(para)=>{

    const customer_id = localStorage.getItem("customer_id");

    if(para=="SiteCoordinator"){
       para="SiteSurveyor";
    }
   let  reqPayload = {
      customer_id: customer_id,
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      role:para,
    };


    getCustomersUsers(reqPayload).then(
      (res) => {
        console.log("res", res);
       
    
        setTotalRecords(res.data.filtercount)
      
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          customerList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage: calMaxPage(res.data.res.length) 
        });
      
      },
      (err) => {
        console.log("err", err.response);
      }
    );


  }


  return (
    <React.Fragment>
      <ToastContainer />

      {/* Edit Customer modal */}
      <EditUserForm
        modal8={modal8}
        toggle8={toggle8}
        data={dataForUpdation}
        fetchUsers={fetchUsers}
        totalRecords={totalRecords}
      />

      <div className="vdi_usermaster py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  <p className="mb-0 f-24">User Management</p>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-12 col-md-3">
                      <input
                        style={{ maxWidth: "300px" }}
                        className="form-control"
                        type="text"
                        name="search"
                        placeholder="Search"
                        // checking if search value is not empty then only calling the function
                        onChange={(e) => {
                          //   if (e.target.value.length > 0) {
                          handleSearchChange(e)
                          //   } else {
                          //   }
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-3">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          if (e.target.value.includes("Select")) {
                            selRole.data = "";
                            setSelRole({ ...selRole, data: selRole.data });
                            searchbyrole("")
                          } else {
                            selRole.data =
                              e.target.value == "SiteCoordinator"
                                ? "SiteSurveyor"
                                : e.target.value;
                            setSelRole({ ...selRole, data: selRole.data });
                            setApiBySearch(true);
                           // fetchUsers(e.target.value);
                            searchbyrole(e.target.value)
                          }
                        }}
                      >
                        <option defaultValue={"Select"}>Select Role</option>
                        {roles.data.map((val, i) => (
                          <option value={val.name} key={i}>
                            {val.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-12 col-md-6">
                      <NewCustomerForm
                        fetchUsers={fetchUsers}
                        totalRecords={totalRecords}
                      />
                    </div>
                  </div>

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.customerList.length > 0 ? (
                    <div>
                      <div
                        className="table-responsive"
                        style={{ maxHeight: "70vh" }}
                      >
                        <table className="table table-striped table-bordered">
                          <thead className="thead-light">
                            <tr
                              className="f-18"
                              style={{
                                position: "sticky",
                                top: "0",
                                background: "white",
                              }}
                            >
                              <th scope="col">{"Sr.No"}</th>
                              <th scope="col">{"Name"}</th>
                              <th scope="col">{"Username"}</th>
                              <th scope="col">{"Email"}</th>
                              <th scope="col">{"Phone No."}</th>
                              <th scope="col">{"Assigned Vessel Name"}</th>
                              <th scope="col">{"Role"}</th>
                              {/* <th scope="col">{"LastAccessedDate"}</th> */}
                              <th scope="col">{"Created Date"}</th>
                              <th scope="col">{"Modified Date"}</th>
                              <th scope="col">{"Status"}</th>
                              <th scope="col">{"Action"}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.customerList.map((val, i) => (
                              <tr key={i} className="f-16">
                                <th scope="row">{i + srNo + 1}</th>
                                {/* <th scope="row">{val.id}</th> */}
                                <td>{val.name}</td>
                                <td>{val.username}</td>
                                <td>{val.email}</td>
                                <td>
                                  {val.phone === null ||
                                    val.phone == undefined ||
                                    val.phone.length < 1
                                    ? "NA"
                                    : val.phone}
                                </td>
                                <td>
                                  {val.vessel === null ? "NA" : val.vessel.name}
                                </td>
                                <td>
                                  {val.role === null
                                    ? "NA"
                                    : val.role.name == "SiteSurveyor"
                                      ? "SiteCoordinator"
                                      : val.role.name}
                                </td>
                                {/* <td>{val.user.last_login === null ? 'NA' : val.user.last_login}</td> */}
                                <td>{val.createdDate}</td>
                                <td>{val.updatedDate}</td>
                                <td>
                                  {val.status === "true"
                                    ? "Active"
                                    : val.status === "false"
                                      ? "Inactive"
                                      : val.status}
                                </td>

                                <td>
                                  <Edit
                                    onClick={() => {
                                      toggle8();
                                      setDataForUpdation(val);
                                    }}
                                    id={"editToolTip" + i}
                                    className="cursor_pointer "
                                    height={"18px"}
                                    width={"18px"}
                                  />

                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={"editToolTip" + i}
                                  >
                                    {"Edit User"}
                                  </UncontrolledTooltip>

                                  <Trash2
                                    onClick={() => delCustomer(val)}
                                    id={"deleteToolTip" + i}
                                    className="cursor_pointer ml-1"
                                    height={"18px"}
                                    width={"18px"}
                                  />
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={"deleteToolTip" + i}
                                  >
                                    {"Delete User"}
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No User Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"User list is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                customerList: state.customerList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC - 10);
                            }}
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() => {
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                customerList: state.customerList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              });
                              setSrNo((prevC) => prevC + 10);
                            }}
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                      <div>
                        <div className="d-flex justify-content-end">
                          <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserManagement;
