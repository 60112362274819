import React, { useEffect, useState } from "react";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {Modal} from "react-bootstrap"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { update_soc, } from "../../../../api/index";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("SOC name is required !"),
    srno: yup
      .string()
      .max(30, "30 charaters allowed !")
      .matches(/^[a-zA-Z0-9]*$/, 'SR.No must be alphanumeric')
      .required("Sr.No is required"),
    // sequence: yup
    //   .string()
    //   .required("Sequence is required"),
    nocCategory: yup
      .array()
      .required("Please select NOC category !")
      .min(1, "Please select NOC category !"),
  })
  .required();

function Edit_SOC_Master(props) {
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;
  const customer_id = localStorage.getItem("customer_id");
  useEffect(() => {
  console.log("props.data", props.data);
   reset()
  console.log("modal",props.modal);
    setValue("name", props.data?.soc);
    setValue("srno",props.data?.srno);
    setValue("nocCategory",props.data?.nocCategory?
    [{ categoryName: props.data.nocCategory, Id: props.data.nocCategoryId }]:[])
    setValue("parent",props.data.parent?[
      {
        Id: props.data.parentId,
        soc: props.data.parent,
      },
    ]
  : []) 
   
  }, [props.data]);

 

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const payload = {
        Id: props.data.Id,
        srno: data.srno,
        socCategoryId: props.socCategoryId,
        soc: data.name,
        parent: data.parent.length!==0 ? data.parent[0].soc : null,
        parentId: data.parent.length!=0 ? data.parent[0].Id : null,
        nocCategory: data.nocCategory[0].categoryName,
        nocCategoryId: data.nocCategory[0].Id,
      //  sequence: data.sequence,
        customer:customer_id,
      }
      console.log("payloadalnsd", payload)
    
      update_soc(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("SOC  updated successfully !", {
            autoClose: 3000, theme: "light",
          });
          props.toggle()
          props.fetch_SOC_lists();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
       show={props.modal}
        onHide={() => props.toggle()}
        size="lg"
        backdrop="static"
      >
        <Modal.Header  className="bg-primary-blue" closeButton>
        <Modal.Title > {"Update SOC"}</Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Sr. No"}</label>
              <input
                className={
                  errors.srno ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`srno`}
                {...register(`srno`)}
                defaultValue={props.data?.srno}
                placeholder="Enter Sr.No*"
              />

              <span className="text-danger">{errors.srno?.message}</span>
            </div>
            {/* <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Sequence"}</label>
              <input
                className={
                  errors.sequence ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`sequence`}
                defaultValue={props.data?.sequence}
                {...register(`sequence`)}
                placeholder="Sequence*"
              />

              <span className="text-danger">{errors.sequence?.message}</span>
            </div> */}
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"SOC  Name"}</label>
              {/* <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                defaultValue={props.data?.soc}
                placeholder="Name*"
              /> */}
               <textarea
                 className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                defaultValue={props.data?.soc}
                placeholder="Enter SOC Name*"
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.name?.message}</span>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Select Parent"}</label>
              <Controller
                name="parent"
                control={control}
                defaultValue={
                  props.data.parent !== null
                    ? [
                        {
                          Id: props.data.parentId,
                          soc: props.data.parent,
                        },
                      ]
                    : []
                }
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead"
                    labelKey="soc"
                    multiple={false}
                    options={props.soc_list.filter((item)=>item.Id!==props.data.Id)}
                    // loading={state.loading}
                    emptyLabel={
                      [].length > 0
                        ? "No matches found."
                        : "No Parents to select"
                    }
                    placeholder="Select Parent"
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultSelected={
                      props.data.parent !== null
                        ? [
                            {
                              Id: props.data.parentId,
                              soc: props.data.parent,
                            },
                          ]
                        : []
                    }
                  />
                )}
              />
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"NOC Category"}</label>
              <Controller
                name="nocCategory"
                control={control}
                defaultValue={[{ categoryName: props.data.nocCategory, Id: props.data.nocCategoryId }]}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead"
                    labelKey="categoryName"
                    multiple={false}
                    options={props.noc_list}
                    // loading={noc_category_list.loading}
                    placeholder="Select NOC Category"
                    onChange={onChange}
                    onBlur={onBlur}
                    // selected={value}
                    defaultSelected={[{ categoryName: props.data.nocCategory, Id: props.data.nocCategoryId }]}
                    isInvalid={errors.nocCategory ? true : false}
                  />
                )}
              />
               <span className="text-danger">{errors.nocCategory?.message}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default Edit_SOC_Master;
