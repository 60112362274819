import {
    Home,
    Box,
    DollarSign,
    UserPlus,
    Users,
    Chrome,
    Settings,
    Airplay,
    FolderPlus,
    File,
    Command, Cloud, Book, FileText, Server, Image, Sliders, Map, GitPullRequest, Calendar, Edit, Mail, MessageSquare, UserCheck, Layers, HelpCircle, Database, Headphones, Mic, ShoppingBag, Search, AlertOctagon, Lock, Briefcase, BarChart, Target, List, Package
} from 'react-feather';

export const SUPER_ADMIN_MENUITEMS = [
    {
        title: 'Org Management', type: 'sub', icon: Briefcase, active: false, children: [
            { path: '/admin/vdi_customer', title: 'Organisation', type: 'link' },
            { path: '/admin/vdi_customersubscription', title: 'Organisation Subscription', type: 'link' },
        ]
    },
    {
        title: 'Subscription Management', icon: Settings, path: '/admin/vdi_subscriptionplans', type: 'link', active: false
    },
    {
        title: 'Global Survey Definition', icon: Settings, path: '/admin/clonechecklist', type: 'link', active: false
    },
    {
        title: 'User Adminstration', type: 'sub', icon: Briefcase, active: false, children: [
            { path: '/admin/vdi_usermaster', title: 'Users', type: 'link' },
            { path: '/admin/vdi_customerAdminUsers', title: 'Org Users', type: 'link' },
        ]
    },
    // {
    //     title: 'Mail Error Logs', icon: Settings, path: '/admin/mailerrorlogs', type: 'link', active: false
    // },
    // {
    //     title: 'Access Error Logs', icon: Settings, path: '/admin/accesserrorlogs', type: 'link', active: false
    // },

]
