// import React ,{useEffect , Fragment} from 'react';
// import Breadcrumb from '../../common/breadcrumb';
// import { DollarSign, Tag, ShoppingBag, MessageCircle, MinusCircle, ThumbsUp, MessageSquare, Briefcase, MoreHorizontal, Send, Activity, Anchor, Compass, Cpu, Slack, Umbrella, Box, Book } from 'react-feather';
// import { calcultionOptions, calcultionData } from '../../../data/default'
// import ChartistGraph from 'react-chartist';
// import EventCharts from './eventCharts';
// import configDB from '../../../data/customizer/config';
// import {New,NewSale,NewMessage,NewVisits,TotalProfit,AllCustomIncome,All,TotalInvestment,TotalReview,CustomerReview,Change,Online,MarshiKisteen,Dashboard,Ui,Xi,Message,Portfolio,NewUser,Month,Today,NickStone,Follow,WiltorNoice,NewReport,TotalFeedback,MilanoEsco,AnnaStrong,RecentNotification,Order,Download, Trash,ByKan,ByKaint,ByTailer,ByWaiter,ByComman,Calculation,TotalIncome,TotalLoss,Conversations,View,Media,Search,SellingUpdate,Shipping,Purchase,TotalSell,Feedback,ByCall,Activitys} from '../../../constant'

// var Knob = require('knob')// browserify require
// var primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;

// const Default = () => {

//     useEffect(() => {
//         var profit = Knob({
//             value: 35,
//             left: 1,
//             angleOffset: 90,
//             className: "review",
//             thickness: 0.2,
//             width: 270,
//             height: 270,
//             fgColor: primary,
//             readOnly: false,
//             dynamicDraw: false,
//             tickColorizeValues: true,
//             bgColor: '#f6f7fb',
//             lineCap: 'round',
//             displayPrevious:true
//         })
//         document.getElementById('profit').appendChild(profit);
//     },[]);
//     return (
//         <Fragment>
//             <Breadcrumb   parent = "Dashboard" title = "Default" />
//             <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-xl-8 xl-100">
//                             <div className="row">
//                                 <EventCharts />
//                             </div>
//                         </div>
//                         <div className="col-xl-4 xl-100">
//                             <div className="card">
//                                 <div className="card-header">
//                                     <h5>{Activitys}</h5>
//                                 </div>
//                                 <div className="card-body activity-scroll">
//                                     <div className="activity">
//                                         <div className="d-flex">
//                                             <div className="flex-shrink-0 gradient-round m-r-30 gradient-line-1">
//                                                 <ShoppingBag />
//                                             </div>
//                                             <div className="flex-grow-1">
//                                                 <h6>{NewSale} <span className="pull-right f-14">{New}</span></h6>
//                                                 <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry."}</p>
//                                             </div>
//                                         </div>
//                                         <div className="d-flex">
//                                             <div className="flex-shrink-0 gradient-round m-r-30 gradient-line-1">
//                                                 <MessageCircle />
//                                             </div>
//                                             <div className="flex-grow-1">
//                                                 <h6>{NewMessage} <span className="pull-right f-14">{"14m Ago"}</span></h6>
//                                                 <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry."}</p>
//                                             </div>
//                                         </div>
//                                         <div className="d-flex">
//                                             <div className="flex-shrink-0 gradient-round m-r-30 small-line">
//                                                 <MinusCircle />
//                                             </div>
//                                             <div className="flex-grow-1">
//                                                 <h6>{NewReport} <span className="pull-right f-14">{"14m Ago"}</span></h6>
//                                                 <p className="activity-xl">{"Lorem Ipsum is simply dummy text."}</p>
//                                             </div>
//                                         </div>
//                                         <div className="d-flex">
//                                             <div className="flex-shrink-0 gradient-round m-r-30 gradient-line-1">
//                                                 <ShoppingBag />
//                                             </div>
//                                             <div className="flex-grow-1">
//                                                 <h6>{NewSale} <span className="pull-right f-14">{"14m Ago"}</span></h6>
//                                                 <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry."}</p>
//                                             </div>
//                                         </div>
//                                         <div className="d-flex">
//                                             <div className="flex-shrink-0 gradient-round m-r-30 medium-line">
//                                                 <Tag />
//                                             </div>
//                                             <div className="flex-grow-1">
//                                                 <h6>{NewVisits} <span className="pull-right f-14">{"14m Ago"}</span></h6>
//                                                 <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry."}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xl-4 col-md-6 xl-50">
//                             <div className="card">
//                                 <div className="card-header">
//                                     <h5>{TotalProfit}</h5><span className="d-block fonts-dashboard">{AllCustomIncome}</span>
//                                 </div>
//                                 <div className="card-body">
//                                     <div className="knob-block text-center">
//                                         <div className="knob" id="profit">
//                                         </div>
//                                     </div>
//                                     <div className="show-value d-flex">
//                                         <div className="value-left">
//                                             <div className="square bg-primary d-inline-block"></div><span>{TotalProfit}</span>
//                                         </div>
//                                         <div className="value-right">
//                                             <div className="square bg-light d-inline-block"></div><span>{TotalInvestment}</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xl-4 xl-50 col-md-6">
//                             <div className="row">
//                                 <div className="col-md-12">
//                                     <div className="card">
//                                         <div className="card-body progress-media">
//                                             <div className="d-flex">
//                                                 <div className="flex-grow-1">
//                                                     <h5>{TotalReview}</h5><span className="mb-0 d-block">{CustomerReview}</span>
//                                                 </div>
//                                                 <ThumbsUp />
//                                             </div>
//                                             <div className="progress-bar-showcase">
//                                                 <div className="progress sm-progress-bar">
//                                                     <div className="progress-bar bg-primary" role="progressbar" style={{ width: "90%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
//                                                 </div>
//                                             </div>
//                                             <div className="progress-change"><span>{Change}</span><span className="pull-right">{"95%"}</span></div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-12">
//                                     <div className="card">
//                                         <div className="card-body progress-media">
//                                             <div className="d-flex">
//                                                 <div className="flex-grow-1">
//                                                     <h5>{TotalFeedback}</h5><span className="mb-0 d-block">{Feedback}</span>
//                                                 </div>
//                                                 <MessageCircle />
//                                             </div>
//                                             <div className="progress-bar-showcase">
//                                                 <div className="progress sm-progress-bar">
//                                                     <div className="progress-bar bg-primary" role="progressbar" style={{ width: "85%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
//                                                 </div>
//                                             </div>
//                                             <div className="progress-change"><span>{Change}</span><span className="pull-right">{"85%"}</span></div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xl-4 xl-100 col-md-12">
//                             <div className="card user-card">
//                                 <div className="card-body">
//                                     <div className="online-user">
//                                         <h5 className="font-primary f-18 mb-0">{Online}</h5>
//                                     </div>
//                                     <div className="user-card-image">
//                                         <img className="img-fluid rounded-circle image-radius" src={require('../../../assets/images/dashboard/designer.jpg')} alt="" />
//                                     </div>
//                                     <div className="user-deatils text-center">
//                                         <h5>{MarshiKisteen}</h5>
//                                         <h6 className="mb-0">{"marshikisteen@gmail.com"}</h6>
//                                     </div>
//                                     <div className="user-badge text-center">
//                                         <a className="badge rounded-pill badge-light" href="#javascript">{Dashboard}</a>
//                                         <a className="badge rounded-pill badge-light" href="#javascript">{Ui}</a>
//                                         <a className="badge rounded-pill badge-light" href="#javascript">{Xi}</a>
//                                         <a href="#javascript">
//                                             <span className="badge rounded-pill badge-light active">{"2+"}</span>
//                                         </a>
//                                     </div>
//                                     <div className="card-footer row pb-0 text-center">
//                                         <div className="col-6">
//                                             <div className="d-flex user-footer">
//                                                 <MessageSquare className="m-r-10" />
//                                                 <h6 className="f-18 mb-0">{Message}</h6>
//                                             </div>
//                                         </div>
//                                         <div className="col-6">
//                                             <div className="d-flex user-footer">
//                                                 <Briefcase className="m-r-10" />
//                                                 <h6 className="f-18 mb-0">{Portfolio}</h6>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xl-6">
//                             <div className="card height-equal" style={{minHeight: '507px'}}>
//                                 <div className="card-header card-header-border">
//                                     <div className="row">
//                                         <div className="col-sm-6">
//                                             <h5>{NewUser}</h5>
//                                         </div>
//                                         <div className="col-sm-6">
//                                             <div className="pull-right right-header"><span>{Month}</span><span>
//                                                 <button className="btn btn-primary btn-pill">{Today}</button></span></div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="card-body">
//                                     <div className="new-users">
//                                         <div className="d-flex align-items-start">
//                                             <img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/2.png')} alt="" />
//                                             <div className="flex-grow-1">
//                                                 <h6 className="mb-0 f-w-700">{NickStone}</h6>
//                                                 <p>{"Visual Designer, Github Inc"}</p>
//                                             </div><span className="pull-right">
//                                                 <button className="btn btn-pill btn-outline-light">{Follow}</button></span>
//                                         </div>
//                                         <div className="d-flex align-items-start">
//                                             <img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/5.jpg')} alt="" />
//                                             <div className="flex-grow-1">
//                                                 <h6 className="mb-0 f-w-700">{MilanoEsco}</h6>
//                                                 <p>{"Visual Designer, Github Inc"}</p>
//                                             </div><span className="pull-right">
//                                                 <button className="btn btn-pill btn-outline-light">{Follow}</button></span>
//                                         </div>
//                                         <div className="d-flex align-items-start"><img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/3.jpg')} alt="" />
//                                             <div className="flex-grow-1">
//                                                 <h6 className="mb-0 f-w-700">{WiltorNoice}</h6>
//                                                 <p>{"Visual Designer, Github Inc"}</p>
//                                             </div><span className="pull-right">
//                                                 <button className="btn btn-pill btn-outline-light">{Follow}</button></span>
//                                         </div>
//                                         <div className="d-flex align-items-start mb-0"><img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/4.jpg')} alt="" />
//                                             <div className="flex-grow-1">
//                                                 <h6 className="mb-0 f-w-700">{AnnaStrong}</h6>
//                                                 <p>{"Visual Designer, Github Inc"}</p>
//                                             </div><span className="pull-right">
//                                                 <button className="btn btn-pill btn-outline-light">{Follow}</button></span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xl-6">
//                             <div className="card height-equal">
//                                 <div className="card-header card-header-border">
//                                     <div className="row">
//                                         <div className="col-sm-7">
//                                             <h5>{RecentNotification}</h5>
//                                         </div>
//                                         <div className="col-sm-5">
//                                             <div className="pull-right right-header">
//                                                 <div className="onhover-dropdown">
//                                                     <button className="btn btn-primary btn-pill" type="button">{All} <span className="pe-0"><i className="fa fa-angle-down"></i></span></button>
//                                                     <div className="onhover-show-div right-header-dropdown"><a className="d-block" href="#javascript">{Order}</a><a className="d-block" href="#javascript">{Download}</a><a className="d-block" href="#javascript">{Trash}</a></div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="card-body recent-notification">
//                                     <div className="d-flex">
//                                         <h6>{"09:00"}</h6>
//                                         <div className="flex-grow-1"><span>{"Lorem ipsum dolor sit amit,consectetur eiusmdd."}</span>
//                                             <p className="f-12">{ByKan}</p>
//                                         </div>
//                                     </div>
//                                     <div className="d-flex">
//                                         <h6>{"10:50"}</h6>
//                                         <div className="flex-grow-1"><span>{"Lorem ipsum."}</span>
//                                             <p className="f-12">{ByTailer}</p>
//                                         </div>
//                                     </div>
//                                     <div className="d-flex">
//                                         <h6>{"01:00"}</h6>
//                                         <div className="flex-grow-1"><span>{"Lorem ipsum dolor sit amit,consectetur eiusmdd."}</span>
//                                             <p className="f-12">{ByKaint}</p>
//                                         </div>
//                                     </div>
//                                     <div className="d-flex">
//                                         <h6>{"05:00"}</h6>
//                                         <div className="flex-grow-1"><span>{"Lorem ipsum dolor sit amit."}</span>
//                                             <p className="f-12">{ByCall}</p>
//                                         </div>
//                                     </div>
//                                     <div className="d-flex">
//                                         <h6>{"12:00"}</h6>
//                                         <div className="flex-grow-1"><span>{"Lorem ipsum dolor sit."}</span>
//                                             <p className="f-12">{ByWaiter}</p>
//                                         </div>
//                                     </div>
//                                     <div className="d-flex">
//                                         <h6>{"08:20"}</h6>
//                                         <div className="flex-grow-1"><span>{"Lorem ipsum dolor sit amit,consectetur eiusmdd dolor sit amit."}</span>
//                                             <p className="f-12">{ByComman}</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-sm-12">
//                             <div className="card">
//                                 <div className="card-header">
//                                     <h5>{Calculation}</h5>
//                                 </div>
//                                 <div className="card-body">
//                                     <div className="show-value-top d-flex">
//                                         <div className="value-left d-inline-block">
//                                             <div className="square bg-primary d-inline-block"></div><span>{TotalIncome}</span>
//                                         </div>
//                                         <div className="value-right d-inline-block">
//                                             <div className="square d-inline-block bg-smooth-chart"></div><span>{TotalLoss}</span>
//                                         </div>
//                                     </div>
//                                     <div className="ct-svg">
//                                         <ChartistGraph data={calcultionData}
//                                          type={'Line'} listener={{
//                                             "created": function (data) {
//                                                 var defs = data.svg.elem('defs');
//                                                     defs.elem('linearGradient', {
//                                                         id: 'gradient1',
//                                                         x1: 0,
//                                                         y1: 0,
//                                                         x2: 1,
//                                                         y2: 1
//                                                     }).elem('stop', {
//                                                         offset: 0,
//                                                         'stop-color': primary
//                                                     }).parent().elem('stop', {
//                                                         offset: 1,
//                                                         'stop-color': primary
//                                                     });
//                                             }
//                                         }} options={calcultionOptions} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xl-7 xl-100">
//                             <div className="card">
//                                 <div className="card-header card-header-border chat-header-default">
//                                     <div className="row">
//                                         <div className="col-sm-6">
//                                             <h5>{Conversations}</h5>
//                                         </div>
//                                         <div className="col-sm-6">
//                                             <div className="right-header pull-right m-t-5">
//                                                 <div className="onhover-dropdown">
//                                                     <MoreHorizontal />
//                                                     <div className="onhover-show-div right-header-dropdown more-dropdown">
//                                                         <a className="d-block" href="#javascript">{View}</a>
//                                                         <a className="d-block" href="#javascript">{Media}</a>
//                                                         <a className="d-block" href="#javascript">{Search}</a>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="card-body chat-box dashboard-chat">
//                                     <div className="chat">
//                                         <div className="d-flex left-side-chat align-items-start">
//                                             <img className="rounded-circle chat-user-img img-60 m-r-20" src={require('../../../assets/images/user/4.jpg')} alt="" />
//                                             <div className="flex-grow-1">
//                                                 <div className="message-main">
//                                                     <p className="mb-0">{"Lorem Ipsum is simply dummy text of the"} </p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="d-flex right-side-chat align-items-start">
//                                             <div className="flex-grow-1 text-end">
//                                                 <div className="message-main">
//                                                     <p className="pull-right">{"Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum has been the industry's"}</p>
//                                                     <div className="clearfix"></div>
//                                                 </div>
//                                                 <div className="sub-message message-main">
//                                                     <p className="pull-right mb-0">{"Lorem Ipsum is simply dummy text of the"} </p>
//                                                     <div className="clearfix"></div>
//                                                 </div>
//                                             </div>
//                                             <img className="rounded-circle chat-user-img img-60 m-l-20" src={require('../../../assets/images/user/5.jpg')} alt="" />
//                                         </div>
//                                         <div className="d-flex left-side-chat align-items-start">
//                                             <img className="rounded-circle chat-user-img img-60 m-r-20" src={require('../../../assets/images/user/4.jpg')} alt="" />
//                                             <div className="flex-grow-1">
//                                                 <div className="message-main">
//                                                     <p>{"Lorem Ipsum is simply dummy text of the printing"}</p>
//                                                 </div>
//                                                 <div className="sub-message message-main smiley-bg"><img src={require('../../../assets/images/dashboard/smily.png')} alt="" /></div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="d-flex chat-footer bg-primary">
//                                     <i className="icon-face-smile"></i>
//                                     <div className="flex-grow-1">
//                                         <input className="form-control" type="text" placeholder="Type your message" required="" />
//                                     </div>
//                                     <Send />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xl-5 xl-100">
//                             <div className="card">
//                                 <div className="card-header card-header-border">
//                                     <div className="row">
//                                         <div className="col-sm-6">
//                                             <h5 className="align-abjust">{SellingUpdate}</h5>
//                                         </div>
//                                         <div className="col-sm-6">
//                                             <div className="pull-right right-header">
//                                                 <div className="onhover-dropdown">
//                                                     <button className="btn btn-primary btn-pill" type="button">{All} <span className="pe-0"><i className="fa fa-angle-down"></i></span></button>
//                                                     <div className="onhover-show-div right-header-dropdown"><a className="d-block" href="#javascript">{Shipping}</a><a className="d-block" href="#javascript">{Purchase}</a><a className="d-block" href="#javascript">{TotalSell}</a></div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="card-body">
//                                     <div className="row">
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center">
//                                                 <Activity />
//                                                 <h5 className="mb-0 f-18">{"+500"}</h5>
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center">
//                                                 <Anchor />
//                                                 <h5 className="mb-0 f-18">{"+120"}</h5>
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center">
//                                                 <Box />
//                                                 <h5 className="mb-0 f-18">{"-410"}</h5>
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center">
//                                                 <Book />
//                                                 <h5 className="mb-0 f-18">{"-155"}</h5>
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center">
//                                                 <Compass />
//                                                 <h5 className="mb-0 f-18">{"+920"}</h5>
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center">
//                                                 <Cpu />
//                                                 <h5 className="mb-0 f-18">{"+500"}</h5>
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center mb-0 xs-mb-selling">
//                                                 <DollarSign />
//                                                 <h5 className="mb-0 f-18">{"+500"}</h5>
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center mb-0 xs-mb-selling">
//                                                 <Slack />
//                                                 <h5 className="mb-0 f-18">{"+120"}</h5>
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-4">
//                                             <div className="selling-update text-center mb-0">
//                                                 <Umbrella />
//                                                 <h5 className="mb-0 f-18">{"-410"}</h5>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//         </Fragment>
//     );
// };

// export default Default;

// Divyansh code

import React, { useEffect, Fragment, useState } from "react";
// import Breadcrumb from "../../common/breadcrumb";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { Search } from "react-feather";
import EventCharts from "./eventCharts";
import configDB from "../../../data/customizer/config";
import ship from "../../../assets/images/dashboard/ship.png";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/loader";
import { useSelector, useDispatch } from "react-redux";
import { WATCH_LOGIN_LIST } from "../../../redux/actionTypes";
import DataTable from "react-data-table-component";
import db from '../../../data/config';
import { collection, query, where, getDocs } from "firebase/firestore";
import { secondsToTime, convertDateYYMMDD, calMaxPage } from "../../ProjectComponents/Common/Functions/CommonFunctions";
import firebase from 'firebase/compat/app';
import { getVesselByUser } from '../../../api/index';
import { updateCustomerVideoCallMins } from '../../ProjectComponents/Common/Functions/CommonFunctions';
import { Data } from "@react-google-maps/api";

var Knob = require("knob"); // browserify require
var primary =
  localStorage.getItem("primary_color") || configDB.data.color.primary_color;

const localData = JSON.parse(localStorage.getItem("login_data"));

const Default = () => {
  const appData = useSelector((content) => content.LoginApp?.login);
  // const [appDatsa, setappData] = useState([])
  const [loader, setLoader] = useState(true);
  const [search, setsearched] = useState("");

  const [tiles, setTiles] = useState([]);
  const [filter, setfilter] = useState([]);
  const [columnList, setColumnList] = useState();
  const [vesselData, setVesselData] = useState({ data: [], loading: false });
  const [totalRecords, setTotalRecords] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [data,setData]=useState([])

  const dispatch = useDispatch();
  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Unique No",
      selector: (row) => row?.number === null ? "NA" : row?.number,
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type.name,
      sortable: true,
      center: true,
    },
    {
      name: "GT",
      selector: (row) => row?.GT,
      sortable: true,
      center: true,
    },
    {
      name: "Survey Count",
      selector: (row) => row?.SurveyCount,
      sortable: true,
      center: true,
    },
  ];


  const handlePageChange = (newPage) => {
         console.log("new",newPage)
    let startIndex = (newPage - 1) * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;
   let  pageData = data.slice(startIndex, endIndex);
    setPage(newPage);
    setVesselData({ ...vesselData, data: pageData, loading: false });

  };


  useEffect(async () => {

    const reqPayload = {
      customer_id: localData.customer_id,
      user_id: localData.user
    }

    getVesselByUser(reqPayload).then((res) => {
      console.log('res', res);
      setVesselData({ ...vesselData, data: [], loading: true });

      if (res.data.length > 0) {
        console.log("res.data", res.data);
            setData(res.data)
        let startIndex = (page - 1) * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;
   let  pageData = res.data.slice(startIndex, endIndex);
        setVesselData({ ...vesselData, data: pageData, loading: false });
        setMaxPage(calMaxPage(res.data.length));
        setTotalRecords(res.data.length)
      } else {
        console.log("asdasas");
        setVesselData({ ...vesselData, data: [], loading: false });

      }
    }, (err) => {

      console.log('err', err);
    })

    setColumnList(columns);

    // dispatch({ type: WATCH_LOGIN_LIST });
    var currDate = new Date();
    currDate.setHours(0, 0, 0, 0);
    console.log("currDate", currDate);
    // console.log("currDate.getTime()", currDate.getTime());

    const q = query(collection(db, "Call"),
      where("source", "==", "web"),
      where("totalTime", "==", null),
      where("customerId", "==", localData.customer_id),
      where("callLeft", "==", false),
      // where('startTime', '<', currDate.getTime())
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      const data = doc.data();

      var seconds = (data.callInProgressTimeStamp.toDate().getTime() - data.startTime.toDate().getTime()) / 1000;
      const tTime = secondsToTime(Math.round(seconds));
      console.log("tTime", tTime);


      if (data.startTime.toDate().getTime() < currDate.getTime()) {

        db.collection("Call").doc(doc.id).update({
          totalSeconds: Math.round(seconds),
          totalTime: tTime,
          callInProgress: false,
          callJoined: false,
          callLeft: true,
        });

        updateCustomerVideoCallMins(data.startTime.toDate(), Math.round(seconds));
      }


    });
  }, []);


  const filterData = (data) => {
    console.log("data", data);
    const initial = {};
    if (data.length !== 0) {

      data.forEach((item) => {
        if (item.vessel.name in initial) {
          initial[item.vessel.name] = initial[item.vessel.name] + 1;
        } else {
          initial[item.vessel.name] = 1;
        }
      });
    }
    console.log("Object.entries(initial)", Object.entries(initial));
    setTiles(Object.entries(initial));
    // setLoader(false);
    // if (appData?.length) {
    //   setLoader(false);
    // }
  };


  // useEffect(() => {
  //   filterData(appData);
  // }, [appData]);

  const navigate = useNavigate();

  const handleSurvey = (data) => {
    console.log('data', data);
    navigate(`${process.env.PUBLIC_URL}/dashboard/default/surveyList2/na/${data.Id}`);
  };

  function searchHandle(e) {
    setsearched(e.target.value);
    let a = e.target.value

    if (e.target.value == "") {
      setfilter(vesselData.data);
    } else {
      const f = vesselData.data.filter((el) => {
        return el.name.toLowerCase().includes(a.toLowerCase());
      });
      setfilter(f);
    }
  }

  const handleSearch = (query) => {
    console.log("data",data)
    console.log("query",query)
    let da=data.filter((el)=>{
      console.log("data",el)
     return el.name.toLowerCase().includes(query.toLowerCase())

    });
    setVesselData({ ...vesselData, data: da, loading: false });

 


  };
  

  



  // const columns = [
  //   {
  //     name: "Name",
  //     selector: (row) => {
  //       return row[0];
  //     },
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: "Unique No",
  //     selector: (row) => {
  //       let a = appData?.filter((el) => {
  //         return row[0] == el?.vessel?.name;
  //       });
  //       return a[0]?.vessel.number;
  //     },
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: "Type",
  //     selector: (row) => {
  //       let a = appData?.filter((el) => {
  //         return row[0] == el?.vessel?.name;
  //       });
  //       return a[0].vessel.type.name;
  //     },
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: "GT",
  //     selector: (row) => {
  //       let a = appData?.filter((el) => {
  //         return row[0] == el?.vessel?.name;
  //       });
  //       return a[0].vessel.GT;
  //     },
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: "Survey Count",
  //     selector: (row) => {
  //       return row[1];
  //     },
  //     sortable: true,
  //     center: true,
  //   },
  // ];

  const customStyles = {

    rows: {
      style: {
        color: "#000",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "roboto",
      },
    },
    headCells: {
      style: {
        color: "white",
        background: "#2264AB",
        // fontSize: "18px",
        fontWeight: "400",
        fontFamily: "roboto",
      },
    },
    cells: {
      style: {
        color: "#000",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "roboto",
      },
    },
  };
  return (
    <Fragment>
      <Loader loader={loader} />
      <div className="container-fluid" style={{ fontFamily: "roboto" }}>
        <div className="row">
          <div className="col-xl-12 xl-100">
            <div className="card mt-2 overflow-hidden">
              <div
                className="card-header"
                style={{ background: "#2264AB", padding: "12px 24px" }}
              >
                <span className="text-light" style={{ fontSize: "24px" }}>
                  Dashboard
                </span>
              </div>
              <div className="card-body">
                <h2 style={{ color: "#2264AB" }}>Survey Summary</h2>
                <div className="row d-flex flex-wrap mt-2 justify-content-between">
                  <EventCharts />
                </div>
                <div className="row mb-3">

                  <div className="col-md-3 col-sm-12  ">
                    <div className="faq-form">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search Vessels"
                        value={search}
                        onChange={(e)=>{
                          setsearched(e.target.value)
                          handleSearch(e.target.value)}}
                      />
                      <Search className="search-icon" />
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-9 col-sm-0 "></div>
                </div>
                <h3 style={{ color: "#2264AB" }}>Vessels</h3>
                <div className="row justify-content-between flex-wrap">
                  {vesselData.loading === true ?
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                    :
                    vesselData.data.length > 0 ?
                      // <DataTable
                      //   columns={columnList}
                      //   data={search ? filter : vesselData.data}
                      //   striped={true}
                      //   center={true}
                      //   persistTableHead
                      //   highlightOnHover
                      //   pagination
                      //   customStyles={customStyles}
                      //   onRowClicked={(data) => {
                      //     handleSurvey(data);
                      //   }}
                      // />



                      <div className="table-responsive " style={{ maxHeight: "70vh" }}>
                        <table className="table table-striped table-bordered">
                          <thead className="thead-light">
                            <tr
                              className="f-18"
                              style={{
                                position: "sticky",
                                top: "0",
                                background: "white",
                              }}
                            >
                              <th scope="col">{"Name"}</th>
                              <th scope="col">{"IMO No."}</th>
                              <th scope="col">{"Type"}</th>
                              <th scope="col">{"GT"}</th>
                              <th scope="col">{"Survey Count"}</th>

                            </tr>
                          </thead>
                          <tbody>
                            {vesselData.data.map((item) => {
                              return <tr className='cursor_pointer' key={item.Id} onClick={()=>handleSurvey(item)} >
                                <td>{item.name}</td>
                                <td>{item.number?item.number:"NA"}</td>
                                <td>{item.type?.name}</td>
                                <td>{item.GT}</td>
                                <td>{item.SurveyCount}</td>
                                
                              </tr>
                            })}


                          </tbody>
                        </table>
                      </div>
                      :

                      <div>
                        <p className="text-center text-danger f-18 f-w-600 mb-0">No survey assigned yet !</p>
                      </div>

                  }

                 


                  {vesselData.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() => {
                               setPage((prev)=>prev-1)
                              handlePageChange(page-1)
                            }}
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            page === maxPage || maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() => {
                              setPage((prev)=>prev+1)
                              handlePageChange(page+1)

                            }}
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                    
                      <div>
                        <div className="d-flex justify-content-end">
                          <p className="f-12 text-muted mt-1">{`${page} / ${maxPage} pages`}</p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                        </div>
                      </div>
                    </div>
                  )}




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Default;
