import React, { useState, useEffect, useReducer } from "react";
import "../../Common/Css/Common.css";
import NewSubscriptionForm from "./NewSubscriptionForm";
import {
  getSubscriptions,
  deleteSubscription,
  updateSubscription,
} from "../../../../api/index";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Trash2, Edit } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import EditSubscription from "./EditSubscription";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";

function SubscriptionManagement() {
  const [modal8, setModal8] = useState();
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [totalRecords, setTotalRecords] = useState("");

  const user = localStorage.getItem("user");

  const initialState = {
    loading: false,
    error: "",
    subscriptionList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          subscriptionList: action.subscriptionList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      subscriptionList: state.subscriptionList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    FetchSubscription();
  }, [state.page, state.itemsPerPage]);

  // approve function
  function approveCustomer(val) {
    updateSubscription({ Id: val.Id, status: "Approved" }).then(
      (res) => {
        console.log("res", res);

        toast.success("Subscription approved successfully !", {
          autoClose: 3000,
          theme: "light",
        });
        FetchSubscription();
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function FetchSubscription(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      subscriptionList: state.subscriptionList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
    };

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
      };
    }

    getSubscriptions(reqPayload).then(
      (res) => {
        console.log("res", res);
        // console.log('calMaxPage(res.data.count)', calMaxPage(res.data.count));
        setTotalRecords(res.data.count);

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          subscriptionList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  // Deleting customer
  const delSubscription = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteSubscription({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Subscription deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            setTimeout(() => {
              FetchSubscription();
            }, 200);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <EditSubscription
        modal8={modal8}
        toggle8={toggle8}
        data={dataForUpdation}
        FetchSubscription={FetchSubscription}
      />

      <div className="vdi_subscriptionplans py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  <p className="mb-0 f-24">Subscription Management</p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-end mb-4">
                    <NewSubscriptionForm
                      FetchSubscription={FetchSubscription}
                    />
                  </div>

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.subscriptionList.length > 0 ? (
                    <div
                      className="table-responsive "
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                          <tr
                            className="f-18"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            <th scope="col">{"PlanId"}</th>
                            <th scope="col">{"Name"}</th>
                            <th scope="col">{"No.OfInspections"}</th>
                            <th scope="col">{"CallMinutes"}</th>
                            <th scope="col">{"No.OfUsers"}</th>
                            <th scope="col">{"No.OfVessels"}</th>
                            <th scope="col">{"MonthlySubscription"}</th>
                            <th scope="col">{"AnnualSubscription"}</th>
                            <th scope="col">{"Currency"}</th>
                            <th scope="col">{"PerSurvey"}</th>
                            <th scope="col">{"PerMinute"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.subscriptionList.map((val, i) => (
                            <tr key={i} className="f-16">
                              <th scope="row">{val.Id}</th>
                              <td>{val.name}</td>
                              <td>{val.inspectionsCount}</td>
                              <td>{val.callMinutes}</td>
                              <td>{val.userCount}</td>
                              <td>{val.vesselCount}</td>
                              <td>{val.monthlySubscription}</td>
                              <td>{val.annualSubscription}</td>
                              <td>
                                {val.currency !== null
                                  ? val.currency.currency
                                  : "NA"}
                              </td>
                              <td>{val.perSurvey}</td>
                              <td>{val.perMinute}</td>

                              <td>
                                <Edit
                                  onClick={() => {
                                    toggle8();
                                    setDataForUpdation(val);
                                  }}
                                  id={"editToolTip" + i}
                                  className="cursor_pointer "
                                  height={"18px"}
                                  width={"18px"}
                                />

                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"editToolTip" + i}
                                >
                                  {"Edit Record"}
                                </UncontrolledTooltip>

                                <Trash2
                                  onClick={() => delSubscription(val)}
                                  id={"deleteToolTip" + i}
                                  className="cursor_pointer ml-1"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"deleteToolTip" + i}
                                >
                                  {"Delete Record"}
                                </UncontrolledTooltip>

                                {user === "superadmin_approver" ? (
                                  val.status === "Pending" ? (
                                    <button
                                      onClick={() => approveCustomer(val)}
                                      className="btn btn-pill btn-xs btn-primary-blue"
                                    >
                                      Approve
                                    </button>
                                  ) : null
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Subscription Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Subscription list is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() =>
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                subscriptionList: state.subscriptionList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              })
                            }
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() =>
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                subscriptionList: state.subscriptionList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              })
                            }
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>

                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SubscriptionManagement;
