import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

function EditTable2() {
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    console.log("location", location);

    const parser = new DOMParser();
    const tableElement = parser
      .parseFromString(location.state.data.file.table, "text/html")
      .querySelector("table");

    const tableContainer = document.getElementById("table-container");
    tableContainer.appendChild(tableElement);
    // tableContainer.innerHTML = tableHtml;

    // const cells = document.getElementsByTagName("input");
    // const a = cells[0].textContent;
    // console.log(cells);
    // console.log(a);
  }, []);

  // let location = useParams();
  return (
    <React.Fragment>
      <div className="fontFamily formTable py-4">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div
                className="title bg-primary-blue d-flex justify-content-between"
                id="card_title"
              >
                <p className="mb-0 f-24">
                  {" "}
                  <span
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="text-light me-2"
                    style={{ fontSize: "20px", cursor: "pointer" }}
                  >
                    <i className="fa fa-arrow-circle-left"></i>
                  </span>
                  Edit Table
                </p>
              </div>
              <div className="card-body">
                <div id="table-container"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditTable2;
