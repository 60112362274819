import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { updateCheckList } from '../../../../api/index';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
    SrNo: yup.string()
        .required('Required !'),
    section: yup.string()
        .required('Required !'),
    comments: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    photos: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    videos: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    OG: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),

}).required();

function EditChecklistItem(props) {

    const { register, handleSubmit, formState, control, reset, getValues } = useForm({
        resolver: yupResolver(schema), mode: "onChange",
    });

    let { errors } = formState;
    const customer_id = localStorage.getItem('customer_id');


    useEffect(() => {
        console.log("props", props.itemUpdateData);
        console.log("props", props);
        reset()
    }, [props.itemUpdateData]);


    const onSubmit = data => {

        if (data !== '') {
            console.log("data", data);

            const reqPayload = {
                Id: props.checkListdata.checklist.Id,
                checklist: {
                    name: props.getValues("name"),
                    description: props.getValues("description"),
                    customer_id: customer_id
                },
                checklistLineItems: [
                    data
                ]
            }

            console.log("reqPayload", reqPayload);

            updateCheckList(reqPayload).then((res) => {
                console.log("res", res);

                toast.success("Checklist Updated Successfully !", { autoClose: 2000,theme:'light' });
                props.toggle8();
                props.fetchCheckListData();

            }, (err) => {
                console.log("err", err);
                toast.error("Something went wrong, please try again !", { autoClose: 3000,theme:'light' })
            });

        } else {
            errors.showMessages();
        }
    };


    return (
        <React.Fragment>
            <Modal isOpen={props.modal8} toggle={props.toggle8} size="lg" backdrop='static'>
                <ModalHeader toggle={props.toggle8} className='bg-primary-blue'>{"Update Checklist Item"}</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-12 col-md-2 mb-2'>
                            <label className='f-w-600 f-14'>{"Sr.No"}</label>
                            <input
                                className={errors.SrNo ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                name={`SrNo`}
                                {...register(`SrNo`)}
                                placeholder="SrNo*"
                                defaultValue={props.itemUpdateData.data.SrNo}
                            />

                            <span className='text-danger'>{errors.SrNo?.message}</span>
                        </div>

                        <input
                            className='d-none'
                            type="text"
                            name='Id'
                            {...register('Id')}
                            defaultValue={props.itemUpdateData.data.Id}
                        />

                        <div className='col-12 col-md-10 mb-2'>
                            <label className='f-w-600 f-14'>{"Section"}</label>
                            <textarea
                                className={errors.section ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                {...register(`section`)}
                                name={`section`}
                                placeholder="Enter section*"
                                rows='2'
                                defaultValue={props.itemUpdateData.data.section}
                            ></textarea>

                            <span className='text-danger'>{errors.section?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Comments"}</label>

                            <select
                                {...register(`comments`)}
                                name={`comments`}
                                defaultValue={props.itemUpdateData.data.comments}
                                className={errors.comments ? 'form-control is-invalid' : 'form-control'}>
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Yes"}</option>
                                <option value="No">{"No"}</option>
                            </select>

                            <span className='text-danger'>{errors.comments?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Photos"}</label>

                            <select
                                {...register(`photos`)}
                                name={`photos`}
                                defaultValue={props.itemUpdateData.data.photos}
                                className={errors.photos ? 'form-control is-invalid' : 'form-control'}>
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Yes"}</option>
                                <option value="No">{"No"}</option>
                            </select>

                            <span className='text-danger'>{errors.photos?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Videos"}</label>

                            <select
                                {...register(`videos`)}
                                name={`videos`}
                                defaultValue={props.itemUpdateData.data.videos}
                                className={errors.videos ? 'form-control is-invalid' : 'form-control'}>
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Yes"}</option>
                                <option value="No">{"No"}</option>
                            </select>

                            <span className='text-danger'>{errors.videos?.message}</span>

                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"OG"}</label>

                            <select
                                {...register(`OG`)}
                                name={`OG`}
                                defaultValue={props.itemUpdateData.data.OG}
                                className={errors.OG ? 'form-control is-invalid' : 'form-control'}>
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Yes"}</option>
                                <option value="No">{"No"}</option>
                            </select>

                            <span className='text-danger'>{errors.OG?.message}</span>
                        </div>



                    </div>
                </ModalBody>

                <ModalFooter>
                    <button className='btn btn-primary-blue' onClick={handleSubmit(onSubmit)}>{"Submit"}</button>
                    <button className='btn btn-dark' onClick={props.toggle8}>{"Cancel"}</button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default EditChecklistItem