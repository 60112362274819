import React, { useRef, useEffect } from "react";
import { BackgroundColor } from "../../../../../../constant";
// import db from "../../../../../../utils/firebase";
import { Paperclip, Send } from 'react-feather';
import db from '../../../../../../data/config';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { formatAMPM } from '../../../../../ProjectComponents/Common/Functions/CommonFunctions';
import { getStorage, uploadBytes, ref as abcd, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState } from "react";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";

function ChatUI(props) {
    const inputFile = useRef(null);
    // Create a root reference
    const guestUserdata = JSON.parse(localStorage.getItem("guestUserData"));
    const GuestVideoCall = JSON.parse(localStorage.getItem("GuestVideoCall"));

    const emailAddress = JSON.parse(localStorage.getItem("login_data"));
    const [chattingUser, setChattingUser] = useState();


    var checkOpen = false;
    const [inputvalue, setInputvalue] = React.useState("");
    const [data, setData] = React.useState([]);

    const { id } = useParams();

    const ref = db.collection('notifications');

    const inputRef = React.useRef(null);

    React.useEffect(() => {
        checkOpen = true;
        console.log("db", db);
        console.log("ref", ref);

        console.log("checkOpen", checkOpen);
        if (checkOpen) {
            getData();
            updateMsgAsSeen();
        }

        console.log("GuestVideoCall", GuestVideoCall);


        if (GuestVideoCall === true) {
            console.log("guestUserdata.email", guestUserdata.email);
            setChattingUser(guestUserdata.email);
        } else {
            setChattingUser(emailAddress.emailId);
        }


        return () => (checkOpen = false)
    }, []);

    useEffect(() => {
        var objDiv = document.getElementById("msg_list");
        objDiv.scrollTop = objDiv.scrollHeight;
    }, [data]);



    async function getData() {
        // console.log(parseInt(GuestVideoCall === true ? guestUserdata.SIDGUEST : id));
        getAuth();

        if (GuestVideoCall) {
            console.log("guestUserdata.SIDGUEST",);
            await ref.where('surveyId', '==', parseInt(guestUserdata.SIDGUEST))
                .orderBy("time")
                .onSnapshot((querySnapshot) => {
                    const items = [];
                    querySnapshot.forEach((doc) => {
                        items.push(doc.data());

                        // console.log("doc.data()", doc.data());
                    })

                    console.log("items", items);
                    setData(items);
                })
        } else {
            console.log("id", id);
            await ref.where('surveyId', '==', parseInt(id))
                .orderBy("time")
                .onSnapshot((querySnapshot) => {
                    const items = [];
                    querySnapshot.forEach((doc) => {
                        items.push(doc.data());

                        // console.log("doc.data()", doc.data());
                    })

                    console.log("items", items);
                    setData(items);
                })
        }


    }


    async function updateMsgAsSeen() {
        getAuth();

        console.log("GuestVideoCall", GuestVideoCall);
        if (GuestVideoCall) {
            console.log();
            await ref.where('surveyId', '==', parseInt(guestUserdata.SIDGUEST))
                .where('from', '!=', guestUserdata.email)
                .onSnapshot(async (querySnapshot) => {
                    console.log("checkOpen", checkOpen);

                    if (checkOpen) {
                        await querySnapshot.forEach((doc) => {
                            console.log("doc", doc);
                            const msgdata = doc.data();
                            const items = [];
                            console.log("msgdata", msgdata);
                            for (let i = 0; i < msgdata.seenBy.length; i++) {
                                // console.log("msgdata", msgdata);
                                items.push(msgdata.seenBy[i]);

                            }
                            // console.log("items", items);

                            if (items.includes(guestUserdata.email) === false) {
                                // console.log("inside iff doo nothinggggg");
                                console.log("========updating seenBy===============");
                                items.push(guestUserdata.email);
                                console.log("items", items);
                                db.collection("notifications").doc(doc.id).update({
                                    seenBy: items
                                });
                            }
                        })
                    }
                })
        }
        else {
            console.log(parseInt(id));
            await ref.where('surveyId', '==', parseInt(id))
                .where('from', '!=', emailAddress?.emailId)
                .onSnapshot((querySnapshot) => {
                    console.log("checkOpen", checkOpen);

                    if (checkOpen) {
                        querySnapshot.forEach((doc) => {
                            console.log("doc", doc);
                            const msgdata = doc.data();
                            const items = [];
                            console.log("msgdata", msgdata);
                            for (let i = 0; i < msgdata.seenBy.length; i++) {
                                // console.log("msgdata", msgdata);
                                items.push(msgdata.seenBy[i]);

                            }
                            // console.log("items", items);

                            if (items.includes(emailAddress?.emailId)) {
                                console.log("inside iff doo nothinggggg");
                            } else {
                                console.log("========updating seenBy===============");
                                items.push(emailAddress?.emailId);
                                console.log("items", items);
                                db.collection("notifications").doc(doc.id).update({
                                    seenBy: items
                                });
                            }
                        })
                    }
                })
        }
    }

    function sendData(message, imgurl) {
        

        if (GuestVideoCall) {
            const ourData = {
                from: guestUserdata.email,
                // from: "siddharth.k@technicious.in",
                message: message,
                surveyId: parseInt(guestUserdata.SIDGUEST),
                time: firebase.firestore.FieldValue.serverTimestamp(),
                to: "common",
                name: guestUserdata.name,
                image_url: imgurl,
                seenBy: [guestUserdata.email],
            };

            ref
                .doc()
                .set(ourData)
                .catch((err) => {
                    console.log("err", err);
                });
            setInputvalue("");
        } else {
            const ourData = {
                from: emailAddress?.emailId,
                // from: "siddharth.k@technicious.in",
                message: message,
                surveyId: parseInt(id),
                time: firebase.firestore.FieldValue.serverTimestamp(),
                to: "common",
                name: emailAddress.FirstName,
                image_url: imgurl,
                seenBy: [emailAddress?.emailId],
            };

            ref
                .doc()
                .set(ourData)
                .catch((err) => {
                    console.log("err", err);
                });
            setInputvalue("");
        }


    }




    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };


    function sendFileInChat(e) {
        const currentTimeStamp = Date.now();
        console.log("e", e.target.files[0]);

         if(!e.target.files[0].type.startsWith("image")){
            
            toast.error("Only images are allowed !",{
                autoClose:1000
            })
            return
         }
        const storage = getStorage();
        const storageRef = abcd(storage, currentTimeStamp.toString());
        // uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        //   console.log("snapshot", snapshot);
        //   console.log('Uploaded a blob or file!');
        // });

        const uploadTask = uploadBytesResumable(storageRef, e.target.files[0], {
            contentType: 'image/jpeg',
        });
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                //your code...
                console.log("snapshot", snapshot);
            },
            (error) => {
                console.log(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log(downloadURL);
                    sendData(null, downloadURL);
                });
            }
        );
        // console.log("storageRef", storageRef.);

    }

    return (
        <React.Fragment>
            <div className='chat_screen light_grey_border shadow-sm'>
                <div className='chat_screen_inside'>


                    <div className="msg_list" id="msg_list">
                        {data.map((val, i) => (
                            <div
                                className={val.from == chattingUser ?
                                    'd-flex justify-content-end' : "d-flex"} key={i}>
                                <div
                                    className={"px-2 pt-2 pb-1 mb-2 shadow-sm light_grey_border"}
                                    style={{
                                        maxWidth: "300px",
                                        width: 'auto', position: 'relative',
                                        background: '#fff', wordBreak: 'break-all', borderRadius: '8px'
                                    }}>

                                    {val.message === null ?
                                        <img className="cursor_pointer"
                                            src={val.image_url} onClick={() => window.open(val.image_url, '_blank')}
                                            style={{ height: '100px', width: '100px', borderRadius: '8px' }} />
                                        :
                                        <>
                                            <div>
                                                <p className="mb-0" id="actualMsgInChat">{val.message}</p>
                                            </div>
                                        </>
                                    }
                                    <div id="nameinchat">
                                        <p className=" f-10 mb-0 mt-1" style={{ color: 'darkgrey' }}>{"~" + val.name}</p>
                                    </div>

                                    {/* <p className="text-muted f-10 mb-0">
                                        {formatAMPM(new Date(val.time.seconds * 1000))}</p> */}
                                </div>

                            </div>
                        ))}

                    </div>

                    <div className='chat_controls d-flex justify-content-between p-2 light_grey_border'>
                        <input placeholder="Type here..."
                            ref={inputRef}
                            className="form-control"
                            onChange={(e) => setInputvalue(e.target.value)}
                            value={inputvalue}
                            onKeyUp={(e) => e.key === "Enter" && sendData(inputvalue, null)}
                        />

                        <button
                            disabled={inputvalue ? false : true}
                            className="shadow-sm ms-2"
                            id="chatSendBtn"
                            onClick={() => sendData(inputvalue, null)}
                        >
                            <Send
                                style={{ color: 'white' }}
                                id={"send"}
                                className='cursor_pointer'
                                height={'18px'}
                                width={'18px'} />
                        </button>

                        <input type='file' id='file' accept="image/*" className="p-0 m-0" ref={inputFile} onChange={(e) => sendFileInChat(e)} style={{ display: 'none' }} />

                        <button
                            className="shadow-sm ms-2"
                            id="shareFileChatBtn"
                            onClick={() => { onButtonClick(); }}
                        >
                            <Paperclip
                                style={{ color: 'white' }}
                                id={"viewattachments"}
                                className='cursor_pointer'
                                height={'18px'}
                                width={'18px'} />
                        </button>
                    </div>


                </div>
            </div>
        </React.Fragment>
    )
}

export default ChatUI