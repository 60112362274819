import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import {
  updateCustomerSubscription,
  getSubscriptions,
} from "../../../../api/index";
import { convertDateYYMMDD } from "../../Common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";

const schema = yup
  .object()
  .shape({
    // status: yup.string().nullable().required('Status is required !'),
    subscriptionPlan: yup
      .array()
      .nullable()
      .required("Subscription plan is required !"),
    startDate: yup.string().nullable().required("Start date is required !"),
    endDate: yup.string().nullable().required("End date is required !"),
  })
  .required();

function EditCustSubscriptionForm(props) {
  const [subscriptionList, setSubscriptionList] = useState({
    data: [],
    loading: false,
  });

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    console.log("props", props);

    if (props.modal8 === true) {
      reset();

      getSubscriptions({ pageNo: 1, entriesPerPage: 10000 }).then(
        (res) => {
          console.log("res", res);

          if (res.data.res.length !== 0) {
            const approvedList = res.data.res.filter((val) => {
              return val.status !== "Pending";
            });

            console.log(approvedList);

            setSubscriptionList({
              ...subscriptionList,
              data: approvedList,
              loading: false,
            });
          } else {
            setSubscriptionList({
              ...subscriptionList,
              data: [],
              loading: false,
            });
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  }, [props.modal8]);

  // submitting form values
  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const reqPayload = {
        Id: props.data?.Id,
        startDate: convertDateYYMMDD(data.startDate, "-"),
        endDate: convertDateYYMMDD(data.endDate, "-"),
        plan: data.subscriptionPlan[0].Id,
      };

      console.log("reqPayload", reqPayload);
      if (
        new Date(data.endDate).getTime() < new Date(data.startDate).getTime()
      ) {
        console.log("sd is less");

        SweetAlert.fire({
          title: "Please select a valid end date !",
          text: "Click ok to proceed!",
          icon: "error",
        });
      } else {
        updateCustomerSubscription(reqPayload).then(
          (res) => {
            console.log("res", res);
            toast.success("Organisation subscription updated successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            props.toggle8();

            setTimeout(() => {
              props.fetchCustSubscription();
            }, 200);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Modal
        isOpen={props.modal8}
        toggle={props.toggle8}
        size="lg"
        backdrop="static"
      >
        <ModalHeader className="bg-primary-blue" toggle={props.toggle8}>
          <p className="f-22 f-w-400 mb-0">
            {"Update Organisation Subscription"}
          </p>
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Subscription Plan"}</label>
                  <Controller
                    name="subscriptionPlan"
                    control={control}
                    defaultValue={[
                      { name: props.data?.plan.name, Id: props.data?.plan.Id },
                    ]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        options={subscriptionList.data}
                        isLoading={subscriptionList.loading}
                        multiple={false}
                        placeholder="--Search*--"
                        // defaultSelected={[{ name: props.data?.plan.name }]}
                        defaultValue={[
                          {
                            name: props.data?.plan.name,
                            Id: props.data?.plan.Id,
                          },
                        ]}
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.subscriptionPlan ? true : false}
                        // disabled={true}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.subscriptionPlan?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Start Date"}</label>

                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={
                      props.data ? new Date(props.data.startDate) : ""
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        defaultValue={
                          props.data ? new Date(props.data.startDate) : ""
                        }
                        className={
                          errors.startDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={onChange}
                        selected={value}
                        minDate={new Date(1901, 1, 1)}
                        // maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  {/* <Controller
                                        control={control}
                                        name="startDate"
                                        defaultValue={props.data ? new Date(props.data.startDate) : ''}

                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                className={errors.startDate ? "form-control is-invalid" : "form-control"}
                                                placeholderText="Select date"
                                                onChange={onChange}
                                                selected={value}
                                                minDate={new Date(1901, 1, 1)}
                                                // maxDate={new Date()}
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        )}
                                    /> */}

                  <span className="text-danger">
                    {errors.startDate?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"End Date"}</label>

                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={
                      props.data ? new Date(props.data.endDate) : ""
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        defaultValue={
                          props.data ? new Date(props.data.endDate) : ""
                        }
                        className={
                          errors.endDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={onChange}
                        selected={value}
                        minDate={new Date(1901, 1, 1)}
                        // maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  {/* <Controller
                                        control={control}
                                        name="endDate"
                                        defaultValue={props.data ? new Date(props.data.endDate) : ''}

                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                className={errors.endDate ? "form-control is-invalid" : "form-control"}
                                                placeholderText="Select date"
                                                onChange={onChange}
                                                selected={value}
                                                minDate={new Date(1901, 1, 1)}
                                                // maxDate={new Date()}
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        )}
                                    /> */}

                  <span className="text-danger">{errors.endDate?.message}</span>
                </div>
              </div>

              {/* <div className='col-12 col-md-6'>
                                <div className="form-group mb-2">
                                    <label className='f-w-600'>{"Status"}</label>

                                    <Controller
                                        control={control}
                                        name="status"
                                        defaultValue={props.data?.status === true ? "Active" : "Inactive"}

                                        render={({ onChange, value }) => (
                                            <select
                                                onChange={onChange}
                                                selected={value}
                                                defaultValue={props.data?.status === true ? "Active" : "Inactive"}
                                                className={errors.status ? "custom-select form-control is-invalid" : "custom-select form-control"}>

                                                <option select="">{"--Select--"}</option>
                                                <option defaultValue="Active">{"Active"}</option>
                                                <option defaultValue="Inactive">{"Inactive"}</option>
                                            </select>
                                        )}
                                    />

                                    <span className='text-danger'>{errors.status?.message}</span>
                                </div>
                            </div> */}
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-right"
          >
            {"Update Subscription"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default EditCustSubscriptionForm;
