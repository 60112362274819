import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { loginGuestUser } from '../../../../api/index';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import '../Css/Common.css';
import { validateCallMinsWithSubscriptionPlan } from '../../Common/Functions/CommonFunctions';
import { apexLineWithAnnotationCharts } from '../../../../data/apexData';
import { collection, query, where, getDocs } from "firebase/firestore";
import db from '../../../../data/config';
import { getAuth, createUserWithEmailAndPassword, setPersistence, signInWithEmailAndPassword } from "firebase/auth";


const schema = yup
    .object()
    .shape({
        name: yup.string().required("Name is required !"),
        email: yup.string().required("Email is required !"),
        code: yup.string().required("Passcode is required !"),
    })
    .required();

function GuestUserAuthForm(props) {
    const { id, sid, cid, sd, ed, cm } = useParams();

    const localData = JSON.parse(localStorage.getItem("login_data"));
    const subsData = JSON.parse(localStorage.getItem("subscription"));
    const [authErr, setAuthErr] = useState('');

    const [err, setErr] = useState("");
    const [planErr, setPlanErr] = useState("");
    const { register, handleSubmit, control, formState, watch, reset, setValue } =
        useForm({
            resolver: yupResolver(schema),
            mode: "onChange",
        }); // initialise the hook

    let { errors } = formState;

    useEffect(() => {
        console.log("cid", window.atob(cid));
        console.log("sd", window.atob(sd));
        console.log("ed", window.atob(ed));
        console.log("cm", window.atob(cm));


    }, []);


    const onSubmit = async (data) => {
        const auth = getAuth();


        if (data !== "") {
            console.log("data", data);
            console.log("id", id);

            var formData = new FormData();
            formData.append("username", id);
            formData.append("email", data.email);
            formData.append("code", data.code);

            console.log("***************************************");

            const planStartYear = new Date(window.atob(sd)).getFullYear();
            const planEndYear = new Date(window.atob(ed)).getFullYear();


            const currDate = new Date();
            console.log("sd", window.atob(sd));
            console.log("ed", window.atob(ed));
            const planStartDate = new Date(new Date(window.atob(sd)));
            const planEndDate = new Date(new Date(window.atob(ed)));

            console.log("currDate", currDate);
            console.log("planStartDate", planStartDate);
            console.log("planEndDate", planEndDate);
            console.log("currDate >= planStartDate", currDate >= planStartDate);
            console.log("currDate <= planEndDate", currDate <= planEndDate);

            if (window.atob(sd) === 'na' || window.atob(ed) === 'na') {

                loginGuestUser(formData).then((res) => {
                    console.log("res", res);

                    const guestUserData = {
                        username: id,
                        name: data.name,
                        email: res.data.email,
                        SIDGUEST: res.data.channelName
                    }
                    localStorage.setItem("guestUserData", JSON.stringify(guestUserData));

                    createUserWithEmailAndPassword(auth, res.data.email, res.data.email)
                        .then((userCredential) => {

                            const user = userCredential.user;

                            console.log("user", user);
                            localStorage.setItem("firebase_user_UID", user.uid);
                            props.setIsAuth(true);


                        })
                        .catch(async (error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;

                            console.log("errorCode", errorCode);
                            console.log("errorMessage", errorMessage);

                            if (errorCode == "auth/weak-password") {
                                setAuthErr('Password too weak, change your password !');
                                console.log("weak password error firebase");
                                return
                            } else if (errorCode == "auth/email-already-in-use") {
                                setAuthErr('');

                                await signInWithEmailAndPassword(auth, res.data.email, res.data.email)
                                    .then((userCredential) => {

                                        const user = userCredential.user;

                                        console.log("user", user);
                                        props.setIsAuth(true);

                                    })
                                    .catch((error) => {
                                        const errorCode = error.code;
                                        const errorMessage = error.message;

                                        console.log("errorCode", errorCode);
                                        console.log("errorMessage", errorMessage);


                                    });

                            }

                        });



                    setErr("");
                    setPlanErr("");
                }, (err) => {

                    console.log("err", err);

                    if (err.response.status === 401) {

                        setErr("Email Or Passcode Is Invalid !");
                    } else {

                        setErr("Something Went Wrong ! Please check email, passcode & try again.");
                    }
                });
                return true

            } else {
                if (currDate >= planStartDate === true && currDate <= planEndDate === true) {
                    getAuth();
                    console.log("window.atob(cid)", window.atob(cid));
                    console.log("planStartYear", planStartYear);
                    console.log("planEndYear", planEndYear);

                    setErr("");
                    setPlanErr("");

                    loginGuestUser(formData).then((res) => {
                        console.log("res", res);

                        const guestUserData = {
                            username: id,
                            name: data.name,
                            email: res.data.email,
                            SIDGUEST: res.data.channelName
                        }
                        localStorage.setItem("guestUserData", JSON.stringify(guestUserData));
                        // props.setIsAuth(true);

                        createUserWithEmailAndPassword(auth, res.data.email, res.data.email)
                            .then((userCredential) => {

                                const user = userCredential.user;

                                console.log("user", user);
                                localStorage.setItem("firebase_user_UID", user.uid);



                            })
                            .catch(async (error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;

                                console.log("errorCode", errorCode);
                                console.log("errorMessage", errorMessage);

                                if (errorCode == "auth/weak-password") {
                                    setAuthErr('Password too weak, change your password !');
                                    console.log("weak password error firebase");
                                    return
                                } else if (errorCode == "auth/email-already-in-use") {
                                    setAuthErr('');

                                    await signInWithEmailAndPassword(auth, res.data.email, res.data.email)
                                        .then((userCredential) => {

                                            const user = userCredential.user;

                                            console.log("user", user);


                                            db.collection('CustomerVideoCallMins')

                                                .where('customerId', '==', parseInt(window.atob(cid)))
                                                // .where("year", "==", planStartYear, planEndYear)
                                                .onSnapshot((querySnapshot) => {
                                                    const items = [];
                                                    var totalsecs = 0;
                                                    querySnapshot.forEach((doc) => {
                                                        if (doc.data().year == planStartYear || doc.data().year == planEndYear) {
                                                            items.push(doc.data());
                                                            const data = doc.data();
                                                            console.log("data", data);
                                                            totalsecs = totalsecs + data.totalSeconds;
                                                        }
                                                    })

                                                    console.log("items", items);
                                                    console.log("totalsecs", totalsecs);
                                                    console.log("totalsecs", Math.floor(totalsecs / 60));

                                                    console.log(window.atob(cm));


                                                    if (Math.floor(totalsecs / 60) < parseInt(window.atob(cm))) {
                                                        console.log("if true");

                                                        props.setIsAuth(true);
                                                        return true
                                                    } else {

                                                        console.log("else false");
                                                        setPlanErr("Your plan is exhausted, please contact admin!");
                                                        props.setIsAuth(false);
                                                        return false
                                                    }
                                                })


                                        })
                                        .catch((error) => {
                                            const errorCode = error.code;
                                            const errorMessage = error.message;

                                            console.log("errorCode", errorCode);
                                            console.log("errorMessage", errorMessage);

                                        });

                                }

                            });



                    }, (err) => {

                        console.log("err", err);

                        if (err.response.status === 401) {

                            setErr("Email Or Passcode Is Invalid !");
                        } else {

                            setErr("Something Went Wrong ! Please check email, passcode & try again.");
                        }
                    });





                } else {
                    console.log("outer else false");
                    setPlanErr("Your plan is exhausted, please contact admin!");
                    return false
                }
            }


        }
    }

    return (
        <React.Fragment>
            <div className='auth_form' style={{ position: "relative", height: '100vh' }}>
                <div className='container-fluid'>

                    <div className='light_grey_border shadow auth_form_card' id='auth_form_card'
                    >
                        <div className='px-4 py-3' style={{ background: "#2264ab", borderRadius: '8px', }}>
                            <p className='f-22 mb-0' style={{ color: "white" }}>Enter Your Credentials For Joining the Video Call</p>
                        </div>
                        <div className='row p-4'>


                            <div className='col-12'>
                                <div className='form-group'>
                                    <label className='f-w-600 f-14'>Name</label>
                                    <input
                                        {...register("name")}
                                        className={errors.name ? "form-control is-invalid" : "form-control"}
                                        type="text"
                                        placeholder='Enter your name'
                                        name='name'

                                    />

                                    <span className="text-danger">{errors.name?.message}</span>
                                </div>
                            </div>

                            <div className='col-12 mt-2'>
                                <div className='form-group'>
                                    <label className='f-w-600 f-14'>Email</label>
                                    <input
                                        {...register("email")}
                                        className={errors.email ? "form-control is-invalid" : "form-control"}
                                        type="text"
                                        placeholder='Enter your email'
                                        name='email'
                                    />

                                    <span className="text-danger">{errors.email?.message}</span>
                                </div>
                            </div>


                            <div className='col-12 mt-2'>
                                <div className='form-group'>
                                    <label className='f-w-600 f-14'>Passcode</label>
                                    <input
                                        {...register("code")}
                                        className={errors.code ? "form-control is-invalid" : "form-control"}
                                        type="password"
                                        placeholder='Enter your passcode'
                                        name='code'
                                    />

                                    <p className='text-muted'>Enter passcode received in your email inbox.</p>
                                    <span className="text-danger">{errors.code?.message}</span>
                                </div>
                            </div>
                            {err.length > 0 ?
                                <p className='mb-0 text-danger'>{err}</p>
                                : null}

                            {planErr.length > 0 ?
                                <p className='mb-0 text-danger'>{planErr}</p>
                                : null}

                            <div className='col-12'>
                                <div className='form-group mt-4 d-flex justify-content-end'>
                                    <button className='btn btn-primary-blue' onClick={handleSubmit(onSubmit)}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GuestUserAuthForm