import React, { useEffect } from 'react';

 import ReactDOM from 'react-dom';
import './index.scss';
import './i18n';
import { auth0 } from './data/config';
import { configureFakeBackend } from "./services/fack.backend";
import {
    Routes,
    Route,
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import { Auth0Provider } from '@auth0/auth0-react'
import MainRoutes from './routes';
import { createBrowserHistory as createHistory } from 'history';
import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';
// import app  from './utils/firebase';
// import OneSignal from 'react-onesignal';

Sentry.init({
    // dsn:"",
     dsn: "https://5eee6a28509c48f5a709c2f1f76b490a@o513526.ingest.sentry.io/4505367253090304",
    integrations: [new Sentry.BrowserTracing({
         // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
      
    }),new Sentry.Replay(),],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment:'development',
});

// setup fake backend

configureFakeBackend();


const Root = () => {
    // const prevLocation = useLocation().pathname;

    const history = createHistory();



    useEffect(() => {
        // console.log("firebase", firebase);



        const unlisten = history.listen((location) => {
            console.log("location", location);
            console.log("window.location.pathname", window.location.pathname);


            const urlToBlock = localStorage.getItem('urlToBlock');

            if (urlToBlock !== null) {

                if (location.pathname !== urlToBlock) {
                    // history.push(prevLocation)
                    console.log("location.pathname", location.pathname);
                    // const temp = prompt('Are you sure want to exit this page');
                    // console.log("temp", temp);
                    // if (temp === null) {
                    //     console.log('urlToBlock', urlToBlock);
                    // } else {
                    //     localStorage.removeItem('urlToBlock');
                    // }

                    // if (myUrl === `${process.env.PUBLIC_URL}/customeradmin/vdi_survey_details`) {
                    //Do something, like display confirmation dialog!
                    // }
                }
            }
        });


        return () => {
            unlisten()
        }
    }, [])

    function generatePassword() {
        var length = 16,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }




    return (
        <div className="App">
            {/* <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}> */}
            <Provider store={store}>
                <BrowserRouter history={history} getUserConfirmation={() => {/* Empty callback to block the default browser prompt */ }}>

                    <MainRoutes />
                </BrowserRouter>
            </Provider>
            {/* </Auth0Provider> */}
        </div>
    );
}



// const container = document.getElementById("root");
// const root = createRoot(container);
// root.render(<Root />);

 ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();