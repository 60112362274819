import React, { useState, useEffect, useReducer } from "react";
import "../../Common/Css/Common.css";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import CreateNewCustSubscription from "./CreateNewCustSubscription";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Trash2, Edit } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import {
  getCustomerSubscriptions,
  deleteCustomerSubscriptions,
} from "../../../../api/index";
import {
  convertDate,
  convertDateYYMMDD,
  calMaxPage,
} from "../../Common/Functions/CommonFunctions";
import EditCustSubscriptionForm from "./EditCustSubscriptionForm";

function CustomerSubscription() {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [searchVal, setSearchVal] = useState("");

  const [totalRecords, setTotalRecords] = useState("");
  const [dataForUpdation, setDataForUpdation] = useState();
  const [apiBySearch, setApiBySearch] = useState(false);
  const [modal8, setModal8] = useState();

  const handleChange = (date, caller) => {
    if (caller === "startDate") {
      setstartDate(date);
      setApiBySearch(true);
      fetchCustSubscription("startDate", date);
    } else {
      setendDate(date);
      setApiBySearch(true);
      fetchCustSubscription("endDate", date);
    }
  };

  const initialState = {
    loading: false,
    error: "",
    customerList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          customerList: action.customerList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      customerList: state.customerList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchCustSubscription();
  }, [state.page, state.itemsPerPage]);

  // Deleting customer
  const delCustomer = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteCustomerSubscriptions({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Organisation subscription deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            setTimeout(() => {
              fetchCustSubscription();
            }, 200);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  function fetchCustSubscription(para1, para2) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      customerList: state.customerList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
    };

    // if called by search
    if (para1 === "search") {
      console.log("startDate", startDate);

      if (startDate && !endDate) {
        reqPayload.name = para2;
        reqPayload.startDate = convertDateYYMMDD(startDate, "-");
      } else if (!startDate && endDate) {
        reqPayload.name = para2;
        reqPayload.endDate = convertDateYYMMDD(endDate, "-");
      } else if (startDate && endDate) {
        reqPayload.name = para2;
        reqPayload.startDate = convertDateYYMMDD(startDate, "-");
        reqPayload.endDate = convertDateYYMMDD(endDate, "-");
      } else if (startDate && para2) {
        reqPayload.startDate = convertDateYYMMDD(startDate, "-");
        reqPayload.name = para2;
      } else if (endDate && para2) {
        reqPayload.endDate = convertDateYYMMDD(endDate, "-");
        reqPayload.name = para2;
      } else if (startDate && endDate && para2) {
        reqPayload.startDate = convertDateYYMMDD(startDate, "-");
        reqPayload.endDate = convertDateYYMMDD(endDate, "-");
        reqPayload.name = para2;
      } else {
        if (para2.length > 0) {
          reqPayload.name = para2;
        }
      }
    } else if (para1 === "startDate") {
      if (searchVal.length > 0 && !endDate) {
        reqPayload.name = searchVal;
        reqPayload.startDate = convertDateYYMMDD(para2, "-");
      } else if (searchVal.length === 0 && endDate) {
        reqPayload.startDate = convertDateYYMMDD(para2, "-");
        reqPayload.endDate = convertDateYYMMDD(endDate, "-");
      } else if (searchVal.length === 0 && !endDate) {
        reqPayload.startDate = convertDateYYMMDD(para2, "-");
      }
    } else if (para1 === "endDate") {
      if (searchVal.length > 0 && !startDate) {
        reqPayload.name = searchVal;
        reqPayload.endDate = convertDateYYMMDD(para2, "-");
      } else if (searchVal.length === 0 && startDate) {
        reqPayload.startDate = convertDateYYMMDD(startDate, "-");
        reqPayload.endDate = convertDateYYMMDD(para2, "-");
      } else if (searchVal.length === 0 && !startDate) {
        reqPayload.endDate = convertDateYYMMDD(para2, "-");
      }
    }

    getCustomerSubscriptions(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);

        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          customerList: res.data.res,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.res.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <EditCustSubscriptionForm
        modal8={modal8}
        toggle8={toggle8}
        data={dataForUpdation}
        fetchCustSubscription={fetchCustSubscription}
      />

      <div className="vdi_customersubscription py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="title bg-primary-blue" id="card_title">
                  <p className="f-24 mb-0">Organisation Subscription</p>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-12 col-md-3">
                      <label className="f-w-600">Select Organisation</label>

                      <input
                        className="form-control"
                        type="text"
                        name="search"
                        placeholder="Search"
                        value={searchVal}
                        // checking if search value is not empty then only calling the function
                        onChange={(e) => {
                          // if (e.target.value.length > 0) {
                          fetchCustSubscription("search", e.target.value);
                          setApiBySearch(true);
                          setSearchVal(e.target.value);
                          // }
                        }}
                      />
                    </div>

                    <div className="col-12 col-md-2">
                      <label className="f-w-600">Start Date</label>

                      <DatePicker
                        className="form-control digits"
                        selected={startDate}
                        placeholderText="Select Date"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => handleChange(e, "startDate")}
                      />
                    </div>

                    <div className="col-12 col-md-2">
                      <label className="f-w-600">End Date</label>

                      <DatePicker
                        className="form-control digits"
                        selected={endDate}
                        placeholderText="Select Date"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => handleChange(e, "endDate")}
                      />
                    </div>

                    <div className="col-12 col-md-2 mt-2 mt-md-0">
                      <label className="f-w-600 invisible d-md-block d-none ">
                        Reset Filter
                      </label>
                      <button
                        onClick={() => {
                          setSearchVal("");
                          setstartDate("");
                          setendDate("");
                          fetchCustSubscription();
                        }}
                        className="btn btn-warning text-dark f-w-600 btn-sm"
                      >
                        Reset Filter
                      </button>
                    </div>

                    <div className="col-12 col-md-3">
                      <label className="f-w-600 invisible">
                        Create Subcription
                      </label>

                      <CreateNewCustSubscription
                        fetchCustSubscription={fetchCustSubscription}
                      />
                    </div>
                  </div>

                  {state.loading ? (
                    <div className="loader-box justify-content-center">
                      <div className="loader">
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                        <div className="line bg-dark"></div>
                      </div>
                    </div>
                  ) : state.customerList.length > 0 ? (
                    <div
                      className="table-responsive "
                      style={{ maxHeight: "70vh" }}
                    >
                      <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                          <tr
                            className="f-18"
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            <th scope="col">{"Id"}</th>
                            <th scope="col">{"OrgId"}</th>
                            <th scope="col">{"OrgName"}</th>
                            <th scope="col">{"SubscriptionPlan"}</th>
                            <th scope="col">{"StartDate"}</th>
                            <th scope="col">{"EndDate"}</th>
                            <th scope="col">{"Status"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.customerList.map((val, i) => (
                            <tr key={i} className="f-16">
                              <th scope="row">{val.Id}</th>
                              <td>
                                {val.customer == null ? "NA" : val.customer.Id}
                              </td>
                              <td>
                                {val.customer == null
                                  ? "NA"
                                  : val.customer.name}
                              </td>
                              <td>{val.plan.name}</td>
                              <td>{convertDate(val.startDate, "-")}</td>
                              <td>{convertDate(val.endDate, "-")}</td>
                              <td>{val.status ? "Active" : "Inactive"}</td>

                              <td>
                                <Edit
                                  onClick={() => {
                                    toggle8();
                                    setDataForUpdation(val);
                                  }}
                                  id={"editToolTip" + i}
                                  className="cursor_pointer "
                                  height={"18px"}
                                  width={"18px"}
                                />

                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"editToolTip" + i}
                                >
                                  {"Edit Record"}
                                </UncontrolledTooltip>

                                <Trash2
                                  onClick={() => delCustomer(val)}
                                  id={"deleteToolTip" + i}
                                  className="cursor_pointer ml-1"
                                  height={"18px"}
                                  width={"18px"}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"deleteToolTip" + i}
                                >
                                  {"Delete Record"}
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Subscription Found !"}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Subscription list is empty"}
                    </p>
                  )}

                  {state.loading ? null : (
                    <div className="mt-4">
                      <Pagination
                        aria-label="Page navigation"
                        className="pagination justify-content-end pagination-primary"
                      >
                        <PaginationItem
                          disabled={state.page === 1 ? true : false}
                        >
                          <PaginationLink
                            className="cursor_pointer"
                            onClick={() =>
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page - 1,
                                loading: state.loading,
                                error: state.error,
                                customerList: state.customerList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              })
                            }
                          >
                            {"Previous"}
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active>
                          <PaginationLink>{state.page}</PaginationLink>
                        </PaginationItem>

                        <PaginationItem
                          disabled={
                            state.page === state.maxPage || state.maxPage === 0
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            last
                            onClick={() =>
                              dispatch({
                                type: HANDLE_ACTION,
                                page: state.page + 1,
                                loading: state.loading,
                                error: state.error,
                                customerList: state.customerList,
                                itemsPerPage: state.itemsPerPage,
                                maxPage: state.maxPage,
                              })
                            }
                            className="cursor_pointer"
                          >
                            {"Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CustomerSubscription;
