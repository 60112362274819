import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateSOP } from "../../../../api/index";
import { toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    sequence: yup
      .number()
      .required("Please enter sr.no")
      .typeError("Please enter valid number")
      .test('is-number', 'Please enter a valid number for sr.no', value => {
        if (value === undefined || value === null) {
          return false;
        }
        return !isNaN(value);
      }),
    instruction: yup.string().required("I&G item name is required !"),
  })
  .required();

function EditSOPItems(props) {
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    console.log("props", props.itemUpdateData);
    console.log("props", props);
    reset();
  }, [props.itemUpdateData]);

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      let arr = props.SOPData?.SOPLineItems.filter((item)=>item.sequence!==props.itemUpdateData.data.sequence);
      console.log("arr",arr) 

      console.log("check")
      let filtered_array = arr.filter((item) => item.sequence == data.sequence);
      console.log("filtered_array", filtered_array)
      if (filtered_array.length > 0) {

        toast.error("This Sr.No is already present !", {
          autoClose: 2000
        })

        return
      }




      const tempMethodology = props.methodologyList.data.filter((val, i) => {
        if (val.type === props.getValues("methodology_id")) {
          return val.Id;
        }
      });

      const reqPayload = {
        Id: props.SOPData.SOP.Id,
        SOP: {
          customer_id: customer_id,
          name: props.getValues("name"),
          description: props.getValues("description"),
          selfie: props.getValues("selfie"),
          opening_Meeting: props.getValues("opening_Meeting"),
          methodology_id: tempMethodology[0].Id,
          photos: props.getValues("photos"),
          videos: props.getValues("videos"),
          OG: props.getValues("OG"),
        },
        SOPLineItems: [data],
      };

      console.log("reqPayload", reqPayload);
      updateSOP(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("I&G item updated successfully !", { autoClose: 2000 });
          props.toggle8();
          props.updateItemApiIsCalled();
          props.fetchSOPById();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal8}
        toggle={props.toggle8}
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={props.toggle8} className="bg-primary-blue">
          {"Update I&G item"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-3 mb-2 mb-md-0">
              <div className="form-group">
                <label className="f-w-600 f-14">{"Sr.No"}</label>
                <input
                  className={
                    errors.sequence
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter Sr.No*"
                  name="sequence"
                  {...register("sequence")}
                  defaultValue={props.itemUpdateData.data.sequence}
                />

                <span className="text-danger">{errors.sequence?.message}</span>
              </div>
            </div>

            <input
              className="d-none"
              type="text"
              name="Id"
              {...register("Id")}
              defaultValue={props.itemUpdateData.data.Id}
            />

            <div className="col-12 col-md-9">
              <div className="form-group">
                <label className="f-w-600 f-14">{"Instructions & Guidelines Item"}</label>
                <textarea
                  className={
                    errors.instruction
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter I&G item name*"
                  name="instruction"
                  {...register("instruction")}
                  rows="2"
                  defaultValue={props.itemUpdateData.data.instruction}
                ></textarea>

                <span className="text-danger">
                  {errors.instruction?.message}
                </span>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            {"Save Changes"}
          </button>
          <button className="btn btn-dark" onClick={props.toggle8}>
            {"Cancel"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default EditSOPItems;
