import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { version } from '../constant/index';

// import { NotificationManager } from 'components/common/react-notifications';

const BaseURL = `${process.env.REACT_APP_API_URL}${version}`
const postData = async (url, body, cred) => {
  const jwsToken = localStorage.getItem('access');
  const response = await fetch(`${BaseURL}${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      //  "Authorization":"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hYXJvZ3lhaW5kaWEuaW5cL3RyYWZpa3NvbFwvcHVibGljXC9hcGlcL3YxXC9sb2dpbiIsImlhdCI6MTU5OTEyMzA1MiwiZXhwIjoxNjAxNzE1MDUyLCJuYmYiOjE1OTkxMjMwNTIsImp0aSI6IlJQVmttMHhmcFRzZHpHaGoiLCJzdWIiOjEsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.DFp2qevRXpL5DKJNSyrryVAnGD0BWXm-GiMVQeNq5LQ",
      Authorization: cred + jwsToken,
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
    //  'content-type': 'multipart/form-data'},
    body: JSON.stringify(body),
  });
  if (response.status === 401) {
    // localStorage.clear();
    // window.location.reload(false);
  } else if (!(response.status === 200 || response.status === 201)) {
    setTimeout(() => {
      toast.error(response.status, { theme: 'light', style: { color: 'white' } });
    }, 200);
  }
  const result1 = await response.json();

  return result1;
};
const putData = async (url, body) => {
  const jwsToken = localStorage.getItem('access');
  const response = await fetch(`${BaseURL}${url}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwsToken}`,
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
    //  'content-type': 'multipart/form-data'},
    body: JSON.stringify(body),
  });
  if (response.status === 401) {
    // localStorage.clear();
    // window.location.reload(false);
  } else if (!(response.status === 200 || response.status === 201)) {
    setTimeout(() => {
      toast.error(response.status, { theme: 'light', style: { color: 'white' } });
    }, 200);
  }
  const result1 = await response.json();

  return result1;
};


const getData = async (url) => {
  const jwsToken = localStorage.getItem('access');
  const response = await fetch(`${BaseURL}${url}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwsToken}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  if (response.status === 401) {


    // localStorage.clear();
    // window.location.reload(false);
  } else if (response.status !== 200) {
    setTimeout(() => {
      toast.error(response.status, { theme: 'light', style: { color: 'white' } });
    }, 200);
  }
  const result2 = await response.json();
  return result2;
};

const getStringData = async (url) => {
  const jwsToken = localStorage.getItem('access');
  const response = await fetch(`${BaseURL}${url}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwsToken}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  if (response.status === 401) {
    // localStorage.clear();
    // window.location.reload(false);
  } else if (response.status !== 200) {
    setTimeout(() => {
      toast.error(response.status, { theme: 'light', style: { color: 'white' } });
    }, 200);
  }

  return response;
};


const deleteApi = async (url, body) => {
  const jwsToken = localStorage.getItem('access');
  const response = await fetch(`${BaseURL}${url}?Id=${body.id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwsToken}`,
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
    // body: JSON.stringify(body),
  });
  if (response.status === 401) {
    // localStorage.clear();
    // window.location.reload(false);
  } else if (response.status !== 202) {
    setTimeout(() => {
      toast.error(response.status, { theme: 'light', style: { color: 'white' } });
    }, 200);
  }

  return response;
};


const axiosPostData = async (url, formData) => {
  const jwsToken = localStorage.getItem('access')
  return axios
    .post(`${BaseURL}${url}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8',
        Authorization: `Bearer ${jwsToken}`,
      },
    })
    .then((res) => {
      // toast.success("Screenshot captured successfully", { theme: 'light', style: { color: 'white' } });
      return res;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        // localStorage.clear();
        // window.location.reload(false);
      } else if (err.response.status !== 200) {
        setTimeout(() => {
          toast.error(err.response.status, { theme: 'light', style: { color: 'white' } });
        }, 200);
      }
    });
};

const axiosPutData = async (url, formData) => {
  const jwsToken = localStorage.getItem('access')
  return axios
    .put(`${BaseURL}${url}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8',
        Authorization: `Bearer ${jwsToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        // localStorage.clear();
        // window.location.reload(false);
      } else if (err.response.status !== 200) {
        setTimeout(() => {
          toast.error(err.response.status, { theme: 'light', style: { color: 'white' } });
        }, 200);
      }
    });
};
export { postData, getData, axiosPostData, putData, axiosPutData, deleteApi, getStringData };
