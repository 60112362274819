import React from 'react';
import {
    AgoraVideoPlayer, createCustomVideoTrack
} from "agora-rtc-react";
import AgoraRTC from 'agora-rtc-sdk-ng';

function ScreenShare(props) {

    async function startCapture() {
        const videoElem = document.getElementById("video");
        try {
            videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    cursor: "always",
                    height: 1000,
                    width: 1200
                },
                audio: false
            });
            // console.log("temp", temp);
            // const screenTrack = createCustomVideoTrack({
            //     mediaStreamTrack: temp,
            // });

            const videoTrack = videoElem.srcObject.getVideoTracks()[0];
            console.log("videoTrack", videoTrack);
            const screenTrack = AgoraRTC.createCustomVideoTrack({
                mediaStreamTrack: videoTrack,
            });

            console.log("screenTrack", screenTrack);
            props.setScreenShareStreamOnFixedScreen(screenTrack);

        } catch (err) {
            // Handle error
            console.error("Error: " + err);
        }
    }

    return (
        <React.Fragment>
            <div className='d-none' style={{ width: "80%", background: "grey", margin: "auto", }}>
                <video id="video" autoPlay playsInline muted></video>
            </div>

            <button className='btn btn-primary' onClick={() => startCapture()}>Screen Share</button>

        </React.Fragment>
    )
}

export default ScreenShare