import { useEffect, useRef, useState } from "react";
import { get_nested_mla, post_mla, post_soc } from "../../../../api";
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { Trello } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

const schema = yup
    .object()
    .shape({
        name: yup
            .string()
            .required("SOC name is required !"),
        srno: yup
            .string()
            .max(30, "30 charaters allowed !")
            .matches(/^[a-zA-Z0-9]*$/, 'SR.No must be alphanumeric')
            .required("Sr.No is required"),
        //sequence: yup.string().required("sequence is required"),
        nocCategory: yup
            .array()
            .required("Please select NOC category !")
            .min(1, "Please select NOC category !"),
        // sequence: yup().string().required("Sequence is required !"),
    })
    .required();
const SOC_tree_view = (props) => {
    const [jsx, setJsx] = useState({ data: "", loading: false });
    const [content, setContent] = useState({});
    const [modal2, setModal2] = useState(false);
    const [selected_parent, setSelected_parent] = useState({})
    const [show, setShow] = useState(false);
    const [object, setObject] = useState({})
    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    let { errors } = formState;
   
    const customer_id = localStorage.getItem("customer_id");


   
    const toggle2 = () => {
        setModal2(!modal2);
       
        if (modal2 == true) {
            props.fetch_SOC_lists()
        }
    }


    useEffect(() => {
        if (modal2) {
            reset()
            // setSelected_parent({})
        }
        if (show == false) {
            reset()
        }

    }, [modal2, show])


    useEffect(() => {
        console.log(props, "props")

        reset();
        setObject(props.object)
        fetchLevOne(props.object)
    }, [props])

    const createchild = () => {
        setSelected_parent(props.object)
        setShow(true)
    }



    // Fetching MLA struture from MLA Id
    function fetchLevOne(soc) {
        setJsx({ ...jsx, data: "", loading: true })

        // fetching seletect mla here
        get_nested_mla({ customer: customer_id }).then((res) => {
            console.log("res", res)
            const result = res.data.socMaster.filter((item) => item.Id == soc.Id);
            console.log("result", result);

            const renderedHTML = renderData(result[0].SocMasterChild);

            setJsx({ ...jsx, data: renderedHTML, loading: false });



        })
            .catch((err) => {
                console.log("err", err)
            })
    }

    function renderData(data) {
        console.log("data", data);
        return (
            data.map((item, i) => (
                <>
                    <li key={new Date().getTime() + item.soc}>
                        <span className="caret" onClick={(e) => {
                            setShow(true)
                            console.log("item", item);
                            // selected_parent = item
                            setSelected_parent(item)


                            e.target.parentNode.querySelector(".nested").classList.toggle("active");
                            e.target.classList.toggle("caret-down");

                        }}>{item.soc}</span>

                        {
                            item.SocMasterChild.length > 0 ? (
                                <>
                                    <ul className="nested ms-4">
                                        {renderData(item.SocMasterChild)}
                                    </ul>
                                </>
                            ) : (
                                ""
                            )
                        }
                    </li>
                </>

            ))

        );
    }
    const onSubmit = (data) => {
        if (data !== "") {
            console.log("data", data);

            const payload = {
                srno: data.srno,
                socCategoryId: props.socCategoryId,
                soc: data.name,
                parent: selected_parent.soc,
                parentId: selected_parent.Id,
                nocCategory: data.nocCategory[0].categoryName,
                nocCategoryId: data.nocCategory[0].Id,
              //  sequence: data.sequence,
                customer: customer_id
            };

            console.log("payloadalnsd", payload);
              post_soc(payload).then(
                (res) => {
                  console.log("res", res);
                  toast.success("SOC  created successfully !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                  reset()
                  setShow(false)
                  fetchLevOne(object);
                },
                (err) => {
                  console.log("err", err);
                  toast.error(err.response.data.message, {
                    autoClose: 3000,
                    theme: "light",
                  });
                }
              );
        } else {
            errors.showMessages();
        }
    };


    return (<>
        <Trello
            onClick={() => {
                toggle2()
            }
            }
            id={"treeToolTip"}
            className="cursor_pointer ms-1"
            height={"18px"}
            width={"18px"}
        />
        <UncontrolledTooltip
            placement="bottom"
            target={"treeToolTip"}
        >
            {"Tree View"}
        </UncontrolledTooltip>
        <Modal
            isOpen={modal2}
            toggle={toggle2}
            backdrop="static"
        >
            <ModalHeader className="bg-primary-blue" toggle={toggle2}>Tree View of {props.object.soc}</ModalHeader>
            <ModalBody>
                <div className="row">

                    <div className="col-12 col-md-5">
                        <div>


                            <h6 id="parent" className="cursor_pointer" onClick={createchild}>Parent: {props.object.soc}</h6>

                            <UncontrolledTooltip
                                placement="bottom"
                                target={"parent"}
                            >
                                {"Click to create child"}
                            </UncontrolledTooltip>
                        </div>
                        <div style={{
                            overflowX: "scroll", width: '100%', minHeight: '400px',
                            height: 'auto', overflowY: 'auto'
                        }}>
                            {jsx.loading == true ?

                                <div className="loader-box justify-content-center">
                                    <div className="loader">
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                        <div className="line bg-dark"></div>
                                    </div>
                                </div>
                                : Object.keys(jsx.data).length > 0 ?
                                    <div className="mla_list"

                                    >

                                        <ul id="myUL" style={{ whiteSpace: "nowrap", }}>
                                            {jsx.data}
                                        </ul>

                                    </div>
                                    : null}
                        </div></div>
                    {show &&
                        <div className="col-12 col-md-7 shadow-sm p-2" style={{ border: '1px solid #c6c6c6', borderRadius: "8px" }}>
                            <div className="row">
                                <div className="col-12 col-md-10 mb-2">
                                    <label className="f-w-600 f-14">{"Sr. No"}</label>
                                    <input
                                        className={
                                            errors.srNo ? "form-control is-invalid" : "form-control"
                                        }
                                        type="text"
                                        name={`srno`}
                                        {...register(`srno`)}
                                        placeholder="Enter Sr.No*"
                                    />

                                    <span className="text-danger">{errors.srno?.message}</span>
                                </div>
                                {/* <div className="col-12 col-md-6 mb-2">
                                    <label className="f-w-600 f-14">{"Sequence"}</label>
                                    <input
                                        className={
                                            errors.sequence ? "form-control is-invalid" : "form-control"
                                        }
                                        type="number"
                                        name={`sequence`}
                                        {...register(`sequence`)}
                                        placeholder="Sequence*"
                                    />

                                    <span className="text-danger">{errors.sequence?.message}</span>
                                </div> */}
                                <div className="col-12 col-md-10 mb-2">
                                    <label className="f-w-600 f-14">{"SOC Name"}</label>
                                    {/* <input
                            className={
                              errors.name ? "form-control is-invalid" : "form-control"
                            }
                            type="text"
                            name={`name`}
                            {...register(`name`)}
                            placeholder="Name*"
                          /> */}
                                    <textarea
                                        className={
                                            errors.name ? "form-control is-invalid" : "form-control"
                                        }
                                        type="text"
                                        name={`name`}
                                        {...register(`name`)}
                                        placeholder="Enter SOC Name*"
                                        rows={"2"}
                                    ></textarea>

                                    <span className="text-danger">{errors.name?.message}</span>
                                </div>

                                <div className="col-12 col-md-10 mb-2">
                                    <label className="f-w-600 f-14">{"Select Parent"}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name={`parent`}
                                        value={selected_parent.soc}
                                        {...register(`parent`)}
                                        placeholder="Select Parent"
                                    />
                                </div>
                                <div className="col-12 col-md-10 mb-2">
                                    <label className="f-w-600 f-14">{"NOC Category"}</label>
                                    <Controller
                                        name="nocCategory"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead"
                                                labelKey="categoryName"
                                                multiple={false}
                                                options={props.noc_category_list.data}
                                                loading={props.noc_category_list.loading}
                                                placeholder="Select NOC Category"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                // selected={value}
                                                isInvalid={errors.nocCategory ? true : false}
                                            />
                                        )}
                                    />
                                     <span className="text-danger">{errors.nocCategory?.message}</span>
                                </div>

                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        style={{ width: "150px" }}
                                        className="btn btn-primary-blue btn-sm ms-auto mt-2"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary-blue" onClick={toggle2} >Close</button>
            </ModalFooter>
        </Modal>
    </>)
}

export default SOC_tree_view