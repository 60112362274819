import React, { useState, useEffect } from 'react';
import ship2 from '../../../../assets/images/Vedam/ship2.png';
import { getSurvey, getVesselByUser } from '../../../../api/index';
import { useNavigate } from 'react-router-dom';

function Vessels() {
    let navigate = useNavigate();

    const [data, setData] = useState({ list: [], loading: false, apiFired: false });
    const [vesselData, setVesselData] = useState({ data: [], loading: false });
    const verifier = localStorage.getItem('vdi_User_Id');
    const customer_id = localStorage.getItem('customer_id');
    const localData = JSON.parse(localStorage.getItem("login_data"));
    const tempList = [];

    useEffect(() => {
        // fetchSurveyList();

        const reqPayload = {
            customer_id: localData.customer_id,
            user_id: localData.user
        }

        getVesselByUser(reqPayload).then((res) => {
            console.log('res', res);
            setVesselData({ ...vesselData, data: [], loading: true });

            if (res.data.length > 0) {

                setVesselData({ ...vesselData, data: res.data, loading: false });
            } else {
                setVesselData({ ...vesselData, data: [], loading: false });

            }
        }, (err) => {

            console.log('err', err);
        })
    }, []);



    function fetchSurveyList() {
        setData({ ...data, list: [], loading: true, apiFired: true });

        getSurvey({ customer_id: customer_id, verifier: verifier, entriesPerPage: 10, pageNo: 1 })
            .then((res) => {
                console.log("res", res);

                if (res.data.res.length !== 0) {

                    var temp = res.data.res.map((val) => { return val.vessel.id });
                    temp = temp.filter(function (value, index, array) {
                        return array.indexOf(value) === index;
                    });

                    console.log("temp", temp);
                    temp = temp.map((val) => {
                        return { id: val, data: [], vesselDetails: {} }
                    })


                    for (let i = 0; i < res.data.res.length; i++) {
                        for (let j = 0; j < temp.length; j++) {
                            if (res.data.res[i].vessel.id === temp[j].id) {
                                temp[j].data.push(res.data.res[i]);
                                temp[j].vesselDetails = res.data.res[i].vessel
                            }
                        }
                    }

                    console.log("temp", temp);
                    setData({ ...data, list: temp, loading: false, apiFired: true });
                } else {
                    setData({ ...data, list: [], loading: false, apiFired: true });
                }




            }, (err) => {
                console.log("err", err);
            })

    }




    return (
        <React.Fragment>
            <div className='surveyor_vessels py-4'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='title bg-primary-blue' id="card_title">
                                <p className='mb-0 f-24'>Select Vessel</p>
                            </div>
                            <div className='card-body'>
                                <div className='row'>

                                    {vesselData.loading ?

                                        <div className="loader-box justify-content-center">
                                            <div className="loader">
                                                <div className="line bg-dark"></div>
                                                <div className="line bg-dark"></div>
                                                <div className="line bg-dark"></div>
                                                <div className="line bg-dark"></div>
                                            </div>
                                        </div>

                                        :
                                        vesselData.data.length > 0 ?
                                            vesselData.data.map((val, i) => (

                                                <div className='col-12 col-md-4' key={i}>
                                                    <div
                                                        onClick={() => { navigate(`${process.env.PUBLIC_URL}/verifier/surveylist/${val.Id}`, { state: val }) }}
                                                        className='card shadow-sm cursor_pointer'
                                                        style={{ border: '1px solid #c7c9c8' }}>
                                                        <div className='card-body'>
                                                            <p className='f-w-600 mb-0 f-22 text-center'>{val.name}</p>

                                                            <div className='text-center mt-4'>
                                                                <img className='' src={ship2} height='100' width='100' />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <p className='text-danger mb-0 f-22 text-center mt-4'>{"There are no vessels !"}</p>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default Vessels