import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import 'react-dropzone-uploader/dist/styles.css';
import AddChecklistItems from './AddChecklistItems';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Trash2, } from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import { createCheckList } from '../../../../api/index';
import { ToastContainer, toast } from 'react-toastify';

// yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {
//     return this.test('unique', message, function (list) {
//         console.log("list", list);
//         // console.log("list.length", list.length);
//         // console.log("new Set(list.map(mapper))", list.map(mapper));
//         // console.log("new Set(list.map(mapper))", new Set(list.map(mapper)));
//         // console.log("new Set(list.map(mapper)).size", new Set(list.map(mapper)).size);
//         // return list.length === new Set(list.map(mapper)).size;
//         // if (list.length > 1) {
//         //     for (let i = 0; i < list.length; i++) {

//         //     }
//         // }
//         const tempData = list.map((val) => { return val.SrNo });
//         for (let i = 0; i < tempData.length; i++) {
//             if (tempData.indexOf(tempData[i]) !== tempData.lastIndexOf(tempData[i])) {
//                 console.log('tempData.indexOf(tempData[i]) !== tempData.lastIndexOf(tempData[i])', tempData.indexOf(tempData[i]) !== tempData.lastIndexOf(tempData[i]))
//                 console.log("tempData.indexOf(tempData[i])", tempData.indexOf(tempData[i]));
//                 console.log("tempData.lastIndexOf(tempData[i])", tempData.lastIndexOf(tempData[i]));
//                 return list;
//             }
//         }


//     });
// });



const schema = yup.object().shape({
    name: yup.string().required('Name is required !'),
    description: yup.string().required('Description is required !'),
    checklistLineItems: yup.array().of(
        yup.object().shape({
            SrNo: yup.string()
                .required('Required !'),
            section: yup.string()
                .required('Required !'),
            comments: yup.string().nullable()
                .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
            photos: yup.string().nullable()
                .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
            videos: yup.string().nullable()
                .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
            OG: yup.string().nullable()
                .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
        })
    )

}).required();

function CreateNewCheckListForm() {


    const customer_id = localStorage.getItem("customer_id");


    const { register, handleSubmit, formState, control } = useForm({
        resolver: yupResolver(schema), mode: "onChange", defaultValues: { checklistLineItems: [{}] }
    }); // initialise the hook

    let { errors } = formState;


    const { fields, append, remove } = useFieldArray({
        control, name: 'checklistLineItems'
    });


    const onSubmit = data => {

        if (data !== '') {
            console.log("data", data);

            const reqPayload = {
                checklist: {
                    name: data.name,
                    description: data.description,
                    customer_id: customer_id
                },
                checklistLineItems: data.checklistLineItems
            }

            console.log("reqPayload", reqPayload);


            // Api call

            createCheckList(reqPayload).then((res) => {
                console.log("res", res);

                toast.success("Checklist Created Successfully !", { autoClose: 2000,theme:'light' });

                window.history.back();

                // props.CallByFetchCust("refresh");

            }, (err) => {
                console.log("err", err);
                toast.error("Something went wrong, please try again !", { autoClose: 3000,theme:'light' })
            });

        } else {
            errors.showMessages();
        }
    };

    function handleSrNo(i) {
        console.log("i", i);
    }

    return (
        <React.Fragment>


            <div className='vdi_checklist py-4'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='title bg-primary-blue' id="card_title">
                                    <p className='mb-0 f-24'>Create New Checklist</p>
                                </div>
                                <div className='card-body'>
                                    <p className='f-18 f-w-600 mb-0'>Create Checklist</p>
                                    <hr className='mb-3 mt-0' />
                                    <form className="">
                                        <div className='row'>

                                            <div className='col-12 col-md-5'>
                                                <div className="form-group mb-2">
                                                    <label className='f-w-600'>{"Name"}</label>
                                                    <input
                                                        className={errors.name ? "form-control is-invalid" : "form-control"}
                                                        type="text"
                                                        placeholder="Enter checklist name*"
                                                        name='name'
                                                        {...register('name')}
                                                    />

                                                    <span className='text-danger'>{errors.name?.message}</span>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-5'>
                                                <div className="form-group mb-2">
                                                    <label className='f-w-600'>{"Description"}</label>
                                                    <input
                                                        className={errors.description ? "form-control is-invalid" : "form-control"}
                                                        type="text"
                                                        placeholder="Enter checklist description*"
                                                        name='description'
                                                        {...register('description')}
                                                    />

                                                    <span className='text-danger'>{errors.description?.message}</span>
                                                </div>
                                            </div>


                                        </div>
                                    </form>

                                    <div className='row mt-2'>

                                        {/* <AddChecklistItems /> */}
                                        <div className='col-12'>
                                            <p className='f-18 f-w-600 mb-0'>Add Checklist Items</p>
                                            <hr className='mb-3 mt-0' />


                                            <div className="table-responsive ">
                                                <table className="table table-bordered">
                                                    <thead className='thead-light'>
                                                        <tr className='f-16'>
                                                            <th scope="col">{"Sr.No"}</th>
                                                            <th scope="col">{"Section"}</th>
                                                            {/* <th scope="col">{"Yes"}</th> */}
                                                            {/* <th scope="col">{"No"}</th> */}
                                                            <th scope="col">{"Comments"}</th>
                                                            <th scope="col">{"Photo"}</th>
                                                            <th scope="col">{"Video"}</th>
                                                            <th scope="col">{"OG"}</th>
                                                            <th scope="col">{"Action"}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fields.map(({ id }, index) => (

                                                            <tr key={id} className="f-18">

                                                                <td scope="row" style={{ width: '100px' }}>
                                                                    <input
                                                                        className={errors.checklistLineItems?.[index]?.SrNo ? 'form-control is-invalid' : 'form-control'}
                                                                        type='text'
                                                                        name={`checklistLineItems[${index}].SrNo`}
                                                                        {...register(`checklistLineItems[${index}].SrNo`)}
                                                                        placeholder="SrNo"

                                                                    />
                                                                    <p className="text-danger">{errors.checklistLineItems?.[index]?.SrNo?.message}</p>
                                                                </td>

                                                                <td>
                                                                    <input
                                                                        className={errors.checklistLineItems?.[index]?.section ? 'form-control is-invalid' : 'form-control'}
                                                                        type='text'
                                                                        {...register(`checklistLineItems[${index}].section`)}
                                                                        name={`checklistLineItems[${index}].section`}
                                                                        placeholder="Enter Section"
                                                                    />

                                                                    <p className="text-danger">{errors.checklistLineItems?.[index]?.section?.message}</p>
                                                                </td>
                                                                {/* 
                                                                 <td>

                                                                    <input id="solid6" type="checkbox" defaultChecked />

                                                                </td>
                                                                <td>

                                                                    <input id="solid6" type="checkbox" defaultChecked />

                                                                </td> */}
                                                                <td>

                                                                    <Controller
                                                                        name={`checklistLineItems[${index}].comments`}
                                                                        control={control}
                                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                            <select
                                                                                {...register(`checklistLineItems[${index}].comments`)}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                defaultValue="Yes"
                                                                                className={errors.checklistLineItems?.[index]?.comments ? 'form-control is-invalid' : 'form-control'}>
                                                                                <option select="">{"--Select*--"}</option>
                                                                                <option value="Yes">{"Yes"}</option>
                                                                                <option value="No">{"No"}</option>
                                                                            </select>

                                                                        )}
                                                                    />

                                                                    <p className="text-danger">{errors.checklistLineItems?.[index]?.comments?.message}</p>
                                                                </td>
                                                                <td>

                                                                    <Controller
                                                                        name={`checklistLineItems[${index}].photos`}
                                                                        control={control}
                                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                            <select
                                                                                {...register(`checklistLineItems[${index}].photos`)}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                defaultValue="Yes"
                                                                                className={errors.checklistLineItems?.[index]?.photos ? 'form-control is-invalid' : 'form-control'}>
                                                                                <option select="">{"--Select*--"}</option>
                                                                                <option value="Yes">{"Yes"}</option>
                                                                                <option value="No">{"No"}</option>
                                                                            </select>

                                                                        )}
                                                                    />

                                                                    <p className="text-danger">{errors.checklistLineItems?.[index]?.photos?.message}</p>
                                                                </td>


                                                                <td>

                                                                    <Controller
                                                                        name={`checklistLineItems[${index}].videos`}
                                                                        control={control}
                                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                            <select
                                                                                {...register(`checklistLineItems[${index}].videos`)}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                defaultValue="Yes"
                                                                                className={errors.checklistLineItems?.[index]?.videos ? 'form-control is-invalid' : 'form-control'}>
                                                                                <option select="">{"--Select*--"}</option>
                                                                                <option value="Yes">{"Yes"}</option>
                                                                                <option value="No">{"No"}</option>
                                                                            </select>

                                                                        )}
                                                                    />


                                                                    <p className="text-danger">{errors.checklistLineItems?.[index]?.videos?.message}</p>
                                                                </td>


                                                                <td>
                                                                    <Controller
                                                                        name={`checklistLineItems[${index}].OG`}
                                                                        control={control}
                                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                            <select
                                                                                {...register(`checklistLineItems[${index}].OG`)}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                defaultValue="Yes"
                                                                                className={errors.checklistLineItems?.[index]?.OG ? 'form-control is-invalid' : 'form-control'}>
                                                                                <option select="">{"--Select*--"}</option>
                                                                                <option value="Yes">{"Yes"}</option>
                                                                                <option value="No">{"No"}</option>
                                                                            </select>

                                                                        )}
                                                                    />

                                                                    <p className="text-danger">{errors.checklistLineItems?.[index]?.OG?.message}</p>
                                                                </td>


                                                                <td>
                                                                    <Trash2
                                                                        id="deleteToolTip"
                                                                        className={index > 0 ? "" : 'cursor_banned'}
                                                                        onClick={index > 0 ? () => remove(index) : null}
                                                                        height={'18px'} width={'18px'} />
                                                                    <UncontrolledTooltip placement="bottom" target="deleteToolTip">
                                                                        {"Delete Record"}
                                                                    </UncontrolledTooltip>

                                                                </td>

                                                            </tr>
                                                        ))}


                                                    </tbody>
                                                </table>



                                                <div className='d-flex justify-content-end  mt-4'>
                                                    <button
                                                        style={{ fontSize: "16px" }}
                                                        className='btn btn-sm btn-primary-blue'
                                                        onClick={() => append({})}>Add</button>

                                                </div>

                                            </div>


                                        </div>

                                    </div>

                                    <hr />

                                    <button
                                        onClick={handleSubmit(onSubmit)} style={{ fontSize: "16px " }}
                                        className='btn btn-primary-blue float-end'>{"Create Checklist"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </React.Fragment>
    )
}

export default CreateNewCheckListForm