import { useState, useEffect, useRef } from "react";
import { config, useClient, useMicrophoneAndCameraTracks } from "./settings.js";
import AgoraRTC from "agora-rtc-sdk-ng";
import Video from "./Video";
import Controls from "./Controls";
import { createScreenVideoTrack, } from "agora-rtc-react";

export default function VideoCall(props) {
  const { setInCall, setCall, setOpen } = props;
  const [users, setUsers] = useState([]);
  const [videoTrackScreenShare, setVideoTrackScreenShare] = useState(null);
  const [switchedTrack, setSwitchedTrack] = useState(null);
  const [start, setStart] = useState(false);
  const client = useClient();
  const [screenTrack, setScreenTrack] = useState(null);
  const userId = JSON.parse(localStorage.getItem("login_data"));
  const { ready, tracks } = useMicrophoneAndCameraTracks();


  const onScreenChoose = async (para) => {
    console.log("para", para);
    if (para == "start") {

      let sources = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        // video: {
        //   cursor: "always",
        //   height: 1000,
        //   width: 1200,
        // },
        audio: false,
      });

      const videoTrack = sources.getVideoTracks()[0];
      // console.log(videoTrack);
      if (videoTrack) {
        const screenTrack = AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: videoTrack,
        });
        console.log("screenTrack", screenTrack);
        setVideoTrackScreenShare(screenTrack);
      }
    } else {
      setVideoTrackScreenShare(null);
    }

  };


  function switchCamera() {
    console.log("tracks", tracks);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

      // Get a list of available video input devices
      navigator.mediaDevices.enumerateDevices()
        .then(function (devices) {
          // Filter out non-video input devices
          var videoDevices = devices.filter(function (device) {
            return device.kind === 'videoinput';
          });

          // Log the list of available video devices to the console
          console.log(videoDevices);

          for (let i = 0; i < videoDevices.length; i++) {
            if (videoDevices[i].label !== tracks[1]._deviceName) {

              navigator.mediaDevices.getUserMedia({ video: { deviceId: videoDevices[i].deviceId } })
                .then(function (stream) {
                  var videoTrack = stream.getVideoTracks()[0];
                  console.log("videoTrack", videoTrack);
                  const customCamSwiTrack = AgoraRTC.createCustomVideoTrack({
                    mediaStreamTrack: videoTrack,
                  });
                  console.log("customCamSwiTrack", customCamSwiTrack);
                  setSwitchedTrack(customCamSwiTrack);
                })
                .catch(function (err) {
                  console.log(err.name + ": " + err.message);
                });
            }
          }
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
        });
    }
  }


  useEffect(() => {
    let init = async (name) => {

      client.on("user-published", async (user, mediaType) => {
        console.log("mediaType", mediaType);

        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });


          console.log("user", user);
        
        }
        if (mediaType === "audio") {
          user.audioTrack.play();
        }



      });


      client.on("user-unpublished", (user, mediaType) => {
        if (mediaType === "audio") {
          if (user.audioTrack) user.audioTrack.stop();
        }
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });
      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });
      try {
        await client.join(config.appId, name, props.token, userId.userId);
      } catch (error) {
        console.log("error");
      }
      // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii");
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };
    if (ready && tracks) {
      try {
        init(props.channelName);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.channelName, client, ready, tracks]);


  return (
    <div
      className="d-flex flex-column video_call"
      // direction="column"
      style={{ height: "80vh", position: "relative", overflowY: "auto" }}
    >
      <div style={{ height: "100%" }}>
        {start && tracks && (
          <Video
            tracks={tracks}
            users={users}
            videoTrackScreenShare={videoTrackScreenShare}
            client={client}
            switchCamera={switchCamera}
            switchedTrack={switchedTrack}
          />
        )}
      </div>
      <div
        style={{
          height: "10%",
          width: "100%",
          position: "absolute",
          bottom: "3%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {ready && tracks && (
          <Controls
            setOpen={setOpen}
            tracks={tracks}
            setStart={setStart}
            setInCall={setInCall}
            setCall={setCall}
            onScreenChoose={onScreenChoose}
            videoTrackScreenShare={videoTrackScreenShare}
            endCall={props.endCall}
            switchCamera={switchCamera}
          />
        )}
      </div>
      {/* <button
        style={{ zIndex: 10000 }}
        className="mt-5" onClick={() => props.handleSwitchCamera()}>Switch Camera</button> */}
    </div>
  );
}
