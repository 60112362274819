const Image_component = ({url}) => {
     // Use the imported image as the icon source
   
    return (
      <div>
        {/* Your component content here */}
        <img src={url} height={20} width={20} style={{filter:" invert(100%)" }} />
      </div>
    );
  };

  export default Image_component;