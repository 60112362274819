import { GET_SURVEY_LIST } from "../actionTypes";

const initial_state = {
  surveyData: [],
  loading: false,
};

const SurveyReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_SURVEY_LIST:
      return { ...state, surveyData: action?.survey.res, loading: true };

    default:
      return { ...state };
  }
};

export default SurveyReducer;
