import React, { useEffect, useState } from 'react';
import { Trash2, Edit, PlusSquare } from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { updateCheckList } from '../../../../api/index';

const schema = yup.object().shape({
    SrNo: yup.string()
        .required('Required !'),
    section: yup.string()
        .required('Required !'),
    comments: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    photos: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    videos: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),
    OG: yup.string().nullable()
        .required('Required !').matches(/^((?!Select).)*$/, "Required !"),

}).required();

function AddChecklistItems(props) {
    const customer_id = localStorage.getItem('customer_id');

    const { register, handleSubmit, formState, control, reset } = useForm({
        resolver: yupResolver(schema), mode: "onChange",
    });

    let { errors } = formState;

    const [modal8, setModal8] = useState();

    const toggle8 = () => {
        setModal8(!modal8)
        if (modal8 === false || modal8 === undefined) {
            reset();
        }
    }


    useEffect(() => {
        console.log("props", props);
    }, []);


    const onSubmit = data => {

        if (data !== '') {

            console.log("data", data);


            const reqPayload = {
                Id: props.checkListdata.checklist.Id,
                checklist: {
                    name: props.getValues("name"),
                    description: props.getValues("description"),
                    customer_id: customer_id
                },
                checklistLineItems: [
                    data
                ]
            }

            console.log("reqPayload", reqPayload);

            updateCheckList(reqPayload).then((res) => {
                console.log("res", res);

                toast.success("Checklist item added successfully !", { autoClose: 2000,theme:'light' });
                toggle8();
                props.fetchCheckListData();


            }, (err) => {
                console.log("err", err);
                toast.error("Something went wrong, please try again !", { autoClose: 3000 ,theme:'light'})
            });

        } else {
            errors.showMessages();
        }
    };



    return (
        <React.Fragment>
            <button
                style={{ fontSize: "16px" }}
                className='btn btn-sm btn-primary-blue'
                onClick={toggle8}
            >Add Checklist Item</button>

            <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop='static'>
                <ModalHeader toggle={toggle8} className='bg-primary-blue'>{"Add Checklist Item"}</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-12 col-md-2 mb-2'>
                            <label className='f-w-600 f-14'>{"Sr.No"}</label>
                            <input
                                className={errors.SrNo ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                name={`SrNo`}
                                {...register(`SrNo`)}
                                placeholder="SrNo*"
                            />

                            <span className='text-danger'>{errors.SrNo?.message}</span>
                        </div>

                        <div className='col-12 col-md-10 mb-2'>
                            <label className='f-w-600 f-14'>{"Section"}</label>
                            <textarea
                                className={errors.section ? 'form-control is-invalid' : 'form-control'}
                                type='text'
                                {...register(`section`)}
                                name={`section`}
                                placeholder="Enter section*"
                                rows='2'
                            ></textarea>

                            <span className='text-danger'>{errors.section?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Comments"}</label>

                            <select
                                {...register(`comments`)}
                                name={`comments`}
                                className={errors.comments ? 'form-control is-invalid' : 'form-control'}
                                defaultValue="Yes">
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Yes"}</option>
                                <option value="No">{"No"}</option>
                            </select>

                            <span className='text-danger'>{errors.comments?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Photos"}</label>

                            <select
                                {...register(`photos`)}
                                name={`photos`}
                                className={errors.photos ? 'form-control is-invalid' : 'form-control'}
                                defaultValue="Yes">
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Yes"}</option>
                                <option value="No">{"No"}</option>
                            </select>

                            <span className='text-danger'>{errors.photos?.message}</span>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"Videos"}</label>

                            <select
                                {...register(`videos`)}
                                name={`videos`}
                                className={errors.videos ? 'form-control is-invalid' : 'form-control'}
                                defaultValue="Yes">
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Yes"}</option>
                                <option value="No">{"No"}</option>
                            </select>

                            <span className='text-danger'>{errors.videos?.message}</span>

                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                            <label className='f-w-600 f-14'>{"OG"}</label>

                            <select
                                {...register(`OG`)}
                                name={`OG`}
                                className={errors.OG ? 'form-control is-invalid' : 'form-control'}
                                defaultValue="Yes">
                                <option select="">{"--Select*--"}</option>
                                <option value="Yes">{"Yes"}</option>
                                <option value="No">{"No"}</option>
                            </select>

                            <span className='text-danger'>{errors.OG?.message}</span>
                        </div>



                    </div>
                </ModalBody>

                <ModalFooter>
                    <button className='btn btn-primary-blue' onClick={handleSubmit(onSubmit)}>{"Submit"}</button>
                    <button className='btn btn-dark' onClick={toggle8}>{"Cancel"}</button>
                </ModalFooter>
            </Modal>


        </React.Fragment>
    )
}

export default AddChecklistItems