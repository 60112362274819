import React, { useState, useEffect } from 'react'
import { ClientConfig, IAgoraRTCRemoteUser, ICameraVideoTrack, IMicrophoneAudioTrack, } from "agora-rtc-sdk-ng";
import { createClient, createMicrophoneAndCameraTracks, } from "agora-rtc-react";

import Controls from './Controls';
import Videos from './Videos';
import GuestVideos from './GuestVideos';

var config = ClientConfig;
config = {
    mode: "rtc", codec: "vp8",
};

const VideoCall = React.memo((props) => {
    const { setInCall, token } = props;

    const [channelName, setChannelName] = useState(JSON.parse(localStorage.getItem("guestUserData")).SIDGUEST.toString());
    const [users, setUsers] = useState([]);
    const [start, setStart] = useState(false);
    const useClient = createClient(config);
    const client = useClient();
    const appId = `${process.env.REACT_APP_AGORA_APPID}`; //ENTER APP ID HERE
    const user_id_guest = parseInt(localStorage.getItem('user_id_guest'));

    const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
    const { ready, tracks } = useMicrophoneAndCameraTracks();


    // useEffect(() => {
    //     console.log("props", props);
    //     console.log('user_id_guest', localStorage.getItem("user_id_guest"));
    // }, []);



    useEffect(() => {
        // function to initialise the SDK
        let init = async (name) => {
            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType);
                console.log("subscribe success");
                if (mediaType === "video") {
                    setUsers((prevUsers) => {
                        return [...prevUsers, user];
                    });
                }
                if (mediaType === "audio") {
                    user.audioTrack?.play();
                }
            });

            client.on("user-unpublished", (user, type) => {
                console.log("unpublished", user, type);
                if (type === "audio") {
                    user.audioTrack?.stop();
                }
                if (type === "video") {
                    setUsers((prevUsers) => {
                        return prevUsers.filter((User) => User.uid !== user.uid);
                    });
                }
            });

            client.on("user-left", (user) => {
                console.log("leaving", user);
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
            });

            console.log("token", token.data);
            console.log("channelName", channelName);
            console.log("appId", appId);            
            console.log("user_id_guest", user_id_guest);
            // console.log("localStorage.getItem('user_id')", localStorage.getItem('user_id'));
            // console.log("channelName", channelName);
            // console.log("client", client);
            // console.log("ready", ready);
            // console.log("tracks", tracks);

            await client.join(appId, channelName, token.data, user_id_guest).then((res) => {
                console.log("res", res);
            });
            if (tracks) await client.publish([tracks[0], tracks[1]]);
            setStart(true);

        };

        if (ready && tracks) {
            console.log("calling init-----------------");
            init();
        }

    }, [ready, tracks]);
    // }, [ready, tracks, channelName, client,]);


    // useEffect(() => {
    //     console.log("start", start);
    //     console.log("tracks", tracks);
    // }, [start, tracks]);


    return (
        <React.Fragment>
            <div className="Agora_App">
                {start && tracks &&
                    <GuestVideos
                        client={client}
                        endCall={props.endCall}
                        users={users} tracks={tracks} setStart={setStart} setInCall={setInCall} />}

                {/* {ready && tracks && (
                    <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
                )} */}

            </div>
        </React.Fragment>
    )
})

export default VideoCall