import React, { useState, useEffect } from "react";
import { getChecklistItemAttachmentv3 } from "../../../../api/index";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import fileimg from "../../../../assets/images/Vedam/fileimg.png";

function OnlySurveyDocs(props) {
  const { basic_settings } = props;
  const [onlySurveyFile, setOnlySurveyFiles] = useState({
    data: [],
    loading: false,
  });
  const [risOpen, rsetIsOpen] = useState(0);
  const { id } = useParams();

  useEffect(async () => {}, []);

  const rtoggle = async (tid) => {
    console.log("tid", tid);

    if (risOpen === tid) {
      rsetIsOpen(null);
    } else {
      await fetchDocs();
      rsetIsOpen(tid);
    }
  };

  async function fetchDocs() {
    setOnlySurveyFiles({ ...onlySurveyFile, data: [], loading: true });
    const payloadAttForSurvey = {
      survey: id,
      withSurveyOnly: "True",
      withTable: "False",
      isRef: "False",
    };

    await getChecklistItemAttachmentv3(payloadAttForSurvey).then(
      (res) => {
        console.log("res", res);
        setOnlySurveyFiles({
          ...onlySurveyFile,
          data: res.data,
          loading: false,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  return (
    <div className="fontFamily mb-4">
      <div className="default-according style-1">
        <Card>
          <CardHeader className="bg-vedam position-relative p-2">
            <h5 className="mb-0">
              <Button
                style={{ position: "relative" }}
                as={Card.Header}
                className="btn btn-link btn-vedam txt-white p-0 "
                onClick={() => rtoggle(1)}
              >
                <p className="mb-0 f-16 me-5"> {`Survey Documents`}</p>
              </Button>
            </h5>

            {/* <button
                            style={{ top: '50%', right: '10px', transform: 'translate(0%,-50%)', maxWidth: '180px' }}
                            onClick={() => fetchDocs()}
                            className='btn btn-light text-center position-absolute'>Get Documents</button> */}
          </CardHeader>

          <Collapse isOpen={risOpen === 1}>
            <CardBody>
              {onlySurveyFile.loading ? (
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              ) : onlySurveyFile.data.length > 0 ? (
                <Slider {...basic_settings} className="mb-2 mb-md-0">
                  {onlySurveyFile.data.map((img, i) =>
                    img.fileType == "Image" ? (
                      <div id="attachment_parent" key={i}>
                        <div className="image_attachments m-2">
                          <img
                            onClick={() =>
                              window.open(img.checklist_file, "_blank").focus()
                            }
                            style={{ borderRadius: "20px" }}
                            className="cursor_pointer"
                            src={img.checklist_file}
                            alt=""
                            height={"150"}
                            width={"100%"}
                          />

                          <div className="date_overlay">{img.name}</div>
                        </div>
                      </div>
                    ) : img.fileType == "Document" ? (
                      <div id="attachment_parent" key={i}>
                        <div className="image_attachments m-2">
                          <img
                            onClick={() =>
                              window.open(img.checklist_file, "_blank").focus()
                            }
                            style={{ borderRadius: "20px" }}
                            className="cursor_pointer"
                            src={fileimg}
                            alt=""
                            height={"150"}
                            width={"100%"}
                          />

                          <div className="date_overlay">{img.name}</div>
                        </div>
                      </div>
                    ) : null
                  )}
                </Slider>
              ) : (
                "No Document"
              )}
            </CardBody>
          </Collapse>
        </Card>
      </div>
    </div>
  );
}

export default OnlySurveyDocs;
