import React, { useEffect, useState } from "react";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal } from "react-bootstrap"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { update_mla, update_soc, get_mla } from "../../../../api/index";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Description is required !"),
    srno: yup
      .string()
      .required("Sr.No is required")
      .max(30, "30 charaters allowed !")
      .test('match', 'Please enter valid Sr.No', function (value) {
        return /^[a-zA-Z0-9.]+$/.test(value);
      }),
    sequence: yup.string()
      .required("Sequence no. is required")
      .matches(/^[1-9][0-9]*$/, 'Sequence no. cannot start with 0'),
    soc: yup
      .string()
      .required("SOC required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    socCategory: yup.array().when("soc", {
      is: (value) => value == "true",
      then: yup
        .array()
        .min(1, "Please select SOC category !")
        .required("Please select SOC category !"),
      otherwise: yup.array().notRequired(),
    }),
  })
  .required();

function Edit_Multi_level_answers(props) {
  const { register, handleSubmit, formState, control, reset, setValue, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;
  const customer_id = localStorage.getItem("customer_id");
  const watchSOC = watch("soc");
  const [socCat, setSocCat] = useState([]);
  const [parent, setParent] = useState([]);
  const [mlaList, setMlaList] = useState({ data: [], loading: false });

  useEffect(() => {
    console.log("props", props);
    reset()
    setValue("name", props.data?.description);
    setValue("sequence",props.data?.sequence)
    setValue("photo", props.data.photo);
    setValue("srno", props.data?.srno);
    setValue("soc", `${props.data.soc}`);
    setValue("comments",props.data?.comments)
    const res = props.soc_list.filter((val) => {
      return val.Id == props.data.socCategoryId;
    });
    console.log("res", res)
    setValue("socCategory", props.data.soc == true ? res : []);
    setValue("parent",props.data.parent? [
      {
        Id: props.data.parentId,
        description: props.data.parent,
      },
    ]
    : [])
    fetchMLA()

  }, [props.data]);

  // useEffect(() => {
  //   if (props.modal) {
  //     reset();
  //     const res = props.soc_list.filter((val) => {
  //       return val.Id == props.data.socCategoryId;
  //     });
  //     console.log("res", res);
  //     setSocCat(res);
  //     fetchMLA();
  //     console.log("props", props);

  //    // setSocCat(res);

  //      setValue("socCategory", props.data.soc==true?res:[]);
  //   }
  // }, [props.modal]);

  function fetchMLA() {
    setMlaList({ ...mlaList, loading: true, data: [] });
    get_mla({ customer: customer_id }).then(
      (res) => {
        console.log("res_mla", res);
        setMlaList({ ...mlaList, loading: false, data: res.data.payload });
        // const res2 = res.data.payload.filter((val) => {
        //   return val.Id == props.data.parentId;
        // });
        // setValue("parent", res2);
        // setParent(res2);
        // console.log("res2", res2);
      },
      (err) => {
        console.log("err", err);
        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const payload = {
        Id: props.data.Id,
        srno: data.srno,
        soc: data.soc,
        socCategoryId:
          data.socCategory.length > 0 ? data.socCategory[0].Id : null,
        parent: data.parent.length > 0 ? data.parent[0].description : null,
        hasParent: data.parent.length > 0 ? true : false,
        parentId: data.parent.length > 0 ? data.parent[0].Id : null,
        photo: data.photo,
        comments: data.comments,
        description: data.name,
        sequence: data.sequence,
        customer: customer_id
      };

      if (payload.soc == "false") {
        payload.socCategoryId = null;
      }

      console.log("payloadalnsd", payload);
      update_mla(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("MLA updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          props.toggle();
          props.fetch_mla_list();
        },
        (err) => {
          console.log("err", err);
          console.log("err.response", err.response);
          toast.error(err.response.data.message, {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.modal}
        onHide={() => props.toggle()}
        size="lg"
        backdrop="static"
      >
        <Modal.Header className="bg-primary-blue f-24" closeButton>
          {"Update Multilevel Answer"}
        </Modal.Header>
        <Modal.Body>
          <div className="row fontFamily">
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Sr. No"}</label>
              <input
                className={
                  errors.srNo ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`srno`}
                defaultValue={props.data.srno}
                {...register(`srno`)}
                placeholder="Enter Sr. No.*"
              />

              <span className="text-danger">{errors.srno?.message}</span>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Sequence No."}</label>
              <input
                className={
                  errors.sequence ? "form-control is-invalid" : "form-control"
                }
                type="number"

                name={`sequence`}
                {...register(`sequence`)}
                defaultValue={props.data.sequence}
                placeholder="Enter Sequence No.*"
              />

              <span className="text-danger">{errors.sequence?.message}</span>
            </div>

            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Description"}</label>
              {/* <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                defaultValue={props.data.description}
                {...register(`name`)}
                placeholder="Enter Description*"
              /> */}

              <textarea
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                placeholder="Enter Description*"
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.name?.message}</span>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="f-w-600">{"Photo"}</label>
                <Controller
                  name="photo"
                  control={control}
                  defaultValue={props.data.photo}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={props.data.photo}
                      className={
                        errors.photo
                          ? " form-control is-invalid"
                          : " form-control"
                      }
                    >
                      <option select="">{"--Select*--"}</option>
                      <option value="NR">{"Not Required"}</option>
                      <option value="Optional">{"Optional"}</option>
                      <option value="Mandatory">{"Mandatory"}</option>
                    </select>
                  )}
                />

                <span className="text-danger">{errors.photo?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="f-w-600">{"Comments"}</label>
                <Controller
                  name="comments"
                  control={control}
                  defaultValue={props.data.comments}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={props.data.comments}
                      className={
                        errors.comments
                          ? " form-control is-invalid"
                          : " form-control"
                      }
                    >
                      <option select="">{"--Select*--"}</option>
                      <option value="NR">{"Not Required"}</option>
                      <option value="Optional">{"Optional"}</option>
                      <option value="Mandatory">{"Mandatory"}</option>
                    </select>
                  )}
                />

                <span className="text-danger">{errors.comments?.message}</span>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="f-w-600">{"SOC"}</label>

                <select
                  {...register("soc")}
                  defaultValue={props.data.soc ? "true" : "false"}
                  className={
                    errors.soc ? " form-control is-invalid" : " form-control"
                  }
                >
                  <option select="">{"Select Option"}</option>
                  <option value="true">{"Yes"}</option>
                  <option value="false">{"No"}</option>
                </select>

                {/* <Controller
                  name="soc"
                  control={control}
                  defaultValue={props.data.soc}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={props.data.soc}
                      className={
                        errors.soc
                          ? " form-control is-invalid"
                          : " form-control"
                      }
                    >
                      <option select="">{"--Select*--"}</option>
                      <option value="true">{"Yes"}</option>
                      <option value="false">{"No"}</option>
                    </select>
                  )}
                /> */}

                <span className="text-danger">{errors.soc?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Select Parent"}</label>
              <Controller
                name="parent"
                control={control}
                defaultValue={
                  props.data.parentId !== null
                    ? [
                      {
                        Id: props.data.parentId,
                        description: props.data.parent,
                      },
                    ]
                    : []
                }
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead"
                    labelKey="description"
                    multiple={false}
                    options={mlaList.data.filter((item) => item.Id !== props.data.Id)}
                    isLoading={mlaList.loading}
                    defaultSelected={
                      props.data.parentId !== null
                        ? [
                          {
                            Id: props.data.parentId,
                            description: props.data.parent,
                          },
                        ]
                        : []
                    }
                    emptyLabel={
                      [].length > 0
                        ? "No matches found."
                        : "No Parents to select"
                    }
                    placeholder="Select Parent"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"SOC Category"}</label>
              <Controller
                name="socCategory"
                control={control}
                defaultValue={props.data.soc == true ? props.soc_list.filter((val) => {
                  return val.Id == props.data.socCategoryId;
                }) : []}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-soc-cat"
                    name="socCategory"
                    labelKey="categoryName"
                    multiple={false}
                    options={props.soc_list}
                    placeholder="Select SOC Category"
                    onChange={onChange}
                    defaultSelected={props.data.soc == true ? props.soc_list.filter((val) => {
                      return val.Id == props.data.socCategoryId;
                    }) : []}
                    onBlur={onBlur}
                    // selected={value}
                    disabled={
                      watchSOC == "false" || watchSOC == undefined
                        ? true
                        : false
                    }
                    isInvalid={errors.socCategory ? true : false}
                  />
                )}
              />

              <span className="text-danger">{errors.socCategory?.message}</span>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default Edit_Multi_level_answers;
