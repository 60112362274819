import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSurvyeApi } from "../../api";
import { GET_SURVEY_LIST, WATCH_SURVEY_LIST } from "../../redux/actionTypes";

function* fetchSurveyAsyn() {
  let Data = yield call(fetchSurvyeApi);
  yield put({ type: GET_SURVEY_LIST, survey: Data });
}

export function* watchSurveyData() {
  yield takeLatest(WATCH_SURVEY_LIST, fetchSurveyAsyn);
}
