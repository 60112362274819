import React, { useEffect, useState } from "react";
import {
  getMethodology,
  getNonConfirmsList,
  updateNonConfoList,
  createNonConfoList,
} from "../../../../api/index";
import { toast, ToastContainer } from "react-toastify";

function NonConfirm() {
  const [nclist, setNclist] = useState({ loading: false, data: [] });
  const [ncFromApi, setNcFromApi] = useState({});
  const [ncRating, setNcRating] = useState({});
  const [ncDataExist, setNcDataExist] = useState(false);

  const login_data = JSON.parse(localStorage.getItem("login_data"));

  useEffect(() => {
    fetchMethodology();
  }, []);

  function fetchMethodology() {
    getMethodology().then(
      (res) => {
        console.log("res", res);
        // let ncList = res.data.filter((val) => val.type !== "Ratings");
        let ncList = res.data;
        console.log("ncList", ncList);
        let allValues = ncList.map((question) => JSON.parse(question.values));
        allValues = allValues.flat();
        console.log("allValues", allValues);

        const uniqueStrings = [...new Set(allValues)];

        allValues = uniqueStrings.map((val) => {
          return { value: val };
        });
        console.log("allValues", allValues);

        // let ncRating = res.data.filter((val) => val.type == "Ratings");
        // ncRating[0].values = JSON.parse(ncRating[0].values);
        // ncRating[0].values = ncRating[0].values.map((val) => {
        //   return { value: val };
        // });

        setNclist({ ...nclist, data: [], loading: true });
        getNonConfirmsList({ customerId: login_data.customer_id }).then(
          (res) => {
            console.log("res", res);
            if (res.data.payload.length > 0) {
              setNcDataExist(true);
              setNcFromApi(res.data.payload[0]);

              // ncRating[0].values.forEach((item) => {
              //   if (item.value == res.data.payload[0].ncRating) {
              //     item.check = true;
              //   } else {
              //     item.check = false;
              //   }
              // });
              // console.log("ncRating", ncRating);
              // setNcRating(ncRating[0]);
                 

              allValues.forEach((item) => {
                let check
                  if(item.value%1==0){
                    check =res.data.payload[0].ncList.includes(`${item.value}`)
                  }else{

                    check =res.data.payload[0].ncList.includes(item.value)
                  } 
                if (check) {
                  console.log("item",item.value);

                  item.check = true;
                } else {
                 // console.log("else",item.value);
                  item.check = false;
                }
              });

            

              console.log("allValues", allValues);
              setNclist({ ...nclist, data: allValues, loading: false });
            } else {
              setNcDataExist(false);
              setNclist({ ...nclist, data: [], loading: false });

              allValues.forEach((item) => {
                item.check = false;
              });
              setNclist({ ...nclist, data: allValues, loading: false });

              // ncRating[0].values.forEach((item) => {
              //   item.check = false;
              // });
              // console.log("ncRating", ncRating);
              // setNcRating(ncRating[0]);
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function handleCheckBox(e, ind) {
    console.log(e.target.checked);
    const newList = { ...nclist };
    newList.data[ind].check = e.target.checked;
    setNclist(newList);
  }

  function handleRating(e, ind) {
    const tempncRating = { ...ncRating };
    if (e.target.checked == true) {
      tempncRating.values.forEach((item, index) => {
        item.check = index === ind ? true : false;
      });
      setNcRating(tempncRating);
    } else {
      tempncRating.values[ind].check = e.target.checked;
      setNcRating(tempncRating);
    }
    console.log("tempncRating", tempncRating);
  }

  function saveList() {
    console.log("nclist", nclist);
    // console.log("ncRating", ncRating);

    // const valuesWithCheckTrue = ncRating.values
    //   .filter((item) => item.check)
    //   .map((item) => item.value);

    // console.log("valuesWithCheckTrue", valuesWithCheckTrue);

    let newList = nclist.data.filter((val) => {
      return val.check == true;
    });

    newList = newList.map((val) => {
           if(val.value%1==0){
            
           return val.value.toString()
           }
           else{

             return val.value;
           }
    });

    if (ncDataExist) {
      const reqPayload = { ...ncFromApi };
      reqPayload.ncList = newList;
      // reqPayload.ncRating =
      //   valuesWithCheckTrue[0] == undefined ? 0 : valuesWithCheckTrue[0];

      console.log("newList", newList);
      console.log("reqPayload", reqPayload);

      updateNonConfoList(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("Saved Successfully !", {
            autoClose: 2000,
            theme: "light",
            
          });
          fetchMethodology();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 2000,
            theme: "light",
            
          });
        }
      );
    } else {
      console.log("newList", newList);
     // console.log("valuesWithCheckTrue", valuesWithCheckTrue);

      const reqPayload = {
        ncList: newList,
        // ncRating: valuesWithCheckTrue.length > 0 ? valuesWithCheckTrue[0] : 0,
        isActive: true,
        customerId: login_data.customer_id,
      };
      console.log("reqPayload", reqPayload);
      createNonConfoList(reqPayload).then(
        (res) => {
          console.log("res", res);
          fetchMethodology();
          toast.success("Saved Successfully !", {
            autoClose: 2000,
            theme: "light",
            
          });
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 2000,
            theme: "light",
            
          });
        }
      );
    }
  }

  return (
    <div className="py-4 fontFamily">
      <div className="card">
        <div className="title bg-primary-blue" id="card_title">
          <p className="mb-0 f-24">Non Conformance List</p>
        </div>
        <div className="card-body">
          {nclist.loading ? (
            <div className="loader-box justify-content-center">
              <div className="loader">
                <div className="line bg-dark"></div>
                <div className="line bg-dark"></div>
                <div className="line bg-dark"></div>
                <div className="line bg-dark"></div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 col-md-4">
                <label className="f-w-600 f-14">Select Non Conformance </label>
                {nclist.data.map((val, ind) => (
                  <label
                    key={ind}
                    className="d-block"
                    htmlFor={"chk-ani" + ind}
                  >
                    <input
                      className={"checkbox_animated"}
                      id={"chk-ani" + ind}
                      type="checkbox"
                      onChange={(e) => handleCheckBox(e, ind)}
                      checked={val.check}
                    />
                    {val.value}
                  </label>
                ))}

                <div className="mt-4">
                  <button
                    className="btn btn-primary-blue"
                    onClick={() => saveList()}
                  >
                    Save
                  </button>
                </div>
              </div>
              {/* <div className="col-12 col-md-4">
                <label className="f-w-600 f-14">
                  Select Non Conformance Rating
                </label>
                {ncRating.values?.map((val, ind) => (
                  <label
                    key={ind}
                    className="d-block"
                    htmlFor={"chkrating" + ind}
                  >
                    <input
                      className={"checkbox_animated"}
                      id={"chkrating" + ind}
                      type="checkbox"
                      onChange={(e) => handleRating(e, ind)}
                      checked={val.check}
                    />
                    {val.value}
                  </label>
                ))}
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NonConfirm;
