import React, { useState, useEffect, useReducer } from "react";
import { Trash2, Edit, PlusCircle, Trello } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal } from "react-bootstrap"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import clipboard from "../../../../assets/images/Vedam/clipboard.png";
import {
  delete_mla,
  get_mla,
  get_noc_category,
  get_soc_category,
  post_mla,
} from "../../../../api";
import { Typeahead } from "react-bootstrap-typeahead";
import Edit_Multi_level_answers from "./Edit_Multi_level_answers";
import Tree_view from "./Tree_view";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(250, "250 characters allowed !")
      .required("Description is required !"),
    srno: yup
      .string()
      .required("Sr.No is required")
      .max(30, "30 charaters allowed !")
      .test('match', 'Please enter valid Sr.No', function (value) {
        return /^[a-zA-Z0-9.]+$/.test(value);
      }),
    sequence: yup
      .string()
      .required("Sequence no. is required")
      .matches(/^[1-9][0-9]*$/, 'Sequence no. cannot start with 0'),
    soc: yup
      .string()
      .required("SOC required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),

    socCategory: yup.array().when("soc", {
      is: (value) => value == "true",
      then: yup
        .array()
        .min(1, "Please select SOC category !")
        .required("Please select SOC category !"),
      otherwise: yup.array().notRequired(),
    }),

    photo: yup
      .string()
      .nullable()
      .required("Photo is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    comments: yup
      .string()
      .nullable()
      .required("Comments is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
  })
  .required();

function Multi_level_answers() {
  let navigate = useNavigate();
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const watchSOC = watch("soc");
  const [modal8, setModal8] = useState();
  const [modal, setModal] = useState(false);
  const [visible, setVisible] = useState(false);

  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdate, setDataForUpdate] = useState({ data: {} });
  const [mlaList, setMlaList] = useState({ data: [], loading: false });
  const [soc_category_list, setSoc_category_list] = useState({
    data: [],
    loading: false,
  });
  const [noc_category_list, setNoc_category_list] = useState({
    data: [],
    loading: false,
  });
  const [search, setSearch] = useState();

  const user = localStorage.getItem("user");

  const toggle8 = () => {
    // setModal8(!modal8);
    setVisible(!visible)

    if (visible === false || visible === undefined) {
      reset();
      fetchMLA();
    }
  };
  const toggle = () => {
    setModal(!modal);
    if (modal === false || modal === undefined) {
      reset();
    }
  };

  const initialState = {
    loading: false,
    error: "",
    mla_list: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          mla_list: action.mla_list,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      mla_list: state.mla_list,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetch_mla_list();
  }, [state.page, state.itemsPerPage]);

  useEffect(() => {
    fetch_SOC_category_lists();
    fetch_NOC_Category();
  }, []);

  useEffect(() => {
    console.log("watchSOC", watchSOC);
  }, [watchSOC]);

  function fetchMLA() {
    setMlaList({ ...mlaList, loading: true, data: [] });
    get_mla({ customer: customer_id }).then(
      (res) => {
        console.log("res_mla", res);
        setMlaList({ ...mlaList, loading: false, data: res.data.payload });
      },
      (err) => {
        console.log("err", err);
        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  const fetch_mla_list = (para1) => {
    console.log(para1, "search", search)
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      mla_list: state.mla_list,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      page: state.page,
      entriesPerPage: state.itemsPerPage,
      customer: customer_id,
    };

    if (para1 || search) {
      reqPayload = {
        page: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para1 == undefined ? search : para1,
        customer: customer_id,
      };
    }
    console.log("pay", reqPayload)

    get_mla(reqPayload).then(
      (res) => {
        console.log("res_mla    ", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.total));
        setTotalRecords(res.data.total);
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          mla_list: res.data.payload,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            (para1 !== "" && search !== "")
              ? calMaxPage(res.data.total)
              : calMaxPage(res.data.total),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  };

  // fetching parent soc category
  function fetch_SOC_category_lists() {
    var reqPayload = {
      customer: customer_id,
    };

    get_soc_category(reqPayload).then(
      (res) => {
        console.log("res_soc    ", res);
        setSoc_category_list({ data: res.data.payload, loading: false });
      },
      (err) => {
        console.log("err", err);
        setSoc_category_list({ data: [], loading: true });
      }
    );
  }

  //  feching noc_category

  function fetch_NOC_Category() {
    var reqPayload = {
      customer: customer_id,
    };

    get_noc_category(reqPayload).then(
      (res) => {
        console.log("res_soc    ", res);
        setNoc_category_list({ data: res.data.payload, loading: false });
      },
      (err) => {
        console.log("err", err);

        setNoc_category_list({ data: [], loading: true });
      }
    );
  }
  // Deleting mla
  const del_mla = (val) => {
    console.log("val", val);
    console.log("state.mla_list", state.mla_list);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        delete_mla({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("MLA deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            if (state.mla_list.length == 1 && state.page !== 1) {
              dispatch({
                type: HANDLE_ACTION,
                page: state.page - 1,
                loading: state.loading,
                error: state.error,
                mla_list: state.mla_list,
                itemsPerPage: state.itemsPerPage,
                maxPage: state.maxPage,
              });
              setSrNo((prevC) => prevC - 10);
            } else {
              fetch_mla_list();
            }
          },
          (err) => {
            console.log("err", err);
            console.log("err.response", err.response);
            toast.error(err.response.data.message, {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const payload = {
        srno: data.srno,
        soc: data.soc,
        socCategoryId:
          data.socCategory !== undefined ? data.socCategory[0].Id : null,
        parent: data.parent ? data.parent[0].description : null,
        hasParent: data.parent ? true : false,
        parentId: data.parent ? data.parent[0].Id : null,
        photo: data.photo,
        comments: data.comments,
        description: data.name,
        sequence: data.sequence,
        customer: customer_id
      };

      console.log("payloadalnsd", payload);
      post_mla(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("MLA created successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          //  toggle8();
          setVisible(false);
          fetch_mla_list();
        },
        (err) => {
          console.log("err.response", err.response);
          if (err.response.status == 400) {
            toast.error(err.response.data.message, {
              autoClose: 3000,
              theme: "light",
            });
            return;
          }
          toast.error(err.response.data.message, {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Edit_Multi_level_answers
        modal={modal}
        toggle={toggle}
        data={dataForUpdate.data}
        soc_list={soc_category_list.data}
        fetch_mla_list={fetch_mla_list}
      />


      <div className="vdi_mla_list py-4 fontFamily">
        <div className="card">
          <div className="title bg-primary-blue" id="card_title">
            <p className="mb-0 f-24">Multi Level Answers </p>
          </div>
          <div className="card-body">
            {/* This is for search field */}
            <div className="d-flex justify-content-between mb-4">
              <div >
                <input placeholder="Search" className="form-control"
                  onChange={(e) => {
                    setSearch(e.target.value)
                    fetch_mla_list(e.target.value)

                  }}
                />
              </div>

              <div >
                <button
                  onClick={() => {
                    toggle8();
                    setVisible(true);
                  }}
                  style={{ fontSize: "16px " }}
                  className="btn btn-primary-blue"
                >
                  Create New MLA
                </button>
              </div>
            </div>


            {/* <div className="d-flex justify-content-end mb-4">
              <button
                onClick={()=>{
                  toggle8();
                  setVisible(true);
                }}
                style={{ fontSize: "16px " }}
                className="btn btn-primary-blue"
              >
                Add
              </button>
            </div> */}

            {state.loading ? (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            ) : state.mla_list.length > 0 ? (
              <div className="table-responsive " style={{ maxHeight: "70vh" }}>
                <table className="table table-striped table-bordered">
                  <thead className="thead-light">
                    <tr
                      className="f-18"
                      style={{
                        position: "sticky",
                        top: "0",
                        background: "white",
                      }}
                    >
                      {/* <th scope="col">{"#"}</th> */}
                      <th scope="col">{"Sr.No"}</th>
                      {/* <th scope="col">{"Sequence"}</th> */}
                      <th scope="col">{"Description"}</th>
                      <th scope="col">{"Parent"}</th>
                      <th scope="col">{"SOC"}</th>
                      <th scope="col">{"Photo"}</th>
                      <th scope="col">{"Comments"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.mla_list.map((val, i) => (
                      <tr key={i} className="f-16">
                        {/* <td>{i + 1 + srNo}</td> */}
                        <td>{val.srno}</td>
                        {/* <td>{val.sequence}</td> */}
                        <td>{val.description}</td>
                        <td>{!val.parent ? "NA" : val.parent}</td>
                        <td>{val.soc ? "Yes" : "No"}</td>
                        <td>{val.photo}</td>
                        <td>{val.comments}</td>
                        <td>
                          <Edit
                            onClick={() => {
                              dataForUpdate.data = val;
                              console.log("val", val);
                              setDataForUpdate({
                                ...dataForUpdate,
                                data: dataForUpdate.data,
                              });
                              toggle();
                            }}
                            style={{ color: "#313131" }}
                            id={"editToolTip" + i}
                            className="cursor_pointer "
                            height={"18px"}
                            width={"18px"}
                          />

                          <UncontrolledTooltip
                            placement="bottom"
                            target={"editToolTip" + i}
                          >
                            {"Edit MLA"}
                          </UncontrolledTooltip>

                          <Trash2
                            onClick={() => del_mla(val)}
                            id={"deleteToolTip" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"deleteToolTip" + i}
                          >
                            {"Delete MLA"}
                          </UncontrolledTooltip>

                          {
                            !val.parent ? <>
                              <Tree_view
                                object={val}
                                soc_list={soc_category_list}
                                mlaList={mlaList}
                                fetch_mla_list={fetch_mla_list}
                              />
                            </>

                              : <></>


                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : apiBySearch ? (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"No Multi Level Answer List Found !"}
              </p>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"Multilevel Answer List is empty"}
              </p>
            )}

            {state.loading ? null : (
              <div className="mt-4">
                <Pagination
                  aria-label="Page navigation"
                  className="pagination justify-content-end pagination-primary"
                >
                  <PaginationItem disabled={state.page === 1 ? true : false}>
                    <PaginationLink
                      className="cursor_pointer"
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page - 1,
                          loading: state.loading,
                          error: state.error,
                          mla_list: state.mla_list,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC - 10);
                      }}
                    >
                      {"Previous"}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem active>
                    <PaginationLink>{state.page}</PaginationLink>
                  </PaginationItem>

                  <PaginationItem
                    disabled={
                      state.page === state.maxPage || state.maxPage === 0
                        ? true
                        : false
                    }
                  >
                    <PaginationLink
                      last
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page + 1,
                          loading: state.loading,
                          error: state.error,
                          mla_list: state.mla_list,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC + 10);
                      }}
                      className="cursor_pointer"
                    >
                      {"Next"}
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>

                {/* <p className='f-14 text-muted float-end mt-1'>{`Total Records: ${totalRecords}`}</p> */}
                <div>
                  <div className="d-flex justify-content-end">
                    <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal show={visible} onHide={() => setVisible(false)} size="lg" backdrop="static">
        <Modal.Header className="bg-primary-blue" closeButton>

          <Modal.Title >{"Create New Multilevel Answer "}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Sr. No"}</label>
              <input
                className={
                  errors.srno ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`srno`}
                {...register(`srno`)}
                placeholder="Enter Sr. No.*"
              />

              <span className="text-danger">{errors.srno?.message}</span>
            </div>

            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Sequence No."}</label>
              <input
                className={
                  errors.sequence ? "form-control is-invalid" : "form-control"
                }
                type="number"
                name={`sequence`}
                {...register(`sequence`)}
                placeholder="Enter Sequence No.*"
              />

              <span className="text-danger">{errors.sequence?.message}</span>
            </div>

            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Description"}</label>
              {/* <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                placeholder="Enter Description*"
              /> */}

              <textarea
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                placeholder="Enter Description*"
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.name?.message}</span>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="f-w-600">{"Photo"}</label>
                <Controller
                  name="photo"
                  control={control}
                  defaultValue="Optional"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue="Optional"
                      className={
                        errors.photo
                          ? " form-control is-invalid"
                          : " form-control"
                      }
                    >
                      <option select="">{"--Select*--"}</option>
                      <option value="NR">{"Not Required"}</option>
                      <option value="Optional">{"Optional"}</option>
                      <option value="Mandatory">{"Mandatory"}</option>
                    </select>
                  )}
                />

                <span className="text-danger">{errors.photo?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="f-w-600">{"Comments"}</label>
                <Controller
                  name="comments"
                  control={control}
                  defaultValue="Optional"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue="Optional"
                      className={
                        errors.comments
                          ? " form-control is-invalid"
                          : " form-control"
                      }
                    >
                      <option select="">{"--Select*--"}</option>
                      <option value="NR">{"Not Required"}</option>
                      <option value="Optional">{"Optional"}</option>
                      <option value="Mandatory">{"Mandatory"}</option>
                    </select>
                  )}
                />

                <span className="text-danger">{errors.comments?.message}</span>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="f-w-600">{"SOC"}</label>

                <select
                  {...register("soc")}
                  // defaultValue="true"
                  className={
                    errors.soc ? " form-control is-invalid" : " form-control"
                  }
                >
                  <option select="">{"Select Option"}</option>
                  <option value="true">{"Yes"}</option>
                  <option value="false">{"No"}</option>
                </select>

                <span className="text-danger">{errors.soc?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Select Parent"}</label>
              <Controller
                name="parent"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead"
                    labelKey="description"
                    multiple={false}
                    options={mlaList.data}
                    loading={mlaList.loading}
                    emptyLabel={
                      [].length > 0
                        ? "No matches found."
                        : "No Parents to select"
                    }
                    placeholder="Select Parent"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"SOC Category"}</label>
              <Controller
                name="socCategory"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-soc-cat"
                    labelKey="categoryName"
                    // disabled={selectValue === "No"}
                    multiple={false}
                    options={soc_category_list.data}
                    loading={soc_category_list.loading}
                    placeholder="Select SOC Category"
                    onChange={onChange}
                    onBlur={onBlur}
                    // selected={value}
                    disabled={
                      watchSOC == "false" || watchSOC == undefined
                        ? true
                        : false
                    }
                    isInvalid={errors.socCategory ? true : false}
                  />
                )}
              />

              <span className="text-danger">{errors.socCategory?.message}</span>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Multi_level_answers;
