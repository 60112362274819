import { CLOSE, OPEN, OPEN_CLOSE } from "../actionTypes"

export const openClose = () => {
    return ({
        type: OPEN_CLOSE,
    })
}
export const open = () => {
    return ({
        type: OPEN,
    })
}
export const close = () => {
    return ({
        type: CLOSE,
    })
}