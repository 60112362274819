import React, { useState, useEffect } from "react";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal, } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import {
  getVesselTypes,
  deleteVesselFile,
  updateVessel,
} from "../../../../api/index";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { FileText, X } from "react-feather";
import SweetAlert from "sweetalert2";

const schema = yup
  .object()
  .shape({
    uniqueNumber: yup.string()
      .matches(/^[a-zA-Z0-9\s]*$/, "Only alphanumeric value are allowed !"),
    vesselIMONO: yup.string()
      .required("IMO No is required !")
      .test('isNumeric', 'Only Numeric value are allowed ', value => /^[0-9]+$/.test(value))
      .test('notStartingWithZero', 'The number should not start with zero', value => !/^0/.test(value))
      ,
    vesselName: yup
      .string()
      .max(50, "50 characters allowed !")
      .required("Vessel name is required !"),
    vesselType: yup
      .array()
      .min(1, "Please select vessel type !")
      .nullable()
      .required("Please select vessel type !"),
    GT: yup
    .string()
    .test('match', 'Please enter valid GT', function (value) {
      return /^(?!0)\d*(\.\d+)?$/.test(value); 
    }),
    vesselInfo: yup.string().notRequired(),
    status: yup
      .string()
      .nullable()
      .required("Status is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
  })
  .required();

var FormData = require("form-data");

function EditVesselForm(props) {
  const [vesselFiles, setVesselFiles] = useState([]);
  const [vesselType, setVesselType] = useState({ data: [], loading: false });

  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    console.log("props.modal8", props.modal8);

    if (props.modal8 === true) {
      reset();
      setValue("vesselType",props.data?.type?[props.data.type]:[]);
      setValue("status",props.data?.status==true?"Active":"Inactive")
    }
  }, [props.modal8]);

  const handleChangeStatus = ({ meta, file }, status) => {
    console.log("meta", meta);
    console.log("file", file);
    console.log("status", status);

    if (status === "done") {
      setVesselFiles([...vesselFiles, { id: meta.id, file: file }]);
    } else if (status === "removed") {
      console.log("vesselFiles", vesselFiles);

      setVesselFiles((curr) =>
        curr.filter((file) => {
          return file.id !== meta.id;
        })
      );
    }
  };

  const onSubmit = (data) => {
    if (data !== "") {
      // console.log("data", data);

      console.log("vesselFiles", vesselFiles);
      console.log("props.data", props.data);

      const reqPayload = {
        number: data.vesselIMONO,
        uniqueNumber: data.uniqueNumber,
        type_id: data.vesselType[0].Id,
        name: data.vesselName,
        GT: data.GT,
        status: data.status === "Active" ? true : false,
        customer_id: customer_id,
        fileCount: vesselFiles.length,
        Id: props.data.Id,
      };

      if (vesselFiles.length === 0) {
        delete reqPayload.fileCount;
      } else {
        for (let i = 0; i < vesselFiles.length; i++) {
          reqPayload["file" + (parseInt(i) + 1)] = vesselFiles[i].file;
        }
      }

      // for (let i = 0; i < vesselFiles.length; i++) {
      //     reqPayload["file" + (parseInt(i) + 1)] = vesselFiles[i].file;
      // }

      console.log("reqPayload", reqPayload);

      var formdata = new FormData();

      for (var key in reqPayload) {
        formdata.append(key, reqPayload[key]);
      }

      updateVessel(formdata).then(
        (res) => {
          console.log("res", res);
          toast.success("Vessel updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          props.toggle8();
          props.fetchVessels();
          setVesselFiles([]);
        },
        (err) => {
          console.log("err", err);
          if(err.response==undefined){
            toast.error("File size is too large !", {
              autoClose: 3000,
              theme: "light",
            });

          }else{
            if (err.response.status === 400) {
              let message=err.response.data.message;
                  if(err.response.data.message==="uniqueNumber unique required"){
                    message="This unique no. already present !"
                  }
                  
                  toast.error(`${message}`, {
                    autoClose: 3000,
                    theme: "light",
                  });
            } else {
              toast.error("Something went wrong !", {
                autoClose: 3000,
                theme: "light",
              });
            }
          }
         
        }
      );
    } else {
      errors.showMessages();
    }
  };

  useEffect(() => {
    console.log("props.data", props.data);
  }, [props.data]);

  function createFileName(val) {
    var temp = val;

    var res = temp.split("/");
    return res[2];
  }

  function delVesselFile(val) {
    console.log("val", val);
    console.log("props.data.Id", props.data.Id);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteVesselFile({ Id: props.data.Id, fileId: val.id }).then(
          (res) => {
            console.log("res", res);
            toast.success("File deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            props.toggle8();
            props.fetchVessels();
          },
          (err) => {
            console.log("err ", err);
            toast.error("Something went wrong !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  }

  useEffect(() => {
    setVesselType({ ...vesselType, data: [], loading: true });
    getVesselTypes().then(
      (res) => {
        console.log("res", res);
        setVesselType({ ...vesselType, data: res.data, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <Modal
        // isOpen={props.modal8}
        toggle={props.toggle8}
        show={props.modal8}
        onHide={() => props.toggle8()}
        size="lg"
        backdrop="static"
      >
        <Modal.Header className="bg-primary-blue" closeButton>

          <Modal.Title ><p className="f-22 f-w-400 mb-0">{"Update Vessel"}</p></Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"IMO No"}</label>
                  <input
                    className={
                      errors.vesselIMONO
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter Vessel IMO No*"
                    name="vesselIMONO"
                    {...register("vesselIMONO")}
                    defaultValue={props.data?.number}
                  />

                  <span className="text-danger">
                    {errors.vesselIMONO?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Unique No"}</label>
                  <input
                    className={
                      errors.uniqueNumber
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter Unique No*"
                    name="uniqueNumber"
                    {...register("uniqueNumber")}
                    defaultValue={
                      props.data?.uniqueNumber === null
                        ? ""
                        : props.data?.uniqueNumber
                    }
                  />

                  <span className="text-danger">
                    {errors.uniqueNumber?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Vessel Name"}</label>
                  <input
                    className={
                      errors.vesselName
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter vessel name*"
                    name="vesselName"
                    {...register("vesselName")}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">
                    {errors.vesselName?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Vessel Type"}</label>
                  <Controller
                    name="vesselType"
                    control={control}
                    defaultValue={
                      props.data === null
                        ? []
                        : [
                          {
                            name: props.data?.type.name,
                            Id: props.data?.type.Id,
                          },
                        ]
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={vesselType.data}
                        isLoading={vesselType.loading}
                        placeholder="Select vessel type"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        defaultSelected={
                          props.data === null
                            ? []
                            : [
                              {
                                name: props.data?.type.name,
                                Id: props.data?.type.Id,
                              },
                            ]
                        }
                        isInvalid={errors.vesselType ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.vesselType?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"GT"}</label>
                  <input
                    className={
                      errors.GT
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter GT*"
                    name="GT"
                    {...register("GT")}
                    defaultValue={props.data?.GT}
                  />

                  <span className="text-danger">{errors.GT?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Status"}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue={
                      props.data?.status === true ? "Active" : "Inactive"
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={
                          props.data?.status === true ? "Active" : "Inactive"
                        }
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.status
                            ? "custom-select form-control is-invalid f-16"
                            : " f-16 custom-select form-control"
                        }
                      >
                        <option select="">{"Select*"}</option>
                        <option value="Active">{"Active"}</option>
                        <option value="Inactive">{"Inactive"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>

              <div className="col-12">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Vessel Info"}</label>

                  <Dropzone
                    // getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    multiple={true}
                    canCancel={false}
                    inputContent="Drop A File"
                  />

                  {props.data?.files.length > 0 ? (
                    <div
                      className="mt-4 p-4 shadow-sm"
                      style={{
                        border: "2px dashed #4f4f4e",
                        background: "#f0f0f0",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="row">
                        {props.data?.files.map((val, i) => (
                          <div className="col-12 col-md-3" key={i}>
                            <div
                              className="shadow-sm text-center"
                              style={{
                                background: "white",
                                borderRadius: "18px",
                                border: "1px solid #c6c6c6",
                              }}
                            >
                              <X
                                onClick={() => delVesselFile(val)}
                                className="mb-2 d-block ms-auto mt-1 mr-1  cursor_pointer"
                                height={"20px"}
                                width={"20px"}
                              />

                              <a href={val.url}>
                                <FileText height={"40px"} width={"40px"} />
                              </a>

                              <p
                                style={{
                                  wordWrap: "break-word",
                                  lineHeight: "1.2",
                                }}
                                className="f-10 p-2 text-muted mb-0"
                              >
                                {createFileName(val.file)}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            style={{ fontSize: "16px " }}
            className="btn btn-primary-blue float-right"
          >
            {"Save Changes"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditVesselForm;
