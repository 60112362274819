import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import HtmlToPdf from "./HtmlToPdf";
import Loader from "../../common/loader";
import { toast } from "react-toastify";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const Show_report = () => {
  const loadPdf = () => {
    const file = new Blob([pdfUrl], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    window.open(fileUrl);
  };

  const data_1 = useLocation();
  console.log("data_1", data_1.state)

  const { id } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [loader, setLoader] = useState(true);

  const [pdfUrl, setPdfData] = useState(null);
  const localData = JSON.parse(localStorage.getItem("login_data"));
  const token = localStorage.getItem("access");
  const base64 = btoa(token);
  const env = `${process.env.REACT_APP_ENV}`;
  // console.log(token);
  //console.log(localData, env);
  const x = `${process.env.REACT_APP_REPORT_URL}`;

  const y = `${process.env.REACT_APP_SIRE_REPORT_URL}`;
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const Get_report = async () => {

    let url;
    let data = {
      env: env,
      customer_id: localData.customer_id,
      survey_id: id,
      authToken: base64,
    };

    if (data_1.state.surveyType === "Sire") {

      url = y;
    }else{
      url=x;
    }

    axios.post(url, data, { responseType: "arraybuffer" }).then(
      (res) => {
        console.log("res", res);
        if (res.status === 200 || res.status === 201) {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // const pdfData = new Blob([res.data], { type: 'application/pdf' });
          const pdfData = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(pdfData);
          setPdfData(pdfUrl);
          setLoader(false);
        }
      },
      (err) => {
        console.log("err.response.status", err.response.status);
        toast.error("Error in generating report", {
          autoClose: 2000,
          theme: "light",

        });
        if (err.response.status === 400) {
          console.log("err.response", err.response);
        }
      }
    );
  };


  // const Get_report = async () => {
  //   const data = {
  //     customer_id: localData.customer_id,
  //     survey_id: id,
  //     authToken: base64,
  //   };

  //   axios.post(url2, data, { responseType: "arraybuffer" }).then(
  //     (res) => {
  //       console.log("res", res);
  //       if (res.status === 200 || res.status === 201) {
  //         const pdfBlob = new Blob([res.data], { type: "application/pdf" });
  //         // const pdfData = new Blob([res.data], { type: 'application/pdf' });
  //         const pdfData = new Blob([res.data], { type: "application/pdf" });
  //         const pdfUrl = URL.createObjectURL(pdfData);
  //         setPdfData(pdfUrl);
  //         setLoader(false);
  //       }
  //     },
  //     (err) => {
  //       console.log("err.response.status", err.response.status);
  //       toast.error("Error in generating report", {
  //         autoClose: 2000,
  //         theme: "light",

  //       });
  //       if (err.response.status === 400) {
  //         console.log("err.response", err.response);
  //       }
  //     }
  //   );
  // };



  const handleclick = () => {
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = `${data_1.state.surveyNo}_${id}.pdf`;
    anchor.click();
  };
  useEffect(async () => {
    await Get_report();
  }, []);
  return (
    <>
      <div className="surveyReportPreview py-4">
        <div className="card">
          <div className="title bg-primary-blue" id="card_title">
            <p className="mb-0 f-24">Survey Report Preview</p>
          </div>
          <div className="card-body">
            {/* {pdfUrl && (
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{ workerSrc: '/pdf.worker.js' }}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            )} */}

            {loader && (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            )}
            <iframe src={pdfUrl} width="100%" height="700px"></iframe>
            <div className="d-flex justify-content-end">
              {
                loader ? (<button
                  className="btn btn-primary-blue mt-4 "

                >
                  {"Generating Report"}
                </button>) : (<button
                  className="btn btn-primary-blue mt-4 "
                  onClick={handleclick}
                >
                  {"Download Report"}
                </button>)
              }
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};
