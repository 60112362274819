import React, { useState, useEffect } from "react";
import vedam from '../../assets/images/Vedam/vedamL.png'

const Footer = props => {
    const [currentYear, setCurrentYear] = useState('');
    useEffect(() => {
        const currentDate = new Date();
        const cr = currentDate.getFullYear();
        console.log(cr);
        setCurrentYear(cr);
    }, []);

    return (
        <footer className="footer" style={{ position: 'relative' }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 footer-copyright text-center">
                        <p className="mb-0 ">{`Copyright ${currentYear} © Vedam All rights reserved. (version ${process.env.REACT_APP_BUILD_VERSION})`}</p>
                    </div>
                    {/* <div className="col-md-6">
                        <p className="pull-right mb-0">{"Hand crafted & made with"}
                            <i className="fa fa-heart"></i>
                        </p>
                    </div> */}
                </div>
            </div>

            <div className='d-flex me-2 d-none d-sm-none d-md-none d-lg-flex' id="footerCompanyMarks">
                <p className='f-16 mb-0' style={{ color: 'black' }}>Powered By </p>
                <img src={vedam} className='ms-1' style={{ height: '20px', width: '85px' }} />
            </div>

            <div className='d-flex me-2 d-lg-none mt-2 justify-content-center' id="">
                <p className='f-16 mb-0' style={{ color: 'black' }}>Powered By </p>
                <img src={vedam} className='ms-1' style={{ height: '20px', width: '85px' }} />
            </div>
        </footer>
    )
};

export default Footer;