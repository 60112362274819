import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {Modal as ModalS} from "react-bootstrap"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  get_mla,
  createCheckListSection,
  getCheckListSection,
  createChecklistItem,
  getChecklistItem,
  deleteChecklistSection,
  deleteChecklistSecItem,
  generateAgoraToken,
  delChecklistItemAttachment,
} from "../../../../api/index";
import { useLocation } from "react-router-dom";
import {
  Trash2,
  Edit,
  Phone,
  Upload,
  Clipboard,
  Image,
  Film,
  FileText,
  XCircle,
} from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import UpdateSection from "../ChecklistManagementNew/UpdateSection";
import { toast } from "react-toastify";
import UpdateItems from "./UpdateItems";
import SweetAlert from "sweetalert2";
import VideoCall from "../../Agora/VideoCall";
import {
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import "../../Agora/index.css";
import { useNavigate } from "react-router-dom";
import UploadFile from "./UploadFile";
import AnswerScreen from "./AnswerScreen";
import ImagePreview from "../../Common/Components/ImagePreview";
import FormElements from "./FormElements";
import Update_sire_items from "./Update_sire_items";
import { localeData } from "moment";

var config = ClientConfig;
config = {
  mode: "rtc",
  codec: "vp8",
};

const appId = `${process.env.REACT_APP_AGORA_APPID}`; //ENTER APP ID HERE


const schema = yup
  .object()
  .shape({
    sectionName: yup
      .string()
      .max(300, "300 characters allowed !")
      .required("Name is required !"),
    sectionId: yup.string().required("Sr.No is required !"),
    sequence: yup
      .string()
      .required("Sequence is required !")
      .matches(/^[0-9]*$/, "Only Integer Allowed !"),
  })
  .required();

const Itemschema = yup
  .object()
  .shape({
    SrNo: yup.string().required("Required !"),
    sequence: yup
      .string()
      .required("Required !")
      .matches(/^[0-9]*$/, "Only Integer Allowed !"),
    section: yup
      .string()
      .max(300, "300 characters allowed !")
      .required("Required !")
      .matches(/^((?!Select).)*$/, "Required !"),
    sectionName: yup.string()
    .required("Required !"),
    comments: yup
      .string()
      .nullable()
      .required("Required !")
      .matches(/^((?!Select).)*$/, "Required !"),
    photos: yup
      .string()
      .nullable()
      .required("Required !")
      .matches(/^((?!Select).)*$/, "Required !"),
    videos: yup
      .string()
      .nullable()
      .required("Required !")
      .matches(/^((?!Select).)*$/, "Required !"),
    OG: yup
      .string()
      .nullable()
      .required("Required !")
      .matches(/^((?!Select).)*$/, "Required !"),
  })
  .required();
const Itemsschems_for_sire = yup
  .object()
  .shape({
    SrNo: yup.string().required("Required !"),
    sequence: yup
      .string()
      .required("Required !")
      .matches(/^[0-9]*$/, "Only Integer Allowed !"),
    section: yup
      .string()
      .required("Required !")
      .max(300, "300 characters allowed !")
      .matches(/^((?!Select).)*$/, "Required !"),
    sectionName: yup.string()
    .max(300, "300 characters allowed !")
    .required("Required !"),
  })
  .required();
function AddSectionAndItems() {
  const [survey_type, setSurvey_tye] = useState(false)
  const location = useLocation();

  // console.log("survery type", location.state.surveyType)
  let navigate = useNavigate();

  const [sectionModal, setSectionModal] = useState();
  const [updateSectionModal, setUpdateSectionModal] = useState();

  const [updationDataForSection, setUpdationDataForSection] = useState("");

  const [updateItemModal, setUpdateItemModal] = useState();
  const [dataForUpdate, setDataForUpdate] = useState({ data: "" });

  const customer_id = localStorage.getItem("customer_id");

  const [sectionList, setSectionList] = useState({ loading: false, data: [] });
  const [checklistItems, setChecklistItems] = useState({ data: [] });
  const [isSectionSelected, setIsSectionSelected] = useState(false);
  const [dataChecklist, setDataChecklist] = useState({});


  const toggle8 = () => {
    setSectionModal(!sectionModal);

    if (sectionModal === false || sectionModal === undefined) {
      reset();
    }
  };

  function setSectionSeleted(val) {
    setIsSectionSelected(val);
  }

  const toggleUpdateSectionModal = () => {
    setUpdateSectionModal(!updateSectionModal);
  };

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(async () => {
    //console.log("location", location);
    if (location.state.surveyType == "Sire") {
      setSurvey_tye(true)
    }
    await fetchChecklistSections();
  }, []);

  function fetchChecklistSections() {
    setSectionList({ ...sectionList, loading: true, data: [] });

    getCheckListSection({ checklist: location.state.Id }).then(
      (res) => {
        console.log("res", res);
        setSectionList({ ...sectionList, loading: false, data: res.data });

        console.log("selectedSection", localStorage.getItem("selectedSection"));
        if (localStorage.getItem("selectedSection")) {
          if (res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              if (
                res.data[i].id ===
                parseInt(localStorage.getItem("selectedSection"))
              ) {
                setChecklistItems({
                  ...checklistItems,
                  data: res.data[i].section_checklist_item,
                });
                document.getElementById("section_row" + i).style.background =
                  "#B1E1FF";
              } else {
                document
                  .getElementById("section_row" + i)
                  .style.removeProperty("background");
              }
            }
          } else {
            setChecklistItems({ ...checklistItems, data: [] });
          }
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      const reqPayload = data;
      reqPayload.checklist = location.state.Id;
      console.log("reqPayload", reqPayload);

      createCheckListSection(reqPayload).then(
        (res) => {
          console.log("res", res);
          toast.success("Section created successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          toggle8();
          // setSectionSeleted(false);
          fetchChecklistSections();
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function selectSection(val, i) {
    console.log("val", val);
    console.log("i", i);
    console.log("sectionList.data", sectionList.data);

    setSectionSeleted(true);
    setDataChecklist(val);

    setChecklistItems({ ...checklistItems, data: val.section_checklist_item });

    localStorage.setItem("selectedSection", val.id);
    localStorage.setItem("selectedSectionName", val.sectionName);
    localStorage.setItem("selectedSectionId", val.sectionId);

    for (let j = 0; j < sectionList.data.length; j++) {
      console.log("j", j);
      if (i === j) {
        document.getElementById("section_row" + i).style.background = "#B1E1FF";
      } else {
        document
          .getElementById("section_row" + j)
          .style.removeProperty("background");
      }
    }
  }

  function deleteSection(val) {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteChecklistSection({ id: val.id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Section deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            // setSectionSeleted(false);
            fetchChecklistSections();
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    });
  }

  return (
    <React.Fragment>
      <UpdateSection
        checkListId={location.state.Id}
        toggleUpdateSectionModal={toggleUpdateSectionModal}
        updateSectionModal={updateSectionModal}
        fetchChecklistSections={fetchChecklistSections}
        data={updationDataForSection}
      // setSectionSeleted={setSectionSeleted}
      />

      {/* <UpdateItems
                dataChecklist={dataChecklist}
                toggleUpdateModal={toggleUpdateModal}
                updateItemModal={updateItemModal}
                data={dataForUpdate.data}
                fetchChecklistSections={fetchChecklistSections}
                checkListId={location.state.Id}
            /> */}

      <div className="checklistSectionItems py-4">
        <div className="card">
          <div className="title bg-primary-blue" id="card_title">
            <p className="mb-0 f-24">
              <span
                onClick={() => {
                  navigate(-1);
                }}
                className="text-light me-2"
                style={{ fontSize: "20px", cursor: "pointer" }}
              >
                <i className="fa fa-arrow-circle-left"></i>
              </span>
              Add Section And Items
            </p>
          </div>
          <div className="card-body">
            {/* {location.state.isForm === true ?
                            <FormElements checklistId={location.state.Id} />
                            : null}

                        <hr /> */}
            <div className="d-flex justify-content-between">
              <p className="f-18 f-w-600 mb-0">Section List</p>

              <button onClick={toggle8} className="btn btn-primary-blue">
                Create Section
              </button>
            </div>
            <hr />
            {sectionList.loading ? (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            ) : sectionList.data.length > 0 ? (
              <div className="table-responsive " id="Checklist_section_table">
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr className="f-18">
                      <th scope="col">{"Sr.No"}</th>
                      <th scope="col">{"Sequence"}</th>
                      <th scope="col">{"Section Name"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sectionList.data.map((val, i) => (
                      <tr
                        key={i}
                        id={"section_row" + i}
                        className="f-16 cursor_pointer"
                        onClick={() => selectSection(val, i)}
                      >
                        <td disabled style={{ width: "150px" }}>
                          {val.sectionId}
                        </td>
                        <td disabled style={{ width: "150px" }}>
                          {val.sequence}
                        </td>
                        <td disabled>{val.sectionName}</td>
                        <td style={{ width: "150px" }}>
                          <Edit
                            onClick={() => {
                              toggleUpdateSectionModal();
                              setUpdationDataForSection(val);
                            }}
                            style={{ color: "#313131" }}
                            id={"editToolTip" + i}
                            className="cursor_pointer "
                            height={"18px"}
                            width={"18px"}
                          />

                          <UncontrolledTooltip
                            placement="bottom"
                            target={"editToolTip" + i}
                          >
                            {"Edit Record"}
                          </UncontrolledTooltip>

                          <Trash2
                            id={"deleteToolTip" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                            onClick={() => {
                              deleteSection(val);
                            }}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"deleteToolTip" + i}
                          >
                            {"Delete Record"}
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"Section list is empty"}
              </p>
            )}

            {location.state.surveyType == "Sire" ? <Item_for_sire
              checklistItems={checklistItems}
              sectionList={sectionList}
              fetchChecklistSections={fetchChecklistSections}
              dataChecklist={dataChecklist}
              isSectionSelected={isSectionSelected}
              survey_type={survey_type}

            /> : <Item
              checklistItems={checklistItems}
              sectionList={sectionList}
              fetchChecklistSections={fetchChecklistSections}
              dataChecklist={dataChecklist}
              isSectionSelected={isSectionSelected}
              survey_type={survey_type}
            // setSectionSeleted={setSectionSeleted}
            />
            }
          </div>
        </div>
      </div>

      <Modal isOpen={sectionModal} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader toggle={toggle8} className="bg-primary-blue">
          {"Create Section"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Sr.No"}</label>
              <input
                className={
                  errors.sectionId ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`sectionId`}
                {...register(`sectionId`)}
                placeholder="Enter Sr.No*"
              />

              <span className="text-danger">{errors.sectionId?.message}</span>
            </div>

            <div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Sequence"}</label>
              <input
                className={
                  errors.sequence ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`sequence`}
                {...register(`sequence`)}
                placeholder="Sequence*"
              />

              <span className="text-danger">{errors.sequence?.message}</span>
            </div>

            <div className="col-12 col-md-12">
              <label className="f-w-600 f-14">{"Section Name"}</label>
              <textarea
                className={
                  errors.sectionName
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                name={`sectionName`}
                {...register(`sectionName`)}
                placeholder="Name*"
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.sectionName?.message}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Create Section
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

function Item(props) {
  const [inCall, setInCall] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [token, setToken] = useState({ data: "" });
  const useClient = createClient(config);
  let navigate = useNavigate();
  const location = useLocation();
  const [ItemModal, setItemModal] = useState();
  const [dataForUpdate, setDataForUpdate] = useState({ data: "" });
  const [updateItemModal, setUpdateItemModal] = useState();
  const [fileUploadModal, setFileUploadModal] = useState();
  const [mlaList, setMlaList] = useState({
    data: [],
    loading: false,
  });
  const [answerModal, setAnswerModal] = useState();
  const [imageModal, setImageModal] = useState();

  const [dataForAttachmentUpload, setDataForAttachmentUpload] = useState("");
  const [dataForAnswer, setDataForAnswer] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [mla, setMla] = useState([]);
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(Itemschema),
    mode: "onChange",
  });


  let { errors } = formState;

  const toggleImageModal = (val) => {
    setImageModal(!imageModal);
    if (imageModal === undefined || imageModal === false) {
      setImageUrl(val);
    }
  };

  const toggleUpdateModal = () => {
    setUpdateItemModal(!updateItemModal);
  };

  const toggleFileUploadModal = (val) => {
    setDataForAttachmentUpload(val);
    setFileUploadModal(!fileUploadModal);
  };

  const toggleAnswerModal = (val) => {
    setDataForAnswer(val);
    setAnswerModal(!answerModal);
  };

  const toggle8 = () => {
    setItemModal(!ItemModal);

    if (ItemModal === false || ItemModal === undefined) {
      reset();
      setMlaList({ ...mlaList, loading: true, data: [] });
      console.log("customer_id", customer_id)
      get_mla({ hasParent: "False", customer: customer_id }).then(
        (res) => {
          console.log("res", res);
          setMlaList({ ...mlaList, loading: false, data: res.data.payload });
        },
        (err) => {
          console.log("err", err);
          setMlaList({ ...mlaList, loading: false, data: [] });
        }
      );
    }
  };

  async function startVideoCall(val) {
    console.log("val", val);
    console.log(localStorage.getItem("selectedSectionName"));

    const sectionName = localStorage.getItem("selectedSectionName");
    const user_id = parseInt(localStorage.getItem("user_id"));

    await generateAgoraToken({
      userId: user_id,
      channelName: sectionName + "_" + val.id,
    }).then(
      (res) => {
        console.log("res", res);
        // token = res.data;
        token.data = res.data;
        setToken({ ...token, data: token.data });

        setChannelName(sectionName + "_" + val.id);
        setInCall(true);
        // navigate(`${process.env.PUBLIC_URL}/customeradmin/videocall`,
        //     {
        //         state: {
        //             setInCall: setInCall,
        //             channelName: channelName,
        //             appId: appId,
        //             token: token,
        //             inCall: inCall
        //         }
        //     })
      },
      (err) => {
        console.log("err", err);
      }
    );

    console.log(token);
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      console.log("mla", mla);
      if (location.state.surveyType == "Sire") {
        if (mlaList.length == 0) {
          toast.error("Minimum One MLA is required !", {
            autoClose: 3000,
            theme: "light",
          });
          return;
        }
      }

      const reqpayload = {
        sequence: data.sequence,
        SrNo: data.SrNo,
        sectionName: parseInt(data.sectionName),
        checkList: parseInt(location.state.Id),
        section: data.section,
        OG: data.OG,
        comments: data.comments,
        photos: data.photos,
        videos: data.videos,
        MLA: mla
      };

      console.log("reqpayload", reqpayload);
      // console.log("location.state", location.state);

      createChecklistItem(reqpayload).then(
        (res) => {
          console.log("res", res);
          toggle8();
          toast.success("Item created successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          setMla([]);
          props.fetchChecklistSections();
          // props.setSectionSeleted(false);
        },
        (err) => {
          console.log("err", err);

          if (err.response.status === 400) {
            SweetAlert.fire({
              title: "Only Integer is allowed for SrNo",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Ok",
              cancelButtonText: "cancel",
              reverseButtons: true,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function deleteItem(val) {
    console.log("val", val);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteChecklistSecItem({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Item deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            // props.setSectionSeleted(false);
            props.fetchChecklistSections();
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    });
  }

  function deleteAttach(val) {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delChecklistItemAttachment({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Attachment deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            props.fetchChecklistSections();
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    });
  }

  return (
    <React.Fragment>
      <UpdateItems
        toggleUpdateModal={toggleUpdateModal}
        updateItemModal={updateItemModal}
        data={dataForUpdate.data}
        fetchChecklistSections={props.fetchChecklistSections}
        checkListId={location.state.Id}
        dataChecklist={props.dataChecklist}
        survey_type={props.survey_type}
      // setSectionSeleted={props.setSectionSeleted}
      />
      <UploadFile
        dataForAttachmentUpload={dataForAttachmentUpload}
        toggleFileUploadModal={toggleFileUploadModal}
        fetchChecklistSections={props.fetchChecklistSections}
        fileUploadModal={fileUploadModal}
      />

      {/* <AnswerScreen
                answerModal={answerModal}
                toggleAnswerModal={toggleAnswerModal}
                dataForAnswer={dataForAnswer}
            /> */}

      <ImagePreview
        toggleImageModal={toggleImageModal}
        imageModal={imageModal}
        imageUrl={imageUrl}
      />

      <hr />
      <div className="d-flex justify-content-between mt-4">
        <p className="f-18 f-w-600 mb-0">{`Items Of Section ${localStorage.getItem("selectedSectionId") !== null
          ? `- Sr.No. ${localStorage.getItem("selectedSectionId")}`
          : ""
          } `}</p>
        <button onClick={toggle8} className="btn btn-primary-blue">
          Create Items
        </button>
      </div>
      <hr />

      {props.checklistItems.data.length > 0 ? (
        <div className="table-responsive ">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr className="f-18">
                <th scope="col">{"SrNo"}</th>
                <th scope="col">{"Sequence"}</th>
                <th scope="col">{"Description"}</th>

                <th scope="col">{"Comments"}</th>
                <th scope="col">{"Photos"}</th>
                <th scope="col">{"Videos"}</th>
                <th scope="col">{"OG"}</th>
                <th scope="col">{"Attachments"}</th>
                <th scope="col" style={{ width: "120px" }}>
                  {"Action"}
                </th>
              </tr>
            </thead>
            <tbody>
              {props.checklistItems.data.map((val, i) => (
                <tr key={i} className="f-16">
                  <td scope="row">{val.SrNo === null ? "NA" : val.SrNo}</td>
                  <td>{val.sequence}</td>
                  <td>{val.section}</td>

                  <td>{val.comments === "Yes" ? "Compulsory" : "Optional"}</td>
                  <td>{val.photos === "Yes" ? "Compulsory" : "Optional"}</td>
                  <td>{val.videos === "Yes" ? "Compulsory" : "Optional"}</td>
                  <td>{val.OG === "Yes" ? "Compulsory" : "Optional"}</td>

                  <td className="">
                    <ul>
                      {val.check_list_attachment.length > 0
                        ? val.check_list_attachment.map((val, i) =>
                          val.withAnswer === false ? (
                            val.fileType === "Image" ? (
                              <React.Fragment key={i}>
                                <li
                                  id="attachmentbg"
                                  className="shadow-sm me-2 mb-2"
                                >
                                  <XCircle
                                    onClick={() => deleteAttach(val)}
                                    className="cursor_pointer"
                                    style={{
                                      color: "red",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      top: "-8px",
                                      right: "-8px",
                                    }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"del" + i}
                                  />

                                  <Image
                                    onClick={() => {
                                      toggleImageModal(val);
                                    }}
                                    className="cursor_pointer  "
                                    style={{ color: "#313131" }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"image" + i}
                                  />
                                </li>
                              </React.Fragment>
                            ) : val.fileType === "Video" ? (
                              <React.Fragment key={i}>
                                <li
                                  id="attachmentbg"
                                  className="shadow-sm me-2 mb-2"
                                >
                                  <XCircle
                                    onClick={() => deleteAttach(val)}
                                    className="cursor_pointer"
                                    style={{
                                      color: "red",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      top: "-8px",
                                      right: "-8px",
                                    }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"del" + i}
                                  />
                                  <Film
                                    onClick={() => {
                                      window
                                        .open(val.checklist_file, "_blank")
                                        .focus();
                                    }}
                                    className="cursor_pointer "
                                    style={{ color: "#313131" }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"video" + i}
                                  />
                                </li>
                              </React.Fragment>
                            ) : val.fileType === "Document" ? (
                              <React.Fragment key={i}>
                                <li
                                  id="attachmentbg"
                                  className="shadow-sm me-2 mb-2"
                                >
                                  <XCircle
                                    onClick={() => deleteAttach(val)}
                                    className="cursor_pointer"
                                    style={{
                                      color: "red",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      top: "-8px",
                                      right: "-8px",
                                    }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"del" + i}
                                  />
                                  <FileText
                                    onClick={() => {
                                      window
                                        .open(val.checklist_file, "_blank")
                                        .focus();
                                    }}
                                    className="cursor_pointer "
                                    style={{ color: "#313131" }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"file" + i}
                                  />
                                </li>
                              </React.Fragment>
                            ) : null
                          ) : (
                            ""
                          )
                        )
                        : "NA"}
                    </ul>
                  </td>
                  <td>
                    <Edit
                      onClick={() => {
                        toggleUpdateModal();
                        dataForUpdate.data = val;
                        setDataForUpdate({
                          ...dataForUpdate,
                          data: dataForUpdate.data,
                        });
                      }}
                      style={{ color: "#313131" }}
                      id={"editToolTi" + i}
                      className="cursor_pointer "
                      height={"18px"}
                      width={"18px"}
                    />

                    <UncontrolledTooltip
                      placement="bottom"
                      target={"editToolTi" + i}
                    >
                      {"Edit Record"}
                    </UncontrolledTooltip>

                    <Trash2
                      onClick={() => {
                        deleteItem(val);
                      }}
                      id={"deleteToolTi" + i}
                      className="cursor_pointer ms-1"
                      height={"18px"}
                      width={"18px"}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"deleteToolTi" + i}
                    >
                      {"Delete Record"}
                    </UncontrolledTooltip>

                    {/* <Phone
                                                onClick={() => { startVideoCall(val) }}
                                                style={{ color: '#313131' }}
                                                id={"call" + i}
                                                className='cursor_pointer ms-1'
                                                height={'18px'}
                                                width={'18px'} />
                                            <UncontrolledTooltip placement="bottom" target={"call" + i}>
                                                {"Call"}
                                            </UncontrolledTooltip> */}

                    {/* <br /> */}

                    <Upload
                      onClick={() => {
                        toggleFileUploadModal(val);
                      }}
                      style={{ color: "#313131" }}
                      id={"uploadFile" + i}
                      className="cursor_pointer ms-1"
                      height={"18px"}
                      width={"18px"}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"uploadFile" + i}
                    >
                      {"Upload file"}
                    </UncontrolledTooltip>

                    {/* <Clipboard
                                                onClick={() => { toggleAnswerModal(val); }}
                                                style={{ color: '#313131' }}
                                                id={"viewanswer" + i}
                                                className='cursor_pointer ms-1'
                                                height={'18px'}
                                                width={'18px'} />
                                            <UncontrolledTooltip placement="bottom" target={"viewanswer" + i}>
                                                {"View Answers"}
                                            </UncontrolledTooltip> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : props.isSectionSelected ? (
        <p className="text-danger mb-0 f-22 text-center mt-4">
          {"No items present in section"}
        </p>
      ) : (
        <p className="text-danger mb-0 f-22 text-center mt-4">
          {"Please select a section"}
        </p>
      )}

      <Modal isOpen={ItemModal} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader toggle={toggle8} className="bg-primary-blue">
          {"Create Checklist Items"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <label className="f-w-600 f-14">{"Sr.No"}</label>
              <input
                className={
                  errors.SrNo ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`SrNo`}
                {...register(`SrNo`)}
                placeholder="SrNo*"
              />

              <span className="text-danger">{errors.SrNo?.message}</span>
            </div>

            <div className="col-12 col-md-3 mb-2">
              <label className="f-w-600 f-14">{"Sequence"}</label>
              <input
                className={
                  errors.sequence ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`sequence`}
                {...register(`sequence`)}
                placeholder="Enter sequence*"
              />

              <span className="text-danger">{errors.sequence?.message}</span>
            </div>

            <div className="col-12 col-md-12 mb-2">
              <label className="f-w-600 f-14">{"Description"}</label>
              <textarea
                className={
                  errors.section ? "form-control is-invalid" : "form-control"
                }
                type="text"
                {...register(`section`)}
                name={`section`}
                placeholder="Enter description*"
                rows="2"
              ></textarea>

              <span className="text-danger">{errors.section?.message}</span>
            </div>

            <div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Section"}</label>

              <select
                {...register(`sectionName`)}
                name={`sectionName`}
                className={
                  errors.sectionName
                    ? "form-control is-invalid"
                    : "form-control"
                }
                defaultValue={`${localStorage.getItem("selectedSection")}`}
              >
                <option value="">{"--Select*--"}</option>
                {props.sectionList.data.map((val, i) => (
                  <option value={val.id} key={i}>
                    {val.sectionName}
                  </option>
                ))}
              </select>

              <span className="text-danger">{errors.sectionName?.message}</span>
            </div>

            {<div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Comments"}</label>

              <select
                disabled={props.survey_type}
                {...register(`comments`)}
                name={`comments`}
                className={
                  errors.comments ? "form-control is-invalid" : "form-control"
                }
                defaultValue="Yes"
              >
                <option select="">{"--Select*--"}</option>
                <option value="Yes">{"Compulsory"}</option>
                <option value="No">{"Optional"}</option>
              </select>

              <span className="text-danger">{errors.comments?.message}</span>
            </div>
            }

            {<div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Photos"}</label>

              <select
                disabled={props.survey_type}
                {...register(`photos`)}
                name={`photos`}
                className={
                  errors.photos ? "form-control is-invalid" : "form-control"
                }
                defaultValue="Yes"
              >
                <option select="">{"--Select*--"}</option>
                <option value="Yes">{"Compulsory"}</option>
                <option value="No">{"Optional"}</option>
              </select>

              <span className="text-danger">{errors.photos?.message}</span>
            </div>
            }
            {<div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Videos"}</label>

              <select
                disabled={props.survey_type}
                {...register(`videos`)}
                name={`videos`}
                className={
                  errors.videos ? "form-control is-invalid" : "form-control"
                }
                defaultValue="Yes"
              >
                <option select="">{"--Select*--"}</option>
                <option value="Yes">{"Compulsory"}</option>
                <option value="No">{"Optional"}</option>
              </select>

              <span className="text-danger">{errors.videos?.message}</span>
            </div>
            }
            {<div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"OG"}</label>

              <select
                disabled={props.survey_type}
                {...register(`OG`)}
                name={`OG`}
                className={
                  errors.OG ? "form-control is-invalid" : "form-control"
                }
                defaultValue="Yes"
              >
                <option select="">{"--Select*--"}</option>
                <option value="Yes">{"Compulsory"}</option>
                <option value="No">{"Optional"}</option>
              </select>

              <span className="text-danger">{errors.OG?.message}</span>
            </div>
            }
            {props.survey_type && <div className="col-12 col-md-8">
              <label className="f-w-600 f-14">
                {"Configure Multi Level Answer"}
              </label>

              <Typeahead
                id="basic-typeahead"
                labelKey="description"
                multiple={true}
                options={mlaList.data}
                isLoading={mlaList.loading}
                placeholder="--Select*--"
                onChange={(e) => setMla(e)}
                selected={mla}
              />
            </div>}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Create Item
          </button>
        </ModalFooter>
      </Modal>

      {/* {
                inCall ?
                    <VideoCall setInCall={setInCall} channelName={channelName} appId={appId} token={token} />
                    : null
            } */}
    </React.Fragment>
  );
}

function Item_for_sire(props) {
  const [inCall, setInCall] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [token, setToken] = useState({ data: "" });
  const useClient = createClient(config);
  let navigate = useNavigate();
  const location = useLocation();
  const [ItemModal, setItemModal] = useState();
  const [visible, setVisible]=useState(false);
  const [dataForUpdate, setDataForUpdate] = useState({ data: "" });
  const [updateItemModal, setUpdateItemModal] = useState(false);
  const [fileUploadModal, setFileUploadModal] = useState();
  const [mlaList, setMlaList] = useState({
    data: [],
    loading: false,
  });
  const [answerModal, setAnswerModal] = useState();
  const [imageModal, setImageModal] = useState();

  const [dataForAttachmentUpload, setDataForAttachmentUpload] = useState("");
  const [dataForAnswer, setDataForAnswer] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [mla, setMla] = useState([]);

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(Itemsschems_for_sire),
    mode: "onChange",
  });
  const customer_id = localStorage.getItem("customer_id");


  let { errors } = formState;

  const toggleImageModal = (val) => {
    setImageModal(!imageModal);
    if (imageModal === undefined || imageModal === false) {
      setImageUrl(val);
    }
  };

  const toggleUpdateModal = () => {
    setUpdateItemModal(!updateItemModal);
  };

  const toggleFileUploadModal = (val) => {
    setDataForAttachmentUpload(val);
    setFileUploadModal(!fileUploadModal);
  };

  const toggleAnswerModal = (val) => {
    setDataForAnswer(val);
    setAnswerModal(!answerModal);
  };

  const toggle8 = () => {
   // setItemModal(!ItemModal);
      setVisible(!visible)
    if (visible=== false || visible === undefined) {
      reset();
      setMlaList({ ...mlaList, loading: true, data: [] });
      get_mla({ hasParent: "False", customer: customer_id }).then(
        (res) => {
          console.log("res", res);
          setMlaList({ ...mlaList, loading: false, data: res.data.payload });
        },
        (err) => {
          console.log("err", err);
          setMlaList({ ...mlaList, loading: false, data: [] });
        }
      );
    }
  };

  async function startVideoCall(val) {
    console.log("val", val);
    console.log(localStorage.getItem("selectedSectionName"));

    const sectionName = localStorage.getItem("selectedSectionName");
    const user_id = parseInt(localStorage.getItem("user_id"));

    await generateAgoraToken({
      userId: user_id,
      channelName: sectionName + "_" + val.id,
    }).then(
      (res) => {
        console.log("res", res);
        // token = res.data;
        token.data = res.data;
        setToken({ ...token, data: token.data });

        setChannelName(sectionName + "_" + val.id);
        setInCall(true);
        // navigate(`${process.env.PUBLIC_URL}/customeradmin/videocall`,
        //     {
        //         state: {
        //             setInCall: setInCall,
        //             channelName: channelName,
        //             appId: appId,
        //             token: token,
        //             inCall: inCall
        //         }
        //     })
      },
      (err) => {
        console.log("err", err);
      }
    );

    console.log(token);
  }

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);
      console.log("mla", mla);
      if (location.state.surveyType == "Sire") {
        if (mlaList.length == 0) {
          toast.error("Minimum One MLA is required !", {
            autoClose: 3000,
            theme: "light",
          });
          return;
        }
      }

      const reqpayload = {
        sequence: data.sequence,
        SrNo: data.SrNo,
        sectionName: parseInt(data.sectionName),
        checkList: parseInt(location.state.Id),
        section: data.section,
        MLA: mla
      };

      console.log("reqpayload", reqpayload);
      console.log("location.state", location.state);

      createChecklistItem(reqpayload).then(
        (res) => {
          console.log("res", res);
          toggle8();
          toast.success("Item created successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          setMla([]);
          props.fetchChecklistSections();
          // props.setSectionSeleted(false);
        },
        (err) => {
          console.log("err", err);

          if (err.response.status === 400) {
            SweetAlert.fire({
              title: "Only Integer is allowed for SrNo",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Ok",
              cancelButtonText: "cancel",
              reverseButtons: true,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function deleteItem(val) {
    console.log("val", val);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteChecklistSecItem({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Item deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            // props.setSectionSeleted(false);
            props.fetchChecklistSections();
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    });
  }

  function deleteAttach(val) {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delChecklistItemAttachment({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Attachment deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            props.fetchChecklistSections();
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    });
  }

  return (
    <React.Fragment>
      <Update_sire_items
        toggleUpdateModal={toggleUpdateModal}
        updateItemModal={updateItemModal}
        data={dataForUpdate.data}
        fetchChecklistSections={props.fetchChecklistSections}
        checkListId={location.state.Id}
        dataChecklist={props.dataChecklist}
        survey_type={props.survey_type}
      // setSectionSeleted={props.setSectionSeleted}
      />
      <UploadFile
        dataForAttachmentUpload={dataForAttachmentUpload}
        toggleFileUploadModal={toggleFileUploadModal}
        fetchChecklistSections={props.fetchChecklistSections}
        fileUploadModal={fileUploadModal}
      />

      {/* <AnswerScreen
                answerModal={answerModal}
                toggleAnswerModal={toggleAnswerModal}
                dataForAnswer={dataForAnswer}
            /> */}

      <ImagePreview
        toggleImageModal={toggleImageModal}
        imageModal={imageModal}
        imageUrl={imageUrl}
      />

      <hr />
      <div className="d-flex justify-content-between mt-4">
        <p className="f-18 f-w-600 mb-0">{`Items Of Section sire ${localStorage.getItem("selectedSectionId") !== null
          ? `- Id ${localStorage.getItem("selectedSectionId")}`
          : ""
          } `}</p>
        <button  onClick={()=>{
                  toggle8();
                  setVisible(true);
                }}
                 className="btn btn-primary-blue">
          Create Items
        </button>
      </div>
      <hr />

      {props.checklistItems.data.length > 0 ? (
        <div className="table-responsive ">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr className="f-18">
                <th scope="col">{"SrNo"}</th>
                <th scope="col">{"Sequence"}</th>
                <th scope="col">{"Description"}</th>

                {/* <th scope="col">{"Comments"}</th>
                <th scope="col">{"Photos"}</th>
                <th scope="col">{"Videos"}</th>
                <th scope="col">{"OG"}</th> */}
                <th scope="col">{"Attachments"}</th>
                <th scope="col" style={{ width: "120px" }}>
                  {"Action"}
                </th>
              </tr>
            </thead>
            <tbody>
              {props.checklistItems.data.map((val, i) => (
                <tr key={i} className="f-16">
                  <td scope="row">{val.SrNo === null ? "NA" : val.SrNo}</td>
                  <td>{val.sequence}</td>
                  <td>{val.section}</td>

                  {/* <td>{val.comments === "Yes" ? "Compulsory" : "Optional"}</td>
                  <td>{val.photos === "Yes" ? "Compulsory" : "Optional"}</td>
                  <td>{val.videos === "Yes" ? "Compulsory" : "Optional"}</td>
                  <td>{val.OG === "Yes" ? "Compulsory" : "Optional"}</td> */}

                  <td className="">
                    <ul>
                      {val.check_list_attachment.length > 0
                        ? val.check_list_attachment.map((val, i) =>
                          val.withAnswer === false ? (
                            val.fileType === "Image" ? (
                              <React.Fragment key={i}>
                                <li
                                  id="attachmentbg"
                                  className="shadow-sm me-2 mb-2"
                                >
                                  <XCircle
                                    onClick={() => deleteAttach(val)}
                                    className="cursor_pointer"
                                    style={{
                                      color: "red",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      top: "-8px",
                                      right: "-8px",
                                    }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"del" + i}
                                  />

                                  <Image
                                    onClick={() => {
                                      toggleImageModal(val);
                                    }}
                                    className="cursor_pointer  "
                                    style={{ color: "#313131" }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"image" + i}
                                  />
                                </li>
                              </React.Fragment>
                            ) : val.fileType === "Video" ? (
                              <React.Fragment key={i}>
                                <li
                                  id="attachmentbg"
                                  className="shadow-sm me-2 mb-2"
                                >
                                  <XCircle
                                    onClick={() => deleteAttach(val)}
                                    className="cursor_pointer"
                                    style={{
                                      color: "red",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      top: "-8px",
                                      right: "-8px",
                                    }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"del" + i}
                                  />
                                  <Film
                                    onClick={() => {
                                      window
                                        .open(val.checklist_file, "_blank")
                                        .focus();
                                    }}
                                    className="cursor_pointer "
                                    style={{ color: "#313131" }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"video" + i}
                                  />
                                </li>
                              </React.Fragment>
                            ) : val.fileType === "Document" ? (
                              <React.Fragment key={i}>
                                <li
                                  id="attachmentbg"
                                  className="shadow-sm me-2 mb-2"
                                >
                                  <XCircle
                                    onClick={() => deleteAttach(val)}
                                    className="cursor_pointer"
                                    style={{
                                      color: "red",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      top: "-8px",
                                      right: "-8px",
                                    }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"del" + i}
                                  />
                                  <FileText
                                    onClick={() => {
                                      window
                                        .open(val.checklist_file, "_blank")
                                        .focus();
                                    }}
                                    className="cursor_pointer "
                                    style={{ color: "#313131" }}
                                    height={"18px"}
                                    width={"18px"}
                                    id={"file" + i}
                                  />
                                </li>
                              </React.Fragment>
                            ) : null
                          ) : (
                            ""
                          )
                        )
                        : "NA"}
                    </ul>
                  </td>
                  <td>
                    <Edit
                      onClick={() => {
                        toggleUpdateModal();
                        dataForUpdate.data = val;
                        setDataForUpdate({
                          ...dataForUpdate,
                          data: dataForUpdate.data,
                        });
                      }}
                      style={{ color: "#313131" }}
                      id={"editToolTi" + i}
                      className="cursor_pointer "
                      height={"18px"}
                      width={"18px"}
                    />

                    <UncontrolledTooltip
                      placement="bottom"
                      target={"editToolTi" + i}
                    >
                      {"Edit Record"}
                    </UncontrolledTooltip>

                    <Trash2
                      onClick={() => {
                        deleteItem(val);
                      }}
                      id={"deleteToolTi" + i}
                      className="cursor_pointer ms-1"
                      height={"18px"}
                      width={"18px"}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"deleteToolTi" + i}
                    >
                      {"Delete Record"}
                    </UncontrolledTooltip>

                    {/* <Phone
                                                onClick={() => { startVideoCall(val) }}
                                                style={{ color: '#313131' }}
                                                id={"call" + i}
                                                className='cursor_pointer ms-1'
                                                height={'18px'}
                                                width={'18px'} />
                                            <UncontrolledTooltip placement="bottom" target={"call" + i}>
                                                {"Call"}
                                            </UncontrolledTooltip> */}

                    {/* <br /> */}

                    <Upload
                      onClick={() => {
                        toggleFileUploadModal(val);
                      }}
                      style={{ color: "#313131" }}
                      id={"uploadFile" + i}
                      className="cursor_pointer ms-1"
                      height={"18px"}
                      width={"18px"}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"uploadFile" + i}
                    >
                      {"Upload file"}
                    </UncontrolledTooltip>

                    {/* <Clipboard
                                                onClick={() => { toggleAnswerModal(val); }}
                                                style={{ color: '#313131' }}
                                                id={"viewanswer" + i}
                                                className='cursor_pointer ms-1'
                                                height={'18px'}
                                                width={'18px'} />
                                            <UncontrolledTooltip placement="bottom" target={"viewanswer" + i}>
                                                {"View Answers"}
                                            </UncontrolledTooltip> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : props.isSectionSelected ? (
        <p className="text-danger mb-0 f-22 text-center mt-4">
          {"No items present in section"}
        </p>
      ) : (
        <p className="text-danger mb-0 f-22 text-center mt-4">
          {"Please select a section"}
        </p>
      )}

      <ModalS show={visible} onHide={() => setVisible(false)} size="lg" backdrop="static">
        <ModalS.Header  className="bg-primary-blue" closeButton>
         
          <ModalS.Title >  {"Create Checklist Items"}</ModalS.Title>
        </ModalS.Header>
        <ModalS.Body>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <label className="f-w-600 f-14">{"Sr.No"}</label>
              <input
                className={
                  errors.SrNo ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`SrNo`}
                {...register(`SrNo`)}
                placeholder="SrNo*"
              />

              <span className="text-danger">{errors.SrNo?.message}</span>
            </div>

            <div className="col-12 col-md-3 mb-2">
              <label className="f-w-600 f-14">{"Sequence"}</label>
              <input
                className={
                  errors.sequence ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`sequence`}
                {...register(`sequence`)}
                placeholder="Enter sequence*"
              />

              <span className="text-danger">{errors.sequence?.message}</span>
            </div>

            <div className="col-12 col-md-12 mb-2">
              <label className="f-w-600 f-14">{"Description"}</label>
              <textarea
                className={
                  errors.section ? "form-control is-invalid" : "form-control"
                }
                type="text"
                {...register(`section`)}
                name={`section`}
                placeholder="Enter description*"
                rows="2"
              ></textarea>

              <span className="text-danger">{errors.section?.message}</span>
            </div>

            <div className="col-12 col-md-4 mb-2">
              <label className="f-w-600 f-14">{"Section"}</label>

              <select
                {...register(`sectionName`)}
                name={`sectionName`}
                className={
                  errors.sectionName
                    ? "form-control is-invalid"
                    : "form-control"
                }
                defaultValue={`${localStorage.getItem("selectedSection")}`}
              >
                <option value="">{"--Select*--"}</option>
                {props.sectionList.data.map((val, i) => (
                  <option value={val.id} key={i}>
                    {val.sectionName}
                  </option>
                ))}
              </select>

              <span className="text-danger">{errors.sectionName?.message}</span>
            </div>




            {props.survey_type && <div className="col-12 col-md-8">
              <label className="f-w-600 f-14">
                {"Configure Multi Level Answer"}
              </label>

              <Typeahead
                id="basic-typeahead"
                labelKey="description"
                multiple={true}
                options={mlaList.data}
                isLoading={mlaList.loading}
                placeholder="--Select*--"
                onChange={(e) => setMla(e)}
                selected={mla}
              />
            </div>}
          </div>
        </ModalS.Body>
        <ModalS.Footer>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Create Item
          </button>
        </ModalS.Footer>
      </ModalS>

      {/* {
                inCall ?
                    <VideoCall setInCall={setInCall} channelName={channelName} appId={appId} token={token} />
                    : null
            } */}
    </React.Fragment>
  );
}
export default AddSectionAndItems;
