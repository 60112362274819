import React from 'react'

function SurveyLogs() {
    return (
        <React.Fragment>
            <div className='vdi_surveylog py-4'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='title bg-primary-blue' id="card_title">
                                    {/* <h4 className='mb-0'>Survey Logs</h4> */}
                                    <p className='mb-0 f-24'>Survey Logs</p>
                                </div>
                                <div className='card-body'>

                                    {/* <NewSubscriptionForm /> */}

                                    <div className="table-responsive ">
                                        <table className="table table-bordered mt-4">
                                            <thead className='thead-light'>
                                                <tr className='f-18'>
                                                    <th scope="col">{"SuveryId"}</th>
                                                    <th scope="col">{"StartDate"}</th>
                                                    <th scope="col" >{"EndDate"}</th>
                                                    <th scope="col">{"CallStartDate&Time"}</th>
                                                    <th scope="col">{"CallEndDate&Time"}</th>
                                                    <th scope="col">{"RemoteUser"}</th>
                                                    <th scope="col">{"PrimarySurveyor"}</th>
                                                    <th scope="col">{"OtherPTS"}</th>
                                                    <th scope="col">{"Owner"}</th>
                                                    <th scope="col">{"Reviewer"}</th>
                                                    <th scope="col">{"CallSessionId"}</th>
                                                    <th scope="col">{"UploadedVideos"}</th>
                                                    <th scope="col">{"CreatedDate"}</th>
                                                    <th scope="col">{"UpdatedDate"}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* <tr>
                                                    <th scope="row">{"1"}</th>
                                                    <td>{"Standard"}</td>
                                                    <td >{"10"}</td>
                                                    <td>{"1000"}</td>
                                                    <td>{"10"}</td>
                                                    <td>{"5"}</td>
                                                    <td>{"500"}</td>
                                                    <td>{"4000"}</td>
                                                    <td>{"USD"}</td>
                                                    <td>{"5"}</td>
                                                    <td>{"10"}</td>
                                                </tr> */}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SurveyLogs