import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateSOP } from "../../../../api/index";
import { toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    sequence: yup
      .number()
      .required("Please enter sr.no")
      .typeError("Please enter valid number"),
    instruction: yup.string().required("I&G item name is required !"),
  })
  .required();

function AddSOPItems(props) {
  const [modal8, setModal8] = useState();
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  const toggle8 = () => {
    setModal8(!modal8);
    if (modal8 === false || modal8 === undefined) {
      reset();
    }
  };

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("props",props)
      console.log("data", data);
         let arr=props.SOPData?.SOPLineItems;
         let filtered_array=arr.filter((item)=>item.sequence==data.sequence);
         console.log("filtered_array",filtered_array)
       if(filtered_array.length>0){

        toast.error("This Sr.No is already present ! !",{
          autoClose:2000
        })

        return
       }


      // console.log("props.getValues", props.getValues("items"));
      console.log("props.getValues name", props.getValues("name"));
      console.log(
        "props.getValues description",
        props.getValues("description")
      );
      console.log("props.getValues selfie", props.getValues("selfie"));
      console.log(
        "props.getValues opening meeting",
        props.getValues("opening_Meeting")
      );
      console.log(
        "props.getValues methodology",
        props.getValues("methodology_id")
      );
      console.log("props.getValues photos", props.getValues("photos"));
      console.log("props.getValues videos", props.getValues("videos"));
      console.log("props.getValues OG", props.getValues("OG"));

      console.log("props.SOPData", props.SOPData);

      const tempMethodology = props.methodologyList.data.filter((val, i) => {
        if (val.type === props.getValues("methodology_id")) {
          return val.Id;
        }
      });

      const reqPayload = {
        Id: props.SOPData.SOP.Id,
        SOP: {
          customer_id: customer_id,
          name: props.getValues("name"),
          description: props.getValues("description"),
          selfie: props.getValues("selfie"),
          opening_Meeting: props.getValues("opening_Meeting"),
          methodology_id: tempMethodology[0].Id,
          photos: props.getValues("photos"),
          videos: props.getValues("videos"),
          OG: props.getValues("OG"),
        },
        SOPLineItems: [data],
      };
      console.log("reqPayload", reqPayload);

      updateSOP(reqPayload).then(
        (res) => {
          console.log("res", res);
          toggle8();
          props.addItemApiIsCalled();
          toast.success("I&G item added successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          props.fetchSOPById();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <button
        style={{ fontSize: "16px" }}
        className="btn btn-sm btn-primary-blue"
        onClick={toggle8}
      >
        Add Item
      </button>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader toggle={toggle8} className="bg-primary-blue">
          {"Add Instructions & Guidelines Item"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-3 mb-2 mb-md-0">
              <div className="form-group">
                <label className="f-w-600 f-14">{"Sr.No"}</label>
                <input
                  className={
                    errors.sequence
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter Sr.No*"
                  name="sequence"
                  {...register("sequence")}
                />

                <span className="text-danger">{errors.sequence?.message}</span>
              </div>
            </div>
            <div className="col-12 col-md-9">
              <div className="form-group">
                <label className="f-w-600 f-14">{"Instructions & Guidelines Item"}</label>
                <textarea
                  className={
                    errors.instruction
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter I&G item name*"
                  name="instruction"
                  {...register("instruction")}
                  rows="2"
                ></textarea>

                <span className="text-danger">
                  {errors.instruction?.message}
                </span>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            {"Submit"}
          </button>
          <button className="btn btn-dark" onClick={toggle8}>
            {"Cancel"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default AddSOPItems;
