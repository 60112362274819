import React, { useState, useEffect } from "react";
import { Edit, Trash2 } from "react-feather";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { updateCheckListForm } from "../../../../api/index";
import { useParams } from "react-router-dom";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Name is required")
      .max(500, "500 characters allowed !"),
    items: yup.array().of(
      yup.object().shape({
        key: yup.string().required("Required"),
        value: yup.string().notRequired(),
      })
    ),
  })
  .required();

function EditForm(props) {
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: { items: [{}] },
    }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "items",
  });

  const { id } = useParams();

  useEffect(() => {
    console.log("props.dataForUpdate", props.dataForUpdate);
    console.log("props.editModal", props.editModal);
    if (props.editModal == true) {
      setValue("name", props.dataForUpdate.title);
      setValue("items", props.dataForUpdate.values);
    }
  }, [props.editModal]);

  const onSubmit = (data) => {
    console.log("errors", errors);
    if (data !== "") {
      console.log("data", data);
      console.log("props.dataForUpdate", props.dataForUpdate);
      console.log("props.formList", props.formList);

      const oldList = [...props.formList.file];
      const reqPayload = {
        withSurvey: false,
        checklist: id,
        Id: props.formList.Id,
      };

      for (let i = 0; i < oldList.length; i++) {
        if (props.dataForUpdate.id === oldList[i].id) {
          oldList[i].title = data.name;
          oldList[i].values = data.items;
        }
      }
      reqPayload.file = oldList;

      updateCheckListForm(reqPayload).then(
        async (res) => {
          console.log("res", res);
          props.editToggle();
          toast.success("Form updated successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          await props.fetchChecklistForm();
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.editModal}
        toggle={() => props.editToggle()}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          toggle={() => props.editToggle()}
          className="bg-primary-blue  position-relative"
        >
          {"Update Form"}
          <button
            style={{
              top: "25%",
              left: "75%",
              tranform: "translate(-50%,-50%)",
            }}
            onClick={() => append({})}
            className="btn btn-light position-absolute"
          >
            Add Element
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 mb-2">
              <label className="f-w-600 f-14">{"Form Name"}</label>
              <textarea
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                placeholder="Enter form name*"
                {...register("name")}
                rows="2"
              ></textarea>

              <span className="text-danger f-14">{errors.name?.message}</span>
            </div>
          </div>
          {fields.map(({ id }, index) => (
            <React.Fragment key={id}>
              <div className="row">
                <div className="col-12 col-md-4 mb-2">
                  <label className="f-w-600 f-14">{"Element Name"}</label>
                  <input
                    className={
                      errors.items?.[index]?.key
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    name={`items[${index}].key`}
                    {...register(`items[${index}].key`)}
                    placeholder="Enter name*"
                  />

                  <span className="text-danger f-14">
                    {errors.items?.[index]?.key?.message}
                  </span>
                </div>

                <div className="col-12 col-md-7 mb-2">
                  <label className="f-w-600 f-14">{"Element Value"}</label>
                  <textarea
                    className={
                      errors.items?.[index]?.value
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    name={`items[${index}].value`}
                    {...register(`items[${index}].value`)}
                    placeholder="Enter value*"
                    rows={1}
                  ></textarea>

                  <span className="text-danger f-14">
                    {errors.items?.[index]?.value?.message}
                  </span>
                </div>

                <div className="col-12 col-md-1">
                  <label className="f-w-600 f-14 invisible">{"Action"}</label>

                  <div
                    id="elememtDel"
                    className={index > 0 ? "" : "cursor_banned "}
                    onClick={index > 0 ? () => remove(index) : null}
                    style={{
                      height: "38px",
                      width: "38px",
                      background: "white",
                      borderRadius: "8px",
                      position: "relative",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <Trash2
                      height={"18px"}
                      width={"18px"}
                      style={{
                        position: "absolute",
                        top: "25%",
                        left: "25%",
                        transform: "transform(-50%,-50%)",
                        color: "red",
                      }}
                    />
                    <UncontrolledTooltip placement="bottom" target="elememtDel">
                      {"Delete Element"}
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Update Form
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default EditForm;
