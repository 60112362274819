import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  getAllCountries,
  getStates,
  getCities,
  createCustomer,
  getAllCustomerType,
} from "../../../../api";
import { ToastContainer, toast } from "react-toastify";
import { getAutoPassword } from "../../../../api/index";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(30, "30 characters allowed !")
      .required("Name is required !"),
    email: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    adminEmail: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    password: yup
      .string()
      .required("Password is required !")
      .min(6, "Minimum 6 characters required !")
      .max(16, "Maximum 16 characters allowed !")
      .matches(/^((?!\s).)*$/, "Space not allowed"),
    website: yup.string().required("Website is required !"),
    contactNumber: yup.string().required("Contact number is required !"),
    // .matches(/^[6-9][0-9]{9}$/, "Enter valid contact number !"),
    address: yup.string().nullable().required("Address is required !"),
    // state: yup.array().nullable().required('State is required !'),
    // city: yup.array().nullable().required('City is required !'),
    countryCode: yup.array().nullable().required("Country Code is required !"),
    country: yup.array().nullable().required("Country is required !"),
    // isActive: yup.string().nullable().required('Status is required !'),
    customerType: yup
      .string()
      .nullable()
      .required("Customer type is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    logo: yup.mixed().test("fileSize", "The file is too large", (value) => {
      if (!value.length) return true; // attachment is optional
      return value[0].size <= 5000000;
    }),
  })
  .required();

function CreateCustForm(props) {
  const [modal8, setModal8] = useState();
  const [dafaultCountry, setDefaultCountry] = useState("India");
  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });
  const [custTypeList, setCustTypeList] = useState({ data: [], state: false });
  const [showPassword, setShowPassword] = useState(false);
  const showPassRef = useRef();

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  let { errors } = formState;

  const selectedCountry = watch("country");
  const selectedState = watch("state");
  const sameAsEmail = watch("sameAsEmail");
  const allocateAutoPassword = watch("allocateAutoPassword");

  useEffect(() => {
    console.log("sameAsEmail", sameAsEmail);
    if (sameAsEmail == true) {
      const email = getValues("email");
      setValue("adminEmail", email);
    }
  }, [sameAsEmail]);

  useEffect(() => {
    console.log("allocateAutoPassword", allocateAutoPassword);
    if (allocateAutoPassword == true) {
      getAutoPassword().then(
        (res) => {
          console.log("res");
          setValue("password", res.data);
          toast.success("Password Generated !", {
            autoClose: 3000,
            theme: "light",
          });
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    }
  }, [allocateAutoPassword]);

  useEffect(() => {
    console.log("selectedCountry", selectedCountry);
    if (selectedCountry !== undefined && selectedCountry.length > 0) {
      //  setValue("countryCode", [{ phone_code: 1 }]);
      // fetching states for selected country through api
      if (selectedCountry !== undefined && selectedCountry.length !== 0) {
        console.log(
          "selectedCountry[0].phone_code",
          selectedCountry[0].phone_code
        );

        setValue("countryCode", [
          { phone_code: selectedCountry[0].phone_code },
        ]);

        setStateList({ ...stateList, data: [], state: true });

        getStates(selectedCountry[0].name).then(
          (res) => {
            console.log("res.data", res.data);
            setStateList({ ...stateList, data: res.data, state: false });
          },
          (err) => {
            console.log("err", err);
            setStateList({ ...stateList, data: [], state: false });
          }
        );
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    console.log("selectedState", selectedState);

    if (selectedState !== undefined) {
      if (selectedState.length > 0) {
        setCityList({ ...cityList, data: [], state: true });

        getCities(selectedState[0].name).then(
          (res) => {
            console.log("res.data", res.data);
            setCityList({ ...cityList, data: res.data, state: false });
          },
          (err) => {
            console.log("err", err);
            setCityList({ ...cityList, data: [], state: false });
            // toast.error("Something went wrong in fetching cities !", { autoClose: 3000,theme:'light' })
          }
        );
      }
    }
  }, [selectedState]);

  const toggle8 = () => {
    setModal8(!modal8);
    // console.log(modal8);

    // fetching all countries throufh api
    if (modal8 === false || modal8 === undefined) {
      // resetting the form
      reset();

      setCountryList({ ...countryList, state: true, data: [] });
      getAllCountries().then(
        (res) => {
          console.log("res", res);
          setCountryList({ ...countryList, state: false, data: res.data });

          // const temp = res.data.map((val) => {
          //     if (val.name === 'India') {
          //         return val
          //     }
          // })

          // console.log("temp", temp);
        },
        (err) => {
          console.log("err", err);
          setCountryList({ ...countryList, state: false, data: [] });
          toast.error("Something went wrong in fetching countries !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );

      // Fetching Customer Type
      setCustTypeList({ ...custTypeList, state: true, data: [] });
      getAllCustomerType().then(
        (res) => {
          console.log("res", res);
          setCustTypeList({ ...custTypeList, state: false, data: res.data });
        },
        (err) => {
          console.log("err", err);
          setCustTypeList({ ...custTypeList, state: false, data: [] });
          toast.error("Something went wrong in fetching countries !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    }
  };

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      var reqPayload = { ...data };
      reqPayload.country = data.country[0].name;
      reqPayload.contactNumber =
        data.countryCode[0].phone_code + data.contactNumber;
      delete reqPayload.countryCode;

      if (data.state?.length > 0) {
        reqPayload.state = data.state[0].name;
      } else {
        delete reqPayload.state;
      }

      if (data.city?.length > 0) {
        reqPayload.city = data.city[0].name;
      } else {
        delete reqPayload.city;
      }

      if (Object.keys(reqPayload.logo).length === 0) {
        delete reqPayload.logo;
      } else {
        reqPayload.logo = reqPayload.logo[0];
      }

      // reqPayload.isActive = data.isActive === 'Active' ? true : false;

      delete reqPayload.sameAsEmail;
      delete reqPayload.allocateAutoPassword;

      console.log("reqPayload", reqPayload);

      var formdata = new FormData();

      for (var key in reqPayload) {
        formdata.append(key, reqPayload[key]);
      }

      createCustomer(formdata).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Customer Created Successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            toggle8();

            setTimeout(() => {
              props.CallByFetchCust("refresh");
            }, 200);
          }
        },
        (err) => {
          console.log("err", err);
          if (err.response.status === 400) {
            toast.error("Customer admin email should be unique !", {
              autoClose: 3000,
              theme: "light",
            });
          } else if (err.response.status === 401) {
            toast.error("Unauthorized !", { autoClose: 3000, theme: "light" });
          } else if (err.response.status === 500) {
            toast.error("Something went wrong, please try again !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };
  const ha = (val) => {
    console.log("val", val);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <button
        style={{ fontSize: "16px " }}
        onClick={toggle8}
        className="btn btn-primary-blue float-right"
      >
        Create Org
      </button>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          <p className="f-22 f-w-400 mb-0">{"Create New Organisation"}</p>
        </ModalHeader>
        <ModalBody>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Name"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Organisation Name*"
                    name="name"
                    {...register("name")}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Email"}</label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register("email")}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Admin Email"}</label>
                  <input
                    className={
                      errors.adminEmail
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="email"
                    name="adminEmail"
                    placeholder="Admin Email Address*"
                    {...register("adminEmail")}
                  />

                  <span className="text-danger">
                    {errors.adminEmail?.message}
                  </span>

                  <div className="checkbox p-0">
                    <input
                      id="solid3"
                      {...register("sameAsEmail")}
                      name="sameAsEmail"
                      type="checkbox"
                    />
                    <label htmlFor="solid3" className="mb-0">
                      {"Same as email"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Password"}</label>
                  <div className="input-group">
                    <input
                      className={
                        errors.password
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type={showPassword ? "text" : "password"}
                      placeholder="Password*"
                      name="password"
                      {...register("password")}
                    />
                    <div className="input-group-append">
                      {showPassword ? (
                        <span className="input-group-text">
                          <i
                            className="fa cursor_pointer fa-eye-slash"
                            onClick={() => setShowPassword(false)}
                          ></i>
                        </span>
                      ) : (
                        <span className="input-group-text">
                          <i
                            className="fa cursor_pointer fa-eye"
                            onClick={() => setShowPassword(true)}
                          ></i>
                        </span>
                      )}
                    </div>
                  </div>

                  <span className="text-danger">
                    {errors.password?.message}
                  </span>

                  <div className="checkbox p-0">
                    <input
                      id="dafault-checkbox2"
                      {...register("allocateAutoPassword")}
                      name="allocateAutoPassword"
                      type="checkbox"
                    />
                    <label htmlFor="dafault-checkbox2" className="mb-0">
                      {"Allocate auto password"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Website"}</label>
                  <input
                    className={
                      errors.website
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Organisation Website*"
                    name="website"
                    {...register("website")}
                  />

                  <span className="text-danger">{errors.website?.message}</span>
                </div>
              </div>

              {/* <div className='col-12 col-md-6'>
                                <div className="form-group mb-2">
                                    <label className='f-w-600'>{"Status"}</label>

                                    <Controller
                                        control={control}
                                        name="isActive"
                                        defaultValue={null}

                                        render={({ onChange, value }) => (
                                            <select
                                                onChange={onChange}
                                                selected={value}
                                                className={errors.isActive ? "custom-select form-control is-invalid" : "custom-select form-control"}>
                                                <option select="">{"--Select--"}</option>
                                                <option defaultValue="Active">{"Active"}</option>
                                                <option defaultValue="Inactive">{"Inactive"}</option>
                                            </select>
                                        )}
                                    />

                                    <span className='text-danger'>{errors.isActive?.message}</span>
                                </div>
                            </div> */}

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Organisation Type"}</label>
                  <Controller
                    name="customerType"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        className={
                          errors.customerType
                            ? "custom-select form-control is-invalid"
                            : "custom-select form-control"
                        }
                      >
                        <option select="">{"--Select*--"}</option>

                        {custTypeList.data.map((val, i) => (
                          <option defaultValue={val.name} key={i}>
                            {val.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />

                  <span className="text-danger">
                    {errors.customerType?.message}
                  </span>
                </div>
              </div>

              {/* <div className='col-12 col-md-6'>
                                <div className="form-group mb-2">
                                    <label className='f-w-600'>{"Contact Number"}</label>
                                    <input
                                        className={errors.contactNumber ? "form-control is-invalid" : "form-control"}
                                        type="text"
                                        placeholder="Organisation Contact*"
                                        name='contactNumber'
                                        {...register('contactNumber')}
                                    />

                                    <span className='text-danger'>{errors.contactNumber?.message}</span>
                                </div>
                            </div> */}

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Address"}</label>

                  <textarea
                    className={
                      errors.address
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Organisation Address*"
                    name="address"
                    {...register("address")}
                    rows="1"
                  ></textarea>

                  <span className="text-danger">{errors.address?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Country"}</label>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={
                          countryList.data !== undefined ? countryList.data : []
                        }
                        isLoading={countryList.state}
                        placeholder="--Select*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.country ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.country?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Contact Number"}</label>

                  <div className="row">
                    <div className="col-12 col-md-3 pe-0">
                      <Controller
                        name="countryCode"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            name="countryCode"
                            id="basic-typeahead"
                            labelKey="phone_code"
                            multiple={false}
                            options={countryList.data}
                            isLoading={countryList.state}
                            placeholder="--Select*--"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.countryCode ? true : false}
                            disabled={
                              selectedCountry !== undefined
                                ? selectedCountry.length > 0
                                  ? false
                                  : true
                                : true
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-9 ps-1">
                      <input
                        className={
                          errors.contactNumber
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        type="number"
                        placeholder="Organisation Contact*"
                        name="contactNumber"
                        {...register("contactNumber")}
                      />
                    </div>
                  </div>

                  <span className="text-danger">
                    {errors.contactNumber?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"State"}</label>

                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={stateList.data}
                        isLoading={stateList.state}
                        placeholder="--Select*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.country ? true : false}
                        disabled={
                          selectedCountry !== undefined
                            ? selectedCountry.length > 0
                              ? false
                              : true
                            : true
                        }
                      />
                    )}
                  />

                  {/* <Controller
                                        as={Typeahead}
                                        control={control}
                                        name="state"
                                        // {...register("state")}
                                        rules={{ required: true }}
                                        id="basic-typeahead"
                                        labelKey="name"
                                        options={stateList.data}
                                        isLoading={stateList.state}
                                        multiple={false}
                                        placeholder="--Select--"
                                        // isInvalid={errors.state ? true : false}
                                        defaultValue={''}
                                        disabled={selectedCountry !== undefined ? selectedCountry.length > 0 ? false : true : true}
                                    /> */}

                  {/* <span className='text-danger'>{errors.state?.message}</span> */}
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"City"}</label>

                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        allowNew={true}
                        multiple={false}
                        options={cityList.data}
                        isLoading={cityList.state}
                        placeholder="--Select*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.country ? true : false}
                        disabled={
                          selectedState !== undefined
                            ? selectedState.length > 0
                              ? false
                              : true
                            : true
                        }
                      />
                    )}
                  />

                  {/* <Controller
                                        as={Typeahead}
                                        control={control}
                                        name="city"
                                        // {...register("state")}
                                        rules={{ required: true }}
                                        id="basic-typeahead"
                                        labelKey="name"
                                        options={cityList.data}
                                        isLoading={cityList.state}
                                        multiple={false}
                                        placeholder="--Select--"
                                        // isInvalid={errors.city ? true : false}
                                        defaultValue={''}
                                        disabled={selectedState !== undefined ? selectedState.length > 0 ? false : true : true}
                                    /> */}

                  {/* <span className='text-danger'>{errors.city?.message}</span> */}
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">Logo</label>

                  <input
                    className={
                      errors.logo ? "form-control is-invalid" : "form-control"
                    }
                    type={"file"}
                    accept="image/png, image/jpeg"
                    {...register("logo")}
                  />

                  <span className="text-danger">{errors.logo?.message}</span>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <button
            style={{ fontSize: "16px" }}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary-blue float-end"
          >
            {"Create Org"}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default CreateCustForm;
