import React, { useState, useEffect, useReducer } from "react";
import { Trash2, Edit, PlusCircle } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HANDLE_ACTION } from "../../../../redux/actionTypes";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
//import EditsocList from "./EditsocList";
import clipboard from "../../../../assets/images/Vedam/clipboard.png";
import Edit_SOC_category from "./Edit_SOC_category";
import {
  delete_soc_category,
  get_soc,
  get_soc_category,
  post_soc_category,
} from "../../../../api";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(250, "250 characters allowed !")
      .required("SOC category name is required !"),
    srno: yup
      .string()
      .max(30, "30 charaters allowed !")
      .matches(/^[a-zA-Z0-9]*$/, 'SR.No must be alphanumeric')
      .required("Sr.No is required"),
    description: yup
      .string()
      .max(500, "500 characters allowed !")
      .required("Description is required !"),
  })
  .required();

function SOC_category_master() {
  let navigate = useNavigate();
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const [modal8, setModal8] = useState();
  const [modal, setModal] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdate, setDataForUpdate] = useState({ data: {} });
  const [search,setSearch]=useState();

  const user = localStorage.getItem("user");

  const toggle8 = () => {
    setModal8(!modal8);
    if (modal8 === false || modal8 === undefined) {
      reset();
    }
  };
  const toggle = () => {
    setModal(!modal);
    if (modal === false || modal === undefined) {
      reset();
    }
  };

  const initialState = {
    loading: false,
    error: "",
    socList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case HANDLE_ACTION:
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          socList: action.socList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: HANDLE_ACTION,
      page: state.page,
      loading: true,
      error: state.error,
      socList: state.socList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetch_SOC_lists();
  }, [state.page, state.itemsPerPage,search]);

  useEffect(() => { }, []);

  function fetch_SOC_lists(para1) {
    dispatch({
      type: HANDLE_ACTION,
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      socList: state.socList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      page: state.page,
      entriesPerPage: state.itemsPerPage,
      customer: customer_id,
    };

    if (para1 || search) {
      reqPayload = {
        page: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para1==undefined?search:para1,
        customer: customer_id,
      };
    }

    get_soc_category(reqPayload).then(
      (res) => {
        console.log("res_soc    ", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.total));
        setTotalRecords(res.data.total);
        dispatch({
          type: HANDLE_ACTION,
          page: state.page,
          loading: false,
          error: state.error,
          socList: res.data.payload,
          itemsPerPage: state.itemsPerPage,
          maxPage:
          (para1 !== "" && search!=="")
              ? calMaxPage(res.data.total)
              : calMaxPage(res.data.total),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    );
  }

  // Deleting soc category
  const del_soc_category = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {

        //checking whether this category have child in it or not
        //  let x= check_child(val)
        // if(x){
        //    toast.error("Child is not present")    
        // }
        // else{
        //   toast.error("Child is  present")    
        // }
        //  Firing delete api
        delete_soc_category({ Id: val.Id }).then(
          (res) => {
            console.log("res", res);
            toast.success("SOC category deleted successfully !", {
              autoClose: 3000,
              theme: "light",
            });

            if (state.socList.length == 1  && state.page !== 1) {
              dispatch({
                type: HANDLE_ACTION,
                page: state.page - 1,
                loading: state.loading,
                error: state.error,
                socList: state.socList,
                itemsPerPage: state.itemsPerPage,
                maxPage: state.maxPage,
              });
            } else {
              fetch_SOC_lists();
            }
          },
          (err) => {
            console.log("err", err);
            toast.error(err.response.data.message, {
              autoClose: 3000,
              theme: "light",
            });
          }
        );
      }
    });
  };

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const payload = {
        srno: data.srno,
        categoryName: data.name,
        description: data.description,
        customer:customer_id
      };

      post_soc_category(payload).then(
        (res) => {
          console.log("res", res);

          toast.success("SOC category created successfully !", {
            autoClose: 3000,
            theme: "light",
          });
          toggle8();
          fetch_SOC_lists();
        },
        (err) => {
          console.log("err", err.response);
          toast.error(err.response.data.message, {
            autoClose: 3000,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };



  const delay = 1000;
  let timerId;

  const handleSearchChange = (e) => {
  
    if (e.target.value == "") {
      setSearch("")
      fetch_SOC_lists()
    }
    else{

    
    clearTimeout(timerId);

    timerId = setTimeout(() => {
     
      setSearch(e.target.value)
      fetch_SOC_lists(e.target.value);
     

    }, delay);
  }
  }

  useEffect(() => {

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  

  return (
    <React.Fragment>
      <Edit_SOC_category
        modal={modal}
        toggle={toggle}
        data={dataForUpdate.data}
        fetch_SOC_lists={fetch_SOC_lists}
      />

      <div className="vdi_socList py-4 fontFamily">
        <div className="card">
          <div className="title bg-primary-blue" id="card_title">
            <p className="mb-0 f-24">SOC Category</p>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="d-flex justify-content-between">
                <div >
                  <input placeholder="Search" className="form-control"
                    onChange={(e) => {
                      handleSearchChange(e)
                    }}
                  />
                </div>
                <div className="d-flex justify-content-end mb-4">
                  <button
                    onClick={toggle8}
                    style={{ fontSize: "16px " }}
                    className="btn btn-primary-blue"
                  >
                    Create New SOC Category
                  </button>
                </div>

              </div>
            </div>


            {state.loading ? (
              <div className="loader-box justify-content-center">
                <div className="loader">
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                  <div className="line bg-dark"></div>
                </div>
              </div>
            ) : state.socList.length > 0 ? (
              <div className="table-responsive " style={{ maxHeight: "70vh" }}>
                <table className="table table-striped table-bordered">
                  <thead className="thead-light">
                    <tr
                      className="f-18"
                      style={{
                        position: "sticky",
                        top: "0",
                        background: "white",
                      }}
                    >
                      {/* <th scope="col">{"#"}</th> */}
                      <th scope="col">{"Sr.No"}</th>
                      <th scope="col">{"SOC Category Name"}</th>
                      <th scope="col">{"Description"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.socList.map((val, i) => (
                      <tr key={i} className="f-16">
                        {/* <td>{i + 1}</td> */}
                        <td>{val.srno}</td>
                        <td>{val.categoryName}</td>
                        <td>{val.description}</td>
                        <td>
                          <Edit
                            onClick={() => {
                              dataForUpdate.data = val;
                              console.log("val", val);
                              setDataForUpdate({
                                ...dataForUpdate,
                                data: dataForUpdate.data,
                              });
                              toggle();
                            }}
                            style={{ color: "#313131" }}
                            id={"editToolTip" + i}
                            className="cursor_pointer "
                            height={"18px"}
                            width={"18px"}
                          />

                          <UncontrolledTooltip
                            placement="bottom"
                            target={"editToolTip" + i}
                          >
                            {"Edit SOC category"}
                          </UncontrolledTooltip>

                          <Trash2
                            onClick={() => del_soc_category(val)}
                            id={"deleteToolTip" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"deleteToolTip" + i}
                          >
                            {"Delete SOC category"}
                          </UncontrolledTooltip>

                          <PlusCircle
                            onClick={() =>
                              navigate(
                                "/customeradmin/soc_category/soc_master",
                                { state: { ...val } }
                              )
                            }
                            id={"plusToolTip" + i}
                            className="cursor_pointer ms-1"
                            height={"18px"}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"plusToolTip" + i}
                          >
                            {"Create SOC Master "}
                          </UncontrolledTooltip>

                          {/* <PlusCircle
                            onClick={() => {
                              navigate(
                                "/customeradmin/soc_category/soc_master",
                                { state: { ...val } }
                              );
                            }}
                            id={"plusToolTip" + i}
                            width={"18px"}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={"plusToolTip" + i}
                          >
                            {"Create SOC "}
                          </UncontrolledTooltip> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : apiBySearch ? (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"No SOC List Found !"}
              </p>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"SOC List is empty"}
              </p>
            )}

            {state.loading ? null : (
              <div className="mt-4">
                <Pagination
                  aria-label="Page navigation"
                  className="pagination justify-content-end pagination-primary"
                >
                  <PaginationItem disabled={state.page === 1 ? true : false}>
                    <PaginationLink
                      className="cursor_pointer"
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page - 1,
                          loading: state.loading,
                          error: state.error,
                          socList: state.socList,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC - 10);
                      }}
                    >
                      {"Previous"}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem active>
                    <PaginationLink>{state.page}</PaginationLink>
                  </PaginationItem>

                  <PaginationItem
                    disabled={
                      state.page === state.maxPage || state.maxPage === 0
                        ? true
                        : false
                    }
                  >
                    <PaginationLink
                      last
                      onClick={() => {
                        dispatch({
                          type: HANDLE_ACTION,
                          page: state.page + 1,
                          loading: state.loading,
                          error: state.error,
                          socList: state.socList,
                          itemsPerPage: state.itemsPerPage,
                          maxPage: state.maxPage,
                        });
                        setSrNo((prevC) => prevC + 10);
                      }}
                      className="cursor_pointer"
                    >
                      {"Next"}
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>

                {/* <p className='f-14 text-muted float-end mt-1'>{`Total Records: ${totalRecords}`}</p> */}
                <div>
                  <div className="d-flex justify-content-end">
                    <p className="f-12 text-muted mt-1">{`${state.page} / ${state.maxPage} pages`}</p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p className="f-14 text-muted">{`Total Records: ${totalRecords}`}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={modal8} toggle={toggle8} size="lg" backdrop="static">
        <ModalHeader toggle={toggle8} className="bg-primary-blue">
          {"Create New SOC Category"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"Sr. No"}</label>
              <input
                className={
                  errors.srno ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`srno`}
                {...register(`srno`)}
                placeholder="Enter Sr. No.*"
              />

              <span className="text-danger">{errors.srno?.message}</span>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="f-w-600 f-14">{"SOC Category Name"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                name={`name`}
                {...register(`name`)}
                placeholder="Enter SOC Category Name"
              />

              <span className="text-danger">{errors.name?.message}</span>
            </div>

            <div className="col-12 col-md-12 mb-2">
              <label className="f-w-600 f-14">{"Description"}</label>
              <textarea
                className={
                  errors.description
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                name={`description`}
                {...register(`description`)}
                placeholder="Enter Description*"
                rows={"2"}
              ></textarea>

              <span className="text-danger">{errors.description?.message}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default SOC_category_master;
